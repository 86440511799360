import React, { useEffect, useRef, useState, useCallback, useMemo } from "react";
import { REIDataTable, REIToastContainer } from "../../../../components/ui";
import { formatCurrency } from "../../../../utils/commonUtil";
import { IAnalyserData, IAnalyserDisplayData } from "../../../../interface/analysed";
import { ColumnType, Icons, MEMBER_KEY, Messages } from "../../../../utils/constants";
import { confirmPopup } from "primereact/confirmpopup";
import { useHttpGets } from "../../../../services/httpService";
import { ApiEndPoints } from "../../../../utils/endpoints";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";

interface ISavedInAnalyserProps {
  data: IAnalyserData[];
  forecastForYears: number;
  callRefreshData: () => void;
  hideExtraColumns?: boolean;
}

const SavedInAnalyser: React.FC<ISavedInAnalyserProps> = ({
  data,
  forecastForYears,
  callRefreshData,
  hideExtraColumns,
}) => {
  const toastRef = useRef<Toast>(null);
  const [savedProperties, setSavedProperties] = useState<IAnalyserDisplayData[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (data && data.length > 0) {
      setSavedProperties(getSavedAnalysedata());
    }
  }, [data, forecastForYears]);

  const getSavedAnalysedata = useCallback((): IAnalyserDisplayData[] => {
    return data.reduce((analysedData: IAnalyserDisplayData[], analysedObject: IAnalyserData) => {
      if (forecastForYears >= 0 && forecastForYears < analysedObject.PositionData.length) {
        const positionData = analysedObject.PositionData[forecastForYears];
        analysedData.push({
          Country: analysedObject.Country,
          ModulesId: analysedObject.ModulesId,
          CapitalGrowth: positionData.CapitalGrowth,
          CashflowPostTax: positionData.CashflowPostTax,
          CashflowPreTax: positionData.CashflowPreTax,
          GrossYield: positionData.GrossYield,
          LoanAmount: positionData.LoanAmount,
          MarketValue: positionData.MarketValue,
          NetYield: positionData.NetYield,
          PropertyAnalyserId: analysedObject.PropertyAnalyserId,
          PropertyName: analysedObject.PropertyName,
        });
      }
      return analysedData;
    }, []);
  }, [data, forecastForYears]);

  const deleteSavedAnalyser = async (rowData: IAnalyserDisplayData) => {
    try {
      const response = await useHttpGets(
        `${ApiEndPoints.analyser}/RemovePropertyAnalyserRecord?MemberId=${localStorage.getItem(
          MEMBER_KEY
        )}&PropertAnalyserId=${rowData.PropertyAnalyserId}`
      );
      if (response.status === 200) {
        callRefreshData();
        showToast("success", Messages.SUCCESS, Messages.DELETE_SUCCESS);
      } else {
        showToast("error", "Error", Messages.DELETE_ERROR);
      }
    } catch (error) {
      showToast("error", "Error", Messages.DELETE_ERROR);
    }
  };

  const handleDeleteSavedAnalyser = (event: React.MouseEvent<HTMLElement>, rowData: IAnalyserDisplayData) => {
    confirmPopup({
      target: event.currentTarget,
      message: Messages.CONFIRMATION_MESSAGE,
      icon: Icons.INFO_ICON,
      accept: () => deleteSavedAnalyser(rowData),
      reject: () => {},
    });
  };

  const handlePropertyClick = (event: React.MouseEvent<HTMLElement>, rowData: IAnalyserDisplayData) => {
    navigate("/property-analyser/analysis-report", {
      state: { propertyAnalyserId: rowData.PropertyAnalyserId, propertyName: rowData.PropertyName },
    });
  };

  const showToast = (severity: "success" | "info" | "warn" | "error", summary: string, detail: string) => {
    toastRef.current?.show({ severity, summary, detail, life: 3000 });
  };

  const currencyTemplate = (field: keyof IAnalyserDisplayData, rowData: IAnalyserDisplayData) => (
    <div className={`w-[92px] text-end`}>
      {(rowData[field] as number) < 0 ? (
        <span className="text-red-600">({formatCurrency(-rowData[field] as number)})</span>
      ) : (
        formatCurrency(rowData[field] as number)
      )}
    </div>
  );

  const cashflowPreTaxTemplate = (rowData: IAnalyserDisplayData) => (
    <div className="w-[130px] text-end">
      {rowData.CashflowPreTax < 0 ? (
        <span className="text-red-600">({formatCurrency(-rowData.CashflowPreTax)})</span>
      ) : (
        formatCurrency(rowData.CashflowPreTax)
      )}
    </div>
  );

  const netYieldPreTaxTemplate = (rowData: IAnalyserDisplayData) => (
    <div className="w-full text-center">{rowData.GrossYield < 0 ? `0%` : `${rowData.GrossYield}%`}</div>
  );

  const columns = useMemo(() => {
    const baseColumns = [
      {
        field: "PropertyName",
        header: "Property Name",
        type: "link",
        width: "55%",
        onClick: handlePropertyClick,
      },
    ];

    if (!hideExtraColumns) {
      const extraColumns = [
        {
          field: "MarketValue",
          header: "Market value",
          width: "130px",
          bodyTemplate: (rowData: IAnalyserDisplayData) => currencyTemplate("MarketValue", rowData),
        },
        {
          field: "LoanAmount",
          header: "Loan amount",
          width: "120px",
          bodyTemplate: (rowData: IAnalyserDisplayData) => currencyTemplate("LoanAmount", rowData),
        },
        {
          field: "CapitalGrowth",
          header: "Capital growth",
          width: "120px",
          bodyTemplate: (rowData: IAnalyserDisplayData) => currencyTemplate("CapitalGrowth", rowData),
        },
        {
          field: "CashflowPreTax",
          header: "Cashflow (pre-tax)",
          width: "130px",
          bodyTemplate: cashflowPreTaxTemplate,
        },
        {
          field: "GrossYield",
          header: "Yield% (pre-tax)",
          width: "130px",
          bodyTemplate: netYieldPreTaxTemplate,
        },
        {
          field: "PropertyAnalyserId",
          header: "",
          type: ColumnType.custom,
          onClick: handleDeleteSavedAnalyser,
          template: <i className="pi pi-minus-circle text-[#4E9FBA] cursor-pointer" style={{ fontSize: "1rem" }}></i>,
          width: "50px",
        },
      ];

      return [...baseColumns, ...extraColumns];
    }

    return baseColumns;
  }, [hideExtraColumns, handlePropertyClick, handleDeleteSavedAnalyser]);

  if (!data || data.length === 0) {
    return <div className="text-center">You have no saved property analysis.</div>;
  }

  return (
    <>
      <REIToastContainer toastReference={toastRef} />
      {savedProperties && (
        <div className={!hideExtraColumns ? "overflow-y-auto min-h-[164px]" : "overflow-y-auto max-h-[100px]"}>
          <REIDataTable
            data={savedProperties}
            columns={columns}
            actions={undefined}
            customclass={hideExtraColumns ? "hide-datatable-header" : ""}
          />
        </div>
      )}
    </>
  );
};

export default SavedInAnalyser;
