import React, { useEffect, useState } from "react";
import { IAnalysedProperty, IPropertyDepreciationEstimation } from "../../../../../interface/addAnalyser";
import { useAnalysedPropertyContext } from "../AnalysedPropertyContext";
import { REICheckBox, REIDropDown, REIInputNumber } from "../../../../../components/ui";
import {
  AU_CAPITAL_CITY,
  AU_CONSTRUCTION_YEAR,
  NZ_RENOVATIONS,
  PROPERTY_QUALITY,
  PROPERTY_TYPE,
} from "../../../../../utils/constants";
import WBLogo from "../../../../../images/wb_logo.png";
import ValutTLogo from "../../../../../images/vault_logo.png";
import InputSlider from "../../../../../components/ui/slider";

interface IDepreciationEstimation {
  setIsValid: (isValid: boolean) => void;
  updateProperty: (updated: Partial<IAnalysedProperty>) => void;
}

const DepreciationEstimation: React.FC<IDepreciationEstimation> = ({ setIsValid, updateProperty }) => {
  const { analysedProperty } = useAnalysedPropertyContext();
  const [depreciationEstimation, setDepreciationEstimation] = useState<IPropertyDepreciationEstimation>();
  const [reset, setReset] = useState<boolean>(false);

  useEffect(() => {
    if (analysedProperty && !reset) {
      setDepreciationEstimation(analysedProperty.TaxMarket.PropertyDepreciationEstimation);
      setReset(true);
    }
  }, [analysedProperty]);

  useEffect(() => {
    if (depreciationEstimation) {
      if (analysedProperty?.PropertyDetail.CarportId == 1 && depreciationEstimation.NearestCapitalCity == 0) {
        setIsValid(false);
      } else {
        setIsValid(true);
      }
      updateProperty({
        ...analysedProperty,
        TaxMarket: {
          ...analysedProperty?.TaxMarket,
          PropertyDepreciationEstimation: depreciationEstimation,
          PropertyMarketFactor: analysedProperty?.TaxMarket.PropertyMarketFactor,
          PropertyTaxRate: analysedProperty?.TaxMarket.PropertyTaxRate,
        },
      });
    }
  }, [depreciationEstimation]);

  const setValues = (name: string, value: string | number) => {
    setDepreciationEstimation((prevState) => {
      if (!prevState) return undefined;

      return {
        ...prevState,
        [name]: value !== undefined ? value : 0,
      };
    });
  };

  return (
    <div>
      <div className="relative rounded-lg pl-3 pr-1 py-3 border-solid border border-gray-300 bg-white mt-2">
        <div className="default-profile-header-container">
          <h3 className="text-[var(--primary-color)] mt-0 m-1 text-lg">Depreciation estimation</h3>
        </div>
        <div className="p-4">
          <div className="flex flex-wrap mb-4">
            {analysedProperty?.PropertyDetail.CountryId == 1 && (
              <div className="w-full text-left md:w-1/2 p-2">
                <div className="flex items-center">
                  <a target="_blank" href="http://www.realestateinvestar.com.au/depreciationreport/">
                    <em className="float-left">Powered by</em>
                    <img className="float-left ml-4 mr-4 w-20" src={WBLogo}></img>
                    <div className="float-left">
                      <strong>Washington Brown</strong>
                      <br />
                      Order depreciation report
                    </div>
                  </a>
                </div>
              </div>
            )}
            {analysedProperty?.PropertyDetail.CountryId == 2 && (
              <div className="w-full  md:w-1/2 p-2">
                <div className="flex">
                  <a target="_blank" href="http://www.realestateinvestar.co.nz/depreciation/">
                    <em className="float-left">Powered by</em>
                    <img className="float-left ml-4 mr-4 w-20" src={ValutTLogo}></img>
                    <div className="float-left">
                      <strong>ValulT</strong>
                      <br />
                      Specialists in property depreciation
                    </div>
                  </a>
                </div>
              </div>
            )}
            <div className="w-full md:w-1/2 p-2">
              <div className="flex items-center">
                <REICheckBox
                  checked={depreciationEstimation?.CalculateDepreciation == "1" ? true : false}
                  onChange={(e) => setValues("CalculateDepreciation", e.checked ? "1" : "0")}
                  label="Calculate depreciation"
                ></REICheckBox>
              </div>
            </div>
          </div>
          {analysedProperty?.PropertyDetail.CountryId == 1 && (
            <>
              <div className="flex flex-wrap mb-4">
                <div className="w-full md:w-1/2 p-2">
                  <div className="flex items-center justify-evenly">
                    <label className="w-1/2 text-left" htmlFor="Rates">
                      Quality
                    </label>
                    <REIDropDown
                      data={PROPERTY_QUALITY}
                      onSelectionChange={(e) => setValues("Quality", Array.isArray(e.value) ? e.value[0] : e.value)}
                      placeholder="Select"
                      value={depreciationEstimation?.Quality}
                      optionValue="value"
                      label="level"
                      className="w-1/2"
                    ></REIDropDown>
                  </div>
                </div>
                <div className="w-full md:w-1/2 p-2">
                  <div className="flex items-center justify-evenly">
                    <label className="w-1/2 text-left" htmlFor="Rates">
                      Property type
                    </label>
                    <REIDropDown
                      data={PROPERTY_TYPE}
                      onSelectionChange={(e) =>
                        setValues("WBPropertyTypeId", Array.isArray(e.value) ? e.value[0] : e.value)
                      }
                      placeholder="Select"
                      value={depreciationEstimation?.WBPropertyTypeId}
                      optionValue="value"
                      label="type"
                      className="w-1/2"
                    ></REIDropDown>
                  </div>
                </div>
              </div>

              <div className="flex flex-wrap mb-4">
                <div className="w-full md:w-1/2 p-2">
                  <div className="flex items-center justify-center">
                    <label className="w-1/2 text-left" htmlFor="Rates">
                      Nearest capital city
                    </label>
                    <REIDropDown
                      data={AU_CAPITAL_CITY}
                      onSelectionChange={(e) =>
                        setValues("NearestCapitalCity", Array.isArray(e.value) ? e.value[0] : e.value)
                      }
                      placeholder="Select"
                      value={depreciationEstimation?.NearestCapitalCity}
                      optionValue="value"
                      label="city"
                      className="w-1/2"
                    ></REIDropDown>
                  </div>
                </div>
                <div className="w-full md:w-1/2 p-2">
                  <div className="flex items-center justify-center">
                    <label className="w-1/2 items-center text-left" htmlFor="Rates">
                      Construction year complete
                    </label>
                    <REIDropDown
                      data={AU_CONSTRUCTION_YEAR}
                      onSelectionChange={(e) =>
                        setValues("ConstructionYearComplete", Array.isArray(e.value) ? e.value[0] : e.value)
                      }
                      placeholder="Select"
                      value={depreciationEstimation?.ConstructionYearComplete}
                      optionValue="value"
                      label="year"
                      className="w-1/2"
                    ></REIDropDown>
                  </div>
                </div>
              </div>
            </>
          )}
          {analysedProperty?.PropertyDetail.CountryId == 2 && (
            <>
              <div className="flex flex-wrap mb-4">
                <div className="w-full md:w-1/2 p-2">
                  <div className="flex items-center justify-center">
                    <label className="w-1/2 items-center text-left" htmlFor="Rates">
                      Construction year complete
                    </label>
                    <REIDropDown
                      data={AU_CONSTRUCTION_YEAR}
                      onSelectionChange={(e) =>
                        setValues("ConstructionYearComplete", Array.isArray(e.value) ? e.value[0] : e.value)
                      }
                      placeholder="Select"
                      value={depreciationEstimation?.ConstructionYearComplete}
                      optionValue="value"
                      label="year"
                      className="w-1/2"
                    ></REIDropDown>
                  </div>
                </div>

                <div className="w-full md:w-1/2 p-2">
                  <div className="flex items-center justify-center">
                    <label className="w-1/2 items-center text-left" htmlFor="Rates">
                      Recent renovations
                    </label>
                    <REIDropDown
                      data={NZ_RENOVATIONS}
                      onSelectionChange={(e) =>
                        setValues("NZRecentRenovation", Array.isArray(e.value) ? e.value[0] : e.value)
                      }
                      placeholder="Select"
                      value={depreciationEstimation?.NZRecentRenovation}
                      optionValue="value"
                      label="renovationType"
                      className="w-1/2"
                    ></REIDropDown>
                  </div>
                </div>
              </div>

              <div className="flex flex-wrap mb-4">
                <div className="w-full md:w-1/2 p-2">
                  <div className="flex items-center justify-evenly">
                    <label className="w-1/3 text-left" htmlFor="Rates">
                      Your estimate of land value ($)
                    </label>
                    <InputSlider
                      value={depreciationEstimation?.NZEstimatedLandValue}
                      onChange={(e) => setValues("NZEstimatedLandValue", Array.isArray(e.value) ? e.value[0] : e.value)}
                      classNames="w-1/2 mx-2"
                      min={0}
                      max={2000000}
                    />
                    <REIInputNumber
                      value={depreciationEstimation?.NZEstimatedLandValue}
                      onValueChange={(e) => setValues("NZEstimatedLandValue", e.target.value)}
                      useGrouping={true}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default DepreciationEstimation;
