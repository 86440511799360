import React, { useEffect, useMemo, useState } from "react";
import PortfolioTrackerHeader from "../header";
import { MEMBER_KEY, MyTrackerTabs, USER_DETAIL } from "../../../utils/constants";
import { Filters } from "../Filters";
import { httpPost } from "../../../services/httpService";
import { ApiEndPoints } from "../../../utils/endpoints";
import EquityChart from "./EquityGraph";
import REIToggablePanel from "../../../components/ui/toggablePanel";
import { REIProgressSpinner } from "../../../components/ui";
import ErrorMessage from "../../../components/ui/errorMessage";
import InputSlider from "../../../components/ui/slider";
import REIToggleButton from "../../../components/ui/toggleButton";
import EquityDetail from "./EquityDetails";

const MyEquity: React.FC = () => {
  const [selectedYear, setSelectedYear] = useState<number>(Number(new Date().getFullYear().toString().slice(-2)));
  const currentYear = Number(new Date().getFullYear().toString().slice(-2));
  const [isForecastOnly, setIsForecastOnly] = useState<boolean>(false);
  const [isShowingTotals, setIsShowingTotals] = useState<boolean>(true);
  const [selectedProfileId, setSelectedProfileId] = useState<number>(0);
  const [selectedEntityId, setSelectedEntityId] = useState<number>(0);
  const memberId = localStorage.getItem(MEMBER_KEY);
  const memberDetail = JSON.parse(localStorage.getItem(USER_DETAIL) || "{}");
  const [equityData, setEquityData] = useState<IEquity>();
  const [selectedProperty, setSelectedProperty] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);

    const payload = {
      MemberId: memberId,
      UserCountryCode: memberDetail.Country,
      Entity: [],
      Profiles: [],
      MemberName: memberDetail.FirstName + " " + memberDetail.LastName,
    };
    async function getEquityData() {
      httpPost(`${ApiEndPoints.tracker}PortfolioTracker/Equity`, payload)
        .then((response) => {
          setEquityData(response?.data);
          setSelectedProperty(1);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          return <ErrorMessage message="error" />;
        });
    }
    getEquityData();
  }, []);

  const filteredData: {
    Actuals: IActual[];
    Forecast: IForecast[];
  } = useMemo(() => {
    if (!equityData) return { Actuals: [], Forecast: [] };
    const entityIdToCompare = selectedEntityId === 0 ? "" : selectedEntityId.toString();
    let filteredData: { Actuals: IActual[]; Forecast: IForecast[] } = { Actuals: [], Forecast: [] };

    if (selectedProperty === 1) {
      const totalData = isShowingTotals
        ? equityData.TotalForGraph.find((x) => x.EntityId == entityIdToCompare)?.Totals
        : equityData.TotalForGraph.find((x) => x.EntityId == entityIdToCompare)?.Portions;
      if (totalData) {
        filteredData = {
          Actuals: totalData.Actuals,
          Forecast: totalData.Forecast,
        };
      }
    } else {
      const propertyData = equityData.PropertyDetails.find(
        (property) => property.EquityPropertyDetail.PortfolioTrackerId === selectedProperty
      );

      if (propertyData) {
        if (isShowingTotals) {
          filteredData = {
            Actuals: propertyData.EquityInDetailsTotals.Actuals.map((data) => {
              return {
                MarketValue: data.CurrentValuation,
                LoanValue: data.MortgageBalance,
                Equity: data.EquityInProperty,
                Year: data.Year,
              };
            }),
            Forecast: propertyData.EquityInDetailsTotals.Forecast.map((data) => {
              return {
                MarketValue: data.CurrentValuation,
                LoanValue: data.MortgageBalance,
                Equity: data.EquityInProperty,
                Year: data.Year,
              };
            }),
          };
        } else {
          filteredData = {
            Actuals: propertyData.EquityInDetailsPortions.Actuals.map((data) => {
              return {
                MarketValue: data.CurrentValuation,
                LoanValue: data.MortgageBalance,
                Equity: data.EquityInProperty,
                Year: data.Year,
              };
            }),
            Forecast: propertyData.EquityInDetailsPortions.Forecast.map((data) => {
              return {
                MarketValue: data.CurrentValuation,
                LoanValue: data.MortgageBalance,
                Equity: data.EquityInProperty,
                Year: data.Year,
              };
            }),
          };
        }
      }
    }
    return filteredData;
  }, [equityData, selectedEntityId, selectedProfileId, isShowingTotals, selectedProperty]);

  const onPortionChange = (istotal: boolean) => {
    setIsShowingTotals(istotal);
  };

  const onSelectionChange = (EntityId: number, ProfileId: number) => {
    setSelectedProfileId(ProfileId);
    setSelectedEntityId(EntityId);
  };

  const handleSelectionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedProperty(Number(event.target.value));
  };

  const HeaderTemplate = (
    <div className="rounded-t-lg p-2 px-4 bg-gray-100 border-solid border-gray-100 border-x border-y flex gap-3 justify-around">
      <div className="flex w-1/3 content-start">
        <h3>Equity in detail</h3>
      </div>
      <div className="flex flex-row gap-3 align-middle w-1/3 content-center">
        <h3>Showing:</h3>
        <InputSlider
          value={selectedYear}
          onChange={(e) => setSelectedYear(e.value)}
          min={currentYear}
          max={currentYear + 9}
          step={1}
          classNames="self-center w-1/2"
        />
        <h3>FY-{selectedYear}</h3>
      </div>
      <div className="w-1/3 content-center text-end ">
        <REIToggleButton
          customclass="flex-end "
          index=""
          isSelected={isForecastOnly}
          offlabel="Show actuals & forecasts"
          onToggleChange={() => setIsForecastOnly(!isForecastOnly)}
          onlabel="Show only forecasts"
        />
      </div>
    </div>
  );

  const renderContent = () => {
    return (
      <>
        <div className="flex flex-col lg:flex-row flex-wrap gap-4 justify-around ">
          <div className="flex-1">
            <EquityChart trackerData={filteredData}></EquityChart>
          </div>
          <div className="text-left flex-1">
            <div className="font-semibold text-xl pb-2">Showing cash flow summary for:</div>
            <div className="overflow-y-auto h-[300px] text-left text-base text-slate-500">
              <div className="flex gap-3">
                <input
                  type="radio"
                  id="property-total"
                  name="property"
                  value={1}
                  checked={selectedProperty === 1}
                  onChange={handleSelectionChange}
                />
                <label htmlFor="property-total">Total</label>
              </div>

              {equityData &&
                equityData?.PropertyDetails.map((property, index) => {
                  const address = [
                    property.EquityPropertyDetail.StreetAddress,
                    property.EquityPropertyDetail.Suburb,
                    property.EquityPropertyDetail.District,
                    property.EquityPropertyDetail.State,
                    property.EquityPropertyDetail.Postcode,
                    `(${property.EquityPropertyDetail.Country})`,
                  ]
                    .filter(Boolean)
                    .join(", ");
                  return (
                    <>
                      <div key={property.EquityPropertyDetail.PortfolioTrackerId} className="flex gap-3 ">
                        <>
                          <input
                            type="radio"
                            id={`property-${index}`}
                            name="property"
                            value={property.EquityPropertyDetail.PortfolioTrackerId}
                            checked={selectedProperty === property.EquityPropertyDetail.PortfolioTrackerId}
                            onChange={handleSelectionChange}
                          />
                          <label htmlFor={`property-${index}`}>{address}</label>
                        </>
                      </div>
                    </>
                  );
                })}
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    equityData && (
      <div className="align-middle hide-header">
        {loading && <REIProgressSpinner></REIProgressSpinner>}
        {!loading && (
          <>
            <PortfolioTrackerHeader
              tabindex={MyTrackerTabs.MyTracker}
              tabName="My Tracker"
              children={<Filters onPortionChange={onPortionChange} onSelectionChange={onSelectionChange}></Filters>}
            ></PortfolioTrackerHeader>
            <REIToggablePanel
              contentComponent={renderContent()}
              defaultCollapse={false}
              header="Equity"
              istoggleable={false}
              customClassName="align-middle bg-gray-100"
            ></REIToggablePanel>
            <REIToggablePanel
              contentComponent={
                <EquityDetail
                  equityData={equityData}
                  isForecastOnly={isForecastOnly}
                  year={"20" + selectedYear.toString()}
                />
              }
              defaultCollapse={false}
              header=""
              customClassName="mt-2"
              istoggleable={false}
              customHeader={HeaderTemplate}
            ></REIToggablePanel>
          </>
        )}
      </div>
    )
  );
};

export default MyEquity;
