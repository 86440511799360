import { useEffect, useRef, useState } from "react";
import { httpPost, httpUpdate, useHttpGet } from "../../../services/httpService";
import { ApiEndPoints } from "../../../utils/endpoints";
import { ALERT_OPTIONS, COUNTRY, COUNTRY_OPTIONS, LOCATION_ADVANCED, OPTION_ALL } from "../../../utils/constants";

import {
  REIButton,
  REISelectButton,
  REIDivider,
  REIDropDown,
  REIProgressSpinner,
  REIErrorMessage,
  REIToastContainer,
} from "../../../components/ui";

import { AustraliaMap, NewZealandMap } from "../../../components/shared";

import LocationComponent from "./Child/LocationComponent";
import StrategyComponent from "./Child/StrategyComponent";
import PropertyTypeComponent from "./Child/PropertyTypeComponent";
import SuburbComponent from "./Child/SuburbComponent";
import { AUS_MAP_DATA, NZ_MAP_DATA } from "../../../utils/mapdata";
import { Toast } from "primereact/toast";

interface IEditSearchProps {
  id?: string;
  whenAdded?: string;
  alertId?: number;
  title?: string;
  closeDialog?: () => void;
}

const EditSearch: React.FC<IEditSearchProps> = ({ id, whenAdded, alertId, title, closeDialog }) => {
  const toastRef = useRef(null);
  const [country, setCountry] = useState(null);
  const [currentCountry, setCurrentCountry] = useState(COUNTRY.AU);
  const [alert, setAlert] = useState(alertId);
  const [currentState, setCurrentState] = useState(null);
  const [refreshData] = useState(true);

  const [selectedDropdownValue, setSelectedDropdownValue] = useState(null);
  const [keywords, setKeywords] = useState(null);
  const [strategyKeywords, setStrategyKeywords] = useState([]);
  const [loading, setLoading] = useState(false);

  const { data: optionsdata, error: optionserror } = useHttpGet(
    `${ApiEndPoints.parameter}/LocalityFilters/AU`,
    undefined,
    refreshData
  );

  const { data, error } = useHttpGet(`${ApiEndPoints.search}?SavedSearchId=${id}`, undefined, refreshData);

  useEffect(() => {
    if (data) {
      initializeFormData(data);
    }
  }, [data]);

  const initializeFormData = (data: any) => {
    const selectedData = data.Country;

    setCountry(selectedData?.LocationBasics[0].Country == COUNTRY.AU ? 1 : 2);
    setCurrentCountry(selectedData?.LocationBasics[0].Country);

    if (selectedData.LocationBasics[0].Country == COUNTRY.AU) {
      setCurrentState(AUS_MAP_DATA[selectedData.LocationBasics[0].State?.toLowerCase()]);
    } else {
      setCurrentState(NZ_MAP_DATA[selectedData.LocationBasics[0].Region?.toLowerCase()]);
    }

    setSelectedDropdownValue({
      ...selectedDropdownValue,
      //LocationComponent default values
      locationtags: setLocationTag(),
      selectedRegion: parseInt(selectedData?.LocationBasics[0].RegionId),
      selectedDistrict: parseInt(selectedData?.LocationBasics[0].DistrictId),
      locationSearchType: getLocationAdvanced(selectedData?.LocationAdvanced[0].locationSearchType),
      locationAdanced: selectedData?.LocationAdvanced[0],
      locationBasicsSuburbs: selectedData?.LocationBasicsSuburbs,
      suburbs: [],

      //StrategyComponent component default values
      priceRangeMin: parseInt(selectedData.PropertySpecifics[0].priceFrom),
      priceRangeMax: parseInt(selectedData.PropertySpecifics[0].priceTo),
      keywordStrategies: parseInt(selectedData.Strategy[0].strategyTypeId),
      keywordPhrase: parseInt(selectedData.Strategy[0].KeywordsOperandId),
      excKeywordPhrase: parseInt(selectedData.Strategy[0].ExcludedKeywordsOperandId),
      keywordPhrases: selectedData.Strategy[0].keywords,
      excKeywordPhrases: selectedData.Strategy[0].exclude,

      //PropertyTypeComponent default values
      dwellingTypes: parseInt(selectedData.PropertySpecifics[0].dwellingType),
      bedRoomsMin: parseInt(selectedData.PropertySpecifics[0].bedRoomMin),
      bedRoomsMax: parseInt(selectedData.PropertySpecifics[0].bedRoomMax),
      bathRoomsMin: parseInt(selectedData.PropertySpecifics[0].bathRoomMin),
      bathRoomsMax: parseInt(selectedData.PropertySpecifics[0].bathRoomMax),
      carportsMin: parseInt(selectedData.PropertySpecifics[0].carPortMin),
      carportsMax: parseInt(selectedData.PropertySpecifics[0].carPortMax),
      landSizesMin: parseInt(selectedData.PropertySpecifics[0].landSizeMin),
      landSizesMax: parseInt(selectedData.PropertySpecifics[0].landSizeMax),
      listingAgeMin: parseInt(selectedData.ListingDetail[0].listingAgeMin),
      listingAgeMax: parseInt(selectedData.ListingDetail[0].listingAgeMax),
      propertyYieldMin: parseInt(selectedData.ListingDetail[0].propertyYieldMin),
      propertyYieldMax: parseInt(selectedData.ListingDetail[0].propertyYieldMax),
      includeLand: selectedData.PropertySpecifics[0].includeLand == "true",
      //SuburbComponent default values
      filterRangeMin: parseInt(selectedData.SuburbStatistics[0].rentalYieldMin),
      filterRangeMax: parseInt(selectedData.SuburbStatistics[0].rentalYieldMax),
      suburbMedianRangeMin: parseInt(selectedData.SuburbStatistics[0].suburbMedianMin),
      suburbMedianRangeMax: parseInt(selectedData.SuburbStatistics[0].suburbMedianMax),
      suburbListingRangeMin: parseInt(selectedData.SuburbStatistics[0].listingMedianMin),
      suburbListingRangeMax: parseInt(selectedData.SuburbStatistics[0].listingMedianMax),
      suburbGrowthRangeMin: parseInt(selectedData.SuburbStatistics[0].oneYrGrowthMin),
      suburbGrowthRangeMax: parseInt(selectedData.SuburbStatistics[0].oneYrGrowthMax),
      populationMin: parseInt(selectedData.SuburbStatistics[0].populationMin),
      populationMax: parseInt(selectedData.SuburbStatistics[0].populationMax),
      daysOnMarketMin: parseInt(selectedData.SuburbStatistics[0].daysOnMarketMin),
      daysOnMarketMax: parseInt(selectedData.SuburbStatistics[0].daysOnMarketMax),
      vacancyRateMin: parseInt(selectedData.SuburbStatistics[0].vacancyRateMin),
      vacancyRateMax: parseInt(selectedData.SuburbStatistics[0].vacancyRateMax),
    });
    if (optionsdata) {
      const defaultKeywords = optionsdata?.StrategiesCollection?.StrategiesAndKeywords?.filter(
        (x) => x.FilterStrategyID == parseInt(selectedData.Strategy[0].strategyTypeId)
      );
      if (defaultKeywords && defaultKeywords.length > 0) {
        setKeywords(defaultKeywords[0].Keywords);
        setStrategyKeywords(defaultKeywords[0].Keywords);
      }
    }
  };

  const setLocationTag = () => {
    if (data?.Locality && data?.Locality.length > 0) {
      return data.Locality ? data.Locality.map((x) => x.Locality) : [];
    } else if (data?.Country?.LocationBasicsSuburbs && data?.Country?.LocationBasicsSuburbs.length > 0) {
      let selectedTags = [];
      if (data?.Country?.LocationBasicsSuburbs[0].SuburbId == "0") {
        const stateregion =
          data?.Country?.LocationBasics[0].Country == COUNTRY.AU
            ? AUS_MAP_DATA[data?.Country?.LocationBasics[0].State?.toLowerCase()]
            : NZ_MAP_DATA[data?.Country?.LocationBasics[0].Region?.toLowerCase()];

        if (data.Country?.LocationBasics[0].Country == COUNTRY.AU) {
          if (data.Country.LocationBasics[0].RegionId == "0" || data.Country.LocationBasics[0].RegionId == "") {
            selectedTags.push(`${stateregion?.title}: all suburbs`);
          } else {
            selectedTags.push(`${data.Country.LocationBasics[0].Region}: all suburbs`);
          }
        } else if (data.Country?.LocationBasics[0].Country == COUNTRY.NZ) {
          if (data.Country.LocationBasics[0].DistrictId == "0" || data.Country.LocationBasics[0].DistrictId == "") {
            selectedTags.push(`${stateregion?.title}: all suburbs`);
          } else {
            selectedTags.push(`${data.Country.LocationBasics[0].District}: all suburbs`);
          }
        }
        return selectedTags;
      } else {
        return data.Country.LocationBasicsSuburbs.map((x) => x.Suburb);
      }
    }
  };

  const getLocationAdvanced = (type: string) => {
    const types: { [key: string]: number } = {
      postcodeLimit: 1,
      addressLimit: 2,
      suburbLimit: 3,
      postcodeRadius: 4,
      suburbtype: 5,
    };
    return types[type] || 0;
  };

  const getLocationTypeById = (number: number): string => {
    const types: { [key: string]: number } = {
      postcodeLimit: 1,
      addressLimit: 2,
      suburbLimit: 3,
      postcodeRadius: 4,
      suburbtype: 5,
    };

    for (const key in types) {
      if (types[key] === number) {
        return key;
      }
    }
    return "";
  };

  const onAlertDropDownChange = (e) => {
    setAlert(e.value);
  };

  const handleDropdownChange = (e, dropdownName) => {
    setSelectedDropdownValue({
      ...selectedDropdownValue,
      [dropdownName]: e.value,
    });
    if (dropdownName == "keywordStrategies") {
      selectKeyWorld(undefined, e.value);
    }
  };

  const selectKeyWorld = (key: string, value: string) => {
    const defaultKeywords = optionsdata?.StrategiesCollection?.StrategiesAndKeywords?.filter((x) =>
      key ? x.Label == key : x.FilterStrategyID == value
    );
    if (defaultKeywords && defaultKeywords.length > 0) {
      setKeywords(defaultKeywords[0].Keywords);
      setStrategyKeywords(defaultKeywords[0].Label !== OPTION_ALL ? defaultKeywords[0].Keywords : []);
    }
  };

  const onStateChange = (state: string) => {
    setCurrentState(state);
  };

  const handleCountryChange = (e) => {
    setCountry(e.value);
    setCurrentCountry(e.value == 1 ? COUNTRY.AU : COUNTRY.NZ);
    setCurrentState(null);
    selectedDropdownValue.locationtags = [];
  };

  const getLabel = (optiondata: [], keyWordId: string, id: number, label: string) => {
    return optiondata.find((x) => x[keyWordId] == id)[label];
  };

  const getSavedSearchData = () => {
    const savedSearchData = {
      Country: {
        MemberID: localStorage.getItem("member_id"),
        Limit: "200",
        Title: title,
        OrderBy: "1",
        CountryCode: currentCountry,
        Strategy: [
          {
            strategyTypeId: selectedDropdownValue.keywordStrategies,
            strategyType: getLabel(
              optionsdata?.StrategiesCollection?.StrategiesAndKeywords,
              "FilterStrategyID",
              selectedDropdownValue.keywordStrategies,
              "Label"
            ),
            keywords: selectedDropdownValue?.keywordPhrases,
            KeywordsOperand: getLabel(
              optionsdata?.Strategy?.KeywordPhrase,
              "FilterKeywordSearchTypeID",
              selectedDropdownValue?.keywordPhrase,
              "Label"
            ),
            KeywordsOperandId: selectedDropdownValue?.keywordPhrase,
            exclude: selectedDropdownValue?.excKeywordPhrases,
            ExcludedKeywordsOperand: getLabel(
              optionsdata?.Strategy?.ExcKeywordPhrase,
              "FilterExcludeKeywordSearchTypeID",
              selectedDropdownValue?.excKeywordPhrase,
              "Label"
            ),
            ExcludedKeywordsOperandId: selectedDropdownValue?.excKeywordPhrase,
          },
        ],
        StrategyTypeKeywords: strategyKeywords.map((x) => {
          return { keywordId: x.FilterKeywordID, keyword: x.Label };
        }),
        PropertySpecifics: [
          {
            dwellingType: selectedDropdownValue?.dwellingTypes,
            includeLand: selectedDropdownValue?.includeLand ? "true" : "false",
            priceFrom: selectedDropdownValue?.priceRangeMin,
            priceTo: selectedDropdownValue?.priceRangeMax,
            bedRoomMin: selectedDropdownValue?.bedRoomsMin,
            bedRoomMax: selectedDropdownValue?.bedRoomsMax,
            bathRoomMin: selectedDropdownValue?.bathRoomsMin,
            bathRoomMax: selectedDropdownValue?.bathRoomsMax,
            carPortMin: selectedDropdownValue?.carportsMin,
            carPortMax: selectedDropdownValue?.carportsMax,
            landSizeMin: selectedDropdownValue?.landSizesMin,
            landSizeMax: selectedDropdownValue?.landSizesMax,
          },
        ],
        LocationAdvanced: [
          {
            locationSearchType: getLocationTypeById(selectedDropdownValue?.locationSearchType),
            limitPostcode:
              selectedDropdownValue?.locationSearchType == LOCATION_ADVANCED.POSTCODE_LIMIT
                ? selectedDropdownValue.locationAdanced.limitPostcode != ""
                  ? selectedDropdownValue.locationAdanced.limitPostcode.toString()
                  : optionsdata?.LocationAdvanced[0].limitPostcode
                : "",
            limitStreet:
              selectedDropdownValue?.locationSearchType == LOCATION_ADVANCED.ADDRESS_LIMIT
                ? selectedDropdownValue.locationAdanced.limitStreet != ""
                  ? selectedDropdownValue.locationAdanced.limitStreet.toString()
                  : optionsdata?.LocationAdvanced[0].limitStreet
                : "",
            limitSuburb:
              selectedDropdownValue?.locationSearchType == LOCATION_ADVANCED.SUBURB_LIMIT &&
              currentCountry == COUNTRY.AU
                ? selectedDropdownValue.locationAdanced.limitSuburb != ""
                  ? selectedDropdownValue.locationAdanced.limitSuburb.toString()
                  : optionsdata?.LocationAdvanced[0].limitSuburb
                : "",
            postcodeRadiusFrom:
              selectedDropdownValue?.locationSearchType == LOCATION_ADVANCED.POSTCODE_RADIUS
                ? selectedDropdownValue?.postcodeRadiusFrom
                : "",
            postcodeRadiusTo:
              selectedDropdownValue?.locationSearchType == LOCATION_ADVANCED.POSTCODE_RADIUS
                ? selectedDropdownValue?.postcodeRadiusTo
                : "",
            ofPostcode:
              selectedDropdownValue?.locationSearchType == LOCATION_ADVANCED.POSTCODE_RADIUS
                ? selectedDropdownValue.locationAdanced.ofPostcode
                : "",
            suburbRadiusFrom:
              selectedDropdownValue?.locationSearchType == LOCATION_ADVANCED.SUBURB_LIMIT
                ? selectedDropdownValue?.suburbRadiusFrom
                : "",
            suburbRadiusTo:
              selectedDropdownValue?.locationSearchType == LOCATION_ADVANCED.SUBURB_LIMIT
                ? selectedDropdownValue?.suburbRadiusTo
                : "",
            OflimitSuburb:
              selectedDropdownValue?.locationSearchType == LOCATION_ADVANCED.SUBURB_LIMIT
                ? selectedDropdownValue.locationAdanced.OflimitSuburb
                : "",
            selectedSuburbType:
              selectedDropdownValue?.locationSearchType == LOCATION_ADVANCED.SUBURB_TYPE
                ? selectedDropdownValue.locationAdanced.selectedSuburbType
                : "",
          },
        ],
        LocationBasicsSuburbs: currentState ? getLocationSuburb() : [],
        SuburbStatistics: [
          {
            suburbMedianMin: selectedDropdownValue?.suburbMedianRangeMin,
            suburbMedianMax: selectedDropdownValue?.suburbMedianRangeMax,
            vacancyRateMin: selectedDropdownValue?.vacancyRateMin,
            vacancyRateMax: selectedDropdownValue?.vacancyRateMax,
            listingMedianMin: selectedDropdownValue?.suburbListingRangeMin,
            listingMedianMax: selectedDropdownValue?.suburbListingRangeMax,
            rentalYieldMin: selectedDropdownValue?.filterRangeMin,
            rentalYieldMax: selectedDropdownValue?.filterRangeMax,
            oneYrGrowthMin: selectedDropdownValue?.suburbGrowthRangeMin,
            oneYrGrowthMax: selectedDropdownValue?.suburbGrowthRangeMax,
            populationMin: selectedDropdownValue?.populationMin,
            populationMax: selectedDropdownValue?.populationMax,
            daysOnMarketMin: selectedDropdownValue?.daysOnMarketMin,
            daysOnMarketMax: selectedDropdownValue?.daysOnMarketMax,
          },
        ],
        ListingDetail: [
          {
            propertyYieldMin: selectedDropdownValue?.propertyYieldMin,
            propertyYieldMax: selectedDropdownValue?.propertyYieldMax,
            listingAgeMin: selectedDropdownValue?.listingAgeMin,
            listingAgeMax: selectedDropdownValue?.listingAgeMax,
            showDuplicates: "false",
          },
        ],
        LocationBasics: [
          {
            Country: currentCountry,
            StateId: currentState?.id,
            State: currentState?.title,
            Region:
              currentCountry == COUNTRY.AU
                ? selectedDropdownValue?.selectedRegion == "0"
                  ? OPTION_ALL
                  : getRegionDistrictName(currentState?.id, selectedDropdownValue?.selectedRegion)?.Label
                : "",
            RegionId: currentCountry == COUNTRY.AU ? selectedDropdownValue?.selectedRegion : "",
            DistrictId: currentCountry != COUNTRY.AU ? selectedDropdownValue?.selectedDistrict : "",
            District:
              currentCountry != COUNTRY.AU
                ? selectedDropdownValue?.selectedDistrict == "0"
                  ? OPTION_ALL
                  : getRegionDistrictName(currentState?.id, selectedDropdownValue?.selectedDistrict)?.Label
                : "",
          },
        ],
        SavedSearchId: id,
      },
      Locality: !currentState ? getLocality() : [],
    };
    return savedSearchData;
  };

  const getLocationSuburb = () => {
    if (
      selectedDropdownValue?.locationBasicsSuburbs.length > 0 &&
      selectedDropdownValue?.locationBasicsSuburbs.some((x) => x.includes("all suburbs"))
    ) {
      return [
        {
          SuburbId: "0",
          Suburb: selectedDropdownValue?.locationBasicsSuburbs[0],
        },
      ];
    } else {
      return selectedDropdownValue?.locationBasicsSuburbs.map((item) => {
        return {
          SuburbId: selectedDropdownValue.suburbs.find((x) => x.Label == item).FilterSuburbID,
          Suburb: item,
        };
      });
    }
  };

  const getLocality = () => {
    let locality = [];
    if (selectedDropdownValue?.locationBasicsSuburbs.length > 0) {
      locality = selectedDropdownValue?.locationBasicsSuburbs.map((x) => {
        return { Locality: x };
      });
    }
    return locality;
  };

  const getRegionDistrictName = (stateid: number, id: number) => {
    if (currentCountry == COUNTRY.AU) {
      const data = optionsdata?.LocationBasicAUCollection?.State?.find((state: any) => state.FilterStateID === stateid);
      if (data) {
        return data.Regions.find((x) => x.FilterRegionID === id);
      }
    } else {
      const data = optionsdata?.LocationBasicNZCollection?.Regions?.find(
        (district: any) => district.FilterRegionID === stateid
      );
      if (data) {
        return data.Districts.find((x) => x.FilterDistrictID === id);
      }
    }
  };

  const saveAlert = async () => {
    const body = {
      MemberId: localStorage.getItem("member_id"),
      FilterEmailAlertSchedule: [
        {
          FilterEmailAlertScheduleId: alert,
        },
      ],
      SavedSearch: [
        {
          Id: id,
        },
      ],
    };
    await httpPost(`${ApiEndPoints.search}/EmailAlert`, body);
  };

  const SaveMySearch = async () => {
    setLoading(true);
    if (alert) {
      await saveAlert();
    }
    const body = getSavedSearchData();
    const response = await httpUpdate(ApiEndPoints.search, body);
    if (response?.error) {
      toastRef.current?.show({
        severity: "error",
        summary: "Error Message",
        detail: response.error,
        life: 3000,
      });
    } else if (response?.data) {
      toastRef.current?.show({
        severity: "success",
        summary: "Success Message",
        detail: "My saved search saved successfully.",
        life: 2000,
      });
    }
    setTimeout(() => {
      closeDialog();
      setLoading(false);
    }, 2000);
  };

  if (optionserror) {
    return (
      <div className="flex items-start">
        <REIErrorMessage message={optionserror}></REIErrorMessage>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-start">
        <REIErrorMessage message={error}></REIErrorMessage>
      </div>
    );
  }

  if (!data) {
    return (
      <div className="flex items-center">
        <REIProgressSpinner></REIProgressSpinner>
      </div>
    );
  }

  return (
    <>
      <Toast ref={toastRef} />
      <div className="grid grid-cols-12 gap-2">
        <div className="col-span-2 pt-3">Send me alerts:</div>
        <div className="col-span-3">
          <REIDropDown
            value={alert}
            onSelectionChange={onAlertDropDownChange}
            data={ALERT_OPTIONS}
            label="name"
            optionValue="code"
            placeholder="Inactive"
          />
        </div>
        <div className="col-span-5"></div>
        <div className="col-span-2 pt-3 pr-1 text-right">Created {whenAdded}</div>
      </div>
      <div className="flex flex-col lg:flex-row flex-wrap gap-2">
        <div className="flex-1">
          <h3 className="text-[var(--primary-color)]">
            Either click to select a region or search for a particular area:
          </h3>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row lg:w-full gap-2">
        <div className="flex-0 lg:w-[30%] w-full">
          <div className="grid grid-cols-12 gap-1">
            <div className="col-span-12 mb-5">
              <REISelectButton
                label="name"
                value={country}
                onChange={handleCountryChange}
                items={COUNTRY_OPTIONS}
              ></REISelectButton>
            </div>
            <div className="col-span-12">
              {country === 1 && data?.Country && (
                <div>
                  <AustraliaMap currentstate={data?.Country?.LocationBasics[0].StateId} onStateChange={onStateChange} />
                </div>
              )}
              {country !== 1 && (
                <NewZealandMap region={data?.Country?.LocationBasics[0].RegionId} onStateChange={onStateChange} />
              )}
            </div>
          </div>
        </div>

        {data && (
          <div className="flex-0 lg:w-[70%] w-full">
            <LocationComponent
              selectedState={currentState}
              optionsdata={optionsdata}
              selectedDropdownValue={selectedDropdownValue}
              handleDropdownChange={handleDropdownChange}
              country={currentCountry}
            />
            <REIDivider />
            <StrategyComponent
              selectedDropdownValue={selectedDropdownValue}
              handleDropdownChange={handleDropdownChange}
              optionsdata={optionsdata}
              strategyKeywords={strategyKeywords}
              strategyOnChange={(e) => setStrategyKeywords(e.value)}
              keywords={keywords}
            ></StrategyComponent>
            <REIDivider />
            <PropertyTypeComponent
              selectedDropdownValue={selectedDropdownValue}
              handleDropdownChange={handleDropdownChange}
              optionsdata={optionsdata}
            ></PropertyTypeComponent>
            <REIDivider />
            <SuburbComponent
              selectedDropdownValue={selectedDropdownValue}
              handleDropdownChange={handleDropdownChange}
              optionsdata={optionsdata}
            ></SuburbComponent>
            <REIDivider />
            <div className="grid grid-cols-12 gap-1">
              <div className="col-span-12 text-end">
                <REIButton label="Save" icon="pi pi-save" disabled={false} loading={loading} onClick={SaveMySearch} />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default EditSearch;
