import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { RootState } from "../store";
import { TOKEN_KEY } from "../utils/constants";

interface AuthRouteProps {
  children: React.ReactNode;
}

const AuthRoute: React.FC<AuthRouteProps> = (props) => {
  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);
  const navigate = useNavigate();

  const checkUserToken = () => {
    const usertoken = localStorage.getItem(TOKEN_KEY);
    if (!usertoken) {
      localStorage.clear();
      navigate("/login");
    }
  };

  useEffect(() => {
    checkUserToken();
  }, [navigate]);

  return <>{localStorage.getItem(TOKEN_KEY) ? props.children : null}</>;
};

export default AuthRoute;
