import { Dropdown } from "primereact/dropdown";

interface REIDropDownProps {
  value: any;
  onSelectionChange: (e: any) => void;
  data: any[];
  label: string;
  placeholder?: string;
  className?: string;
  optionValue?: string;
}

const REIDropDown: React.FC<REIDropDownProps> = ({
  value,
  onSelectionChange,
  data,
  label,
  placeholder,
  className = "w-full md:w-14rem",
  optionValue = "value",
}) => {
  return (
    <Dropdown
      value={value}
      onChange={onSelectionChange}
      options={data}
      optionLabel={label}
      placeholder={placeholder}
      style={{ fontSize: "10px" }}
      optionValue={optionValue}
      className={className}
    />
  );
};

export default REIDropDown;
