import { REIButton, REIDropDown, REIInputText } from "../../../components/ui";
import { httpPost } from "../../../services/httpService";
import { ALERT_OPTIONS } from "../../../utils/constants";
import { useRef, useState } from "react";
import { ApiEndPoints } from "../../../utils/endpoints";
import { Toast } from "primereact/toast";
import { ISaveSearch } from "../../../interface/ISaveSearch";

export interface ISaveThisSearchProps {
  closeDialog?: () => void;
  savesearch?: ISaveSearch;
}

const SaveThisSearch: React.FC<ISaveThisSearchProps> = ({ closeDialog, savesearch }) => {
  const [alert, setAlert] = useState(1);
  const [searchName, setSearchName] = useState("");
  const [loading, setLoading] = useState(false);
  const toastRef = useRef(null);

  const onAlertDropDownChange = (e) => {
    setAlert(e.value);
  };

  const saveAlert = async (id) => {
    const body = {
      MemberId: localStorage.getItem("member_id"),
      FilterEmailAlertSchedule: [
        {
          FilterEmailAlertScheduleId: alert,
        },
      ],
      SavedSearch: [
        {
          Id: id,
        },
      ],
    };
    await httpPost(`${ApiEndPoints.search}/EmailAlert`, body);
  };

  const saveThisSearch = async () => {
    savesearch.Country.Title = searchName;
    const response = await httpPost(ApiEndPoints.search, savesearch);
    if (response?.error) {
      toastRef.current?.show({
        severity: "error",
        summary: "Error Message",
        detail: response.error,
        life: 3000,
      });
    } else if (response?.data) {
      await saveAlert(response.data.SavedSearchID);
      toastRef.current?.show({
        severity: "success",
        summary: "Success Message",
        detail: "My saved search saved successfully.",
        life: 2000,
      });
    }
    setTimeout(() => {
      closeDialog();
      setLoading(false);
    }, 2000);
    closeDialog();
  };

  return (
    <>
      <Toast ref={toastRef} />
      <div className="flex flex-row mb-2">
        <label className="block font-medium">Please give this search as name.</label>{" "}
      </div>
      <div className="flex flex-row">
        <REIInputText
          type="text"
          name="searchName"
          className="w-full"
          value={searchName}
          onChange={(e) => setSearchName(e.target.value)}
        />
      </div>
      <div className="flex flex-row mb-2 mt-3">
        <label className="block font-medium">
          How often would you like to receive email alerts of properties matching this search.
        </label>
      </div>
      <div className="flex flex-row">
        <div className="w-1/3">
          <REIDropDown
            value={alert}
            onSelectionChange={onAlertDropDownChange}
            data={ALERT_OPTIONS}
            label="name"
            optionValue="code"
            placeholder="Inactive"
          />
        </div>
        <div className="w-8/12 text-end">
          <REIButton
            label="Save search"
            disabled={searchName ? false : true}
            loading={loading}
            icon="pi pi-save"
            onClick={() => {
              saveThisSearch();
            }}
          />
        </div>
      </div>
    </>
  );
};

export default SaveThisSearch;
