import { MultiSelect } from "primereact/multiselect";

interface IMultiSelectProps {
  value: any[];
  onChange: (event: any) => void;
  options: any[];
  optionLabel: string;
  placeholder?: string;
  maxSelectedLabels?: number;
  className?: string;
}

const REIMultiSelect: React.FC<IMultiSelectProps> = ({
  value,
  onChange,
  options,
  optionLabel,
  placeholder = "Select Keywords",
  maxSelectedLabels = 3,
  className,
}) => {
  return (
    <MultiSelect
      value={value}
      onChange={onChange}
      options={options}
      optionLabel={optionLabel}
      placeholder={placeholder}
      maxSelectedLabels={maxSelectedLabels}
      className={className}
    />
  );
};

export default REIMultiSelect;
