import { useEffect, useRef, useState } from "react";
import { IREIGraphDataSet } from "../../../interface/graph";
import { IEquity } from "../../../interface/propertyTracker";
import { REIProgressSpinner } from "../../../components/ui";
import { formatNumber } from "../../../utils/commonUtil";
import ScrollableGraph from "../../../components/shared/ScrollableGraph";

const EquityChart: React.FC<{
  trackerData: { Actuals: IActual[]; Forecast: IForecast[] };
}> = ({ trackerData }) => {
  const currentYear = Number(new Date().getFullYear().toString());
  const [loading, setLoading] = useState<boolean>(false);
  const [currentYearIndex, setCurrentYearIndex] = useState(0);
  const [chartData, setChartData] = useState<IREIGraphDataSet[]>([
    { label: "MarketValue", data: [], backgroundColor: ["rgba(45, 212, 191, 1)"] },
    { label: "LoanBalance", data: [], backgroundColor: ["rgba(156, 163, 175, 1)"] },
  ]);
  const [trakerIndexData, setTrakerIndexData] = useState<{ Actuals: IActual[]; Forecast: IForecast[] }>();
  const [years, setYears] = useState<string[]>([]);
  const [selectedYear, setSelectedYear] = useState<number>(0);
  const selectedYearForecast = useRef<IEquity>();
  const displayedYears = 5;
  const colorMap = {
    MarketValue: {
      0: "rgba(240, 171, 252, 1)",
      1: "rgba(240, 171, 252, 1)",
      default: "rgba(45, 212, 191, 1)",
    },
    LoanBalance: {
      0: "rgba(56, 189, 248, 1)",
      1: "rgba(56, 189, 248, 1)",

      default: "rgba(156, 163, 175, 1)",
    },
  };

  useEffect(() => {
    if (trackerData) {
      setTrakerIndexData(trackerData);
      setChartData((prev) => prev.map((data) => ({ ...data, data: [] })));
    }
  }, [trackerData]);

  useEffect(() => {
    setLoading(true);
    getChartData();
    setSelectedYear(Number(years[currentYearIndex]));

    selectedYearForecast.current = trackerData?.Forecast.find(
      (data) => data.Year === years[currentYearIndex]?.toString()
    );
    setLoading(false);
  }, [trakerIndexData, currentYearIndex, displayedYears]);

  const getChartData = () => {
    if (!trackerData) return;
    const forecastData = trackerData.Forecast;

    const newChartData = forecastData.map((item) => ({
      marketValue: Number(item.MarketValue),
      loanBalance: Number(item.LoanValue),
    }));

    setChartData((prev) =>
      prev.map((dataset) => {
        const backgroundColors: string[] = newChartData.map((_, index) => {
          return colorMap[dataset.label][index] || colorMap[dataset.label].default;
        });

        return {
          ...dataset,
          data: newChartData.map((item) => item[dataset.label === "MarketValue" ? "marketValue" : "loanBalance"]),
          backgroundColor: backgroundColors,
        };
      })
    );
    setYears(forecastData.map((item) => item.Year));

    selectedYearForecast.current = [...trackerData.Actuals, ...trackerData.Forecast]
      .filter((value, index, self) => self.indexOf(value) === index)
      .find((data) => data.Year === years[currentYearIndex]?.toString());
  };

  const customOptions = {
    plugins: {
      legend: {
        display: false,
        position: "top",
      },
      scales: {
        y: {
          grid: {
            display: false,
          },
          beginAtZero: true,
        },
        x: {
          grid: {
            display: false,
          },
        },
      },
    },
  };

  const getClassForValue = (value: string | undefined) => {
    return Number(value) < 0 ? "text-red-500" : "";
  };

  const renderGraphHeader = () => {
    return (
      <div className="flex flex-row justify-around">
        <div className="align-top w-1/4 text-wrap">
          <b>
            {` FY-${selectedYear?.toString().slice(2, 4)} 
            ${selectedYear == currentYear ? "Actuals YTD" : selectedYear < currentYear ? "Actuals" : "Forecast"}`}
          </b>
        </div>
        <div className="flex flex-col text-sm content-center bg-gray-100 border-solid border-gray-200 border-x border-y rounded-md w-3/4">
          <table className="w-full">
            <thead>
              <tr className="flex gap-5 align-bottom justify-between text-center w-full">
                <th className="w-1/4 content-end">Loan Balance</th>
                <th className="w-1/4 content-end">Market Value</th>
                <th className="w-1/4 content-end">Equity</th>
              </tr>
            </thead>
            <tbody>
              <tr className="flex gap-5 justify-around text-center w-full">
                <td className={`w-1/4 ${getClassForValue(selectedYearForecast.current?.LoanValue)}`}>
                  <b>$ {formatNumber(Math.abs(Number(selectedYearForecast.current?.LoanValue)))}</b>
                </td>
                <td className={`w-1/4 ${getClassForValue(selectedYearForecast.current?.MarketValue)}`}>
                  <b>$ {formatNumber(Math.abs(Number(selectedYearForecast.current?.MarketValue)))}</b>
                </td>
                <td className={`w-1/4 ${getClassForValue(selectedYearForecast.current?.Equity)}`}>
                  {selectedYearForecast.current?.Equity != null && (
                    <b>
                      <span className={getClassForValue(selectedYearForecast.current.Equity)}>
                        ($ {formatNumber(Math.abs(Number(selectedYearForecast.current.Equity)))})
                      </span>
                    </b>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  const renderGraphLegend = (
    <div className="bg-gray-100 border-solid border-gray-200 border-x border-y rounded-b-md p-3 m-3">
      <div className="flex justify-around">
        <div className="flex gap-2 items-center mr-2">
          <span className="w-4 h-4 bg-fuchsia-300"></span>
          <span className="text-left">
            Actual
            <br /> market value
          </span>
        </div>
        <div className="flex gap-2 items-center mr-4">
          <div className="w-4 h-4 bg-sky-400"></div>
          <span className="text-left">
            Actual
            <br /> loan balance
          </span>
        </div>
        <div className="flex gap-2 items-center mr-4">
          <div className="w-4 h-4 bg-teal-400"></div>
          <span className="text-left">
            Forecast
            <br /> market value
          </span>
        </div>
        <div className="flex gap-2 items-center mr-4">
          <div className="w-4 h-4 bg-gray-400"></div>
          <span className="text-left">
            Forecast
            <br /> loan balance
          </span>
        </div>
      </div>
    </div>
  );

  const onYearChange = (selectedYearIndex: number) => {
    setCurrentYearIndex(selectedYearIndex);
  };

  return (
    <>
      {loading && (
        <div className="flex items-center">
          <REIProgressSpinner />
        </div>
      )}

      {!loading && (
        <div className="flex flex-col">
          {renderGraphHeader()}
          <div className="mb-4">
            <ScrollableGraph
              onYearChange={onYearChange}
              data={chartData}
              displayedYears={displayedYears}
              graphType="line"
              labels={years}
              customOptions={customOptions}
            />
            {renderGraphLegend}
          </div>
        </div>
      )}
    </>
  );
};

export default EquityChart;
