import React, { useEffect, useRef, useState } from "react";
import { IMyProperties } from "../../../interface/myProperty";
import { REIButton, REIDivider, REIProgressSpinner, REIToastContainer } from "../../../components/ui";
import { RadioButton } from "primereact/radiobutton";
import PortfolioTrackerHeader from "../header";
import { MEMBER_KEY, Messages, MyTrackerTabs, USER_DETAIL } from "../../../utils/constants";
import REIToggablePanel from "../../../components/ui/toggablePanel";
import HouseIcon from "../../../public/assets/images/icon-house.png";
import BedIcon from "../../../public/assets/images/icon-bed.png";
import BathIcon from "../../../public/assets/images/icon-bath.png";
import CarIcon from "../../../public/assets/images/icon-car.png";
import { httpDelete, useHttpGets } from "../../../services/httpService";
import { ApiEndPoints } from "../../../utils/endpoints";
import { IMyPortfolio } from "../../../interface/myPortfolio";
import ViewPropertyDetails from "../../../interface/Watchlist/propertyDetails";
import REIFileUpload from "../../../components/shared/fileUpload";
import { formatCurrency, formatNumberWithDecimal } from "../../../utils/commonUtil";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import MapWithMarker from "../../../components/shared/mapWithMarker";

enum PropertyDataType {
  Forecast = "forecast",
  Actuals = "actuals",
}
interface IMarker {
  lat: number;
  lng: number;
  title: string;
}

const ManageProperty: React.FC<{ propertyData: IMyProperties; handleRefresh: () => void }> = ({
  propertyData,
  handleRefresh,
}) => {
  const addressParts = [
    propertyData.StreetAddress,
    propertyData.Suburb,
    propertyData.State,
    propertyData.Postcode,
    propertyData.Country,
  ]
    .filter((part) => part && part.trim() !== "")
    .join(", ");

  const [selectedDataFilter, setSelectedDataFilter] = useState<PropertyDataType>(PropertyDataType.Actuals);
  const memberId = localStorage.getItem(MEMBER_KEY);
  const memberDetail = JSON.parse(localStorage.getItem(USER_DETAIL) || "{}");
  const [portfolioData, setPortfolioData] = useState<IMyPortfolio>({} as IMyPortfolio);
  const [viewPropertyData, SetViewPropertyData] = useState<ViewPropertyDetails>({} as ViewPropertyDetails);
  const [isLoading, setIsLoading] = useState(true);
  const [isShowingTotals, setIsShowingTotals] = useState<boolean>(false);
  const [selectedEquityOption, setSelectedEquityOption] = useState<string>();
  const toastRef = useRef<Toast>(null);
  const [center, setCenter] = useState({ lat: 0, lng: 0 });
  const [marker, setMarker] = useState<IMarker[]>([]);
  const zoom = 15;
  const [isImageComponent, setIsImageComponent] = useState<boolean>(true);

  useEffect(() => {
    if (!propertyData) {
      console.warn("propertyData is undefined or null");
      return;
    }

    SetViewPropertyData((prevData) => ({
      ...prevData,
      PropertyDetails: {
        ...prevData.PropertyDetails,
        WatchlistID: propertyData.MemberPropertyId.toString(),
        isFromPropertyAnalyser: true,
      },
    }));

    useHttpGets(
      `${ApiEndPoints.tracker}PortfolioTracker/MyPortfolio?MemberId=${memberId}&userCountryCode=${memberDetail.Country}&PortfolioTrackerId=${propertyData.PortfolioTrackerId}`,
      undefined
    )
      .then((result) => {
        if (result?.data) {
          setPortfolioData(result?.data);

          const googleMapsUrl =
            "http://www.realestateinvestar.com.au/ME2/MyModules/IRES/GetMap.asp?m=Tamworth NSW, Australia, , , , AU"?.split(
              "?m="
            )[1];
          if (googleMapsUrl) {
            getCoordinatesFromAddress(googleMapsUrl).then((response) => {
              if (response) {
                setCenter(response);
                setMarker([{ lat: response.lat, lng: response.lng, title: googleMapsUrl }]);
              }
            });
          }
        }
      })
      .catch((error) => {
        console.error("Error fetching portfolio data:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [propertyData]);

  const getCoordinatesFromAddress = async (address: string): Promise<{ lat: number; lng: number } | null> => {
    setIsLoading(true);
    const apiKey = process.env.GOOGLE_MAPS_API_KEY;
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      address
    )}&key=${apiKey}`;

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Failed to fetch data from Google Maps API");
      }

      const data = await response.json();

      if (data.status === "OK" && data.results.length > 0) {
        const { lat, lng } = data.results[0].geometry.location;
         setIsLoading(false);
        return { lat, lng };
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  };

  const removeProperty = async () => {
    setIsLoading(true);
    await httpDelete(
      `${ApiEndPoints.tracker}PortfolioTracker/Tracker?MemberId=${memberId}&PortfolioTrackerId=${propertyData.PortfolioTrackerId}`
    )
      .then((data) => {
        if (data.status == 200) {
          toastRef.current?.show({
            severity: "success",
            summary: Messages.DELETE_SUCCESS,
            detail: "The Property has been removed from your portfolio",
            life: 2000,
          });
          handleRefresh();
        } else {
          toastRef.current?.show({
            severity: "error",
            summary: Messages.DELETE_ERROR,
            detail: data.error,
            life: 2000,
          });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toastRef.current?.show({
          severity: "error",
          summary: Messages.DELETE_ERROR,
          detail: error,
          life: 2000,
        });
      });
  };

  const handleDeletePropertyMethod = () => {
    confirmDialog({
      message: (
        <div className="text-lg">
          Are you sure you want to remove this property from your portfolio?
          <br />
          Removing this property will remove all record of its future forecast and all its details.
        </div>
      ),
      header: "Delete Confirmation",
      icon: "pi pi-exclamation-triangle",
      defaultFocus: "reject",
      acceptClassName: "p-button-danger",
      accept: () => {
        removeProperty();
      },
      reject: () => {},
    });
  };

  const onPortionChange = (istotal: boolean) => {
    setIsShowingTotals(istotal);
  };

  const renderCustomHeader = () => {
    return (
      <div className="flex flex-row justify-between bg-gray-50 border-solid border-gray-200 border-x border-y rounded-t-md p-4">
        <label className="font-semibold text-lg">Summary</label>
        <REIButton
          label="Reanalyze or edit this property"
          classNames="text-white text-sm rounded-lg p-1 border-none px-2"
          onClick={(event) => {}}
        ></REIButton>
      </div>
    );
  };

  const returnSummaryObject = () => {
    if (isShowingTotals) {
      return selectedDataFilter == PropertyDataType.Actuals
        ? portfolioData.PortfolioTrackerDetailLabel.PropertyKeyValuesTotal
        : portfolioData.PortfolioTrackerDetailLabel.PropertyKeyValuesForecastTotal;
    } else {
      return selectedDataFilter == PropertyDataType.Actuals
        ? portfolioData.PortfolioTrackerDetailLabel.PropertyKeyValuesPortion
        : portfolioData.PortfolioTrackerDetailLabel.PropertyKeyValuesForecastPortion;
    }
  };

  const currencyTemplate = (value: string) => {
    return (
      <span className={Number(value) < 0 ? "text-red-600" : ""}>
        {Number(value) < 0 ? `(${formatCurrency(-Number(value))})` : formatCurrency(Number(value))}
      </span>
    );
  };

  const renderKeyValues = () => {
    return (
      <div className="flex flex-col gap-2 md:w-2/3">
        <div className="flex md:flex-row flex-col gap-2 justify-between">
          <label className="font-semibold xl:text-lg">{"Entity: Personal Ownership".toLocaleUpperCase()}</label>
          <div className="flex align-bottom mb-2">
            <RadioButton
              inputId="forecast"
              name="actuals"
              value={PropertyDataType.Actuals}
              checked={selectedDataFilter == PropertyDataType.Actuals ? true : false}
              onChange={(e) => {
                setSelectedDataFilter(e.value);
              }}
            />
            <label htmlFor={PropertyDataType.Actuals} className="mx-2 ">
              Actuals
            </label>
            <RadioButton
              inputId="forecast"
              name="forecast"
              value={PropertyDataType.Forecast}
              checked={selectedDataFilter == PropertyDataType.Forecast ? true : false}
              onChange={(e) => {
                setSelectedDataFilter(e.value);
              }}
            />
            <label htmlFor={PropertyDataType.Forecast} className="mx-2 ">
              Forecast
            </label>
          </div>
          <div className="text-md">Added:{portfolioData.PortfolioTrackerDetailLabel.DateAdded}</div>
        </div>
        <div className="w-full flex md:flex-row flex-col gap-2">
          <div className="md:w-1/3 flex flex-row ">
            <img src={HouseIcon} alt="Image 1" className="" id="image" />
            <label htmlFor="image" className=" text-center align-top text-lg ml-2">
              {portfolioData.PortfolioTrackerDetailLabel.PropertyType}
            </label>
          </div>
          <div className="md:w-1/3  flex flex-row content-center gap-2">
            <img src={BathIcon} alt="Image 1" className="" id="bathIcon" />
            <label htmlFor="bathIcon" className=" text-center align-top text-lg mr-2">
              {portfolioData.PortfolioTrackerDetailLabel.Bath}
            </label>
            <img src={BedIcon} alt="Image 2" className="" id="bedhIcon" />
            <label htmlFor="bedhIcon" className=" text-center align-top text-lg mr-2">
              {portfolioData.PortfolioTrackerDetailLabel.Bed}
            </label>
            <img src={CarIcon} alt="Image 3" className="" id="carIcon" />
            <label htmlFor="carIcon" className=" text-center align-top text-lg mr-2">
              {portfolioData.PortfolioTrackerDetailLabel.Parking}
            </label>
          </div>
          <div className="md:w-1/3 items-center md:text-end text-start lg:text-lg">
            {`Land size: ${portfolioData.PortfolioTrackerDetailLabel.Landsize.split(" ")[0]} m²`}
          </div>
        </div>
        <REIDivider></REIDivider>
        <div className="">
          <div className="w-full flex md:flex-row flex-col gap-2 mb-3">
            <div className="w-1/2 flex flex-col text-start ">
              <span className="font-bold text-2xl">{currencyTemplate(returnSummaryObject().CurrentMarketValue)}</span>
              <span className="uppercase text-sm">Current market estimate*</span>
            </div>
            <div className="w-1/2 flex flex-col text-start">
              <span className="font-bold text-2xl">{currencyTemplate(returnSummaryObject().CapitalGrowth)}</span>
              <span className="uppercase text-sm">Capital growth</span>
            </div>
            <div className="w-1/2 flex flex-col text-start ">
              <span className="font-bold text-2xl">{currencyTemplate(returnSummaryObject().NetEquity)}</span>
              <span className="uppercase text-sm">Net equity</span>
            </div>
          </div>

          <div className="w-full flex md:flex-row flex-col gap-2 mb-3">
            <div className="w-1/2 flex flex-col text-start">
              <span className="font-bold text-2xl">
                {formatNumberWithDecimal(Number(returnSummaryObject().CapitalGrowthPercentage), 2, 2)}%
              </span>
              <span className="uppercase text-sm">Total Capital growth</span>
            </div>
            <div className="w-1/2 flex flex-col text-start ">
              <span className="font-bold text-2xl">{currencyTemplate(returnSummaryObject().PreTaxCashflow)}</span>
              <span className="uppercase text-sm">Pre-tax cash flow YTD</span>
            </div>
            <div className="w-1/2 flex flex-col text-start">
              <span className="font-bold text-2xl">
                {formatNumberWithDecimal(Number(returnSummaryObject().LVR), 2, 2)}%
              </span>
              <span className="uppercase text-sm">LVR</span>
            </div>
          </div>
          <div className="w-full flex md:flex-row flex-row gap-2 mb-3">
            <div className="w-1/2 flex flex-col text-start ">
              <span className="font-bold text-2xl">{currencyTemplate(returnSummaryObject().DSR)}</span>
              <span className="uppercase text-sm">DSR</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderImageGallery = () => {
    console.log(viewPropertyData.isFromPropertyAnalyser);
    return (
      <div className="w-full relative lg:w-1/4 max-h-64 overflow-hidden  justify-center text-center ">
        {isImageComponent && (
          <div className=" ">
            <REIFileUpload propertyData={viewPropertyData} editorMode="Basic"></REIFileUpload>
          </div>
        )}
        {!isImageComponent && center && (
          <div className="w-full h-56 object-fill">
            <MapWithMarker center={center} markers={marker} zoom={zoom} />
          </div>
        )}
        {viewPropertyData.isFromPropertyAnalyser && (
          <div className="absolute mt-[-40px] mb-[0px] right-0 flex space-x-1 " id="mapbutton">
            <REIButton icon="pi pi-image" label="" onClick={() => setIsImageComponent(true)}></REIButton>
            <REIButton
              icon="pi pi-map"
              classNames="ml-1"
              label=""
              onClick={() => setIsImageComponent(false)}
            ></REIButton>
          </div>
        )}
      </div>
    );
  };

  const renderContentComponent = () => {
    return (
      <>
        <div className="flex md:flex-row flex-col gap-2 justify-between xl:mx-20">
          {renderImageGallery()}
          {renderKeyValues()}
        </div>
        <div className="text-start">
          * Last update: {portfolioData.PortfolioTrackerDetailLabel.EstimatedMarketValueLastUpdated}
        </div>
      </>
    );
  };

  return (
    <>
      <REIToastContainer toastReference={toastRef}></REIToastContainer>
      <ConfirmDialog />
      {isLoading && <REIProgressSpinner />}
      {propertyData && !isLoading && (
        <>
          <PortfolioTrackerHeader
            tabindex={MyTrackerTabs.ManageProperty}
            tabName="My Properties"
            children={
              <>
                <div className="flex flex-row gap-2 w-full justify-between p-4">
                  <div className="flex flex-col w-full ">
                    <div className="flex text-blue-600 -mt-9 text-left">
                      <h2>{addressParts}</h2>
                    </div>
                  </div>
                  <div className="flex flex-col w-full content-center gap-2 align-top">
                    <div className="flex flex-row gap-4 justify-end">
                      <label>Showing : </label>
                      <RadioButton
                        inputId="totals"
                        name="tracker_dashboard_showing"
                        value="totals"
                        checked={selectedEquityOption ? selectedEquityOption === "totals" : true}
                        onChange={(e) => {
                          setSelectedEquityOption(e.value);
                          onPortionChange(true);
                        }}
                      />
                      <label htmlFor="tracker_dashboard_showing" className="ml-2">
                        totals
                      </label>
                      <RadioButton
                        inputId="portions"
                        name="tracker_dashboard_showing"
                        value="portions"
                        checked={selectedEquityOption === "portions"}
                        onChange={(e) => {
                          setSelectedEquityOption(e.value);
                          onPortionChange(false);
                        }}
                      />
                      <label htmlFor="tracker_dashboard_showing" className="ml-2">
                        this entity's portion
                      </label>
                    </div>
                    <REIButton
                      classNames=" no-wrap self-end"
                      onClick={() => {
                        handleDeletePropertyMethod();
                      }}
                      label="Remove this property from my portfolio"
                    ></REIButton>
                  </div>
                </div>
              </>
            }
          ></PortfolioTrackerHeader>
          <REIToggablePanel
            contentComponent={renderContentComponent()}
            defaultCollapse={true}
            header=""
            customHeader={renderCustomHeader()}
            istoggleable={false}
            customClassName="mb-2"
          ></REIToggablePanel>
          <REIToggablePanel
            header="Annual Growth Performance"
            istoggleable={false}
            contentComponent={
              <div className="flex flex-col gap-2 text-start p-2">
                <span>
                  Since <b>{portfolioData.PortfolioTrackerDetailLabel.MonthYearPurchase}</b> (the month you purchased)
                  <b> {portfolioData.PortfolioTrackerDetailLabel.Growth}%</b> <br />
                </span>
                <span>
                  <b>Average growth for property type: {portfolioData.PortfolioTrackerDetailLabel.PropertyType}</b>{" "}
                  <br />
                </span>
                <span>
                  Suburb:{portfolioData.PortfolioTrackerDetailLabel.Suburb} long term average
                  <b>
                    {" "}
                    {formatNumberWithDecimal(
                      portfolioData.PortfolioTrackerDetailLabel.PropertyGrowthStatSet.StatsSuburbLongTermGrowth * 100,
                      2,
                      2
                    )}
                    %
                  </b>{" "}
                  Postcode:
                  {portfolioData.PortfolioTrackerDetailLabel.PropertyGrowthStatSet.StatsPostcode} long term average{" "}
                  <b>
                    {formatNumberWithDecimal(
                      portfolioData.PortfolioTrackerDetailLabel.PropertyGrowthStatSet.StatsPostcodeLongTermGrowth * 100,
                      2,
                      2
                    )}
                    %
                  </b>{" "}
                  Region: {portfolioData.PortfolioTrackerDetailLabel.PropertyGrowthStatSet.StatsRegion}
                  long term average{" "}
                  <b>
                    {formatNumberWithDecimal(
                      portfolioData.PortfolioTrackerDetailLabel.PropertyGrowthStatSet.StatsRegionLongTermGrowth * 100,
                      2,
                      2
                    )}
                    %
                  </b>
                </span>
              </div>
            }
            defaultCollapse={false}
          ></REIToggablePanel>
        </>
      )}
    </>
  );
};

export default ManageProperty;
