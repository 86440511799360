import { RadioButton } from "primereact/radiobutton";
import { Slider } from "primereact/slider";
import React, { useEffect, useState } from "react";
import { REIButton, REIInputNumber } from "../../../../components/ui";
import { IAnalysisSummaryResponse, IPropertyRentalIncome } from "../../../../interface/analysisSummary";
import { formatCurrency } from "../../../../utils/commonUtil";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { MEMBER_KEY, USER_DETAIL } from "../../../../utils/constants";
import { httpUpdate } from "../../../../services/httpService";
import { ApiEndPoints } from "../../../../utils/endpoints";
import { triggerReload } from "../../../../store/slice/analyserSlice";

interface IUpdateRentDialogeProps {
  year: string | undefined;
  value: string | undefined;
  propertyRentalIncome: IPropertyRentalIncome;
  closeDialogue: () => void;
}

const UpdateRentDialoge: React.FC<IUpdateRentDialogeProps> = ({ year, value, propertyRentalIncome, closeDialogue }) => {
  const analysisSummary = useSelector((state: RootState) => state.analyser.analysisSummary as IAnalysisSummaryResponse);
  const memberDetail = JSON.parse(localStorage.getItem(USER_DETAIL) || "{}");
  const selectedYear = year?.replace("Year ", "").trim();
  const [rentType, setRentType] = useState<number>(propertyRentalIncome.isHoliday == 0 ? 1 : 0);
  const [rentOccupancy, setRentOccupancy] = useState<number>(propertyRentalIncome.NoOfWeeks);
  const [weeklyRent, setWeeklyRent] = useState<number>(propertyRentalIncome.WeeklyRent);
  const [updatedRenalIncome, setUpdatedRenalIncome] = useState<number>(parseFloat(value || "0"));

  const [noOfWeeksPeak, setNoOfWeeksPeak] = useState<number>(propertyRentalIncome.NoOfWeeksPeak);
  const [noOfWeeksShoulder, setNoOfWeeksShoulder] = useState<number>(propertyRentalIncome.NoOfWeeksShoulder);
  const [noOfWeeksOffPeak, setNoOfWeeksOffPeak] = useState<number>(propertyRentalIncome.NoOfWeeksOffPeak);

  const [occupancyPeak, setOccupancyPeak] = useState<number>(propertyRentalIncome.OccupancyPeak);
  const [occupancyShoulder, setOccupancyShoulder] = useState<number>(propertyRentalIncome.OccupancyShoulder);
  const [occupancyOffPeak, setOccupancyOffPeak] = useState<number>(propertyRentalIncome.OccupancyOffPeak);

  const [weeklyRentPeak, setWeeklyRentPeak] = useState<number>(propertyRentalIncome.RatePWPeak);
  const [weeklyRentShoulder, setWeeklyRentShoulder] = useState<number>(propertyRentalIncome.RatePWShoulder);
  const [weeklyRentOffPeak, setWeeklyRentOffPeak] = useState<number>(propertyRentalIncome.RatePWOffPeak);

  const [weeklyRentPeakSubtotal, setWeeklyRentPeakSubtotal] = useState<number>(0);
  const [weeklyRentShoulderSubtotal, setWeeklyRentShoulderSubtotal] = useState<number>(0);
  const [weeklyRentOffPeakSubtotal, setWeeklyRentOffPeakSubtotal] = useState<number>(0);

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (rentType === 0) {
      const peakSubtotal = noOfWeeksPeak * (occupancyPeak / 100) * weeklyRentPeak;
      const shoulderSubtotal = noOfWeeksShoulder * (occupancyShoulder / 100) * weeklyRentShoulder;
      const offPeakSubtotal = noOfWeeksOffPeak * (occupancyOffPeak / 100) * weeklyRentOffPeak;

      setWeeklyRentPeakSubtotal(peakSubtotal);
      setWeeklyRentShoulderSubtotal(shoulderSubtotal);
      setWeeklyRentOffPeakSubtotal(offPeakSubtotal);
      setUpdatedRenalIncome(peakSubtotal + shoulderSubtotal + offPeakSubtotal);
    } else {
      const subtotal = rentOccupancy * weeklyRent;
      setUpdatedRenalIncome(subtotal);
    }
  }, [
    rentType,
    rentOccupancy,
    weeklyRent,
    noOfWeeksPeak,
    noOfWeeksShoulder,
    noOfWeeksOffPeak,
    occupancyPeak,
    occupancyShoulder,
    occupancyOffPeak,
    weeklyRentPeak,
    weeklyRentShoulder,
    weeklyRentOffPeak,
  ]);

  const updateRentalIncome = async () => {
    setLoading(true);
    const variableBody = {
      MemberId: localStorage.getItem(MEMBER_KEY),
      MemberCountryCode: memberDetail.Country,
      LVR: analysisSummary?.Levers?.LoanValueRatio,
      LoansPrincipalInterest: analysisSummary?.LoanPrincipalInterest,
      ForecastOverrides: [
        {
          ForecastItemCode: "CashflowRent.ResidentialNumWeeks",
          Year: selectedYear,
          ThisYearOnly: 1,
          Value: rentOccupancy,
          Remove: 0,
          LoanId: 0,
        },
        {
          ForecastItemCode: "CashflowRent.ResidentialWeeklyRent",
          Year: selectedYear,
          ThisYearOnly: 1,
          Value: weeklyRent,
          Remove: 0,
          LoanId: 0,
        },
        {
          ForecastItemCode: "CashflowRent.PeakNumWeeks",
          Year: selectedYear,
          ThisYearOnly: 1,
          Value: noOfWeeksPeak,
          Remove: 0,
          LoanId: 0,
        },
        {
          ForecastItemCode: "CashflowRent.ShoulderNumWeeks",
          Year: selectedYear,
          ThisYearOnly: 1,
          Value: noOfWeeksShoulder,
          Remove: 0,
          LoanId: 0,
        },
        {
          ForecastItemCode: "CashflowRent.OffPeakNumWeeks",
          Year: selectedYear,
          ThisYearOnly: 1,
          Value: noOfWeeksOffPeak,
          Remove: 0,
          LoanId: 0,
        },
        {
          ForecastItemCode: "CashflowRent.PeakOccupancyRate",
          Year: selectedYear,
          ThisYearOnly: 1,
          Value: occupancyPeak,
          Remove: 0,
          LoanId: 0,
        },
        {
          ForecastItemCode: "CashflowRent.ShoulderOccupancyRate",
          Year: selectedYear,
          ThisYearOnly: 1,
          Value: occupancyShoulder,
          Remove: 0,
          LoanId: 0,
        },
        {
          ForecastItemCode: "CashflowRent.OffPeakOccupancyRate",
          Year: selectedYear,
          ThisYearOnly: 1,
          Value: occupancyOffPeak,
          Remove: 0,
          LoanId: 0,
        },
        {
          ForecastItemCode: "CashflowRent.PeakWeeklyRent",
          Year: selectedYear,
          ThisYearOnly: 1,
          Value: weeklyRentPeak,
          Remove: 0,
          LoanId: 0,
        },
        {
          ForecastItemCode: "CashflowRent.ShoulderWeeklyRent",
          Year: selectedYear,
          ThisYearOnly: 1,
          Value: weeklyRentShoulder,
          Remove: 0,
          LoanId: 0,
        },
        {
          ForecastItemCode: "CashflowRent.OffPeakWeeklyRent",
          Year: selectedYear,
          ThisYearOnly: 1,
          Value: weeklyRentOffPeak,
          Remove: 0,
          LoanId: 0,
        },
      ],
      PropertyAnalyserId: analysisSummary?.PropertyAnalyserId,
    };

    if (propertyRentalIncome.isResidential != rentType) {
      variableBody.ForecastOverrides.push({
        ForecastItemCode: "CashflowRent.IsResidential",
        Year: selectedYear,
        ThisYearOnly: 1,
        Value: rentType,
        Remove: 0,
        LoanId: 0,
      });
    }

    try {
      const result = await httpUpdate(`${ApiEndPoints.analyser}/AnalysisReport/`, variableBody);
      if (result.status === 200 && result.data) {
        dispatch(triggerReload());
      }
    } catch (error) {
      console.error("Error updating loan value ratio:", error);
    } finally {
      setLoading(false);
      closeDialogue();
    }
  };

  return (
    <>
      <div className="flex flex-col lg:flex-row lg:w-full gap-2">
        <div className="flex-1 w-1/5">
          <label className="text-gray-500">Rental Type</label>
        </div>
        <div className="flex w-4/5">
          <RadioButton
            inputId="longTerm"
            name="renttype"
            value={1}
            onChange={(e) => setRentType(e.value)}
            checked={rentType === 1}
          />
          <label htmlFor="renttype" className="mx-2">
            Residential long term
          </label>
          <RadioButton
            inputId="homeRental"
            name="renttype"
            value={0}
            onChange={(e) => setRentType(e.value)}
            checked={rentType === 0}
          />
          <label htmlFor="renttype" className="ml-2">
            Holiday rental
          </label>
        </div>
      </div>
      {rentType === 1 && (
        <div className="flex mt-3">
          <div className="flex w-1/2 p-1">
            <span className="w-full mt-2">Rate of occupancy (wks)</span>
            <Slider
              value={rentOccupancy}
              onChange={(e) => setRentOccupancy(Array.isArray(e.value) ? e.value[0] : e.value)}
              className="w-full mt-4"
              max={52}
            />
            <REIInputNumber
              value={rentOccupancy}
              onValueChange={(e) => setRentOccupancy(e.target.value)}
              className="ml-3 w-full"
              useGrouping={true}
              maxFractionDigits={0}
              min={0}
              max={52}
            />
          </div>

          <div className="flex w-1/2 p-1">
            <span className="w-full mt-2">Weekly rent ($)</span>
            <Slider
              value={weeklyRent}
              onChange={(e) => setWeeklyRent(Array.isArray(e.value) ? e.value[0] : e.value)}
              className="w-full mt-4"
              max={2000}
            />
            <REIInputNumber
              value={weeklyRent}
              onValueChange={(e) => setWeeklyRent(e.target.value)}
              className="ml-3 w-full"
              useGrouping={true}
              maxFractionDigits={0}
              min={0}
              max={2000}
            />
          </div>
        </div>
      )}
      {rentType === 0 && (
        <>
          <div className="flex flex-col lg:flex-row lg:w-full gap-1 mt-4">
            <div className="flex-1 w-[25%]">
              <label className="text-gray-500"></label>
            </div>
            <div className="flex-1 w-[15%]">
              <label className="text-gray-500">Peak:</label>
            </div>
            <div className="flex-1 w-[10%]">
              <label className="text-gray-500"></label>
            </div>
            <div className="flex-1 w-[15%]">
              <label className="text-gray-500">Shoulder:</label>
            </div>
            <div className="flex-1 w-[10%]">
              <label className="text-gray-500"></label>
            </div>
            <div className="flex-1 w-[15%]">
              <label className="text-gray-500">Off peak:</label>
            </div>
            <div className="flex-1 w-[10%]">
              <label className="text-gray-500"></label>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row lg:w-full gap-2 mt-4">
            <div className="flex-1 mt-2 w-[25%]">
              <label className="text-gray-500 "># of weeks</label>
            </div>
            <div className="flex-1 w-[15%] px-1">
              <Slider
                value={noOfWeeksPeak}
                onChange={(e) => setNoOfWeeksPeak(Array.isArray(e.value) ? e.value[0] : e.value)}
                className="w-full mt-4"
                max={52}
              />
            </div>
            <div className="flex-1 w-[10%]">
              <REIInputNumber
                value={noOfWeeksPeak}
                onValueChange={(e) => setNoOfWeeksPeak(e.target.value)}
                className="w-full"
                useGrouping={true}
                maxFractionDigits={0}
                min={0}
                max={52}
              />
            </div>
            <div className="flex-1 w-[15%] px-1">
              <Slider
                value={noOfWeeksShoulder}
                onChange={(e) => setNoOfWeeksShoulder(Array.isArray(e.value) ? e.value[0] : e.value)}
                className="w-full mt-4"
                max={52}
              />
            </div>
            <div className="flex-1 w-[10%]">
              <REIInputNumber
                value={noOfWeeksShoulder}
                onValueChange={(e) => setNoOfWeeksShoulder(e.target.value)}
                className="w-full"
                useGrouping={true}
                maxFractionDigits={0}
                min={0}
                max={52}
              />
            </div>
            <div className="flex-1 w-[15%] px-1">
              <Slider
                value={noOfWeeksOffPeak}
                onChange={(e) => setNoOfWeeksOffPeak(Array.isArray(e.value) ? e.value[0] : e.value)}
                className="w-full mt-4"
                max={52}
              />
            </div>
            <div className="flex-1 w-[10%]">
              <REIInputNumber
                value={noOfWeeksOffPeak}
                onValueChange={(e) => setNoOfWeeksOffPeak(e.target.value)}
                className="w-full"
                useGrouping={true}
                maxFractionDigits={0}
                min={0}
                max={52}
              />
            </div>
          </div>
          <div className="flex flex-col lg:flex-row lg:w-full gap-2 mt-4">
            <div className="flex-1 mt-2 w-[25%]">
              <label className="text-gray-500 ">Occupancy (%)</label>
            </div>
            <div className="flex-1 w-[15%] px-1">
              <Slider
                value={occupancyPeak}
                onChange={(e) => setOccupancyPeak(Array.isArray(e.value) ? e.value[0] : e.value)}
                className="w-full mt-4"
                max={100}
              />
            </div>
            <div className="flex-1 w-[10%]">
              <REIInputNumber
                value={occupancyPeak}
                onValueChange={(e) => setOccupancyPeak(e.target.value)}
                className="w-full"
                useGrouping={true}
                maxFractionDigits={0}
                min={0}
                max={100}
              />
            </div>
            <div className="flex-1 w-[15%] px-1">
              <Slider
                value={occupancyShoulder}
                onChange={(e) => setOccupancyShoulder(Array.isArray(e.value) ? e.value[0] : e.value)}
                className="w-full mt-4"
                max={100}
              />
            </div>
            <div className="flex-1 w-[10%]">
              <REIInputNumber
                value={occupancyShoulder}
                onValueChange={(e) => setOccupancyShoulder(e.target.value)}
                className="w-full"
                useGrouping={true}
                maxFractionDigits={0}
                min={0}
                max={100}
              />
            </div>
            <div className="flex-1 w-[15%] px-1">
              <Slider
                value={occupancyOffPeak}
                onChange={(e) => setOccupancyOffPeak(Array.isArray(e.value) ? e.value[0] : e.value)}
                className="w-full mt-4"
                max={100}
              />
            </div>
            <div className="flex-1 w-[10%]">
              <REIInputNumber
                value={occupancyOffPeak}
                onValueChange={(e) => setOccupancyOffPeak(e.target.value)}
                className="w-full"
                useGrouping={true}
                maxFractionDigits={0}
                min={0}
                max={100}
              />
            </div>
          </div>
          <div className="flex flex-col lg:flex-row lg:w-full gap-2 mt-4">
            <div className="flex-1 mt-2 w-[25%]">
              <label className="text-gray-500 ">Weekly rent ($)</label>
            </div>
            <div className="flex-1 w-[15%] px-1">
              <Slider
                value={weeklyRentPeak}
                onChange={(e) => setWeeklyRentPeak(Array.isArray(e.value) ? e.value[0] : e.value)}
                className="w-full mt-4"
                max={2000}
              />
            </div>
            <div className="flex-1 w-[10%]">
              <REIInputNumber
                value={weeklyRentPeak}
                onValueChange={(e) => setWeeklyRentPeak(e.target.value)}
                className="w-full"
                useGrouping={true}
                maxFractionDigits={0}
                min={0}
                max={2000}
              />
            </div>
            <div className="flex-1 w-[15%] px-1">
              <Slider
                value={weeklyRentShoulder}
                onChange={(e) => setWeeklyRentShoulder(Array.isArray(e.value) ? e.value[0] : e.value)}
                className="w-full mt-4"
                max={2000}
              />
            </div>
            <div className="flex-1 w-[10%]">
              <REIInputNumber
                value={weeklyRentShoulder}
                onValueChange={(e) => setWeeklyRentShoulder(e.target.value)}
                className="w-full"
                useGrouping={true}
                maxFractionDigits={0}
                min={0}
                max={2000}
              />
            </div>
            <div className="flex-1 w-[15%] px-1">
              <Slider
                value={weeklyRentOffPeak}
                onChange={(e) => setWeeklyRentOffPeak(Array.isArray(e.value) ? e.value[0] : e.value)}
                className="w-full mt-4"
                max={2000}
              />
            </div>
            <div className="flex-1 w-[10%]">
              <REIInputNumber
                value={weeklyRentOffPeak}
                onValueChange={(e) => setWeeklyRentOffPeak(e.target.value)}
                className="w-full"
                useGrouping={true}
                maxFractionDigits={0}
                min={0}
                max={2000}
              />
            </div>
          </div>

          <div className="flex flex-col lg:flex-row lg:w-full gap-1 mt-4 font-semibold">
            <div className="flex-1 w-[25%]">
              <label className="text-gray-500 ">Subtotal</label>
            </div>
            <div className="flex-1 w-[15%]">
              <label className="text-gray-500">{formatCurrency(weeklyRentPeakSubtotal)}</label>
            </div>
            <div className="flex-1 w-[10%]">
              <label className="text-gray-500"></label>
            </div>
            <div className="flex-1 w-[15%]">
              <label className="text-gray-500">{formatCurrency(weeklyRentShoulderSubtotal)}</label>
            </div>
            <div className="flex-1 w-[10%]">
              <label className="text-gray-500"></label>
            </div>
            <div className="flex-1 w-[15%]">
              <label className="text-gray-500">{formatCurrency(weeklyRentOffPeakSubtotal)}</label>
            </div>
            <div className="flex-1 w-[10%]">
              <label className="text-gray-500"></label>
            </div>
          </div>
        </>
      )}
      <div className="flex flex-col lg:flex-row lg:w-full gap-2 mt-4">
        <span className="font-semibold">
          Rent pa modified above = {formatCurrency(updatedRenalIncome)} (Prior to editing was
          {formatCurrency(parseFloat(value || "0"))})
        </span>
      </div>
      <div className="flex gap-2 mt-3">
        <div className="w-1/2 text-start">
          <REIButton
            label="Cancel without saving"
            disabled={false}
            loading={false}
            icon="pi pi-refresh"
            onClick={() => {
              closeDialogue();
            }}
          />
        </div>
        <div className="w-1/2 text-end">
          <REIButton
            label="Save"
            disabled={false}
            icon="pi pi-save"
            loading={loading}
            classNames="mr-1"
            onClick={() => {
              updateRentalIncome();
            }}
          />
        </div>
      </div>
    </>
  );
};

export default UpdateRentDialoge;
