import React, { useEffect, useState, useMemo } from "react";
import { useLocation } from "react-router-dom";
import LoanPurchaseCost from "./LoanPurchaseCost";
import RentAndExpense from "./RentAndExpense";
import REIStepper from "../../../components/ui/stepper";
import { httpPost, useHttpGets } from "../../../services/httpService";
import { REIProgressSpinner } from "../../../components/ui";
import { ApiEndPoints } from "../../../utils/endpoints";
import { USER_DETAIL, MEMBER_KEY } from "../../../utils/constants";
import {
  IAnalysedProperty,
  IGrowth,
  IPropertyDepreciationEstimation,
  IPropertyMarketFactor,
  IPropertyTaxRate,
} from "../../../interface/addAnalyser";
import { IMemberEntity, IPropertyAnalyserMedianRequest } from "../../../interface/analysed";
import { ILoan } from "../../../interface/addAnalyser";
import { IUserProfileDetails } from "../../../interface/userdetails";
import { formatDate } from "../../../utils/commonUtil";
import { useAnalysedPropertyContext } from "./Child/AnalysedPropertyContext";
import TaxAndMarket from "./TaxAndMarket";
import PropertyInfoCard from "./Child/PropertyDetails";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import Results from "./Results";

const useAnalysedProperty = (
  propertyAnalyserId: number | undefined,
  memberId: string,
  memberCountry: string,
  medianRequestBody?: IPropertyAnalyserMedianRequest
) => {
  const [analysedProperty, setAnalysedProperty] = useState<IAnalysedProperty | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const [useDefaultProfile, setUseDefaultProfile] = useState(false);

  useEffect(() => {
    if (!propertyAnalyserId) {
      setLoading(false);
      const initialProperty: IAnalysedProperty = {
        LoanTypeCost: {
          Loans: [
            {
              LoanId: 0,
              Name: "",
              IsPrincipal: 0,
              Amount: 2000,
              Length_Years: 0,
              Length_Months: 0,
              InterestRate: 5,
              EstablishmentFees: 500,
              ValuationFees: 500,
              OtherLoanCosts: 0,
              PropertyAnalyserid: 0,
            },
          ] as ILoan[],
          PropertyOtherPurchaseExpense: {
            CapitalInvestment: 0,
            OtherCost: 0,
            SolicitorsCost: 100,
            StampDuty: 0,
          },
          PropertyPurchaseCost: {
            CashDeposit: 10,
            EquityDeposit: 0,
            MarketValueNow: 0,
            PurchaseAmount: 0,
            isEnterPercentage: "1",
            isEnterValue: "0",
          },
        },
        PropertyDetail: {
          BathroomId: Number(medianRequestBody?.BathroomId) || 0,
          BedroomId: Number(medianRequestBody?.BedroomId) || 0,
          CarportId: Number(medianRequestBody?.CarportId || 0),
          CountryId: Number(medianRequestBody?.CountryId) || 0,
          DateAdded: medianRequestBody?.DateAdded || new Date().toLocaleDateString("en-GB"),
          Landsize: medianRequestBody?.Landsize || "0",
          MemberPropertyId: medianRequestBody?.MemberPropertyId.toString() || "0",
          ModulesId: medianRequestBody?.ModulesId || "0",
          PropertyDetailId: medianRequestBody?.PropertyDetailId || 0,
          PropertyName: medianRequestBody?.PropertyName || "",
          PropertyTypeId: Number(medianRequestBody?.PropertyTypeId) || 0,
          UserCountryId: medianRequestBody?.UserCountryId || 0,
          district: medianRequestBody?.district || "",
          locality: medianRequestBody?.locality || "",
          postcode: medianRequestBody?.postcode || "",
          state: medianRequestBody?.state || "",
          useDefault: medianRequestBody?.useDefault || "0",
          Growth: {
            value: 0,
            description: "",
            PropertyType: "",
            StatsPostcode: "",
            StatsPostcodeLongTermGrowth: 0,
            StatsRegion: "",
            StatsRegionLongTermGrowth: 0,
            StatsSuburb: "",
            StatsSuburbLongTermGrowth: 0,
          } as IGrowth,
          MemberEntityId: "0",
          MemberProfiles: [],
          PortionOwned: "0",
        },
        RentExpense: {
          PropertyAnnualExpense: {
            AgentComm: 0,
            BodyCorp: 0,
            Cleaning: 0,
            Gardening: 0,
            Insurance: 0,
            LandLease: 0,
            LettingFee: 0,
            Maintenance: 0,
            Other: 0,
            Rates: 0,
            ServiceCont: 0,
            Yr1_Expense: null,
          },
          PropertyAnnualRentalReturns: {
            NoOfWeeksOffPeak: 0,
            NoOfWeeksPeak: 0,
            NoOfWeeksShoulder: 0,
            OccupancyOffPeak: 0,
            OccupancyPeak: 0,
            OccupancyShoulder: 0,
            RateOfOccupancy: 0,
            RatePWOffPeak: 0,
            RatePWPeak: 0,
            RatePWShoulder: 0,
            WeeklyRent: 0,
            isHoliday: "0",
            isResidential: "0",
          },
        },
        TaxMarket: {
          PropertyDepreciationEstimation: {
            Quality: 0,
            NearestCapitalCity: 0,
            ConstructionYearComplete: 0,
            NZRecentRenovation: 0,
            NZEstimatedLandValue: 0,
            WBPropertyTypeId: "0",
            CalculateDepreciation: "0",
          } as IPropertyDepreciationEstimation,
          PropertyMarketFactor: {
            CapitalGrowth: 0,
            ConsumerPriceIndex: 0,
            Expense_LandTax: 0,
          } as IPropertyMarketFactor,
          PropertyTaxRate: {
            TaxRate: 0,
          } as IPropertyTaxRate,
        },
      };

      setAnalysedProperty(initialProperty);
      return;
    }

    const fetchAnalysedProperty = async () => {
      try {
        const result = await useHttpGets(
          `${ApiEndPoints.analyser}/PropertyAnalyser?MemberId=${memberId}&PropertyAnalyserId=${propertyAnalyserId}&MemberCountryCode=${memberCountry}`
        );
        if (result.status === 200 && result.data) {
          const data = result.data as IAnalysedProperty;
          setAnalysedProperty(data);
          setUseDefaultProfile(data.PropertyDetail.useDefault === "1");
        }
      } catch {
        console.error("Error fetching analysed property.");
      } finally {
        setLoading(false);
      }
    };

    fetchAnalysedProperty();
  }, [propertyAnalyserId, memberId, memberCountry]);

  return { analysedProperty, loading, useDefaultProfile };
};

const useMemberEntities = (memberId: string, memberCountry: string, memberName: string) => {
  const [memberEntities, setMemberEntities] = useState<IMemberEntity[]>([]);

  useEffect(() => {
    const fetchMemberEntities = async () => {
      try {
        const result = await useHttpGets(
          `${ApiEndPoints.tracker}/MemberEntities?memberId=${memberId}&userCountryCode=${memberCountry}&memberName=${memberName}`
        );
        if (result?.data && !result.error) {
          setMemberEntities(result.data);
        }
      } catch {
        console.error("Error fetching member entities.");
      }
    };

    fetchMemberEntities();
  }, [memberId, memberCountry, memberName]);

  return memberEntities;
};

const useUserProfile = (useDefaultProfile: boolean, memberId: string, memberCountry: string, memberName: string) => {
  const [userProfile, setUserProfile] = useState<IUserProfileDetails | undefined>(undefined);

  useEffect(() => {
    const fetchUserProfile = async () => {
      const condition = useDefaultProfile
        ? `?ModulesId=${memberId}&Country=${memberCountry}&memberName=${memberName}`
        : `/${memberCountry}`;

      try {
        const result = await useHttpGets(`${ApiEndPoints.analyser}/UserDefault${condition}`);
        if (result.status === 200 && result.data) {
          setUserProfile(result.data as IUserProfileDetails);
        }
      } catch {
        console.error("Error fetching user profile.");
      }
    };

    fetchUserProfile();
  }, [useDefaultProfile, memberId, memberCountry, memberName]);

  return userProfile;
};

const useGetMedian = (medianBody: any) => {
  const [median, setMedian] = useState(null);

  useEffect(() => {
    if (!medianBody) return;
    const fetchMedianRecords = async () => {
      try {
        const result = await httpPost(`${ApiEndPoints.analyser}/GetMedians`, medianBody);
        if (result.status === 200) {
          setMedian(result.data);
        }
      } catch (error) {
        console.error("Error fetching median records:", error);
      }
    };

    fetchMedianRecords();
  }, [medianBody]);

  return median;
};

const AddPropertyStepper: React.FC = () => {
  const [isStepOneValid, setIsStepOneValid] = useState(false);
  const [isStepTwoValid, setIsStepTwoValid] = useState(false);
  const [isStepThreeValid, setIsStepThreeValid] = useState(false);
  const location = useLocation();
  const { propertyAnalyserId, medianRequestBody } = location.state || {};
  const memberDetail = useMemo(() => JSON.parse(localStorage.getItem(USER_DETAIL) || "{}"), []);
  const memberId = localStorage.getItem(MEMBER_KEY) || "";
  const memberName = `${memberDetail.FirstName} ${memberDetail.LastName}`;
  const [medianRequest, setMedianRequest] = useState<IPropertyAnalyserMedianRequest>();
  const { setAnalysedProperty } = useAnalysedPropertyContext();

  const {
    analysedProperty,
    loading: loadingProperty,
    useDefaultProfile,
  } = useAnalysedProperty(propertyAnalyserId, memberId, memberDetail.Country, medianRequestBody);
  let Country = analysedProperty?.PropertyDetail.CountryId == 1 ? "AU" : "NZ";
  const memeberEntity = useSelector((state: RootState) => state.analyser.memeberEntity as IMemberEntity[]);

  useEffect(() => {
    if (analysedProperty) {
      setAnalysedProperty(analysedProperty);
    }
  }, [analysedProperty]);

  useEffect(() => {
    if (medianRequestBody) {
      setMedianRequest(medianRequestBody);
    } else if (analysedProperty) {
      setMedianRequest({
        BathroomId: analysedProperty.PropertyDetail.BathroomId.toString(),
        BedroomId: analysedProperty.PropertyDetail.BedroomId.toString(),
        CarportId: analysedProperty.PropertyDetail.CarportId.toString(),
        CountryId: analysedProperty.PropertyDetail.CountryId.toString(),
        DateAdded: formatDate(analysedProperty.PropertyDetail.DateAdded),
        Landsize: analysedProperty.PropertyDetail.Landsize.toString(),
        MemberPropertyId: Number(analysedProperty.PropertyDetail.MemberPropertyId),
        ModulesId: analysedProperty.PropertyDetail.ModulesId,
        PropertyDetailId: analysedProperty.PropertyDetail.PropertyDetailId,
        PropertyName: analysedProperty.PropertyDetail.PropertyName,
        PropertyTypeId: Number(analysedProperty.PropertyDetail.PropertyTypeId).toString(),
        UserCountryId: analysedProperty.PropertyDetail.UserCountryId,
        district: analysedProperty.PropertyDetail.district,
        locality: analysedProperty.PropertyDetail.locality,
        postcode: analysedProperty.PropertyDetail.postcode,
        state: analysedProperty.PropertyDetail.state,
        useDefault: analysedProperty.PropertyDetail.useDefault?.toString() || "0",
      });
    }
  }, [medianRequestBody, analysedProperty]);

  const handleUpdateProperty = (updatedProperty: Partial<IAnalysedProperty>) => {
    setAnalysedProperty((prevState) => {
      if (!prevState) return undefined;

      return {
        ...prevState,
        ...updatedProperty,
      };
    });
  };

  const steps = [
    {
      header: "Loan & purchase costs",
      content: <LoanPurchaseCost setIsValid={setIsStepOneValid} updateProperty={handleUpdateProperty} />,
      validate: () => isStepOneValid,
    },
    {
      header: "Rent & expenses",
      content: <RentAndExpense setIsValid={setIsStepTwoValid} updateProperty={handleUpdateProperty} />,
      validate: () => isStepTwoValid,
    },
    {
      header: "Tax & market",
      content: (
        <TaxAndMarket
          setIsValid={setIsStepThreeValid}
          updateProperty={handleUpdateProperty}
          memberEntities={memeberEntity}
        ></TaxAndMarket>
      ),
      validate: () => isStepThreeValid,
    },
    {
      header: "Results",
      content: <Results />,
      validate: () => true,
    },
  ];

  if (loadingProperty) {
    return <REIProgressSpinner />;
  }

  return (
    <div className="flex flex-col">
      <div className=" flex flex-row flex-wrap content-center mb-4">
        <div className="w-1/3 text-center p-3">
          <h1 className="text-orange-700">Property Analyser</h1>
          <h2>Analyse a property</h2>
        </div>
        <div className="w-2/3 justify-evenly">
          <div className="w-1/2 content-end">
            <PropertyInfoCard updateProperty={handleUpdateProperty}></PropertyInfoCard>
          </div>
        </div>
      </div>
      <div className="text-center text-base">
        This property is in {Country}, and will use all amounts specific to {Country} other than your income and tax
        rate.
      </div>
      <div className="flex-1 w-full">{analysedProperty && <REIStepper steps={steps} linear={true} />}</div>
    </div>
  );
};

export default AddPropertyStepper;
