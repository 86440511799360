import React, { useEffect, useState } from "react";
import { REIInputNumber } from "../../../../components/ui";
import { IAnalysedProperty, IPropertyPurchaseCost } from "../../../../interface/addAnalyser";
import { useAnalysedPropertyContext } from "./AnalysedPropertyContext";
import { RadioButton } from "primereact/radiobutton";
import { Slider } from "primereact/slider";

interface PurchaseDetailsProps {
  setIsValid: (isValid: boolean) => void;
  updateProperty: (updated: Partial<IAnalysedProperty>) => void;
}

export const PurchaseDetails: React.FC<PurchaseDetailsProps> = ({ setIsValid, updateProperty }) => {
  const { analysedProperty } = useAnalysedPropertyContext();
  const [loanPurchaseCost, setLoanPurchaseCost] = useState<IPropertyPurchaseCost>();
  const [cashDeposit, setCashDeposit] = useState<number>(0);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [reset, setReset] = useState<boolean>(false);

  useEffect(() => {
    if (analysedProperty && !reset) {
      setLoanPurchaseCost(analysedProperty.LoanTypeCost.PropertyPurchaseCost);
      setCashDeposit(analysedProperty.LoanTypeCost.PropertyPurchaseCost.CashDeposit);
      setReset(true);
    }
  }, [analysedProperty]);

  const setValues = (name: string, value: string | number) => {
    setLoanPurchaseCost((prevState) => {
      if (!prevState) return undefined;

      return {
        ...prevState,
        [name]: value !== undefined ? value : 0,
      };
    });
  };

  const validate = () => {
    const newErrors: { [key: string]: string } = {};
    if (!loanPurchaseCost?.PurchaseAmount || loanPurchaseCost.PurchaseAmount <= 0) {
      newErrors["PurchaseAmount"] = "Purchase amount must be greater than 0.";
    }
    if (!loanPurchaseCost?.MarketValueNow || loanPurchaseCost.MarketValueNow <= 0) {
      newErrors["MarketValueNow"] = "Market value must be greater than 0.";
    }
    setErrors(newErrors);
    setIsValid(Object.keys(newErrors).length === 0);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    if (loanPurchaseCost && validate()) {
      updateProperty({
        LoanTypeCost: {
          ...analysedProperty?.LoanTypeCost,
          PropertyPurchaseCost: loanPurchaseCost,
          Loans: analysedProperty?.LoanTypeCost.Loans || [],
          PropertyOtherPurchaseExpense: analysedProperty?.LoanTypeCost.PropertyOtherPurchaseExpense || {
            CapitalInvestment: 0,
            OtherCost: 0,
            SolicitorsCost: 0,
            StampDuty: 0,
          },
        },
      });
    }
  }, [loanPurchaseCost]);

  const calculateTotal = () => {
    if (loanPurchaseCost?.isEnterPercentage == "1") {
      setCashDeposit((loanPurchaseCost.PurchaseAmount * cashDeposit) / 100);
    } else {
      setCashDeposit(analysedProperty?.LoanTypeCost?.PropertyPurchaseCost?.CashDeposit ?? 0);
    }
  };

  return (
    <div className="w-full">
      <div className="relative rounded-lg pl-3 pr-1 py-3 border-solid border border-gray-300 bg-white mt-2">
        <div className="default-profile-header-container">
          <h3 className="text-[var(--primary-color)] mt-0 m-1 text-lg">Purchase details</h3>
        </div>
        {loanPurchaseCost && (
          <div className="mt-4">
            <div className="flex mb-3">
              <div className="flex w-1/3">
                <RadioButton
                  inputId="purachaseValueinDollar"
                  name="purchasevalue"
                  value={loanPurchaseCost.isEnterValue}
                  onChange={(e) => {
                    setValues("isEnterValue", e.checked ? "1" : "0");
                    setValues("isEnterPercentage", e.checked ? "0" : "1");
                    calculateTotal();
                  }}
                  checked={loanPurchaseCost.isEnterValue === "1"}
                />
                <label htmlFor="purachaseValueinDollar" className="ml-2">
                  Enter as $ value
                </label>
              </div>
              <div className="flex w-1/3">
                <RadioButton
                  inputId="purachaseValueinPercentage"
                  name="purchasevalue"
                  value={loanPurchaseCost.isEnterPercentage}
                  onChange={(e) => {
                    setValues("isEnterPercentage", e.checked ? "1" : "0");
                    setValues("isEnterValue", e.checked ? "0" : "1");
                    calculateTotal();
                  }}
                  checked={loanPurchaseCost.isEnterPercentage === "1"}
                />
                <label htmlFor="purachaseValueinPercentage" className="ml-2">
                  Enter as % of property purchase price
                </label>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row lg:w-full gap-2">
              <div className="flex-0 flex-col w-full lg:w-1/2 gap-2">
                <div className="flex w-full p-1">
                  <div className="w-[20%] mt-2">Deposit ({loanPurchaseCost.isEnterValue === "1" ? "$" : "%"})</div>
                  <div className="w-[40%] pr-2">
                    <Slider
                      value={cashDeposit}
                      onChange={(e) => setCashDeposit(Array.isArray(e.value) ? e.value[0] : e.value)}
                      className="w-full mt-4"
                      min={0}
                      max={loanPurchaseCost.isEnterValue === "1" ? 1000000 : 100}
                    />
                  </div>
                  <div className="w-[40%] flex px-2">
                    <REIInputNumber
                      value={cashDeposit}
                      onValueChange={(e) => setCashDeposit(e.target.value)}
                      className="w-full"
                      useGrouping={true}
                    />
                  </div>
                </div>
              </div>
              <div className="flex-0 flex-col w-full lg:w-1/2 gap-2">
                <div className="flex w-full p-1">
                  <div className="w-[20%] mt-2">Market value now ($)</div>
                  <div className="w-[40%] pr-2">
                    <Slider
                      value={loanPurchaseCost.MarketValueNow}
                      onChange={(e) => setValues("MarketValueNow", Array.isArray(e.value) ? e.value[0] : e.value)}
                      className="w-full mt-4"
                      min={0}
                      max={10000000}
                    />
                  </div>
                  <div className="w-[40%] flex px-2">
                    <REIInputNumber
                      value={loanPurchaseCost.MarketValueNow}
                      onValueChange={(e) => setValues("MarketValueNow", e.target.value)}
                      className="w-full"
                      useGrouping={true}
                      invalid={!!errors.MarketValueNow}
                    />
                  </div>
                </div>
                {errors.MarketValueNow && <div className="text-red-500 text-sm mt-1">{errors.MarketValueNow}</div>}
              </div>
            </div>
            <div className="flex flex-col lg:flex-row lg:w-full gap-2">
              <div className="flex-0 flex-col w-full lg:w-1/2 gap-2">
                <div className="flex w-full p-1">
                  <div className="w-[20%] mt-2">Purchase amount ($)</div>
                  <div className="w-[40%] pr-2">
                    <Slider
                      value={loanPurchaseCost.PurchaseAmount}
                      onChange={(e) => setValues("PurchaseAmount", Array.isArray(e.value) ? e.value[0] : e.value)}
                      className="w-full mt-4"
                      min={0}
                      max={10000000}
                    />
                  </div>
                  <div className="w-[40%] flex px-2">
                    <REIInputNumber
                      value={loanPurchaseCost?.PurchaseAmount}
                      onValueChange={(e) => setValues("PurchaseAmount", e.target.value)}
                      className="w-full"
                      useGrouping={true}
                      invalid={!!errors.PurchaseAmount}
                    />
                  </div>
                </div>
                {errors.PurchaseAmount && <div className="text-red-500 text-sm mt-1">{errors.PurchaseAmount}</div>}
              </div>
              <div className="flex-0 flex-col w-full lg:w-1/2 gap-2"></div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PurchaseDetails;
