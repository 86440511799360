import React, { useEffect, useRef, useState } from "react";
import { Form, Field, FormSpy } from "react-final-form";
import { httpPost, useHttpGets } from "../../services/httpService";
import { ApiEndPoints } from "../../utils/endpoints";
import { MEMBER_KEY, Messages } from "../../utils/constants";
import { REIInputText, REIProgressSpinner, REIToastContainer } from "../../components/ui";
import { Toast } from "primereact/toast";

interface IContactSummaryData {
  id: string;
  firstName: string;
  lastName: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
}

const ContactSummary: React.FC<{ isEditMode: boolean; isValid: (isValid: boolean) => void }> = ({
  isEditMode,
  isValid,
}) => {
  const memberId = localStorage.getItem(MEMBER_KEY);
  const contactSummaryEndpoint = `${ApiEndPoints.myaccount}/GetContactInfo?Id=${memberId}`;
  const updateContactSummaryEndpoint = `${ApiEndPoints.myaccount}/UpdateContactInfo`;
  const contactData = useRef<IContactSummaryData>({} as IContactSummaryData);
  const [initialContactData, setInitialContactData] = useState<IContactSummaryData>();
  const [refreshData, setRefreshData] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const toastRef = useRef<Toast>(null);

  useEffect(() => {
    setLoading(true);
    useHttpGets(contactSummaryEndpoint)
      .then((result) => {
        if (result?.data) {
          contactData.current = result?.data;
          setInitialContactData(result?.data);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [refreshData]);

  useEffect(() => {
    async function UpdateContact() {
      setLoading(true);
      await httpPost(updateContactSummaryEndpoint, contactData.current).then((response) => {
        if (response.status == 200) {
          setRefreshData(!refreshData);
          toastRef.current?.show({
            severity: "success",
            summary: Messages.SUCCESS,
            detail: "Contact successfully saved!",
            life: 2000,
          });
        } else {
          toastRef.current?.show({
            severity: "error",
            summary: Messages.ERROR,
            detail: response.error,
            life: 2000,
          });
        }
      });
    }

    if (
      isEditMode != undefined &&
      !isEditMode &&
      contactData.current &&
      contactData.current.firstName != null &&
      contactData.current.lastName != null
    ) {
      UpdateContact();
    } else {
    }
  }, [isEditMode]);

  const onChangeHandler = (form) => {
    contactData.current = form.getState().values;
    if (form.getState().values.firstName != null && form.getState().values.lastName != null) {
      isValid(true);
    } else {
      isValid(false);
    }
  };

  return (
    <>
      <REIToastContainer toastReference={toastRef}></REIToastContainer>
      <div className="text-center">{loading && <REIProgressSpinner />}</div>
      {initialContactData && !loading && (
        <Form
          onSubmit={() => {}}
          initialValues={initialContactData}
          validate={(values) => {
            const errors = {
              id: undefined,
              firstName: undefined,
              lastName: undefined,
              address1: undefined,
              address2: undefined,
              city: undefined,
              state: undefined,
              country: undefined,
              postalCode: undefined,
            };
            if (!values.firstName) {
              errors.firstName = "FirstName Required";
            }
            if (!values.lastName) {
              errors.lastName = "LastName Required";
            }

            return errors;
          }}
          render={({ form }) => (
            <form>
              <FormSpy subscription={{ values: true }}>
                {() => {
                  onChangeHandler(form);
                  return null;
                }}
              </FormSpy>
              <div className="px-2">
                <div className="flex flex-col md:flex-row p-1 ">
                  <div className="flex-0 md:w-1/6 w-full content-center">
                    <span className="font-semibold">First Name:</span>
                  </div>
                  <div className="flex-0 md:w-1/3 w-full content-center break-words">
                    <Field name="firstName">
                      {({ input, meta }) => (
                        <>
                          <REIInputText
                            hidden={!isEditMode}
                            type="text"
                            className={`p-inputtext-sm ${meta.error ? "border-red-500" : ""}`}
                            {...input}
                          />
                          {meta.error && <pre className="text-red-500">{meta.error}</pre>}
                        </>
                      )}
                    </Field>
                    <label hidden={isEditMode}>{initialContactData.firstName}</label>
                  </div>
                  <div className="flex-0 md:w-1/6 w-full content-center">
                    <span className="font-semibold">Last Name:</span>
                  </div>
                  <div className="flex-0 md:w-1/3 w-full content-center break-words">
                    <Field name="lastName">
                      {({ input, meta }) => (
                        <>
                          <REIInputText
                            hidden={!isEditMode}
                            type="text"
                            className={`p-inputtext-sm ${meta.error ? "border-red-500" : ""}`}
                            {...input}
                          />
                          {meta.error && <pre className="text-red-500">{meta.error}</pre>}
                        </>
                      )}
                    </Field>
                    <label hidden={isEditMode}>{initialContactData.lastName}</label>
                  </div>
                </div>
                <div className="flex flex-col md:flex-row p-1 border-solid border border-x-0 border-b-0 border-gray-300">
                  <div className="flex-0 md:w-1/6 w-full content-center">
                    <span className="font-semibold">Address:</span>
                  </div>
                  <div className="flex-0 md:w-1/3 w-full content-center break-words">
                    <Field name="address1">
                      {({ input }) => (
                        <REIInputText hidden={!isEditMode} type="text" className="p-inputtext-sm" {...input} />
                      )}
                    </Field>
                    <label hidden={isEditMode}>{initialContactData.address1}</label>
                  </div>
                  <div className="flex-0 md:w-1/6 w-full content-center">
                    <span className="font-semibold">City:</span>
                  </div>
                  <div className="flex-0 md:w-1/3 w-full content-center break-words">
                    <Field name="city">
                      {({ input }) => (
                        <REIInputText hidden={!isEditMode} type="text" className="p-inputtext-sm" {...input} />
                      )}
                    </Field>
                    <label hidden={isEditMode}>{initialContactData.city}</label>
                  </div>
                </div>
                <div className="flex flex-col md:flex-row p-1 border-solid border border-x-0 border-b-0 border-gray-300">
                  <div className="flex-0 md:w-1/6 w-full content-center">
                    <span className="font-semibold">State:</span>
                  </div>
                  <div className="flex-0 md:w-1/3 w-full content-center break-words">
                    <Field name="state">
                      {({ input }) => (
                        <REIInputText hidden={!isEditMode} type="text" className="p-inputtext-sm" {...input} />
                      )}
                    </Field>
                    <label hidden={isEditMode}>{initialContactData.state}</label>
                  </div>
                  <div className="flex-0 md:w-1/6 w-full content-center">
                    <span className="font-semibold">Postal Code:</span>
                  </div>
                  <div className="flex-0 md:w-1/3 w-full content-center break-words">
                    <Field name="postalCode">
                      {({ input }) => (
                        <REIInputText hidden={!isEditMode} type="text" className="p-inputtext-sm" {...input} />
                      )}
                    </Field>
                    <label hidden={isEditMode}>{initialContactData.postalCode}</label>
                  </div>
                </div>
                <div className="flex flex-col md:flex-row p-1 border-solid border border-x-0 border-gray-300">
                  <div className="flex-0 md:w-1/6 w-full content-center">
                    <span className="font-semibold">Country:</span>
                  </div>
                  <div className="flex-0 md:w-1/3 w-full content-center break-words">
                    <Field name="country">
                      {({ input }) => (
                        <REIInputText hidden={!isEditMode} type="text" className="p-inputtext-sm" {...input} />
                      )}
                    </Field>
                    <label hidden={isEditMode}>{initialContactData.country}</label>
                  </div>
                </div>
              </div>
            </form>
          )}
        />
      )}
    </>
  );
};

export default ContactSummary;
