import React, { useEffect, useState, useCallback } from "react";
import { IAnalysisSummaryResponse } from "../../../../interface/analysisSummary";
import { REIProgressSpinner } from "../../../../components/ui";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import EditableDataTable from "../../../../components/shared/editableDataTable";
import { triggerReload } from "../../../../store/slice/analyserSlice";
import { ForecastItemCode, MEMBER_KEY, USER_DETAIL, YEARS } from "../../../../utils/constants";
import { httpUpdate } from "../../../../services/httpService";
import { ApiEndPoints } from "../../../../utils/endpoints";

const OverviewSummary: React.FC<{
  editFlag: boolean;
  visibleYears: { label: string; defaultShow: boolean }[];
  resetEditFlag: () => void;
}> = ({ editFlag, visibleYears, resetEditFlag }) => {
  const analysisSummary = useSelector((state: RootState) => state.analyser.analysisSummary as IAnalysisSummaryResponse);
  const [overviewData, setOverviewData] = useState<{ [key: string]: any }>({});
  const [loading, setLoading] = useState(false);
  const memberDetail = JSON.parse(localStorage.getItem(USER_DETAIL) || "{}");

  const dispatch = useDispatch();

  const didValueChange = useCallback(
    (itemCode: string, year: number) =>
      analysisSummary?.ForecastOverrides?.some(
        (forecast) => forecast.ForecastItemCode === itemCode && forecast.Year === year
      ),
    [analysisSummary]
  );

  const didAnyValueChange = useCallback(
    (itemCode: string) =>
      analysisSummary?.ForecastOverrides?.some((forecast) => forecast.ForecastItemCode === itemCode),
    [analysisSummary]
  );

  const forectedForFutureYear = useCallback(
    (itemCode: string, year: number) =>
      analysisSummary?.ForecastOverrides?.some(
        (forecast) => forecast.ForecastItemCode === itemCode && forecast.Year === year && forecast.ThisYearOnly === 0
      ),
    [analysisSummary]
  );

  const mapValuesToYears = (
    overview: { [key: string]: any },
    forecastCode: string,
    prefix: string,
    editableYears: number[]
  ) => {
    const yearsMap: {
      [key: string]: { value: string; editable: boolean; didValueChange: boolean; forcastedForFutureYears: boolean };
    } = {};
    YEARS.forEach((year, index) => {
      const yearLabel = year.label.replace("Year ", "Yr");
      yearsMap[year.label] = {
        value: yearLabel.includes("Today") ? "" : overview[`${prefix}${yearLabel}`]?.toString(),
        editable: editableYears?.includes(index),
        didValueChange: didValueChange(forecastCode, index),
        forcastedForFutureYears: forectedForFutureYear(forecastCode, index),
      };
    });
    return yearsMap;
  };

  var initializeSummaryData = (analysisSummary: IAnalysisSummaryResponse) => ({
    "Total deductions $": {
      type: "currency",
      values: mapValuesToYears(analysisSummary.AnalysisSummary, "", "TotalDeduction", []),
    },
    "Net profit/loss $": {
      type: "currency",
      values: mapValuesToYears(analysisSummary.AnalysisSummary, "", "NetProfitLoss", []),
    },
    "Tax refund $": {
      type: "currency",
      values: mapValuesToYears(analysisSummary.AnalysisSummary, "", "TaxRefund", []),
    },
    "Property market value $": {
      type: "currency",
      forecastOverrideKey: ForecastItemCode.OverviewSummary,
      anyCellValueChange: didAnyValueChange(ForecastItemCode.OverviewSummary),
      values: mapValuesToYears(
        analysisSummary.LandTax,
        ForecastItemCode.OverviewSummary,
        "LandTax",
        [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
      ),
    },
  });

  const footer = (analysisSummary: IAnalysisSummaryResponse) =>
    analysisSummary && {
      "After tax cash flow p/a $": {
        type: "currency",
        values: mapValuesToYears(analysisSummary.AnalysisSummary, "", "PostTaxCashflowPA", []),
      },
      "After tax cash flow p/w $": {
        type: "currency",
        values: mapValuesToYears(analysisSummary.AnalysisSummary, "", "PostTaxCashflowPW", []),
      },
    };

  useEffect(() => {
    if (analysisSummary) {
      setOverviewData(initializeSummaryData(analysisSummary));
    }
  }, [analysisSummary]);

  const onEdit = async (rowKey: string, year: string, value: string) => {
    const previousValue = overviewData[rowKey].values[year].value;
    if (parseFloat(previousValue) === parseFloat(value)) {
      return;
    }
    setLoading(true);
    const variableBody = {
      MemberId: localStorage.getItem(MEMBER_KEY),
      MemberCountryCode: memberDetail.Country,
      LVR: analysisSummary?.Levers?.LoanValueRatio,
      LoansPrincipalInterest: analysisSummary?.LoanPrincipalInterest,
      ForecastOverrides: [
        {
          ForecastItemCode: ForecastItemCode.OverviewSummary,
          Year: year.replace("Year ", "").trim(),
          ThisYearOnly: 1,
          Value: parseFloat(value).toString(),
          Remove: 0,
          LoanId: 0,
        },
      ],
      PropertyAnalyserId: analysisSummary?.PropertyAnalyserId,
    };
    updateForecastValue(variableBody, true);
    setOverviewData((prevData) => ({
      ...prevData,
      [rowKey]: {
        ...prevData[rowKey],
        values: {
          ...prevData[rowKey].values,
          [year]: { ...prevData[rowKey].values[year], value },
        },
      },
    }));
  };

  const onForecastEdit = async (rowKey: string, year: string, value: string, forecastItemCode: string) => {
    setLoading(true);
    var variableBody = {};
    variableBody = {
      MemberId: localStorage.getItem(MEMBER_KEY),
      MemberCountryCode: memberDetail.Country,
      LVR: analysisSummary?.Levers?.LoanValueRatio,
      LoansPrincipalInterest: analysisSummary?.LoanPrincipalInterest,
      ForecastOverrides:
        year === "Year 10"
          ? analysisSummary.ForecastOverrides.map((forecast) => {
              return {
                ...forecast,
                Remove: 1,
              };
            })
          : [
              {
                ForecastItemCode: forecastItemCode,
                Year: year.replace("Year ", "").trim(),
                ThisYearOnly: 0,
                Value: parseFloat(value).toString(),
                Remove: 0,
                LoanId: 0,
              },
            ],
      PropertyAnalyserId: analysisSummary?.PropertyAnalyserId,
    };
    updateForecastValue(variableBody, false);
  };

  const updateForecastValue = async (variableBody: object, updateSingleValue: boolean) => {
    try {
      const result = await httpUpdate(`${ApiEndPoints.analyser}/AnalysisReport/`, variableBody);

      if (result.status === 200 && result.data) {
        dispatch(triggerReload());

        !updateSingleValue && resetEditFlag();
      }
    } catch (error) {
      console.error("Error updating loan value ratio:", error);
    } finally {
      setLoading(false);
    }
  };

  if (!analysisSummary || loading) {
    return (
      <div className="w-full text-center">
        <REIProgressSpinner />
      </div>
    );
  }

  return (
    overviewData && (
      <EditableDataTable
        title="Summary"
        data={overviewData}
        years={visibleYears.map((year) => year.label)}
        isEditable={editFlag}
        onForecastValueChange={onForecastEdit}
        onEdit={onEdit}
        footer={footer(analysisSummary)}
      />
    )
  );
};

export default OverviewSummary;
