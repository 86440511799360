import AddNewProperty from "./Child/AddNewProperty";
import REIToggablePanel from "../../components/ui/toggablePanel";
import AnalyserWatchList from "./Child/AnalyserWatchList";
import SavedAnalyser from "./SaveAnalyser";
import { REIButton, REIDialoge } from "../../components/ui";
import { ReactElement, useCallback, useState } from "react";
import MyDefaultProfile from "../Search/Child/defaultProfile";

const Index = () => {
  const [showDefaultProfile, setShowDefaultProfile] = useState<boolean>(false);

  const hideDefaultProfile = useCallback(() => {
    setShowDefaultProfile(false);
  }, []);

  const headerTemplate = (options: any): ReactElement<any, any> => {
    const className = `${options.className} justify-content-space-between`;

    return (
      <div className={className}>
        <div className="flex align-items-center gap-2">
          <span className="font-bold">Add a new property</span>
          {/* <span className=" text-sm ">(Either click to select a region or search for a particular area)</span> */}
        </div>
        <div>
          <REIButton
            icon="pi pi-user-edit"
            label="My default profile"
            classNames="p-button-link"
            onClick={() => setShowDefaultProfile(true)}
          />
          {options.togglerElement}
        </div>
      </div>
    );
  };

  return (
    <>
      <REIDialoge
        showHeader={true}
        header={`Edit Default Profile`}
        visible={showDefaultProfile}
        handleEditClick={hideDefaultProfile}
        position="top"
        style={{ width: "60vw" }}
        customClass="edit-default-profile"
        contentComponent={<MyDefaultProfile closeDialog={hideDefaultProfile} />}
      ></REIDialoge>
      <div className="flex flex-col lg:flex-row flex-wrap gap-2">
        <div className="flex flex-col lg:flex-row lg:w-full gap-2">
          <div className="flex-0 lg:w-[60%] w-full">
            <REIToggablePanel
              header="Add a new property"
              istoggleable={false}
              defaultCollapse={false}
              customHeader={headerTemplate}
              contentComponent={<AddNewProperty />}
            />
          </div>
          <div className="flex-0 lg:w-[40%] w-full">
            <REIToggablePanel
              header="My Investar Search watchlist"
              istoggleable={true}
              defaultCollapse={false}
              contentComponent={<AnalyserWatchList />}
            />
          </div>
        </div>
        <div className="flex flex-col lg:flex-row lg:w-full gap-2">
          <div className="flex-0 w-full">
            <REIToggablePanel
              header="What I’ve analysed"
              istoggleable={false}
              defaultCollapse={false}
              contentComponent={<SavedAnalyser />}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
