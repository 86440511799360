import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Messages } from "primereact/messages";
import { ICashFlow } from "../../../../interface/cashFlow";
import { REIButton, REIDialoge, REIDropDown, REIInputText } from "../../../../components/ui";
import DatePicker from "../../../../components/ui/calender";
import { getDateStringWithGivenFormat, nth } from "../../../../utils/commonUtil";
import { MEMBER_KEY, USER_DETAIL } from "../../../../utils/constants";
import { httpPost } from "../../../../services/httpService";
import { ApiEndPoints } from "../../../../utils/endpoints";

interface ILoanPaymentFormProps {
  cashFlowData: ICashFlow;
  onCancel: () => void;
  year: string;
}

interface IPaymentRow {
  id: number;
  date: string;
  loan: string;
  amount: number;
  notes: string;
  type: PaymentType;
  invalidFields?: string[];
  isRowEditable: boolean;
}

enum PaymentType {
  Principal = "Principal",
  Interest = "Interest",
}

const EditLoan: React.FC<ILoanPaymentFormProps> = ({ cashFlowData, onCancel, year }) => {
  const [selectedProperty, setSelectedProperty] = useState<string>("");
  const [principalData, setPrincipalData] = useState<IPaymentRow[]>([
    {
      id: 0,
      date: "",
      loan: "",
      amount: 0,
      notes: "",
      type: PaymentType.Principal,
      invalidFields: [],
      isRowEditable: true,
    },
  ]);
  const [intrestData, setIntrestData] = useState<IPaymentRow[]>([
    {
      id: 0,
      date: "",
      loan: "",
      amount: 0,
      notes: "",
      type: PaymentType.Interest,
      invalidFields: [],
      isRowEditable: true,
    },
  ]);
  const [filteredPrincipalData, setFilteredPrincipalData] = useState<IPaymentRow[]>([]);
  const [filteredIntrestData, setFilteredIntrestData] = useState<IPaymentRow[]>([]);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);
  const toastRef = useRef(null);
  const memberId = localStorage.getItem(MEMBER_KEY);
  const memberDetail = JSON.parse(localStorage.getItem(USER_DETAIL) || "{}");
  const [tableDataByProperty, setTableDataByProperty] = useState<{
    [propertyId: string]: { principal: IPaymentRow[]; interest: IPaymentRow[] };
  }>({});

  useEffect(() => {
    if (selectedProperty) {
      const savedData = tableDataByProperty[selectedProperty];

      const defaultData = (type: PaymentType) => ({
        id: 0,
        date: "",
        loan: "",
        amount: 0,
        notes: "",
        type,
        isRowEditable: true,
      });

      if (!savedData) {
        setPrincipalData([defaultData(PaymentType.Principal)]);
        setIntrestData([defaultData(PaymentType.Interest)]);
        return;
      }

      setIntrestData(savedData.interest.length > 0 ? savedData.interest : [defaultData(PaymentType.Interest)]);
      setPrincipalData(savedData.principal.length > 0 ? savedData.principal : [defaultData(PaymentType.Principal)]);
    }
  }, [selectedProperty]);

  const hideDefaultProfile = () => {
    setShowConfirmationDialog(false);
  };

  const showToast = (severity: string, summary: string, detail: string) => {
    toastRef.current?.show({
      severity,
      summary,
      detail,
      life: 2000,
      sticky: true,
    });
  };

  const propertyOptions = cashFlowData.PropertyDetails.map((property) => ({
    label: [
      property.PropertyDetail.StreetAddress,
      property.PropertyDetail.Suburb,
      property.PropertyDetail.State,
      property.PropertyDetail.Postcode,
    ]
      .filter(Boolean)
      .join(", "),
    value: property.PropertyDetail.PortfolioTrackerId,
  }));

  const getLoanOptionsForProperty = (propertyId: number) => {
    const selectedPropertyDetail =
      cashFlowData.PropertyDetails.find((property) => property.PropertyDetail.PortfolioTrackerId === propertyId)
        ?.Details.CashflowLoans.Forecast.flat()
        .filter((data) => data.Year == year) || [];

    if (selectedPropertyDetail) {
      const loanEntry = selectedPropertyDetail.map((loan, index) => {
        let loanName = loan.Name;
        if (!loanName) {
          loanName = `${index + 1}${nth(index + 1)} loan`;
        }
        return {
          label: loanName,
          value: loan.LoanId.toString(),
        };
      });
      return loanEntry;
    }
    return [];
  };

  const loanOptions = getLoanOptionsForProperty(Number(selectedProperty));

  const validateData = (type?: string): boolean => {
    let isValid: boolean = true;

    let validationFields: string[] = [];

    if (selectedProperty == "") {
      isValid = false;
      if (toastRef.current) {
        showToast("error", "Error", "Please select a property");
      }
    }
    const rowsToValidate = type
      ? type == PaymentType.Principal
        ? principalData.filter((data) => data.type == type)
        : intrestData.filter((data) => data.type == type)
      : [
          ...principalData.filter((data) => data.isRowEditable == false),
          ...intrestData.filter((data) => data.isRowEditable == false),
        ];

    const updatedData = rowsToValidate.map((row) => {
      let validationFieldName: string[] = [];
      if (!row.date || !row.amount) {
        if (!row.date) {
          validationFields.push("Date");
          validationFieldName.push("date");
        }

        if (!row.amount || row.amount == 0) {
          validationFields.push("Amount");
          validationFieldName.push("amount");
        }

        isValid = false;
      }

      row.invalidFields = validationFieldName;
      return { ...row, invalidFields: validationFieldName };
    });
    if (type) type === PaymentType.Principal ? setPrincipalData(updatedData) : setIntrestData(updatedData);

    validationFields.length > 0 ? validationMessage([...new Set(validationFields)]) : "";
    if (isValid) {
      return true;
    } else {
      return false;
    }
  };

  const handleAddRow = (index: number, type: PaymentType) => {
    if (validateData(type)) {
      const newRow: IPaymentRow = {
        id: type == PaymentType.Principal ? principalData.length : intrestData.length,
        date: "",
        loan: "",
        amount: 0,
        notes: "",
        type: type,
        invalidFields: [],
        isRowEditable: true,
      };

      let updatedTableData;
      if (type === PaymentType.Principal) {
        updatedTableData = principalData.map((item) => ({
          ...item,
          isRowEditable: false,
        }));
      } else {
        updatedTableData = intrestData.map((item) => ({
          ...item,
          isRowEditable: false,
        }));
      }

      if (type === PaymentType.Interest) {
        index = intrestData.length - 1;
      }

      const newTableData = [...updatedTableData.slice(0, index), newRow, ...updatedTableData.slice(index)];
      console.log(newTableData);

      if (type === PaymentType.Principal) {
        setPrincipalData(newTableData);
      } else {
        setIntrestData(newTableData);
      }

      handleSaveDataForProperty(selectedProperty);
    }
  };

  const saveLoanData = async () => {
    const tableData = [...filteredIntrestData, ...filteredPrincipalData];

    const payload = {
      MemberId: memberId,
      MemberCountryCode: memberDetail.Country,
      Actuals: tableData.map((row) => ({
        Amount: row.amount.toString(),
        Date: getDateStringWithGivenFormat(new Date(row.date), "dd/mm/yy"),
        DateFrom: null,
        DateTo: null,
        Description: row.notes,
        LoanId: row.loan == "" ? loanOptions[0].value : row.loan.toString(),
        PortfolioTrackerId: selectedProperty.toString(),
        TrackingItemType:
          row.type == PaymentType.Principal ? "CashflowMortgage.PrincipalPayment" : "CashflowMortgage.InterestPayment",
      })),
      PurchaseCosts: [],
    };

    await httpPost(ApiEndPoints.tracker + "PortfolioTracker/ActualsByBatch/", payload).then((response) => {
      if (response?.error) {
        toastRef.current?.show({
          severity: "error",
          summary: "Error Message",
          detail: "Failed to save loan details.",
          life: 3000,
        });
      } else {
        toastRef.current.show({
          severity: "success",
          summary: "Success Message",
          detail: "Loan details saved successfully.",
          life: 2000,
        });
      }
      setIntrestData([
        {
          id: 0,
          date: "",
          loan: "",
          amount: 0,
          notes: "",
          type: PaymentType.Interest,
          invalidFields: [],
          isRowEditable: true,
        },
      ]);
      setPrincipalData([
        {
          id: 0,
          date: "",
          loan: "",
          amount: 0,
          notes: "",
          type: PaymentType.Principal,
          invalidFields: [],
          isRowEditable: true,
        },
      ]);
      setShowConfirmationDialog(false);
    });
  };

  const handleTableFieldChange = (rowId: number, field: keyof IPaymentRow, value: any, type: string) => {
    type == PaymentType.Principal
      ? setPrincipalData((prevTableData) =>
          prevTableData.map((row) => (row.id === rowId ? { ...row, [field]: value, invalidFields: [] } : row))
        )
      : setIntrestData((prevTableData) =>
          prevTableData.map((row) => (row.id === rowId ? { ...row, [field]: value, invalidFields: [] } : row))
        );
  };

  const dateBodyTemplate = (row: IPaymentRow, field: keyof IPaymentRow) => (
    <DatePicker
      dateString={row[field] ? row[field].toString() : ""}
      onChange={(e) => handleTableFieldChange(row.id, field, e.value?.toISOString(), row.type)}
      dateFormat="dd/mm/yy"
      showIcon={true}
      className="w-full"
      invalid={row.invalidFields?.includes(field) ? true : false}
    />
  );

  const handleDeleteRow = (rowId: number, type: string) => {
    const FilteredData = type == PaymentType.Principal ? principalData : intrestData;
    if (rowId === 0 && FilteredData.length == 0) {
      type == PaymentType.Principal
        ? setPrincipalData([
            {
              id: 0,
              date: "",
              loan: "",
              amount: 0,
              notes: "",
              type: PaymentType.Principal,
              isRowEditable: true,
            },
          ])
        : setIntrestData([
            {
              id: 0,
              date: "",
              loan: "",
              amount: 0,
              notes: "",
              type: PaymentType.Interest,
              isRowEditable: true,
            },
          ]);
    } else {
      type == "Principal"
        ? setPrincipalData(
            principalData
              .filter((row) => row.id !== rowId)
              .map((row) => ({ ...row, id: row.id > rowId ? row.id - 1 : row.id }))
          )
        : setIntrestData(
            intrestData
              .filter((row) => row.id !== rowId)
              .map((row) => ({ ...row, id: row.id > rowId ? row.id - 1 : row.id }))
          );
    }
  };

  const validationMessage = (fields: string[]) => {
    toastRef.current?.show({
      severity: "error",
      summary: "Error",
      detail: `Please fill the following fields: ${fields.join(", ")}`,
      life: 2000,
      sticky: true,
    });
    return;
  };

  const handleSaveDataForProperty = (propertyId: string) => {
    setTableDataByProperty((prevData) => ({
      ...prevData,
      [propertyId]: {
        principal:
          principalData.length == 0
            ? [
                {
                  id: 0,
                  date: "",
                  loan: "",
                  amount: 0,
                  notes: "",
                  type: PaymentType.Principal,
                  invalidFields: [],
                  isRowEditable: true,
                },
              ]
            : principalData,
        interest:
          intrestData.length == 0
            ? [
                {
                  id: 0,
                  date: "",
                  loan: "",
                  amount: 0,
                  notes: "",
                  type: PaymentType.Interest,
                  invalidFields: [],
                  isRowEditable: true,
                },
              ]
            : intrestData,
      },
    }));
  };

  const renderConfirmationContent = (
    <DataTable
      key="confirmationTable"
      value={[...filteredPrincipalData, ...filteredIntrestData]}
      className="editable-table"
      showHeaders={true}
    >
      <Column
        header=""
        body={(row) => (row.type === PaymentType.Principal ? "Principal payments" : "Interest payments")}
      />
      <Column field="id" header="" hidden={true} />
      <Column
        field="date"
        header="Date (dd/mm/yy)"
        body={(row) =>
          row.isRowEditable
            ? dateBodyTemplate(row, "date")
            : getDateStringWithGivenFormat(new Date(row.date), "dd/mm/yy")
        }
      />
      <Column
        field="loan"
        header="Loan"
        body={(row) =>
          row.isRowEditable && loanOptions ? (
            <REIDropDown
              value={row.loan == "" ? loanOptions[0].value : row.loan}
              data={getLoanOptionsForProperty(Number(selectedProperty))}
              label="label"
              onSelectionChange={(e) => handleTableFieldChange(row.id, "loan", e.value, row.type)}
              placeholder="Select Loan"
              className="w-full"
            />
          ) : row.loan ? (
            loanOptions.find((data) => data.value == row.loan)?.label
          ) : (
            loanOptions[0].label
          )
        }
      />
      <Column
        field="amount"
        header="Amount"
        body={(row) =>
          row.isRowEditable ? (
            <REIInputText
              name="amount"
              value={row.amount.toString()}
              onChange={(e) => handleTableFieldChange(row.id, "amount", parseFloat(e.target.value), row.type)}
              className={`w-full ${row.invalidFields?.includes("amount") ? "p-invalid" : ""}`}
              type="number"
            />
          ) : (
            row.amount.toString()
          )
        }
      />
      <Column
        field="notes"
        header="Notes"
        body={(row) =>
          row.isRowEditable ? (
            <REIInputText
              type="text"
              name="notes"
              value={row.notes}
              onChange={(e) =>
                row.isRowEditable ? handleTableFieldChange(row.id, "notes", e.target.value, row.type) : row.notes
              }
              className="w-full"
            />
          ) : (
            row.notes
          )
        }
      />
      <Column
        header="Action"
        body={(row: IPaymentRow, options) =>
          options.rowIndex === 0 || row.isRowEditable ? (
            <REIButton
              icon="pi pi-plus"
              classNames="p-button-text"
              onClick={() => {
                handleAddRow(options.rowIndex, row.type);
              }}
            />
          ) : (
            <REIButton
              icon="pi pi-minus"
              classNames="p-button-text"
              onClick={() => handleDeleteRow(row.id, row.type)}
            />
          )
        }
        headerStyle={{ width: "3rem" }}
      />
    </DataTable>
  );

  const renderFooterContent = (
    <div className="flex justify-end space-x-2 mt-4">
      <REIButton
        label="Cancel"
        icon="pi pi-times"
        classNames="p-button-secondary"
        onClick={() => {
          setShowConfirmationDialog(false);
          if (!showConfirmationDialog) {
            onCancel();
          }
        }}
      />
      <REIButton
        label="Save"
        icon="pi pi-check"
        classNames="p-button-success"
        onClick={() => {
          if (validateData()) {
            if (!showConfirmationDialog) {
              setShowConfirmationDialog(true);

              const filteredIntrestData = intrestData.filter((row) => row.isRowEditable === false);
              const filteredPrincipalData = principalData.filter((row) => row.isRowEditable === false);

              setFilteredIntrestData(filteredIntrestData);
              setFilteredPrincipalData(filteredPrincipalData);

              handleSaveDataForProperty(selectedProperty);
            } else {
              saveLoanData();
            }
          }
        }}
      />
    </div>
  );

  const renderTable = (isRowEditable: boolean) => {
    return (
      <>
        <DataTable value={[...principalData, ...intrestData]} key="mainTable" className="editable-table">
          <Column header="" body={(row) => (row.type === "Principal" ? "Principal payments" : "Interest payments")} />
          <Column field="id" header="" hidden={true} />
          <Column
            field="date"
            header="Date (dd/mm/yy)"
            body={(row) =>
              row.isRowEditable
                ? dateBodyTemplate(row, "date")
                : getDateStringWithGivenFormat(new Date(row.date), "dd/mm/yy")
            }
          />
          <Column
            field="loan"
            header="Loan"
            body={(row) =>
              row.isRowEditable ? (
                <REIDropDown
                  value={row.loan == "" ? (loanOptions.length != 0 ? loanOptions[0].value : "") : row.loan}
                  data={getLoanOptionsForProperty(Number(selectedProperty))}
                  label="label"
                  onSelectionChange={(e) => handleTableFieldChange(row.id, "loan", e.value, row.type)}
                  placeholder="Select Loan"
                  className="w-full"
                />
              ) : loanOptions && row.loan ? (
                loanOptions.find((data) => data.value == row.loan)?.label
              ) : (
                loanOptions[0].label
              )
            }
          />
          <Column
            field="amount"
            header="Amount"
            body={(row) =>
              row.isRowEditable ? (
                <REIInputText
                  name="amount"
                  value={row.amount.toString()}
                  onChange={(e) => handleTableFieldChange(row.id, "amount", parseFloat(e.target.value), row.type)}
                  className={`w-full ${row.invalidFields?.includes("amount") ? "p-invalid" : ""}`}
                  type="number"
                />
              ) : (
                row.amount.toString()
              )
            }
          />
          <Column
            field="notes"
            header="Notes"
            body={(row) =>
              row.isRowEditable ? (
                <REIInputText
                  type="text"
                  name="notes"
                  value={row.notes}
                  onChange={(e) =>
                    !isRowEditable ? handleTableFieldChange(row.id, "notes", e.target.value, row.type) : row.notes
                  }
                  className="w-full"
                />
              ) : (
                row.notes
              )
            }
          />
          <Column
            header="Action"
            body={(row, options) =>
              row.isRowEditable || options.rowIndex === 0 ? (
                <REIButton
                  icon="pi pi-plus"
                  classNames="p-button-text"
                  onClick={() => {
                    handleAddRow(options.rowIndex, row.type);
                  }}
                />
              ) : (
                <REIButton
                  icon="pi pi-minus"
                  classNames="p-button-text"
                  onClick={() => handleDeleteRow(row.id, row.type)}
                />
              )
            }
            headerStyle={{ width: "3rem" }}
          />
        </DataTable>
        {renderFooterContent}
      </>
    );
  };

  return (
    <div className="flex flex-col space-y-4">
      <div className="w-full">
        <Messages ref={toastRef}></Messages>
      </div>
      <REIDialoge
        showHeader={true}
        header=""
        visible={showConfirmationDialog}
        handleEditClick={hideDefaultProfile}
        position="top"
        style={{ width: "60vw" }}
        customClass="edit-default-profile"
        contentComponent={
          <div>
            {renderConfirmationContent}
            {renderFooterContent}
          </div>
        }
      ></REIDialoge>
      <div>
        <div className="flex flex-row flex-wrap">
          <label className="self-center mx-3" htmlFor="property">
            Select Property
          </label>
          <REIDropDown
            key={`property-${selectedProperty}`}
            value={selectedProperty}
            data={propertyOptions}
            label="label"
            onSelectionChange={(e) => {
              setSelectedProperty(e.value);
              getLoanOptionsForProperty(Number(e.value));
            }}
            placeholder="Select Property"
            className="w-min"
          />
        </div>
      </div>

      <div className="flex-1">{renderTable(false)}</div>
    </div>
  );
};

export default EditLoan;
