import { useEffect, useState, useRef } from "react";
import { IAnalysedProperty, IPropertyDetail } from "../../../../../interface/addAnalyser";
import { useAnalysedPropertyContext } from "../AnalysedPropertyContext";
import { REIButton, REIDialoge, REIDropDown, REIInputNumber, REILinkButton } from "../../../../../components/ui";
import InputSlider from "../../../../../components/ui/slider";
import { IMemberEntity } from "../../../../../interface/analysed";
import EntityOwnerShip from "../AddEntityOwnership";

interface IAssignEntity {
  setIsValid: (isValid: boolean) => void;
  updateProperty: (updated: Partial<IAnalysedProperty>) => void;
  memberEntities: IMemberEntity[];
}

const AssignEntity: React.FC<IAssignEntity> = ({ setIsValid, updateProperty, memberEntities }) => {
  const { analysedProperty } = useAnalysedPropertyContext();
  const [propertyDetails, setPropertyDetails] = useState<IPropertyDetail | undefined>(undefined);
  const PropertyEntities = useRef<any[]>([]);
  const [showEntityOwnershipDialog, setShowEntityOwnershipDialog] = useState(false);
  const [reset, setReset] = useState<boolean>(false);

  useEffect(() => {
    if (analysedProperty && !reset) {
      setPropertyDetails(analysedProperty.PropertyDetail);
      setPropertyEntityNames();
      setReset(true);
    }
  }, [analysedProperty]);

  useEffect(() => {
    if (propertyDetails) {
      setIsValid(true);
      updateProperty({
        ...analysedProperty,
        PropertyDetail: propertyDetails,
      });
    }
  }, [propertyDetails]);

  const setValues = (name: string, value: string | number) => {
    setPropertyDetails((prevState) => {
      if (!prevState) return undefined;
      const updatedExpense = {
        ...prevState,
        [name]: value !== undefined ? value : 0,
      };
      return updatedExpense;
    });
  };

  const setPropertyEntityNames = () => {
    if (memberEntities) {
      let memeberEntitiesValues: any[] = [];
      memberEntities.map((entities) => {
        memeberEntitiesValues.push({
          EntityName: entities.EntityName,
          EntityValue: entities.MemberEntityId.toString(),
        });
      });
      PropertyEntities.current = memeberEntitiesValues;
    }
  };

  const hideEntityOwnershipDialog = () => {
    setShowEntityOwnershipDialog(false);
  };

  return (
    <div>
      <REIDialoge
        showHeader={true}
        header={`Add a new entity ownership structure`}
        visible={showEntityOwnershipDialog}
        handleEditClick={hideEntityOwnershipDialog}
        position="top"
        customClass="update-statistics-dialog"
        style={{ width: "55vw" }}
        contentComponent={<EntityOwnerShip closeDialogue={hideEntityOwnershipDialog} />}
      ></REIDialoge>
      <div className="relative rounded-lg pl-3 pr-1 py-3 border-solid border border-gray-300 bg-white mt-2">
        <div className="default-profile-header-container">
          <h3 className="text-[var(--primary-color)] mt-0 m-1 text-lg">Assign an entity</h3>
        </div>
        <div className="p-4">
          <div className="flex flex-wrap mb-4">
            <div className="w-2/3 md:w-1/2 p-2">
              <div className="flex items-center justify-evenly">
                <label className="w-1/2 text-left" htmlFor="Rates">
                  This property belongs to enitity:
                </label>
                <REIDropDown
                  data={PropertyEntities.current}
                  onSelectionChange={(e) => setValues("MemberEntityId", Array.isArray(e.value) ? e.value[0] : e.value)}
                  placeholder="Select"
                  value={propertyDetails?.MemberEntityId}
                  optionValue="EntityValue"
                  label="EntityName"
                ></REIDropDown>
              </div>
            </div>
            <div className="w-full md:w-1/2 p-2 text-center content-center">
              <REIButton
                label="Create a new entity"
                classNames="p-button-link p-0 pt-2"
                onClick={() => {
                  setShowEntityOwnershipDialog(true);
                }}
              />
            </div>
          </div>

          <div className="flex flex-wrap mb-4">
            <div className="w-full md:w-1/2 p-2">
              <div className="flex items-center justify-evenly">
                <label className="w-1/3 text-left" htmlFor="entityName">
                  Entity selected :
                </label>
                <label className="w-2/3 text-left" htmlFor="PortionOwned">
                  Entity Owns
                </label>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 p-2 justify-evenly">
            <div className="flex flex-row items-center align-middle">
              <div className="w-1/3" id="entityName">
                {memberEntities && (
                  <label className="text-left">
                    <b>
                      {memberEntities.find((x) => x.MemberEntityId == propertyDetails?.MemberEntityId)?.EntityName}
                      <a href=""> (view)</a>
                    </b>
                  </label>
                )}
              </div>
              <div className="w-2/3 flex flex-row content-center items-center">
                <InputSlider
                  value={propertyDetails?.PortionOwned}
                  onChange={(e) => setValues("PortionOwned", Array.isArray(e.value) ? e.value[0] : e.value)}
                  classNames="w-full mx-2"
                  min={1}
                  max={100}
                />
                <REIInputNumber
                  value={propertyDetails?.PortionOwned}
                  onValueChange={(e) => setValues("PortionOwned", e.target.value)}
                  useGrouping={true}
                  className=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignEntity;
