import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISaveSearch } from "../../interface/ISaveSearch";

interface IInvestarSearchState {
  filterValue: ISaveSearch;
  sortBy?: number;
  error: string | null;
  searchId: number;
  salesListingData: any | null;
}

const initialState: IInvestarSearchState = {
  filterValue: null,
  sortBy: 1,
  error: null,
  searchId: 0,
  salesListingData: null,
};

const investarSearchSlice = createSlice({
  name: "investarSearch",
  initialState,
  reducers: {
    setFilterValue(state, action: PayloadAction<IInvestarSearchState>) {
      state.filterValue = action.payload.filterValue;
      state.error = action.payload.error;
    },
    setInvestarSearchSortBy(state, action: PayloadAction<number>) {
      state.sortBy = action.payload;
    },
    setCurrentSavedSearch(state, action: PayloadAction<number>) {
      state.searchId = action.payload;
    },
    setSalesListingData(state, action: PayloadAction<any>) {
      state.salesListingData = action.payload;
    },
    resetSalesListingData(state) {
      state.salesListingData = null;
    },
    clearFilterValue(state) {
      state.filterValue = null;
      state.error = null;
      state.searchId = 0;
      state.salesListingData = null;
    },
  },
});

export const {
  setFilterValue,
  setInvestarSearchSortBy,
  clearFilterValue,
  setCurrentSavedSearch,
  setSalesListingData,
  resetSalesListingData,
} = investarSearchSlice.actions;

export default investarSearchSlice.reducer;
