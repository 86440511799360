import { Divider } from "primereact/divider";

interface IDividerProps {
  children?: React.ReactNode;
}

const REIDivider: React.FC<IDividerProps> = ({ children }) => (
  <Divider>{children}</Divider>
);

export default REIDivider;
