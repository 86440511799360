import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { App } from "../App";
import AuthRoute from "./authroute";

import Login from "../pages/Login";
import InvestarSearch from "../pages/InvestarSearch";
import MyAccount from "../pages/MyAccount/MyAccount";
import PropertyAnalyser from "../pages/ProppertyAnalyser/Index";
import AddPropertyStepper from "../pages/ProppertyAnalyser/PropertyStepper/Index";
import AnalysisReportIndex from "../pages/ProppertyAnalyser/AnalysisReport/Index";
import { AnalysedPropertyProvider } from "../pages/ProppertyAnalyser/PropertyStepper/Child/AnalysedPropertyContext";
import AddPropertyStepperWrapper from "../pages/ProppertyAnalyser/PropertyStepper/Child/AddPropertyStepperWrapper";
import PortfolioTrackerIndex from "../pages/PortfolioTracker";

const Routing: React.FC = () => {
  return (
    <Router basename={"/"}>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<App />}>
          <Route
            path=""
            element={
              <AuthRoute>
                <InvestarSearch />
              </AuthRoute>
            }
          ></Route>
          <Route
            path=":id"
            element={
              <AuthRoute>
                <InvestarSearch />
              </AuthRoute>
            }
          ></Route>
          <Route
            path="/myaccount"
            element={
              <AuthRoute>
                <MyAccount />
              </AuthRoute>
            }
          ></Route>
          <Route
            path="/property-analyser"
            element={
              <AuthRoute>
                <PropertyAnalyser />
              </AuthRoute>
            }
          ></Route>
          <Route
            path="/property-analyser/analysis-report"
            element={
              <AuthRoute>
                <AnalysisReportIndex />
              </AuthRoute>
            }
          ></Route>
          <Route
            path="/analyse-property"
            element={
              <AuthRoute>
                <AnalysedPropertyProvider>
                  <AddPropertyStepperWrapper />
                </AnalysedPropertyProvider>
              </AuthRoute>
            }
          ></Route>
          <Route
            path="/portfolio-tracker"
            element={
              <AuthRoute>
                <PortfolioTrackerIndex />
              </AuthRoute>
            }
          ></Route>
        </Route>
      </Routes>
    </Router>
  );
};

export default Routing;
