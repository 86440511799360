import React, { useState } from "react";
import { Form, Field } from "react-final-form";
import { COUNTRY, COUNTRY_OPTIONS, GET_LENGTH_OPTIONS, MEMBER_KEY } from "../../../utils/constants";
import { REIButton, REICheckBox, REIDropDown, REIInputNumber } from "../../../components/ui";
import { ApiEndPoints } from "../../../utils/endpoints";
import { useHttpGet } from "../../../services/httpService";
import { BathIcon, BedIcon, CarIcon } from "../../../public/assets/images";
import { formatDate } from "../../../utils/commonUtil";
import AddressAutoComplete from "../../../components/shared/addressAutoComplete";
import { useNavigate } from "react-router-dom";
import { IPropertyAnalyserMedianRequest } from "../../../interface/analysed";

const AddNewProperty: React.FC = () => {
  const { data: propertyTypes, error } = useHttpGet(`${ApiEndPoints.anylyserfilter}/WBPropertyType`, undefined, true);
  const [defauleProfile, setDefauleProfile] = useState(false);
  const [address, setAddress] = useState<string | null>(null);
  const navigate = useNavigate();

  const getBedRoomOptions = () => [...GET_LENGTH_OPTIONS(0, 9, (i) => (i === 9 ? "9+" : i.toString()))];

  const getBathCarOptions = () => [...GET_LENGTH_OPTIONS(0, 5, (i) => (i === 5 ? "5+" : i.toString()), 2)];

  const onSubmit = async (values: any) => {
    const body = {
      BathroomId: values.bath.toString(),
      BedroomId: values.bed.toString(),
      CarportId: values.car.toString(),
      CountryId: values.country,
      DateAdded: new Date().toLocaleDateString("en-GB"),
      Landsize: values.landArea?.toString(),
      MemberPropertyId: 0,
      ModulesId: localStorage.getItem(MEMBER_KEY),
      PropertyDetailId: 0,
      PropertyName: address,
      PropertyTypeId: values.propertyType,
      UserCountryId: 1,
      district: null,
      locality: "",
      postcode: "",
      state: "",
      useDefault: defauleProfile ? "1" : "0",
    } as IPropertyAnalyserMedianRequest;

    navigate("/analyse-property", {
      state: { propertyAnalyserId: 0, medianRequestBody: body },
    });
  };

  const validate = () => {
    const errors: { selectedPlace?: string } = {};

    if (!address) {
      errors.selectedPlace = "Property name or address is required.";
    }

    return errors;
  };

  const handlePlaceSelected = (place: google.maps.places.PlaceResult | null, address: string | null) => {
    setAddress(address);
  };

  const onDefauleProfileChange = (e: any) => {
    setDefauleProfile(e.checked);
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        country: 1,
        landArea: "",
        bed: 1,
        bath: 1,
        car: 1,
        propertyType: 1,
        defauleProfile: false,
      }}
      validate={validate}
      render={({ handleSubmit, submitting, valid, values }) => (
        <form onSubmit={handleSubmit} className="p-fluid">
          <div className="flex md:flex-row flex-col gap-2 mb-2">
            <Field name="country">
              {({ input }) => (
                <div className="md:w-1/4 w-full">
                  <label className="block text-sm font-medium">Country</label>
                  <REIDropDown
                    {...input}
                    data={COUNTRY_OPTIONS}
                    label="name"
                    optionValue="value"
                    onSelectionChange={(e) => input.onChange(e.value)}
                  />
                </div>
              )}
            </Field>

            <Field name="selectedPlace">
              {({ input, meta }) => (
                <div className="md:w-3/4 w-full">
                  <label className="block text-sm font-medium">Property name or address</label>
                  <AddressAutoComplete
                    onPlaceSelected={handlePlaceSelected}
                    country={values.country == 1 ? COUNTRY.AU : COUNTRY.NZ}
                  />
                  {meta.error && meta.touched && <span className="text-red-500 text-sm">{meta.error}</span>}
                </div>
              )}
            </Field>
          </div>

          <div className="flex md:flex-row flex-col gap-2 mt-1 mb-2">
            <Field name="propertyType">
              {({ input }) => (
                <div className="md:w-1/4 w-full">
                  <label className="block text-sm font-medium">Property Type</label>
                  <REIDropDown
                    {...input}
                    data={propertyTypes}
                    label="Label"
                    optionValue="WBPropertyTypeId"
                    onSelectionChange={(e) => input.onChange(e.value)}
                  />
                </div>
              )}
            </Field>

            <Field name="landArea">
              {({ input }) => (
                <div className="md:w-1/4 w-full">
                  <label className="block text-sm font-medium">Land area (m2)</label>
                  <REIInputNumber
                    {...input}
                    className="w-full"
                    maxFractionDigits={0}
                    min={0}
                    max={100000}
                    onValueChange={(e) => input.onChange(e.value)}
                  />
                </div>
              )}
            </Field>

            <div className="md:w-2/4 w-full">
              <div className="flex md:flex-row flex-col gap-2 items-center h-full mt-2 ml-1">
                <Field name="bed">
                  {({ input }) => (
                    <div className="md:w-1/3 w-full flex items-center h-full">
                      <img src={BedIcon} alt="Bed" className="h-8 w-auto" />
                      <REIDropDown
                        {...input}
                        data={getBedRoomOptions()}
                        label="Label"
                        className="w-full"
                        optionValue="value"
                        onSelectionChange={(e) => input.onChange(e.value)}
                      />
                    </div>
                  )}
                </Field>

                <Field name="bath">
                  {({ input }) => (
                    <div className="md:w-1/3 w-full flex items-center h-full">
                      <img src={BathIcon} alt="Bath" className="h-8 w-auto" />
                      <REIDropDown
                        {...input}
                        data={getBathCarOptions()}
                        label="Label"
                        className="w-full"
                        optionValue="value"
                        onSelectionChange={(e) => input.onChange(e.value)}
                      />
                    </div>
                  )}
                </Field>

                <Field name="car">
                  {({ input }) => (
                    <div className="md:w-1/3 w-full flex items-center h-full">
                      <img src={CarIcon} alt="Park" className="h-8 w-auto" />
                      <REIDropDown
                        {...input}
                        data={getBathCarOptions()}
                        label="Label"
                        className="w-full"
                        optionValue="value"
                        onSelectionChange={(e) => input.onChange(e.value)}
                      />
                    </div>
                  )}
                </Field>
              </div>
            </div>
          </div>

          <div className="flex md:flex-row flex-col gap-2 mt-2">
            <Field name="defauleProfile" type="checkbox">
              {({ input }) => (
                <div className="md:w-3/4 w-full text-start">
                  <REICheckBox
                    {...input}
                    className="mr-1"
                    label="Use my default profile"
                    checked={defauleProfile}
                    onChange={onDefauleProfileChange}
                  />
                </div>
              )}
            </Field>

            <div className="md:w-1/4 w-full text-end">
              <REIButton
                label="Add Property"
                type="submit"
                disabled={submitting || !valid}
                icon=""
                loading={submitting}
                classNames="w-full"
                onClick={() => {}}
              />
            </div>
          </div>
        </form>
      )}
    />
  );
};

export default AddNewProperty;
