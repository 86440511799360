import React, { useEffect, useState, useCallback } from "react";
import { IAnalysisSummaryResponse } from "../../../../interface/analysisSummary";
import { REIInputNumber, REIProgressSpinner } from "../../../../components/ui";
import { MEMBER_KEY, USER_DETAIL } from "../../../../utils/constants";
import { ApiEndPoints } from "../../../../utils/endpoints";
import { httpUpdate } from "../../../../services/httpService";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store";
import { fetchAnalysisSummary } from "../../../../store/slice/analyserSlice";

const AnalysisVariables: React.FC = () => {
  const analysisSummary = useSelector((state: RootState) => state.analyser.analysisSummary as IAnalysisSummaryResponse);
  const memberDetail = JSON.parse(localStorage.getItem(USER_DETAIL) || "{}");
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch: AppDispatch = useDispatch();

  const onLoanValueChange = useCallback(
    async (e: any) => {
      e.preventDefault();
      setLoading(true);

      const variableBody = {
        MemberId: localStorage.getItem(MEMBER_KEY),
        MemberCountryCode: memberDetail.Country,
        LVR: e.target.value,
        LoansPrincipalInterest: analysisSummary?.LoanPrincipalInterest,
        ForecastOverrides: [],
        PropertyAnalyserId: analysisSummary?.PropertyAnalyserId,
      };

      try {
        const result = await httpUpdate(`${ApiEndPoints.analyser}/AnalysisReport/`, variableBody);

        if (result.status === 200 && result.data) {
          dispatch(fetchAnalysisSummary(analysisSummary?.PropertyAnalyserId.toString()));
        }
      } catch (error) {
        console.error("Error updating loan value ratio:", error);
      } finally {
        setLoading(false);
      }
    },
    [analysisSummary, memberDetail.Country, dispatch]
  );

  if (!analysisSummary || loading) {
    return (
      <div className="w-full text-center">
        <REIProgressSpinner />
      </div>
    );
  }

  return (
    <div className="flex md:flex-row flex-col w-full h-[44px]">
      <div className="flex-0 w-[340px] pt-3">
        <label className="mr-1 w-full">Loan to value ratio target %</label>
      </div>
      <div className="flex-0 w-full">
        <REIInputNumber
          onValueChange={onLoanValueChange}
          className="w-full"
          value={analysisSummary?.Levers?.LoanValueRatio}
        />
      </div>
    </div>
  );
};

export default AnalysisVariables;
