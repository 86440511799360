import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { httpPost, useHttpGets } from "../../services/httpService";
import { ApiEndPoints } from "../../utils/endpoints";
import { IEntity } from "../../interface/propertyTracker";
import { IMemberEntity, IMemberProfile } from "../../interface/analysed";
import { MEMBER_KEY, USER_DETAIL } from "../../utils/constants";

interface IProprtyDetailsState {
  trackerData: IEntity[];
  profileData: IMemberProfile[];
  memberEntities: IMemberEntity[];
  error: string | null;
  loading: boolean;
}

const initialState: IProprtyDetailsState = {
  trackerData: [],
  profileData: [],
  memberEntities: [],
  error: null,
  loading: false,
};

export const fetchTrackerIndex = createAsyncThunk(
  "portfolioTracker/fetchTrackerIndex",
  async (parameter: {
    MemberId: string;
    UserCountryCode: string;
    Entity: any[];
    Profiles: any[];
    MemberName: string;
  }) => {
    const response = await httpPost(`${ApiEndPoints.tracker}PortfolioTracker/TrackerIndex/`, parameter);
    return response.data as IEntity[];
  }
);

export const fetchMemberProfiles = createAsyncThunk("portfolioTracker/fetchMemberProfiles", async () => {
  try {
    const result = await useHttpGets(`${ApiEndPoints.tracker}/GetMemberProfiles/${localStorage.getItem(MEMBER_KEY)}`);

    if (result.status === 200 && result.data && !result.error) {
      return result.data as IMemberProfile[];
    } else {
      throw new Error("Failed to fetch member profile");
    }
  } catch (error) {
    throw new Error("Failed to fetch member profile");
  }
});

export const fetchMemberEntities = createAsyncThunk("portfolioTracker/fetchMemberEntities", async () => {
  try {
    const memberDetail = JSON.parse(localStorage.getItem(USER_DETAIL) || "{}");

    const result = await useHttpGets(
      `${ApiEndPoints.tracker}/MemberEntities?memberId=${localStorage.getItem(MEMBER_KEY)}&userCountryCode=${
        memberDetail.Country
      }&memberName=${memberDetail.FirstName + " " + memberDetail.LastName}`
    );

    if (result.status === 200) {
      if (result?.data && !result.error) {
        return result.data as IMemberEntity[];
      } else {
        throw new Error("Failed to fetch member entities");
      }
    } else {
      throw new Error("Failed to fetch member entities");
    }
  } catch (error) {
    throw new Error("Failed to fetch member entities");
  }
});

const myTrackerSlice = createSlice({
  name: "portfolioTracker",
  initialState,
  reducers: {
    setTrackerData(state, action: PayloadAction<IEntity>) {
      state.trackerData.push(action.payload);
    },
    setEntitiesData(state, action: PayloadAction<IMemberEntity>) {
      state.memberEntities.push(action.payload);
    },
    clearPropertyDetails(state) {
      state.trackerData = [];
    },
    setProfileData(state, action: PayloadAction<IMemberProfile[]>) {
      state.profileData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTrackerIndex.pending, (state) => {
        state.error = null;
        state.loading = true;
      })
      .addCase(fetchTrackerIndex.fulfilled, (state, action: PayloadAction<IEntity[]>) => {
        state.trackerData = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchTrackerIndex.rejected, (state, action) => {
        state.error = action.error.message || "Failed to fetch tracker index";
        state.loading = false;
      })
      .addCase(fetchMemberEntities.pending, (state) => {
        state.error = null;
        state.loading = true;
      })
      .addCase(fetchMemberEntities.fulfilled, (state, action: PayloadAction<IMemberEntity[]>) => {
        state.memberEntities = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchMemberEntities.rejected, (state, action) => {
        state.error = action.error.message || "Failed to fetch member entities";
        state.loading = false;
      })
      .addCase(fetchMemberProfiles.rejected, (state) => {
        state.error = "Failed to fetch member profile";
        state.loading = false;
      })
      .addCase(fetchMemberProfiles.pending, (state) => {
        state.error = null;
        state.loading = true;
      })
      .addCase(fetchMemberProfiles.fulfilled, (state, action: PayloadAction<IMemberProfile[]>) => {
        state.profileData = action.payload;
      });
  },
});

export const { clearPropertyDetails, setTrackerData } = myTrackerSlice.actions;

export default myTrackerSlice.reducer;
