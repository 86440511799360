import { InputText } from "primereact/inputtext";

interface IInputTextProps {
  type: string;
  name: string;
  className?: string;
  value?: string;
  onChange?: (e: any) => void;
  hidden?: boolean;
  id?: string;
  ref?: any;
  placeholder?: string;
  disabled?: boolean;
}

const REIInputText: React.FC<IInputTextProps> = ({
  type = "text",
  name,
  className = "w-full",
  value = "",
  onChange,
  hidden = false,
  id,
  ref,
  placeholder = "",
  disabled = false,
}) => {
  return (
    <InputText
      ref={ref}
      id={id}
      type={type}
      name={name}
      className={className}
      value={value}
      onChange={onChange}
      hidden={hidden}
      placeholder={placeholder}
      disabled={disabled}
    />
  );
};

export default REIInputText;
