import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AustraliaMap, NewZealandMap } from "../../../components/shared";
import { REIButton, REIDialoge, REIDivider, REIErrorMessage, REISelectButton } from "../../../components/ui";

import { ISaveSearch } from "../../../interface/ISaveSearch";
import { clearFilterValue } from "../../../store/slice/investarSearchSlice";

import LocationComponent from "../../SavedSearch/Edit/Child/LocationComponent";
import PropertyTypeComponent from "../../SavedSearch/Edit/Child/PropertyTypeComponent";
import StrategyComponent from "../../SavedSearch/Edit/Child/StrategyComponent";
import SuburbComponent from "../../SavedSearch/Edit/Child/SuburbComponent";

import { ApiEndPoints } from "../../../utils/endpoints";
import { httpPost, useHttpGet } from "../../../services/httpService";
import { COUNTRY, COUNTRY_OPTIONS, LOCATION_ADVANCED, OPTION_ALL } from "../../../utils/constants";

import SaveThisSearch from "./saveThisSearch";
import { RootState } from "../../../store";
import { classNames } from "primereact/utils";
import { AUS_MAP_DATA, NZ_MAP_DATA } from "../../../utils/mapdata";

interface ISearchDetailProps {
  handleSearchClick: (data: {}, filters: {}) => void;
  resetSearchOnlyScreen: () => void;
  searchOnlyScreen?: boolean;
  setSearchLoading: () => void;
}

const SearchDetail: React.FC<ISearchDetailProps> = ({
  handleSearchClick,
  resetSearchOnlyScreen,
  searchOnlyScreen,
  setSearchLoading,
}) => {
  const [country, setCountry] = useState(1);
  const [currentCountry, setCurrentCountry] = useState(COUNTRY.AU);
  const [currentState, setCurrentState] = useState(null);
  const [refreshData] = useState(true);
  const [showMap, setShowMap] = useState(false);

  const [showSaveThisSearch, setShowSaveThisSearch] = useState<boolean>(false);

  const [selectedDropdownValue, setSelectedDropdownValue] = useState(null);
  const [keywords, setKeywords] = useState(null);
  const [strategyKeywords, setStrategyKeywords] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState<ISaveSearch>(null);
  const salesListingData = useSelector((state: RootState) => state.investar.salesListingData);

  const dispatch = useDispatch();
  const filterData = useSelector((state: RootState) => state.investar.filterValue);
  const searchId = useSelector((state: RootState) => state.investar.searchId);
  const sortBy = useSelector((state: RootState) => state.investar.sortBy);

  const { data: options, error } = useHttpGet(`${ApiEndPoints.parameter}/LocalityFilters/AU`, undefined, refreshData);

  useEffect(() => {
    if (filterData && filterData.Country.OrderBy != sortBy.toString() && selectedDropdownValue) {
      selectedDropdownValue.orderBy = sortBy.toString();
      searchResult();
    }
  }, [sortBy]);

  useEffect(() => {
    if (options) {
      initializeFormData();
    }
  }, [options]);

  useEffect(() => {
    if (searchId > 0) {
      initializeFormData();

      var searchValue = {
        Country: { ...filterData.Country },
        Locality: { ...filterData.Locality },
      };
      searchValue.Country.Title = "";
      searchValue.Country.MemberID = localStorage.getItem("member_id");

      httpPost(`${ApiEndPoints.searchSummary}/${currentCountry}/Search/Summary/`, searchValue)
        .then((result) => {
          if (result.status == 200) {
            handleSearchClick(result.data, filterData);
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  }, [searchId]);

  useEffect(() => {
    const openSaleslisting = async () => {
      if (salesListingData) {
        initializeFormData();
        const filers = await getSearchObject();
        handleSearchClick(salesListingData, filers);
      }
    };
    if (salesListingData) {
      openSaleslisting();
    }
  }, [salesListingData]);

  const handleCountryChange = (e: any) => {
    if (e.value) {
      setCountry(e.value ? e.value : 1);
      setCurrentCountry(e.value == 1 ? COUNTRY.AU : COUNTRY.NZ);
      setCurrentState(null);
      selectedDropdownValue.locationtags = [];
    }
  };

  const onStateChange = (state: string) => {
    setCurrentState(state);
  };

  const handleDropdownChange = (e: any, dropdownName: any) => {
    setSelectedDropdownValue({
      ...selectedDropdownValue,
      [dropdownName]: e.value,
    });
    if (dropdownName == "keywordStrategies") {
      selectKeyWorld(undefined, e.value);
    }
  };

  const selectKeyWorld = (key: string, value: string) => {
    const defaultKeywords = options?.StrategiesCollection?.StrategiesAndKeywords?.filter((x) =>
      key ? x.Label == key : x.FilterStrategyID == value
    );
    if (defaultKeywords && defaultKeywords.length > 0) {
      setKeywords(defaultKeywords[0].Keywords);
      setStrategyKeywords(defaultKeywords[0].Label !== OPTION_ALL ? defaultKeywords[0].Keywords : []);
    }
  };

  if (error) {
    return (
      <div className="flex items-start">
        <REIErrorMessage message={error}></REIErrorMessage>
      </div>
    );
  }

  const setLocationTag = () => {
    if (filterData?.Locality && filterData?.Locality.length > 0) {
      return filterData.Locality ? filterData.Locality.map((x) => x.Locality) : [];
    } else if (filterData?.Country?.LocationBasicsSuburbs && filterData?.Country?.LocationBasicsSuburbs.length > 0) {
      let selectedTags = [];
      if (filterData?.Country?.LocationBasicsSuburbs[0].SuburbId == "0") {
        const stateregion =
          filterData?.Country?.LocationBasics[0].Country == COUNTRY.AU
            ? AUS_MAP_DATA[filterData?.Country?.LocationBasics[0].State?.toLowerCase()]
            : NZ_MAP_DATA[filterData?.Country?.LocationBasics[0].Region?.toLowerCase()];

        if (filterData.Country?.LocationBasics[0].Country == COUNTRY.AU) {
          if (
            filterData.Country.LocationBasics[0].RegionId == "0" ||
            filterData.Country.LocationBasics[0].RegionId == ""
          ) {
            selectedTags.push(`${stateregion?.title}: all suburbs`);
          } else {
            selectedTags.push(`${filterData.Country.LocationBasics[0].Region}: all suburbs`);
          }
        } else if (filterData.Country?.LocationBasics[0].Country == COUNTRY.NZ) {
          if (
            filterData.Country.LocationBasics[0].DistrictId == "0" ||
            filterData.Country.LocationBasics[0].DistrictId == ""
          ) {
            selectedTags.push(`${stateregion?.title}: all suburbs`);
          } else {
            selectedTags.push(`${filterData.Country.LocationBasics[0].District}: all suburbs`);
          }
        }
        return selectedTags;
      } else {
        return filterData.Country.LocationBasicsSuburbs.map((x) => x.Suburb);
      }
    }
  };

  const getLocationAdvanced = (type: string) => {
    const types: { [key: string]: number } = {
      postcodeLimit: 1,
      addressLimit: 2,
      suburbLimit: 3,
      postcodeRadius: 4,
      suburbtype: 5,
    };
    return types[type] || 0;
  };

  const initializeFormData = () => {
    const selectedData = filterData?.Country;
    if (selectedData) {
      setCountry(selectedData?.LocationBasics[0].Country == COUNTRY.AU ? 1 : 2);
      setCurrentCountry(selectedData?.LocationBasics[0].Country);

      if (selectedData.LocationBasics[0].Country == COUNTRY.AU) {
        setCurrentState(AUS_MAP_DATA[selectedData.LocationBasics[0].State?.toLowerCase()]);
      } else {
        setCurrentState(NZ_MAP_DATA[selectedData.LocationBasics[0].Region?.toLowerCase()]);
      }
    }

    setSelectedDropdownValue({
      ...selectedDropdownValue,

      locationtags: selectedData ? setLocationTag() : [],
      selectedRegion: parseInt(selectedData?.LocationBasics[0].RegionId),
      selectedDistrict: parseInt(selectedData?.LocationBasics[0].DistrictId),
      locationSearchType: getLocationAdvanced(selectedData?.LocationAdvanced[0].locationSearchType),
      locationAdanced: selectedData ? selectedData.LocationAdvanced[0] : {},
      locationBasicsSuburbs:
        selectedData?.LocationBasicsSuburbs && selectedData?.LocationBasicsSuburbs.length > 0
          ? selectedData.LocationBasicsSuburbs
          : [],
      suburbs: [],

      //StrategyComponent component default values
      priceRangeMin: selectedData
        ? parseInt(selectedData.PropertySpecifics[0].priceFrom)
        : options.PropertySpecifics.PriceRange.PriceRangeMin[0].ListingPrice_From_ID,
      priceRangeMax: selectedData
        ? parseInt(selectedData.PropertySpecifics[0].priceTo)
        : options.PropertySpecifics.PriceRange.PriceRangeMax[0].ListingPrice_To_ID,
      keywordStrategies: selectedData
        ? parseInt(selectedData.Strategy[0].strategyTypeId)
        : options.Strategy.Strategies[0].FilterStrategyID,
      keywordPhrase: selectedData
        ? parseInt(selectedData.Strategy[0].KeywordsOperandId)
        : options.Strategy.KeywordPhrase[0].FilterKeywordSearchTypeID,
      excKeywordPhrase: selectedData
        ? parseInt(selectedData.Strategy[0].ExcludedKeywordsOperandId)
        : options.Strategy.ExcKeywordPhrase[0].FilterExcludeKeywordSearchTypeID,
      keywordPhrases: selectedData ? selectedData.Strategy[0].keywords : "",
      excKeywordPhrases: selectedData ? selectedData.Strategy[0].exclude : "",

      //PropertyTypeComponent default values
      dwellingTypes: selectedData
        ? parseInt(selectedData.PropertySpecifics[0].dwellingType)
        : options.PropertySpecifics.DwellingTypes[0].FilterDwellingTypeID,
      bedRoomsMin: selectedData
        ? parseInt(selectedData.PropertySpecifics[0].bedRoomMin)
        : options.PropertySpecifics.BedRooms.BedRoomsMin[0].Bedroom_From_ID,
      bedRoomsMax: selectedData
        ? parseInt(selectedData.PropertySpecifics[0].bedRoomMax)
        : options.PropertySpecifics.BedRooms.BedRoomsMax[0].Bedroom_To_ID,
      bathRoomsMin: selectedData
        ? parseInt(selectedData.PropertySpecifics[0].bathRoomMin)
        : options.PropertySpecifics.BathRooms.BathRoomsMin[0].Bathroom_From_ID,
      bathRoomsMax: selectedData
        ? parseInt(selectedData.PropertySpecifics[0].bathRoomMax)
        : options.PropertySpecifics.BathRooms.BathRoomsMax[0].Bathroom_To_ID,
      carportsMin: selectedData
        ? parseInt(selectedData.PropertySpecifics[0].carPortMin)
        : options.PropertySpecifics.Carports.CarportsMin[0].Carport_From_ID,
      carportsMax: selectedData
        ? parseInt(selectedData.PropertySpecifics[0].carPortMax)
        : options.PropertySpecifics.Carports.CarportsMax[0].Carport_To_ID,
      landSizesMin: selectedData
        ? parseInt(selectedData.PropertySpecifics[0].landSizeMin)
        : options.PropertySpecifics.LandSizes.LandSizesMin[0].LandSize_From_ID,
      landSizesMax: selectedData
        ? parseInt(selectedData.PropertySpecifics[0].landSizeMax)
        : options.PropertySpecifics.LandSizes.LandSizesMax[0].LandSize_To_ID,
      listingAgeMin: selectedData
        ? parseInt(selectedData.ListingDetail[0].listingAgeMin)
        : options.ListngDetails.ListingAge.ListingAgeMin[0].ListingAge_From_ID,
      listingAgeMax: selectedData
        ? parseInt(selectedData.ListingDetail[0].listingAgeMax)
        : options.ListngDetails.ListingAge.ListingAgeMax[0].ListingAge_To_ID,
      propertyYieldMin: selectedData
        ? parseInt(selectedData.ListingDetail[0].propertyYieldMin)
        : options.ListngDetails.PropertyYields.PropertyYieldMin[0].PotentialYield_From_ID,
      propertyYieldMax: selectedData
        ? parseInt(selectedData.ListingDetail[0].propertyYieldMax)
        : options.ListngDetails.PropertyYields.PropertyYieldMax[0].PotentialYield_To_ID,
      includeLand: selectedData ? selectedData.PropertySpecifics[0].includeLand == "true" : false,

      // //SuburbComponent default values
      filterRangeMin: selectedData
        ? parseInt(selectedData.SuburbStatistics[0].rentalYieldMin)
        : options.SuburbStatistics.RentalYields.FilterRangeMin[0].From_ID_Int,
      filterRangeMax: selectedData
        ? parseInt(selectedData.SuburbStatistics[0].rentalYieldMax)
        : options.SuburbStatistics.RentalYields.FilterRangeMax[0].To_ID_Int,
      suburbMedianRangeMin: selectedData
        ? parseInt(selectedData.SuburbStatistics[0].suburbMedianMin)
        : options.SuburbStatistics.SuburbMedian.FilterRangeMin[0].From_ID_Int,
      suburbMedianRangeMax: selectedData
        ? parseInt(selectedData.SuburbStatistics[0].suburbMedianMax)
        : options.SuburbStatistics.SuburbMedian.FilterRangeMax[0].To_ID_Int,
      suburbListingRangeMin: selectedData
        ? parseInt(selectedData.SuburbStatistics[0].listingMedianMin)
        : options.SuburbStatistics.ListingMedian.FilterRangeMin[0].From_ID_Int,
      suburbListingRangeMax: selectedData
        ? parseInt(selectedData.SuburbStatistics[0].listingMedianMax)
        : options.SuburbStatistics.ListingMedian.FilterRangeMax[0].To_ID_Int,
      suburbGrowthRangeMin: selectedData
        ? parseInt(selectedData.SuburbStatistics[0].oneYrGrowthMin)
        : options.SuburbStatistics.YearGrowth.FilterRangeMin[0].From_ID_Int,
      suburbGrowthRangeMax: selectedData
        ? parseInt(selectedData.SuburbStatistics[0].oneYrGrowthMax)
        : options.SuburbStatistics.YearGrowth.FilterRangeMax[0].To_ID_Int,
      populationMin: selectedData
        ? parseInt(selectedData.SuburbStatistics[0].populationMin)
        : options.SuburbStatistics.Population.FilterRangeMin[0].From_ID_Int,
      populationMax: selectedData
        ? parseInt(selectedData.SuburbStatistics[0].populationMax)
        : options.SuburbStatistics.Population.FilterRangeMax[0].To_ID_Int,
      daysOnMarketMin: selectedData
        ? parseInt(selectedData.SuburbStatistics[0].daysOnMarketMin)
        : options.SuburbStatistics.DaysOnMarket.FilterRangeMin[0].From_ID_Int,
      daysOnMarketMax: selectedData
        ? parseInt(selectedData.SuburbStatistics[0].daysOnMarketMax)
        : options.SuburbStatistics.DaysOnMarket.FilterRangeMax[0].To_ID_Int,
      vacancyRateMin: selectedData
        ? parseInt(selectedData.SuburbStatistics[0].vacancyRateMin)
        : options.SuburbStatistics.VacancyRate.FilterRangeMin[0].From_ID_Int,
      vacancyRateMax: selectedData
        ? parseInt(selectedData.SuburbStatistics[0].vacancyRateMax)
        : options.SuburbStatistics.VacancyRate.FilterRangeMax[0].To_ID_Int,

      valueInitiated: true,
    });
    if (options && selectedData && selectedData.Strategy && selectedData.Strategy.length > 0) {
      const defaultKeywords = options?.StrategiesCollection?.StrategiesAndKeywords?.filter(
        (x) => x.FilterStrategyID == parseInt(selectedData.Strategy[0].strategyTypeId)
      );
      if (defaultKeywords && defaultKeywords.length > 0) {
        setKeywords(defaultKeywords[0].Keywords);
        setStrategyKeywords(defaultKeywords[0].Keywords);
      }
    }
  };

  const resetFilters = () => {
    setCountry(0);
    setTimeout(() => {
      setCountry(1);
    }, 100);
    dispatch(clearFilterValue());
    initializeFormData();
    selectedDropdownValue.locationtags = [];
    resetSearchOnlyScreen();
    setCurrentState(null);
    setShowMap(true);
  };

  const getLabel = (optiondata: [], keyWordId: string, id: number, label: string) => {
    const data = optiondata.find((x) => x[keyWordId] == id);
    if (data) {
      return data[label];
    }
    return "";
  };

  const getLocationTypeById = (number: number): string => {
    const types: { [key: string]: number } = {
      postcodeLimit: 1,
      addressLimit: 2,
      suburbLimit: 3,
      postcodeRadius: 4,
      suburbtype: 5,
    };

    for (const key in types) {
      if (types[key] === number) {
        return key;
      }
    }
    return "";
  };

  const getLocationSuburb = () => {
    if (
      selectedDropdownValue?.locationBasicsSuburbs?.length > 0 &&
      selectedDropdownValue?.locationBasicsSuburbs?.some((x) => x.includes("all suburbs"))
    ) {
      return [
        {
          SuburbId: "0",
          Suburb: selectedDropdownValue?.locationBasicsSuburbs[0],
        },
      ];
    } else {
      return selectedDropdownValue?.locationBasicsSuburbs.map((item) => {
        return {
          SuburbId: selectedDropdownValue?.suburbs?.find((x) => x.Label == item).FilterSuburbID,
          Suburb: item,
        };
      });
    }
  };

  const getRegionDistrictName = (stateid: number, id: number) => {
    if (currentCountry == COUNTRY.AU) {
      const data = options?.LocationBasicAUCollection?.State?.find((state: any) => state.FilterStateID === stateid);
      if (data) {
        return data.Regions.find((x) => x.FilterRegionID === id);
      }
    } else {
      const data = options?.LocationBasicNZCollection?.Regions?.find(
        (district: any) => district.FilterRegionID === stateid
      );
      if (data) {
        return data.Districts.find((x) => x.FilterDistrictID === id);
      }
    }
  };

  const getLocality = () => {
    let locality = [];
    if (selectedDropdownValue?.locationBasicsSuburbs?.length > 0) {
      locality = selectedDropdownValue?.locationBasicsSuburbs.map((x) => {
        return { Locality: x };
      });
    }
    return locality;
  };

  const getSearchObject = async () => {
    const savedSearchData = {
      Country: {
        MemberID: localStorage.getItem("member_id"),
        Limit: "200",
        Title: "",
        OrderBy: selectedDropdownValue.orderBy,
        CountryCode: currentCountry,
        Strategy: [
          {
            strategyTypeId: selectedDropdownValue.keywordStrategies,
            strategyType: getLabel(
              options?.StrategiesCollection?.StrategiesAndKeywords,
              "FilterStrategyID",
              selectedDropdownValue.keywordStrategies,
              "Label"
            ),
            keywords: selectedDropdownValue?.keywordPhrases,
            KeywordsOperand: getLabel(
              options?.Strategy?.KeywordPhrase,
              "FilterKeywordSearchTypeID",
              selectedDropdownValue?.keywordPhrase,
              "Label"
            ),
            KeywordsOperandId: selectedDropdownValue?.keywordPhrase,
            exclude: selectedDropdownValue?.excKeywordPhrases,
            ExcludedKeywordsOperand: getLabel(
              options?.Strategy?.ExcKeywordPhrase,
              "FilterExcludeKeywordSearchTypeID",
              selectedDropdownValue?.excKeywordPhrase,
              "Label"
            ),
            ExcludedKeywordsOperandId: selectedDropdownValue?.excKeywordPhrase,
          },
        ],
        StrategyTypeKeywords: strategyKeywords.map((x) => {
          return { keywordId: x.FilterKeywordID, keyword: x.Label };
        }),
        PropertySpecifics: [
          {
            dwellingType: selectedDropdownValue?.dwellingTypes,
            includeLand: selectedDropdownValue?.includeLand ? "true" : "false",
            priceFrom: selectedDropdownValue?.priceRangeMin,
            priceTo: selectedDropdownValue?.priceRangeMax,
            bedRoomMin: selectedDropdownValue?.bedRoomsMin,
            bedRoomMax: selectedDropdownValue?.bedRoomsMax,
            bathRoomMin: selectedDropdownValue?.bathRoomsMin,
            bathRoomMax: selectedDropdownValue?.bathRoomsMax,
            carPortMin: selectedDropdownValue?.carportsMin,
            carPortMax: selectedDropdownValue?.carportsMax,
            landSizeMin: selectedDropdownValue?.landSizesMin,
            landSizeMax: selectedDropdownValue?.landSizesMax,
          },
        ],
        LocationAdvanced: [
          {
            locationSearchType: getLocationTypeById(selectedDropdownValue?.locationSearchType),
            limitPostcode:
              selectedDropdownValue?.locationSearchType == LOCATION_ADVANCED.POSTCODE_LIMIT
                ? selectedDropdownValue.locationAdanced.limitPostcode != ""
                  ? selectedDropdownValue.locationAdanced?.limitPostcode?.toString()
                  : options?.LocationAdvanced[0]?.limitPostcode
                : "",
            limitStreet:
              selectedDropdownValue?.locationSearchType == LOCATION_ADVANCED.ADDRESS_LIMIT
                ? selectedDropdownValue.locationAdanced.limitStreet != ""
                  ? selectedDropdownValue.locationAdanced?.limitStreet?.toString()
                  : options?.LocationAdvanced[0]?.limitStreet
                : "",
            limitSuburb:
              selectedDropdownValue?.locationSearchType == LOCATION_ADVANCED.SUBURB_LIMIT &&
              currentCountry == COUNTRY.AU
                ? selectedDropdownValue.locationAdanced.limitSuburb != ""
                  ? selectedDropdownValue.locationAdanced?.limitSuburb?.toString()
                  : options?.LocationAdvanced[0]?.limitSuburb
                : "",
            postcodeRadiusFrom:
              selectedDropdownValue?.locationSearchType == LOCATION_ADVANCED.POSTCODE_RADIUS
                ? selectedDropdownValue?.postcodeRadiusFrom
                : "",
            postcodeRadiusTo:
              selectedDropdownValue?.locationSearchType == LOCATION_ADVANCED.POSTCODE_RADIUS
                ? selectedDropdownValue?.postcodeRadiusTo
                : "",
            ofPostcode:
              selectedDropdownValue?.locationSearchType == LOCATION_ADVANCED.POSTCODE_RADIUS
                ? selectedDropdownValue.locationAdanced.ofPostcode
                : "",
            suburbRadiusFrom:
              selectedDropdownValue?.locationSearchType == LOCATION_ADVANCED.SUBURB_LIMIT
                ? selectedDropdownValue?.suburbRadiusFrom
                : "",
            suburbRadiusTo:
              selectedDropdownValue?.locationSearchType == LOCATION_ADVANCED.SUBURB_LIMIT
                ? selectedDropdownValue?.suburbRadiusTo
                : "",
            OflimitSuburb:
              selectedDropdownValue?.locationSearchType == LOCATION_ADVANCED.SUBURB_LIMIT
                ? selectedDropdownValue.locationAdanced.OflimitSuburb
                : "",
            selectedSuburbType:
              selectedDropdownValue?.locationSearchType == LOCATION_ADVANCED.SUBURB_TYPE
                ? selectedDropdownValue.locationAdanced.selectedSuburbType
                : "",
          },
        ],
        LocationBasicsSuburbs: currentState ? getLocationSuburb() : [],
        SuburbStatistics: [
          {
            suburbMedianMin: selectedDropdownValue?.suburbMedianRangeMin,
            suburbMedianMax: selectedDropdownValue?.suburbMedianRangeMax,
            vacancyRateMin: selectedDropdownValue?.vacancyRateMin,
            vacancyRateMax: selectedDropdownValue?.vacancyRateMax,
            listingMedianMin: selectedDropdownValue?.suburbListingRangeMin,
            listingMedianMax: selectedDropdownValue?.suburbListingRangeMax,
            rentalYieldMin: selectedDropdownValue?.filterRangeMin,
            rentalYieldMax: selectedDropdownValue?.filterRangeMax,
            oneYrGrowthMin: selectedDropdownValue?.suburbGrowthRangeMin,
            oneYrGrowthMax: selectedDropdownValue?.suburbGrowthRangeMax,
            populationMin: selectedDropdownValue?.populationMin,
            populationMax: selectedDropdownValue?.populationMax,
            daysOnMarketMin: selectedDropdownValue?.daysOnMarketMin,
            daysOnMarketMax: selectedDropdownValue?.daysOnMarketMax,
          },
        ],
        ListingDetail: [
          {
            propertyYieldMin: selectedDropdownValue?.propertyYieldMin,
            propertyYieldMax: selectedDropdownValue?.propertyYieldMax,
            listingAgeMin: selectedDropdownValue?.listingAgeMin,
            listingAgeMax: selectedDropdownValue?.listingAgeMax,
            showDuplicates: "false",
          },
        ],
        LocationBasics: [
          {
            Country: currentCountry,
            StateId: currentCountry == COUNTRY.AU ? currentState?.id : "",
            State: currentCountry == COUNTRY.AU ? currentState?.title : "",
            Region:
              currentCountry != COUNTRY.AU
                ? currentState?.title
                : selectedDropdownValue?.selectedRegion == "0"
                ? OPTION_ALL
                : getRegionDistrictName(currentState?.id, selectedDropdownValue?.selectedRegion)?.Label,
            RegionId: currentCountry != COUNTRY.AU ? currentState?.id : selectedDropdownValue?.selectedRegion,
            DistrictId: currentCountry != COUNTRY.AU ? selectedDropdownValue?.selectedDistrict : "",
            District:
              currentCountry != COUNTRY.AU
                ? selectedDropdownValue?.selectedDistrict == "0"
                  ? OPTION_ALL
                  : getRegionDistrictName(currentState?.id, selectedDropdownValue?.selectedDistrict)?.Label
                : "",
          },
        ],
      },
      Locality: !currentState ? getLocality() : [],
    };
    return savedSearchData;
  };

  const searchResult = async () => {
    setLoading(true);
    setSearchLoading();
    const search = await getSearchObject();
    await httpPost(`${ApiEndPoints.searchSummary}/${currentCountry}/Search/Summary/`, search)
      .then((result) => {
        if (result.status == 200) {
          handleSearchClick(result.data, search);
          setShowMap(false);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const hideSaveThisSearch = useCallback(() => {
    setShowSaveThisSearch(false);
  }, []);

  const openSaveThisSearch = async () => {
    const searchobject = await getSearchObject();
    setSearchData(searchobject);
    setShowSaveThisSearch(true);
  };
  return (
    <>
      {options && selectedDropdownValue && (
        <REIDialoge
          showHeader={true}
          header={`Save this search`}
          visible={showSaveThisSearch}
          handleEditClick={hideSaveThisSearch}
          position="top"
          style={{ width: "60vw" }}
          customClass="save-this-search"
          contentComponent={<SaveThisSearch closeDialog={hideSaveThisSearch} savesearch={searchData} />}
        ></REIDialoge>
      )}
      <div className="flex flex-col">
        <div className="flex-1 border border-gray-300">
          <div
            className={classNames(
              "flex",
              searchOnlyScreen ? "lg:flex-nowrap md:flex-wrap flex-wrap" : "flex-wrap",
              "gap-1"
            )}
          >
            <div className={searchOnlyScreen ? "lg:w-[40%] w-full" : "w-full"}>
              <div className="flex flex-row lg:justify-between w-full gap-1">
                <div className="flex-0 w-7/12 text-start">
                  {(searchOnlyScreen || showMap) && (
                    <REISelectButton
                      label="name"
                      value={country}
                      onChange={handleCountryChange}
                      items={COUNTRY_OPTIONS}
                    ></REISelectButton>
                  )}
                </div>
                {!searchOnlyScreen && (
                  <div className="flex-0 w-5/12 text-end">
                    <REIButton
                      label=""
                      disabled={false}
                      loading={false}
                      icon="pi pi-map"
                      tooltip={showMap ? "Hide Map" : "Show Map"}
                      onClick={() => {
                        !searchOnlyScreen ? setShowMap(!showMap) : setShowMap(false);
                      }}
                    />
                  </div>
                )}
              </div>
              {(searchOnlyScreen || showMap) && (
                <div className="flex flex-col lg:flex-row lg:w-full gap-1">
                  <div className="flex-0 w-full pt-5 align-center-vl">
                    {country === 1 && (
                      <AustraliaMap
                        currentstate={!currentState ? -1 : parseInt(filterData?.Country?.LocationBasics[0].StateId)}
                        onStateChange={onStateChange}
                      />
                    )}
                    {country !== 1 && (
                      <NewZealandMap
                        region={!currentState ? -1 : parseInt(filterData?.Country?.LocationBasics[0].RegionId)}
                        onStateChange={onStateChange}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>

            <div className={searchOnlyScreen ? "lg:w-[60%] w-full" : "w-full"}>
              {options && (
                <div className="edit-saved-search">
                  <LocationComponent
                    selectedState={currentState}
                    optionsdata={options}
                    selectedDropdownValue={selectedDropdownValue}
                    handleDropdownChange={handleDropdownChange}
                    country={currentCountry}
                    showMapOptions={searchOnlyScreen || showMap}
                  />
                  <REIDivider />
                  <StrategyComponent
                    selectedDropdownValue={selectedDropdownValue}
                    handleDropdownChange={handleDropdownChange}
                    optionsdata={options}
                    strategyKeywords={strategyKeywords}
                    strategyOnChange={(e) => setStrategyKeywords(e.value)}
                    keywords={keywords}
                  ></StrategyComponent>
                  <REIDivider />
                  <PropertyTypeComponent
                    selectedDropdownValue={selectedDropdownValue}
                    handleDropdownChange={handleDropdownChange}
                    optionsdata={options}
                  ></PropertyTypeComponent>
                  <REIDivider />
                  <SuburbComponent
                    selectedDropdownValue={selectedDropdownValue}
                    handleDropdownChange={handleDropdownChange}
                    optionsdata={options}
                  ></SuburbComponent>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="border-solid border border-x-0 border-b-0 border-gray-300 min-h-16 p-2">
          <div className="flex flex-wrap gap-1 justify-end max-sm:flex-wrap-reverse">
            <REIButton
              label="Save search"
              disabled={false}
              loading={false}
              icon="pi pi-save"
              classNames="max-sm:w-full"
              onClick={() => openSaveThisSearch()}
            />
            <REIButton
              label="Reset filters"
              disabled={false}
              icon="pi pi-refresh"
              loading={false}
              classNames={"max-sm:w-full"}
              onClick={() => resetFilters()}
            />
            <REIButton
              label="Search"
              icon="pi pi-search"
              disabled={false}
              loading={loading}
              classNames={"max-sm:w-full"}
              onClick={() => searchResult()}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchDetail;
