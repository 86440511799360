import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { ColumnType } from "../../utils/constants";

interface Columns {
  field: string;
  header?: string;
  icon?: string;
  image?: string;
  className?: string;
  bodyClassName?: any;
  width?: string;
  type?: string;
  options?: { value: any; label: string }[];
  onClick?: (event: any, rowData: any) => void;
  template?: React.ReactNode;
  bodyTemplate?: (rowData: any) => React.ReactNode;
}

interface ActionType {
  icon: string;
  label: string;
  className?: string;
  onClick: (rowData: any) => void;
}

interface Props {
  data: any[];
  columns: Columns[];
  actions?: ActionType[];
  customclass?: string;
  pagination?: boolean;
  pageSize?: number;
}

const REIDataTable: React.FC<Props> = ({ data, columns, actions, customclass, pagination = false, pageSize = 10 }) => {
  const renderColumns = () => {
    return columns.map((col) => (
      <Column
        key={col.field}
        field={col.field}
        header={renderHeaderWithIcon(col.header, col.icon, col.image, col.className)}
        body={col.bodyTemplate ? col.bodyTemplate : renderColumnValue(col)}
        style={{ width: col.width }}
      />
    ));
  };

  const renderHeaderWithIcon = (header?: string, icon?: string, image?: string, className?: string) => {
    if (image) {
      return (
        <span className={classNames("p-column-title", "p-unselectable-text")}>
          <img src={image} alt={header} className="p-mr-2" style={{ width: "14px", height: "14px" }} />
          {header}
        </span>
      );
    } else if (icon) {
      return (
        <span className={classNames("p-column-title", "p-unselectable-text", className)}>
          {icon == "pi pi-question-circle" ? (
            <>
              {header} <i className={classNames(icon, "p-mr-2")}></i>
            </>
          ) : (
            <>
              <i className={classNames(icon, "p-mr-2")}></i>
              {header}{" "}
            </>
          )}
        </span>
      );
    } else if (header) {
      return <span className={classNames("p-column-title", "p-unselectable-text", className)}>{header}</span>;
    }
    return null;
  };

  const renderColumnValue = (col: Columns) => {
    return (rowData: any) => {
      if (col.type === ColumnType.negativeValues) {
        return <span className={col.bodyClassName(rowData[col.field])}>{rowData[col.field]}</span>;
      }
      if (col.type === ColumnType.link) {
        if (col.onClick) {
          return (
            <div className="flex flex-row w-full text-[var(--primary-color)]">
              <span
                onClick={(event) => {
                  col.onClick && col.onClick(event, rowData);
                }}
                rel="noopener noreferrer"
                className="cursor-pointer"
              >
                {rowData[col.field]}
              </span>
            </div>
          );
        }
        if (col.header == ColumnType.resouceLinks) {
          return (
            <a href={rowData.Url} target="_blank" className="no-underline" rel="noopener noreferrer">
              {rowData[col.field]}
            </a>
          );
        }
        if (col.header == ColumnType.attachmentFiles) {
          return (
            <a href={rowData.FileUrl} target="_blank" className="no-underline" rel="noopener noreferrer">
              {rowData[col.field]}
            </a>
          );
        } else {
          return (
            <a href={rowData[col.field]} target="_blank" className="no-underline" rel="noopener noreferrer">
              {rowData[col.field]}
            </a>
          );
        }
      } else if (col.type === ColumnType.dropdown) {
        return (
          <select value={rowData[col.field]}>
            {col.options?.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        );
      } else if (col.type === ColumnType.custom) {
        return (
          <div
            onClick={(event) => {
              col.onClick && col.onClick(event, rowData);
            }}
          >
            {col.template}
          </div>
        );
      } else {
        return rowData[col.field];
      }
    };
  };

  const renderActionButtons = (rowData: any) => {
    return actions?.map((action, index) => (
      <Button
        key={index}
        icon={action.icon}
        aria-label={action.label}
        className={action.className}
        onClick={() => action.onClick(rowData)}
        size="small"
      />
    ));
  };
  return (
    <>
      {data != undefined && (
        <>
          <DataTable
            value={data}
            size="small"
            paginator={pagination}
            rows={pageSize}
            tableStyle={{}}
            className={customclass}
          >
            {renderColumns()}
            {actions != undefined && (
              <Column field="action" header="" body={(rowData) => renderActionButtons(rowData)} />
            )}
          </DataTable>
        </>
      )}
    </>
  );
};

export default REIDataTable;
