import { useEffect, useState } from "react";
import { REIDropDown, REIInputNumber, REIInputText } from "../../../../components/ui";
import { RadioButton } from "primereact/radiobutton";
import { ILoan } from "../../../../interface/addAnalyser";
import DatePicker from "../../../../components/ui/calender";
import { Slider } from "primereact/slider";
import { GET_LENGTH_OPTIONS } from "../../../../utils/constants";
import { getDateStringWithGivenFormat, getAdjustedFormattedDate } from "../../../../utils/commonUtil";

interface ILoanDetailsTabProps {
  loan: ILoan;
  updateLoanValue?: (loan: ILoan) => void;

  setValues?: (name: string, value: string | number, loan: ILoan) => void;
}

const LoanDetailsTab: React.FC<ILoanDetailsTabProps> = ({ loan, updateLoanValue, setValues }) => {
  const [loans, setLoans] = useState<ILoan>();
  const [reset, setReset] = useState<boolean>(false);
  const getYearOptions = () => [...GET_LENGTH_OPTIONS(0, 99, undefined, 1, "desc")];

  const getMonthOptions = () => [...GET_LENGTH_OPTIONS(0, 11, undefined, 1, "desc")];

  useEffect(() => {
    setLoans(loan);
  }, []);

  useEffect(() => {
    if (reset) {
      !loan.isFromPortfolio ? setValues({ LoanTypeCost: { Loans: [loans] } }) : updateLoanValue(loans);
    }
  }, [loans]);

  const setLoanValues = (name: string, value: string | number, loans: ILoan) => {
    setReset(true);
    setLoans((prevState) => {
      if (!prevState) return undefined;
      const updatedLoan = {
        ...prevState,
        [name]: value !== undefined ? value : 0,
      };
      return updatedLoan;
    });
  };

  const renderSliderWithInput = (label: string, value: number, name: string, max: number) => (
    <div className="flex w-full py-1">
      <div className="w-[20%] mt-2">{label}</div>
      <div className="w-[40%] pr-2">
        <Slider
          key={name}
          value={value}
          onChange={(e) =>
            loans?.isFromPortfolio ? setLoanValues(name, e.value, loans) : setValues(name, e.value, loans)
          }
          className="w-full mt-4"
          min={0}
          max={max}
        />
      </div>
      <div className="w-[40%] flex pr-1">
        <REIInputNumber
          value={value}
          onValueChange={(e) =>
            loans?.isFromPortfolio ? setLoanValues(name, e.target.value, loans) : setValues(name, e.target.value, loans)
          }
          className="w-full"
          useGrouping={true}
        />
      </div>
    </div>
  );

  return (
    loans && (
      <div>
        <div className="flex w-full items-center p-1 space-x-4 gap-2">
          <div className="flex w-1/2 items-center">
            <div className="w-[20%] mt-2">Lender/name of loan</div>
            <div className="w-[80%] flex">
              <REIInputText
                name="loanName"
                value={loans?.Name}
                onChange={(e) =>
                  loans?.isFromPortfolio
                    ? setLoanValues("Name", e.target.value, loans)
                    : setValues("Name", e.target.value, loans)
                }
                type="text"
                className="w-full"
              />
            </div>
          </div>

          <div className="flex w-1/2 items-center space-x-4">
            <div className="flex items-center">
              <RadioButton
                inputId="interestOnly"
                name="loanType"
                value={loans?.IsPrincipal !== 1}
                onChange={(e) =>
                  loans?.isFromPortfolio
                    ? setLoanValues("IsPrincipal", e.checked ? 0 : 1, loans)
                    : setValues("IsPrincipal", e.value, loans)
                }
                checked={loans?.IsPrincipal !== 1}
              />
              <label htmlFor="interestOnly" className="ml-2">
                Interest only
              </label>
            </div>
            <div className="flex items-center">
              <RadioButton
                inputId="principalAndInterest"
                name="loanType"
                value={loans?.IsPrincipal === 1}
                onChange={(e) =>
                  loans?.isFromPortfolio
                    ? setLoanValues("IsPrincipal", e.checked ? 1 : 0, loans)
                    : setValues("IsPrincipal", e.value, loans)
                }
                checked={loans?.IsPrincipal === 1}
              />
              <label htmlFor="principalAndInterest" className="ml-2">
                Principal & interest
              </label>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4">
          {!loans?.isFromPortfolio && renderSliderWithInput("Amount of loan ($)", loans.Amount, "Amount", 1500000)}
          {loans?.isFromPortfolio && renderSliderWithInput("Loan Balance ($)", loans.Balance, "Balance", 560000)}
          <div className="flex w-full items-center p-1">
            <div className="w-[30%] ">Loan length</div>
            <div className="flex w-[60%] items-center space-x-2">
              <REIDropDown
                data={getYearOptions()}
                label="Label"
                value={loans?.Length_Years}
                optionValue="value"
                onSelectionChange={(e) =>
                  loans?.isFromPortfolio
                    ? setLoanValues("Length_Years", e.value, loans)
                    : setValues("Length_Years", e.value, loans)
                }
              />
              <span>yrs</span>
              <REIDropDown
                data={getMonthOptions()}
                label="Label"
                optionValue="value"
                value={loans?.Length_Months}
                onSelectionChange={(e) =>
                  loans?.isFromPortfolio
                    ? setLoanValues("Length_Months", e.value, loans)
                    : setValues("Length_Months", e.value, loans)
                }
              />
              <span>months</span>
            </div>
          </div>

          {renderSliderWithInput("Interest rate (%)", loans?.InterestRate, "InterestRate", 100)}
          {loans.isFromPortfolio && (
            <>
              <div className="flex w-full items-center p-1">
                <div className="w-[30%]">Fixed interest period (yrs)</div>
                <div className="flex w-[60%] items-center space-x-2">
                  <REIDropDown
                    data={getYearOptions()}
                    label="Label"
                    value={loans?.FixedInterestPeriod_Years}
                    optionValue="value"
                    onSelectionChange={(e) => setLoanValues("FixedInterestPeriod_Years", e.value, loans)}
                  />
                  <span>yrs</span>
                  <REIDropDown
                    data={getMonthOptions()}
                    label="Label"
                    optionValue="value"
                    value={loans?.FixedInterestPeriod_Months}
                    onSelectionChange={(e) => setLoanValues("FixedInterestPeriod_Months", e.value, loans)}
                  />
                  <span>months</span>
                </div>
              </div>
              <div className="flex w-full items-center p-1 gap-2">
                <div className="w-[20%]">Loan date</div>
                <DatePicker
                  dateString={
                    loans.Date != ""
                      ? getDateStringWithGivenFormat(new Date(loans.Date))
                      : getDateStringWithGivenFormat(new Date(Date.now()))
                  }
                  onChange={(date) => setLoanValues("Date", getDateStringWithGivenFormat(date.value), loans)}
                ></DatePicker>
              </div>
              <div className="flex w-full items-center p-1 gap-2">
                <div className="w-[20%]">Next rate review date</div>
                <DatePicker
                  dateString={
                    loans.InterestRateReviewDate != ""
                      ? getDateStringWithGivenFormat(new Date(loans.InterestRateReviewDate))
                      : getAdjustedFormattedDate(new Date(Date.now()), { years: 3 })
                  }
                  onChange={(date) =>
                    setLoanValues("InterestRateReviewDate", getDateStringWithGivenFormat(date.value), loans)
                  }
                ></DatePicker>
              </div>
            </>
          )}
          {!loans.isFromPortfolio && (
            <>
              {renderSliderWithInput("Establishment fees ($)", loans.EstablishmentFees, "EstablishmentFees", 10000)}

              {renderSliderWithInput("Other loan costs ($)", loans.OtherLoanCosts, "OtherLoanCosts", 10000)}

              {renderSliderWithInput("Valuation fees ($)", loans.ValuationFees, "ValuationFees", 10000)}
            </>
          )}
        </div>
      </div>
    )
  );
};

export default LoanDetailsTab;
