import { Paginator, PaginatorPageChangeEvent } from "primereact/paginator";

interface IButtonProps {
  first: number;
  rows: number;
  totalRecords: number;
  onPageChange: (event: PaginatorPageChangeEvent) => void;
}

const REIPaginatior: React.FC<IButtonProps> = ({ first, rows, totalRecords, onPageChange }) => {
  return (
    <Paginator
      first={first}
      rows={rows}
      totalRecords={totalRecords}
      rowsPerPageOptions={[10, 20]}
      onPageChange={onPageChange}
      className="border-solid border border-x-0 border-b-0 border-gray-300"
    />
  );
};

export default REIPaginatior;
