export const WatchListService = {
    getPropertyData() {
        return [ {
            title: "Median Listing Price",
            value: "N/A",
        },
        {
            title: "1 yr Listing Price",
            value: "N/A",
        },
        {
            title: "Median Weekly Rent",
            value: "N/A",
        },
        {
            title: "1 yr weekly rent growth",
            value: "N/A",
        },
        {
            title: "Median gross yield",
            value: "N/A",
        }];
    },

    getFeatureData() {
        return [     {
            feature: "Vacancy rate",
            value: "0.7%",
          },
          {
            feature: "1 yr vacancy rate change",
            value: "-0.1%",
          },
          {
            feature: "Average days on market",
            value: "221",
          },
          {
            feature: "Current listings",
            value: "19",
          },
          {
            feature: "Listings cleared in the last 30 days",
            value: "1",
          },
          {
            feature: "Clearance rate",
            value: "5.3%",
          },];
    },

    getResourcesData() {
        return [      
            {
            title: "Full search result details on www.gumtree.com.au",
            value:
              "https://www.myhomesearch.com.au/propertylink/redirect.aspx?lih=-1853121102&c=AU&m=7013EDD811D84878B8A87734961A643F&w=F77ED7B14265B1AFC3272FE220D15184A3659CF33942C0079EA9B4A7E6C8C2D57F47DAD37C93B9DCAFB0E525C218CBE8",
          },
          {
            title: "BERRIMAH on WikiPedia",
            value: "http://en.wikipedia.org/wiki/Berrimah,_Northern_Territory",
          },
          {
            title: "CITY OF DARWIN council website",
            value: "http://www.darwin.nt.gov.au/",
          },];
    },

    getFilesData() {
      return [      
          {
          title: "Full search result details on www.gumtree.com.au",
          value:
            "https://www.myhomesearch.com.au/propertylink/redirect.aspx?lih=-1853121102&c=AU&m=7013EDD811D84878B8A87734961A643F&w=F77ED7B14265B1AFC3272FE220D15184A3659CF33942C0079EA9B4A7E6C8C2D57F47DAD37C93B9DCAFB0E525C218CBE8",
        },
        {
          title: "BERRIMAH on WikiPedia",
          value: "http://en.wikipedia.org/wiki/Berrimah,_Northern_Territory",
        },
        {
          title: "CITY OF DARWIN council website",
          value: "http://www.darwin.nt.gov.au/",
        },];
  },

    getProperties() {
        return Promise.resolve(this.getPropertyData());
    },

    getFeature() {
        return Promise.resolve(this.getFeatureData());
    },
    getResources() {
        return Promise.resolve(this.getResourcesData());
    },
    getFiles() {
      return Promise.resolve(this.getFilesData());
  },
};

