import React, { useEffect, useRef, useState } from "react";
import { InputText } from "primereact/inputtext";
import { COUNTRY } from "../../utils/constants";
import { useGoogleMapsLoader } from "../../utils/commonUtil";

type AddressAutocompleteProps = {
  onPlaceSelected: (place: google.maps.places.PlaceResult | null, address: string | null) => void;
  country?: string;
};

const AddressAutoComplete: React.FC<AddressAutocompleteProps> = ({ onPlaceSelected, country = COUNTRY.AU }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [autocompleteValue, setAutocompleteValue] = useState<string | null>(null);
  const isLoaded = useGoogleMapsLoader();

  useEffect(() => {
    if (!isLoaded) return;

    const initAutocomplete = () => {
      if (inputRef.current && window.google) {
        const options: google.maps.places.AutocompleteOptions = {
          componentRestrictions: { country: country.toLowerCase() },
          fields: ["address_components", "geometry", "icon", "name"],
          strictBounds: false,
        };

        const autocomplete = new google.maps.places.Autocomplete(inputRef.current, options);

        autocomplete.addListener("place_changed", () => {
          const place = autocomplete.getPlace();
          const address = inputRef.current?.value || null;
          setAutocompleteValue(address);

          onPlaceSelected(place, address);
        });
      }
    };

    initAutocomplete();
  }, [isLoaded, onPlaceSelected, country]);

  return (
    <InputText
      ref={inputRef}
      id="pac-input"
      type="text"
      name="pac-input"
      hidden={false}
      className="w-full"
      onChange={(e) => setAutocompleteValue(e.target.value)}
      value={autocompleteValue || ""}
    />
  );
};

export default AddressAutoComplete;
