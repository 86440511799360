import React from "react";
import AssignEntity from "./Child/TaxAndMarket/AssignEntity";
import { IAnalysedProperty } from "../../../interface/addAnalyser";
import { IMemberEntity } from "../../../interface/analysed";
import MarketFectors from "./Child/TaxAndMarket/MarketFectors";
import DepreciationEstimation from "./Child/TaxAndMarket/DepreciationEstimation";

interface ITaxAndMarket {
  setIsValid: (isValid: boolean) => void;
  updateProperty: (updated: Partial<IAnalysedProperty>) => void;
  memberEntities: IMemberEntity[];
}

const TaxAndMarket: React.FC<ITaxAndMarket> = ({ setIsValid, memberEntities, updateProperty }) => {
  return (
    <div className="flex flex-col gap-3">
      <AssignEntity
        memberEntities={memberEntities}
        setIsValid={setIsValid}
        updateProperty={updateProperty}
      ></AssignEntity>
      <MarketFectors setIsValid={setIsValid} updateProperty={updateProperty}></MarketFectors>
      <DepreciationEstimation setIsValid={setIsValid} updateProperty={updateProperty}></DepreciationEstimation>
    </div>
  );
};

export default TaxAndMarket;
