import React, { ReactElement, useEffect, useState } from "react";
import REIToggablePanel from "../../../components/ui/toggablePanel";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { formatCurrency, formatNumberWithDecimal } from "../../../utils/commonUtil";
import { REIButton } from "../../../components/ui";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";

interface PropertyData {
  street: string;
  suburb: string;
  state: string;
  postcode: string;
  country: string;
  type: string;
  currentMarketEstimate: number;
  netEquity: number;
  lvr: string;
  dsr: string;
  preTaxCashflow: number;
  capitalGrowth: number;
  capitalGrowthPercentage: number;
}

const MyPropertyAtGlance: React.FC<{ entityId: string }> = ({ entityId }) => {
  const trackerData = useSelector((state: RootState) => state.myTracker.trackerData);
  const [transformedData, setTransformedData] = useState<PropertyData[]>([]);
  const [propertiesTotal, setPropertiesTotal] = useState<any>({});

  useEffect(() => {
    if (trackerData) {
      const entityData = trackerData.find((item) => item.EntityId === entityId);
      if (entityData) {
        setTransformedData(transformData(entityData.Properties || []));
        setPropertiesTotal(entityData.PropertyTotal.PropertyKeyValuesTotal || {});
      }
    }
  }, [trackerData, entityId]);

  const transformData = (data: any[]): PropertyData[] => {
    return data.flatMap((property) =>
      ["Forecast", "Actuals", "Variance"].map((type) => ({
        street: property.StreetAddress,
        suburb: property.Suburb,
        state: property.State,
        postcode: property.Postcode,
        country: property.Country,
        type,
        currentMarketEstimate: Number(property.PropertyKeyValuesPortion[type]?.CurrentMarketValue) || 0,
        netEquity: Number(property.PropertyKeyValuesPortion[type]?.NetEquity) || 0,
        lvr: formatNumberWithDecimal(Number(property.PropertyKeyValuesPortion[type]?.LVR) || 0, 0, 2),
        dsr: formatNumberWithDecimal(Number(property.PropertyKeyValuesPortion[type]?.DSR) || 0, 0, 2),
        preTaxCashflow: Number(property.PropertyKeyValuesPortion[type]?.PreTaxCashflow) || 0,
        capitalGrowth: Number(property.PropertyKeyValuesPortion[type]?.CapitalGrowth) || 0,
        capitalGrowthPercentage: Number(property.PropertyKeyValuesPortion[type]?.CapitalGrowthPercentage) || 0,
      }))
    );
  };

  const currencyTemplate = (value: number) => (
    <span className={value < 0 ? "text-red-600" : ""}>
      {value < 0 ? `(${formatCurrency(-value)})` : formatCurrency(value)}
    </span>
  );

  const percentageTemplate = (value: number) => (
    <span className={value < 0 ? "text-red-600" : ""}>
      {value < 0 ? `(${formatNumberWithDecimal(-value, 2, 2)}%)` : `${formatNumberWithDecimal(value, 2, 2)}%`}
    </span>
  );

  const renderFooter = (category: string) => (
    <Row>
      <Column footer={category == "Forecast" ? "Totals/averages" : ""} />
      <Column field="type" footer={category} />
      <Column
        footer={currencyTemplate(propertiesTotal?.[category]?.CurrentMarketValue)}
        footerStyle={{ textAlign: "right" }}
      />
      <Column footer={currencyTemplate(propertiesTotal?.[category]?.NetEquity)} footerStyle={{ textAlign: "right" }} />
      <Column
        footer={`${formatNumberWithDecimal(propertiesTotal?.[category]?.LVR, 2, 2)}%`}
        footerStyle={{ textAlign: "right" }}
      />
      <Column footer={percentageTemplate(propertiesTotal?.[category]?.DSR)} footerStyle={{ textAlign: "right" }} />
      <Column
        footer={currencyTemplate(propertiesTotal?.[category]?.PreTaxCashflow)}
        footerStyle={{ textAlign: "right" }}
      />
      <Column
        footer={currencyTemplate(propertiesTotal?.[category]?.CapitalGrowth)}
        footerStyle={{ textAlign: "right" }}
      />
      <Column
        footer={percentageTemplate(propertiesTotal?.[category]?.CapitalGrowthPercentage)}
        footerStyle={{ textAlign: "right" }}
      />
    </Row>
  );

  const footerGroup = (
    <ColumnGroup>
      {renderFooter("Forecast")}
      {renderFooter("Actuals")}
      {renderFooter("Variance")}
    </ColumnGroup>
  );

  return (
    trackerData && (
      <REIToggablePanel
        header=""
        istoggleable={false}
        defaultCollapse={false}
        customHeader={(options) => (
          <div className={`${options.className} justify-content-space-between`}>
            <span className="font-bold">My properties at a glance – current view</span>
            <REIButton label="Add a new property" classNames="bg-blue-500 text-white" onClick={() => {}} />
          </div>
        )}
        contentComponent={
          <DataTable
            value={transformedData}
            rowGroupMode="rowspan"
            groupRowsBy="street"
            sortField="street"
            sortMode="single"
            sortOrder={1}
            footerColumnGroup={footerGroup}
          >
            <Column
              field="street"
              header="Address/property name"
              body={(rowData) => (
                <span className="cursor-pointer text-blue-700">{`${rowData.street}, ${rowData.suburb}, ${rowData.state}, ${rowData.postcode} (${rowData.country})`}</span>
              )}
            />
            <Column field="type" header="Type" />
            <Column
              field="currentMarketEstimate"
              header="Current Market Estimate"
              body={(rowData) => currencyTemplate(rowData.currentMarketEstimate)}
              align="right"
            />
            <Column
              field="netEquity"
              header="Net Equity"
              body={(rowData) => currencyTemplate(rowData.netEquity)}
              align="right"
            />
            <Column field="lvr" header="LVR" body={(rowData) => `${rowData.lvr}%`} align="right" />
            <Column field="dsr" header="DSR" body={(rowData) => percentageTemplate(rowData.dsr)} align="right" />
            <Column
              field="preTaxCashflow"
              header="Pre-tax Cash Flow"
              body={(rowData) => currencyTemplate(rowData.preTaxCashflow)}
              align="right"
            />
            <Column
              field="capitalGrowth"
              header="$ Cap Growth"
              body={(rowData) => currencyTemplate(rowData.capitalGrowth)}
              align="right"
            />
            <Column
              field="capitalGrowthPercentage"
              header="% Cap Growth"
              body={(rowData) => percentageTemplate(rowData.capitalGrowthPercentage)}
              align="right"
            />
          </DataTable>
        }
      />
    )
  );
};

export default MyPropertyAtGlance;
