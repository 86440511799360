import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { PaginatorPageChangeEvent } from "primereact/paginator";
import REIPaginatior from "../../../components/ui/paginatior";
import { PropertyDetails } from "../../WatchList/ViewProperty/PropertyDetails";
import { IWatchListData } from "../../../interface/Watchlist/propertyDetails";
import { setShowFileUploadNotes } from "../../../store/slice/propertyDetailsSlice";
import { REIDropDown } from "../../../components/ui";
import { INVESTAR_SEARCH_SORTBY } from "../../../utils/constants";
import { setInvestarSearchSortBy } from "../../../store/slice/investarSearchSlice";

interface ISearchListProps {
  data: {
    CountryCode?: string;
    Root: any[];
    SearchedProperties?: number;
    RowsCount?: number;
  };
}

const SearchList: React.FC<ISearchListProps> = ({ data }) => {
  const results = useRef<IWatchListData[]>([]);
  const [arrayList, setArrayList] = useState<IWatchListData[]>([]);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [sortBy, setSortBy] = useState(1);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setShowFileUploadNotes(false));
    const contryCode = data.CountryCode || "";
    const memberId = localStorage.getItem("member_id") || "";

    results.current = data.Root.map((item) => {
      const result: IWatchListData = {
        BathRooms: item.Summary.BathRooms || 0,
        BedRooms: item.Summary.BedRooms || 0,
        Carport: item.Summary.Carport || 0,
        Category: item.Summary.Category || "",
        CountryID: contryCode,
        DateDownloaded: item.Summary.DateDownloadedFirst || 0,
        LinkIDHash: item.Summary.LinkIDHash || "",
        MemberId: memberId,
        Postcode: item.Summary.Postcode || "",
        Price: item.Summary.Price || 0,
        PriceFormatted: item.Summary.PriceFormatted || "",
        PropertyID: item.Summary.PropertyId || "",
        StateRegion: item.Summary.StateRegion || "",
        StreetAddress: item.Summary.StreetAddress || "",
        Suburb: item.Summary.Suburb || "",
        Title: item.Summary.Title || "",
        WatchlistID: item.Summary.WatchlistID || "",
        Website: item.Summary.Website || "",
        address: item.Summary.address || "",
      };
      return result;
    });
  }, [data]);

  useEffect(() => {
    setArrayList(
      paginateArray(results.current, { first: 0, rows: 10, page: 1, pageCount: results.current.length / rows })
    );
  }, [results, data]);

  const onPageChange = (event: PaginatorPageChangeEvent) => {
    setFirst(event.first);
    setRows(event.rows);
    setArrayList(paginateArray(results.current, event));
  };

  const paginateArray = (array: IWatchListData[], pagination: PaginatorPageChangeEvent) => {
    const start = pagination.first;
    const end = start + pagination.rows;
    return array.slice(start, end);
  };

  return (
    <>
      {data?.Root?.length === 0 && (
        <div className="flex items-center justify-center h-64">
          <div className="text-center">
            <p className="text-gray-500 text-lg">No records found</p>
          </div>
        </div>
      )}
      {data?.Root?.length > 0 && (
        <>
          <div className="flex flex-col">
            <div className="flex flex-col md:flex-row w-full lg:items-center rounded-md bg-gray-100 p-1 border border-solid border-gray-200 mb-2">
              <div className="flex-0 max-md:w-full w-2/12 max-md:text-center">
                <label className="block text-sm text-[var(--primary-color)]">
                  Showing: {first + 1}-{rows + first} of ({results?.current?.length})
                </label>
              </div>
              <div className="flex-0 max-md:w-full w-6/12 text-center">
                <span className="text-sm text-[var(--primary-color)]">
                  {data?.SearchedProperties} listings searched: showing 1-{results?.current?.length} of{" "}
                  {data?.RowsCount} matching properties
                </span>
              </div>
              <div className="flex-0 max-md:w-full max-md:text-center w-4/12 flex items-center justify-end space-x-2">
                <label className="text-sm text-[var(--primary-color)]">Sort by:</label>
                <REIDropDown
                  value={sortBy}
                  onSelectionChange={(e) => {
                    setSortBy(e.value);
                    dispatch(setInvestarSearchSortBy(e.value));
                  }}
                  data={INVESTAR_SEARCH_SORTBY}
                  label="name"
                  className="w-[80%]"
                  optionValue="value"
                  placeholder="Inactive"
                />
              </div>
            </div>
            <div className="flex-1 border border-gray-300 mt-[-4px]">
              {arrayList.map((item, index) => (
                <div
                  key={index}
                  className="w-full rounded-md border-solid border-2 border-gray-200 shadow-lg bg-white p-2 mt-2"
                >
                  <PropertyDetails propertyDetails={item} />
                </div>
              ))}
            </div>

            <div className="">
              <REIPaginatior
                first={first}
                rows={rows}
                totalRecords={results.current.length}
                onPageChange={onPageChange}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SearchList;
