import { useEffect, useRef, useState } from "react";
import { httpPost, useHttpGets } from "../../services/httpService";
import { Messages, Payment_Method_ENV, USER_DETAIL } from "../../utils/constants";
import { ApiEndPoints } from "../../utils/endpoints";
import { Toast } from "primereact/toast";

interface IAddPaymentMethod {
  onsuccess: any;
  isAddPaymentMethodMode: boolean;
  accountId: string;
}

const AddPaymentMethod: React.FC<IAddPaymentMethod> = ({ isAddPaymentMethodMode, accountId, onsuccess }) => {
  var prepopulateFields = {};
  const userDetails = JSON.parse(localStorage.getItem(USER_DETAIL));
  const toastRef = useRef<Toast>(null);
  const myAccountAppEndpoint = `${ApiEndPoints.myaccount}/SubscribeHPM2?country=${userDetails.Country}&environment=${Payment_Method_ENV}`;
  const [refreshPaymentData, setRefreshPaymentData] = useState<boolean>(false);

  useEffect(() => {
    if (isAddPaymentMethodMode) {
      useHttpGets(myAccountAppEndpoint)
        .then((result) => {
          if (result?.data != null && !result.error) {
            loadHostedPage(result.data);
            onsuccess(false);
          } else {
            toastRef.current?.show({
              severity: "success",
              summary: Messages.ERROR,
              detail: result.error,
              life: 2000,
            });
          }
        })
        .catch((error) => {
          toastRef.current?.show({
            severity: "success",
            summary: Messages.ERROR,
            detail: "There was an error. Please contact support.\nError:\n" + error,
            life: 2000,
          });
        });
    }
  }, [isAddPaymentMethodMode, refreshPaymentData]);

  var params = {
    tenantId: "",
    id: "",
    token: "",
    signature: "",
    style: "overlay",
    key: "",
    submitEnabled: "true",
    field_accountId: accountId,
    url: ApiEndPoints.paymentPageUrl,
  };

  const loadHostedPage = (data: any) => {
    params["id"] = data.id, 
    params["tenantId"] = data.tenantId;
    params["token"] = data.token;
    params["signature"] = data.signature, 
    params["key"] = data.key;
    params["field_accountId"] = accountId;
    Z.renderWithErrorHandler(params, prepopulateFields, callback, errorMessageCallback);
  };

  const errorMessageCallback = (key: string, code: string, message: string, rawGatewayInfo: any) => {
    var errorMessage = message;
    if (key != "error") {
      switch (key) {
        case "creditCardNumber":
          if (code === "001") {
            errorMessage = "Card number required. Please input the card number first.";
          } else if (code === "002") {
            errorMessage = "Number does not match credit card. Please try again.";
          }
          break;

        case "cardSecurityCode":
          break;

        case "creditCardExpirationYear":
          break;

        case "creditCardExpirationMonth":
          break;

        case "creditCardType":
          break;
      }
    } else {
      if (code == "unknown" && rawGatewayInfo) {
        errorMessage = rawGatewayInfo;
      } else {
        if (errorMessage == "[Attempt_Exceed_Limitation] Attempt exceed the limitation, refresh page to try again.") {
          errorMessage = "Page refreshed due to access token overuse.";
        }
        errorMessage = "Validation failed : " + errorMessage;
      }
    }
    Z.sendErrorMessageToHpm(key, errorMessage);
  };

  const callback = (response: any) => {
    if (response.success) {
      callback_success(response.refId);
    } else {
      alert("errorcode=" + response.errorCode + ", errorMessage=" + response.errorMessage);
    }
  };

  const callback_success = (ref_id: any) => {
    httpPost(ApiEndPoints.addPaymentMethodWorkatoUrl, {
      pmId: ref_id,
      accId: accountId,
    }).then(() => {
      const element = document.getElementById("zuora_payment");
      if (element) {
        element.classList.add("hidden");
      }
      setRefreshPaymentData(true);
      onsuccess(true, ref_id);
    });
  };

  return <div id="zuora_payment"></div>;
};

export default AddPaymentMethod;
