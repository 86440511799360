import { useState } from "react";
import AnnualPropertyExpense from "./Child/RentAndExpense/AnnualPropertyExpense";
import { IAnalysedProperty } from "../../../interface/addAnalyser";
import AnnualRentalReturn from "./Child/RentAndExpense/AnnualRentalReturn";

interface IRentAndExpense {
  setIsValid: (isValid: boolean) => void;
  updateProperty: (updated: Partial<IAnalysedProperty>) => void;
}

const RentAndExpense: React.FC<IRentAndExpense> = ({ setIsValid, updateProperty }) => {
  const [totalRentalReturn, setTotalRentalReturn] = useState<number>(0);

  return (
    <div className="flex flex-col gap-3">
      <AnnualRentalReturn
        setIsValid={setIsValid}
        updateProperty={updateProperty}
        updateRentalReturn={setTotalRentalReturn}
      ></AnnualRentalReturn>
      <AnnualPropertyExpense
        setIsValid={setIsValid}
        updateProperty={updateProperty}
        rentalReturnTotal={totalRentalReturn}
      ></AnnualPropertyExpense>
    </div>
  );
};

export default RentAndExpense;
