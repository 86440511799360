import { ToggleButton, ToggleButtonChangeEvent } from "primereact/togglebutton";

interface REIToggleButtonProps {
  onlabel: string;
  offlabel: string;
  isSelected: boolean;
  onToggleChange: (event: ToggleButtonChangeEvent) => void;
  customclass?: string;
  index: string;
}

const REIToggleButton: React.FC<REIToggleButtonProps> = ({
  onlabel,
  offlabel,
  isSelected,
  onToggleChange,
  customclass,
  index,
}) => {
  return (
    <ToggleButton
      onLabel={onlabel}
      offLabel={offlabel}
      checked={isSelected}
      onChange={onToggleChange}
      className={customclass}
      // className="rounded-none border-r-0"
      key={index}
      id={index.toString()} // Convert index to string for id
    />
  );
};

export default REIToggleButton;
