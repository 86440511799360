import React, { useEffect, useState } from "react";
import { useHttpGet } from "../../../services/httpService";
import { ApiEndPoints } from "../../../utils/endpoints";
import { MEMBER_KEY } from "../../../utils/constants";
import { REIButton, REIDataTable, REIDialoge, REIProgressSpinner } from "../../../components/ui";
import PropertyInfo from "../../../components/shared/propertyInfo";
import PropertyInvestmentCalculator from "./PropertyInvestmentCalculator";
import { useNavigate } from "react-router-dom";

const AnalyserWatchList: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [showPropertyInvestmentCalculator, setShowPropertyInvestmentCalculator] = useState<boolean>(false);
  const [selectedWathclistItem, setSelectedWathclistItem] = useState<any>(null);
  const { data: watchlistData, error } = useHttpGet(
    `${ApiEndPoints.analyser}/Watchlist/${localStorage.getItem(MEMBER_KEY)}`,
    undefined,
    true
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (watchlistData) {
      setLoading(false);
    }
  }, [watchlistData]);

  if (loading) {
    return (
      <div className="text-center">
        <REIProgressSpinner />
      </div>
    );
  }

  if (!watchlistData.length) {
    return <div className="text-center">No data selected for analyser.</div>;
  }

  const handleOnAddressClick = (rowData: any) => {
    if (rowData.isAnalysed !== "1") {
      setSelectedWathclistItem(rowData);
      setShowPropertyInvestmentCalculator(true);
    } else {
      navigate("/property-analyser/analysis-report", {
        state: { propertyAnalyserId: rowData.PropetyAnalyserId, propertyName: rowData.streetAddress },
      });
    }
  };

  const hidePropertyInvcestmentCalculator = () => {
    setSelectedWathclistItem(null);
    setShowPropertyInvestmentCalculator(false);
  };

  const renderAddress = (rowData: any) => (
    <>
      <div className="flex flex-row w-full text-[#4e9fba]">
        <span
          className="mr-1 pt-2 cursor-pointer"
          onClick={() => {
            handleOnAddressClick(rowData);
          }}
        >
          {`${rowData.StreetAddress}, ${rowData.Suburb}, ${rowData.StateRegion}`}
        </span>
      </div>
      <PropertyInfo bathrooms={rowData.Bathrooms} bedrooms={rowData.Bedrooms} carport={rowData.Carport} />
    </>
  );

  const renderAnalysedStatus = (rowData: any) => (
    <div className="text-center">
      {rowData.isAnalysed === "1" ? (
        <REIButton icon="pi pi-check" text={true} onClick={() => {}} severity="success" />
      ) : (
        <REIButton icon="pi pi-minus" text={true} onClick={() => {}} severity="danger" />
      )}
    </div>
  );

  const columns = [
    {
      field: "address",
      header: "Address",
      bodyTemplate: renderAddress,
    },
    {
      field: "isAnalysed",
      header: "Analysed?",
      width: "100px",
      bodyTemplate: renderAnalysedStatus,
    },
  ];

  return (
    <>
      <REIDialoge
        showHeader={true}
        header={`Calculate property's investment potential`}
        visible={showPropertyInvestmentCalculator}
        handleEditClick={hidePropertyInvcestmentCalculator}
        position="top"
        style={{ width: "55vw" }}
        customClass="edit-default-profile"
        contentComponent={
          <PropertyInvestmentCalculator
            closeDialog={hidePropertyInvcestmentCalculator}
            wathclistItem={selectedWathclistItem}
          />
        }
      ></REIDialoge>
      <div className="overflow-y-auto min-h-[164px]">
        <REIDataTable data={watchlistData} columns={columns} actions={undefined} customclass="max-h-[164px]" />
      </div>
    </>
  );
};

export default AnalyserWatchList;
