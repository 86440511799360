import { classNames } from "primereact/utils";
import React, { FC, ReactNode } from "react";

interface BoxComponentProps {
  children: ReactNode;
  cssClass?: string; // Optional cssClass prop
}

const BoxComponent: FC<BoxComponentProps> = ({ children, cssClass }) => {
  return (
    <div
      className={`w-full lg:w-1/4 bg-white max-h-64 overflow-hidden rounded-sm shadow-sm p-2 align-top justify-center text-center ${
        cssClass || ""
      }`}
    >
      <div className="p-1">{children}</div>
    </div>
  );
};

export default BoxComponent;
