import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { IAnalysisSummaryResponse } from "../../interface/analysisSummary";
import { ApiEndPoints } from "../../utils/endpoints";
import { MEMBER_KEY, USER_DETAIL } from "../../utils/constants";
import { useHttpGets } from "../../services/httpService";
import { IMemberEntity } from "../../interface/analysed";

interface IAnalysisSummaryState {
  analysisSummary?: IAnalysisSummaryResponse;
  memeberEntity?: IMemberEntity[];
  error: string | null;
  reload: boolean;
}

const initialState: IAnalysisSummaryState = {
  analysisSummary: undefined,
  error: null,
  reload: false,
};

export const fetchAnalysisSummary = createAsyncThunk(
  "analysisSummary/fetch",
  async (propertyAnalyserId: string, { rejectWithValue }) => {
    try {
      const memberDetail = JSON.parse(localStorage.getItem(USER_DETAIL) || "{}");
      const response = await useHttpGets(
        `${ApiEndPoints.analyser}/AnalysisReport?MemberId=${localStorage.getItem(
          MEMBER_KEY
        )}&PropertyAnalyserId=${propertyAnalyserId}&MemberCountryCode=${memberDetail.Country}`,
        undefined
      );

      if (response.status === 200) {
        if (response.data) {
          return response.data as IAnalysisSummaryResponse;
        } else {
          return rejectWithValue("Failed to fetch analysis summary");
        }
      } else {
        return rejectWithValue("Failed to fetch analysis summary");
      }
    } catch (error) {
      return rejectWithValue("An error occurred during the fetch");
    }
  }
);

export const fetchMemeberDetail = createAsyncThunk(
  "analysisSummary/fetchMemeberEntity",
  async (_, { rejectWithValue }) => {
    try {
      const memberDetail = JSON.parse(localStorage.getItem(USER_DETAIL) || "{}");
      const result = await useHttpGets(
        `${ApiEndPoints.tracker}/MemberEntities?memberId=${localStorage.getItem(MEMBER_KEY)}&userCountryCode=${
          memberDetail.Country
        }&memberName=${memberDetail.FirstName + " " + memberDetail.LastName}`
      );
      if (result.status === 200) {
        if (result?.data && !result.error) {
          return result.data as IMemberEntity[];
        } else {
          return rejectWithValue("Failed to fetch member detail");
        }
      } else {
        return rejectWithValue("Failed to fetch member detail");
      }
    } catch (error) {
      return rejectWithValue("An error occurred during the fetch");
    }
  }
);

const analysisSummarySlice = createSlice({
  name: "analysisSummary",
  initialState,
  reducers: {
    getAnalysisSummaryData(state, action: PayloadAction<IAnalysisSummaryResponse>) {
      state.analysisSummary = action.payload;
    },
    getMemeberData(state, action: PayloadAction<IMemberEntity[]>) {
      state.memeberEntity = action.payload;
    },
    triggerReload(state) {
      state.reload = !state.reload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAnalysisSummary.fulfilled, (state, action: PayloadAction<IAnalysisSummaryResponse>) => {
        state.analysisSummary = action.payload;
        state.error = null;
      })
      .addCase(fetchAnalysisSummary.rejected, (state, action) => {
        state.error = action.payload as string;
      })
      .addCase(fetchMemeberDetail.fulfilled, (state, action: PayloadAction<IMemberEntity[]>) => {
        state.memeberEntity = action.payload;
        state.error = null;
      })
      .addCase(fetchMemeberDetail.rejected, (state, action) => {
        state.error = action.payload as string;
      });
  },
});

export const { triggerReload } = analysisSummarySlice.actions;
export default analysisSummarySlice.reducer;
