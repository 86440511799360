import React, { ReactElement, useEffect, useState } from "react";
import REIToggablePanel from "../../../components/ui/toggablePanel";
import AnalysisVariables from "./Child/AnalysisVariables";
import SummaryInput from "./Child/SummaryInput";
import AnalysisOverview from "./Child/AnalysisOverview";
import { REIButton } from "../../../components/ui";
import { YEARS } from "../../../utils/constants";
import Statistics from "./Child/Statistics";
import NonCashDeduction from "./Child/NonCashDeduction";
import OverviewSummary from "./Child/OverviewSummary";
import CashDeductions from "./Child/CashDeductions";

const Analysis: React.FC = () => {
  const [editFlag, setEditFlag] = React.useState(false);
  const [showAllColumns, setShowAllColumns] = useState(false);
  const [visibleYears, setVisibleYears] = useState<{ label: string; defaultShow: boolean }[]>([]);

  useEffect(() => {
    setVisibleYears(showAllColumns ? YEARS : YEARS.filter((year) => year.defaultShow));
  }, [showAllColumns]);

  const resetEditFlag = () => {
    setEditFlag(false);
  };

  const headerTemplate = (options: any): ReactElement<any, any> => {
    const className = `${options.className} justify-content-space-between`;

    return (
      <div className={className}>
        <div className="flex align-items-center gap-2">
          <REIButton
            icon=""
            label={!showAllColumns ? "Show all years(0-10)" : "Show only years 1,3,5 & 10"}
            classNames="p-button-link p-0 m-0"
            onClick={() => setShowAllColumns(!showAllColumns)}
          />
        </div>
        <div>
          <REIButton
            icon="pi pi-user-edit"
            label={!editFlag ? "Edit future years forecast" : "Stop forecast editing"}
            classNames="p-button mr-2"
            onClick={() => setEditFlag(!editFlag)}
          />
          {options.togglerElement}
        </div>
      </div>
    );
  };

  const renderHeaderAndAnalysisOverview = () => (
    <>
      <div className="py-0">
        <table className="w-full text-left border-solid border border-x-0 border-y-0 border-b border-gray-300">
          <thead>
            <tr>
              <th className="border-b p-2"></th>
              {visibleYears.map((year) => (
                <th key={year.label} className="border-b p-2 text-end w-36 text-blue-600">
                  {year.label}
                </th>
              ))}
            </tr>
          </thead>
        </table>
      </div>
      <AnalysisOverview editFlag={editFlag} visibleYears={visibleYears} resetEditFlag={resetEditFlag} />
      <Statistics editFlag={editFlag} visibleYears={visibleYears} resetEditFlag={resetEditFlag} />
      <CashDeductions editFlag={editFlag} visibleYears={visibleYears} resetEditFlag={resetEditFlag} />
      <NonCashDeduction editFlag={editFlag} visibleYears={visibleYears} resetEditFlag={resetEditFlag} />
      <OverviewSummary editFlag={editFlag} visibleYears={visibleYears} resetEditFlag={resetEditFlag} />
    </>
  );

  return (
    <>
      <div className="flex flex-col lg:flex-row flex-wrap gap-2">
        <div className="flex flex-col lg:flex-row lg:w-full gap-2">
          <div className="flex-0 lg:w-[40%] w-full">
            <REIToggablePanel
              header="Variables"
              istoggleable={false}
              defaultCollapse={false}
              contentComponent={<AnalysisVariables />}
            />
          </div>
          <div className="flex-0 lg:w-[60%] w-full">
            <REIToggablePanel
              header="Summary Inputs"
              istoggleable={false}
              defaultCollapse={false}
              customClassName="summary-input-panel"
              contentComponent={<SummaryInput />}
            />
          </div>
        </div>
        <div className="w-full">
          <REIToggablePanel
            header="Show all years(0-10)"
            istoggleable={false}
            defaultCollapse={false}
            customHeader={headerTemplate}
            contentComponent={renderHeaderAndAnalysisOverview()}
          />
        </div>
      </div>
    </>
  );
};

export default Analysis;
