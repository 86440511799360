import { useState, useEffect } from "react";
import axios from "axios";
import { API_USER, API_PWD, TOKEN_KEY } from "../utils/constants";
import { axiosInstance } from "./interceptor";

const generateHeaders = () => {
  return {
    "Content-Type": "application/json",
    Usertoken: localStorage.getItem(TOKEN_KEY) || undefined,
    APIUserName: API_USER,
    APIPassword: API_PWD,
  };
};

const generateFileHeader = (data: any) => {
  return {
    Usertoken: localStorage.getItem(TOKEN_KEY) || undefined,
    APIUserName: API_USER,
    APIPassword: API_PWD,
    FileName: data.fileData.name,
    FileSize: data.fileData.size,
    MemberPropertyID: data.memberPropertyId,
    IsAttachement: data.IsAttachement,
  };
};

const generateData = (data: any) => {
  return data.file;
};

export const useHttpGet = (path: string, params: any = {}, shouldFetch: boolean = true) => {
  const [data, setData] = useState<any>(null);
  const [error, setError] = useState<any>(null);
  const [statusCode, setStatusCode] = useState<number | null>(null);

  useEffect(() => {
    const cancelToken = axios?.CancelToken?.source?.();

    const getData = async () => {
      try {
        let res = await axiosInstance.get(path, {
          params,
          headers: generateHeaders(),
          cancelToken: cancelToken?.token,
          validateStatus: () => true,
        });
        if (res?.data && res?.status === 200) {
          setData(res.data);
        }
        if (res?.status) {
          setStatusCode(res.status);
        }
      } catch (err) {
        if (axios?.isCancel?.(err)) {
          console.log("Request canceled", err.message);
        } else {
          setError(err);
        }
      }
    };

    getData();

    return () => {
      cancelToken?.cancel?.();
    };
  }, [path, shouldFetch]);

  return { data, error, statusCode };
};

export const useHttpGets = async (path: string, params: any = {}) => {
  let output = { data: null, error: null, status: null };

  await axiosInstance
    .get(path, {
      params,
      headers: generateHeaders(),
      validateStatus: () => true,
    })
    .then((res: any) => {
      output = { data: res.data, error: null, status: res.status };
    })
    .catch((err: any) => {
      output = {
        data: null,
        error: err.response ? err.response.data : err.message,
        status: null,
      };
    });

  return output;
};

export const httpPost = async (path: string, body: any, params: any = {}) => {
  let output = { data: null, error: null, status: null };

  await axiosInstance
    .post(path, body, {
      params: params,
      headers: generateHeaders(),
    })
    .then((res: any) => {
      output = { data: res.data, error: null, status: res.status };
    })
    .catch((err: any) => {
      output = {
        data: null,
        error: err.response ? err.response.data : err.message,
        status: null,
      };
    });

  return output;
};

export const httpPostFile = async (path: string, body: any, params: any = {}, data: any) => {
  let output = { data: null, error: null, status: null };

  await axiosInstance
    .post(path, body, {
      params: params,
      data: generateData(data),
      headers: generateFileHeader(data),
    })
    .then((res: any) => {
      output = { data: res.data, error: null, status: res.status };
    })
    .catch((err: any) => {
      output = {
        data: null,
        error: err.response ? err.response.data : err.message,
        status: null,
      };
    });

  return output;
};

export const httpDelete = async (path: string, params: any = {}, optimistic?: boolean) => {
  let output = { data: null, error: null, status: null };

  await axiosInstance
    .delete(path, {
      headers: generateHeaders(),
    })
    .then((res: any) => {
      output = { data: res.data, error: null, status: res.status };
    })
    .catch((err: any) => {
      output = {
        data: null,
        error: err.response ? err.response.data : err.message,
        status: null,
      };
    });

  return output;
};

export const httpUpdate = async (path: string, body: any, optimistic?: boolean, params: any = {}) => {
  let output = { data: null, error: null, status: null };

  await axiosInstance
    .put(path, body, { headers: generateHeaders() })
    .then((res: any) => {
      output = { data: res.data, error: null, status: res.status };
    })
    .catch((err: any) => {
      output = {
        data: null,
        error: err.response ? err.response.data : err.message,
        status: null,
      };
    });

  return output;
};
