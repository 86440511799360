import React, { useEffect, useRef, useState } from "react";
import { FileUpload } from "primereact/fileupload";
import { REIButton } from "../ui";
import Gallery from "../ui/gallery";

interface IFileUploadProps {
  uploadHandler: (event: any) => void;
  onSelect: (event: any) => void;
  onError: () => void;
  onClear: () => void;
  chooseOptions: any;
  fileData: any[];
  itemTemplate: (item: any) => JSX.Element;
}

const ImageUploadWithGallery: React.FC<IFileUploadProps> = ({
  uploadHandler,
  onSelect,
  onError,
  onClear,
  chooseOptions,
  fileData,
  itemTemplate,
}) => {
  const fileUploadRef = useRef(null);
  const [Images, SetImages] = useState([]);
  const hasFiles = useRef<boolean>(false);

  const clearFileContent = () => {
    fileUploadRef.current.clear();
  };

  useEffect(() => {
    fileUploadRef.current && clearFileContent();
    SetImages(fileData);
    hasFiles.current = fileData && fileData.length != 0 ? true : false;
  }, [fileData]);

  return (
    <div className="flex flex-col">
      <>
        <div className="h-[156px]">
          {hasFiles.current && (
            <Gallery
              itemTemplate={itemTemplate}
              imageData={Images}
              showThumbnails={false}
              circular
              autoPlay={false}
              showIndicators={true}
              classNames="h-full"
            ></Gallery>
          )}
          {!hasFiles.current && <div className="content-center align-middle bg-gray-300 h-full">No Photos</div>}
        </div>
        <div className="bg-gray-300 flex flex-row content-center image-gallery-container">
          <FileUpload
            customUpload
            id="basicFileUpload"
            mode="basic"
            accept="image/*"
            className="text-xs mt-1 basicFileUpload overflow-hidden"
            uploadHandler={uploadHandler}
            onBeforeSelect={onSelect}
            ref={fileUploadRef}
            name="basicFileUpload"
            onSelect={onSelect}
            onError={onError}
            onClear={onClear}
            chooseOptions={chooseOptions}
            chooseLabel="Browse"
          />
          <REIButton
            icon="pi pi-times"
            classNames="ml-1 mt-1"
            onClick={() => {
              clearFileContent();
            }}
          ></REIButton>
        </div>
      </>
    </div>
  );
};

export default ImageUploadWithGallery;
