import React, { useEffect, useState } from "react";
import { IAnalysedProperty } from "../../../../../interface/addAnalyser";
import { useAnalysedPropertyContext } from "../AnalysedPropertyContext";
import { IPropertyMarketFactor } from "../../../../../interface/addAnalyser";
import InputSlider from "../../../../../components/ui/slider";
import { REIInputNumber } from "../../../../../components/ui";
import { PROPERTY_TYPE } from "../../../../../utils/constants";
import { formatNumberWithDecimal } from "../../../../../utils/commonUtil";

interface IMarketFectors {
  setIsValid: (isValid: boolean) => void;
  updateProperty: (updated: Partial<IAnalysedProperty>) => void;
}

const MarketFectors: React.FC<IMarketFectors> = ({ setIsValid, updateProperty }) => {
  const { analysedProperty } = useAnalysedPropertyContext();
  const [marketFectors, setMarketFectors] = useState<IPropertyMarketFactor | undefined>(undefined);
  const [reset, setReset] = useState<boolean>(false);

  useEffect(() => {
    if (analysedProperty && !reset) {
      setMarketFectors(analysedProperty.TaxMarket.PropertyMarketFactor);
      setReset(true);
    }
  }, [analysedProperty]);

  useEffect(() => {
    if (marketFectors) {
      setIsValid(true);
      updateProperty({
        ...analysedProperty,
        TaxMarket: {
          ...analysedProperty?.TaxMarket,
          PropertyDepreciationEstimation: analysedProperty?.TaxMarket.PropertyDepreciationEstimation,
          PropertyMarketFactor: marketFectors,
          PropertyTaxRate: analysedProperty?.TaxMarket.PropertyTaxRate,
        },
      });
    }
  }, [marketFectors]);

  const setValues = (name: string, value: string | number) => {
    setMarketFectors((prevState) => {
      if (!prevState) return undefined;
      const updatedExpense = {
        ...prevState,
        [name]: value !== undefined ? value : 0,
      };
      return updatedExpense;
    });
  };

  return (
    <div>
      <div className="relative rounded-lg pl-3 pr-1 py-3 border-solid border border-gray-300 bg-white mt-2">
        <div className="default-profile-header-container">
          <h3 className="text-[var(--primary-color)] mt-0 m-1 text-lg">Market Fectors</h3>
        </div>
        <div className="p-4">
          <div className="flex flex-wrap mb-4">
            <div className="w-full md:w-1/2 p-2">
              <div className="flex items-center justify-evenly">
                <label className="w-1/3 text-left" htmlFor="Rates">
                  Consumer price index (%)
                </label>
                <InputSlider
                  value={marketFectors?.ConsumerPriceIndex}
                  step={0.1}
                  onChange={(e) => setValues("ConsumerPriceIndex", Array.isArray(e.value) ? e.value[0] : e.value)}
                  classNames="w-1/2 mx-2"
                  min={0}
                  max={10}
                />
                <REIInputNumber
                  value={marketFectors?.ConsumerPriceIndex}
                  onValueChange={(e) => setValues("ConsumerPriceIndex", e.target.value)}
                  useGrouping={true}
                />
              </div>
            </div>
            <div className="w-full md:w-1/2 p-2" title="Add estimated annual cost.">
              <div className="flex items-center justify-evenly">
                <label className="w-1/3 text-left" htmlFor="BodyCorp">
                  Capital growth (%)
                </label>
                <InputSlider
                  value={marketFectors?.CapitalGrowth}
                  onChange={(e) => setValues("CapitalGrowth", Array.isArray(e.value) ? e.value[0] : e.value)}
                  step={0.1}
                  classNames="w-1/2 mx-2"
                  min={0}
                  max={10}
                />
                <REIInputNumber
                  value={marketFectors?.CapitalGrowth}
                  onValueChange={(e) => setValues("CapitalGrowth", e.target.value)}
                  useGrouping={true}
                />
              </div>
            </div>
          </div>
          {analysedProperty?.PropertyDetail.Growth.StatsPostcode != null && (
            <div className="flex flex-wrap mb-4">
              <div className="w-full p-2">
                <label className="w-1/3">
                  {`* Historical capital growth for a `}
                  <b>
                    {PROPERTY_TYPE.find((type) => type.value == analysedProperty?.PropertyDetail.PropertyTypeId)?.type}
                  </b>
                  {` in `}
                  <b>{analysedProperty?.PropertyDetail.Growth.StatsSuburb}</b>
                  {` is `}
                  <b>
                    {formatNumberWithDecimal(
                      analysedProperty?.PropertyDetail.Growth.StatsSuburbLongTermGrowth * 100,
                      1,
                      1
                    )}
                    %
                  </b>
                  {` within the `}
                  <b>{analysedProperty?.PropertyDetail.Growth.StatsRegion}</b>
                  {` region which has historical annual property growth for a `}
                  <b>
                    {PROPERTY_TYPE.find((type) => type.value == analysedProperty?.PropertyDetail.PropertyTypeId)?.type}
                  </b>
                  {` of `}
                  <b>
                    {formatNumberWithDecimal(
                      analysedProperty?.PropertyDetail.Growth.StatsRegionLongTermGrowth * 100,
                      1,
                      1
                    )}
                    %
                  </b>
                  {`.`}
                </label>
              </div>
            </div>
          )}
          <div className="flex flex-wrap mb-4">
            <div className="w-full md:w-1/2 p-2">
              <div className="flex items-center justify-evenly">
                <label className="w-1/3 text-left" htmlFor="Rates">
                  Land tax ($)
                </label>
                <InputSlider
                  value={marketFectors?.Expense_LandTax}
                  onChange={(e) => setValues("Expense_LandTax", Array.isArray(e.value) ? e.value[0] : e.value)}
                  classNames="w-1/2 mx-2"
                  min={0}
                  max={20000}
                />
                <REIInputNumber
                  value={marketFectors?.Expense_LandTax}
                  onValueChange={(e) => setValues("Expense_LandTax", e.target.value)}
                  useGrouping={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarketFectors;
