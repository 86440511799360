import { PropsWithChildren } from "react";
import PrimeReactRootProvider from "./primeReactProvider";
import ReduxProvider from "./reduxProvider";

type P = PropsWithChildren;

export default function Providers({ children }: P) {
  return (
    <>
      <ReduxProvider>
        <PrimeReactRootProvider>{children}</PrimeReactRootProvider>
      </ReduxProvider>
    </>
  );
}
