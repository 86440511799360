import { ProgressSpinner } from "primereact/progressspinner";

const REIProgressSpinner: React.FC = (props) => (
  <ProgressSpinner
    style={{ width: "40px", height: "40px" }}
    strokeWidth="4"
    animationDuration="1s"
    {...props}
  />
);

export default REIProgressSpinner;
