import { useState, useRef, useEffect } from "react";
import { Avatar } from "primereact/avatar";
import { TieredMenu } from "primereact/tieredmenu";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { MenuIcons, MenuLabels } from "../../utils/constants";
import { REIButton } from "../ui";

const AppToolBar: React.FC = () => {
  const tieredMenuRef = useRef<TieredMenu>(null);
  const [activeMenu, setActiveMenu] = useState<MenuLabels>(MenuLabels.INVESTAR_SEARCH);
  const [menuOpen, setMenuOpen] = useState(false); // State to toggle the menu on mobile
  const navigate = useNavigate();
  const location = useLocation();

  // Map routes to MenuLabels for easy activation based on current path
  const routeMap: { [key in MenuLabels]: string } = {
    [MenuLabels.INVESTAR_SEARCH]: "/",
    [MenuLabels.RP_DATA_PRICEFINDER]: "/rp-data-pricefinder",
    [MenuLabels.PROPERTY_ANALYSER]: "/property-analyser",
    [MenuLabels.PORTFOLIO_TRACKER]: "/portfolio-tracker",
    [MenuLabels.MY_ACCOUNT]: "/myaccount",
    [MenuLabels.HOME]: "/",
  };

  // Update active menu based on the current route
  useEffect(() => {
    const currentPath = location.pathname;
    const foundMenu = Object.keys(routeMap).find((key) => routeMap[key as MenuLabels] === currentPath) as
      | MenuLabels
      | undefined;

    if (foundMenu) {
      setActiveMenu(foundMenu);
    }
  }, [location.pathname]);

  // Logout function
  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };

  // Define submenu items separately to keep code DRY
  const submenuItems = [
    {
      label: "My Account",
      icon: "pi pi-users",
      command: () => navigate("/myaccount"),
    },
    { label: "Logout", icon: "pi pi-sign-out", command: handleLogout },
  ];

  // Opens the submenu
  const openSubMenu = (event: React.MouseEvent) => {
    tieredMenuRef.current?.toggle(event);
  };

  // Handles menu click and routing
  const handleMenuClick = (label: MenuLabels) => {
    setActiveMenu(label);
    navigate(routeMap[label]);
  };

  // Reusable button rendering logic
  const renderMenuButton = (icon: string, label: MenuLabels) => (
    <REIButton
      icon={icon}
      label={label}
      aria-label="Filter"
      onClick={() => handleMenuClick(label)}
      text={activeMenu !== label}
      classNames={`shadow-none rounded-none border-solid border border-y-0 border-r-0 border-gray-300 h-14 ${
        activeMenu === label ? "" : "text-[var(--text-color-secondary)]"
      }`}
    />
  );

  // Toggles the menu visibility on mobile
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="layout-topbar">
      <Link to="/" className="layout-topbar-logo">
        <img
          src="https://info.realestateinvestar.com.au/hubfs/Logos/REI%20and%20MRI/Resized/RE%20Investar-Logo-MRI_Colour%20web%20229x115px%20(1).png"
          alt="Logo"
          style={{ height: "80px", width: "150px" }}
        />
      </Link>

      {/* Hamburger Icon for Mobile */}
      <div className="block lg:hidden p-4 cursor-pointer" onClick={toggleMenu}>
        <REIButton icon="pi pi-bars" classNames="" onClick={() => {}} />
      </div>

      {/* Menu buttons for larger screens */}
      <div
        className={`w-full lg:flex ${menuOpen ? "block" : "hidden"} ${menuOpen ? "bg-slate-900" : ""} ${
          menuOpen ? "ml-[-1px]] mt-[204px]" : ""
        } lg:block`}
      >
        <div className="w-full flex flex-col lg:flex-row ">
          {renderMenuButton(MenuIcons.INVESTAR_SEARCH, MenuLabels.INVESTAR_SEARCH)}
          {renderMenuButton(MenuIcons.RP_DATA_PRICEFINDER, MenuLabels.RP_DATA_PRICEFINDER)}
          {renderMenuButton(MenuIcons.PROPERTY_ANALYSER, MenuLabels.PROPERTY_ANALYSER)}
          {renderMenuButton(MenuIcons.PORTFOLIO_TRACKER, MenuLabels.PORTFOLIO_TRACKER)}
          {renderMenuButton(MenuIcons.MY_ACCOUNT, MenuLabels.MY_ACCOUNT)}
        </div>
      </div>

      <TieredMenu model={submenuItems} popup ref={tieredMenuRef} />
      <div className="layout-topbar-menu">
        <Avatar
          image="https://primefaces.org/cdn/primereact/images/avatar/amyelsner.png"
          shape="circle"
          className="p-link layout-topbar-button"
          onClick={openSubMenu}
        />
      </div>
    </div>
  );
};

export default AppToolBar;
