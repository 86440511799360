import React, { ReactElement, useEffect, useState } from "react";
import REIToggablePanel from "../../../components/ui/toggablePanel";
import { RadioButton } from "primereact/radiobutton";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { formatCurrency, formatNumber, formatNumberWithDecimal } from "../../../utils/commonUtil";

const KeyMemberAtGlance: React.FC<{ entityId: string }> = ({ entityId }) => {
  const trackerData = useSelector((state: RootState) => state.myTracker.trackerData);
  const [isActual, setIsActual] = useState(true);
  const [keyGlanceDetail, setKeyGlanceDetail] = useState({
    LVR: 0,
    DSR: 0,
    YieldPurchasePrice: 0,
    YieldCurrentValue: 0,
    TotalProperties: 0,
    AverageAnnualCapitalGrowth: 0,
    CurrentPortfolioValue: 0,
  });

  useEffect(() => {
    if (trackerData) {
      const data = trackerData.find((item) => item.EntityId === entityId);

      if (data) {
        const totalProperties = isActual ? data.KeyNumberActualsPortion : data.KeyNumberForecastTotal;

        setKeyGlanceDetail({
          LVR: parseFloat(totalProperties.LVR),
          DSR: parseFloat(totalProperties.DSR),
          YieldPurchasePrice: parseFloat(totalProperties.YieldVsPurchasePrice),
          YieldCurrentValue: parseFloat(totalProperties.YieldVsCurrentValuation),
          TotalProperties: parseFloat(totalProperties.NoOfProperties),
          AverageAnnualCapitalGrowth: parseFloat(totalProperties.AverageCapitalGrowth),
          CurrentPortfolioValue: parseFloat(totalProperties.TotalValue),
        });
      }
    }
  }, [trackerData, entityId, isActual]);

  const headerTemplate = (options: any): ReactElement<any, any> => {
    const className = `${options.className} justify-content-space-between`;

    return (
      <div className={className}>
        <div className="flex align-items-center gap-2">
          <span className="font-bold">Key numbers at a glance – current view</span>
        </div>
        <div className="flex align-items-center gap-2 mr-4">
          <RadioButton
            inputId="forcastType"
            name="forcastType"
            value={isActual}
            onChange={(e) => {
              setIsActual(!e.target.value);
            }}
            checked={isActual}
          />
          <label htmlFor="purachaseValueinPercentage" className="ml-1">
            Actual
          </label>

          <RadioButton
            inputId="forcastType"
            name="forcastType"
            value={!isActual}
            onChange={(e) => {
              setIsActual(e.target.value);
            }}
            checked={!isActual}
          />
          <label htmlFor="purachaseValueinPercentage" className="ml-1">
            Forecast
          </label>
        </div>
      </div>
    );
  };

  const GlanceDetail = () => (
    <div>
      <div className="flex flex-row content-center">
        <div className="w-1/6">{formatNumberWithDecimal(keyGlanceDetail.LVR, 2, 2)} %</div>
        <div className="w-1/6">{formatNumberWithDecimal(keyGlanceDetail.DSR, 2, 2)}</div>
        <div className="w-1/6">{formatNumberWithDecimal(keyGlanceDetail.YieldPurchasePrice, 2, 2)} %</div>
        <div className="w-1/6">{formatNumberWithDecimal(keyGlanceDetail.YieldCurrentValue, 2, 2)} %</div>
        <div className="w-1/6">{formatNumber(keyGlanceDetail.TotalProperties)}</div>
        <div className="w-1/6">
          {keyGlanceDetail.AverageAnnualCapitalGrowth < 0 ? (
            <span className="text-red-600">
              ({formatNumberWithDecimal(-keyGlanceDetail.AverageAnnualCapitalGrowth, 2, 2)}%)
            </span>
          ) : (
            <span>({formatNumberWithDecimal(keyGlanceDetail.AverageAnnualCapitalGrowth, 2, 2)}%)</span>
          )}
        </div>
        <div className="w-1/6">{formatCurrency(keyGlanceDetail.CurrentPortfolioValue)}</div>
      </div>

      <div className="flex flex-row content-center mt-2">
        {["LVR", "DSR", "Yield %", "Yield %", "Properties", "Average annual", "Current"].map((label, index) => (
          <div key={index} className="w-1/6">
            {label}
          </div>
        ))}
      </div>
      <div className="flex flex-row content-center">
        {[
          "(loan to value ratio)",
          "(debt service ratio)",
          "(of purchase price)",
          "(of current valuation)",
          "in portfolio",
          "capital growth",
          "portfolio value",
        ].map((subLabel, index) => (
          <div key={index} className="w-1/6">
            {subLabel}
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <>
      {trackerData && (
        <REIToggablePanel
          header=""
          istoggleable={false}
          defaultCollapse={false}
          customHeader={headerTemplate}
          contentComponent={<GlanceDetail />}
        />
      )}
    </>
  );
};

export default KeyMemberAtGlance;
