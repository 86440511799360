import React, { useEffect, useState } from "react";
import { IAnalysedProperty, ILoan } from "../../../../interface/addAnalyser";
import { useAnalysedPropertyContext } from "./AnalysedPropertyContext";
import REITab from "../../../../components/ui/tab";
import { REIButton } from "../../../../components/ui";
import { formatDayCycle } from "../../../../utils/commonUtil";
import LoanDetailsTab from "./LoanDetailsTab";

interface ILoanDetailsProps {
  setIsValid: (isValid: boolean) => void;
  updateProperty: (updated: Partial<IAnalysedProperty>) => void;
  isFromPortolioTracker: boolean | undefined;
}

const LoanDetails: React.FC<ILoanDetailsProps> = ({ setIsValid, updateProperty, isFromPortolioTracker = false }) => {
  const { analysedProperty } = useAnalysedPropertyContext();
  const [loans, setLoans] = useState<ILoan[]>([]);
  const [tabs, setTabs] = useState<{ header: string; content: JSX.Element; closable: boolean }[]>([]);
  const [reset, setReset] = useState<boolean>(false);

  useEffect(() => {
    if (analysedProperty && !reset) {
      const loans = analysedProperty.LoanTypeCost.Loans.map((loan: ILoan) => ({
        ...loan,
        isFromPortfolio: false,
      }));

      setLoans(loans || []);
      setReset(true);
    }
  }, [analysedProperty]);

  useEffect(() => {
    if (loans.length > 0) {
      const newTabs = loans.map((loan, index) => ({
        header: `${formatDayCycle(index + 1)} loan`,
        content: <LoanDetailsTab loan={loan} setValues={setValues} />,
        closable: index !== 0,
      }));
      setTabs(newTabs);
    }
  }, [loans]);

  useEffect(() => {
    if (loans.length > 0) {
      setIsValid(true);
      updateProperty({
        LoanTypeCost: {
          ...analysedProperty?.LoanTypeCost,
          Loans: loans,
          PropertyOtherPurchaseExpense: analysedProperty?.LoanTypeCost.PropertyOtherPurchaseExpense || {
            CapitalInvestment: 0,
            OtherCost: 0,
            SolicitorsCost: 0,
            StampDuty: 0,
          },
          PropertyPurchaseCost: analysedProperty?.LoanTypeCost.PropertyPurchaseCost || {
            CashDeposit: 0,
            MarketValueNow: 0,
            PurchaseAmount: 0,
            EquityDeposit: 0,
            isEnterPercentage: "0",
            isEnterValue: "0",
          },
        },
      });
    }
  }, [loans]);

  const setValues = (name: string, value: string | number, loan: ILoan) => {
    setLoans((prevState) => prevState.map((l) => (l.LoanId === loan.LoanId ? { ...loan, [name]: value || 0 } : l)));
  };

  return (
    <div className="w-full pt-2">
      <div className="relative rounded-lg pl-3 pr-1 py-3 border-solid border border-gray-300 bg-white mt-2">
        <div className="absolute top-2 right-2 z-10">
          <REIButton
            label=""
            icon="pi pi-plus"
            tooltip="Add Loan"
            onClick={() => {
              const newLoan = { ...loans[loans.length - 1], id: Date.now() };
              setLoans([...loans, newLoan]);
              setTabs([
                ...tabs,
                {
                  header: `${formatDayCycle(loans.length + 1)} loan`,
                  content: <LoanDetailsTab loan={newLoan} setValues={setValues} />,
                  closable: true,
                },
              ]);
            }}
          />
        </div>
        {tabs.length > 0 && <REITab tabs={tabs} />}
      </div>
    </div>
  );
};

export default LoanDetails;
