import React, { useEffect, useRef } from "react";
import { useGoogleMapsLoader } from "../../utils/commonUtil";

interface Marker {
  lat: number;
  lng: number;
  title?: string;
}

interface MapWithMarkerProps {
  center: { lat: number; lng: number };
  zoom: number;
  markers: Marker[];
}

const MapWithMarker: React.FC<MapWithMarkerProps> = ({ center, zoom, markers }) => {
  const mapRef = useRef<HTMLDivElement>(null);
  const isLoaded = useGoogleMapsLoader();

  useEffect(() => {
    if (!isLoaded) return;

    const initMap = () => {
      if (window.google && window.google.maps) {
        const map = new window.google.maps.Map(mapRef.current as HTMLElement, {
          center,
          zoom,
          mapId: process.env.GOOGLE_MAPS_ID,
        });

        const createCustomMarker = (position: { lat: number; lng: number }, title?: string) => {
          const markerContent = document.createElement("div");
          markerContent.style.position = "relative";
          markerContent.style.width = "40px";
          markerContent.style.height = "60px";

          // Outer marker (inverted pin shape)
          const markerOuter = document.createElement("div");
          markerOuter.style.position = "absolute";
          markerOuter.style.top = "0";
          markerOuter.style.left = "0";
          markerOuter.style.right = "0";
          markerOuter.style.margin = "auto";
          markerOuter.style.width = "30px";
          markerOuter.style.height = "40px";
          markerOuter.style.backgroundColor = "red";
          markerOuter.style.borderRadius = "50% 50% 50% 50%";
          markerOuter.style.clipPath = "polygon(50% 100%, 0% 25%, 50% 0%, 100% 25%)";

          const markerInner = document.createElement("div");
          markerInner.style.position = "absolute";
          markerInner.style.top = "50%";
          markerInner.style.left = "50%";
          markerInner.style.transform = "translate(-50%, -50%)";
          markerInner.style.width = "12px";
          markerInner.style.height = "12px";
          markerInner.style.backgroundColor = "white";
          markerInner.style.borderRadius = "50%";

          markerOuter.appendChild(markerInner);
          markerContent.appendChild(markerOuter);

          // Create a marker with custom content
          const customMarker = new window.google.maps.marker.AdvancedMarkerView({
            position,
            map,
            title,
            content: markerContent,
          });

          return customMarker;
        };

        markers.forEach((marker) => {
          createCustomMarker({ lat: marker.lat, lng: marker.lng }, marker.title);
        });
      }
    };

    initMap();
  }, [isLoaded, center, zoom, markers]);

  return (
    <div
      ref={mapRef}
      style={{
        width: "100%",
        height: "200px",
        border: "1px solid #ccc",
      }}
    ></div>
  );
};

export default MapWithMarker;
