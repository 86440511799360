import { Dialog } from "primereact/dialog";
import { ReactNode } from "react";

interface REIDialogProps {
  header:  React.ReactNode;
  visible: boolean;
  handleEditClick: () => void;
  contentComponent: React.ReactNode;
  style?: object;
  position?:
    | "center"
    | "top"
    | "bottom"
    | "left"
    | "right"
    | "top-left"
    | "top-right"
    | "bottom-left"
    | "bottom-right";
  customClass?: string;
  showHeader:boolean;
}

const REIDialoge: React.FC<REIDialogProps> = ({
  header,
  visible,
  handleEditClick,
  contentComponent,
  style,
  showHeader = true,
  position = "center",
  customClass,
}) => {
  return (
    <Dialog
      showHeader={showHeader}
      header={header}
      visible={visible}
      style={style}
      position={position}
      onHide={handleEditClick}
      className={customClass}
    >
      <div>{contentComponent}</div>
    </Dialog>
  );
};

export default REIDialoge;
