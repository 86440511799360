import React from "react";
import { Slider } from "primereact/slider";

interface ISliderProps {
  value: number;
  onChange: (value: number) => void;
  min?: number;
  max?: number;
  classNames?: string;
  step: number;
}

const InputSlider: React.FC<ISliderProps> = ({ value, onChange, min = 0, max = 100, classNames, step }) => {
  return <Slider value={value} onChange={onChange} className={classNames} min={min} max={max} step={step} />;
};

export default InputSlider;
