import React, { createContext, useContext, useState, ReactNode } from "react";
import { IAnalysedProperty } from "../../../../interface/addAnalyser";

interface AnalysedPropertyContextType {
  analysedProperty: IAnalysedProperty | undefined;
  setAnalysedProperty: React.Dispatch<React.SetStateAction<IAnalysedProperty | undefined>>;
}

const AnalysedPropertyContext = createContext<AnalysedPropertyContextType | undefined>(undefined);

export const AnalysedPropertyProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [analysedProperty, setAnalysedProperty] = useState<IAnalysedProperty | undefined>(undefined);

  return (
    <AnalysedPropertyContext.Provider value={{ analysedProperty, setAnalysedProperty }}>
      {children}
    </AnalysedPropertyContext.Provider>
  );
};

export const useAnalysedPropertyContext = () => {
  const context = useContext(AnalysedPropertyContext);
  if (!context) {
    throw new Error("useAnalysedPropertyContext must be used within a AnalysedPropertyProvider");
  }
  return context;
};
