export const ApiEndPoints = {
  login: "security/AuthService.svc/API/Security/",
  search: "search/PropertySearchService.svc/API/SavedSearch",
  watchlist: "search/PropertySearchService.svc/API/Watchlist",
  address: "address/suburbautocomplete.svc/API/Address",
  parameter: "search/ParameterService.svc/API/KeyValues",
  searchSummary: "search/PropertySearchService.svc/API/",
  files: "upload/FileUploadService.svc/API/Documents/",
  analyser: "analyser/AnalyserService.svc/API/Analyser",
  anylyserfilter: "analyser/AnalyserService.svc/API/Filter",
  myaccount: "reiapi/API/myaccount",
  tracker: "tracker/PortfolioService.svc/API/",
  addPaymentMethodWorkatoUrl:
    "https://www.workato.com/webhooks/rest/b83ce985-6fcd-48ba-8d8b-e829aaed14db/newccpaymentmethod",
  paymentPageUrl: "https://www.zuora.com/apps/PublicHostedPageLite.do",
  changeEmail: "zuora/AccountService.svc",
} as const;
