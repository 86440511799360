import { ConfirmPopup } from "primereact/confirmpopup";

import REIToggablePanel from "../components/ui/toggablePanel";

import MySavedSearch from "./SavedSearch";
import MyWatchList from "./WatchList";
import InvestarSearchHome from "./Search";
import { ReactElement, useCallback, useState } from "react";
import { REIButton, REIDialoge } from "../components/ui";
import MyDefaultProfile from "./Search/Child/defaultProfile";

const InvestarSearch: React.FC = () => {
  const [showDefaultProfile, setShowDefaultProfile] = useState<boolean>(false);

  const hideDefaultProfile = useCallback(() => {
    setShowDefaultProfile(false);
  }, []);

  const headerTemplate = (options: any): ReactElement<any, any> => {
    const className = `${options.className} justify-content-space-between`;

    return (
      <div className={className}>
        <div className="flex align-items-center gap-2">
          <span className="font-bold">Investar search</span>
          <span className=" text-sm ">(Either click to select a region or search for a particular area)</span>
        </div>
        <div>
          <REIButton
            icon="pi pi-user-edit"
            label="My default profile"
            classNames="p-button-link"
            onClick={() => setShowDefaultProfile(true)}
          />
          {options.togglerElement}
        </div>
      </div>
    );
  };

  return (
    <>
      <ConfirmPopup />
      <REIDialoge
        showHeader={true}
        header={`Edit Default Profile`}
        visible={showDefaultProfile}
        handleEditClick={hideDefaultProfile}
        position="top"
        style={{ width: "60vw" }}
        customClass="edit-default-profile"
        contentComponent={<MyDefaultProfile closeDialog={hideDefaultProfile} />}
      ></REIDialoge>
      <div className="flex flex-col lg:flex-row flex-wrap gap-2">
        <div className="flex flex-col lg:flex-row lg:w-full gap-2">
          <div className="flex-1 lg:w-1/2 w-full">
            <REIToggablePanel
              header="My watchlist"
              istoggleable={true}
              defaultCollapse={true}
              contentComponent={<MyWatchList />}
            />
          </div>
          <div className="flex-1 lg:w-1/2 w-full">
            <REIToggablePanel
              header="My saved search"
              istoggleable={true}
              defaultCollapse={true}
              contentComponent={<MySavedSearch />}
            />
          </div>
        </div>
        <div className="w-full">
          <REIToggablePanel
            header="Investar Search"
            istoggleable={false}
            defaultCollapse={false}
            customHeader={headerTemplate}
            contentComponent={<InvestarSearchHome />}
          />
        </div>
      </div>
    </>
  );
};

export default InvestarSearch;
