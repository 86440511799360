import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import PortfolioTrackerHeader from "../header";
import PropertyCard from "./PropertyCard";
import { REIButton, REIDropDown } from "../../../components/ui";
import { useHttpGets } from "../../../services/httpService";
import { MEMBER_KEY, MyTrackerTabs, USER_DETAIL } from "../../../utils/constants";
import { ApiEndPoints } from "../../../utils/endpoints";
import ManageProperty from "./ManageProperty";
import { IMyProperties } from "../../../interface/myProperty";

const MyPropertiesIndex: React.FC = () => {
  const memberEntities = useSelector((state: RootState) => state.myTracker.memberEntities);
  const memberEntity = useRef<any[]>();
  const [selectedEntity, setSelectedEntity] = useState(null);
  const memberId = localStorage.getItem(MEMBER_KEY);
  const memberDetail = JSON.parse(localStorage.getItem(USER_DETAIL) || "{}");
  const [propertiesData, setPropertiesData] = useState<any[]>([]);
  const [filteredProperties, setFilteredProperties] = useState<any[]>([]);
  const [isManagePropertyVisible, setIsManagePropertyVisible] = useState<boolean>(false);
  const [propertyDetails, setPropertyDetails] = useState<IMyProperties>({} as IMyProperties);

  useEffect(() => {
    setMemberEntities();
    async function getPropertiesData() {
      useHttpGets(
        `${ApiEndPoints.tracker}PortfolioTracker/MyProperties?MemberId=${memberId}&userCountryCode=${memberDetail.Country}`
      )
        .then((response) => {
          setPropertiesData(response?.data);
          setFilteredProperties(response?.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    getPropertiesData();
  }, [memberEntities, isManagePropertyVisible]);

  const setMemberEntities = () => {
    if (memberEntities) {
      let memeberEntitiesValues: any[] = [];
      memeberEntitiesValues.push({ EntityName: "All Entity", EntityValue: "0" });
      memberEntities.map((entities) => {
        memeberEntitiesValues.push({
          EntityName: entities.EntityName,
          EntityValue: entities.MemberEntityId.toString(),
        });
      });
      memberEntity.current = memeberEntitiesValues;
      setSelectedEntity(memeberEntitiesValues[0].EntityValue);
    }
  };

  const onEntityChange = (e: any) => {
    setSelectedEntity(e.value);
    if (e.value == "0") {
      setFilteredProperties(propertiesData);
    } else {
      setFilteredProperties(propertiesData.filter((property) => property.MemberEntityId == Number(e.value)));
    }
  };

  const handleManagePropertyClick = (propertyData: IMyProperties): void => {
    setPropertyDetails((prevData) => {
      return propertyData;
    });
    setIsManagePropertyVisible((prevData) => {
      return true;
    });
  };

  const handleRefresh = () => {
    setIsManagePropertyVisible((prevData) => {
      return false;
    });
  };

  return (
    <div className="flex flex-col gap-4">
      <div hidden={isManagePropertyVisible}>
        <PortfolioTrackerHeader tabindex={MyTrackerTabs.MyProperties} tabName="My Properties"></PortfolioTrackerHeader>
        <div className="flex flex-row justify-between pl-4 mb-2">
          <div className="flex justify-start gap-4 ">
            <label className="self-center mr-3 " htmlFor="memberEntity">
              Show properties from entity:
            </label>

            {memberEntities && (
              <REIDropDown
                data={memberEntity?.current}
                onSelectionChange={(e) => onEntityChange(e)}
                placeholder="Select"
                value={selectedEntity}
                optionValue="EntityValue"
                label="EntityName"
                className="w-64 align-middle"
              ></REIDropDown>
            )}
          </div>
          <div className="flex justify-end ">
            <REIButton onClick={() => {}} label="Add New Property"></REIButton>
          </div>
        </div>
        <div className="flex flex-col w-full gap-4">
          {filteredProperties.map((property) => {
            return <PropertyCard propertyData={property} setPropertyData={handleManagePropertyClick}></PropertyCard>;
          })}
        </div>
        {filteredProperties.length == 0 && (
          <div className="flex content-start">
            You currently don't have any properties in this entity. You can add a new property to your portfolio and
            assign it here.
          </div>
        )}
      </div>
      {isManagePropertyVisible && (
        <div id="manage-property-card" hidden={!isManagePropertyVisible}>
          <ManageProperty handleRefresh={handleRefresh} propertyData={propertyDetails}></ManageProperty>
        </div>
      )}
    </div>
  );
};

export default MyPropertiesIndex;
