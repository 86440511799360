import React from "react";
import { formatNumber } from "../../utils/commonUtil";
import { REIButton } from "../ui";

interface EditableDataTableProps {
  title?: string;
  data: {
    [key: string]: {
      type: string;
      forecastOverrideKey?: string;
      anyCellValueChange?: boolean;
      values: {
        [year: string]: {
          value: string;
          editable: boolean;
          didValueChange?: boolean;
          forcastedForFutureYears?: boolean;
        };
      };
    };
  };
  years: string[];
  isEditable: boolean;
  footer?: {
    [key: string]: {
      type: string;
      values: { [year: string]: { value: string; editable: boolean } };
    };
  };
  onEdit: (rowKey: string, year: string, value: string, forecastItemCode: string | undefined) => void;
  onClick?: (rowKey: string, year: string, value: string, forecastItemCode: string | undefined) => void;
  onForecastValueChange?: (rowKey: string, year: string, value: string, forecastItemCode: string) => void;
}

const formatValue = (value: string, type: string, isDefault: boolean = false) => {
  const numValue = parseFloat(value);
  if (isNaN(numValue)) return value;
  if (type === "%") return `${numValue.toFixed(2)}`;
  if (type === "currency") return isDefault ? formatNumber(numValue).replace(",", "") : formatNumber(numValue);
  return value;
};

const ForecastButton: React.FC<{
  tooltip: string;
  icon: string;
  onClick: () => void;
  disabled?: boolean;
}> = ({ tooltip, icon, onClick, disabled }) => (
  <REIButton
    label=""
    icon={icon}
    iconPos="right"
    disabled={disabled}
    classNames="p-button-link"
    tooltip={tooltip}
    onClick={onClick}
  />
);

const EditableDataTable: React.FC<EditableDataTableProps> = ({
  title,
  data,
  years,
  isEditable,
  onEdit,
  footer,
  onClick,
  onForecastValueChange,
}) => {
  const handleInputBlur = (rowKey: string, year: string, value: string, forecastItemCode: string | undefined) => {
    onEdit(rowKey, year, value, forecastItemCode);
  };

  const handleInputClick = (rowKey: string, year: string, value: string, forecastItemCode: string | undefined) => {
    onClick && onClick(rowKey, year, value, forecastItemCode);
  };

  const renderCell = (
    rowKey: string,
    year: string,
    type: string,
    value: string,
    forecastItemCode: string | undefined
  ) => (
    <div className="flex items-center gap-2">
      <input
        type="text"
        className="p-inputnumber-input p-inputtext p-component p-filled p-1 border rounded w-full text-end"
        defaultValue={formatValue(value, type, true)}
        onInput={(e) => {
          const regex = new RegExp(`^\\d*\\.?\\d{0,${type === "%" ? 2 : 0}}$`);
          const input = e.target as HTMLInputElement;
          input.value = input.value.match(regex) ? input.value : input.value.slice(0, -1);
        }}
        onBlur={(e) => handleInputBlur(rowKey, year, e.target.value, forecastItemCode)}
        onClick={(e) => handleInputClick(rowKey, year, (e.target as HTMLInputElement).value, forecastItemCode)}
      />
    </div>
  );

  const renderForecastButton = (
    rowKey: string,
    year: string,
    forecastItemCode: string | undefined,
    didValueChange: boolean | undefined,
    anyCellValueChange: boolean | undefined,
    isLastYear: boolean,
    value: string,
    forcastedForFutureYears: boolean | undefined
  ) => {
    if (didValueChange && !isLastYear) {
      if (forcastedForFutureYears) {
        return (
          <ForecastButton
            tooltip="This change will be applied to future years"
            icon="pi pi-forward"
            disabled={true}
            onClick={() => {}}
          />
        );
      } else {
        return (
          <ForecastButton
            tooltip="Apply changes to future years"
            icon="pi pi-play"
            onClick={() =>
              onForecastValueChange && forecastItemCode && onForecastValueChange(rowKey, year, value, forecastItemCode)
            }
          />
        );
      }
    }
    if (anyCellValueChange && isLastYear) {
      return (
        <ForecastButton
          tooltip="Reset all changes in this row"
          icon="pi pi-refresh"
          onClick={() =>
            onForecastValueChange && forecastItemCode && onForecastValueChange(rowKey, year, value, forecastItemCode)
          }
        />
      );
    }
    return null;
  };

  return (
    <>
      {title && (
        <div className="py-2">
          <span className="text-lg font-semibold text-blue-600">{title}</span>
        </div>
      )}
      <div className="py-0">
        <table className="w-full text-left border-solid border border-gray-300 rounded-md">
          <tbody>
            {Object.keys(data).map((rowKey, index) => (
              <tr key={rowKey} className={`hover:bg-gray-100 ${index % 2 === 0 ? "bg-slate-100" : "bg-slate-50"}`}>
                <td className="p-2">{rowKey}</td>
                {years.map((year, idx) => (
                  <td key={year} className="p-2 text-end w-36">
                    <div className="flex items-center gap-2">
                      {isEditable &&
                        data[rowKey].values[year]?.editable &&
                        renderCell(
                          rowKey,
                          year,
                          data[rowKey].type,
                          data[rowKey].values[year]?.value || "",
                          data[rowKey].forecastOverrideKey
                        )}
                      {isEditable &&
                        renderForecastButton(
                          rowKey,
                          year,
                          data[rowKey].forecastOverrideKey,
                          data[rowKey].values[year]?.didValueChange,
                          data[rowKey].anyCellValueChange,
                          year === "Year 10",
                          data[rowKey].values[year]?.value || "",
                          data[rowKey].values[year]?.forcastedForFutureYears
                        )}
                    </div>
                    {!(isEditable && data[rowKey].values[year]?.editable) &&
                      (parseFloat(data[rowKey].values[year]?.value || "") < 0 ? (
                        <span className="text-red-600">
                          (
                          {formatValue(
                            (-parseFloat(data[rowKey].values[year]?.value)).toString() || "",
                            data[rowKey].type
                          )}
                          )
                        </span>
                      ) : (
                        formatValue(data[rowKey].values[year]?.value || "", data[rowKey].type)
                      ))}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
          {footer && (
            <tfoot>
              {Object.keys(footer).map((rowKey) => (
                <tr key={rowKey} className="bg-slate-300 font-semibold">
                  <td className="p-2 ">{rowKey}</td>
                  {years.map((year) => (
                    <td key={year} className="p-2 text-end w-36">
                      {parseFloat(footer[rowKey]?.values[year]?.value || "") < 0 ? (
                        <span className="text-red-600">
                          (
                          {formatValue(
                            (-parseFloat(footer[rowKey].values[year]?.value)).toString() || "",
                            footer[rowKey].type
                          )}
                          )
                        </span>
                      ) : parseFloat(footer[rowKey]?.values[year]?.value || "") == 0 ? (
                        " "
                      ) : (
                        formatValue(footer[rowKey]?.values[year]?.value || "", footer[rowKey]?.type)
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tfoot>
          )}
        </table>
      </div>
    </>
  );
};

export default EditableDataTable;
