import AddPropertyStepper from "../Index";
import { AnalysedPropertyProvider } from "./AnalysedPropertyContext";

const AddPropertyStepperWrapper: React.FC = () => {
  return (
    <AnalysedPropertyProvider>
      <AddPropertyStepper />
    </AnalysedPropertyProvider>
  );
};

export default AddPropertyStepperWrapper;
