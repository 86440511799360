import { InputNumber } from "primereact/inputnumber";

interface IInputNumberProps {
  value: number | undefined;
  useGrouping?: boolean;
  onValueChange: (event: any) => void;
  className?: string;
  min?: number;
  max?: number;
  maxFractionDigits?: number;
  styleValue?: string;
  onBlur?: (event: any) => void;
  invalid?: boolean;
}

const REIInputNumber: React.FC<IInputNumberProps> = ({
  value,
  onValueChange,
  useGrouping = false,
  className,
  min,
  max,
  maxFractionDigits,
  styleValue,
  onBlur,
  invalid,
}) => {
  return (
    <InputNumber
      value={value}
      onValueChange={onValueChange}
      useGrouping={useGrouping}
      className={className}
      min={min}
      max={max}
      maxFractionDigits={maxFractionDigits}
      onBlur={onBlur}
      invalid={invalid}
    />
  );
};

export default REIInputNumber;
