import WatchList from "./List/WatchList";
import "../../App.scss";

const MyWatchList: React.FC = () => {
  return (
    <div className="overflow-y-auto min-h-40">
      <WatchList />
    </div>
  );
};

export default MyWatchList;
