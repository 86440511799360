import { SelectButton, SelectButtonChangeEvent } from "primereact/selectbutton";

interface IButtonProps {
  label: string;
  value: any;
  items?: any[];
  onChange: (event: SelectButtonChangeEvent) => void;
}

const REISelectButton: React.FC<IButtonProps> = ({
  label,
  value,
  items,
  onChange,
}) => {
  return (
    <SelectButton
      value={value}
      onChange={onChange}
      optionLabel={label}
      options={items}
    />
  );
};

export default REISelectButton;
