import ViewPropertyDetails from "../../../interface/Watchlist/propertyDetails";
import HouseIcon from "../../../public/assets/images/icon-house.png";
import BedIcon from "../../../public/assets/images/icon-bed.png";
import BathIcon from "../../../public/assets/images/icon-bath.png";
import CarIcon from "../../../public/assets/images/icon-car.png";
import FilledStar from "../../../public/assets/images/star_with_fill.png";
import Star from "../../../public/assets/images/star_without_fill.png";
import { formatCurrency } from "../../../utils/commonUtil";
import { useEffect, useRef, useState } from "react";
import { ApiEndPoints } from "../../../utils/endpoints";
import { httpPost } from "../../../services/httpService";
import { useDispatch, useSelector } from "react-redux";
import { removePropertyFromWatchList, getWatchListProperties } from "../../../store/slice/watchlistSlice";
import { Toast } from "primereact/toast";
import { Messages, ANALYZE_IN_FULL } from "../../../utils/constants";
import { REILinkButton } from "../../../components/ui";

interface PropertyInformation {
  propertydata: ViewPropertyDetails;
  onClosecallback: any;
  hideCloseBtn?: boolean;
}

interface IWatchListPayloadData {
  Country: string;
  LinkIDHash: string;
  MemberId: string;
  Website: string;
  WebsiteMode: string;
}

const PropertyHeader: React.FC<PropertyInformation> = ({ propertydata, onClosecallback, hideCloseBtn }) => {
  const [isPropertyWatchListed, setIsPropertyWatchListed] = useState<boolean>(false);
  let watchListResponse = useSelector((state) => state.watchList?.watchListResponse);
  const dispatch = useDispatch();
  let watchListId = useRef<string>("");
  const toastRef = useRef<Toast>(null);
  let propertyHeader = useRef<string>("");

  useEffect(() => {
    if (watchListResponse.error) {
      return toastRef.current?.show({
        severity: "success",
        summary: Messages.ERROR,
        detail: Messages.UNKNOWN_ERROR,
        life: 2000,
      });
    }
    if (watchListResponse.length != 0 && watchListResponse?.WatchList?.length != 0) {
      let isWatchListed =
        watchListResponse.WatchList.find((x) => x.LinkIDHash == propertydata.PropertyDetails.LinkIDHash)?.LinkIDHash !=
        undefined
          ? true
          : false;
      watchListId.current = watchListResponse.WatchList.find(
        (x) => x.LinkIDHash == propertydata.PropertyDetails.LinkIDHash
      )?.WatchlistID;
      setIsPropertyWatchListed(isWatchListed);
      propertyHeader.current = [
        propertydata.PropertyDetails.StreetAddress,
        propertydata.PropertyDetails.Suburb,
        propertydata.PropertyDetails.Postcode,
      ]
        .filter(Boolean)
        .join(", ");
    } else {
      setIsPropertyWatchListed(false);
    }
  }, [watchListResponse]);

  const onStarClick = async () => {
    if (isPropertyWatchListed) {
      await dispatch(removePropertyFromWatchList(`${Number(watchListId.current)}`));
      setIsPropertyWatchListed(!isPropertyWatchListed);
    } else {
      addToWatchList();
    }
  };

  const addToWatchList = async () => {
    const data: IWatchListPayloadData = {
      Country: `${propertydata.PropertyDetails.Country}`,
      LinkIDHash: `${propertydata.PropertyDetails.LinkIDHash}`,
      MemberId: `${propertydata.PropertyDetails.MemberId}`,
      Website: `${propertydata.PropertyDetails.Website}`,
      WebsiteMode: `${propertydata.PropertyDetails.WebsiteMode}`,
    };

    await httpPost(`${ApiEndPoints.watchlist}`, data)
      .then((result) => {
        if (result.status == 200) {
          dispatch(getWatchListProperties());
          setIsPropertyWatchListed(!isPropertyWatchListed);
        }
      })
      .catch((error) => {
        return toastRef.current?.show({
          severity: "success",
          summary: Messages.ERROR,
          detail: error.messege,
          life: 2000,
        });
      });
  };

  return (
    <>
      <div>
        <div hidden={propertydata.isFromPropertyAnalyser} className="flex md:flex-row flex-col bg-slate-100 p-2">
          <div className="w-full md:w-1/2 text-start">
            <img
              src={`${isPropertyWatchListed ? FilledStar : Star}`}
              style={{ height: "20px" }}
              onClick={onStarClick}
            />
            <span className="font-family_2 font-bold text-lg align-top p-1">
              {propertydata.PropertyDetails.Suburb} {propertydata.PropertyDetails.DateDownloadedFirst}
            </span>
          </div>
          <div className="w-full md:w-1/2 text-end font-bold">
            {propertydata.PropertyDetails.Price == 0 ? "N/A" : formatCurrency(propertydata.PropertyDetails.Price)}
            {propertydata.PropertyDetails.LocalityMedian}
          </div>
        </div>
        <div className="flex md:flex-row flex-col pl-2">
          <div className="w-full md:w-1/2 my-1 text-lg text-orange-700 font-bold">{propertyHeader.current}</div>
          <div className="w-full md:w-1/4 flex flex-row mr-2">
            <div className="w-1/2 text-start content-center">
              <img src={HouseIcon} alt="Image 1" className="h-5 w-5" />
              <span className="text-center text-sm m-1 align-top">{propertydata.PropertyDetails.Category}</span>
            </div>
            <div className="w-1/2 text-end pr-2 content-center">
              <img src={BathIcon} alt="Image 1" className="h-5 w-5" />
              <span className="text-center text-md align-top m-1">{propertydata.PropertyDetails.BathRooms}</span>
              <img src={BedIcon} alt="Image 2" className="h-5 w-5" />
              <span className=" text-md text-md align-top m-1">{propertydata.PropertyDetails.BedRooms}</span>
              <img src={CarIcon} alt="Image 3" className="h-5 w-5" />
              <span className=" text-md text-md align-top m-1">{propertydata.PropertyDetails.Carport}</span>
            </div>
          </div>
          <div className="w-full md:w-1/4 flex flex-row content-center">
            <div className="w-1/2 text-start content-center xl:text-sm">
              {`Land size: ${
                propertydata.PropertyDetails.LandArea == 0 ? "N/A" : propertydata.PropertyDetails.LandArea
              }`}
            </div>
            {propertydata.isFromPropertyAnalyser && (
              <div className="w-1/2 text-start content-center xl:text-sm">
                added {propertydata.PropertyDetails.DataAdded.split(" ")[0]}
              </div>
            )}
            <div hidden={propertydata.isFromPropertyAnalyser} className="w-1/2 text-end content-center">
              <REILinkButton className="text-sm" href={ANALYZE_IN_FULL} target="_blank">
                Analyze in full
              </REILinkButton>
            </div>
          </div>
        </div>
        <div className="font-bold pl-2 text-sky-600 pb-2">
          {propertydata.PropertyDetails.Title.replace(/^,|,$/g, "")}
        </div>
      </div>
    </>
  );
};
export default PropertyHeader;
