import React, { useEffect, useRef, useState } from "react";
import {
  REIButton,
  REIDataTable,
  REIDropDown,
  REIInputNumber,
  REIInputText,
  REIToastContainer,
} from "../../../../components/ui";
import { COUNTRY_OPTIONS, ENTITY_TYPE, MEMBER_KEY, Messages, USER_DETAIL } from "../../../../utils/constants";
import { httpPost, useHttpGets } from "../../../../services/httpService";
import { IMemberEntity, IMemberProfile } from "../../../../interface/analysed";
import { ApiEndPoints } from "../../../../utils/endpoints";
import { Toast } from "primereact/toast";

interface EntityOwnerShipProps {
  closeDialogue: () => void;
}

export const EntityOwnerShip: React.FC<EntityOwnerShipProps> = ({ closeDialogue }) => {
  const toastRef = useRef<Toast>(null);
  const [shoAddNewProfile, setShowAddNewProfile] = useState<boolean>(false);
  const memberDetail = JSON.parse(localStorage.getItem(USER_DETAIL) || "{}");
  const [memberEntities, setMemberEntities] = useState<IMemberEntity[]>([]);
  const [memberProfiles, setMemberProfiles] = useState<IMemberProfile[]>([]);
  const [memberProfile, setMemberProfile] = useState<number>();
  const [selectedProfiles, setSelectedProfiles] = useState<any[]>([]);

  const [seelctedCountry, setSelectedCountry] = useState<number>(1);
  const [entityType, setEntityType] = useState<string>("PER");
  const [entityName, setEntityName] = useState<string>("");
  const [entityDescription, setEntityDescription] = useState<string>("");
  const [notes, setNotes] = useState<string>("");

  const [portfolio, setPortfolio] = useState<any>({
    PortfolioValue: 0,
    Equity: 0,
    AnnualRent: 0,
    PreTaxCashFlow: 0,
    AfterTaxCashFlow: 0,
  });

  const [profile, setProfile] = useState<any>({
    ProfileName: "",
    Income: 0,
    Owns: 0,
    MemberProfileId: 0,
  });

  useEffect(() => {
    if (memberDetail) {
      useHttpGets(
        `${ApiEndPoints.tracker}/MemberEntities?memberId=${localStorage.getItem(MEMBER_KEY)}&userCountryCode=${
          memberDetail.Country
        }&memberName=${memberDetail.FirstName + " " + memberDetail.LastName}`,
        undefined
      )
        .then((result) => {
          if (result?.data != null && !result.error) {
            if (Array.isArray(result.data)) {
              setMemberEntities((result.data as any).filter((x: any) => x.EntityName !== "Personal Ownership"));
            }
          }
        })
        .catch((error) => {});
    }
    useHttpGets(`${ApiEndPoints.tracker}/GetMemberProfiles/${localStorage.getItem(MEMBER_KEY)}`, undefined)
      .then((result) => {
        if (result?.data != null && !result.error) {
          setMemberProfiles(result.data);
        }
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    if (memberProfile) {
      const member = memberProfiles.filter((x: any) => x.MemberProfileId === memberProfile);
      setShowAddNewProfile(true);
      setProfile({
        ProfileName: member[0].MemberName,
        Income: member[0].Income,
        Owns: profile.Owns,
        MemberProfileId: memberProfile,
      });
    }
  }, [memberProfile]);

  useEffect(() => {
    if (selectedProfiles.length) {
      const filteredProfiles = memberProfiles.filter(
        (x) => !selectedProfiles.some((y) => y.MemberProfileId === x.MemberProfileId)
      );
      setMemberProfiles(filteredProfiles);
    }
  }, [selectedProfiles]);

  const addNewProfile = () => {
    setShowAddNewProfile(false);
    setSelectedProfiles([...selectedProfiles, profile]);
    setProfile({
      ProfileName: "",
      Income: 0,
      Owns: 0,
      MemberProfileId: 0,
    });
  };

  const handleEdit = () => {};

  const handleDelete = (event: any, row: any) => {
    setSelectedProfiles(selectedProfiles.filter((x) => x.MemberProfileId !== row.MemberProfileId));
    if (row.MemberProfileId > 0) {
      setMemberProfiles([
        ...memberProfiles,
        {
          Income: row.Income,
          MemberName: row.ProfileName,
          MemberProfileId: row.MemberProfileId,
        },
      ]);
    }
  };

  const handleSaveClick = async () => {
    const body = {
      CountryCode: seelctedCountry === 1 ? "AU" : "NZ",
      EntityTypeCode: entityType,
      GoalPortfolioValue: portfolio.PortfolioValue,
      GoalEquityUnlocked: portfolio.Equity,
      GoalAnnualRent: portfolio.AnnualRent,
      GoalPreTaxCashFlow: portfolio.PreTaxCashFlow,
      GoalAfterTaxCashFlow: portfolio.AfterTaxCashFlow,
      IsDefault: memberEntities.length > 0 && memberEntities[0].ValuesDefaulted ? 1 : 0,
      MemberId: localStorage.getItem(MEMBER_KEY),
      EntityName: entityName,
      GoalDesc: entityDescription,
      Note: notes,
      MemberProfiles: selectedProfiles.map((x) => {
        return {
          MemberName: x.ProfileName,
          Income: x.Income,
          PortionOwned: x.Owns,
          MemberProfileId: x.MemberProfileId,
        };
      }),
    };

    await httpPost(`${ApiEndPoints.tracker}MemberEntity/Add/`, body)
      .then((result) => {
        if (result.status == 200) {
          toastRef.current?.show({
            severity: "success",
            summary: Messages.SUCCESS,
            detail: "Entity added successfully",
            life: 2000,
          });
          closeDialogue();
        }
      })
      .catch(() => {
        toastRef.current?.show({
          severity: "success",
          summary: Messages.ERROR,
          detail: Messages.ERROR,
          life: 2000,
        });
      });
  };

  const columns = [
    {
      field: "ProfileName",
      header: "Name",
      height: "39px",
      width: "200px",
    },
    {
      field: "Income",
      header: "Income",
      width: "100px",
    },
    {
      field: "Owns",
      header: "Owns(%)",
      width: "100px",
    },
    {
      field: "MemberProfileId",
      header: "",
      type: "custom",
      onClick: handleEdit,
      template: <span className="text-blue-700 cursor-pointer">Edit</span>,
      width: "50px",
    },
    {
      field: "Id",
      header: "",
      type: "custom",
      onClick: handleDelete,
      template: <i className="pi pi-minus-circle text-blue-700 cursor-pointer" style={{ fontSize: "1rem" }}></i>,
      width: "50px",
    },
  ];

  return (
    <div className="">
      <REIToastContainer toastReference={toastRef}></REIToastContainer>
      <div className="flex flex-row w-full p-1 gap-1">
        <div className="flex-0 w-1/3">
          <label>Entity country</label>
          <REIDropDown
            data={COUNTRY_OPTIONS}
            value={seelctedCountry}
            onSelectionChange={(e) => {
              setSelectedCountry(e.value);
            }}
            label="name"
            optionValue="value"
            placeholder="Select entity country"
          />
        </div>
        <div className="flex-0 w-1/3">
          <label>Entity Type</label>
          <REIDropDown
            data={ENTITY_TYPE}
            value={entityType}
            onSelectionChange={(e) => {
              setEntityType(e.value);
            }}
            label="name"
            optionValue="value"
            placeholder="Select Entity type"
          />
        </div>
        <div className="flex-0 w-1/3"></div>
      </div>
      <div className="flex flex-row w-full p-1 gap-1">
        <div className="flex-0 w-full">
          <label>Entity Name*</label>
          <REIInputText
            className="w-full"
            type="text"
            placeholder=""
            value={entityName}
            onChange={(e) => setEntityName(e.target.value)}
            disabled={false}
            name="entityName"
          />
        </div>
      </div>
      <div className="flex flex-row w-full p-1 gap-1">
        <div className="flex-0 w-full">
          <label>User profiles for this entity</label>
          <br />
          <label className="mt-2">
            This is important for calculating tax implications accurately across your portfolio. Please ensure all
            profiles have a unique name.
          </label>
        </div>
      </div>
      <div className="flex flex-row w-full p-1 gap-1">
        <div className="flex-0 w-full overflow-y-auto">
          <REIDataTable data={selectedProfiles} columns={columns} actions={undefined} customclass="max-h-40" />
        </div>
      </div>
      <div className="flex flex-row w-full p-1 gap-1">
        {memberProfiles && memberProfiles.length > 0 && (
          <div className="flex-0 w-1/3">
            <label>Select an existing profile</label>
            <REIDropDown
              data={memberProfiles}
              value={memberProfile}
              onSelectionChange={(e) => {
                setMemberProfile(e.value);
              }}
              label="MemberName"
              optionValue="MemberProfileId"
              placeholder="Select existing profile"
            />
          </div>
        )}
        <div className="flex-0 w-1/3 gap-1">
          <div className="flex items-center gap-1">
            {memberProfiles && memberProfiles.length > 0 && <span className="mt-2 mx-1">or</span>}
            <REIButton
              label="Add a new profile to this entity"
              icon={"pi pi-plus"}
              type="button"
              iconPos="right"
              disabled={shoAddNewProfile}
              classNames="p-button-primary mt-5"
              onClick={() => {
                setShowAddNewProfile(true);
              }}
            />
          </div>
        </div>
        <div className="flex-0 w-1/3"></div>
      </div>

      {shoAddNewProfile && (
        <div className="flex flex-row w-full p-1 gap-1">
          <div className="flex-0 w-[40%]">
            <label>Name</label>
            <REIInputText
              className="w-full"
              type="text"
              placeholder=""
              value={profile.ProfileName}
              disabled={false}
              onChange={(e) => {
                setProfile({ ...profile, ProfileName: e.target.value });
              }}
              name="profileName"
            />
          </div>
          <div className="flex-0 w-[20%]">
            <label>Income ($)</label>
            <REIInputNumber
              className="w-full"
              key={"income"}
              value={profile.Income}
              onValueChange={(e) => {
                setProfile({ ...profile, Income: e.target.value });
              }}
            />
          </div>
          <div className="flex-0 w-[20%]">
            <label>Owns (%)</label>
            <REIInputNumber
              className="w-full"
              key={"owns"}
              value={profile.Owns}
              onValueChange={(e) => {
                setProfile({ ...profile, Owns: e.target.value });
              }}
              max={100}
              maxFractionDigits={2}
            />
          </div>
          <div className="flex-0 w-[20%]">
            <div className="flex items-center gap-1 mt-5">
              <REIButton
                label="Add"
                onClick={() => {
                  addNewProfile();
                }}
              />
              <REIButton
                label="Cancel"
                classNames=""
                onClick={() => {
                  setShowAddNewProfile(false);
                }}
              />
            </div>
          </div>
        </div>
      )}

      <div className="flex flex-row w-full p-1 gap-1">
        <div className="flex-0 w-full">
          <label>Describe this entity's goals</label>
          <REIInputText
            className="w-full"
            type="text"
            placeholder=""
            value={entityDescription}
            onChange={(e) => {
              setEntityDescription(e.target.value);
            }}
            disabled={false}
            name="entityName"
          />
        </div>
      </div>
      <div className="flex flex-row w-full p-1 gap-1">
        <div className="flex-0 w-full">
          <label>Goals for this entity</label>
        </div>
      </div>
      <div className="border border-solid border-gray-300 w-full gap-1 rounded-lg">
        <div className="flex flex-row w-full bg-slate-200 gap-1 min-h-8 items-center rounded-t-lg  border border-x-0 border-t-0 border-b border-solid border-gray-300">
          <div className="flex-0 w-1/6"></div>
          <div className="flex-0 w-1/6 font-bold">Portfolio value</div>
          <div className="flex-0 w-1/6 font-bold">Equity</div>
          <div className="flex-0 w-1/6 font-bold">Annual rent</div>
          <div className="flex-0 w-1/6 font-bold">Pre-tax cash flow pa</div>
          <div className="flex-0 w-1/6 font-bold">After-tax cash flow pa</div>
        </div>
        <div className="flex flex-row w-full gap-1 items-center py-1">
          <div className="flex-0 w-1/6 text-center">Target</div>
          <div className="flex-0 w-1/6">
            <REIInputNumber
              className="w-full"
              key={"portfolioValue"}
              value={portfolio.PortfolioValue}
              onValueChange={(e) => {
                setPortfolio({ ...portfolio, PortfolioValue: e.target.value });
              }}
            />
          </div>
          <div className="flex-0 w-1/6">
            <REIInputNumber
              className="w-full"
              key={"equity"}
              value={portfolio.Equity}
              onValueChange={(e) => {
                setPortfolio({
                  ...portfolio,
                  Equity: e.target.value,
                });
              }}
            />
          </div>
          <div className="flex-0 w-1/6">
            <REIInputNumber
              className="w-full"
              key={"annualRent"}
              value={portfolio.AnnualRent}
              onValueChange={(e) => {
                setPortfolio({
                  ...portfolio,
                  AnnualRent: e.target.value,
                });
              }}
            />
          </div>
          <div className="flex-0 w-1/6">
            <REIInputNumber
              className="w-full"
              key={"pretax"}
              value={portfolio.PreTaxCashFlow}
              onValueChange={(e) => {
                setPortfolio({
                  ...portfolio,
                  PreTaxCashFlow: e.target.value,
                });
              }}
            />
          </div>
          <div className="flex-0 w-1/6">
            <REIInputNumber
              className="w-full pr-1"
              key={"aftertax"}
              value={portfolio.AfterTaxCashFlow}
              onValueChange={(e) => {
                setPortfolio({
                  ...portfolio,
                  AfterTaxCashFlow: e.target.value,
                });
              }}
            />
          </div>
        </div>
      </div>

      <div className="flex flex-row w-full p-1 gap-1">
        <div className="flex-0 w-full">
          <label className="mt-2">
            To add properties to this entity, please select this entity name when adding a new property to Tracker, or
            when editing an existing property.
          </label>
        </div>
      </div>
      <div className="flex flex-row w-full p-1 gap-1">
        <div className="flex-0 w-full">
          <label>Notes</label>
          <REIInputText
            className="w-full"
            type="text"
            placeholder=""
            value={notes}
            disabled={false}
            onChange={(e) => {
              setNotes(e.target.value);
            }}
            name="entityName"
          />
        </div>
      </div>
      <div className="flex flex-row w-full p-1 gap-1">
        <div className="flex-0 w-full text-right">
          <REIButton label="Cancel" classNames="mr-1 p-button-link" onClick={closeDialogue} />
          <REIButton label="Save" onClick={handleSaveClick} />
        </div>
      </div>
    </div>
  );
};

export default EntityOwnerShip;
