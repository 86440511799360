import React, { useEffect, useState } from "react";
import { IREIGraph } from "../../interface/graph";
import { Chart } from "primereact/chart";

const Graph: React.FC<IREIGraph> = ({ labels, customOptions = {}, style = {}, dataSet, graphType }) => {
  const [chartData, setChartData] = useState({} as any);

  useEffect(() => {
    if (dataSet && dataSet.length > 0) {
      setChartData({
        labels: labels,
        datasets: dataSet,
      });
    }
  }, [dataSet, labels]);

  return (
    <>
      <Chart key={`graphType`} type={graphType} data={chartData} options={customOptions} style={style} />
    </>
  );
};

export default Graph;
