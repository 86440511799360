import { useEffect, useState } from "react";
import { AppDispatch } from "../../store";
import { useDispatch } from "react-redux";
import REITab from "../../components/ui/tab";
import "../../styles/custom.scss";
import { ITab } from "../../interface/tab";
import MyTrackerIndex from "./MyTracker/Index";

import { fetchMemberEntities, fetchMemberProfiles } from "../../store/slice/myTrackerSlice";
import MyCashFlowIndex from "./MyCashFlow";
import { CashFlowProvider } from "./MyCashFlow/MyCashFlowContext";
import MyPropertiesIndex from "./MyProperty/Index";
import MyEquity from "./MyEquity/MyEquity";

const PortfolioTrackerIndex = () => {
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchMemberProfiles());
    dispatch(fetchMemberEntities());
  }, [dispatch]);

  const [tabs, setTabs] = useState<ITab[]>([
    {
      header: "My Tracker",
      content: (
        <div>
          <MyTrackerIndex></MyTrackerIndex>
        </div>
      ),
      closable: false,
    },
    {
      header: "My Cash Flow",
      content: (
        <div>
          <CashFlowProvider>
            <MyCashFlowIndex></MyCashFlowIndex>
          </CashFlowProvider>
        </div>
      ),
      closable: false,
    },
    {
      header: "My Equity",
      content: (
        <div>
          <MyEquity></MyEquity>
        </div>
      ),
      closable: false,
    },
    {
      header: "My Properties",
      content: <MyPropertiesIndex></MyPropertiesIndex>,
      closable: false,
    },
    {
      header: "My Entities & Goals",
      content: <div>My Entities & Goals</div>,
      closable: false,
    },
    {
      header: "My Phonebook",
      content: <div>My Phonebook</div>,
      closable: false,
    },
  ]);

  return (
    <div className=" mx-auto p-4 content-center justified-between-tabview ">
      <REITab tabs={tabs} classNames="text-center"></REITab>
    </div>
  );
};

export default PortfolioTrackerIndex;
