import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { httpDelete, useHttpGets } from "../../services/httpService";
import { ApiEndPoints } from "../../utils/endpoints";
import { MEMBER_KEY } from "../../utils/constants";
import { IWatchListData } from "../../interface/Watchlist/propertyDetails";

interface IDeleteWatchListResponse {
  data: any;
  error: any;
  status: any;
}

interface IWatchListSlice {
  watchListResponse: IWatchListData[];
  deleteWatchListResponse: IDeleteWatchListResponse;
  loading: boolean;
}

const initialState: IWatchListSlice = {
  watchListResponse: [],
  deleteWatchListResponse: {
    data: null,
    error: null,
    status: null,
  },
  loading: false,
};

export const getWatchListProperties = createAsyncThunk("getWatchListProperties", async () => {
  try {
    const response = await useHttpGets(
      `${ApiEndPoints.watchlist}/${localStorage.getItem(MEMBER_KEY) || ""}`,
      undefined
    );
    if (response.error) {
      return error.message; // Re-throw the error if present
    } else {
      return response.data; // Return the data if no error
    }
  } catch (error) {
    return error.message;
  }
});

export const removePropertyFromWatchList = createAsyncThunk("removePropertyFromWatchList", async (id: number) => {
  try {
    const response = await httpDelete(`${ApiEndPoints.watchlist}/${id}`);
    return response;
  } catch (error) {
    return error;
  }
});

const watchListSlice = createSlice({
  name: "watchList",
  initialState,
  reducers: {
    getWatchListData(state, action: PayloadAction<IWatchListSlice>) {
      state.watchListResponse = action.payload.watchListResponse; // Update the state with the API response
    },
    deleteWatchListData(state, action: PayloadAction<IWatchListSlice>) {
      state.deleteWatchListResponse = action.payload.deleteWatchListResponse; // Update the state with the API response
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getWatchListProperties.fulfilled, (state, action) => {
      state.watchListResponse = action.payload;
      state.loading = false;
    });
    builder.addCase(getWatchListProperties.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(removePropertyFromWatchList.fulfilled, (state, action) => {
      state.deleteWatchListResponse = action.payload;
      state.loading = false;
    });
    builder.addCase(removePropertyFromWatchList.pending, (state, action) => {
      state.loading = true;
    });
  },
});

export const { getWatchListData } = watchListSlice.actions;

export default watchListSlice.reducer;
