import React, { useEffect, useMemo, useState } from "react";
import REIToggablePanel from "../../../components/ui/toggablePanel";
import CashFlow from "../MyTracker/CashFlow";
import { IActuals } from "../../../interface/cashFlow";
import { useCashFlowContext } from "./MyCashFlowContext";

const CashFlowSummaryGraph: React.FC<{
  isShowingTotals: boolean;
  selectedProfileId: number;
  selectedEntityId: number;
  isDataLoading: boolean;
}> = ({ selectedEntityId, selectedProfileId, isShowingTotals, isDataLoading }) => {
  const [selectedProperty, setSelectedProperty] = useState<number>(1);
  const { cashflow } = useCashFlowContext();

  useEffect(() => {
    setSelectedProperty(1);
  }, []);

  const filteredData: {
    Actuals: IActuals[];
    Forecast: IActuals[];
  } = useMemo(() => {
    if (!cashflow) return { Actuals: [], Forecast: [] };

    const entityIdToCompare = selectedEntityId === 0 ? "" : selectedEntityId.toString();
    let filteredData: { Actuals: IActuals[]; Forecast: IActuals[] } = { Actuals: [], Forecast: [] };

    if (isShowingTotals) {
      const totalData = cashflow.TotalForGraph.find((total) => total.EntityId === entityIdToCompare);
      if (totalData) {
        filteredData = {
          Actuals: totalData.Totals.Actuals,
          Forecast: totalData.Totals.Forecast,
        };
      }
    } else {
      if (selectedProperty === 1) {
        const totalData = cashflow.TotalForGraph.find((total) => total.EntityId === entityIdToCompare);
        if (totalData) {
          filteredData = {
            Actuals: totalData.Totals.Actuals,
            Forecast: totalData.Totals.Forecast,
          };
        }
      } else {
        const propertyData = cashflow.PropertyDetails.find(
          (property) => property.PropertyDetail.PortfolioTrackerId === selectedProperty
        );
        if (propertyData) {
          filteredData = {
            Actuals: propertyData.CashflowGraphTotals.Actuals,
            Forecast: propertyData.CashflowGraphTotals.Forecast,
          };
        }
      }
    }
    return filteredData;
  }, [cashflow, selectedEntityId, isShowingTotals, selectedProperty]);

  const handleSelectionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedProperty(Number(event.target.value));
  };

  const renderContent = (
    <>
      <div className="flex flex-col lg:flex-row flex-wrap gap-4 justify-around">
        <CashFlow trackerData={filteredData} entityId="" showingTotalSummary={false}></CashFlow>
        <div className="overflow-y-auto">
          <h3>Showing cash flow summary for:</h3>
          <div className="text-left text-base text-slate-500">
            {cashflow &&
              cashflow?.PropertyDetails.map((property, index) => {
                const address = [
                  property.PropertyDetail.StreetAddress,
                  property.PropertyDetail.Suburb,
                  property.PropertyDetail.District,
                  property.PropertyDetail.State,
                  property.PropertyDetail.Postcode,
                  `(${property.PropertyDetail.Country})`,
                ]
                  .filter(Boolean)
                  .join(", ");
                return (
                  <>
                    <div key={property.PropertyDetail.PortfolioTrackerId} className="flex gap-3">
                      {index === 0 && (
                        <>
                          <input
                            type="radio"
                            id="property-total"
                            name="property"
                            value={1}
                            checked={selectedProperty === 1}
                            onChange={handleSelectionChange}
                          />
                          <label htmlFor="property-total">Total</label>
                        </>
                      )}
                      {index != 0 && (
                        <>
                          <input
                            type="radio"
                            id={`property-${index}`}
                            name="property"
                            value={property.Details.PortfolioTrackerId}
                            checked={selectedProperty === property.Details.PortfolioTrackerId}
                            onChange={handleSelectionChange}
                          />
                          <label htmlFor={`property-${index}`}>{address}</label>
                        </>
                      )}
                    </div>
                  </>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );

  return (
    <REIToggablePanel
      contentComponent={renderContent}
      defaultCollapse={false}
      header="Cash Flow Summary"
      istoggleable={false}
      customClassName="align-middle bg-gray-100"
    ></REIToggablePanel>
  );
};

export default CashFlowSummaryGraph;
