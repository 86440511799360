import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUserDetail } from "../../interface/userdetails";

interface AuthState {
    isLoggedIn: boolean;
    username: string | null;
    memberId: string | null;
    error: string | null;
}

const initialState: AuthState = {
    isLoggedIn: false,
    username: null,
    error: null,
    memberId: null,
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        loginRequest(state) {
            state.error = null;
        },
        loginSuccess(state, action: PayloadAction<IUserDetail>) {
            state.isLoggedIn = true;
            state.username = action.payload.username;
            state.memberId = action.payload.memberId;
            state.error = null;
        },
        loginFailure(state, action: PayloadAction<string>) {
            state.isLoggedIn = false;
            state.username = null;
            state.memberId = null;
            state.error = action.payload;
        },
        logout(state) {
            state.isLoggedIn = false;
            state.username = null;
            state.memberId = null;
            state.error = null;
        },
    }
});

export const { loginRequest, loginSuccess, loginFailure, logout } = authSlice.actions;

export default authSlice.reducer;