import React, { useEffect, useState, useCallback } from "react";
import {
  IAnalysisSummaryResponse,
  ICashDeduction,
  ILoanPrincipalInterest,
  IPropertyExpense,
} from "../../../../interface/analysisSummary";
import { REIDialoge, REIProgressSpinner } from "../../../../components/ui";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import EditableDataTable from "../../../../components/shared/editableDataTable";
import { triggerReload } from "../../../../store/slice/analyserSlice";
import { ForecastItemCode, MEMBER_KEY, USER_DETAIL, YEARS } from "../../../../utils/constants";
import { httpUpdate } from "../../../../services/httpService";
import { ApiEndPoints } from "../../../../utils/endpoints";
import UpdateExpenseDialoge from "./UpdateExpenseDialogue";
import CashDeductionLoanDetail from "./CashDeductionLoanDetail";
import { RadioButton } from "primereact/radiobutton";

const CashDeductions: React.FC<{
  editFlag: boolean;
  visibleYears: { label: string; defaultShow: boolean }[];
  resetEditFlag: () => void;
}> = ({ editFlag, visibleYears, resetEditFlag }) => {
  const analysisSummary = useSelector((state: RootState) => state.analyser.analysisSummary as IAnalysisSummaryResponse);
  const memberDetail = JSON.parse(localStorage.getItem(USER_DETAIL) || "{}");
  const [cashDeductionData, setCashDeductionData] = useState<{ [key: string]: any }>({});
  const [loading, setLoading] = useState(false);
  const [showExpenseDialogue, setShowExpenseDialogue] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<{ key?: string; year?: string; value?: string }>({});
  const [propertyExpense, setPropertyExpense] = useState<IPropertyExpense>();
  const [cashDeduction, setCashDeduction] = useState<ICashDeduction[]>();
  const [loans, setLoans] = useState<ILoanPrincipalInterest[]>();

  const dispatch = useDispatch();

  const didAnyValueChange = useCallback(
    (itemCode: string) =>
      analysisSummary?.ForecastOverrides?.some((forecast) => forecast.ForecastItemCode === itemCode),
    [analysisSummary]
  );

  const forectedForFutureYear = useCallback(
    (itemCode: string, year: number) =>
      analysisSummary?.ForecastOverrides?.some(
        (forecast) => forecast.ForecastItemCode === itemCode && forecast.Year === year && forecast.ThisYearOnly === 0
      ),
    [analysisSummary]
  );

  const mapValuesToYears = (
    overview: { [key: string]: any },
    forecastCode: string,
    prefix: string,
    editableYears: number[],
    hideNegativeValue = false,
    doNotShowFirstYear = false
  ) => {
    const yearsMap: {
      [key: string]: {
        value: string;
        editable: boolean;
        didValueChange: boolean;
        forcastedForFutureYears: boolean;
      };
    } = {};

    YEARS.forEach((year, index) => {
      const yearLabel = year.label.replace("Year ", "Yr");
      const key = `${prefix}${yearLabel}`;
      const rawValue = overview[key]?.toString() || "";

      const value =
        doNotShowFirstYear && index === 0 ? "" : hideNegativeValue && parseFloat(rawValue) < 0 ? "" : rawValue;

      yearsMap[year.label] = {
        value,
        editable: editableYears.includes(index),
        didValueChange: false,
        forcastedForFutureYears: forectedForFutureYear(forecastCode, index),
      };
    });

    return yearsMap;
  };

  const initializeCashDeductionData = (analysisSummary: IAnalysisSummaryResponse) => ({
    "Property expenses $": {
      type: "currency",
      forecastOverrideKey: ForecastItemCode.CashflowPropertyExpenseRates,
      anyCellValueChange: didAnyValueChange(ForecastItemCode.CashflowPropertyExpenseRates),
      values: mapValuesToYears(
        analysisSummary.AnalysisCashDeduction,
        ForecastItemCode.CashflowPropertyExpenseRates,
        "PropertyExpense",
        [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
      ),
    },
  });

  const footer = (analysisSummary: IAnalysisSummaryResponse) =>
    analysisSummary && {
      "Pre-tax cash flow p/a $": {
        type: "currency",
        values: mapValuesToYears(analysisSummary.AnalysisCashDeduction, "", "PreTaxCashflowpa", [], false, true),
      },
      "Pre-tax cash flow p/w $": {
        type: "currency",
        values: mapValuesToYears(analysisSummary.AnalysisCashDeduction, "", "PreTaxCashflowpw", [], false, true),
      },
    };

  useEffect(() => {
    if (analysisSummary) {
      setCashDeductionData(initializeCashDeductionData(analysisSummary));
      setCashDeduction(analysisSummary.AnalysisCashDeduction.CashDeductions);
      setLoans(analysisSummary.LoanPrincipalInterest);
    }
  }, [analysisSummary]);

  const onEdit = (rowKey: string, year: string, value: string) => {};

  const onInputClick = (rowKey: string, year: string, value: string) => {
    setSelectedRow({ key: rowKey, year: year, value: value });
    const propertyExpenseIncome = analysisSummary.PropertyExpense.find(
      (item) => item.Year == parseInt(year.replace("Year ", ""))
    );
    setPropertyExpense(propertyExpenseIncome);
    setShowExpenseDialogue(true);
  };

  const onForecastEdit = async (rowKey: string, year: string, value: string, forecastItemCode: string) => {
    setLoading(true);
    var variableBody = {};
    variableBody = {
      MemberId: localStorage.getItem(MEMBER_KEY),
      MemberCountryCode: memberDetail.Country,
      LVR: analysisSummary?.Levers?.LoanValueRatio,
      LoansPrincipalInterest: analysisSummary?.LoanPrincipalInterest,
      ForecastOverrides:
        year === "Year 10"
          ? analysisSummary.ForecastOverrides.map((forecast) => {
              return {
                ...forecast,
                Remove: 1,
                ThisYearOnly: 0,
              };
            })
          : analysisSummary.ForecastOverrides.map((forecast) => {
              return {
                ...forecast,
                ThisYearOnly: 0,
              };
            }),
      PropertyAnalyserId: analysisSummary?.PropertyAnalyserId,
    };

    updateForecastValue(variableBody, false);
  };

  const updateForecastValue = async (variableBody: object, updateSingleValue: boolean) => {
    try {
      const result = await httpUpdate(`${ApiEndPoints.analyser}/AnalysisReport/`, variableBody);

      if (result.status === 200 && result.data) {
        dispatch(triggerReload());

        !updateSingleValue && resetEditFlag();
      }
    } catch (error) {
      console.error("Error updating loan value ratio:", error);
    } finally {
      setLoading(false);
      resetEditFlag();
    }
  };

  const hideEditDialog = useCallback(() => {
    setShowExpenseDialogue(false);
    resetEditFlag();
  }, []);

  if (!analysisSummary || loading) {
    return (
      <div className="w-full text-center">
        <REIProgressSpinner />
      </div>
    );
  }

  const onLoanTypeChange = (e: any, loanId: number) => {
    setLoading(true);
    var variableBody = {
      MemberId: localStorage.getItem(MEMBER_KEY),
      MemberCountryCode: memberDetail.Country,
      LVR: analysisSummary?.Levers?.LoanValueRatio,
      LoansPrincipalInterest: loans?.map((loan) => {
        if (loan.LoanId === loanId) {
          return { ...loan, IsPrincipal: e.value };
        }
        return loan;
      }),
      ForecastOverrides: [],
      PropertyAnalyserId: analysisSummary?.PropertyAnalyserId,
    };

    updateForecastValue(variableBody, false);

    setLoans(
      loans?.map((loan) => {
        if (loan.LoanId === loanId) {
          return { ...loan, IsPrincipal: e.value };
        }
        return loan;
      })
    );
  };

  return (
    <>
      <REIDialoge
        showHeader={true}
        header={`Update Rent - ${selectedRow?.year}`}
        visible={showExpenseDialogue}
        handleEditClick={hideEditDialog}
        position="top"
        customClass="update-statistics-dialog"
        style={{ width: "60vw" }}
        contentComponent={
          <UpdateExpenseDialoge
            value={selectedRow.value}
            year={selectedRow.year}
            propertyExpense={propertyExpense || ({} as IPropertyExpense)}
            closeDialogue={hideEditDialog}
          />
        }
      ></REIDialoge>
      <EditableDataTable
        title="Cash Deductions"
        data={cashDeductionData}
        years={visibleYears.map((year) => year.label)}
        isEditable={editFlag}
        onForecastValueChange={onForecastEdit}
        onEdit={onEdit}
        onClick={onInputClick}
      />
      {cashDeduction &&
        cashDeduction.length > 0 &&
        cashDeduction.map((item) => (
          <React.Fragment key={`${item.LoanNo}-${item.LoanName}`}>
            <div className="flex flex-row w-full p-2">
              <div className="w-1/2">
                <span className="font-semibold">{`Loan ${item.LoanNo.toString()}:${item.LoanName}`}</span>
              </div>
              {editFlag && (
                <div className="w-1/2 text-end">
                  <RadioButton
                    inputId={`loanPrincipal-${item.LoanId}`}
                    name={`loanType-${item.LoanId}`}
                    value={1} // "Principal and Interest" corresponds to value 1
                    onChange={(e) => {
                      onLoanTypeChange(e, item.LoanId);
                    }}
                    checked={loans?.find((loan) => loan.LoanId === item.LoanId)?.IsPrincipal === 1}
                  />
                  <label htmlFor={`loanPrincipal-${item.LoanId}`} className="mx-2">
                    Principal and Interest
                  </label>
                  <RadioButton
                    inputId={`loanInterest-${item.LoanId}`}
                    name={`loanType-${item.LoanId}`}
                    value={0} // "Interest Only Loan" corresponds to value 0
                    onChange={(e) => {
                      onLoanTypeChange(e, item.LoanId);
                    }}
                    checked={loans?.find((loan) => loan.LoanId === item.LoanId)?.IsPrincipal === 0}
                  />
                  <label htmlFor={`loanInterest-${item.LoanId}`} className="ml-2">
                    Interest Only Loan
                  </label>
                </div>
              )}
            </div>
            <CashDeductionLoanDetail
              visibleYears={visibleYears}
              editFlag={editFlag}
              resetEditFlag={resetEditFlag}
              data={item}
            />
          </React.Fragment>
        ))}

      <EditableDataTable
        title=""
        data={{}}
        years={visibleYears.map((year) => year.label)}
        isEditable={editFlag}
        onEdit={onEdit}
        footer={footer(analysisSummary)}
      />
    </>
  );
};

export default CashDeductions;
