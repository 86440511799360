import React, { useRef, useState } from "react";
import { Stepper } from "primereact/stepper";
import { StepperPanel } from "primereact/stepperpanel";
import REIButton from "./button";

interface IStep {
  header: string;
  content: React.ReactNode;
  validate?: () => boolean;
}

interface IREIStepperProps {
  steps: IStep[];
  linear?: boolean;
}

const REIStepper: React.FC<IREIStepperProps> = ({ steps, linear }) => {
  const stepperRef = useRef<any>(null);
  const [activeStep, setActiveStep] = useState(0);
  const [validSteps, setValidSteps] = useState(Array(steps.length).fill(false));
  const [errorMessage, setErrorMessage] = useState("");

  const validateStep = (stepIndex: number) => {
    return steps[stepIndex]?.validate ? steps[stepIndex].validate() : true;
  };

  const goToNextStep = () => {
    if (validateStep(activeStep)) {
      const newValidSteps = [...validSteps];
      newValidSteps[activeStep] = true;
      setValidSteps(newValidSteps);
      setErrorMessage("");
      setActiveStep(activeStep + 1);
      if (stepperRef.current) {
        stepperRef.current.nextCallback();
      }
    } else {
      setErrorMessage("Please complete the required fields before proceeding.");
    }
  };

  const goToPreviousStep = () => {
    setActiveStep(activeStep - 1);
    setErrorMessage("");
    if (stepperRef.current) {
      stepperRef.current.prevCallback();
    }
  };

  return (
    <Stepper ref={stepperRef} activeStep={activeStep} linear={linear}>
      {steps.map((step, index) => (
        <StepperPanel key={index} header={step.header}>
          <>{step.content}</>
          {errorMessage && activeStep === index && (
            <div className="pt-2" style={{ color: "red" }}>
              {errorMessage}
            </div>
          )}
          <div className="flex pt-4 justify-content-between">
            <div className="flex-1 w-1/2 text-start">
              {index > 0 && (
                <REIButton label="Back" severity="secondary" icon="pi pi-arrow-left" onClick={goToPreviousStep} />
              )}
            </div>
            <div className="flex-1 w-1/2  text-end">
              {index < steps.length - 1 && (
                <REIButton label="Next" icon="pi pi-arrow-right" iconPos="right" onClick={goToNextStep} />
              )}
            </div>
          </div>
        </StepperPanel>
      ))}
    </Stepper>
  );
};

export default REIStepper;
