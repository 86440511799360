import React, { useEffect, useState, useCallback } from "react";
import { IAnalysisSummaryResponse, ICashDeduction } from "../../../../interface/analysisSummary";
import { REIProgressSpinner } from "../../../../components/ui";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import EditableDataTable from "../../../../components/shared/editableDataTable";
import { triggerReload } from "../../../../store/slice/analyserSlice";
import { ForecastItemCode, MEMBER_KEY, USER_DETAIL, YEARS } from "../../../../utils/constants";
import { httpUpdate } from "../../../../services/httpService";
import { ApiEndPoints } from "../../../../utils/endpoints";

const CashDeductionLoanDetail: React.FC<{
  editFlag: boolean;
  data: ICashDeduction;
  visibleYears: { label: string; defaultShow: boolean }[];
  resetEditFlag: () => void;
}> = ({ editFlag, visibleYears, resetEditFlag, data }) => {
  const analysisSummary = useSelector((state: RootState) => state.analyser.analysisSummary as IAnalysisSummaryResponse);
  const [loanData, setLoanData] = useState<{ [key: string]: any }>({});
  const [loading, setLoading] = useState(false);
  const memberDetail = JSON.parse(localStorage.getItem(USER_DETAIL) || "{}");

  const dispatch = useDispatch();

  const didValueChange = useCallback(
    (itemCode: string, year: number) =>
      analysisSummary?.ForecastOverrides?.some(
        (forecast) =>
          forecast.ForecastItemCode === itemCode && forecast.Year === year && forecast.LoanId === data.LoanId
      ),
    [analysisSummary]
  );

  const didAnyValueChange = useCallback(
    (itemCode: string) =>
      analysisSummary?.ForecastOverrides?.some(
        (forecast) => forecast.ForecastItemCode === itemCode && forecast.LoanId === data.LoanId
      ),
    [analysisSummary]
  );

  const forectedForFutureYear = useCallback(
    (itemCode: string, year: number) =>
      analysisSummary?.ForecastOverrides?.some(
        (forecast) =>
          forecast.ForecastItemCode === itemCode &&
          forecast.Year === year &&
          forecast.ThisYearOnly === 0 &&
          forecast.LoanId === data.LoanId
      ),
    [analysisSummary]
  );

  const mapValuesToYears = (
    overview: { [key: string]: any },
    forecastCode: string,
    prefix: string,
    editableYears: number[],
    displayForecastButton: boolean = true
  ) => {
    const yearsMap: {
      [key: string]: { value: string; editable: boolean; didValueChange: boolean; forcastedForFutureYears: boolean };
    } = {};
    YEARS.forEach((year, index) => {
      const yearLabel = year.label.replace("Year ", "Yr");
      yearsMap[year.label] = {
        value: overview[`${prefix}${yearLabel}`]?.toString(),
        editable: editableYears?.includes(index),
        didValueChange: displayForecastButton ? didValueChange(forecastCode, index) : false,
        forcastedForFutureYears: forectedForFutureYear(forecastCode, index),
      };
    });
    return yearsMap;
  };

  var initializeLoanData = () => ({
    "Interest rate %": {
      type: "%",
      forecastOverrideKey: ForecastItemCode.CashflowMortgageInterestRate,
      anyCellValueChange: didAnyValueChange(ForecastItemCode.CashflowMortgageInterestRate),
      values: mapValuesToYears(
        data,
        ForecastItemCode.CashflowMortgageInterestRate,
        "InterestRate",
        [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
      ),
    },
    "Interest payments $": {
      type: "currency",
      forecastOverrideKey: ForecastItemCode.CashflowMortgageInterestPayment,
      anyCellValueChange: didAnyValueChange(ForecastItemCode.CashflowMortgageInterestPayment),
      values: mapValuesToYears(
        data,
        ForecastItemCode.CashflowMortgageInterestPayment,
        "MortgageInterestPayments",
        [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
      ),
    },
    "Principal payments $": {
      type: "currency",
      forecastOverrideKey: ForecastItemCode.CashflowMortgagePrincipalPayment,
      anyCellValueChange: didAnyValueChange(ForecastItemCode.CashflowMortgagePrincipalPayment),
      values: mapValuesToYears(
        data,
        ForecastItemCode.CashflowMortgagePrincipalPayment,
        "PrincipalPayments",
        [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        false
      ),
    },
  });

  useEffect(() => {
    if (data) {
      setLoanData(initializeLoanData());
    }
  }, [data]);

  const onEdit = async (rowKey: string, year: string, value: string, forecastItemCode: string | undefined) => {
    const previousValue = loanData[rowKey].values[year].value;
    if (parseFloat(previousValue) === parseFloat(value)) {
      return;
    }
    setLoading(true);
    const variableBody = {
      MemberId: localStorage.getItem(MEMBER_KEY),
      MemberCountryCode: memberDetail.Country,
      LVR: analysisSummary?.Levers?.LoanValueRatio,
      LoansPrincipalInterest: analysisSummary?.LoanPrincipalInterest,
      ForecastOverrides: [
        {
          ForecastItemCode: forecastItemCode,
          Year: year.replace("Year ", "").trim(),
          ThisYearOnly: 1,
          Value: parseFloat(value).toString(),
          Remove: 0,
          LoanId: data.LoanId,
        },
      ],
      PropertyAnalyserId: analysisSummary?.PropertyAnalyserId,
    };
    updateForecastValue(variableBody, true);
    setLoanData((prevData) => ({
      ...prevData,
      [rowKey]: {
        ...prevData[rowKey],
        values: {
          ...prevData[rowKey].values,
          [year]: { ...prevData[rowKey].values[year], value },
        },
      },
    }));
  };

  const onForecastEdit = async (rowKey: string, year: string, value: string, forecastItemCode: string) => {
    setLoading(true);
    var variableBody = {};
    variableBody = {
      MemberId: localStorage.getItem(MEMBER_KEY),
      MemberCountryCode: memberDetail.Country,
      LVR: analysisSummary?.Levers?.LoanValueRatio,
      LoansPrincipalInterest: analysisSummary?.LoanPrincipalInterest,
      ForecastOverrides:
        year === "Year 10"
          ? analysisSummary.ForecastOverrides.map((forecast) => {
              return {
                ...forecast,
                Remove: 1,
              };
            })
          : [
              {
                ForecastItemCode: forecastItemCode,
                Year: year.replace("Year ", "").trim(),
                ThisYearOnly: 0,
                Value: parseFloat(value).toString(),
                Remove: 0,
                LoanId: data.LoanId,
              },
            ],
      PropertyAnalyserId: analysisSummary?.PropertyAnalyserId,
    };
    updateForecastValue(variableBody, false);
  };

  const updateForecastValue = async (variableBody: object, updateSingleValue: boolean) => {
    try {
      const result = await httpUpdate(`${ApiEndPoints.analyser}/AnalysisReport/`, variableBody);

      if (result.status === 200 && result.data) {
        dispatch(triggerReload());

        !updateSingleValue && resetEditFlag();
      }
    } catch (error) {
      console.error("Error updating loan value ratio:", error);
    } finally {
      setLoading(false);
    }
  };

  if (!analysisSummary || loading) {
    return (
      <div className="w-full text-center">
        <REIProgressSpinner />
      </div>
    );
  }

  return (
    loanData && (
      <EditableDataTable
        title=""
        data={loanData}
        years={visibleYears.map((year) => year.label)}
        isEditable={editFlag}
        onForecastValueChange={onForecastEdit}
        onEdit={onEdit}
      />
    )
  );
};

export default CashDeductionLoanDetail;
