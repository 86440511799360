import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Field } from "react-final-form";
import { useDispatch } from "react-redux";

import { Menubar } from "primereact/menubar";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";

import { httpPost, useHttpGets } from "../services/httpService";
import { ApiEndPoints } from "../utils/endpoints";
import { MEMBER_KEY, TOKEN_KEY, USER_DETAIL, httpCode } from "../utils/constants";

import { loginSuccess } from "../store/slice/authSlice";

import REICard from "../components/ui/card";
import REILinkButton from "../components/ui/linkButton";
import InputField from "../components/ui/inputField";
import ErrorMessage from "../components/ui/errorMessage";

const Login = () => {
  const navigate = useNavigate();
  const toast = useRef(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const validate = (data) => {
    let errors = {
      username: undefined,
      password: undefined,
    };

    if (!data.username) {
      errors.username = "Username is required.";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.username)) {
      errors.username = "Invalid email address. E.g. example@email.com";
    }

    if (!data.password) {
      errors.password = "Password is required.";
    }
    return errors;
  };

  const onSubmit = async (data, form) => {
    setLoading(true);

    const userdetail = {
      UserName: data.username,
      Password: data.password,
      SessionType: 0,
    };

    const response = await httpPost(ApiEndPoints.login + "AuthMember", userdetail);

    if (response?.status === httpCode.SUCCESS) {
      localStorage.setItem(TOKEN_KEY, response.data.AuthToken);
      localStorage.setItem(MEMBER_KEY, response.data.MemberID);

      await useHttpGets(`${ApiEndPoints.login}Member/${response.data.MemberID}`, undefined)
        .then((result) => {
          if (result.status == 200) {
            localStorage.setItem(USER_DETAIL, JSON.stringify(result.data));
          }
        })
        .catch(() => {
          return <ErrorMessage message="Get error while fetching users data" />;
        });

      dispatch(
        loginSuccess({
          username: data.username,
          memberId: response.data.MemberID,
        })
      );

      navigate("/");
    } else if (response?.error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: response.error,
        life: 3000,
      });
    }
    form.restart();
    setLoading(false);
  };

  return (
    <div className="w-full flex flex-col h-screen">
      <Toast ref={toast} />
      <Menubar className="bg-[#292b2c] h-12 border-none rounded-none" />
      <div className="flex flex-col flex-1">
        <div className="flex justify-center">
          <img
            alt="logo"
            src="https://info.realestateinvestar.com.au/hubfs/promembershiptools/images/real_estate_investar_tools.png"
            className="mr-2 max-w-80"
          />
        </div>
        <div className="login flex justify-center min-h-96">
          <REICard className="flex md:w-5/12 w-full bg-[#fff] my-5 justify-center shadow-lime-300">
            <div className="max-w-96 justify-center">
              <div className="flex justify-center font-bold">
                <h1>MEMBERSHIP LOGIN</h1>
              </div>
              <div>
                <span>If you're an existing member, please log in here.</span>
              </div>
              <Form
                onSubmit={onSubmit}
                initialValues={{ username: "", password: "" }}
                validate={validate}
                render={({ handleSubmit, submitting, valid }) => (
                  <form onSubmit={handleSubmit} className="p-fluid">
                    <Field
                      name="username"
                      render={({ input, meta }) => (
                        <div className="mt-3">
                          <InputField id="username" input={input} meta={meta} placeholder="Username" icon="pi-user" />
                        </div>
                      )}
                    />
                    <Field
                      name="password"
                      render={({ input, meta }) => (
                        <div className="mt-3">
                          <InputField
                            id="password"
                            input={input}
                            meta={meta}
                            placeholder="Password"
                            type="password"
                            icon="pi-lock"
                          />
                        </div>
                      )}
                    />
                    <div className="mt-3">
                      <Button
                        label="SIGN IN"
                        type="submit"
                        severity="success"
                        disabled={submitting || !valid}
                        autoFocus
                        loading={loading}
                      />
                    </div>
                  </form>
                )}
              />
              <div className="flex justify-center border-t border-black mt-5">
                <p className="mt-2 text-sm">
                  Don't have a membership?{" "}
                  <REILinkButton
                    href="https://info.realestateinvestar.com.au/pricing"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="cursor-pointer text-blue-600"
                  >
                    Please Sign Up Here
                  </REILinkButton>
                  <br />
                  Having trouble logging in? Cookies must be enabled to use our website,{" "}
                  <REILinkButton
                    href="https://www.wikihow.com/Enable-Cookies-in-Your-Internet-Web-Browser"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="cursor-pointer text-blue-600"
                  >
                    click here
                  </REILinkButton>{" "}
                  for instructions.
                </p>
              </div>
              <div className="flex justify-center">
                <p className="mt-2 text-sm text-center">
                  <a href="#" target="_blank" className="cursor-pointer text-blue-600">
                    Forgot password?
                  </a>
                  <br />
                  <br />
                  By logging into this site, I have read and agree with the
                  <br />
                  <a
                    href="https://info.realestateinvestar.com.au/terms-conditions-v5"
                    target="_blank"
                    className="cursor-pointer text-blue-600"
                  >
                    Terms & Conditions
                  </a>
                </p>
              </div>
            </div>
          </REICard>
        </div>
        <div className="last-div flex-grow bg-black"></div>
      </div>
    </div>
  );
};

export default Login;
