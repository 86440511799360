import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Toast } from "primereact/toast";
import Gallery from "../../../../components/ui/gallery";
import { REIDropDown, REIInputText, REIProgressSpinner } from "../../../../components/ui";
import REIToggablePanel from "../../../../components/ui/toggablePanel";
import { GET_LENGTH_OPTIONS, PROPERTY_TYPE, Status } from "../../../../utils/constants";
import { IAnalysedProperty, IPropertyDetail } from "../../../../interface/addAnalyser";
import { BathIcon, BedIcon, CarIcon } from "../../../../public/assets/images";
import { useAnalysedPropertyContext } from "./AnalysedPropertyContext";
import { fetchFiles } from "../../../../store/slice/fileUploadSlice";

const PropertyInfoCard: React.FC<{ updateProperty: (updated: Partial<IAnalysedProperty>) => void }> = ({
  updateProperty,
}) => {
  const { analysedProperty } = useAnalysedPropertyContext();
  const [propertyDetails, setPropertyDetails] = useState<IPropertyDetail | undefined>(undefined);
  const getBedRoomOptions = () => [...GET_LENGTH_OPTIONS(0, 9, (i) => (i === 9 ? "9+" : i.toString()))];
  const getBathCarOptions = () => [...GET_LENGTH_OPTIONS(0, 5, (i) => (i === 5 ? "5+" : i.toString()), 2)];
  const [loading, setLoading] = useState<boolean>(false);
  const [fileData, setFileData] = useState();
  const dispatch = useDispatch();
  const toastRef = useRef<Toast>(null);
  const FetchFileError = useSelector((state) => state.files?.error);
  const getFileResponse = useSelector((state) => state.files?.files);
  const FileStatus = useSelector((state) => state.files?.status);
  const FormattedDate = useRef<string>();

  useEffect(() => {
    if (analysedProperty) {
      setPropertyDetails(analysedProperty.PropertyDetail);
      formatDate();
    }
  }, [analysedProperty]);

  useEffect(() => {
    const getData = async () => {
      await dispatch(
        fetchFiles({
          WatchlistID: analysedProperty?.PropertyDetail.MemberPropertyId,
          isAttachementData: false,
        })
      );
      setLoading(false);
    };

    if (analysedProperty?.PropertyDetail.MemberPropertyId) {
      getData();
    }
  }, [dispatch, analysedProperty?.PropertyDetail.MemberPropertyId]);

  useEffect(() => {
    if (FileStatus === Status.SUCCESS && FetchFileError === "") {
      setFileData(getFileResponse);
      setLoading(false);
    } else if (FileStatus === Status.FAILED) {
      toastRef.current?.show({
        severity: "error",
        summary: Messages.ERROR,
        detail: Messages.ERROR,
        life: 2000,
      });
    } else {
      setLoading(true);
    }
  }, [FileStatus, FetchFileError, getFileResponse]);

  useEffect(() => {
    if (propertyDetails) {
      updateProperty({
        ...analysedProperty,
        PropertyDetail: propertyDetails,
      });
    }
  }, [propertyDetails]);

  const formatDate = () => {
    let DateAdded = new Date(propertyDetails?.DateAdded);
    FormattedDate.current = DateAdded.getDate() + "/" + DateAdded.getMonth() + "/" + DateAdded.getFullYear();
  };

  const setValues = (name: string, value: string | number) => {
    setPropertyDetails((prevState) => {
      if (!prevState) return undefined;
      const updatedExpense = {
        ...prevState,
        [name]: value !== undefined ? value : 0,
      };
      return updatedExpense;
    });
  };

  const itemTemplate = (item) => {
    return <img className="h-40 w-full block" src={item.FileUrl} alt={item.alt} />;
  };

  const RenderPanelContent = (
    <div className="flex flex-row">
      <div className="w-1/3 h-40 mr-3">
        {loading && (
          <div className="flex items-center">
            <REIProgressSpinner></REIProgressSpinner>
          </div>
        )}
        <div>
          {fileData && fileData.length !== 0 && (
            <Gallery
              autoPlay={true}
              imageData={fileData}
              circular={true}
              classNames="max-w-full h-40 object-cover"
              itemTemplate={itemTemplate}
              showIndicators={false}
              showThumbnails={false}
              showItemNavigators={true}
            ></Gallery>
          )}
        </div>
      </div>
      <div className="flex flex-col">
        <div className="flex gap-2 mb-3">
          <div className="w-1/3">
            <img src={BathIcon} alt="BathIcon" className="h-5" />
            <REIDropDown
              data={getBedRoomOptions()}
              label="Label"
              value={propertyDetails?.BathroomId}
              onSelectionChange={(e) => setValues("BathroomId", Array.isArray(e.value) ? e.value[0] : e.value)}
            />
          </div>
          <div className="w-1/3">
            <img src={BedIcon} alt="BathIcon" className="h-5" />
            <REIDropDown
              data={getBathCarOptions()}
              label="Label"
              value={propertyDetails?.BedroomId}
              onSelectionChange={(e) => setValues("BedroomId", Array.isArray(e.value) ? e.value[0] : e.value)}
            />
          </div>
          <div className="w-1/3">
            <img src={CarIcon} alt="BathIcon" className="h-5" />
            <REIDropDown
              data={getBathCarOptions()}
              label="Label"
              value={propertyDetails?.CarportId}
              onSelectionChange={(e) => setValues("CarportId", Array.isArray(e.value) ? e.value[0] : e.value)}
            />
          </div>
        </div>
        <div className="flex">
          <div className="w-1/3 text-center">
            <label className="font-medium text-gray-700 block mb-2">Land area (m²) </label>
            <REIInputText
              name="LandArea"
              type="text"
              value={propertyDetails?.Landsize}
              onChange={(e) => setValues("Landsize", e.target.value)}
            ></REIInputText>
          </div>
          <div className="w-1/3 text-center">
            <label className="font-medium text-gray-700 block mb-2">added</label>
            <div className="content-center h-12"> {propertyDetails?.DateAdded}</div>
          </div>
          <div className="w-1/3 text-center">
            <label className="font-medium text-gray-700 block mb-2">Prop. type</label>
            <REIDropDown
              data={PROPERTY_TYPE}
              label="type"
              value={propertyDetails?.PropertyTypeId}
              onSelectionChange={(e) => setValues("PropertyTypeId", Array.isArray(e.value) ? e.value[0] : e.value)}
            />
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <REIToggablePanel
        contentComponent={RenderPanelContent}
        header=""
        customClassName="content-center"
        customHeader={
          <div className="bg-slate-700 rounded-t-lg text-white text-center p-2">
            {analysedProperty?.PropertyDetail.PropertyName}
          </div>
        }
        istoggleable={false}
        defaultCollapse={false}
      ></REIToggablePanel>
    </div>
  );
};

export default PropertyInfoCard;
