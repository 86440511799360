import { RadioButton } from "primereact/radiobutton";
import { useEffect, useRef, useState } from "react";
import { REIButton, REIDropDown, REIInputNumber, REILinkButton } from "../../../components/ui";
import { LENGTH_OPTION_1_100, MEMBER_KEY, USER_DETAIL } from "../../../utils/constants";
import { Slider } from "primereact/slider";
import { httpUpdate, useHttpGet } from "../../../services/httpService";
import { ApiEndPoints } from "../../../utils/endpoints";
import { Toast } from "primereact/toast";
import { IDefaultProfile, IDefaultProfileSave } from "../../../interface/mydefaultprofile";

export interface IMyDefaultProfileProps {
  closeDialog?: () => void;
}

const MyDefaultProfile: React.FC<IMyDefaultProfileProps> = ({ closeDialog }) => {
  const [defaultProfile, setDefaultProfile] = useState<IDefaultProfile>({} as IDefaultProfile);
  const [loading, setLoading] = useState(false);
  const toastRef = useRef(null);
  const [loantype, setLoanType] = useState("");
  const [loanLength, setLoanLength] = useState(30);
  const [loaddeposit, setLoanDeposit] = useState("");
  const [interestValue, setInterestValue] = useState(0);
  const [cashdeposit, setCashDeposit] = useState(0);
  const [equitydeposit, setEquityDeposit] = useState(0);
  const [estemationfee, setEstemationFee] = useState(0);
  const [valuationfee, setValuationFee] = useState(0);
  const [solicitorfee, setSolicitorFee] = useState(0);
  const [improvement, setImprovement] = useState(0);
  const [otherloancost, setOtherLoanCost] = useState(0);
  const [cpi, setCPI] = useState(0);
  const [capitalgrowth, setCapitalGrowth] = useState(0);
  const [rate, setRates] = useState(0);
  const [insurance, setInsurance] = useState(0);
  const [repair, setRepair] = useState(0);

  const [bodycorporate, setBodyCorporate] = useState(0);
  const [rateexpenses, setRateExpenses] = useState("");
  const [insuranceexpenses, setInsuranceExpenses] = useState("");
  const [repairexpenses, setRepairExpenses] = useState("");
  const [bodycorporateexpenses, setBodyCorporateExpenses] = useState("");
  const [cleaning, setCleaning] = useState(0);
  const [lettingfee, setLettingFee] = useState(0);
  const [landlease, setLandLease] = useState(0);
  const [managementfees, setManagementFees] = useState(0);
  const [gardening, setGardening] = useState(0);
  const [propertytype, setPropertyType] = useState("");
  const [refreshData] = useState(false);
  const memberDetail = JSON.parse(localStorage.getItem(USER_DETAIL));

  const enum OPTION_VALUE {
    DOLLARTYPE = "dollarvalue",
    PURCHASETYPE = "purchaseprice",
    INTRESTTYPE = "interestonly",
    WITHINTRESTTYPE = "withinterest",
    ALLPROPERTY = "allproperties",
    ONLYTHIS = "onlythisproperty",
  }

  const { data: options, error } = useHttpGet(
    `${ApiEndPoints.analyser}/UserDefault?ModulesID=${localStorage.getItem(MEMBER_KEY)}&Country=${
      memberDetail.Country
    }&memberName=${memberDetail.FirstName + " " + memberDetail.LastName}`,
    undefined,
    refreshData
  );

  useEffect(() => {
    if (options) {
      setDefaultProfile(options);
    }
  }, [options]);

  useEffect(() => {
    initDefaultProfile();
  }, [defaultProfile]);

  const initDefaultProfile = () => {
    if (!defaultProfile) return;
    setLoanType(
      defaultProfile.MortgageType?.PrincipalNInterest == "1" ? OPTION_VALUE.WITHINTRESTTYPE : OPTION_VALUE.INTRESTTYPE
    );
    setLoanLength(defaultProfile.MortgageType?.LengthOfLoan);
    setInterestValue(defaultProfile.MortgageType?.InterestRate);
    setLoanDeposit(defaultProfile.LoanDeposit?.EnterValue == "1" ? OPTION_VALUE.DOLLARTYPE : OPTION_VALUE.PURCHASETYPE);
    setCashDeposit(
      defaultProfile.LoanDeposit?.EnterValue == "1"
        ? defaultProfile.LoanDeposit?.CashDeposit
        : defaultProfile.LoanDeposit?.CashDepositPercentage
    );
    setEquityDeposit(
      defaultProfile.LoanDeposit?.EnterValue == "1"
        ? defaultProfile.LoanDeposit?.EquityDeposit
        : defaultProfile.LoanDeposit?.EquityDepositPercentage
    );
    setEstemationFee(defaultProfile.LoanCost?.EstPrice);
    setValuationFee(defaultProfile.LoanCost?.ValuationFee);
    setSolicitorFee(defaultProfile.LoanCost?.SolicitorFee);
    setImprovement(defaultProfile.LoanCost?.ImprovementFee);
    setOtherLoanCost(defaultProfile.LoanCost?.OtherLoanCost);
    setCPI(defaultProfile.TaxRateMarketFactors?.ConsumerPrice);
    setCapitalGrowth(defaultProfile.TaxRateMarketFactors?.CapitalGrowth);

    setRateExpenses(
      defaultProfile.PropertyExpenseStandard?.isRatePercentage == "1"
        ? OPTION_VALUE.PURCHASETYPE
        : OPTION_VALUE.DOLLARTYPE
    );
    setRates(
      defaultProfile.PropertyExpenseStandard?.isRatePercentage == "1"
        ? defaultProfile.PropertyExpenseStandard?.RatesPercentage
        : defaultProfile.PropertyExpenseStandard?.Rates
    );

    setInsuranceExpenses(
      defaultProfile.PropertyExpenseStandard?.isInsurancePercentage == "1"
        ? OPTION_VALUE.PURCHASETYPE
        : OPTION_VALUE.DOLLARTYPE
    );
    setInsurance(
      defaultProfile.PropertyExpenseStandard?.isInsurancePercentage == "1"
        ? defaultProfile.PropertyExpenseStandard?.InsurancePercentage
        : defaultProfile.PropertyExpenseStandard?.Insurance
    );

    setRepairExpenses(
      defaultProfile.PropertyExpenseStandard?.isRepairPercentage == "1"
        ? OPTION_VALUE.PURCHASETYPE
        : OPTION_VALUE.DOLLARTYPE
    );
    setRepair(
      defaultProfile.PropertyExpenseStandard?.isRepairPercentage == "1"
        ? defaultProfile.PropertyExpenseStandard?.RepairPercentage
        : defaultProfile.PropertyExpenseStandard?.Repair
    );

    setBodyCorporateExpenses(
      defaultProfile.PropertyExpenseSpecific?.isBodyCorporatePercentage == "1"
        ? OPTION_VALUE.PURCHASETYPE
        : OPTION_VALUE.DOLLARTYPE
    );
    setBodyCorporate(
      defaultProfile.PropertyExpenseSpecific?.isBodyCorporatePercentage == "1"
        ? defaultProfile.PropertyExpenseSpecific?.BodyCorpatePercentage
        : defaultProfile.PropertyExpenseSpecific?.BodyCorporate
    );

    setCleaning(defaultProfile.PropertyExpenseStandard?.Cleaning);
    setLettingFee(defaultProfile.PropertyExpenseStandard?.LettingFee);
    setLandLease(defaultProfile.PropertyExpenseSpecific?.LandLease);
    setManagementFees(defaultProfile.PropertyExpenseStandard?.AgentComm);
    setGardening(defaultProfile.PropertyExpenseSpecific?.Gardening);
    setPropertyType(defaultProfile.UserDetailDefault?.isAll == "1" ? OPTION_VALUE.ALLPROPERTY : OPTION_VALUE.ONLYTHIS);
  };

  const setLoanTypes = (e) => {
    setLoanType(e.value);
  };

  const getDefaultProfile = () => {
    var defaultProfileSave: IDefaultProfileSave = {
      group: {
        LoanCost: [
          {
            EstPrice: estemationfee,
            ImprovementFee: improvement,
            OtherLoanCost: otherloancost,
            SolicitorFee: solicitorfee,
            ValuationFee: valuationfee,
          },
        ],
        LoanDeposit: [
          {
            CashDeposit: loaddeposit == OPTION_VALUE.DOLLARTYPE ? cashdeposit : 0,
            CashDepositPercentage: loaddeposit != OPTION_VALUE.DOLLARTYPE ? cashdeposit : 0,
            EnterPercentage: loaddeposit == OPTION_VALUE.PURCHASETYPE ? "1" : "0",
            EnterValue: loaddeposit == OPTION_VALUE.DOLLARTYPE ? "1" : "0",
            EquityDeposit: loaddeposit == OPTION_VALUE.DOLLARTYPE ? equitydeposit : 0,
            EquityDepositPercentage: loaddeposit != OPTION_VALUE.DOLLARTYPE ? equitydeposit : 0,
          },
        ],
        MortgageCategory: [
          {
            InterestOnly: loantype == OPTION_VALUE.INTRESTTYPE ? "1" : "0",
            InterestRate: interestValue,
            LengthOfLoan: loanLength,
            PrincipalNInterest: loantype == OPTION_VALUE.WITHINTRESTTYPE ? "1" : "0",
          },
        ],
        PropertyExpenseSpecific: [
          {
            BodyCorpatePercentage: bodycorporateexpenses == OPTION_VALUE.PURCHASETYPE ? bodycorporate : 0,
            BodyCorporate: bodycorporateexpenses == OPTION_VALUE.DOLLARTYPE ? parseInt(bodycorporate.toString()) : 0,
            Gardening: gardening,
            LandLease: landlease,
            isBodyCorporatePercentage: bodycorporateexpenses == OPTION_VALUE.PURCHASETYPE ? "1" : "0",
          },
        ],
        PropertyExpenseStandard: [
          {
            AgentComm: managementfees,
            Cleaning: cleaning,
            Insurance: insuranceexpenses == OPTION_VALUE.DOLLARTYPE ? parseInt(insurance.toString()) : 0,
            InsurancePercentage: insuranceexpenses != OPTION_VALUE.DOLLARTYPE ? insurance : 0,
            LettingFee: lettingfee,
            Others: 0,
            Rates: rateexpenses == OPTION_VALUE.DOLLARTYPE ? parseInt(rate.toString()) : 0,
            RatesPercentage: rateexpenses != OPTION_VALUE.DOLLARTYPE ? rate : 0,
            Repair: repairexpenses == OPTION_VALUE.DOLLARTYPE ? parseInt(repair.toString()) : 0,
            RepairPercentage: repairexpenses != OPTION_VALUE.DOLLARTYPE ? repair : 0,
            ServiceCont: 0,
            isInsurancePercentage: insuranceexpenses == OPTION_VALUE.PURCHASETYPE ? "1" : "0",
            isRatePercentage: rateexpenses == OPTION_VALUE.PURCHASETYPE ? "1" : "0",
            isRepairPercentage: repairexpenses == OPTION_VALUE.PURCHASETYPE ? "1" : "0",
          },
        ],
        TaxRateMarketFactors: [
          {
            CapitalGrowth: capitalgrowth,
            ConsumerPrice: cpi,
          },
        ],
        UserDetailDefault: [
          {
            Country: memberDetail.Country,
            HasDefault: "1",
            LVR: defaultProfile.UserDetailDefault.LVR,
            MemberEntityID: defaultProfile.UserDetailDefault?.MemberEntityID.toString(),
            ModuleSID: defaultProfile.UserDetailDefault?.ModuleSID.toString(),
            isAll: propertytype == OPTION_VALUE.ALLPROPERTY ? "1" : "0",
            isCurrent: defaultProfile.UserDetailDefault?.isCurrent,
            isGlobal: defaultProfile.UserDetailDefault?.isGlobal,
          },
        ],
      },
    };
    return defaultProfileSave;
  };

  const saveDefaultProfile = async () => {
    setLoading(true);
    const defaultProfileSave = getDefaultProfile();
    const response = await httpUpdate(`${ApiEndPoints.analyser}/UserSettings/`, defaultProfileSave);
    if (response?.error) {
      toastRef.current?.show({
        severity: "error",
        summary: "Error Message",
        detail: response.error,
        life: 3000,
      });
    } else if (response?.data) {
      toastRef.current?.show({
        severity: "success",
        summary: "Success Message",
        detail: "Default profile saved successfully.",
        life: 2000,
      });
    }
    setTimeout(() => {
      closeDialog();
      setLoading(false);
    }, 2000);
  };

  return (
    <>
      <Toast ref={toastRef} />
      <div className="flex flex-wrap gap-2">
        <div className="flex-auto">
          <p>
            Save time with your property analysis by setting your default profile. You can override this on any given
            property or change your default at any time.
          </p>
        </div>
      </div>
      <div className="relative rounded-lg pl-3 pr-1 py-3 border-solid border border-gray-300 bg-white mt-2">
        <div className="default-profile-header-container">
          <h3 className="text-[var(--primary-color)] m-2 text-sm">Loan type</h3>
        </div>
        <div className="mt-4">
          <div className="flex mb-3">
            <div className="flex w-1/4">
              <RadioButton
                inputId="withinterest"
                name="loantype"
                value="withinterest"
                onChange={(e) => setLoanTypes(e)}
                checked={loantype === "withinterest"}
              />
              <label htmlFor="suburbmining" className="ml-2">
                Principal and interest
              </label>
            </div>
            <div className="flex w-1/4">
              <RadioButton
                inputId={OPTION_VALUE.INTRESTTYPE}
                name="loantype"
                value={OPTION_VALUE.INTRESTTYPE}
                onChange={(e) => setLoanTypes(e)}
                checked={loantype === OPTION_VALUE.INTRESTTYPE}
              />
              <label htmlFor="suburbmining" className="ml-2">
                Interest only
              </label>
            </div>
          </div>
          <div className="flex">
            <div className="flex w-1/2">
              <span className="mt-2 mr-1 w-56">Length of loan(yrs)</span>
              <REIDropDown
                value={loanLength}
                onSelectionChange={(e) => {
                  setLoanLength(e.value);
                }}
                data={LENGTH_OPTION_1_100}
                label="name"
                className="w-full mr-1"
                optionValue="code"
                placeholder="0"
              />
            </div>
            <div className="flex w-1/2 p-1">
              <span className="w-[18.5rem] mt-2">Interest rate(%)</span>
              <Slider
                value={interestValue}
                onChange={(e) => setInterestValue(Array.isArray(e.value) ? e.value[0] : e.value)}
                className="w-full mt-4"
                max={100}
                step={0.1}
              />
              <REIInputNumber
                value={interestValue}
                onValueChange={(e) => setInterestValue(e.target.value)}
                className="ml-3 w-1/3"
                maxFractionDigits={2}
                min={0}
                max={100}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="relative rounded-lg pl-3 pr-1 py-3 border-solid border border-gray-300 bg-white mt-6">
        <div className="default-profile-header-container">
          <h3 className="text-[var(--primary-color)] m-2 text-sm">Loan deposit</h3>
        </div>
        <div className="mt-4">
          <div className="flex mb-3">
            <div className="flex w-1/4">
              <RadioButton
                inputId={OPTION_VALUE.DOLLARTYPE}
                name="loaddeposit"
                value={OPTION_VALUE.DOLLARTYPE}
                onChange={(e) => setLoanDeposit(e.value)}
                checked={loaddeposit === OPTION_VALUE.DOLLARTYPE}
              />
              <label htmlFor="suburbmining" className="ml-2">
                Enter as dollar($) value
              </label>
            </div>
            <div className="flex w-1/2">
              <RadioButton
                inputId={OPTION_VALUE.PURCHASETYPE}
                name="loaddeposit"
                value={OPTION_VALUE.PURCHASETYPE}
                onChange={(e) => setLoanDeposit(e.value)}
                checked={loaddeposit === OPTION_VALUE.PURCHASETYPE}
              />
              <label htmlFor="suburbmining" className="ml-2">
                Enter as percent(%) of property purchase price
              </label>
            </div>
          </div>
          <div className="flex">
            <div className="flex w-1/2 p-1">
              <span className="w-[18.5rem] mt-2">
                Cash deposit{loaddeposit === OPTION_VALUE.PURCHASETYPE ? "(%)" : "($)"}
              </span>
              <Slider
                value={cashdeposit}
                onChange={(e) => setCashDeposit(Array.isArray(e.value) ? e.value[0] : e.value)}
                className="w-full mt-4"
                max={loaddeposit === OPTION_VALUE.PURCHASETYPE ? 100 : 150000}
              />
              <REIInputNumber
                value={cashdeposit}
                onValueChange={(e) => setCashDeposit(e.target.value)}
                className="ml-3 w-1/3"
                useGrouping={true}
                maxFractionDigits={loaddeposit === OPTION_VALUE.PURCHASETYPE ? 2 : 0}
                min={0}
                max={loaddeposit === OPTION_VALUE.PURCHASETYPE ? 100 : 150000}
              />
            </div>
            <div className="flex w-1/2 p-1">
              <span className="w-[18.5rem] mt-2">
                Equity deposit{loaddeposit === OPTION_VALUE.PURCHASETYPE ? "(%)" : "($)"}
              </span>
              <Slider
                value={equitydeposit}
                onChange={(e) => setEquityDeposit(Array.isArray(e.value) ? e.value[0] : e.value)}
                className="w-full mt-4"
                max={loaddeposit === OPTION_VALUE.PURCHASETYPE ? 100 : 150000}
              />
              <REIInputNumber
                value={equitydeposit}
                onValueChange={(e) => setEquityDeposit(e.target.value)}
                className="ml-3 w-1/3"
                useGrouping={true}
                maxFractionDigits={loaddeposit === OPTION_VALUE.PURCHASETYPE ? 2 : 0}
                min={0}
                max={loaddeposit === OPTION_VALUE.PURCHASETYPE ? 100 : 150000}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="relative rounded-lg pl-3 pr-1 py-3 border-solid border border-gray-300 bg-white mt-6">
        <div className="default-profile-header-container">
          <h3 className="text-[var(--primary-color)] m-2 text-sm">Loan costs</h3>
        </div>
        <div className="mt-4">
          <div className="flex">
            <div className="flex w-1/2 p-1">
              <span className="w-[18.5rem] mt-2">Est. fees($)</span>
              <Slider
                value={estemationfee}
                onChange={(e) => setEstemationFee(Array.isArray(e.value) ? e.value[0] : e.value)}
                className="w-full mt-4"
                max={2500}
              />
              <REIInputNumber
                value={estemationfee}
                onValueChange={(e) => setEstemationFee(e.target.value)}
                className="ml-3 w-1/3"
                useGrouping={true}
                maxFractionDigits={0}
                min={0}
                max={2500}
              />
            </div>
            <div className="flex w-1/2 p-1">
              <span className="w-[18.5rem] mt-2">Valuation fees($)</span>
              <Slider
                value={valuationfee}
                onChange={(e) => setValuationFee(Array.isArray(e.value) ? e.value[0] : e.value)}
                className="w-full mt-4"
                max={2500}
              />
              <REIInputNumber
                value={valuationfee}
                onValueChange={(e) => setValuationFee(e.target.value)}
                className="ml-3 w-1/3"
                useGrouping={true}
                maxFractionDigits={0}
                min={0}
                max={2500}
              />
            </div>
          </div>
          <div className="flex">
            <div className="flex w-1/2 p-1">
              <span className="w-[18.5rem] mt-2">Solicitor fees($)</span>
              <Slider
                value={solicitorfee}
                onChange={(e) => setSolicitorFee(Array.isArray(e.value) ? e.value[0] : e.value)}
                className="w-full mt-4"
                max={2500}
              />
              <REIInputNumber
                value={solicitorfee}
                onValueChange={(e) => setSolicitorFee(e.target.value)}
                className="ml-3 w-1/3"
                useGrouping={true}
                maxFractionDigits={0}
                min={0}
                max={2500}
              />
            </div>
            <div className="flex w-1/2 p-1">
              <span className="w-[18.5rem] mt-2">Improvements($)</span>
              <Slider
                value={improvement}
                onChange={(e) => setImprovement(Array.isArray(e.value) ? e.value[0] : e.value)}
                className="w-full mt-4"
                max={20000}
              />
              <REIInputNumber
                value={improvement}
                onValueChange={(e) => setImprovement(e.target.value)}
                className="ml-3 w-1/3"
                useGrouping={true}
                maxFractionDigits={0}
                min={0}
                max={20000}
              />
            </div>
          </div>
          <div className="flex">
            <div className="flex w-1/2 p-1">
              <span className="w-[18.5rem] mt-2">Other loan costs($)</span>
              <Slider
                value={otherloancost}
                onChange={(e) => setOtherLoanCost(Array.isArray(e.value) ? e.value[0] : e.value)}
                className="w-full mt-4"
                max={2500}
              />
              <REIInputNumber
                value={otherloancost}
                onValueChange={(e) => setOtherLoanCost(e.target.value)}
                className="ml-3 w-1/3"
                useGrouping={true}
                maxFractionDigits={0}
                min={0}
                max={2500}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="relative rounded-lg pl-3 pr-1 py-3 border-solid border border-gray-300 bg-white mt-6">
        <div className="default-profile-header-container">
          <h3 className="text-[var(--primary-color)] m-2 text-sm">Market factors</h3>
        </div>
        <div className="flex mt-4">
          <div className="flex w-1/2 p-1">
            <span className="w-[18.5rem] mt-2">CPI(%)</span>
            <Slider
              value={cpi}
              onChange={(e) => setCPI(Array.isArray(e.value) ? e.value[0] : e.value)}
              className="w-full mt-4"
              max={10}
              step={0.1}
            />
            <REIInputNumber
              value={cpi}
              onValueChange={(e) => setCPI(e.target.value)}
              className="ml-3 w-1/3"
              useGrouping={true}
              maxFractionDigits={2}
              min={0}
              max={10}
            />
          </div>
          <div className="flex w-1/2 p-1">
            <span className="w-[22rem] mt-2">Capital growth(%)</span>
            <Slider
              value={capitalgrowth}
              onChange={(e) => setCapitalGrowth(Array.isArray(e.value) ? e.value[0] : e.value)}
              className="w-full mt-4"
              max={10}
              step={0.1}
            />
            <REIInputNumber
              value={capitalgrowth}
              onValueChange={(e) => setCapitalGrowth(e.target.value)}
              className="ml-3 w-1/3"
              useGrouping={true}
              maxFractionDigits={2}
              min={0}
              max={10}
            />
          </div>
        </div>
      </div>

      <div className="relative rounded-lg pl-3 pr-1 py-3 border-solid border border-gray-300 bg-white mt-6">
        <div className="default-profile-header-container">
          <h3 className="text-[var(--primary-color)] m-2 text-sm">Entities</h3>
        </div>
        <div className="flex mt-4">
          <p>
            To help correctly calculate your after tax cashflow, please select a default entity to use when analysing
            properties.
            <br />
            <br />
            Entity selected: <b>Personal Ownership</b>
            <REILinkButton
              children="View"
              rel="noopener noreferrer"
              href={"#"}
              className="ml-1 no-underline font-semibold"
              target="_blank"
            ></REILinkButton>
            <br />
            <br />
            <REILinkButton
              children="Add a new entity."
              rel="noopener noreferrer"
              href={"#"}
              className="ml-1 no-underline font-semibold"
              target="_blank"
            ></REILinkButton>
          </p>
        </div>
      </div>

      <div className="relative rounded-lg pl-3 pr-1 py-3 border-solid border border-gray-300 bg-white mt-6">
        <div className="default-profile-header-container">
          <h3 className="text-[var(--primary-color)] m-2 text-sm">Property expenses</h3>
        </div>
        <div className="flex mt-4">
          <p>
            You can enter values for rates, insurance, body corporate and repairs & maintenance as either a $ value, or
            as a % of purchase price.
          </p>
        </div>
        <div className="flex mb-3">
          <div className="flex w-1/2"></div>
          <div className="flex w-full">
            <RadioButton
              inputId={OPTION_VALUE.DOLLARTYPE}
              name="rateexpenses"
              value={OPTION_VALUE.DOLLARTYPE}
              onChange={(e) => setRateExpenses(e.value)}
              checked={rateexpenses === OPTION_VALUE.DOLLARTYPE}
            />
            <label htmlFor="suburbmining" className="mr-2 ml-2">
              Enter as dollar($) value
            </label>
            <RadioButton
              inputId={OPTION_VALUE.PURCHASETYPE}
              name="rateexpenses"
              value={OPTION_VALUE.PURCHASETYPE}
              onChange={(e) => setRateExpenses(e.value)}
              checked={rateexpenses === OPTION_VALUE.PURCHASETYPE}
            />
            <label htmlFor="suburbmining" className="ml-2">
              Enter as percent(%) of property purchase price
            </label>
          </div>
        </div>
        <div className="flex">
          <div className="flex w-1/2 p-1">
            <span className="w-[18.5rem] mt-2">Rates{rateexpenses === OPTION_VALUE.PURCHASETYPE ? "(%)" : "($)"}</span>
            <Slider
              value={rate}
              onChange={(e) => setRates(Array.isArray(e.value) ? e.value[0] : e.value)}
              className="w-full mt-4"
              step={rateexpenses === OPTION_VALUE.PURCHASETYPE ? 0.1 : 1}
              max={rateexpenses === OPTION_VALUE.PURCHASETYPE ? 1 : 5000}
            />
            <REIInputNumber
              value={rate}
              onValueChange={(e) => setRates(parseInt(e.target.value))}
              className="ml-3 w-1/3"
              useGrouping={true}
              maxFractionDigits={rateexpenses === OPTION_VALUE.PURCHASETYPE ? 2 : 0}
              min={0}
              max={rateexpenses === OPTION_VALUE.PURCHASETYPE ? 1 : 5000}
            />
          </div>
        </div>
        <div className="flex mb-3">
          <div className="flex w-1/2"></div>
          <div className="flex w-full">
            <RadioButton
              inputId={OPTION_VALUE.DOLLARTYPE}
              name="insuranceexpenses"
              value={OPTION_VALUE.DOLLARTYPE}
              onChange={(e) => {
                setInsuranceExpenses(e.value);
              }}
              checked={insuranceexpenses === OPTION_VALUE.DOLLARTYPE}
            />
            <label htmlFor="suburbmining" className="mr-2 ml-2">
              Enter as dollar($) value
            </label>
            <RadioButton
              inputId={OPTION_VALUE.PURCHASETYPE}
              name="insuranceexpenses"
              value={OPTION_VALUE.PURCHASETYPE}
              onChange={(e) => setInsuranceExpenses(e.value)}
              checked={insuranceexpenses === OPTION_VALUE.PURCHASETYPE}
            />
            <label htmlFor="suburbmining" className="ml-2">
              Enter as percent(%) of property purchase price
            </label>
          </div>
        </div>
        <div className="flex">
          <div className="flex w-1/2 p-1">
            <span className="w-[18.5rem] mt-2">
              Insurance{insuranceexpenses === OPTION_VALUE.PURCHASETYPE ? "(%)" : "($)"}
            </span>
            <Slider
              value={insurance}
              onChange={(e) => setInsurance(Array.isArray(e.value) ? e.value[0] : e.value)}
              className="w-full mt-4"
              step={insuranceexpenses === OPTION_VALUE.PURCHASETYPE ? 0.1 : 1}
              min={0}
              max={insuranceexpenses === OPTION_VALUE.PURCHASETYPE ? 1 : 5000}
            />

            <REIInputNumber
              value={insurance}
              onValueChange={(e) => setInsurance(e.target.value)}
              className="ml-3 w-1/3"
              useGrouping={true}
              maxFractionDigits={insuranceexpenses === OPTION_VALUE.PURCHASETYPE ? 2 : 0}
              min={0}
              max={insuranceexpenses === OPTION_VALUE.PURCHASETYPE ? 1 : 5000}
            />
          </div>
        </div>
        <div className="flex mb-3">
          <div className="flex w-1/2"></div>
          <div className="flex w-full">
            <RadioButton
              inputId={OPTION_VALUE.DOLLARTYPE}
              name="repairexpenses"
              value={OPTION_VALUE.DOLLARTYPE}
              onChange={(e) => setRepairExpenses(e.value)}
              checked={repairexpenses === OPTION_VALUE.DOLLARTYPE}
            />
            <label htmlFor="suburbmining" className="mr-2 ml-2">
              Enter as dollar($) value
            </label>
            <RadioButton
              inputId={OPTION_VALUE.PURCHASETYPE}
              name="repairexpenses"
              value={OPTION_VALUE.PURCHASETYPE}
              onChange={(e) => setRepairExpenses(e.value)}
              checked={repairexpenses === OPTION_VALUE.PURCHASETYPE}
            />
            <label htmlFor="suburbmining" className="ml-2">
              Enter as percent(%) of property purchase price
            </label>
          </div>
        </div>
        <div className="flex">
          <div className="flex w-1/2 p-1">
            <span className="w-[18.5rem] mt-2">
              Repairs / Maintenance{repairexpenses === OPTION_VALUE.PURCHASETYPE ? "(%)" : "($)"}
            </span>
            <Slider
              value={repair}
              onChange={(e) => setRepair(Array.isArray(e.value) ? e.value[0] : e.value)}
              className="w-full mt-4"
              step={repairexpenses === OPTION_VALUE.PURCHASETYPE ? 0.1 : 1}
              max={repairexpenses === OPTION_VALUE.PURCHASETYPE ? 1 : 5000}
            />
            <REIInputNumber
              value={repair}
              onValueChange={(e) => setRepair(parseInt(e.target.value))}
              className="ml-3 w-1/3"
              useGrouping={true}
              maxFractionDigits={repairexpenses === OPTION_VALUE.PURCHASETYPE ? 2 : 0}
              min={0}
              max={repairexpenses === OPTION_VALUE.PURCHASETYPE ? 1 : 5000}
            />
          </div>
        </div>

        <div className="flex mb-3">
          <div className="flex w-1/2"></div>
          <div className="flex w-full">
            <RadioButton
              inputId={OPTION_VALUE.DOLLARTYPE}
              name="bodycorporateexpenses"
              value={OPTION_VALUE.DOLLARTYPE}
              onChange={(e) => setBodyCorporateExpenses(e.value)}
              checked={bodycorporateexpenses === OPTION_VALUE.DOLLARTYPE}
            />
            <label htmlFor="suburbmining" className="mr-2 ml-2">
              Enter as dollar($) value
            </label>
            <RadioButton
              inputId={OPTION_VALUE.PURCHASETYPE}
              name="bodycorporateexpenses"
              value={OPTION_VALUE.PURCHASETYPE}
              onChange={(e) => setBodyCorporateExpenses(e.value)}
              checked={bodycorporateexpenses === OPTION_VALUE.PURCHASETYPE}
            />
            <label htmlFor="suburbmining" className="ml-2">
              Enter as percent(%) of property purchase price
            </label>
          </div>
        </div>
        <div className="flex">
          <div className="flex w-1/2 p-1">
            <span className="w-[18.5rem] mt-2">
              Body Corporate{bodycorporateexpenses === OPTION_VALUE.PURCHASETYPE ? "(%)" : "($)"}
            </span>
            <Slider
              value={bodycorporate}
              onChange={(e) => setBodyCorporate(Array.isArray(e.value) ? e.value[0] : e.value)}
              className="w-full mt-4"
              step={bodycorporateexpenses === OPTION_VALUE.PURCHASETYPE ? 0.1 : 1}
              max={bodycorporateexpenses === OPTION_VALUE.PURCHASETYPE ? 1 : 5000}
            />
            <REIInputNumber
              value={bodycorporate}
              onValueChange={(e) => setBodyCorporate(parseInt(e.target.value))}
              className="ml-3 w-1/3"
              useGrouping={true}
              maxFractionDigits={bodycorporateexpenses === OPTION_VALUE.PURCHASETYPE ? 2 : 0}
              min={0}
              max={bodycorporateexpenses === OPTION_VALUE.PURCHASETYPE ? 1 : 5000}
            />
          </div>
        </div>

        <div className="flex flex-wrap gap-2">
          <div className="flex-12">
            <p>
              The following expenses are entered as an annual $ amount, or for management fees as a fixed % per week of
              rent.
            </p>
          </div>
        </div>
        <div className="mt-4">
          <div className="flex">
            <div className="flex w-1/2 p-1">
              <span className="w-[18.5rem] mt-2">Cleaning($)</span>
              <Slider
                value={cleaning}
                onChange={(e) => setCleaning(Array.isArray(e.value) ? e.value[0] : e.value)}
                className="w-full mt-4"
                max={1000}
              />
              <REIInputNumber
                value={cleaning}
                onValueChange={(e) => setCleaning(e.target.value)}
                className="ml-3 w-1/3"
                maxFractionDigits={0}
                min={0}
                max={1000}
              />
            </div>
            <div className="flex w-1/2 p-1">
              <span className="w-[18.5rem] mt-2">Letting fees($)</span>
              <Slider
                value={lettingfee}
                onChange={(e) => setLettingFee(Array.isArray(e.value) ? e.value[0] : e.value)}
                className="w-full mt-4"
                max={3000}
              />
              <REIInputNumber
                value={lettingfee}
                onValueChange={(e) => setLettingFee(e.target.value)}
                className="ml-3 w-1/3"
                maxFractionDigits={0}
                min={0}
                max={3000}
              />
            </div>
          </div>
          <div className="flex">
            <div className="flex w-1/2 p-1">
              <span className="w-[18.5rem] mt-2">Land lease($)</span>
              <Slider
                value={landlease}
                onChange={(e) => setLandLease(Array.isArray(e.value) ? e.value[0] : e.value)}
                className="w-full mt-4"
                max={50000}
              />
              <REIInputNumber
                value={landlease}
                onValueChange={(e) => setLandLease(e.target.value)}
                className="ml-3 w-1/3"
                maxFractionDigits={0}
                min={0}
                max={50000}
              />
            </div>
            <div className="flex w-1/2 p-1">
              <span className="w-[18.5rem] mt-2">Management fees(%)</span>
              <Slider
                value={managementfees}
                onChange={(e) => setManagementFees(Array.isArray(e.value) ? e.value[0] : e.value)}
                className="w-full mt-4"
                step={0.1}
                max={10}
              />
              <REIInputNumber
                value={managementfees}
                onValueChange={(e) => setManagementFees(e.target.value)}
                className="ml-3 w-1/3"
                maxFractionDigits={2}
                min={0}
                max={10}
              />
            </div>
          </div>
          <div className="flex">
            <div className="flex w-1/2 p-1">
              <span className="w-[18.5rem] mt-2">Gardening ($)</span>
              <Slider
                value={gardening}
                onChange={(e) => setGardening(Array.isArray(e.value) ? e.value[0] : e.value)}
                className="w-full mt-4"
                max={3000}
              />
              <REIInputNumber
                value={gardening}
                onValueChange={(e) => setGardening(e.target.value)}
                className="ml-3 w-1/3"
                maxFractionDigits={2}
                min={0}
                max={3000}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-wrap gap-2">
        <div className="flex-12">
          <p>Do you want to apply these changes to all existing properties in your Analyser and Portfolio Manager?</p>
        </div>
      </div>

      <div className="flex gap-2">
        <div className="flex w-full">
          <RadioButton
            inputId={OPTION_VALUE.ONLYTHIS}
            name="propertytype"
            value={OPTION_VALUE.ONLYTHIS}
            onChange={(e) => setPropertyType(e.value)}
            checked={propertytype === OPTION_VALUE.ONLYTHIS}
          />
          <label htmlFor="suburbmining" className="ml-2">
            Only this property and any properties added from this point onwards.
          </label>
        </div>
        <div className="flex w-1/3">
          <RadioButton
            inputId={OPTION_VALUE.ALLPROPERTY}
            name="propertytype"
            value={OPTION_VALUE.ALLPROPERTY}
            onChange={(e) => setPropertyType(e.value)}
            checked={propertytype === OPTION_VALUE.ALLPROPERTY}
          />
          <label htmlFor="suburbmining" className="ml-2">
            All properties in my Analyser.
          </label>
        </div>
      </div>

      <div className="flex gap-2 mt-3">
        <div className="w-1/2 text-start">
          <REIButton
            label="Discard changes"
            disabled={false}
            loading={false}
            icon="pi pi-refresh"
            onClick={() => initDefaultProfile()}
          />
        </div>
        <div className="w-1/2 text-end">
          <REIButton
            label="Save default profile"
            disabled={false}
            icon="pi pi-save"
            loading={loading}
            classNames="mr-1"
            onClick={() => {
              saveDefaultProfile();
            }}
          />
        </div>
      </div>
    </>
  );
};

export default MyDefaultProfile;
