import { ReactNode } from "react";
import { classNames } from "primereact/utils";
import { BreadCrumb } from "primereact/breadcrumb";
import { useLocation } from "react-router-dom";

import "../../styles/layout.scss";
import AppToolBar from "./toolbar";
import Logo from "../../images/rei-logo.png";

interface LayoutProps {
  children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const location = useLocation();
  const showSideMenu = false;

  const containerClass = classNames("layout-wrapper", "layout-static", {
    "layout-static-inactive": !showSideMenu,
    "layout-mobile-active": showSideMenu,
  });

  const breadcrumbMap: Record<string, { label: string; parent?: string }> = {
    dashboard: { label: "Dashboard" },
    myaccount: { label: "My Account" },
    "rp-data-pricefinder": { label: "RP Data Pricefinder" },
    "property-analyser": { label: "Property Analyser" },
    "analyse-property": {
      label: "Analyse Property",
      parent: "property-analyser",
    },
    "property-analyser/analysis-report": {
      label: "Analyse Report",
      parent: "property-analyser",
    },
    "portfolio-tracker": { label: "Portfolio Tracker" },
    "": { label: "Investar Search" },
  };

  const currentPath = location.pathname.replace("/", "");
  const breadcrumbItems = [];

  if (breadcrumbMap[currentPath]) {
    const { label, parent } = breadcrumbMap[currentPath];
    if (parent) {
      breadcrumbItems.push({ label: breadcrumbMap[parent].label, url: `/${parent}` });
    }
    breadcrumbItems.push({ label });
  } else {
    breadcrumbItems.push({ label: "Investar Search" });
  }

  return (
    <div className={containerClass}>
      <AppToolBar />

      <div className="layout-main-container">
        <BreadCrumb model={breadcrumbItems} className="bg-transparent border-none" home={{ icon: "pi pi-home" }} />
        <div className="layout-main">{children}</div>
        <div className="layout-footer">
          <img src={Logo} alt="Logo" className="h-8 w-auto mr-2" />
          by
          <span className="font-medium ml-2">Real Estate Investar Australia Pty Ltd</span>
        </div>
      </div>
    </div>
  );
};

export default Layout;
