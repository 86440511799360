import React, { useRef } from "react";
import { FileUpload, FileUploadProgressBarTemplateOptions } from "primereact/fileupload";
import { Toast } from "primereact/toast";
import { Tooltip } from "primereact/tooltip";
import { REIProgressSpinner } from "../ui";
import REIDataTable from "../ui/dataTable";

interface AdvancedFileUploadProps {
  uploadHandler: (event: any) => void;
  onSelect: (event: any) => void;
  onError: () => void;
  onClear: () => void;
  onBeforeSelect: (event: any) => void;
  headerTemplate: (file: any, props: any) => React.ReactNode;
  itemTemplate: (file: any, props: any) => React.ReactNode;
  emptyTemplate: () => React.ReactNode;
  chooseOptions: any;
  uploadOptions: any;
  cancelOptions: any;
  totalSize: number;
  loading: boolean;
  fileData: any[];
  fileColumns: any[];
  progressBarTemplate?: React.ReactNode | ((options: FileUploadProgressBarTemplateOptions) => React.ReactNode);
}

const FileUploadWithCustomTemplate: React.FC<AdvancedFileUploadProps> = ({
  uploadHandler,
  onSelect,
  onError,
  onClear,
  headerTemplate,
  itemTemplate,
  emptyTemplate,
  onBeforeSelect,
  chooseOptions,
  uploadOptions,
  cancelOptions,
  totalSize,
  loading,
  fileData,
  fileColumns,
  progressBarTemplate,
}) => {
  const fileUploadRef = useRef(null);
  const toastRef = useRef<Toast>(null);

  return (
    <div className="card">
      <Toast ref={toastRef} />
      <Tooltip target=".custom-choose-btn" content="Choose" position="bottom" />
      <Tooltip target=".custom-upload-btn" content="Upload" position="bottom" />
      <Tooltip target=".custom-cancel-btn" content="Clear" position="bottom" />

      <FileUpload
        progressBarTemplate={progressBarTemplate}
        customUpload
        onBeforeSelect={onBeforeSelect}
        uploadHandler={uploadHandler}
        ref={fileUploadRef}
        name="documents"
        onSelect={onSelect}
        onError={onError}
        onClear={onClear}
        headerTemplate={headerTemplate}
        itemTemplate={totalSize != 0 ? itemTemplate : emptyTemplate}
        emptyTemplate={emptyTemplate}
        chooseOptions={chooseOptions}
        uploadOptions={uploadOptions}
        cancelOptions={cancelOptions}
      />

      {loading && (
        <div className="flex items-center">
          <REIProgressSpinner></REIProgressSpinner>
        </div>
      )}

      {!loading && (
        <REIDataTable
          customclass="m-1 border-solid border rounded border-gray-200 text-xs"
          columns={fileColumns}
          data={fileData}
        ></REIDataTable>
      )}
    </div>
  );
};

export default FileUploadWithCustomTemplate;
