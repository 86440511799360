import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { REIButton, REIProgressSpinner } from "../../components/ui";
import { setFilterValue, setCurrentSavedSearch } from "../../store/slice/investarSearchSlice";
import { ISaveSearch } from "../../interface/ISaveSearch";
import SearchList from "./Child/list";
import SearchDetail from "./Child/search";
import { getWatchListProperties } from "../../store/slice/watchlistSlice";

const InvestarSearchHome: React.FC = () => {
  const [searchOnlyScreen, setSearchOnlyScreen] = useState(true);
  const [searchData, setSearchData] = useState({ Root: [] });
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const renderCount = useRef<number>(1);
  const [showScrollTopButton, setShowScrollTopButton] = useState(false);

  useEffect(() => {
    dispatch(getWatchListProperties());
  }, []);

  const setSearchLoading = () => {
    setLoading(true);
  };

  const handleSearchClick = (data, filters) => {
    dispatch(setCurrentSavedSearch(0));
    dispatch(
      setFilterValue({
        filterValue: filters as ISaveSearch,
        error: null,
        searchId: 0,
      })
    );
    renderCount.current++;
    if (renderCount.current === 2) {
      setIsSidebarOpen(true);
    }
    setSearchData(data);
    setSearchOnlyScreen(false);
    setLoading(false);
  };

  const resetSearchOnlyScreen = () => {
    setSearchOnlyScreen(true);
  };

  const handleFilterbarClick = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <>
      {loading && (
        <div className="transperent-loader">
          <REIProgressSpinner />
        </div>
      )}

      <div className="flex lg:flex-nowrap flex-wrap gap-1">
        <div
          className={`border-solid border rounded border-gray-200 p-2
            ${searchOnlyScreen
              ? "w-full overflow-auto top-[74px]"
              : "lg:w-2/6 w-full flex flex-row lg:sticky h-[855px] overflow-auto top-[74px]"
            }`}
        >
          <div className={!searchOnlyScreen ? "w-full" : "w-full"}>
            <SearchDetail
              handleSearchClick={handleSearchClick}
              resetSearchOnlyScreen={resetSearchOnlyScreen}
              searchOnlyScreen={searchOnlyScreen}
              setSearchLoading={setSearchLoading}
            />
          </div>
        </div>

        {!searchOnlyScreen && (
          <div className="w-full overflow-y-auto">
            <SearchList data={searchData} />
          </div>
        )}
      </div>

      {/* {showScrollTopButton && (
        <REIButton
          label=""
          disabled={false}
          icon="pi pi-arrow-up"
          loading={false}
          classNames={"fixed bottom-5 right-5 bg-blue-500 text-white p-3 rounded-full shadow-lg"}
          onClick={scrollToTop}
        />
      )} */}
    </>
  );
};

export default InvestarSearchHome;
