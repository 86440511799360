import { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { useHttpGets } from "../../services/httpService";
import { ColumnType, MEMBER_KEY } from "../../utils/constants";
import { ApiEndPoints } from "../../utils/endpoints";
import { REIDataTable, REIProgressSpinner, REIToastContainer } from "../../components/ui";
import { Tag } from "primereact/tag";
import { downloadPdf, formatCurrencyWithDecimal, formatDate } from "../../utils/commonUtil";
import { Toast } from "primereact/toast";

const useFetchInvoices = (memberId: string, toastRef: React.RefObject<Toast>) => {
  const [invoices, setInvoices] = useState<IInvoiceData>({} as IInvoiceData);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!memberId) return;

    const endpoint = `${ApiEndPoints.myaccount}/invoice?memberId=${memberId}`;

    useHttpGets(endpoint, undefined)
      .then((result) => {
        const { data } = result;
        if (data) {
          const sortedRecords = data.records.sort(
            (a: IInvoiceRecord, b: IInvoiceRecord) =>
              new Date(b.invoiceDate).getTime() - new Date(a.invoiceDate).getTime()
          );
          setInvoices({ ...data, records: sortedRecords });
        }
        setLoading(false);
      })
      .catch((error) => {
        toastRef.current?.show({
          severity: "error",
          summary: "Error",
          detail: error,
          life: 3000,
        });
        setLoading(false);
      });
  }, [memberId, toastRef]);

  return { invoices, loading };
};

// Invoice component
const Invoice: React.FC = () => {
  const memberId = localStorage.getItem(MEMBER_KEY) ?? "";
  const toastRef = useRef<Toast>(null);

  const { invoices, loading } = useFetchInvoices(memberId, toastRef);

  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [downloadingInvoice, setDownloadingInvoice] = useState<string | null>(null);

  const handleDownload = useCallback(
    (event: any, rowData: IInvoiceRecord) => {
      setIsDownloading(true);
      setDownloadingInvoice(rowData.invoiceNumber);
      const endpoint = `${ApiEndPoints.myaccount}/invoices/pdf?memberId=${memberId}&invoiceNumber=${rowData.invoiceNumber}`;

      useHttpGets(endpoint, undefined)
        .then((result: { data: { records: { body: string }[] } | null }) => {
          const { data } = result;
          if (data) {
            downloadPdf(rowData.invoiceNumber, data.records[0].body);
          }
          setIsDownloading(false);
          setDownloadingInvoice(null);
        })
        .catch((error) => {
          toastRef.current?.show({
            severity: "error",
            summary: "Error",
            detail: error,
            life: 3000,
          });
          setIsDownloading(false);
          setDownloadingInvoice(null);
        });
    },
    [memberId]
  );

  const columns = useMemo(
    () => [
      { field: "invoiceNumber", header: "Invoice Number" },
      {
        field: "invoiceDate",
        header: "Invoice Date",
        bodyTemplate: (rowData: IInvoiceRecord) => formatDate(rowData.dueDate),
      },
      { field: "dueDate", header: "Due Date", bodyTemplate: (rowData: IInvoiceRecord) => formatDate(rowData.dueDate) },
      {
        field: "tax",
        header: "Tax",
        bodyTemplate: (rowData: IInvoiceRecord) => formatCurrencyWithDecimal(rowData.taxAmount),
      },
      {
        field: "amount",
        header: "Amount",
        bodyTemplate: (rowData: IInvoiceRecord) => formatCurrencyWithDecimal(rowData.amount),
      },
      {
        field: "balance",
        header: "Balance",
        bodyTemplate: (rowData: IInvoiceRecord) => formatCurrencyWithDecimal(rowData.balance),
      },
      {
        field: "status",
        header: "Payment Status",
        width: "150px",
        bodyTemplate: (rowData: IInvoiceRecord) => (
          <div className="text-center">
            {rowData.balance === 0 ? (
              <Tag value="Fully Paid" severity="success" />
            ) : (
              <Tag value="Unpaid" severity="danger" />
            )}
          </div>
        ),
      },
      {
        field: "",
        type: ColumnType.custom,
        bodyTemplate: (rowData: IInvoiceRecord) => (
          <div className="text-center">
            {downloadingInvoice === rowData.invoiceNumber ? (
              <REIProgressSpinner />
            ) : (
              <i
                className="pi pi-download text-[#8a9ea5] cursor-pointer"
                onClick={(event) => handleDownload(event, rowData)}
              ></i>
            )}
          </div>
        ),
      },
    ],
    [handleDownload, isDownloading]
  );

  if (loading) {
    return (
      <div className="text-center">
        <REIProgressSpinner />
      </div>
    );
  }

  if (!invoices.records?.length) {
    return <div className="text-center">No invoice found.</div>;
  }

  return (
    <>
      <REIToastContainer toastReference={toastRef} />
      <REIDataTable
        data={invoices.records}
        pagination={true}
        pageSize={10}
        columns={columns}
        actions={undefined}
        customclass="max-h-[500px]"
      />
    </>
  );
};

export default Invoice;
