import React from "react";
import { IAnalysisSummaryResponse } from "../../../../interface/analysisSummary";
import { REIProgressSpinner } from "../../../../components/ui";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { formatCurrency } from "../../../../utils/commonUtil";

interface SummaryRowProps {
  label: string;
  value: string | number | undefined;
  bgColor: string;
  border?: string;
}

const SummaryRow: React.FC<SummaryRowProps> = ({
  label,
  value,
  bgColor,
  border = "border-solid border border-l-0 border-b-0 border-gray-300",
}) => (
  <div className={`flex-0 md:w-1/3 w-full flex items-center pl-2 ${bgColor} ${border}`}>
    <div className="w-[80%]">
      <span className="font-semibold">{label}</span>
    </div>
    <div className="w-[20%] text-end mr-2">{value}</div>
  </div>
);

const SummaryInput: React.FC = () => {
  const analysisSummary = useSelector((state: RootState) => state.analyser.analysisSummary as IAnalysisSummaryResponse);

  if (!analysisSummary) {
    return (
      <div className="w-full text-center">
        <REIProgressSpinner />
      </div>
    );
  }

  const { TenYrInternalRate, Deposit, TenYrTotalGrowth, PurchasePrice, PurchaseCost, LoanCoast } =
    analysisSummary?.SummaryInput || {};

  return (
    <>
      <div className="flex md:flex-row flex-col w-full min-h-9">
        <SummaryRow label="10yr internal rate of return" value={`${TenYrInternalRate}%`} bgColor="bg-slate-100" />
        <SummaryRow label="Deposit" value={formatCurrency(Deposit)} bgColor="bg-slate-50" />
        <SummaryRow
          label="10yr total growth"
          value={`${TenYrTotalGrowth}%`}
          bgColor="bg-slate-100"
          border="border-solid border border-x-0 border-b-0 border-gray-300"
        />
      </div>
      <div className="flex md:flex-row flex-col w-full min-h-9">
        <SummaryRow label="Property purchase price" value={formatCurrency(PurchasePrice)} bgColor="bg-slate-50" />
        <SummaryRow label="Purchase costs" value={formatCurrency(PurchaseCost)} bgColor="bg-slate-100" />
        <SummaryRow
          label="Loan cost"
          value={formatCurrency(LoanCoast)}
          bgColor="bg-slate-50"
          border="border-solid border border-x-0 border-b-0 border-gray-300"
        />
      </div>
    </>
  );
};

export default SummaryInput;
