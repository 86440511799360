import { ProgressBar } from "primereact/progressbar";
import * as React from "react";

interface IProgressbarProps {
  value: number;
  showValue: boolean;
  className: string;
  mode: "determinate" | "indeterminate" | undefined;
}

const REIProgressbar: React.FC<IProgressbarProps> = ({ value, showValue, className, mode }) => {
  return <ProgressBar mode={mode} className={className} value={value} showValue={showValue}></ProgressBar>;
};

export default REIProgressbar;
