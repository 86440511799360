import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
  ICashFlow,
  ICashflowRentEntry,
  IPropertyDetail,
  IRent,
  IRentEntry,
  ITotalForDetails,
} from "../../../../interface/cashFlow";
import { formatCurrency } from "../../../../utils/commonUtil";
import { REIButton } from "../../../../components/ui";
import { Row } from "primereact/row";
import { ColumnGroup } from "primereact/columngroup";
import RentEditForm from "./EditRent";

interface RentProps {
  cashFlowData: ICashFlow;
  year: string;
  isForecastOnly: boolean;
  isEditable: boolean;
}

const Rent: React.FC<RentProps> = ({ cashFlowData, year, isForecastOnly, isEditable }) => {
  const [transformedData, setTransformedData] = useState<IRent>();
  const [collapsedRows, setCollapsedRows] = useState<Record<string, boolean>>({});
  const [transformedFooter, setTransformedFooter] = useState<IRent>();

  useEffect(() => {
    if (cashFlowData) {
      const transformed = transformRentData(cashFlowData.PropertyDetails, year);
      setTransformedData(transformed);
      const footer = TransformedFooter(cashFlowData.TotalForDetails, year);
      setTransformedFooter(footer);
    }
  }, [cashFlowData, year]);

  const transformRentData = (data: IPropertyDetail[], year: string) => {
    return data.flatMap((property) => {
      const actual: ICashflowRentEntry =
        property.Details.CashflowRentTotal?.Actuals?.find((item) => item.Year === year) || {};
      const forecast: ICashflowRentEntry =
        property.Details.CashflowRentTotal?.Forecast?.find((item) => item.Year === year) || {};
      const variance: ICashflowRentEntry =
        property.Details.CashflowRentTotal?.Variance?.find((item) => item.Year === year) || {};

      return [
        { type: "Actuals", data: actual },
        { type: "Forecast", data: forecast },
        { type: "Variance", data: variance },
      ].map(({ type, data }) => {
        const addressParts = [
          property.PropertyDetail.StreetAddress,
          property.PropertyDetail.Suburb,
          property.PropertyDetail.State,
          property.PropertyDetail.Postcode,
          property.PropertyDetail.Country,
        ]
          .filter((part) => part && part.trim() !== "")
          .join(", ");

        return {
          property: addressParts,
          type,
          annualRent: Number(data.RentPA) || 0,
          weeklyRent: Number(data.RentPW) || 0,
          leaseStart: data.LeaseStart || "",
          leaseEnd: data.LeaseEnd || "",
          nextRentReview: data.NextReview || "",
          tenants: data.Tenants || "",
        };
      });
    });
  };

  const TransformedFooter = (data: ITotalForDetails[], year: string): IRent => {
    const footerProperties =
      data.find((item: any) => item.EntityId === "")?.CashflowDetailsTotals.Rent || ({} as IRent);
    const actualFooter = footerProperties?.Actuals || ({} as IRentEntry[]);
    const forecastFooter = footerProperties?.Forecast || ({} as IRentEntry[]);
    const varianceFooter = footerProperties?.Variance || ({} as IRentEntry[]);

    return {
      Actuals: actualFooter,
      Forecast: forecastFooter,
      Variance: varianceFooter,
    };
  };

  const currencyTemplate = (value: string) => (
    <span className={Number(value) < 0 ? "text-red-600" : ""}>
      {Number(value) < 0 ? `(${formatCurrency(-Number(value))})` : formatCurrency(Number(value))}
    </span>
  );

  const toggleRowCollapse = (street: string) => {
    setCollapsedRows((prev) => ({ ...prev, [street]: !prev[street] }));
  };

  const renderFooter = (category: any, data) =>
    transformedFooter && (
      <Row>
        <Column footer={category == "Forecast" ? "Totals/averages" : ""} />
        <Column field="type" footer={category} />
        <Column footer={currencyTemplate(data?.RentPA || 0)} footerStyle={{ textAlign: "right" }} />
        <Column footer={currencyTemplate(data?.RentPW || 0)} footerStyle={{ textAlign: "right" }} />
      </Row>
    );

  const footerGroup = transformedFooter && (
    <ColumnGroup>
      {renderFooter("Forecast", transformedFooter.Forecast.find((x) => x.Year === year)!)}
      {renderFooter("Actuals", transformedFooter.Actuals.find((x) => x.Year === year)!)}
      {renderFooter("Variance", transformedFooter.Variance.find((x) => x.Year === year)!)}
    </ColumnGroup>
  );

  const onCancel = () => {
    isEditable = false;
  };

  return (
    cashFlowData && (
      <div className="rent-container">
        {isEditable ? (
          <RentEditForm cashFlowData={cashFlowData} onCancel={onCancel} />
        ) : (
          <DataTable
            value={transformedData}
            rowGroupMode="rowspan"
            groupRowsBy="property"
            footerColumnGroup={footerGroup}
            size="small"
          >
            <Column
              field="property"
              header="Address/property name"
              body={(rowData) => (
                <div className="flex items-center">
                  {isForecastOnly && (
                    <REIButton
                      icon={collapsedRows[rowData.property] ? "pi pi-angle-down" : "pi pi-angle-right"}
                      text={true}
                      onClick={() => toggleRowCollapse(rowData.property)}
                    />
                  )}
                  <span className="cursor-pointer text-blue-700">{`${rowData.property}`}</span>
                </div>
              )}
            />
            <Column
              field="type"
              header="Type"
              body={(rowData) =>
                (!collapsedRows[rowData.property] || rowData.type === "Forecast") &&
                (isForecastOnly || rowData.type === "Forecast")
                  ? rowData.type
                  : null
              }
            />
            <Column
              field="annualRent"
              header="annualRent"
              body={(rowData) =>
                (!collapsedRows[rowData.property] || rowData.type === "Forecast") &&
                (isForecastOnly || rowData.type === "Forecast")
                  ? currencyTemplate(rowData.annualRent)
                  : null
              }
            />
            <Column
              field="weeklyRent"
              header="Weekly Rent"
              body={(rowData) =>
                (!collapsedRows[rowData.property] || rowData.type === "Forecast") &&
                (isForecastOnly || rowData.type === "Forecast")
                  ? currencyTemplate(rowData.weeklyRent)
                  : null
              }
            />
            <Column
              field="leaseStart"
              header="Lease Start"
              body={(rowData) =>
                (!collapsedRows[rowData.property] || rowData.type === "Forecast") &&
                (isForecastOnly || rowData.type === "Forecast")
                  ? rowData.leaseStart
                  : null
              }
            />
            <Column
              field="leaseEnd"
              header="Lease End"
              body={(rowData) =>
                (!collapsedRows[rowData.property] || rowData.type === "Forecast") &&
                (isForecastOnly || rowData.type === "Forecast")
                  ? rowData.leaseEnd
                  : null
              }
            />
            <Column
              field="nextRentReview"
              header="Next Rent Review"
              body={(rowData) =>
                (!collapsedRows[rowData.property] || rowData.type === "Forecast") &&
                (isForecastOnly || rowData.type === "Forecast")
                  ? rowData.nextRentReview
                  : null
              }
            />
            <Column
              field="tenants"
              header="Tenants"
              body={(rowData) =>
                (!collapsedRows[rowData.property] || rowData.type === "Forecast") &&
                (isForecastOnly || rowData.type === "Forecast")
                  ? rowData.tenants
                  : null
              }
            />
          </DataTable>
        )}
      </div>
    )
  );
};

export default Rent;
