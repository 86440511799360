import { useEffect, useState, useCallback } from "react";
import { IAnalysedProperty, IPropertyAnnualExpense } from "../../../../../interface/addAnalyser";
import { useAnalysedPropertyContext } from "../AnalysedPropertyContext";
import { REIInputNumber } from "../../../../../components/ui";
import InputSlider from "../../../../../components/ui/slider";
import { formatCurrencyWithDecimal } from "../../../../../utils/commonUtil";

interface IAnnualPropertyReturn {
  setIsValid: (isValid: boolean) => void;
  updateProperty: (updated: Partial<IAnalysedProperty>) => void;
  rentalReturnTotal: number;
}

const AnnualPropertyExpense: React.FC<IAnnualPropertyReturn> = ({ setIsValid, updateProperty, rentalReturnTotal }) => {
  const { analysedProperty } = useAnalysedPropertyContext();
  const [annualPropertyExpense, setAnnualPropertyExpense] = useState<IPropertyAnnualExpense | undefined>(undefined);
  const [propertyExpenseTotal, setPropertyExpenseTotal] = useState<number>();
  const [reset, setReset] = useState<boolean>(false);

  useEffect(() => {
    if (analysedProperty && !reset) {
      setAnnualPropertyExpense(analysedProperty.RentExpense.PropertyAnnualExpense);
      setReset(true);
    }
  }, [analysedProperty]);

  useEffect(() => {
    calculateTotalExpense(analysedProperty?.RentExpense.PropertyAnnualExpense);
  }, [rentalReturnTotal]);

  useEffect(() => {
    if (annualPropertyExpense) {
      setIsValid(true);
      updateProperty({
        ...analysedProperty,
        RentExpense: {
          ...analysedProperty?.RentExpense,
          PropertyAnnualRentalReturns: analysedProperty?.RentExpense.PropertyAnnualRentalReturns,
          PropertyAnnualExpense: annualPropertyExpense,
        },
      });
    }
  }, [annualPropertyExpense]);

  const calculateTotalExpense = useCallback(
    (expense: IPropertyAnnualExpense | undefined) => {
      if (expense) {
        const totalExpense = Object.values(expense).reduce(
          (acc, curr) => acc + (typeof curr === "number" ? curr : 0),
          0
        );
        const totalRentExpense = totalExpense + rentalReturnTotal;
        setPropertyExpenseTotal(
          expense.AgentComm > 0
            ? parseFloat(((totalRentExpense / 100) * expense.AgentComm).toString())
            : totalRentExpense
        );
      }
    },
    [rentalReturnTotal]
  );

  const setValues = useCallback(
    (name: string, value: string | number) => {
      setAnnualPropertyExpense((prevState) => {
        if (!prevState) return undefined;
        const updatedExpense = {
          ...prevState,
          [name]: value !== undefined ? value : 0,
        };
        calculateTotalExpense(updatedExpense);
        return updatedExpense;
      });
    },
    [calculateTotalExpense]
  );

  return (
    <div>
      <div className="relative rounded-lg pl-3 pr-1 py-3 border-solid border border-gray-300 bg-white mt-2">
        <div className="default-profile-header-container">
          <h3 className="text-[var(--primary-color)] mt-0 m-1 text-lg">Annual Expense</h3>
        </div>
        <div className="p-4">
          <div className="flex flex-wrap mb-4">
            <div className="w-full md:w-1/2 p-2">
              <div className="flex items-center justify-evenly">
                <label className="w-1/3 text-left" htmlFor="Rates">
                  Rates ($)
                </label>
                <InputSlider
                  value={annualPropertyExpense?.Rates}
                  onChange={(e) => setValues("Rates", Array.isArray(e.value) ? e.value[0] : e.value)}
                  classNames="w-1/2 mx-2"
                  min={0}
                  max={5000}
                />
                <REIInputNumber
                  value={annualPropertyExpense?.Rates}
                  onValueChange={(e) => setValues("Rates", e.target.value)}
                  useGrouping={true}
                />
              </div>
            </div>
            <div className="w-full md:w-1/2 p-2" title="Add estimated annual cost.">
              <div className="flex items-center justify-evenly">
                <label className="w-1/3 text-left" htmlFor="BodyCorp">
                  Body corporate ($)
                </label>
                <InputSlider
                  value={annualPropertyExpense?.BodyCorp}
                  onChange={(e) => setValues("BodyCorp", Array.isArray(e.value) ? e.value[0] : e.value)}
                  classNames="w-1/2 mx-2"
                  min={0}
                  max={10000}
                />
                <REIInputNumber
                  value={annualPropertyExpense?.BodyCorp}
                  onValueChange={(e) => setValues("BodyCorp", e.target.value)}
                  useGrouping={true}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-wrap mb-4">
            <div className="w-full md:w-1/2 p-2 justify-evenly">
              <div className="flex items-center">
                <label className="w-1/3 text-left" htmlFor="LandLease">
                  Land lease ($)
                </label>
                <InputSlider
                  value={annualPropertyExpense?.LandLease}
                  onChange={(e) => setValues("LandLease", Array.isArray(e.value) ? e.value[0] : e.value)}
                  classNames="w-1/2 mx-2"
                  min={0}
                  max={50000}
                />
                <REIInputNumber
                  value={annualPropertyExpense?.LandLease}
                  onValueChange={(e) => setValues("LandLease", e.target.value)}
                  useGrouping={true}
                />
              </div>
            </div>
            <div className="w-full md:w-1/2 p-2">
              <div className="flex items-center justify-evenly">
                <label className="w-1/3 text-left" htmlFor="Insurance">
                  Insurance ($)
                </label>
                <InputSlider
                  value={annualPropertyExpense?.Insurance}
                  onChange={(e) => setValues("Insurance", Array.isArray(e.value) ? e.value[0] : e.value)}
                  classNames="w-1/2 mx-2"
                  min={0}
                  max={2690}
                />
                <REIInputNumber
                  value={annualPropertyExpense?.Insurance}
                  onValueChange={(e) => setValues("Insurance", e.target.value)}
                  useGrouping={true}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-wrap mb-4">
            <div className="w-full md:w-1/2 p-2 justify-evenly">
              <div className="flex items-center">
                <label className="w-1/3 text-left" htmlFor="LettingFee">
                  Letting fees ($)
                </label>
                <InputSlider
                  value={annualPropertyExpense?.LettingFee}
                  onChange={(e) => setValues("LettingFee", Array.isArray(e.value) ? e.value[0] : e.value)}
                  classNames="w-1/2 mx-2"
                  min={0}
                  max={3000}
                />
                <REIInputNumber
                  value={annualPropertyExpense?.LettingFee}
                  onValueChange={(e) => setValues("LettingFee", e.target.value)}
                  useGrouping={true}
                />
              </div>
            </div>
            <div className="w-full md:w-1/2 p-2">
              <div className="flex items-center justify-evenly">
                <label className="w-1/3 text-left" htmlFor="AgentComm">
                  Management fees (%)
                </label>
                <InputSlider
                  value={annualPropertyExpense?.AgentComm}
                  onChange={(e) => setValues("AgentComm", Array.isArray(e.value) ? e.value[0] : e.value)}
                  classNames="w-1/2 mx-2"
                  min={0.0}
                  max={10.0}
                />
                <REIInputNumber
                  value={annualPropertyExpense?.AgentComm}
                  onValueChange={(e) => setValues("AgentComm", e.target.value)}
                  useGrouping={true}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-wrap mb-4">
            <div className="w-full md:w-1/2 p-2 justify-evenly">
              <div className="flex items-center">
                <label className="w-1/3 text-left" htmlFor="Maintenance">
                  Repairs/maintenance ($)
                </label>
                <InputSlider
                  value={annualPropertyExpense?.Maintenance}
                  onChange={(e) => setValues("Maintenance", Array.isArray(e.value) ? e.value[0] : e.value)}
                  classNames="w-1/2 mx-2"
                  min={0}
                  max={2900}
                />
                <REIInputNumber
                  value={annualPropertyExpense?.Maintenance}
                  onValueChange={(e) => setValues("Maintenance", e.target.value)}
                  useGrouping={true}
                />
              </div>
            </div>
            <div className="w-full md:w-1/2 p-2">
              <div className="flex items-center justify-evenly">
                <label className="w-1/3 text-left" htmlFor="Gardening">
                  Gardening ($)
                </label>
                <InputSlider
                  value={annualPropertyExpense?.Gardening}
                  onChange={(e) => setValues("Gardening", Array.isArray(e.value) ? e.value[0] : e.value)}
                  classNames="w-1/2 mx-2"
                  min={0}
                  max={3000}
                />
                <REIInputNumber
                  value={annualPropertyExpense?.Gardening}
                  onValueChange={(e) => setValues("Gardening", e.target.value)}
                  useGrouping={true}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-wrap mb-4">
            <div className="w-full md:w-1/2 p-2 justify-evenly">
              <div className="flex items-center">
                <label className="w-1/3 text-left" htmlFor="Cleaning">
                  Cleaning ($)
                </label>
                <InputSlider
                  value={annualPropertyExpense?.Cleaning}
                  onChange={(e) => setValues("Cleaning", Array.isArray(e.value) ? e.value[0] : e.value)}
                  classNames="w-1/2 mx-2"
                  min={0}
                  max={1000}
                />
                <REIInputNumber
                  value={annualPropertyExpense?.Cleaning}
                  onValueChange={(e) => setValues("Cleaning", e.target.value)}
                  useGrouping={true}
                />
              </div>
            </div>
            <div className="w-full md:w-1/2 p-2">
              <div className="flex items-center justify-evenly">
                <label className="w-1/3 text-left" htmlFor="ServiceCont">
                  Service contracts ($)
                </label>
                <InputSlider
                  value={annualPropertyExpense?.ServiceCont}
                  onChange={(e) => setValues("ServiceCont", Array.isArray(e.value) ? e.value[0] : e.value)}
                  classNames="w-1/2 mx-2"
                  min={0}
                  max={1000}
                />
                <REIInputNumber
                  value={annualPropertyExpense?.ServiceCont}
                  onValueChange={(e) => setValues("ServiceCont", e.target.value)}
                  useGrouping={true}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-wrap mb-4">
            <div className="w-full md:w-1/2 p-2 justify-evenly">
              <div className="flex items-center">
                <label className="w-1/3 text-left" htmlFor="Maintenance">
                  Other ($)
                </label>
                <InputSlider
                  value={annualPropertyExpense?.Other}
                  onChange={(e) => setValues("Other", Array.isArray(e.value) ? e.value[0] : e.value)}
                  classNames="w-1/2 mx-2"
                  min={0}
                  max={100000}
                />
                <REIInputNumber
                  value={annualPropertyExpense?.Other}
                  onValueChange={(e) => setValues("Other", e.target.value)}
                  useGrouping={true}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-wrap mb-4">
            <div className="w-full md:w-1/2 p-2">
              <p>
                <strong>
                  Total annual property expenses =
                  <span className="total_annual_property_expenses">{`${formatCurrencyWithDecimal(
                    propertyExpenseTotal ?? 0
                  )}`}</span>
                </strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnnualPropertyExpense;
