import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";

import { classNames } from "primereact/utils";
import { REIDropDown, REICheckBox } from "../../../../components/ui";
import { BedIcon, BathIcon, CarIcon } from "../../../../public/assets/images";

interface PropertyTypeComponentProps {
  selectedDropdownValue: any;
  handleDropdownChange: (e: any, dropdownName: string) => void;
  optionsdata: any;
}

const PropertyTypeComponent: React.FC<PropertyTypeComponentProps> = ({
  selectedDropdownValue,
  handleDropdownChange,
  optionsdata,
}) => {
  const [showPropertyOptions, setShowPropertyOptions] = useState(false);
  const [includeLand, setIncludeLand] = useState(false);
  const filterData = useSelector((state: RootState) => state.investar.filterValue);

  useEffect(() => {
    setIncludeLand(selectedDropdownValue?.includeLand);
  }, [selectedDropdownValue]);

  const togglePropertyOptions = () => {
    setShowPropertyOptions(!showPropertyOptions);
  };

  const onIncludeLandChange = () => {
    setIncludeLand((prevIncludeLand) => !prevIncludeLand);
    selectedDropdownValue.includeLand = !includeLand;
  };

  const renderDropdown = (filterName: string, data: any, optionValue: string, label?: string, width?: string) => (
    <REIDropDown
      value={selectedDropdownValue?.[filterName] || data[0]?.[optionValue]}
      onSelectionChange={(e) => handleDropdownChange(e, filterName)}
      data={data}
      label={label || "Label"}
      optionValue={optionValue}
      className={classNames(!width ? `w-[50%]` : width)}
    />
  );

  return (
    <>
      <div className="flex flex-row w-full gap-1">
        <div className="flex-0 w-full">
          <label className="block text-sm font-medium">Property type</label>
          {renderDropdown(
            "dwellingTypes",
            optionsdata?.PropertySpecifics?.DwellingTypes || [],
            "FilterDwellingTypeID",
            undefined,
            "w-full"
          )}
        </div>
        <div className="flex-0 w-full pt-[24px]">
          <REICheckBox onChange={onIncludeLandChange} checked={includeLand} className="mr-1" label="Include land" />
        </div>
      </div>

      <div className={!filterData ? "flex flex-col md:flex-row md:w-full gap-1" : "flex flex-col w-full gap-1"}>
        <div className={!filterData ? "flex flex-row md:w-1/3 gap-1" : "flex flex-row w-full gap-1"}>
          <div className="flex-0 w-full">
            <label className="block text-sm font-medium">
              <img src={BedIcon} alt="Bed" className="h-8 w-auto" />
            </label>
            {renderDropdown(
              "bedRoomsMin",
              optionsdata?.PropertySpecifics?.BedRooms?.BedRoomsMin || [],
              "Bedroom_From_ID",
              undefined,
              "w-full"
            )}
          </div>
          <div className="flex-0 w-full pt-[33px]">
            {renderDropdown(
              "bedRoomsMax",
              optionsdata?.PropertySpecifics?.BedRooms?.BedRoomsMax || [],
              "Bedroom_To_ID",
              undefined,
              "w-full"
            )}
          </div>
        </div>

        <div className={!filterData ? "flex flex-row md:w-1/3 gap-1" : "flex flex-row w-full gap-1"}>
          <div className="flex-0 w-full">
            <label className="block text-sm font-medium">
              <img src={BathIcon} alt="Bath" className="h-8 w-auto" />
            </label>
            {renderDropdown(
              "bathRoomsMin",
              optionsdata?.PropertySpecifics?.BathRooms?.BathRoomsMin || [],
              "Bathroom_From_ID",
              undefined,
              "w-full"
            )}
          </div>
          <div className="flex-0 w-full pt-[33px]">
            {renderDropdown(
              "bathRoomsMax",
              optionsdata?.PropertySpecifics?.BathRooms?.BathRoomsMax || [],
              "Bathroom_To_ID",
              undefined,
              "w-full"
            )}
          </div>
        </div>

        <div className={!filterData ? "flex flex-row md:w-1/3 gap-1" : "flex flex-row w-full gap-1"}>
          <div className="flex-0 w-full">
            <label className="block text-sm font-medium">
              <img src={CarIcon} alt="Car" className="h-8 w-auto" />
            </label>
            {renderDropdown(
              "carportsMin",
              optionsdata?.PropertySpecifics?.Carports?.CarportsMin || [],
              "Carport_From_ID",
              undefined,
              "w-full"
            )}
          </div>
          <div className="flex-0 w-full pt-[33px]">
            {renderDropdown(
              "carportsMax",
              optionsdata?.PropertySpecifics?.Carports?.CarportsMax || [],
              "Carport_To_ID",
              undefined,
              "w-full"
            )}
          </div>
        </div>
      </div>

      {showPropertyOptions && (
        <>
          <div className={!filterData ? "flex flex-col md:flex-row md:w-full gap-1" : "flex flex-col w-full gap-1"}>
            <div className={!filterData ? "flex flex-row md:w-1/3 gap-1" : "flex flex-row w-full gap-1"}>
              <div className="flex-0 w-1/2">
                <label className="block text-sm font-medium">Listing Age</label>
                {renderDropdown(
                  "listingAgeMin",
                  optionsdata?.ListngDetails?.ListingAge?.ListingAgeMin || [],
                  "ListingAge_From_ID",
                  undefined,
                  "w-full"
                )}
              </div>
              <div className="flex-0 w-1/2 pt-[18px]">
                {renderDropdown(
                  "listingAgeMax",
                  optionsdata?.ListngDetails?.ListingAge?.ListingAgeMax || [],
                  "ListingAge_To_ID",
                  undefined,
                  "w-full"
                )}
              </div>
            </div>

            <div className={!filterData ? "flex flex-row md:w-1/3 gap-1" : "flex flex-row w-full gap-1"}>
              <div className="flex-0 w-full">
                <label className="block text-sm font-medium">Property Yield</label>
                {renderDropdown(
                  "propertyYieldMin",
                  optionsdata?.ListngDetails?.PropertyYields?.PropertyYieldMin || [],
                  "PotentialYield_From_ID",
                  undefined,
                  "w-full"
                )}
              </div>
              <div className="flex-0 w-full pt-[18px]">
                {renderDropdown(
                  "propertyYieldMax",
                  optionsdata?.ListngDetails?.PropertyYields?.PropertyYieldMax || [],
                  "PotentialYield_To_ID",
                  undefined,
                  "w-full"
                )}
              </div>
            </div>

            <div className={!filterData ? "flex flex-row md:w-1/3 gap-1" : "flex flex-row w-full gap-1"}>
              <div className="flex-0 w-full">
                <label className="block text-sm font-medium">Land Size(m2)</label>
                {renderDropdown(
                  "landSizesMin",
                  optionsdata?.PropertySpecifics?.LandSizes?.LandSizesMin || [],
                  "LandSize_From_ID",
                  undefined,
                  "w-full"
                )}
              </div>
              <div className="flex-0 w-full pt-[18px]">
                {renderDropdown(
                  "landSizesMax",
                  optionsdata?.PropertySpecifics?.LandSizes?.LandSizesMax || [],
                  "LandSize_To_ID",
                  undefined,
                  "w-full"
                )}
              </div>
            </div>
          </div>
        </>
      )}

      <div className="col-span-12 text-end mt-2 text-blue-700">
        <span className="mr-1 pt-2 cursor-pointer" onClick={togglePropertyOptions}>
          {showPropertyOptions ? "Fewer" : "More"} property-specific options
        </span>
        <i
          className={classNames("pi cursor-pointer", !showPropertyOptions ? "pi-angle-down" : "pi-angle-up")}
          onClick={togglePropertyOptions}
        ></i>
      </div>
    </>
  );
};

export default PropertyTypeComponent;
