import { Toast } from "primereact/toast";
import { toastPosition } from "../../utils/constants";

const REIToastContainer = ({
  toastReference,
  position = toastPosition,
}: {
  toastReference: any;
  position?:
    | "center"
    | "top-center"
    | "top-left"
    | "top-right"
    | "bottom-center"
    | "bottom-left"
    | "bottom-right"
    | undefined;
}) => {
  return <Toast ref={toastReference} position={position} />;
};

export default REIToastContainer;
