import { PrimeReactProvider } from "primereact/api";
import "primereact/resources/themes/lara-light-indigo/theme.css";

export default function PrimeReactRootProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  return <PrimeReactProvider>{children}</PrimeReactProvider>;
}
