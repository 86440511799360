export const API_URL = process.env.API_URL;
export const API_USER = process.env.API_USER;
export const ANALYZE_IN_FULL = process.env.ANALYZE_IN_FULL;
export const Payment_Method_ENV = process.env.Payment_Method_ENV;
export const API_PWD = (process.env.API_PWD ?? "").replace("xxx", "$%$");
export const TOKEN_KEY = "access_token";
export const MEMBER_KEY = "member_id";
export const USER_DETAIL = "user_detail";
export const toastPosition = "top-right";
export const OPTION_ALL = "All";
export const EMAIL_REGEX =
  /^((?:[A-Za-z0-9!#$%&'*+\-\/=?^_`{|}~]|(?<=^|\.)"|"(?=$|\.|@)|(?<=".*)[ .](?=.*")|(?<!\.)\.){1,64})(@)((?:[A-Za-z0-9.\-])*(?:[A-Za-z0-9])\.(?:[A-Za-z0-9]){2,})$/;

export const ALERT_OPTIONS = [
  { name: "Inactive", code: 1 },
  { name: "Daily", code: 2 },
  { name: "Monthly", code: 3 },
  { name: "Weekly", code: 4 },
  { name: "Every 3 Days", code: 5 },
];

export const AU_STATES = [
  { name: "Australian Capital Territory", value: "ACT" },
  { name: "New South Wales", value: "NSW" },
  { name: "Northern Territory", value: "NT" },
  { name: "Queensland", value: "QLD" },
  { name: "South Australia", value: "SA" },
  { name: "Tasmania", value: "TAS" },
  { name: "Victoria", value: "VIC" },
  { name: "Western Australia", value: "WA" },
];

export const PAYMENT_MODES = [{ name: "Credit Card", code: 1 }];

export const PROPERTY_QUALITY = [
  { level: "Low", value: 1 },
  { level: "Medium", value: 2 },
  { level: "High", value: 3 },
];

export const PROPERTY_TYPE = [
  { type: "Please Select...", value: "" },
  { type: "House", value: 1 },
  { type: "Unit", value: 2 },
  { type: "Highrise", value: 3 },
  { type: "Industrial Suite", value: 4 },
  { type: "Commercial Suite", value: 5 },
  { type: "Complete Office Building", value: 6 },
  { type: "Industrial Building", value: 7 },
];

export const AU_CONSTRUCTION_YEAR = [
  { year: "Please Select...", value: "" },
  { year: "2024", value: 2024 },
  { year: "2023", value: 2023 },
  { year: "2022", value: 2022 },
  { year: "2021", value: 2021 },
  { year: "2020", value: 2020 },
  { year: "2019", value: 2019 },
  { year: "2018", value: 2018 },
  { year: "2017", value: 2017 },
  { year: "2016", value: 2016 },
  { year: "2015", value: 2015 },
  { year: "2014", value: 2014 },
  { year: "2013", value: 2013 },
  { year: "2012", value: 2012 },
  { year: "2011", value: 2011 },
  { year: "2010", value: 2010 },
  { year: "2009", value: 2009 },
  { year: "2008", value: 2008 },
  { year: "2007", value: 2007 },
  { year: "2006", value: 2006 },
  { year: "2005", value: 2005 },
  { year: "2004", value: 2004 },
  { year: "2003", value: 2003 },
  { year: "2002", value: 2002 },
  { year: "2001", value: 2001 },
  { year: "2000", value: 2000 },
  { year: "1999", value: 1999 },
  { year: "1998", value: 1998 },
  { year: "1997", value: 1997 },
  { year: "1996", value: 1996 },
  { year: "1995", value: 1995 },
  { year: "1994", value: 1994 },
  { year: "1993", value: 1993 },
  { year: "1992", value: 1992 },
  { year: "1991", value: 1991 },
  { year: "1990", value: 1990 },
  { year: "1989", value: 1989 },
  { year: "1988", value: 1988 },
  { year: "1987", value: 1987 },
  { year: "1986", value: 1986 },
  { year: "1985", value: 1985 },
  { year: "1984", value: 1984 },
  { year: "1983", value: 1983 },
  { year: "1982", value: 1982 },
  { year: "1981", value: 1981 },
  { year: "1980", value: 1980 },
  { year: "1979", value: 1979 },
  { year: "1978", value: 1978 },
  { year: "1977", value: 1977 },
  { year: "1976", value: 1976 },
  { year: "1975", value: 1975 },
  { year: "1974", value: 1974 },
  { year: "Pre 1974", value: "Pre 1974" },
];

export const AU_CAPITAL_CITY = [
  { city: "Please Select...", value: 0 },
  { city: "Sydney", value: 1 },
  { city: "Melbourne", value: 2 },
  { city: "Brisbane", value: 3 },
  { city: "Perth", value: 4 },
  { city: "Adelaide", value: 5 },
  { city: "Cairns", value: 6 },
  { city: "Hobart", value: 7 },
  { city: "Darwin", value: 8 },
  { city: "Canberra", value: 9 },
];

export const NZ_RENOVATIONS = [
  { renovationType: "Please Select...", value: "" },
  { renovationType: "Full", value: 1 },
  { renovationType: "Partial", value: 2 },
  { renovationType: "None", value: 3 },
];

export const LOCATION_ADVANCED_AU = [
  { name: "Limit postcode", code: 1 },
  { name: "Limit street address", code: 2 },
  { name: "Limit suburb", code: 3 },
  { name: "Search radius", code: 4 },
  { name: "Suburb classification", code: 5 },
];

export const LOCATION_ADVANCED_NZ = [
  { name: "Limit postcode", code: 1 },
  { name: "Limit street address", code: 2 },
  { name: "Limit suburb", code: 3 },
];

export const LOCATION_ADVANCED = {
  POSTCODE_LIMIT: 1,
  ADDRESS_LIMIT: 2,
  SUBURB_LIMIT: 3,
  POSTCODE_RADIUS: 4,
  SUBURB_TYPE: 5,
};

export const INVESTAR_SEARCH_SORTBY = [
  { name: "Listing Date: Newest to Oldest", value: 1 },
  { name: "Listing Date: Oldest to Newest", value: 2 },
  { name: "Keyword Relevancy", value: 3 },
  { name: "% of suburb median (by bedroom/dwelling type)", value: 4 },
  { name: "Listing Price: Low to High", value: 5 },
  { name: "Listing Price: High to Low", value: 6 },
  { name: "Land Area (sqm): Low to High", value: 7 },
  { name: "Land Area (sqm): High to Low", value: 8 },
  { name: "Postcode", value: 9 },
  { name: "Distance From Postcode: Low to High", value: 10 },
  { name: "Suburb", value: 11 },
  { name: "Suburb Median Rental Yield: Low to High", value: 12 },
  { name: "Suburb Median Rental Yield: High to Low", value: 13 },
  { name: "Advertised Yield: High to Low", value: 14 },
  { name: "Advertised Yield: Low to High", value: 15 },
  { name: "Average days on market: Low to High", value: 16 },
  { name: "Average days on market: High to Low", value: 17 },
];

export const COUNTRY = {
  AU: "AU",
  NZ: "NZ",
};

export const COUNTRY_OPTIONS = [
  { name: "Australia", value: 1 },
  { name: "New Zealand", value: 2 },
];

export const ENTITY_TYPE = [
  { name: "Personally Owned", value: "PER" },
  { name: "Company", value: "COM" },
  { name: "Trust", value: "TRU" },
  { name: "Self Manager SuperFund", value: "SMF" },
];

export const ShowHide_MSG = {
  SHOW: "Show Full Property Details",
  HIDE: "Hide Property Details",
};

export const ColumnType = {
  link: "link",
  dropdown: "dropdown",
  custom: "custom",
  resouceLinks: "resouceLinks",
  attachmentFiles: "attachmentFiles",
  negativeValues: "negativeValues",
};

export const httpCode = {
  UNAUTHORIZED: 401,
  NO_CONTENT: 204,
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
  SUCCESS: 200,
  ERR_CANCELED: "ERR_CANCELED",
  ERR_NETWORK: "ERR_NETWORK",
  BAD_REQUEST: 400,
  FORBIDDEN: 403,
} as const;

export const removeTokensFromLocalStorage = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(MEMBER_KEY);
};

export enum MyTrackerTabs {
  MyTracker = 1,
  MyCashFlow = 2,
  MyEquity = 3,
  MyProperties = 4,
  MyEntitiesGoals = 5,
  MyPhonebook = 6,
  ManageProperty = 7,
}

export enum MenuLabels {
  HOME = "Home",
  INVESTAR_SEARCH = "Investar Search",
  RP_DATA_PRICEFINDER = "RP Data/Pricefinder",
  PROPERTY_ANALYSER = "Property Analyser",
  PORTFOLIO_TRACKER = "Portfolio Tracker",
  MY_ACCOUNT = "My Account",
}

export enum MenuIcons {
  INVESTAR_SEARCH = "pi pi-search-plus",
  RP_DATA_PRICEFINDER = "pi pi-file",
  PROPERTY_ANALYSER = "pi pi-chart-pie",
  PORTFOLIO_TRACKER = "pi pi-building",
  MY_ACCOUNT = "pi pi-users",
}

export enum Messages {
  SUCCESS = "Success Message",
  ERROR = "Error",
  UNKNOWN_ERROR = "Something went wrong",
  DELETE_SUCCESS = "Record deleted successfully...",
  DELETE_ERROR = "Error deleting record...",
  CONFIRMATION_MESSAGE = "Are you sure you want to delete this record?",
  FILEUPLOAD_MESSAGE = "File uploaded succesfully",
}

export enum Status {
  SUCCESS = "succeeded",
  FAILED = "failed",
  LOADING = "loading",
}

export enum Icons {
  INFO_ICON = "pi pi-info-circle",
}

export enum Titles {
  WATCHLIST = "Watchlist",
  PROPERTY_DETAILS = "Property Details",
}

export const SuburbStatsAUMap = new Map([
  ["MedianSalePrice", "Median listing price"],
  ["YearGrowthPercent", "1 Yr listing price growth"],
  ["MedianRent", "Median weekly rent"],
  ["RentGrowth_1yr", "weekly rent growth"],
  ["MedianRentalYield", "Median gross yield"],
]);

export const SuburbStatsNZMap = new Map([
  ["YearGrowthPercent", "1yr sales growth locality %"],
  ["MedianRent", "Med. rent "],
  ["MedianRentalYield", "Med. rental yield"],
  ["MedianSalePrice", "Med. listing price "],
]);

export const UnitStasticMap = new Map([
  ["VacancyPercent", "Vacancy rate"],
  ["VacancyChange_1yr", "1 yr vacancy rate change"],
  ["AvgDaysOnMarket", "Average days on market"],
  ["NumberOfSales", "Current listings"],
  ["ListingsClearedLast30days", "Listings cleared in the last 30 days"],
  ["ClearanceRate", "Clearance rate"],
]);

export const PAUnitStasticMap = new Map([
  ["VacancyRate", "Vacancy rate"],
  ["OneYrVacancyRateChange", "1 yr vacancy rate change"],
  ["AverageDaysOnMarket", "Average days on market"],
  ["CurrentListing", "Current listings"],
  ["ListingClearedlast30", "Listings cleared in the last 30 days"],
  ["ClearanceRate", "Clearance rate"],
]);

export const PASnapshotDataMap = new Map([
  ["CashflowPostTaxYr1", "Cash flow p/a (after-tax)"],
  ["CashflowPostTaxYr10", "Cash flow p/a (after-tax)"],
  ["CashflowPostTaxYr3", "Cash flow p/a (after-tax)"],
  ["CashflowPostTaxYr5", "Cash flow p/a (after-tax)"],
  ["CashflowPreTaxYr1", "Cash flow p/a (pre-tax)"],
  ["CashflowPreTaxYr10", "Cash flow p/a (pre-tax)"],
  ["CashflowPreTaxYr3", "Cash flow p/a (pre-tax)"],
  ["CashflowPreTaxYr5", "Cash flow p/a (pre-tax)"],
  ["GrossYieldYr1", "Gross yield"],
  ["GrossYieldYr10", "Gross yield"],
  ["GrossYieldYr3", "Gross yield"],
  ["GrossYieldYr5", "Gross yield"],
  ["NetYieldY1", "Net yield"],
  ["NetYieldY10", "Net yield"],
  ["NetYieldY3", "Net yield"],
  ["NetYieldY5", "Net yield"],
  ["TotalReturnYr1", "Total returns (cash & growth)"],
  ["TotalReturnYr10", "Total returns (cash & growth)"],
  ["TotalReturnYr5", "Total returns (cash & growth)"],
  ["TotalReturnYr3", "Total returns (cash & growth)"],
]);

export const LENGTH_OPTION_1_100 = Array.from({ length: 100 }, (_, index) => ({
  name: index.toString(),
  code: index + 1,
}));

export const GET_LENGTH_OPTIONS = (
  start: number,
  end: number,
  labelFormat = (i: number) => i.toString(),
  valueOffset = 1,
  sorting = "asc"
) => {
  const options = [];
  if (sorting === "desc") {
    for (let i = end; i >= start; i--) {
      options.push({ Label: labelFormat(i), value: i + valueOffset - 1 });
    }
  } else {
    for (let i = start; i <= end; i++) {
      options.push({ Label: labelFormat(i), value: i + valueOffset - 1 });
    }
  }
  return options;
};

export const ArchistrarDataMap = new Map([
  ["LandUses", "Land Uses"],
  ["Overlays", "Overlays"],
  ["Zoning", "Zone"],
]);

export const SnapshotDataMap = new Map([
  ["TotalReturnYr1", "Total returns (cash & growth)"],
  ["TotalReturnYr10", "Total returns (cash & growth)"],
  ["TotalReturnYr5", "Total returns (cash & growth)"],
  ["TotalReturnYr3", "Total returns (cash & growth)"],
  ["GrossYieldYr1", "Gross yield"],
  ["GrossYieldYr10", "Gross yield"],
  ["GrossYieldYr3", "Gross yield"],
  ["GrossYieldYr5", "Gross yield"],
  ["NetYieldY1", "Net yield"],
  ["NetYieldY10", "Net yield"],
  ["NetYieldY3", "Net yield"],
  ["NetYieldY5", "Net yield"],
  ["CashflowPreTaxYr1", "Cash flow p/a (after-tax)"],
  ["CashflowPreTaxYr10", "Cash flow p/a (after-tax)"],
  ["CashflowPreTaxYr3", "Cash flow p/a (after-tax)"],
  ["CashflowPreTaxYr5", "Cash flow p/a (after-tax)"],
]);

export const ForecastItemCode = {
  CapitalGrowth: "EquityDetails.CapitalGrowth",
  CPI: "CashflowDetails.CPI",
  PropertyMarketValue: "EquityDetails.MarketValue",
  CashFlowRent: "CashflowRent.OffPeakNumWeeks",
  NonCashDeduction: "CashflowDepreciation.Depreciation",
  OverviewSummary: "CashflowPropertyExpense.LLF",
  CashflowPropertyExpenseRates: "CashflowPropertyExpense.Rates",
  CashflowMortgageInterestRate: "CashflowMortgage.InterestRate",
  CashflowMortgageInterestPayment: "CashflowMortgage.InterestPayment",
  CashflowMortgagePrincipalPayment: "CashflowMortgage.PrincipalPayment",
};

export const YEARS = [
  { label: "Today", defaultShow: true },
  { label: "Year 1", defaultShow: true },
  { label: "Year 2", defaultShow: false },
  { label: "Year 3", defaultShow: true },
  { label: "Year 4", defaultShow: false },
  { label: "Year 5", defaultShow: true },
  { label: "Year 6", defaultShow: false },
  { label: "Year 7", defaultShow: false },
  { label: "Year 8", defaultShow: false },
  { label: "Year 9", defaultShow: false },
  { label: "Year 10", defaultShow: true },
];
