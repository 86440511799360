import { Outlet, useNavigate } from "react-router-dom";
import "primeicons/primeicons.css";
import "./index.scss";
import "./styles/custom.scss";

import Layout from "./components/layout/layout";
import { useHttpGet } from "./services/httpService";
import { ApiEndPoints } from "./utils/endpoints";
import { TOKEN_KEY } from "./utils/constants";

const App: React.FC = () => {
  const navigate = useNavigate();

  if (localStorage.getItem(TOKEN_KEY)) {
    const { data, error } = useHttpGet(
      `${ApiEndPoints.login}/AuthToken?authToken=${localStorage.getItem(
        TOKEN_KEY
      )}`,
      undefined
    );
    if (error) {
      navigate("/login");
    }
  }

  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};

export { App };
