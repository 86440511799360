import SavedSearchList from "./List/SavedSearch";

const MySavedSearch: React.FC = () => {
  return (
    <div className="overflow-y-auto min-h-40">
      <SavedSearchList />
    </div>
  );
};

export default MySavedSearch;
