import { useEffect, useRef, useState, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { httpPost } from "../../../services/httpService";
import { ApiEndPoints } from "../../../utils/endpoints";
import { IWatchListData } from "../../../interface/Watchlist/propertyDetails";
import ViewPropertyDetails from "../../../interface/Watchlist/propertyDetails";
import PropertyHeader from "./PropertyHeader";
import PropertyInformation from "./PropertyInformation";
import { REIProgressSpinner } from "../../../components/ui";
import { RootState } from "../../../store";
import { setPropertyDetails } from "../../../store/slice/propertyDetailsSlice";

interface ViewPropertyProp {
  onClose?: () => void;
  propertyDetails: IWatchListData;
  hideCloseBtn?: boolean;
}

const PropertyDetails: React.FC<ViewPropertyProp> = ({ onClose, propertyDetails, hideCloseBtn }) => {
  const [propertyData, setPropertyData] = useState<ViewPropertyDetails | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const hasFetched = useRef<string | null>(null);

  useEffect(() => {

    if (!propertyDetails.PropertyID || hasFetched.current === propertyDetails.PropertyID) {
      return;
    }

    const fetchData = async () => {
      try {
        setLoading(true);

        const parameters = {
          CountryCode: propertyDetails.CountryID,
          PropertyId: propertyDetails.PropertyID,
          LinkIdHash: propertyDetails.LinkIDHash,
          MemberId: propertyDetails.MemberId,
        };

        const result = await httpPost(`${ApiEndPoints.searchSummary}/AU/Search/PropertyDetails/`, parameters);

        if (result.status === 200 && result.data?.Result?.length > 0) {
          const updatedData = {
            ...result.data.Result[0],
            PropertyDetails: {
              ...result.data.Result[0].PropertyDetails,
              WatchlistID: propertyDetails.WatchlistID,
              MemberId: propertyDetails.MemberId,
            },
            isFromPropertyAnalyser: false,
          };

          if (JSON.stringify(propertyData) !== JSON.stringify(updatedData)) {
            setPropertyData(updatedData);
            dispatch(setPropertyDetails(updatedData));
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
        hasFetched.current = propertyDetails.PropertyID;
      }
    };

    fetchData();
  }, [propertyDetails.PropertyID]);

  // Memoize the callback to avoid unnecessary re-renders
  const handleCallback = useCallback(() => {
    onClose?.();
  }, [onClose]);

  // Memoize propertyData to prevent unnecessary re-renders of PropertyInformation
  const memoizedPropertyData = useMemo(() => propertyData, [propertyData]);

  return (
    <>
      {loading && (
        <div className="w-full flex items-center min-h-60">
          <REIProgressSpinner />
        </div>
      )}
      {!loading && propertyData && (
        <>
          <PropertyHeader
            propertydata={memoizedPropertyData}
            onClosecallback={handleCallback}
            hideCloseBtn={hideCloseBtn}
          />
          <PropertyInformation viewPropertyData={memoizedPropertyData} />
        </>
      )}
    </>
  );
};

export { PropertyDetails };
