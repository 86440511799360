import React from "react";
import { TabPanel, TabView } from "primereact/tabview";
import { ITab } from "../../interface/tab";

interface IREITabProps {
  tabs: ITab[];
  classNames?: string;
}

const REITab: React.FC<IREITabProps> = ({ tabs, classNames }) => {
  return (
    <TabView>
      {tabs.map((tab, index) => (
        <TabPanel
          key={index}
          header={tab.header}
          closable={tab.closable}
          className={classNames}
          headerTemplate={tab.headerTemplate}
        >
          {tab.content}
        </TabPanel>
      ))}
    </TabView>
  );
};

export default REITab;
