import { HTMLAttributeAnchorTarget } from "react";

interface linkButtonProps {
  href: string;
  target: HTMLAttributeAnchorTarget;
  rel: string;
  className: string;
  children: React.ReactNode;
}

const REILinkButton: React.FC<linkButtonProps> = ({
  href,
  target,
  rel,
  className,
  children,
  ...rest
}) => (
  <a href={href} className={className} target={target} rel={rel} {...rest}>
    {children}
  </a>
);

export default REILinkButton;
