import React, { useEffect, useState } from "react";
import { IAnalysedProperty, IPropertyAnnualRentalReturns } from "../../../../../interface/addAnalyser";
import { useAnalysedPropertyContext } from "../AnalysedPropertyContext";
import { RadioButton } from "primereact/radiobutton";
import { REIInputNumber } from "../../../../../components/ui";
import InputSlider from "../../../../../components/ui/slider";

interface IAnnualRentalReturn {
  setIsValid: (isValid: boolean) => void;
  updateProperty: (updated: Partial<IAnalysedProperty>) => void;
  updateRentalReturn: any;
}

const AnnualRentalReturn: React.FC<IAnnualRentalReturn> = ({ setIsValid, updateProperty, updateRentalReturn }) => {
  const { analysedProperty } = useAnalysedPropertyContext();
  const [anuualRentalReturn, setAnuualRentalReturn] = useState<IPropertyAnnualRentalReturns>();
  const [totalPeak, setTotalPeak] = useState<number>(0);
  const [totalShoulder, setTotalShoulder] = useState<number>(0);
  const [totalOffPeak, setTotalOffPeak] = useState<number>(0);
  const [reset, setReset] = useState<boolean>(false);

  const calculateSubtotal = (weeks: number, occupancy: number, rate: number) =>
    Math.round(weeks * (occupancy / 100) * rate);

  useEffect(() => {
    if (analysedProperty && !reset) {
      setAnuualRentalReturn(analysedProperty?.RentExpense.PropertyAnnualRentalReturns);
      updateRentalReturn(
        anuualRentalReturn?.isResidential == "1"
          ? anuualRentalReturn?.WeeklyRent * anuualRentalReturn?.RateOfOccupancy
          : totalOffPeak + totalPeak + totalShoulder
      );
      setReset(true);
    }
  }, [analysedProperty]);

  useEffect(() => {
    if (anuualRentalReturn) {
      setIsValid(true);
      setTotalPeak(
        calculateSubtotal(
          anuualRentalReturn?.NoOfWeeksPeak || 0,
          anuualRentalReturn?.OccupancyPeak || 0,
          anuualRentalReturn?.RatePWPeak || 0
        )
      );
      setTotalShoulder(
        calculateSubtotal(
          anuualRentalReturn?.NoOfWeeksShoulder || 0,
          anuualRentalReturn?.OccupancyShoulder || 0,
          anuualRentalReturn?.RatePWShoulder || 0
        )
      );
      setTotalOffPeak(
        calculateSubtotal(
          anuualRentalReturn?.NoOfWeeksOffPeak || 0,
          anuualRentalReturn?.OccupancyOffPeak || 0,
          anuualRentalReturn?.RatePWOffPeak || 0
        )
      );
      updateRentalReturn(
        anuualRentalReturn?.isResidential == "1"
          ? anuualRentalReturn?.WeeklyRent * anuualRentalReturn?.RateOfOccupancy
          : totalOffPeak + totalPeak + totalShoulder
      );
      updateProperty({
        ...analysedProperty,
        RentExpense: {
          ...analysedProperty?.RentExpense,
          PropertyAnnualRentalReturns: anuualRentalReturn,
          PropertyAnnualExpense: analysedProperty?.RentExpense?.PropertyAnnualExpense,
        },
      });
    }
  }, [anuualRentalReturn]);

  const setValues = (name: string, value: string | number) => {
    setAnuualRentalReturn((prevState) => {
      if (!prevState) return undefined;

      return {
        ...prevState,
        [name]: value !== undefined ? value : 0,
      };
    });
  };

  return (
    <div>
      <div className="relative rounded-lg pl-3 pr-1 py-3 border-solid border border-gray-300 bg-white mt-2">
        <div className="default-profile-header-container">
          <h3 className="text-[var(--primary-color)] mt-0 m-1 text-lg">Annual Rental Return</h3>
        </div>
        <div className="mt-4 p-2">
          <div className="flex mb-3">
            <div className="flex w-full gap-3">
              Rental type:
              <div className=" mr-3">
                <RadioButton
                  inputId="residential"
                  name="rentalType"
                  value={anuualRentalReturn?.isResidential == "1" ? "1" : "0"}
                  onChange={(e) => {
                    setValues("isResidential", e.checked ? "1" : "0");
                    setValues("isHoliday", e.checked ? "0" : "1");
                  }}
                  checked={anuualRentalReturn?.isResidential === "1"}
                />
                <label htmlFor="isresidential" className="ml-2 align-top">
                  Residential long term
                </label>
              </div>
              <div className=" mr-3">
                <RadioButton
                  inputId="holiday"
                  name="rentalType"
                  value={anuualRentalReturn?.isHoliday == "1" ? "1" : "0"}
                  onChange={(e) => {
                    setValues("isResidential", e.checked ? "0" : "1");
                    setValues("isHoliday", e.checked ? "1" : "0");
                  }}
                  checked={anuualRentalReturn?.isHoliday === "1"}
                />
                <label htmlFor="isresidential" className="ml-2 align-top">
                  Holiday rental
                </label>
              </div>
            </div>
          </div>
          {anuualRentalReturn?.isResidential == "1" && (
            <div className="flex flex-col lg:flex-row lg:w-full gap-2">
              <div className="flex-0 flex-col w-full lg:w-1/2 gap-2">
                <div className="flex w-full p-1">
                  <div className="w-[20%] mt-2">Rate of occupancy (wks)</div>
                  <div className="w-[40%] pr-2">
                    <InputSlider
                      value={anuualRentalReturn?.RateOfOccupancy}
                      onChange={(e) => setValues("RateOfOccupancy", Array.isArray(e.value) ? e.value[0] : e.value)}
                      classNames="w-full mt-4"
                      min={0}
                      max={52}
                    />
                  </div>
                  <div className="w-[40%] flex px-2">
                    <REIInputNumber
                      value={anuualRentalReturn?.RateOfOccupancy}
                      onValueChange={(e) => setValues("RateOfOccupancy", e.target.value)}
                      className="w-full"
                      useGrouping={true}
                    />
                  </div>
                </div>
              </div>
              <div className="flex-0 flex-col w-full lg:w-1/2 gap-2">
                <div className="flex w-full p-1">
                  <div className="w-[20%] mt-2">Weekly rent ($)</div>
                  <div className="w-[40%] pr-2">
                    <InputSlider
                      value={anuualRentalReturn?.WeeklyRent}
                      onChange={(e) => setValues("WeeklyRent", Array.isArray(e.value) ? e.value[0] : e.value)}
                      classNames="w-full mt-4"
                      min={0}
                      max={2000}
                    />
                  </div>
                  <div className="w-[40%] flex px-2">
                    <REIInputNumber
                      value={anuualRentalReturn?.WeeklyRent}
                      onValueChange={(e) => setValues("WeeklyRent", e.target.value)}
                      className="w-full"
                      useGrouping={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {anuualRentalReturn?.isHoliday == "1" && (
            <>
              <div className="flex flex-row w-full">
                <div className="w-1/3"> </div>
                <div className="flex flex-row w-full gap-2 px-2 justify-around"></div>
              </div>
              <div className="flex gap-2 mb-3 text-center">
                <div className="w-1/3">Peak:</div>
                <div className="w-1/3">Shoulder:</div>
                <div className="w-1/3">Off peak:</div>
              </div>
              <div className="flex gap-2 mb-3">
                <div className="w-1/3"> # of weeks</div>
                <div className="flex flex-row w-full gap-2 px-2">
                  <InputSlider
                    value={anuualRentalReturn?.NoOfWeeksPeak}
                    onChange={(e) => setValues("NoOfWeeksPeak", Array.isArray(e.value) ? e.value[0] : e.value)}
                    classNames="w-full mt-4 align-middle"
                    min={0}
                    max={52}
                  />

                  <REIInputNumber
                    value={anuualRentalReturn?.NoOfWeeksPeak}
                    onValueChange={(e) => setValues("NoOfWeeksPeak", e.target.value)}
                    className="w-full"
                    useGrouping={true}
                  />
                </div>
                <div className="flex flex-row w-full gap-2 px-2">
                  <InputSlider
                    value={anuualRentalReturn?.NoOfWeeksShoulder}
                    onChange={(e) => setValues("NoOfWeeksShoulder", Array.isArray(e.value) ? e.value[0] : e.value)}
                    classNames="w-full mt-4"
                    min={0}
                    max={52}
                  />
                  <REIInputNumber
                    value={anuualRentalReturn?.NoOfWeeksShoulder}
                    onValueChange={(e) => setValues("NoOfWeeksShoulder", e.target.value)}
                    className="w-full"
                    useGrouping={true}
                  />
                </div>
                <div className="flex flex-row w-full gap-2 px-2">
                  <InputSlider
                    value={anuualRentalReturn?.NoOfWeeksOffPeak}
                    onChange={(e) => setValues("NoOfWeeksOffPeak", Array.isArray(e.value) ? e.value[0] : e.value)}
                    classNames="w-full mt-4"
                    min={0}
                    max={52}
                  />
                  <REIInputNumber
                    value={anuualRentalReturn?.NoOfWeeksOffPeak}
                    onValueChange={(e) => setValues("NoOfWeeksOffPeak", e.target.value)}
                    className="w-full"
                    useGrouping={true}
                  />
                </div>
              </div>
              <div className="flex gap-2 mb-3">
                <div className="w-1/3">Occupancy (%)</div>
                <div className="flex flex-row w-full gap-2 px-2">
                  <InputSlider
                    value={anuualRentalReturn?.OccupancyPeak}
                    onChange={(e) => setValues("OccupancyPeak", Array.isArray(e.value) ? e.value[0] : e.value)}
                    classNames="w-full mt-4 align-middle"
                    min={0}
                    max={100}
                  />

                  <REIInputNumber
                    value={anuualRentalReturn?.OccupancyPeak}
                    onValueChange={(e) => setValues("OccupancyPeak", e.target.value)}
                    className="w-full"
                    useGrouping={true}
                  />
                </div>
                <div className="flex flex-row w-full gap-2 px-2">
                  <InputSlider
                    value={anuualRentalReturn?.OccupancyShoulder}
                    onChange={(e) => setValues("OccupancyShoulder", Array.isArray(e.value) ? e.value[0] : e.value)}
                    classNames="w-full mt-4"
                    min={0}
                    max={100}
                  />
                  <REIInputNumber
                    value={anuualRentalReturn?.OccupancyShoulder}
                    onValueChange={(e) => setValues("OccupancyShoulder", e.target.value)}
                    className="w-full"
                    useGrouping={true}
                  />
                </div>
                <div className="flex flex-row w-full gap-2 px-2">
                  <InputSlider
                    value={anuualRentalReturn?.OccupancyOffPeak}
                    onChange={(e) => setValues("OccupancyOffPeak", Array.isArray(e.value) ? e.value[0] : e.value)}
                    classNames="w-full mt-4"
                    min={0}
                    max={100}
                  />
                  <REIInputNumber
                    value={anuualRentalReturn?.OccupancyOffPeak}
                    onValueChange={(e) => setValues("OccupancyOffPeak", e.target.value)}
                    className="w-full"
                    useGrouping={true}
                  />
                </div>
              </div>
              <div className="flex gap-2 mb-3">
                <div className="w-1/3">Weekly rent ($)</div>
                <div className="flex flex-row w-full gap-2 px-2">
                  <InputSlider
                    value={anuualRentalReturn?.RatePWPeak}
                    onChange={(e) => setValues("RatePWPeak", Array.isArray(e.value) ? e.value[0] : e.value)}
                    classNames="w-full mt-4 align-middle"
                    min={0}
                    max={2000}
                  />

                  <REIInputNumber
                    value={anuualRentalReturn?.RatePWPeak}
                    onValueChange={(e) => setValues("RatePWPeak", e.target.value)}
                    className="w-full"
                    useGrouping={true}
                  />
                </div>
                <div className="flex flex-row w-full gap-2 px-2">
                  <InputSlider
                    value={anuualRentalReturn?.RatePWShoulder}
                    onChange={(e) => setValues("RatePWShoulder", Array.isArray(e.value) ? e.value[0] : e.value)}
                    classNames="w-full mt-4"
                    min={0}
                    max={2000}
                  />
                  <REIInputNumber
                    value={anuualRentalReturn?.RatePWShoulder}
                    onValueChange={(e) => setValues("RatePWShoulder", e.target.value)}
                    className="w-full"
                    useGrouping={true}
                  />
                </div>
                <div className="flex flex-row w-full gap-2 px-2">
                  <InputSlider
                    value={anuualRentalReturn?.RatePWOffPeak}
                    onChange={(e) => setValues("RatePWOffPeak", Array.isArray(e.value) ? e.value[0] : e.value)}
                    classNames="w-full mt-4"
                    min={0}
                    max={2000}
                  />
                  <REIInputNumber
                    value={anuualRentalReturn?.RatePWOffPeak}
                    onValueChange={(e) => setValues("RatePWOffPeak", e.target.value)}
                    className="w-full"
                    useGrouping={true}
                  />
                </div>
              </div>
              <div className="flex gap-2 mb-3 ">
                <div className="w-1/3">subtotal</div>
                <div className="flex flex-row w-full gap-2 px-2 justify-self-end">${totalPeak}</div>
                <div className="flex flex-row w-full gap-2 px-2 justify-self-end">${totalShoulder}</div>
                <div className="flex flex-row w-full gap-2 px-2 justify-self-end">${totalOffPeak}</div>
              </div>
            </>
          )}
          <div className="flex flex-row lg:w-full gap-2 mt-3">
            <div className="w-[30%]">
              <b>
                {`Total Annual Rent : $ ${
                  anuualRentalReturn?.isResidential == "1"
                    ? anuualRentalReturn?.WeeklyRent * anuualRentalReturn?.RateOfOccupancy
                    : totalOffPeak + totalPeak + totalShoulder
                }`}
              </b>
            </div>
            <div className="w-[60%]">
              Please note: you can only enter rental values for either residential or holiday rental, not both.
              Whichever screen you complete and keep as the selected (visible) option will be the rental type used for
              analysis.
            </div>
          </div>
        </div>
        <div className="flex w-1/3 items-start"></div>
      </div>
    </div>
  );
};

export default AnnualRentalReturn;
