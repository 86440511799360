import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";
import { REIButton, REIProgressSpinner, REIToastContainer } from "../ui";
import { ColumnType, Icons, Messages, Status } from "../../utils/constants";
import { IFileDetails } from "../../interface/files";
import { confirmPopup } from "primereact/confirmpopup";
import ViewPropertyDetails from "../../interface/Watchlist/propertyDetails";
import ImageUploadWithGallery from "./imageUploadWithGallery";
import FileUploadWithCustomTemplate from "./fileUploadeWithCustomTemplate";
import REIProgressbar from "../ui/progressbar";
import { deleteFile, fetchFiles, uploadFile } from "../../store/slice/fileUploadSlice";

interface IPropertyData {
  propertyData: ViewPropertyDetails;
  editorMode: "Advanced" | "Basic";
}

const REIFileUpload: React.FC<IPropertyData> = ({ propertyData, editorMode }) => {
  const [totalSize, setTotalSize] = useState(0);
  const [value, setValue] = useState(0);
  const fileUploadRef = useRef(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [refreshData, setRefreshData] = useState<boolean>(true);
  const toastRef = useRef<Toast>(null);
  const [fileData, setFileData] = useState();
  const dispatch = useDispatch();
  const deleteRepsonse = useSelector((state) => state.files?.deleteFilesResponse);
  const uploadRepsonse = useSelector((state) => state.files?.uploadResponse);
  const FetchFileError = useSelector((state) => state.files?.error);
  const getFileResponse = useSelector((state) => state.files?.files);
  const FileStatus = useSelector((state) => state.files?.status);
  const isCustomFileUpload = useRef<boolean>(editorMode == "Basic" ? false : true);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      await dispatch(
        fetchFiles({
          WatchlistID: propertyData.PropertyDetails.WatchlistID,
          isAttachementData: !propertyData.isFromPropertyAnalyser,
        })
      );
      setLoading(false);
    };

    if (propertyData.PropertyDetails?.WatchlistID) {
      getData();
    }
  }, [dispatch, propertyData.PropertyDetails.WatchlistID, refreshData]);

  useEffect(() => {
    if (FileStatus === Status.SUCCESS && FetchFileError === "") {
      setFileData(getFileResponse);
      setLoading(false);
    } else if (FileStatus === Status.FAILED) {
      toastRef.current?.show({
        severity: "error",
        summary: Messages.ERROR,
        detail: Messages.ERROR,
        life: 2000,
      });
    } else {
      setLoading(true);
    }
  }, [FileStatus, FetchFileError, getFileResponse]);

  useEffect(() => {
    if (uploadRepsonse.status == Status.SUCCESS && uploadRepsonse.error == "" && uploadRepsonse.data != null) {
      setValue(100);
      setRefreshData(!refreshData);
      toastRef.current?.show({
        severity: "success",
        summary: Messages.SUCCESS,
        detail: Messages.FILEUPLOAD_MESSAGE,
        life: 2000,
      });
      setLoading(false);
      setTimeout(() => {
        setValue(0);
      }, 2000);
    }
    if (uploadRepsonse.status == Status.FAILED && uploadRepsonse.error != "") {
      setValue(0);
      toastRef.current?.show({
        severity: "error",
        summary: Messages.ERROR,
        detail: Messages.ERROR,
        life: 2000,
      });
      setLoading(false);
    }
    setLoading(false);
  }, [uploadRepsonse]);

  useEffect(() => {
    if (deleteRepsonse.status == Status.SUCCESS && deleteRepsonse.error == "") {
      setLoading(false);
      setRefreshData(!refreshData);
      toastRef.current?.show({
        severity: "success",
        summary: Messages.SUCCESS,
        detail: Messages.DELETE_SUCCESS,
        life: 2000,
      });
    }

    if (deleteRepsonse.error != "" && deleteRepsonse.status == Status.FAILED) {
      toastRef.current?.show({
        severity: "error",
        summary: "Error",
        detail: deleteRepsonse.sterrortus,
        life: 3000,
      });
    }
    setLoading(false);
  }, [deleteRepsonse]);

  const deleteSelectedFile = async (files: IFileDetails) => {
    setLoading(true);

    const fileParams = {
      FileName: files.FileName,
      WatchlistID: propertyData.PropertyDetails.WatchlistID,
    };
    await dispatch(deleteFile(fileParams));
  };

  const handleDeleteDocuments = (event: React.MouseEvent<HTMLElement>, row: IFileDetails) => {
    confirmPopup({
      target: event.currentTarget,
      message: Messages.CONFIRMATION_MESSAGE,
      icon: Icons.INFO_ICON,
      accept: () => deleteSelectedFile(row),
      reject: () => {},
    });
  };

  const fileColumns = [
    { field: "FileName", header: ColumnType.attachmentFiles, icon: "", type: ColumnType.link },
    {
      field: "",
      header: "",
      type: ColumnType.custom,
      onClick: handleDeleteDocuments,
      width: "50px",
      template: <i className="pi pi-minus-circle text-[#4E9FBA] cursor-pointer text-sm"></i>,
    },
  ];

  const fileUploader = async (fileStream: ArrayBuffer | string, fileData: any, memberPropertyId: string) => {
    if (fileStream) {
      setValue(20);
      setTotalSize(0);
      setLoading(true);
      setValue(50);
      let APIparameters = {
        file: fileStream,
        memberPropertyId: memberPropertyId,
        fileData: fileData,
        isattachement: propertyData.isFromPropertyAnalyser ? 0 : 1,
      };
      await dispatch(uploadFile(APIparameters));
    }
  };

  const fileUploadHandler = async ({ files }) => {
    setLoading(true);
    const [file] = files;
    const fileReader = new FileReader();

    fileReader.onload = async (e) => {
      fileUploader(e.target?.result, files[0], propertyData.PropertyDetails.WatchlistID);
    };
    fileReader.readAsDataURL(file);
  };

  const onTemplateSelect = (e) => {
    let _totalSize = totalSize;
    let files = e.files;

    Object.keys(files).forEach((key) => {
      _totalSize += files[key].size || 0;
    });

    setTotalSize(_totalSize);
  };

  const onCancel = () => {
    if (fileUploadRef.current) {
      fileUploadRef.current.clear();
    }
  };

  const onTemplateRemove = (file, callback) => {
    setTotalSize(totalSize - file.size);
    callback();
  };

  const basicItemTemplate = (item: IFileDetails) => {
    return (
      <div className="flex flex-col content-center">
        <img src={item.FileUrl} alt={item.FileName} className="w-full object-fill " />

        <REIButton
          icon="pi pi-times"
          classNames="p-button-rounded p-button-danger p-button-sm "
          onClick={() => deleteSelectedFile(item)}
        />
      </div>
    );
  };

  const onTemplateClear = () => {
    setTotalSize(0);
  };

  const headerTemplate = (options) => {
    const { className, chooseButton, uploadButton, cancelButton } = options;

    return (
      <div className={`${className} flex content-center text-xs bg-transparent`}>
        {chooseButton}
        {uploadButton}
        {cancelButton}
        </div>
    );
  };

  const onFileSelect = () => {
    setValue(0);
  };

  const itemTemplate = (file, props) => {
    return (
      <>
        {loading && (
          <div className="flex items-center">
            <REIProgressSpinner></REIProgressSpinner>
          </div>
        )}
        {!loading && (
          <div className="flex align-items-center flex-wrap self-center">
            <div className="flex space-x-2 justify-between align-items-center">
              <span className="self-center">
                <img alt={file.name} role="presentation" src={file.objectURL} width={100} />
              </span>
              <span className=" text-left ml-3 self-center">
                <span className="p">{file.name}</span>
              </span>
              <Tag value={props.formatSize} severity="warning" className="px-3 py-2 h-1/2 self-center text-xs" />
              <Button
                type="button"
                icon="pi pi-times"
                className=" p-button-outlined p-button-rounded p-button-danger m-auto self-center text-xs"
                onClick={() => onTemplateRemove(file, props.onRemove)}
              />
            </div>
          </div>
        )}
      </>
    );
  };

  const emptyTemplate = () => {
    if (isCustomFileUpload) {
      return (
        <div className="flex place-content-center flex-column">
          <i className="pi pi-image mt-3 p-5 text-6xl bg-gray-200 text-gray-50 rounded-full"></i>
          <span className="my-5 text-xl text-gray-300 content-center m-4">Drag and Drop Image Here</span>
        </div>
      );
    } else {
      return <div className="flex place-content-center flex-column">No Photos</div>;
    }
  };

  const chooseOptions = {
    icon: "pi pi-fw pi-images",
    iconOnly: true,
    className: "custom-choose-btn p-button-rounded p-button-outlined",
  };
  const basicChooseOptions = {
    label: "Choose",
    iconOnly: false,
    icon: "",
    className: "p-button-rounded p-button-outlined text-sm small-icon",
  };

  const uploadOptions = {
    icon: "pi pi-fw pi-cloud-upload",
    iconOnly: true,
    className: "custom-upload-btn p-button-success p-button-rounded p-button-outlined",
  };

  const cancelOptions = {
    icon: "pi pi-fw pi-times",
    iconOnly: true,
    className: "custom-cancel-btn p-button-danger p-button-rounded p-button-outlined text-xs",
  };

  return (
    <>
      <REIToastContainer toastReference={toastRef} />

      <>
      {!isCustomFileUpload.current ? (
        <ImageUploadWithGallery
          emptyTemplate={emptyTemplate}
          fileData={fileData}
          uploadHandler={fileUploadHandler}
          onCancel={onCancel}
          onSelect={onTemplateSelect}
          onError={onTemplateClear}
          onClear={onTemplateClear}
          chooseOptions={basicChooseOptions}
          loading={loading}
          itemTemplate={basicItemTemplate}
        />
      ) : (
        <FileUploadWithCustomTemplate
          uploadHandler={fileUploadHandler}
          onSelect={onTemplateSelect}
          onError={onTemplateClear}
          onClear={onTemplateClear}
          onBeforeSelect={onFileSelect}
          headerTemplate={headerTemplate}
          itemTemplate={itemTemplate}
          emptyTemplate={emptyTemplate}
          chooseOptions={chooseOptions}
          uploadOptions={uploadOptions}
          cancelOptions={cancelOptions}
          totalSize={totalSize}
          loading={loading}
          fileData={fileData}
          fileColumns={fileColumns}
            progressBarTemplate={
              <REIProgressbar
                className="w-full h-1 rounded-none"
                value={value}
                showValue={false}
                mode="determinate"
              ></REIProgressbar>
            }
        />
      )}
    </>
    </>
  );
};

export default REIFileUpload;
