import { Galleria } from "primereact/galleria";
import React, { useEffect, useState } from "react";
import { IFileDetails } from "../../interface/files";
import * as styles from "./gallary.module.scss";

interface IGalleryProps {
  imageData: IFileDetails[];
  classNames?: string;
  showItemNavigators?: boolean;
  showItemNavigatorsOnHover?: boolean;
  showIndicators?: boolean;
  showThumbnails?: boolean;
  circular?: boolean;
  showIndicatorsOnItem?: boolean;
  autoPlay: boolean;
  itemTemplate?: (item: any) => JSX.Element;
}

const Gallery: React.FC<IGalleryProps> = ({
  imageData,
  classNames,
  showItemNavigators = false,
  showItemNavigatorsOnHover = false,
  showIndicators = false,
  showThumbnails = false,
  circular = false,
  showIndicatorsOnItem = false,
  autoPlay = false,
  itemTemplate,
}) => {
  const [images, setImages] = useState<IFileDetails[]>();

  useEffect(() => {
    const images = imageData;
    setImages(images);
  }, [imageData]);
  return (
    <div className={`reigallary ${classNames}`}>
      {imageData && (
        <Galleria
          className="reigallary"
          value={images}
          showThumbnails={showThumbnails}
          showIndicators={showIndicators}
          circular={circular}
          showItemNavigators={showItemNavigators}
          showItemNavigatorsOnHover={showItemNavigatorsOnHover}
          showIndicatorsOnItem={showIndicatorsOnItem}
          item={itemTemplate}
          autoPlay={autoPlay}
        />
      )}
    </div>
  );
};

export default Gallery;
