import { MyTrackerTabs } from "../../utils/constants";

const PortfolioTrackerHeader: React.FC<{ tabindex: number; tabName: string; children?: any }> = ({
  tabindex,
  tabName,
  children,
}) => {
  return (
    <>
      <div className="flex flex-row w-full ">
        <div className="flex flex-col gap-4 w-1/2 p-4 text-start">
          <div className="text-2xl font-bold">Portfolio Tracker — {tabName}</div>
          <div>
            {tabindex != 4 && tabindex != 7 && (
              <a href="#" className="text-gray-500 hover:underline no-underline text-start" title="Show Print View">
                Print View <i className="pi pi-print "></i>
              </a>
            )}
          </div>
          {tabindex == 1 && (
            <div className="text-sm text-sky-500">
              * Your portfolio contains properties that use different currencies. All values here are shown in AUD.
            </div>
          )}
          {tabindex == 4 && (
            <div className="text-sm ">
              Keep a record of each of your properties here. You can add a new property or import a property from
              Analyser.
            </div>
          )}
        </div>
        {(tabindex == MyTrackerTabs.MyTracker ||
          tabindex == MyTrackerTabs.MyEquity ||
          tabindex == MyTrackerTabs.MyCashFlow) && <div className="w-1/2 p-4">{children}</div>}
      </div>
      {(tabindex == MyTrackerTabs.MyProperties ||
        tabindex == MyTrackerTabs.MyEntitiesGoals ||
        tabindex == MyTrackerTabs.MyPhonebook ||
        tabindex == MyTrackerTabs.ManageProperty) && <div className="flex flex-row">{children}</div>}
    </>
  );
};

export default PortfolioTrackerHeader;
