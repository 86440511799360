import { Card } from "primereact/card";

interface ICardContainerProps {
  children: React.ReactNode;
  className: string;
}

const REICard: React.FC<ICardContainerProps> = ({ className, children }) => (
  <Card className={className}>{children}</Card>
);

export default REICard;
