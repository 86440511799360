import { useEffect, useState } from "react";
import { classNames } from "primereact/utils";
import { REIInputNumber, REIDropDown, REILinkButton, REIInputText } from "../../../../components/ui";
import { COUNTRY, LOCATION_ADVANCED, LOCATION_ADVANCED_AU, LOCATION_ADVANCED_NZ } from "../../../../utils/constants";
import LocalityComponent from "./LocalityComponent";
import { RadioButton } from "primereact/radiobutton";

interface ILocationComponentProps {
  selectedState?: any;
  optionsdata: any;
  selectedDropdownValue: any;
  handleDropdownChange: (e: any, dropdownName: string) => void;
  country: string;
  showMapOptions?: boolean;
}

const LocationComponent: React.FC<ILocationComponentProps> = ({
  selectedState,
  optionsdata,
  selectedDropdownValue,
  handleDropdownChange,
  country,
  showMapOptions = true,
}) => {
  const [postcode, setPostCode] = useState<number | null>(null);
  const [street, setStreet] = useState<string>("");
  const [suburb, setSuburb] = useState<string>("");
  const [advancedLocation, setAdvancedLocation] = useState<number>(1);
  const [showMoreLocation, setShowMoreLocation] = useState<boolean>(false);
  const [suburbClassification, setSuburbClassification] = useState("");

  const toggleMoreLocation = () => {
    setShowMoreLocation(!showMoreLocation);
  };

  const setClassifications = (e) => {
    setSuburbClassification(e.value);
    selectedDropdownValue.locationAdanced.selectedSuburbType = e.value;
  };

  useEffect(() => {
    setAdvancedLocation(selectedDropdownValue?.locationSearchType);
    switch (selectedDropdownValue?.locationSearchType) {
      case LOCATION_ADVANCED.POSTCODE_LIMIT:
        setPostCode(selectedDropdownValue?.locationAdanced?.limitPostcode);
        break;
      case LOCATION_ADVANCED.POSTCODE_RADIUS:
        selectedDropdownValue.postcodeRadiusFrom = parseInt(selectedDropdownValue?.locationAdanced?.postcodeRadiusFrom);
        selectedDropdownValue.postcodeRadiusTo = parseInt(selectedDropdownValue?.locationAdanced?.postcodeRadiusTo);
        setPostCode(selectedDropdownValue?.locationAdanced?.ofPostcode);
        break;
      case LOCATION_ADVANCED.SUBURB_TYPE:
        setSuburbClassification(selectedDropdownValue?.locationAdanced?.selectedSuburbType);
        break;
      default:
        const locationSearchType = selectedDropdownValue?.locationSearchType;
        const locationAdanced = selectedDropdownValue?.locationAdanced;
        if (locationSearchType === LOCATION_ADVANCED.ADDRESS_LIMIT || locationAdanced?.limitStreet !== "") {
          setAdvancedLocation(LOCATION_ADVANCED.ADDRESS_LIMIT);
          setStreet(locationAdanced?.limitStreet);
        } else if (
          locationSearchType === LOCATION_ADVANCED.SUBURB_LIMIT ||
          (locationAdanced?.limitSuburb !== "" && country === COUNTRY.AU)
        ) {
          setAdvancedLocation(LOCATION_ADVANCED.SUBURB_LIMIT);
          setSuburb(locationAdanced?.limitSuburb);
        } else if (
          locationSearchType === LOCATION_ADVANCED.SUBURB_LIMIT ||
          (locationAdanced?.suburbRadiusFrom !== "" &&
            locationAdanced?.suburbRadiusTo !== 0 &&
            locationAdanced?.OflimitSuburb != null &&
            country !== COUNTRY.AU)
        ) {
          setAdvancedLocation(LOCATION_ADVANCED.SUBURB_LIMIT);
          selectedDropdownValue.suburbRadiusFrom = parseInt(locationAdanced?.suburbRadiusFrom);
          selectedDropdownValue.suburbRadiusTo = parseInt(locationAdanced?.suburbRadiusTo);
          setSuburb(locationAdanced?.OflimitSuburb);
        }
        break;
    }
  }, [selectedDropdownValue?.locationSearchType]);

  return (
    <>
      <LocalityComponent
        selectedState={selectedState}
        optionsdata={optionsdata}
        country={country}
        selectedDropdownValue={selectedDropdownValue}
      />
      {showMoreLocation && (
        <>
          <div className="flex flex-col xl:flex-row lg:w-full gap-1">
            <div className="flex-0 xl:w-1/2 w-full">
              <label className="block text-sm font-medium">Location Advanced</label>
              <REIDropDown
                value={advancedLocation}
                onSelectionChange={(e) => {
                  setAdvancedLocation(e.value);
                  selectedDropdownValue.locationSearchType = e.value;
                }}
                data={country === COUNTRY.AU ? LOCATION_ADVANCED_AU : LOCATION_ADVANCED_NZ}
                label="name"
                optionValue="code"
                placeholder="Limit postcode"
              />
            </div>
            {(advancedLocation == 0 || advancedLocation == LOCATION_ADVANCED.POSTCODE_LIMIT) && (
              <div className="flex-0 xl:w-1/2 w-full">
                <label className="block text-sm font-medium">
                  Postcode
                  <REILinkButton
                    href="http://www1.auspost.com.au/postcodes/"
                    className="no-underline"
                    target="_blank"
                    rel=""
                  >
                    <span className="ml-1">(Postcode Search)</span>
                  </REILinkButton>
                </label>
                <REIInputNumber
                  value={postcode}
                  onValueChange={(e) => {
                    setPostCode(e.value);
                    if (selectedDropdownValue?.locationAdanced) {
                      selectedDropdownValue.locationAdanced.limitPostcode = e.value;
                    }
                  }}
                  useGrouping={false}
                  className="p-inputtext-sm w-full"
                />
              </div>
            )}
            {advancedLocation > 0 && advancedLocation == LOCATION_ADVANCED.ADDRESS_LIMIT && (
              <div className="flex-0 xl:w-1/2 w-full">
                <label className="block text-sm font-medium">Street Name</label>
                <REIInputText
                  type="text"
                  value={street}
                  name="streetname"
                  onChange={(e) => {
                    setStreet(e.target.value);
                    if (selectedDropdownValue?.locationAdanced) {
                      selectedDropdownValue.locationAdanced.limitStreet = e.target.value;
                    }
                  }}
                  className="w-full p-inputtext-sm"
                />
              </div>
            )}
            {advancedLocation > 0 && advancedLocation == LOCATION_ADVANCED.SUBURB_LIMIT && country === COUNTRY.AU && (
              <div className="flex-0 lg:w-1/2 w-full">
                <label className="block text-sm font-medium">Suburb</label>
                <REIInputText
                  type="text"
                  value={suburb}
                  name="limitsuburb"
                  onChange={(e) => {
                    setSuburb(e.target.value);
                    if (selectedDropdownValue?.locationAdanced) {
                      selectedDropdownValue.locationAdanced.limitSuburb = e.target.value;
                    }
                  }}
                  className="w-full p-inputtext-sm"
                />
              </div>
            )}
            {advancedLocation > 0 && advancedLocation === LOCATION_ADVANCED.SUBURB_LIMIT && country !== COUNTRY.AU && (
              <>
                <div className="w-full">
                  <div className="flex flex-col lg:w-full gap-1">
                    <div className="flex-0 w-full">
                      <div className="flex flex-col lg:flex-row lg:w-full gap-1">
                        <div className="flex-0 w-full">
                          <label className="block text-sm font-medium">Within radius</label>
                        </div>
                      </div>
                      <div className="flex flex-col lg:flex-row lg:w-full gap-1">
                        <div className="flex-0 w-full">
                          <REIDropDown
                            value={
                              selectedDropdownValue?.suburbRadiusFrom ||
                              optionsdata?.LocationAdvanced?.SuburbRadius?.SuburbRadiusFrom[0].SearchRadius_From_ID
                            }
                            onSelectionChange={(e) => handleDropdownChange(e, "suburbRadiusFrom")}
                            data={optionsdata?.LocationAdvanced?.SuburbRadius?.SuburbRadiusFrom}
                            label="Label"
                            optionValue="SearchRadius_From_ID"
                            className="w-full"
                          />
                        </div>
                        <div className="flex-0 lg:w-[5%] lg:mt-3 w-full">
                          <span className="m-1">to</span>
                        </div>
                        <div className="flex-0 w-full">
                          <REIDropDown
                            value={
                              selectedDropdownValue?.suburbRadiusTo ||
                              optionsdata?.LocationAdvanced?.SuburbRadius?.SuburbRadiusTo[0].SearchRadius_To_ID
                            }
                            onSelectionChange={(e) => handleDropdownChange(e, "suburbRadiusTo")}
                            data={optionsdata?.LocationAdvanced?.SuburbRadius?.SuburbRadiusTo}
                            label="Label"
                            optionValue="SearchRadius_To_ID"
                            className="w-full"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col lg:flex-row lg:w-full gap-1">
                      <div className="flex-0 w-full">
                        <label className="block text-sm font-medium">Of selected suburb</label>
                      </div>
                    </div>
                    <div className="flex-0 w-full">
                      <REIInputText
                        type="text"
                        value={suburb}
                        name="suburbname"
                        onChange={(e) => {
                          setSuburb(e.target.value);
                          if (selectedDropdownValue?.locationAdanced) {
                            selectedDropdownValue.locationAdanced.OflimitSuburb = e.target.value;
                          }
                        }}
                        className="w-full p-inputtext-sm"
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
            {advancedLocation > 0 && advancedLocation === LOCATION_ADVANCED.POSTCODE_RADIUS && (
              <div className="w-full">
                <div className="flex flex-col lg:w-full gap-1">
                  <div className="flex-0 w-full">
                    <div className="flex flex-col lg:flex-row lg:w-full gap-1">
                      <div className="flex-0 w-full">
                        <label className="block text-sm font-medium">Postcode radius</label>
                      </div>
                    </div>
                    <div className="flex flex-col lg:flex-row lg:w-full gap-1">
                      <div className="flex-0 w-full">
                        <REIDropDown
                          value={
                            selectedDropdownValue?.postcodeRadiusFrom ||
                            optionsdata?.LocationAdvanced?.PostcodeRadius?.PostcodeRadiusFrom[0].PostcodeRadius_From_ID
                          }
                          onSelectionChange={(e) => handleDropdownChange(e, "postcodeRadiusFrom")}
                          data={optionsdata?.LocationAdvanced?.PostcodeRadius?.PostcodeRadiusFrom}
                          label="Label"
                          optionValue="PostcodeRadius_From_ID"
                          className="w-full"
                        />
                      </div>
                      <div className="flex-0 w-[7%] lg:pt-2">
                        <span>to</span>
                      </div>
                      <div className="flex-0 w-full">
                        <REIDropDown
                          value={
                            selectedDropdownValue?.postcodeRadiusTo ||
                            optionsdata?.LocationAdvanced?.PostcodeRadius?.PostcodeRadiusTo[0].PostcodeRadius_To_ID
                          }
                          onSelectionChange={(e) => handleDropdownChange(e, "postcodeRadiusTo")}
                          data={optionsdata?.LocationAdvanced?.PostcodeRadius?.PostcodeRadiusTo}
                          label="Label"
                          optionValue="PostcodeRadius_To_ID"
                          className="w-full"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col lg:flex-row lg:w-full gap-1">
                    <div className="flex-0 w-full">
                      <label className="block text-sm font-medium">
                        Of postcode
                        <REILinkButton
                          href="http://www1.auspost.com.au/postcodes/"
                          className="no-underline"
                          target="_blank"
                          rel=""
                        >
                          <span className="ml-1">(Postcode Search)</span>
                        </REILinkButton>
                      </label>
                    </div>
                  </div>
                  <div className="flex-0 w-full">
                    <REIInputNumber
                      value={postcode}
                      onValueChange={(e) => {
                        setPostCode(e.value);
                        if (selectedDropdownValue?.locationAdanced) {
                          selectedDropdownValue.locationAdanced.ofPostcode = e.value;
                        }
                      }}
                      useGrouping={false}
                      className="p-inputtext-sm w-full"
                    />
                  </div>
                </div>
              </div>
            )}
            {advancedLocation > 0 && advancedLocation === LOCATION_ADVANCED.SUBURB_TYPE && (
              <>
                <div className="flex-0 lg:w-1/2 w-full">
                  <label className="block text-sm font-medium">Choose classification</label>
                  <div className="flex flex-wrap gap-3 mt-2 ml-1">
                    <div className="flex align-items-center">
                      <RadioButton
                        inputId="suburbmining"
                        name="suburbclassification"
                        value="mining"
                        onChange={(e) => setClassifications(e)}
                        checked={suburbClassification === "mining"}
                      />
                      <label htmlFor="suburbmining" className="ml-2">
                        Mining
                      </label>
                    </div>
                    <div className="flex align-items-center">
                      <RadioButton
                        inputId="suburbwaterfront"
                        name="suburbclassification"
                        value="coastal"
                        onChange={(e) => setClassifications(e)}
                        checked={suburbClassification === "coastal"}
                      />
                      <label htmlFor="suburbwaterfront" className="ml-2">
                        Waterfront
                      </label>
                    </div>
                    <div className="flex align-items-center">
                      <RadioButton
                        inputId="suburbuniversity"
                        name="suburbclassification"
                        value="universities"
                        onChange={(e) => setClassifications(e)}
                        checked={suburbClassification === "universities"}
                      />
                      <label htmlFor="suburbuniversity" className="ml-2">
                        University
                      </label>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      )}
      <div className="col-span-12 text-end mt-2 text-blue-700">
        <span className="mr-1 pt-2 cursor-pointer" onClick={toggleMoreLocation}>
          {showMoreLocation ? "Fewer" : "More"} location options
        </span>
        <i
          className={classNames("pi cursor-pointer", showMoreLocation ? "pi-angle-up" : "pi-angle-down")}
          onClick={toggleMoreLocation}
        ></i>
      </div>
    </>
  );
};

export default LocationComponent;
