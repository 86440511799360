import React, { useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Messages } from "primereact/messages";
import { Calendar } from "primereact/calendar";
import { REIButton, REIDialoge, REIDropDown, REIInputText } from "../../../components/ui";
import { formatCurrency, formatDate, getDateStringWithGivenFormat } from "../../../utils/commonUtil";
import { httpPost } from "../../../services/httpService";
import { ApiEndPoints } from "../../../utils/endpoints";
import { MEMBER_KEY, USER_DETAIL } from "../../../utils/constants";

const initialExpenses = [
  {
    id: 1,
    group: "Capital Improvements at purchase",
    date: null,
    amount: null as number | null,
    mortgage: 0,
    notes: "",
    isNew: false,
    address: "",
    trackerId: 0,
  },
  {
    id: 2,
    group: "Solicitors conveyancing costs",
    date: null,
    amount: null as number | null,
    mortgage: 0,
    notes: "",
    isNew: false,
    address: "",
    trackerId: 0,
  },
  {
    id: 3,
    group: "Stamp duty",
    date: null,
    amount: null as number | null,
    mortgage: 0,
    notes: "",
    isNew: false,
    address: "",
    trackerId: 0,
  },
  {
    id: 4,
    group: "Other purchase expenses",
    date: null,
    amount: null as number | null,
    mortgage: 0,
    notes: "",
    isNew: false,
    address: "",
    trackerId: 0,
  },
  {
    id: 5,
    group: "Loan establishment fees",
    date: null,
    amount: null as number | null,
    mortgage: 0,
    notes: "",
    isNew: false,
    address: "",
    trackerId: 0,
    loanId: 0,
  },
  {
    id: 6,
    group: "Valuation fees",
    date: null,
    amount: null as number | null,
    mortgage: 0,
    notes: "",
    isNew: false,
    address: "",
    trackerId: 0,
    loanId: 0,
  },
  {
    id: 7,
    group: "Other loan costs",
    date: null,
    amount: null as number | null,
    mortgage: 0,
    notes: "",
    isNew: false,
    address: "",
    trackerId: 0,
    loanId: 0,
  },
];

const EditPurchaseCost: React.FC<{
  cashFlowData: any;
  setIsEditable: (isEditable: boolean) => void;
}> = ({ cashFlowData, setIsEditable }) => {
  const toastRef = useRef<Messages>(null);
  const [selectedProperty, setSelectedProperty] = useState("");
  const [expenses, setExpenses] = useState(initialExpenses);
  const [newExpenses, setNewExpenses] = useState<any[]>([]);
  const [idCounter, setIdCounter] = useState(8);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [mortgageOptions, setMortgageOptions] = useState<any[]>([]);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);
  const memberId = localStorage.getItem(MEMBER_KEY);
  const memberDetail = JSON.parse(localStorage.getItem(USER_DETAIL) || "{}");

  const propertyOptions = cashFlowData.PropertyDetails.map(({ PropertyDetail }: any) => ({
    label: [PropertyDetail.StreetAddress, PropertyDetail.Suburb, PropertyDetail.State, PropertyDetail.Postcode]
      .filter(Boolean)
      .join(", "),
    value: PropertyDetail.PortfolioTrackerId,
  }));

  const hideDefaultProfile = () => {
    setNewExpenses([]);
    setShowConfirmationDialog(false);
  };

  const onPropertyChange = (e: any) => {
    setSelectedProperty(e.value);
    const loans = cashFlowData.PropertyDetails.find((x: any) => x.PropertyDetail.PortfolioTrackerId == e.value).Details
      ?.CashflowLoans.Actuals;
    console.log(loans);
    if (loans) {
      setMortgageOptions(
        loans[0].map((l: any) => ({
          label: l.Name,
          value: l.LoanId,
        }))
      );
    }
  };

  const validateExpenses = (id: number) => {
    const errors: string[] = [];
    setCurrentIndex(id);
    const expense = expenses.find((e) => e.id === id);

    if (selectedProperty === "") {
      errors.push("Please select a property");
    }

    if (!expense?.date) {
      errors.push(`${expense?.group} date is required.`);
    }

    if (expense?.date && (expense?.date as Date) >= new Date()) {
      errors.push(
        `${expense?.group} Date is in the future. Please enter a date between the start of the current financial year and today.`
      );
    }

    if (!expense?.amount) {
      errors.push(`${expense?.group} amount is required.`);
    }

    if (errors.length > 0) {
      errors.forEach((err) => toastRef.current?.show({ severity: "error", summary: "Error", detail: err, life: 3000 }));
    }
    setValidationErrors(errors);

    return errors.length === 0;
  };

  const addRow = (group: string, id: number) => {
    if (!validateExpenses(id)) return;

    setExpenses((prevExpenses) => {
      const index = prevExpenses.findIndex((e) => e.id === id);
      const newRow = {
        id: idCounter,
        group,
        date: prevExpenses[index]?.date || null,
        amount: prevExpenses[index]?.amount || null,
        mortgage: prevExpenses[index]?.mortgage || 0,
        notes: prevExpenses[index]?.notes || "",
        isNew: true,
        address: propertyOptions.find((p: any) => p.value === parseInt(selectedProperty))?.label || "",
        trackerId: parseInt(selectedProperty),
      };

      const updatedExpenses = [...prevExpenses];
      updatedExpenses[index] = {
        ...prevExpenses[index],
        date: null,
        amount: null,
        mortgage: 0,
        notes: "",
        trackerId: 0,
      };
      updatedExpenses.splice(index + 1, 0, newRow);

      return updatedExpenses;
    });

    setIdCounter((prev) => prev + 1);
  };

  const removeRow = (id: number) => setExpenses(expenses.filter((e) => e.id !== id));

  const removeSummaryRow = (id: number) => {
    setExpenses(expenses.filter((e) => e.id !== id));
    setNewExpenses(newExpenses.filter((e) => e.id !== id));
  };

  const renderDateCell = (rowData: any) => (
    <Calendar
      value={rowData.date}
      onChange={(e) => (rowData.date = e.value)}
      showIcon
      disabled={rowData.isNew}
      dateFormat="dd/mm/yy"
      readOnlyInput
      className={
        !rowData.date && rowData.id === currentIndex && validationErrors.length ? "w-full p-invalid" : "w-full"
      }
    />
  );

  const renderGroupCell = (rowData: any) => (!rowData.isNew ? <span>{rowData.group}</span> : "");

  const renderAmountCell = (rowData: any) =>
    !rowData.isNew ? (
      <REIInputText
        value={rowData.amount === null ? "" : rowData.amount}
        onChange={(e) => {
          const updatedValue = e.target.value === "" ? null : parseFloat(e.target.value) || 0;
          setExpenses((prevExpenses) =>
            prevExpenses.map((expense) => (expense.id === rowData.id ? { ...expense, amount: updatedValue } : expense))
          );
        }}
        type="number"
        name="amount"
        className={
          rowData.amount === null && rowData.id === currentIndex && validationErrors.length
            ? "w-full p-invalid"
            : "w-full"
        }
      />
    ) : (
      <span>{rowData.amount === null ? "" : formatCurrency(rowData.amount)}</span>
    );

  const renderMorgageCell = (rowData: any) => {
    return !rowData.isNew && rowData.id > 4 ? (
      <div className="w-full">
        <REIDropDown
          key={`property-${rowData.mortgage}`}
          value={rowData.mortgage}
          data={mortgageOptions}
          label="label"
          onSelectionChange={(e) =>
            setExpenses((prevExpenses) =>
              prevExpenses.map((expense) => (expense.id === rowData.id ? { ...expense, mortgage: e.value } : expense))
            )
          }
          placeholder="Select loan"
          className="w-full"
        />
      </div>
    ) : (
      <div className="w-full"></div>
    );
  };

  const renderNoteCell = (rowData: any) =>
    !rowData.isNew ? (
      <REIInputText
        value={rowData.notes}
        name="notes"
        type="text"
        onChange={(e) => {
          const updatedExpenses = expenses.map((exp) =>
            exp.id === rowData.id ? { ...exp, notes: e.target.value } : exp
          );
          setExpenses(updatedExpenses);
        }}
      />
    ) : (
      <div className="w-full">{rowData.notes}</div>
    );

  const renderActionCell = (rowData: any) =>
    rowData.isNew ? (
      <REIButton
        icon="pi pi-minus"
        classNames="p-button-text"
        onClick={() => {
          removeRow(rowData.id);
        }}
      />
    ) : (
      <REIButton
        icon="pi pi-plus"
        classNames="p-button-text"
        onClick={() => {
          addRow(rowData.group, rowData.id);
        }}
      />
    );

  const renderActionCellSummary = (rowData: any) => (
    <REIButton
      icon="pi pi-minus"
      classNames="p-button-text"
      onClick={() => {
        removeSummaryRow(rowData.id);
      }}
    />
  );

  const renderPurchaseDate = (rowData: any) => {
    return getDateStringWithGivenFormat(new Date(rowData.date), "dd/mm/yy");
  };

  const savePurchaseCost = () => {
    setNewExpenses(expenses.filter((e) => e.isNew));
    setShowConfirmationDialog(true);
  };

  const savePurchaseCostToDB = async () => {
    const newExpensesToSave = newExpenses.map((e) => ({
      Amount: e.amount,
      Date: formatDate(e.date),
      Description: e.notes,
      LoanId: e.mortgage,
      PortfolioTrackerId: e.trackerId,
      PurchaseCostItemType: getPurchasedCostItemType(e.group),
    }));

    const payload = {
      MemberId: memberId,
      MemberCountryCode: memberDetail.Country,
      Actuals: [],
      PurchaseCosts: newExpensesToSave,
    };

    await httpPost(ApiEndPoints.tracker + "PortfolioTracker/ActualsByBatch/", payload).then((response) => {
      if (response?.error) {
        toastRef.current?.show({
          severity: "error",
          summary: "Error Message",
          detail: "Error while saving purchase cost.",
          life: 3000,
        });
      } else {
        toastRef?.current?.show({
          severity: "success",
          summary: "Success Message",
          detail: "Purchase cost saved successfully.",
          life: 2000,
        });
        hideDefaultProfile();
        setIsEditable(false);
      }
    });
  };

  const getPurchasedCostItemType = (group: string) => {
    switch (group) {
      case "Capital Improvements at purchase":
        return "CashflowPurchaseCosts.Improvements";
      case "Solicitors conveyancing costs":
        return "CashflowPurchaseCosts.Solicitor";
      case "Stamp duty":
        return "CashflowPurchaseCosts.StampDuty";
      case "Other purchase expenses":
        return "CashflowMortgage.OtherCosts";
      case "Loan establishment fees":
        return "CashflowMortgage.EstablishmentFees";
      case "Valuation fees":
        return "CashflowMortgage.ValuationFees";
      case "Other loan costs":
        return "CashflowMortgage.OtherLoanCosts";
      default:
        return "";
    }
  };

  const renderTable = () => {
    return (
      <>
        <div className="flex flex-row w-full">
          Please check the following updates you've added before adding these to each of your properties.
        </div>
        <div className="flex flex-row w-full">
          <DataTable
            value={newExpenses}
            rowGroupMode="rowspan"
            groupRowsBy="address"
            sortMode="single"
            sortField="address"
            className="p-datatable-striped w-full"
            size="small"
          >
            <Column field="address" header="Address" />
            <Column field="group" header="Item" />
            <Column field="date" header="Date (dd/mm/yy)" body={renderPurchaseDate} />
            <Column field="amount" header="Amount" />
            <Column field="notes" header="Notes" />
            <Column header="Action" body={renderActionCellSummary} />
          </DataTable>
        </div>
        <div className="flex justify-end space-x-2 mt-4">
          <REIButton
            label="Go back and edit"
            icon="pi pi-times"
            classNames="p-button-secondary"
            onClick={() => {
              setShowConfirmationDialog(false);
            }}
          />
          <REIButton label="Save" icon="pi pi-check" classNames="p-button-success" onClick={savePurchaseCostToDB} />
        </div>
      </>
    );
  };

  return (
    <>
      <REIDialoge
        showHeader={true}
        header="Review and confirm your actuals updates"
        visible={showConfirmationDialog}
        handleEditClick={hideDefaultProfile}
        position="top"
        customClass="edit-default-profile w-[60%]"
        contentComponent={<div>{renderTable()}</div>}
      ></REIDialoge>
      <div className="flex flex-col">
        <div className="flex flex-row flex-wrap mb-2">
          <label className="self-center mr-3" htmlFor="property">
            Property address:
          </label>
          <REIDropDown
            key={`property-${selectedProperty}`}
            value={selectedProperty}
            data={propertyOptions}
            label="label"
            onSelectionChange={(e) => onPropertyChange(e)}
            placeholder="Select Property"
            className="w-min"
          />
        </div>

        <div className="mb-4">
          <Messages ref={toastRef} />
        </div>

        <DataTable
          value={expenses.filter((x) => x.trackerId == 0 || x.trackerId == parseInt(selectedProperty))}
          className="p-datatable-striped"
          size="small"
        >
          <Column field="group" header="" body={renderGroupCell} />
          <Column field="date" header="Date (dd/mm/yy)" body={renderDateCell} />
          <Column field="amount" header="Amount" body={renderAmountCell} />
          <Column field="mortgage" header="Mortgage" body={renderMorgageCell} />
          <Column field="notes" header="Notes" body={renderNoteCell} />
          <Column header="Action" body={renderActionCell} />
        </DataTable>
      </div>
      <div className="footerNote text-start mt-4">
        Please note that transactions manually entered will not appear within Xero. When using Xero, always add your
        transactions in Xero, and then Sync to Portfolio Tracker. Sync is one way only, Xero to Portfolio Tracker.
      </div>

      <div className="flex justify-end space-x-2 mt-4">
        <REIButton
          label="Cancel without saving"
          icon="pi pi-times"
          classNames="p-button-secondary"
          onClick={() => {
            setIsEditable(false);
          }}
        />
        <REIButton label="Save" icon="pi pi-check" classNames="p-button-success" onClick={savePurchaseCost} />
      </div>
    </>
  );
};

export default EditPurchaseCost;
