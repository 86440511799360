import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Graph from "../../../../components/ui/graph";
import { IREIGraphDataSet } from "../../../../interface/graph";
import { sortObjectByNumericKeys, formatCurrencyWithDecimal } from "../../../../utils/commonUtil";
import { IAnalysisSummaryResponse } from "../../../../interface/analysisSummary";

const PreTaxVsPostTax = () => {
  const analysisData: IAnalysisSummaryResponse = useSelector((state) => state.analyser.analysisSummary);
  const equityInProperty: Number[] = [];
  let preTaxData: Number[] = [];
  let postTaxData: Number[] = [];
  const [chartData, setChartData] = useState([] as IREIGraphDataSet[]);
  const [chartOptions, setChartOptions] = useState({});

  const extractData = () => {
    preTaxData = Object.entries(sortObjectByNumericKeys(analysisData.AnalysisCashDeduction)).reduce(
      (acc, [key, value]) => {
        if (key.startsWith("PreTaxCashflowpaYr") && !key.includes("Today")) {
          acc.preTaxData.push(value);
        }
        return acc;
      },
      { preTaxData: [] }
    ).preTaxData;

    postTaxData = Object.entries(sortObjectByNumericKeys(analysisData.AnalysisSummary)).reduce(
      (acc, [key, value]) => {
        if (key.startsWith("PostTaxCashflowPAYr") && !key.includes("Today")) {
          acc.postTaxData.push(value);
        }
        return acc;
      },
      { postTaxData: [] }
    ).postTaxData;
  };

  useEffect(() => {
    extractData();

    const options = {
      maintainAspectRatio: true,
      aspectRatio: 3.8,
      plugins: {
        legend: {
          display: true,
          position: "right",
        },
        tooltip: {
          title: "",
          callbacks: {
            label: (tooltipItem) => {
              const Tooltip = `${tooltipItem.dataset.label} : ${formatCurrencyWithDecimal(
                tooltipItem.dataset.data[tooltipItem.dataIndex]
              )}`;
              return Tooltip;
            },
          },
          multiLine: true,
        },
      },
      scales: {
        y: {
          ticks: {
            callback: function (value) {
              return "$" + value;
            },
          },
          grid: {
            display: false,
          },
          beginAtZero: true,
        },
        x: {
          grid: {
            display: false,
          },
        },
      },
    };

    const data = [
      {
        label: "Pre-tax",
        extraData: equityInProperty,
        data: preTaxData,
        backgroundColor: " rgb(250, 97, 97)",
      },
      {
        label: "After-tax",
        extraData: equityInProperty,
        data: postTaxData,
        backgroundColor: " rgb(75, 192, 192)",
      },
    ];

    setChartData(data);
    setChartOptions(options);
  }, []);

  return (
    <>
      <Graph
        graphType="line"
        dataSet={chartData}
        labels={["yr1", "yr2", "yr3", "yr4", "yr5", "yr6", "yr7", "yr8", "yr9", "yr10"]}
        customOptions={chartOptions}
      ></Graph>
    </>
  );
};

export default PreTaxVsPostTax;
