import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { formatCurrency, formatNumberWithDecimal } from "../../../utils/commonUtil";
import { REIButton } from "../../../components/ui";
import { Row } from "primereact/row";
import { ColumnGroup } from "primereact/columngroup";
import EditPurchaseCost from "./EditPurchaseCost";

interface IPurchaseCost {
  street: string;
  suburb: string;
  state: string;
  postcode: string;
  country: string;
  type: string;
  capitalImprovementPurchasePrice: number;
  solicitorPurchasePrice: number;
  stampDutyPurchasePrice: number;
  otherPurchasePrice: number;
  loanEstablishmentPurchasePrice: number;
  valualtionPurchasePrice: number;
  otherLoanPurchasePrice: number;
}

interface IPurchaseCostSummary {
  type: string;
  capitalImprovementPurchasePrice: number;
  solicitorPurchasePrice: number;
  stampDutyPurchasePrice: number;
  otherPurchasePrice: number;
  loanEstablishmentPurchasePrice: number;
  valualtionPurchasePrice: number;
  otherLoanPurchasePrice: number;
}

const TrackerPurchaseCost: React.FC<{
  cashFlowData: any;
  isForecastOnly: boolean;
  year: string;
  isEditable: boolean;
  setIsEditable: (isEditable: boolean) => void;
}> = ({ cashFlowData, isForecastOnly, year, isEditable, setIsEditable }) => {
  const [transformedData, setTransformedData] = useState<IPurchaseCost[]>([]);
  const [transformedFooter, setTransformedFooter] = useState<IPurchaseCostSummary[]>([]);
  const [collapsedRows, setCollapsedRows] = useState<Record<string, boolean>>({});

  useEffect(() => {
    if (cashFlowData && Array.isArray(cashFlowData.PropertyDetails) && cashFlowData.PropertyDetails.length > 0) {
      const transformed = cashFlowData.PropertyDetails.map((properties: any) => {
        const actual =
          properties.Details.CashflowPurchaseCostPortion?.Actuals?.find((item: any) => item.Year === year) || {};
        const forecast =
          properties.Details.CashflowPurchaseCostPortion?.Forecast?.find((item: any) => item.Year === year) || {};
        const variance =
          properties.Details.CashflowPurchaseCostPortion?.Variance?.find((item: any) => item.Year === year) || {};

        return [
          { type: "Actuals", data: actual },
          { type: "Forecast", data: forecast },
          { type: "Variance", data: variance },
        ].map(({ type, data }) => ({
          street: properties.PropertyDetail?.StreetAddress || "",
          suburb: properties.PropertyDetail?.Suburb || "",
          state: properties.PropertyDetail?.State || "",
          postcode: properties.PropertyDetail?.Postcode || "",
          country: properties.PropertyDetail?.Country || "",
          type,
          capitalImprovementPurchasePrice: Number(data.Cost_CapitalImprovements) || 0,
          solicitorPurchasePrice: Number(data.Cost_SolicitorFees) || 0,
          stampDutyPurchasePrice: Number(data.Cost_StampDuty) || 0,
          otherPurchasePrice: Number(data.Cost_OtherCosts) || 0,
          loanEstablishmentPurchasePrice: Number(data.Loan_EstablishmentFees) || 0,
          valualtionPurchasePrice: Number(data.Cost_ValuationFees) || 0,
          otherLoanPurchasePrice: Number(data.Loan_OtherCost) || 0,
        }));
      }).flat();

      setTransformedData(transformed);

      const footerProperties = cashFlowData.TotalForDetails.find((item: any) => item.EntityId === "") || {};
      const actualFooter =
        footerProperties.CashflowDetailsTotals.PurchaseCost?.Actuals?.find((item: any) => item.Year === year) || {};
      const forecastFooter =
        footerProperties.CashflowDetailsTotals.PurchaseCost?.Forecast?.find((item: any) => item.Year === year) || {};
      const varianceFooter =
        footerProperties.CashflowDetailsTotals.PurchaseCost?.Variance?.find((item: any) => item.Year === year) || {};

      const footer = [
        {
          type: "Actuals",
          capitalImprovementPurchasePrice: Number(actualFooter.Cost_CapitalImprovements) || 0,
          solicitorPurchasePrice: Number(actualFooter.Cost_SolicitorFees) || 0,
          stampDutyPurchasePrice: Number(actualFooter.Cost_StampDuty) || 0,
          otherPurchasePrice: Number(actualFooter.Cost_OtherCosts) || 0,
          loanEstablishmentPurchasePrice: Number(actualFooter.Loan_EstablishmentFees) || 0,
          valualtionPurchasePrice: Number(actualFooter.Cost_ValuationFees) || 0,
          otherLoanPurchasePrice: Number(actualFooter.Loan_OtherCost) || 0,
        },
        {
          type: "Forecast",
          capitalImprovementPurchasePrice: Number(forecastFooter.Cost_CapitalImprovements) || 0,
          solicitorPurchasePrice: Number(forecastFooter.Cost_SolicitorFees) || 0,
          stampDutyPurchasePrice: Number(forecastFooter.Cost_StampDuty) || 0,
          otherPurchasePrice: Number(forecastFooter.Cost_OtherCosts) || 0,
          loanEstablishmentPurchasePrice: Number(forecastFooter.Loan_EstablishmentFees) || 0,
          valualtionPurchasePrice: Number(forecastFooter.Cost_ValuationFees) || 0,
          otherLoanPurchasePrice: Number(forecastFooter.Loan_OtherCost) || 0,
        },
        {
          type: "Variance",
          capitalImprovementPurchasePrice: Number(varianceFooter.Cost_CapitalImprovements) || 0,
          solicitorPurchasePrice: Number(varianceFooter.Cost_SolicitorFees) || 0,
          stampDutyPurchasePrice: Number(varianceFooter.Cost_StampDuty) || 0,
          otherPurchasePrice: Number(varianceFooter.Cost_OtherCosts) || 0,
          loanEstablishmentPurchasePrice: Number(varianceFooter.Loan_EstablishmentFees) || 0,
          valualtionPurchasePrice: Number(varianceFooter.Cost_ValuationFees) || 0,
          otherLoanPurchasePrice: Number(varianceFooter.Loan_OtherCost) || 0,
        },
      ];

      setTransformedFooter(footer);
    }
  }, [cashFlowData, year]);

  const currencyTemplate = (value: number) => (
    <span className={value < 0 ? "text-red-600" : ""}>
      {value < 0 ? `(${formatCurrency(-value)})` : formatCurrency(value)}
    </span>
  );

  const percentageTemplate = (value: number) => (
    <span className={value < 0 ? "text-red-600" : ""}>
      {value < 0 ? `(${formatNumberWithDecimal(-value, 2, 2)}%)` : `${formatNumberWithDecimal(value, 2, 2)}%`}
    </span>
  );

  const renderFooter = (category: any, data: IPurchaseCostSummary) =>
    transformedFooter && (
      <Row>
        <Column footer={category == "Forecast" ? "Totals/averages" : ""} />
        <Column field="type" footer={category} />
        <Column footer={currencyTemplate(data?.capitalImprovementPurchasePrice)} footerStyle={{ textAlign: "right" }} />
        <Column footer={currencyTemplate(data?.solicitorPurchasePrice)} footerStyle={{ textAlign: "right" }} />
        <Column footer={currencyTemplate(data?.stampDutyPurchasePrice)} footerStyle={{ textAlign: "right" }} />
        <Column footer={currencyTemplate(data?.otherLoanPurchasePrice)} footerStyle={{ textAlign: "right" }} />
        <Column footer={currencyTemplate(data?.loanEstablishmentPurchasePrice)} footerStyle={{ textAlign: "right" }} />
        <Column footer={currencyTemplate(data?.valualtionPurchasePrice)} footerStyle={{ textAlign: "right" }} />
        <Column footer={currencyTemplate(data?.otherLoanPurchasePrice)} footerStyle={{ textAlign: "right" }} />
      </Row>
    );

  const footerGroup = transformedFooter && (
    <ColumnGroup>
      {renderFooter("Forecast", transformedFooter.find((x) => x.type == "Forecast")!)}
      {isForecastOnly && renderFooter("Actuals", transformedFooter.find((x) => x.type == "Actuals")!)}
      {isForecastOnly && renderFooter("Variance", transformedFooter.find((x) => x.type == "Variance")!)}
    </ColumnGroup>
  );

  const toggleRowCollapse = (street: string) => {
    setCollapsedRows((prev) => ({ ...prev, [street]: !prev[street] }));
  };

  return isEditable ? (
    <EditPurchaseCost cashFlowData={cashFlowData} setIsEditable={setIsEditable} />
  ) : (
    transformedData && transformedFooter && (
      <DataTable
        value={transformedData}
        rowGroupMode="rowspan"
        groupRowsBy="street"
        sortField="street"
        sortMode="single"
        sortOrder={1}
        size="small"
        footerColumnGroup={footerGroup}
      >
        <Column
          field="street"
          header="Address/property name"
          body={(rowData) => (
            <div className="flex items-center">
              {isForecastOnly && (
                <REIButton
                  icon={collapsedRows[rowData.street] ? "pi pi-angle-down" : "pi pi-angle-right"}
                  text={true}
                  onClick={() => toggleRowCollapse(rowData.street)}
                />
              )}
              <span className="cursor-pointer text-blue-700">{`${rowData.street}, ${rowData.suburb}, ${rowData.state}, ${rowData.postcode} (${rowData.country})`}</span>
            </div>
          )}
        />
        <Column
          field="type"
          header="Type"
          body={(rowData) =>
            (!collapsedRows[rowData.street] || rowData.type === "Forecast") &&
            (isForecastOnly || rowData.type === "Forecast")
              ? rowData.type
              : null
          }
        />
        <Column
          field="capitalImprovementPurchasePrice"
          header="Capital improvements at purchase"
          body={(rowData) =>
            (!collapsedRows[rowData.street] || rowData.type === "Forecast") &&
            (isForecastOnly || rowData.type === "Forecast")
              ? currencyTemplate(rowData.capitalImprovementPurchasePrice)
              : null
          }
          align="right"
        />
        <Column
          field="solicitorPurchasePrice"
          header="Solicitor costs"
          body={(rowData) =>
            (!collapsedRows[rowData.street] || rowData.type === "Forecast") &&
            (isForecastOnly || rowData.type === "Forecast")
              ? currencyTemplate(rowData.solicitorPurchasePrice)
              : null
          }
          align="right"
        />
        <Column
          field="stampDutyPurchasePrice"
          header="Stamp duty"
          body={(rowData) =>
            (!collapsedRows[rowData.street] || rowData.type === "Forecast") &&
            (isForecastOnly || rowData.type === "Forecast")
              ? currencyTemplate(rowData.stampDutyPurchasePrice)
              : null
          }
          align="right"
        />
        <Column
          field="otherPurchasePrice"
          header="Other purchase expenses"
          body={(rowData) =>
            (!collapsedRows[rowData.street] || rowData.type === "Forecast") &&
            (isForecastOnly || rowData.type === "Forecast")
              ? currencyTemplate(rowData.otherPurchasePrice)
              : null
          }
          align="right"
        />
        <Column
          field="loanEstablishmentPurchasePrice"
          header="Loan establishment fees"
          body={(rowData) =>
            (!collapsedRows[rowData.street] || rowData.type === "Forecast") &&
            (isForecastOnly || rowData.type === "Forecast")
              ? currencyTemplate(rowData.loanEstablishmentPurchasePrice)
              : null
          }
          align="right"
        />
        <Column
          field="valualtionPurchasePrice"
          header="Valuation fees"
          body={(rowData) =>
            (!collapsedRows[rowData.street] || rowData.type === "Forecast") &&
            (isForecastOnly || rowData.type === "Forecast")
              ? currencyTemplate(rowData.valualtionPurchasePrice)
              : null
          }
          align="right"
        />
        <Column
          field="otherLoanPurchasePrice"
          header="Other loan costs"
          body={(rowData) =>
            (!collapsedRows[rowData.street] || rowData.type === "Forecast") &&
            (isForecastOnly || rowData.type === "Forecast")
              ? currencyTemplate(rowData.otherLoanPurchasePrice)
              : null
          }
          align="right"
        />
      </DataTable>
    )
  );
};

export default TrackerPurchaseCost;
