import { Tooltip } from "primereact/tooltip";
import "../../styles/tooltip.scss";

interface IToolTip {
  position: string;
  targetId: string;
  customClass?: string | null;
  content: any;
}

const REITooltip: React.FC<IToolTip> = ({ targetId, content, customClass = "", position = "top" }) => {
  return (
    <Tooltip
      theme="tooltip-theme"
      target={`.${targetId}`}
      autoHide={false}
      position={position}
      className={`${customClass}`}
    >
      {content}
    </Tooltip>
  );
};

export default REITooltip;
