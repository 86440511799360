import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { httpPost, httpPostFile, useHttpGets } from "../../services/httpService";
import { ApiEndPoints } from "../../utils/endpoints";
import { IFileDetails, IAPIResponse } from "../../interface/files";
import { Status } from "../../utils/constants";

interface IFileState {
  deleteFilesResponse: IAPIResponse;
  uploadResponse: IAPIResponse;
  files: IFileDetails[];
  status: string;
  error: any;
}

interface IDeleteFileParams {
  FileName: string;
  WatchlistID: string;
}

interface IFetchFileParams {
  WatchlistID: string;
  isAttachementData: boolean;
}

const initialState = {
  files: [],
  deleteFilesResponse: {
    data: "",
    error: "",
    status: "",
  },
  uploadResponse: {
    data: "",
    error: "",
    status: "",
  },
  status: "",
  error: "",
};

interface IuploadFile {
  file: ArrayBuffer | string;
  fileData: any;
  memberPropertyId: string;
  isattachement: number;
}

export const fetchFiles = createAsyncThunk("fetchFiles", async (params: IFetchFileParams, { rejectWithValue }) => {
  try {
    const result = await useHttpGets(`${ApiEndPoints.files}/AllFiles/${params.WatchlistID}`, undefined);
    if (result.status === 200) {
      if (params.isAttachementData) {
        return result.data.Attachements.Files;
      } else {
        return result.data.PropertyImages.Files;
      }
    } else {
      return rejectWithValue(result.error);
    }
  } catch (ex) {
    return rejectWithValue(ex.message);
  }
});

export const uploadFile = createAsyncThunk(
  "uploadFile",
  async (file: IuploadFile, { rejectWithValue, fulfillWithValue }) => {
    try {
      const result = await httpPostFile(`${ApiEndPoints.files}Uploads/HtmlClient/`, undefined, undefined, {
        file: file.file,
        memberPropertyId: file.memberPropertyId,
        fileData: file.fileData,
        IsAttachement: file.isattachement,
      });
      if (result.status == 200) {
        return fulfillWithValue(file.file);
      } else {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(result.error, "text/xml");
        const message = xmlDoc.documentElement.textContent;
        return rejectWithValue(message);
      }
    } catch (ex) {
      return rejectWithValue(ex.message);
    }
  }
);

export const deleteFile = createAsyncThunk(
  "deleteFile",
  async (files: IDeleteFileParams, { rejectWithValue, fulfillWithValue }) => {
    const fileNames = [files.FileName];
    try {
      const response = await httpPost(`${ApiEndPoints.files}DeleteFiles`, {
        MemberPropertyId: `${files.WatchlistID}`,
        FileNames: fileNames,
      });
      if (response.error == null) {
        return fulfillWithValue(files.FileName);
      } else {
        return rejectWithValue(response.error);
      }
    } catch (ex) {
      return rejectWithValue(ex.message);
    }
  }
);

const fileSlice = createSlice({
  name: "files",
  initialState,
  reducers: {
    getFileData(state, action: PayloadAction<IFileState>) {
      state.files = action.payload.files;
    },
    deleteFileData(state, action: PayloadAction<IFileState>) {
      state.deleteFilesResponse = action.payload.deleteFilesResponse;
    },
    uploadFileData(state, action: PayloadAction<IFileState>) {
      state.uploadResponse = action.payload.uploadResponse;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFiles.fulfilled, (state, action) => {
      state.files = action.payload;
      state.deleteFilesResponse.status = "";
      state.uploadResponse.status = "";
      state.status = Status.SUCCESS;
      state.error = "";
    });
    builder.addCase(uploadFile.fulfilled, (state, action) => {
      state.uploadResponse.data = action.payload;
      state.uploadResponse.status = Status.SUCCESS;
      state.uploadResponse.error = "";
    });
    builder.addCase(deleteFile.fulfilled, (state, action) => {
      state.deleteFilesResponse.data = action.payload;
      state.deleteFilesResponse.error = "";
      state.deleteFilesResponse.status = Status.SUCCESS;
    });
    builder.addCase(fetchFiles.pending, (state) => {
      state.deleteFilesResponse.status = Status.LOADING;
    });
    builder.addCase(deleteFile.pending, (state) => {
      state.status = Status.LOADING;
    });
    builder.addCase(uploadFile.pending, (state) => {
      state.uploadResponse.status = Status.LOADING;
      state.uploadResponse.data = ";";
    });
    builder.addCase(fetchFiles.rejected, (state, action) => {
      state.status = Status.FAILED;
      state.error = action.error.message;
    });
    builder.addCase(uploadFile.rejected, (state, action) => {
      state.status = Status.FAILED;
      state.uploadResponse.data = "";
      state.uploadResponse.error = action.error.message;
    });
    builder.addCase(deleteFile.rejected, (state, action) => {
      state.deleteFilesResponse.error = action.error.message;
      state.deleteFilesResponse.status = Status.FAILED;
    });
  },
});

export const { getFileData, deleteFileData, uploadFileData } = fileSlice.actions;

export default fileSlice.reducer;
