import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { RadioButton } from "primereact/radiobutton";
import { REIButton, REICheckBox, REIDialoge, REIDropDown } from "../../components/ui";
import { IMemberProfile } from "../../interface/analysed";

export const Filters: React.FC<{
  onPortionChange: (value: boolean) => void;
  onSelectionChange: (entityId: number | undefined, profileId: number | undefined) => void;
}> = ({ onPortionChange, onSelectionChange }) => {
  const userProfile = useSelector((state: RootState) => state.myTracker.profileData);
  const memberEntities = useSelector((state: RootState) => state.myTracker.memberEntities);
  const [selectedEquityOption, setSelectedEquityOption] = useState<string>();
  const [selectedFilter, setSelectedFilter] = useState<string>("entity");
  const [showEntityDialog, setShowEntityDialog] = useState<boolean>();
  const [isProfileSelected, setIsProfileSelected] = useState<boolean>(false);
  const [memberProfileData, setMemberProfileData] = useState<IMemberProfile>();
  const [selectedEntity, setSelectedEntity] = useState<number>();
  const [entityData, setEntityData] = useState<{ entityName: string; value: number }[]>([]);

  useEffect(() => {
    setMemberProfileData((prevstate) => {
      return userProfile[0];
    });
  }, [userProfile]);

  useEffect(() => {
    let entityDropdownData: { entityName: string; value: number }[] = [];

    entityDropdownData.push({
      entityName: "Total",
      value: 0,
    });

    memberEntities.map((data) => {
      entityDropdownData.push({
        entityName: data.EntityName,
        value: data.MemberEntityId,
      });
    });
    setEntityData(entityDropdownData);
    setSelectedEntity(entityDropdownData[0].value);
  }, [memberEntities]);

  const renderContent = () => {
    return (
      <div className="flex flex-col w-auto gap-3">
        <label>You can view your summary statement from the point of view of:</label>
        <div className="flex flex-row content-center items-center gap-2">
          <RadioButton
            inputId="entity"
            name="tracker_dashboard_showing"
            value="entity"
            checked={selectedFilter ? selectedFilter === "entity" : true}
            onChange={(e) => {
              setSelectedFilter(e.value);
            }}
          />
          <label htmlFor="tracker_dashboard_showing" className="ml-2 ">
            Entity:
          </label>
          <REIDropDown
            data={entityData}
            label="entityName"
            onSelectionChange={(e) => {
              setSelectedEntity(e.value);
            }}
            value={selectedEntity}
          ></REIDropDown>
        </div>
        <div className="flex gap-2">
          <RadioButton
            inputId="profile"
            name="tracker_dashboard_showing"
            value="profile"
            checked={selectedFilter === "profile"}
            onChange={(e) => {
              setSelectedFilter(e.value);
            }}
          />
          <label htmlFor="tracker_dashboard_showing" className="ml-2">
            Profile(s):
          </label>
          <REICheckBox
            disabled={selectedFilter === "entity"}
            className="text-sm mr-2 align-middle"
            checked={isProfileSelected}
            onChange={(e) => {
              setIsProfileSelected(e.checked);
            }}
            label={memberProfileData?.MemberName}
          ></REICheckBox>
        </div>
        <div className="flex gap-2">
          <REIButton
            onClick={() => {
              selectedFilter === "entity"
                ? onSelectionChange(selectedEntity, 0)
                : onSelectionChange(0, memberProfileData?.MemberProfileId);
              setShowEntityDialog(false);
            }}
            label="Ok"
          ></REIButton>
          <REIButton
            onClick={() => {
              setShowEntityDialog(false);
            }}
            label="Cancel"
          ></REIButton>
        </div>
      </div>
    );
  };

  return (
    <>
      <REIDialoge
        contentComponent={renderContent()}
        handleEditClick={() => {
          setShowEntityDialog(false);
        }}
        header=""
        showHeader={true}
        visible={showEntityDialog}
      ></REIDialoge>
      <div className="flex flex-col justify-between">
        <div className="flex flex-row justify-between">
          <div className="align-middle">Equity summary for all entities</div>
          <REIButton
            onClick={() => {
              setShowEntityDialog(true);
            }}
            label="Change"
          ></REIButton>
        </div>
        <div className="flex flex-row gap-2">
          <label>Showing : </label>
          <RadioButton
            inputId="totals"
            name="tracker_dashboard_showing"
            value="totals"
            checked={selectedEquityOption ? selectedEquityOption === "totals" : true}
            onChange={(e) => {
              setSelectedEquityOption(e.value);
              onPortionChange(true);
            }}
          />
          <label htmlFor="tracker_dashboard_showing" className="ml-2">
            totals
          </label>
          <RadioButton
            inputId="portions"
            name="tracker_dashboard_showing"
            value="portions"
            checked={selectedEquityOption === "portions"}
            onChange={(e) => {
              setSelectedEquityOption(e.value);
              onPortionChange(false);
            }}
          />
          <label htmlFor="tracker_dashboard_showing" className="ml-2">
            this entity's portion
          </label>
        </div>
      </div>
    </>
  );
};
