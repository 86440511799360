import React, { useMemo, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ICashFlow, IPropertyDetail, ILoans, ILoanEntry } from "../../../../interface/cashFlow";
import { formatCurrency, formatNumberWithDecimal, nth } from "../../../../utils/commonUtil";
import { REIButton } from "../../../../components/ui";
import EditLoan from "./EditLoan";

interface LoanProps {
  cashFlowData: ICashFlow;
  year: string;
  isForecastOnly: boolean;
  isEditable: boolean;
}

const Loan: React.FC<LoanProps> = ({ cashFlowData, year, isForecastOnly, isEditable }) => {
  const [collapsedRows, setCollapsedRows] = useState<Record<string, boolean>>({});

  const TransformLoanData = (data: IPropertyDetail[], year: string) => {
    let resultData: any[] = [];

    data.forEach((property) => {
      const actualData = property.Details.CashflowLoans?.Actuals.flat().filter((data) => data.Year == year) || [];
      const forecastData = property.Details.CashflowLoans?.Forecast.flat().filter((data) => data.Year == year) || [];
      const varianceData = property.Details.CashflowLoans?.Variance.flat().filter((data) => data.Year == year) || [];
      const loanNameArray =
        forecastData.map((item) => item.Name).filter((name) => name) ||
        actualData.map((item) => item.Name).filter((name) => name) ||
        varianceData.map((item) => item.Name).filter((name) => name);

      forecastData.forEach((data, index) => {
        if (!data.Name) {
          if (loanNameArray[index]) {
            data.Name = loanNameArray[index];
          } else {
            data.Name = `${index + 1}${nth(index + 1)} loan`;
          }
        }
      });

      actualData.forEach((data, index) => {
        if (!data.Name) {
          if (loanNameArray[index]) {
            data.Name = loanNameArray[index];
          } else {
            data.Name = `${index + 1}${nth(index + 1)} loan`;
          }
        }
      });

      varianceData.forEach((data, index) => {
        if (!data.Name) {
          if (loanNameArray[index]) {
            data.Name = loanNameArray[index];
          } else {
            data.Name = `${index + 1}${nth(index + 1)} loan`;
          }
        }
      });

      const allData = [
        { type: "Actuals", data: actualData },
        { type: "Forecast", data: forecastData },
        { type: "Variance", data: varianceData },
      ];

      let finalData: { loanName: string; data: { Actuals: []; Forecast: []; Variance: [] }[] }[] = [];
      let totalData: any[] = [];
      allData.forEach(({ type, data }) => {
        data.forEach((item) => {
          const addressParts = [
            property.PropertyDetail.StreetAddress,
            property.PropertyDetail.Suburb,
            property.PropertyDetail.State,
            property.PropertyDetail.Postcode,
            property.PropertyDetail.Country,
          ]
            .filter((part) => part && part.trim() !== "")
            .join(", ");
          const portfolioTrackerId = property.PropertyDetail.PortfolioTrackerId;
          totalData.push({
            property: addressParts,
            type,
            loanType: item.MortgageType || "",
            loanStart: item.LoanStart || "",
            term: item.Term || "",
            name: item.Name + (item.RefCode ? ` (${item.RefCode})` : "") || "",
            nextReview: item.InterestRateReview || "",
            interest: Number(item.InterestRate) || 0,
            interestRate: Number(item.InterestPayment) || 0,
            principal: Number(item.PrincipalPayment) || 0,
            balance: Number(item.Balance) || 0,
            portfolioTrackerId: portfolioTrackerId,
          });
        });
      });

      const loanNames = new Set(totalData.map((item) => item.name));
      loanNames.forEach((loanName) => {
        const types = ["Actuals", "Forecast", "Variance"];
        types.forEach((type) => {
          const hasType = totalData.some((item) => item.name === loanName && item.type === type);
          if (!hasType) {
            totalData.push({
              property: "",
              type,
              loanType: "",
              loanStart: "",
              term: "",
              name: loanName,
              nextReview: "",
              interestRate: 0,
              principal: 0,
              balance: 0,
              portfolioTrackerId: "",
            });
          }
        });
      });

      const separatedData = totalData.reduce((acc, item) => {
        if (!acc[item.name]) {
          acc[item.name] = [];
        }
        acc[item.name].push(item);
        return acc;
      }, {});

      Object.keys(separatedData).forEach((loanName) => {
        const name = separatedData[loanName][0]?.name || "Unknown Loan";
        finalData.push({ loanName: name, data: separatedData[loanName] });
      });
      if (finalData.length > 1) {
        let totalsAveragesData = CalculateTotalData(property, year);
        finalData.push(totalsAveragesData);
      }
      resultData.push(finalData);
    });
    return resultData;
  };

  const CalculateTotalData = (data: IPropertyDetail, year: string) => {
    const loansTotalsData = data.Details.CashflowLoans?.LoansTotals || {};
    const totalsAveragesData = {
      loanName: "Totals/Averages for this property",
      data: [
        {
          type: "Actuals",
          loanType: loansTotalsData.Actuals?.find((data) => data.Year == year)?.MortgageType || "",
          loanStart: loansTotalsData.Actuals?.find((data) => data.Year == year)?.LoanStart || "",
          term: "",
          name: "Totals/Averages for this property",
          nextReview: loansTotalsData.Actuals?.find((data) => data.Year == year)?.InterestRateReview || "",
          interest: Number(loansTotalsData.Actuals?.find((data) => data.Year == year)?.InterestRate) || 0,
          interestRate: Number(loansTotalsData.Actuals?.find((data) => data.Year == year)?.InterestPayment) || 0,
          principal: Number(loansTotalsData.Actuals?.find((data) => data.Year == year)?.PrincipalPayment) || 0,
          balance: Number(loansTotalsData.Actuals?.find((data) => data.Year == year)?.Balance) || 0,
          portfolioTrackerId: "",
          property: "",
        },
        {
          type: "Forecast",
          loanType: loansTotalsData.Forecast?.find((data) => data.Year == year)?.MortgageType || "",
          loanStart: loansTotalsData.Forecast?.find((data) => data.Year == year)?.LoanStart || "",
          term: "",
          name: "Totals/Averages for this property",
          nextReview: loansTotalsData.Forecast?.find((data) => data.Year == year)?.InterestRateReview || "",
          interest: Number(loansTotalsData.Forecast?.find((data) => data.Year == year)?.InterestRate) || 0,
          interestRate: Number(loansTotalsData.Forecast?.find((data) => data.Year == year)?.InterestPayment) || 0,
          principal: Number(loansTotalsData.Forecast?.find((data) => data.Year == year)?.PrincipalPayment) || 0,
          balance: Number(loansTotalsData.Forecast?.find((data) => data.Year == year)?.Balance) || 0,
          portfolioTrackerId: "",
          property: "",
        },
        {
          type: "Variance",
          loanType: loansTotalsData.Variance?.find((data) => data.Year == year)?.MortgageType || "",
          loanStart: loansTotalsData.Variance?.find((data) => data.Year == year)?.LoanStart || "",
          term: "",
          name: "Totals/Averages for this property",
          nextReview: loansTotalsData.Variance?.find((data) => data.Year == year)?.InterestRateReview || "",
          interest: Number(loansTotalsData.Variance?.find((data) => data.Year == year)?.InterestRate) || 0,
          interestRate: Number(loansTotalsData.Variance?.find((data) => data.Year == year)?.InterestPayment) || 0,
          principal: Number(loansTotalsData.Variance?.find((data) => data.Year == year)?.PrincipalPayment) || 0,
          balance: Number(loansTotalsData.Variance?.find((data) => data.Year == year)?.Balance) || 0,
          portfolioTrackerId: "",
          property: "",
        },
      ],
    };
    return totalsAveragesData;
  };

  const TransformedFooter = (data: ILoans, year: string) => {
    const footerProperties = data || {};
    const actualFooter = footerProperties?.Actuals.find((data) => data.Year == year) || ({} as ILoanEntry);
    const forecastFooter = footerProperties?.Forecast.find((data) => data.Year == year) || ({} as ILoanEntry);
    const varianceFooter = footerProperties?.Variance.find((data) => data.Year == year) || ({} as ILoanEntry);

    return {
      Actuals: actualFooter,
      Forecast: forecastFooter,
      Variance: varianceFooter,
    };
  };

  const transformedData = useMemo(() => {
    if (cashFlowData) {
      return TransformLoanData(cashFlowData.PropertyDetails, year);
    }
    return [];
  }, [cashFlowData, year]);

  const transformedFooter = useMemo(() => {
    if (cashFlowData) {
      return TransformedFooter(
        cashFlowData.TotalForDetails.find((data) => data.EntityId === "")?.CashflowDetailsTotals.Loans,
        year
      );
    }
    return undefined;
  }, [cashFlowData, year]);

  const toggleRowCollapse = (street: string) => {
    setCollapsedRows((prev) => ({ ...prev, [street]: !prev[street] }));
  };

  const percentageTemplate = (value: number) => (
    <span className={value < 0 ? "text-red-600" : ""}>
      {value < 0 ? `(${formatNumberWithDecimal(-value, 2, 2)}%)` : `${formatNumberWithDecimal(value, 2, 2)}%`}
    </span>
  );

  const currencyTemplate = (value: string) => (
    <span className={Number(value) < 0 ? "text-red-600" : ""}>
      {Number(value) < 0 ? `(${formatCurrency(-Number(value))})` : formatCurrency(Number(value))}
    </span>
  );

  const onCancel = () => {
    isEditable = false;
  };

  return (
    cashFlowData && (
      <div className="rent-container">
        {isEditable ? (
          <EditLoan year={year} cashFlowData={cashFlowData} onCancel={onCancel} />
        ) : (
          <div>
            <DataTable size="small" value={[]} showHeaders emptyMessage="" className="hide-empty-message">
              <Column headerClassName="w-[10%] text-left" field="type" header="Type" />
              <Column headerClassName="w-[10%] text-left" field="loanType" header="Loan Type" />
              <Column headerClassName="w-[10%] text-left" field="loanStart" header="Loan Start" />
              <Column headerClassName="w-[10%] text-left" field="term" header="Term" />
              <Column headerClassName="w-[10%] text-left" field="nextReview" header="Rate Review" />
              <Column headerClassName="w-[10%] text-left" field="interest" header="Interest" />
              <Column headerClassName="w-[10%] text-left" field="interestRate" header="Interest Rate" />
              <Column headerClassName="w-[10%] text-left" field="loanAmount" header="Principal" />
              <Column headerClassName="w-[10%] text-left" field="balance" header="Balance" />
              <Column headerClassName="w-[10%] text-left" field="" header="" />
            </DataTable>
            {cashFlowData &&
              transformedData &&
              transformedData.length > 0 &&
              cashFlowData.PropertyDetails.length > 0 &&
              cashFlowData.PropertyDetails.map((property, index) => {
                const addressParts = [
                  property.PropertyDetail.StreetAddress,
                  property.PropertyDetail.Suburb,
                  property.PropertyDetail.State,
                  property.PropertyDetail.Postcode,
                  property.PropertyDetail.Country,
                ]
                  .filter((part) => part && part.trim() !== "")
                  .join(", ");

                return (
                  <div key={property.PropertyDetail.PortfolioTrackerId} className="p-2 ">
                    <div className="text-left text-lg font-bold">{addressParts}</div>
                    {transformedData[index].map((loan, loanIndex) => (
                      <div key={loanIndex} className="mb-2">
                        <div className="text-left text-base font-semibold">{loan.loanName}</div>
                        <DataTable
                          value={loan.data}
                          rowGroupMode="rowspan"
                          groupRowsBy="name"
                          showHeaders={false}
                          size="small"
                          showGridlines={false}
                        >
                          {[
                            { field: "type", header: "Type" },
                            { field: "loanType", header: "Loan Type" },
                            { field: "loanStart", header: "Loan Start" },
                            {
                              field: "term",
                              header: "Term",
                              format: (value) =>
                                value === ""
                                  ? ""
                                  : value % 1 === 0
                                  ? `${value} yrs`
                                  : `${formatNumberWithDecimal(value, 1, 1)} yrs`,
                            },
                            { field: "nextReview", header: "Next Review" },
                            { field: "interest", header: "Interest Rate", format: percentageTemplate },
                            { field: "interestRate", header: "Interest Rate", format: currencyTemplate },
                            { field: "principal", header: "Loan Amount", format: currencyTemplate },
                            { field: "balance", header: "Balance", format: currencyTemplate },
                            { field: "icon", header: "" },
                          ].map(({ field, header, format }) => (
                            <Column
                              key={field}
                              className="w-[10%] text-left"
                              field={field}
                              header={header}
                              body={(rowData) =>
                                field === "icon" ? (
                                  <div className="flex items-center justify-center">
                                    {isForecastOnly && rowData.type === "Forecast" && (
                                      <REIButton
                                        icon={
                                          collapsedRows[rowData.name + rowData.property]
                                            ? "pi pi-angle-down"
                                            : "pi pi-angle-right"
                                        }
                                        text={true}
                                        onClick={() => toggleRowCollapse(rowData.name + rowData.property)}
                                      />
                                    )}
                                  </div>
                                ) : (!collapsedRows[rowData.name + rowData.property] || rowData.type === "Forecast") &&
                                  (isForecastOnly || rowData.type === "Forecast") &&
                                  rowData[field] !== null &&
                                  rowData[field] !== undefined ? (
                                  format ? (
                                    format(rowData[field])
                                  ) : (
                                    rowData[field]
                                  )
                                ) : null
                              }
                            />
                          ))}
                        </DataTable>
                      </div>
                    ))}
                  </div>
                );
              })}
            <div>
              {transformedFooter && (
                <>
                  <div className="text-start text-lg font-semibold">Totals/Averages for this property</div>
                  <DataTable
                    value={[
                      { ...transformedFooter.Forecast, type: "Forecast" },
                      { ...transformedFooter.Actuals, type: "Actuals" },
                      { ...transformedFooter.Variance, type: "Variance" },
                    ]}
                    showHeaders={false}
                    emptyMessage=""
                    className="hide-empty-message text-end"
                  >
                    <Column className="w-[10%] text-left font-semibold" field="type" />
                    <Column className="w-[10%] text-left" field="loanType" />
                    <Column className="w-[10%] text-left" field="loanStart" />
                    <Column className="w-[10%] text-left" field="term" />
                    <Column className="w-[10%] text-left" field="nextReview" />
                    <Column
                      className="w-[10%] text-left"
                      field="InterestRate"
                      body={(rowData) => percentageTemplate(rowData.InterestRate)}
                    />
                    <Column
                      className="w-[10%] text-left"
                      field="InterestPayment"
                      body={(rowData) => currencyTemplate(rowData.InterestPayment)}
                    />
                    <Column
                      className="w-[10%] text-left"
                      field="PrincipalPayment"
                      body={(rowData) => currencyTemplate(rowData.PrincipalPayment)}
                    />
                    <Column className="w-[10%] text-left" field="balance" />
                    <Column className="w-[10%] text-left" field="" />
                  </DataTable>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    )
  );
};

export default Loan;
