import { useEffect, useRef, useState } from "react";
import { IREIGraphDataSet } from "../../../interface/graph";
import { ICashflow } from "../../../interface/propertyTracker";
import REIToggablePanel from "../../../components/ui/toggablePanel";
import { formatNumber } from "../../../utils/commonUtil";
import ScrollableGraph from "../../../components/shared/ScrollableGraph";
import { REILinkButton, REIProgressSpinner } from "../../../components/ui";

const CashFlow: React.FC<{
  entityId: string;
  showingTotalSummary: boolean;
  trackerData: {
    Actuals: ICashflow[];
    Forecast: ICashflow[];
  };
}> = ({ entityId, showingTotalSummary, trackerData }) => {
  const [currentYearIndex, setCurrentYearIndex] = useState(0);
  const [chartData, setChartData] = useState<IREIGraphDataSet[]>([] as IREIGraphDataSet[]);
  const [trakerIndexData, setTrakerIndexData] = useState<{
    Actuals: ICashflow[];
    Forecast: ICashflow[];
  }>();
  const [selectedTrakerIndexData, setSelectedTrakerIndexData] = useState<{
    Actuals: ICashflow[];
    Forecast: ICashflow[];
  }>();
  const [years, setYears] = useState<string[]>([]);
  const [selectedYear, setSelectedYear] = useState<number>(0);
  const selectedYearForecast = useRef<ICashflow>();
  const displayedYears = 5;
  const [loading, setLoading] = useState<boolean>(false);
  const colorMap = {
    Income: {
      0: "rgba(134, 239, 172, 1)",

      default: "rgba(45, 212, 191, 1)",
    },
    Expense: {
      0: "rgba(248, 113, 113, 1)",

      default: "rgba(156, 163, 175, 1)",
    },
  };

  useEffect(() => {
    const getTrackerData = async () => {
      setTrakerIndexData((prevState) => {
        return trackerData;
      });
      setChartData([
        {
          label: "Income",
          data: [],
          backgroundColor: [],
        },
        {
          label: "Expense",
          data: [],
          backgroundColor: [],
        },
      ]);
    };
    if (trackerData) {
      getTrackerData();
    }
  }, [trackerData, entityId, showingTotalSummary]);

  useEffect(() => {
    setLoading(true);
    getChartData();
    setSelectedYear(Number(years[currentYearIndex]));
    selectedYearForecast.current =
      selectedTrakerIndexData &&
      selectedTrakerIndexData.Forecast.find((data) => data.Year == years[currentYearIndex].toString());
    setLoading(false);
  }, [trakerIndexData, currentYearIndex, displayedYears]);

  const getChartData = () => {
    if (trakerIndexData) {
      const forecastData = trakerIndexData.Forecast;
      const actualData = trakerIndexData.Actuals;

      const newChartData = forecastData.map((item, index) => ({
        IN: Number(index < actualData.length ? actualData[index].IN : item.IN),
        OUT: Number(index < actualData.length ? actualData[index].OUT : item.OUT),
      }));

      setChartData((prev) =>
        prev.map((dataset, idx) => {
          const backgroundColors: string[] = newChartData.map((_, index) => {
            if (index == 0) {
              return colorMap[dataset.label][index];
            } else {
              return colorMap[dataset.label].default;
            }
          });

          return {
            ...dataset,
            data: newChartData.map((item) => item[dataset.label === "Income" ? "IN" : "OUT"]),
            backgroundColor: backgroundColors,
          };
        })
      );

      setYears(forecastData.map((item) => item.Year));
      setSelectedTrakerIndexData(trakerIndexData);
    }
  };

  const customOptions = {
    plugins: {
      legend: {
        display: false,
        position: "top",
      },
      backgroundColor: "rgb(206, 205, 205)",
      scales: {
        y: {
          grid: {
            display: false,
          },
          beginAtZero: true,
        },
        x: {
          grid: {
            display: false,
          },
        },
      },
    },
  };

  const CashFlowHeader = (
    <div className="flex flex-row bg-gray-200 border-solid border-gray-200 border-x border-y rounded-t-md">
      <div className="w-1/2 text-start text-gray-700 font-bold text-sm ml-auto pl-3">
        <h3>Cash Flow</h3>
      </div>
      <div className="w-1/2 text-end pl-3 content-center px-4">
        <REILinkButton
          href=""
          target="_blank"
          rel="noopener noreferrer"
          className="no-underline cursor-pointer text-gray-700"
        >
          {"See all expenses & income >>"}
        </REILinkButton>
      </div>
    </div>
  );

  const getClassForValue = (value) => {
    return Number(value) < 0 ? "text-red-500" : "";
  };

  const renderGraphHeader = (
    <div className="flex flex-row justify-around">
      <div className="align-top w-1/4">
        <b>FY-{selectedYear && selectedYear.toString().slice(2, 4)} Actuals</b>
      </div>
      <div className="flex flex-col text-sm content-center bg-gray-100 border-solid border-gray-200 border-x border-y rounded-md w-3/4">
        <table className="w-full">
          <thead>
            <tr className="flex gap-5 align-bottom justify-between text-center w-full">
              <th className="w-1/4 content-end">Income</th>
              <th className="w-1/4 content-end">Expenditure</th>
              <th className="w-1/4 content-end">Pre-tax cash flow</th>
              <th className="w-1/4 content-end">After-tax cash flow</th>
            </tr>
          </thead>
          <tbody>
            <tr className="flex gap-5 justify-around text-center w-full">
              <td className="w-1/4">
                <b className={getClassForValue(selectedYearForecast.current?.IN)}>
                  $ {formatNumber(Number(selectedYearForecast.current?.IN))}
                </b>
              </td>
              <td className="w-1/4">
                <b className={getClassForValue(selectedYearForecast.current?.NetProfitLoss)}>
                  $ {formatNumber(Number(selectedYearForecast.current?.NetProfitLoss))}
                </b>
              </td>
              <td className="w-1/4">
                {selectedYearForecast.current?.PreTaxCashflow != undefined && (
                  <b>
                    <span className={getClassForValue(selectedYearForecast.current.PreTaxCashflow)}>
                      ($ {formatNumber(Number(selectedYearForecast.current.PreTaxCashflow))})
                    </span>
                  </b>
                )}
              </td>
              <td className="w-1/4">
                {selectedYearForecast.current?.PostTaxCashflow != undefined && (
                  <b>
                    <span className={getClassForValue(selectedYearForecast.current.PostTaxCashflow)}>
                      ($ {formatNumber(Number(selectedYearForecast.current.PostTaxCashflow))})
                    </span>
                  </b>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );

  const renderGraphLegend = (
    <div className="bg-gray-100 border-solid border-gray-200 border-x border-y rounded-b-md p-3 m-3">
      <div className="flex flex-wrap justify-around">
        <div className="flex gap-2 items-center m-2">
          <span className="w-4 h-4 bg-green-300"></span>
          <span className="text-center">Actual income</span>
        </div>
        <div className="flex gap-2 items-center m-2">
          <div className="w-4 h-4 bg-red-400"></div>
          <span className="text-center">Actual expenses</span>
        </div>
        <div className="flex gap-2 items-center m-2">
          <div className="w-4 h-4 bg-teal-400"></div>
          <span className="text-center">Forecast income</span>
        </div>
        <div className="flex gap-2 items-center m-2">
          <div className="w-4 h-4 bg-gray-400"></div>
          <span className="text-center">Forecast expenses</span>
        </div>
      </div>
    </div>
  );

  const onYearChange = (selectedYearIndex: number) => {
    setCurrentYearIndex(selectedYearIndex);
  };

  return (
    <>
      {!trackerData && (
        <div className="flex items-center">
          <REIProgressSpinner></REIProgressSpinner>
        </div>
      )}

      <REIToggablePanel
        contentComponent={
          <div className="flex flex-col">
            {renderGraphHeader}
            {!loading && (
              <div className=" mb-4">
                <ScrollableGraph
                  onYearChange={onYearChange}
                  data={chartData}
                  displayedYears={5}
                  graphType="bar"
                  labels={years}
                  customOptions={customOptions}
                ></ScrollableGraph>
                {renderGraphLegend}
              </div>
            )}
          </div>
        }
        header=""
        customHeader={CashFlowHeader}
        istoggleable={false}
        defaultCollapse={false}
      ></REIToggablePanel>
    </>
  );
};

export default CashFlow;
