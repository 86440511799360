import React, { useEffect, useRef, useState } from "react";
import { useHttpGets } from "../../../services/httpService";
import { ApiEndPoints } from "../../../utils/endpoints";
import { MEMBER_KEY, USER_DETAIL } from "../../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { IAnalysisSummaryResponse } from "../../../interface/analysisSummary";
import { REIProgressSpinner } from "../../../components/ui";
import ErrorMessage from "../../../components/ui/errorMessage";
import { Mapper } from "./mapper";
import PropertyInformation from "../../WatchList/ViewProperty/PropertyInformation";
import ViewPropertyDetails from "../../../interface/Watchlist/propertyDetails";
import PropertyHeader from "../../WatchList/ViewProperty/PropertyHeader";
import { setShowFileUploadNotes } from "../../../store/slice/propertyDetailsSlice";

const PropertySummary: React.FC = () => {
  const memberDetail = JSON.parse(localStorage.getItem(USER_DETAIL) || "{}");
  const memberId = localStorage.getItem(MEMBER_KEY);
  const analysisSummary = useSelector((state: RootState) => state.analyser.analysisSummary as IAnalysisSummaryResponse);
  const mappedData = useRef<ViewPropertyDetails>();
  const [loading, setLoading] = useState<boolean>(false);
  const [propertySummary, setPropertySummary] = useState<ViewPropertyDetails>();
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    useHttpGets(
      `${ApiEndPoints.analyser}/PropertyDetailSummary?MemberId=${memberId}&PropertyAnalyserId=${analysisSummary.PropertyAnalyserId}&MemberCountryCode=${memberDetail.Country}`,
      undefined
    )
      .then((result) => {
        if (result.status == 200) {
          dispatch(setShowFileUploadNotes(true));
          mappedData.current = Mapper(result.data);
          setPropertySummary(mappedData.current);
          setLoading(false);
        } else {
          setLoading(false);
          return <REIProgressSpinner></REIProgressSpinner>;
        }
      })
      .catch(() => {
        setLoading(false);
        return <ErrorMessage message="error" />;
      });
  }, []);

  return (
    <>
      {loading && (
        <div className="flex items-center">
          <REIProgressSpinner></REIProgressSpinner>
        </div>
      )}
      {!loading && mappedData.current && propertySummary && (
        <>
          <PropertyHeader propertydata={propertySummary} hideCloseBtn={true} onClosecallback=""></PropertyHeader>
          <PropertyInformation viewPropertyData={propertySummary}></PropertyInformation>
        </>
      )}
    </>
  );
};

export default PropertySummary;
