import React, { useEffect, useRef, useState } from "react";
import { httpPost, useHttpGets } from "../../../../services/httpService";
import { ApiEndPoints } from "../../../../utils/endpoints";
import { MEMBER_KEY } from "../../../../utils/constants";
import { REIButton, REIDialoge, REIInputText, REIProgressSpinner } from "../../../../components/ui";
import AssignEntity from "../../PropertyStepper/Child/TaxAndMarket/AssignEntity";
import { useAnalysedPropertyContext } from "../../PropertyStepper/Child/AnalysedPropertyContext";
import {
  IAnalysedProperty,
  IGrowth,
  IPropertyAnnualExpense,
  IPropertyAnnualRentalReturns,
  IPropertyDepreciationEstimation,
  IPropertyTaxRate,
  ILoan,
} from "../../../../interface/addAnalyser";
import { IMemberEntity, IPropertyMarketFactor } from "../../../../interface/analysed";
import DatePicker from "../../../../components/ui/calender";
import { getAdjustedFormattedDate, getDateStringWithGivenFormat } from "../../../../utils/commonUtil";
import LoanDetailsTab from "../../PropertyStepper/Child/LoanDetailsTab";
import { useNavigate } from "react-router-dom";

const MoveToPortfolioTracker: React.FC<{
  propertyAnalyserId: number;
  memberCountryCode: string;
  memberEntity: IMemberEntity[];
}> = ({ propertyAnalyserId, memberCountryCode, memberEntity }) => {
  const [PropertyTrackerData, setPropertyTrackerData] = useState<IPropertyTrackerData>();
  const [addPropertyToPortfolioRequest, setAddPropertyToPortfolioRequest] = useState<IAddPropertyToPortfolioRequest>();
  const [lease, setLease] = useState<ILease>();
  const PortfolioRequestData = useRef<IAddPropertyToPortfolioRequest>();
  const { setAnalysedProperty, analysedProperty } = useAnalysedPropertyContext();
  const [loanDetails, setLoanDetails] = useState<ILoan[]>([]);
  const [isPorfolioSuccess, setIsPorfolioSuccess] = useState<boolean>(false);
  const [tanents, setTanents] = useState<string>("");
  const navigate = useNavigate();

  useEffect(() => {
    const getData = () => {
      useHttpGets(
        `${ApiEndPoints.analyser}/PropertyAnalyserToTracker?MemberId=${localStorage.getItem(
          MEMBER_KEY
        )}&PropertyAnalyserId=${propertyAnalyserId}&MemberCountryCode=${memberCountryCode}`,
        undefined
      ).then((result: { data: IPropertyTrackerData | null; error: string | null; status: number | null }) => {
        if (result.status == 200) {
          const loans = result.data?.Loans.map((loan: ILoan) => ({
            ...loan,
            FixedInterestPeriod_Years: loan.FixedInterestPeriod_Years || 0,
            FixedInterestPeriod_Months: loan.FixedInterestPeriod_Months || 0,
            InterestRateReviewDate:
              loan.InterestRateReviewDate || getAdjustedFormattedDate(new Date(Date.now()), { years: 3 }),
            Date: loan.Date !== undefined ? loan.Date : getDateStringWithGivenFormat(new Date(Date.now())),
            isFromPortfolio: true,
          }));

          setPropertyTrackerData({
            ...result.data,
            Loans: loans,
          });

          setTrackerDataToContext(result.data);
          setLoanDetails(loans);
          initializeAddPropertyToPortfolioRequest();
          setLease({
            EndDate: getAdjustedFormattedDate(new Date(Date.now()), { years: 1 }),
            ReviewAlert: "0",
            ReviewDate: getAdjustedFormattedDate(new Date(Date.now()), { years: 1 }),
            StartDate: getDateStringWithGivenFormat(new Date(Date.now())),
            Tenants: "",
          });
        } else {
          return <REIProgressSpinner></REIProgressSpinner>;
        }
      });
    };
    if (propertyAnalyserId) {
      getData();
    }
  }, [propertyAnalyserId]);

  useEffect(() => {
    if (PropertyTrackerData?.Loans && PropertyTrackerData?.Loans.length > 0) {
      handleUpdateProperty({
        LoanTypeCost: {
          ...analysedProperty?.LoanTypeCost,
          Loans: loanDetails,
          PropertyOtherPurchaseExpense: analysedProperty?.LoanTypeCost.PropertyOtherPurchaseExpense || {
            CapitalInvestment: 0,
            OtherCost: 0,
            SolicitorsCost: 0,
            StampDuty: 0,
          },
          PropertyPurchaseCost: analysedProperty?.LoanTypeCost.PropertyPurchaseCost || {
            CashDeposit: 0,
            MarketValueNow: 0,
            PurchaseAmount: 0,
            EquityDeposit: 0,
            isEnterPercentage: "0",
            isEnterValue: "0",
          },
        },
      });
    }
  }, [loanDetails]);

  const handleUpdateProperty = (updatedProperty: Partial<IAnalysedProperty>) => {
    setAnalysedProperty((prevState) => {
      if (!prevState) return undefined;
      return {
        ...prevState,
        ...updatedProperty,
      };
    });
  };
  const initializeAddPropertyToPortfolioRequest = () => {
    const initialRequest: IAddPropertyToPortfolioRequest = {
      PropertyAnalyserId: propertyAnalyserId,
      MemberEntityId: Number(PropertyTrackerData?.MemberEntityId) || 0,
      PurchasePrice: PropertyTrackerData?.PurchasePrice || 0,
      Loans: [
        {
          AnalyserLoanId: 0,
          Balance: 0,
          Date: getDateStringWithGivenFormat(new Date(Date.now()), "dd/mm/yy"),
          EstablishmentFees: 0,
          FixedInterestPeriod_Months: 1,
          FixedInterestPeriod_Years: 1,
          InterestRate: 0,
          InterestRateReviewAlert: "",
          InterestRateReviewDate: getAdjustedFormattedDate(new Date(Date.now()), { years: 3 }),
          Length_Months: 1,
          Length_Years: 1,
          Name: "",
          OtherLoanCost: 0,
          RefCode: "",
          ValuationFees: 0,
          isNewLoan: 1,
          isPrincipal: "1",
        },
      ],
      Lease: {
        EndDate: getAdjustedFormattedDate(new Date(Date.now()), { years: 1 }),
        ReviewAlert: "",
        ReviewDate: getAdjustedFormattedDate(new Date(Date.now()), { years: 1 }),
        StartDate: getDateStringWithGivenFormat(new Date()),
        Tenants: "",
      },
      PortionedOwned: 0,
      MemberId: localStorage.getItem(MEMBER_KEY) || "",
      PurchaseDate: getDateStringWithGivenFormat(new Date()),
      CountryCode: "",
      userCountryCode: "",
      Year1ProRata: 0,
      Year1ProRataDateExpense: null,
      Year1ProRataDateIncome: null,
      DepreciationValues: {
        VIConstructionYear_DepreciationValue: "",
        VIEstimatedLandValue_DepreciationValue: "0",
        VIRenovation_DepreciationValue: "",
        WBConstructionYear_DepreciationValue: "",
        WBNearestCapitalCity_DepreciationValue: "",
        WBPropertyType_DepreciationValue: "",
        WBQuality_DepreciationValue: "",
      },
      CalculateDepreciation: 0,
    };
    setAddPropertyToPortfolioRequest(initialRequest);
    PortfolioRequestData.current = initialRequest;
  };

  const moveToPortfolioTracker = async () => {
    mapAnalyserDataToPortfolioRequest();
    await httpPost(`${ApiEndPoints.tracker}PortfolioTracker/FromAnalyser/`, PortfolioRequestData.current, null).then(
      (result) => {
        if (result.status == 200) {
          setIsPorfolioSuccess(true);
        }
      }
    );
  };

  const setTrackerDataToContext = (data: IPropertyTrackerData) => {
    const initialProperty: IAnalysedProperty = {
      LoanTypeCost: {
        Loans: data.Loans.map((loan: ILoan) => ({
          ...loan,
          FixedInterestPeriod_Years: loan.FixedInterestPeriod_Years || 0,
          FixedInterestPeriod_Months: loan.FixedInterestPeriod_Months || 0,
          InterestRateReviewDate: loan.InterestRateReviewDate || "",
          Date: loan.Date || "",
          isFromPortfolio: true,
        })),
        PropertyOtherPurchaseExpense: {
          CapitalInvestment: 0,
          OtherCost: 0,
          SolicitorsCost: 100,
          StampDuty: 0,
        },
        PropertyPurchaseCost: {
          CashDeposit: 10,
          EquityDeposit: 0,
          MarketValueNow: 0,
          PurchaseAmount: data.PurchasePrice,
          isEnterPercentage: "1",
          isEnterValue: "0",
        },
      },
      PropertyDetail: {
        BathroomId: 0,
        BedroomId: 0,
        CarportId: 0,
        CountryId: 0,
        DateAdded: new Date().toLocaleDateString("en-GB"),
        Landsize: "0",
        MemberPropertyId: "0",
        ModulesId: "0",
        PropertyDetailId: 0,
        PropertyName: "",
        PropertyTypeId: 0,
        UserCountryId: 0,
        district: "",
        locality: "",
        postcode: "",
        state: "",
        useDefault: "0",
        Growth: {
          value: 0,
          description: "",
          PropertyType: "",
          StatsPostcode: "",
          StatsPostcodeLongTermGrowth: 0,
          StatsRegion: "",
          StatsRegionLongTermGrowth: 0,
          StatsSuburb: "",
          StatsSuburbLongTermGrowth: 0,
        } as IGrowth,
        MemberEntityId: data.MemberEntityId.toString(),
        MemberProfiles: [],
        PortionOwned: data.PortionOwned.toString(),
      },
      RentExpense: {
        PropertyAnnualExpense: {} as IPropertyAnnualExpense,
        PropertyAnnualRentalReturns: {} as IPropertyAnnualRentalReturns,
      },
      TaxMarket: {
        PropertyDepreciationEstimation: {} as IPropertyDepreciationEstimation,
        PropertyMarketFactor: {} as IPropertyMarketFactor,
        PropertyTaxRate: {} as IPropertyTaxRate,
      },
    };
    PortfolioRequestData.current = initialProperty;
    setAnalysedProperty(initialProperty);
  };

  const mapAnalyserDataToPortfolioRequest = () => {
    const data: IAddPropertyToPortfolioRequest = {
      PropertyAnalyserId: Number(propertyAnalyserId),
      MemberEntityId: Number(PropertyTrackerData?.MemberEntityId),
      PurchasePrice: PropertyTrackerData?.PurchasePrice,
      Loans: loanDetails
        ? loanDetails.map((loan) => ({
            AnalyserLoanId: loan.LoanId,
            Balance: loan.Amount != undefined ? loan.Amount : 0,
            Date: getDateStringWithGivenFormat(new Date(loan.Date), "dd/mm/yy"),
            EstablishmentFees: loan.EstablishmentFees,
            FixedInterestPeriod_Months: loan.FixedInterestPeriod_Months,
            FixedInterestPeriod_Years: loan.FixedInterestPeriod_Years,
            InterestRate: loan.InterestRate,
            InterestRateReviewAlert: 0,
            InterestRateReviewDate: getDateStringWithGivenFormat(new Date(loan.InterestRateReviewDate), "dd/mm/yy"),
            Length_Months: loan.Length_Months,
            Length_Years: loan.Length_Years,
            Name: loan.Name,
            OtherLoanCost: loan.OtherLoanCosts,
            RefCode: "",
            ValuationFees: loan.ValuationFees,
            isNewLoan: 1,
            isPrincipal: loan.IsPrincipal.toString(),
          }))
        : [],
      Lease_Tenants: tanents != undefined ? tanents : "",
      Lease_EndDate:
        lease?.EndDate != undefined
          ? getDateStringWithGivenFormat(new Date(lease?.EndDate), "dd/mm/yy")
          : getAdjustedFormattedDate(new Date(Date.now()), { years: 1 }),
      Lease_ReviewAlert: 0,
      Lease_ReviewDate:
        lease?.ReviewDate != undefined
          ? getDateStringWithGivenFormat(new Date(lease?.ReviewDate), "dd/mm/yy")
          : getAdjustedFormattedDate(new Date(Date.now()), { years: 1 }),
      Lease_StartDate:
        lease?.StartDate != undefined
          ? getDateStringWithGivenFormat(new Date(lease?.StartDate), "dd/mm/yy")
          : getDateStringWithGivenFormat(new Date()),
      PortionedOwned: Number(analysedProperty?.PropertyDetail.PortionOwned),
      MemberId: localStorage.getItem(MEMBER_KEY),
      PurchaseDate: new Date().toLocaleDateString("en-GB"),
      CountryCode: memberCountryCode,
      userCountryCode: memberCountryCode,
      CalculateDrepreciation: 1,
      VIConstructionYear_DepreciationValue:
        PropertyTrackerData?.PropertyDepreciationDepreciationValues.VIConstructionYear_DepreciationValue,
      VIEstimatedLandValue_DepreciationValue:
        PropertyTrackerData?.PropertyDepreciationDepreciationValues.VIEstimatedLandValue_DepreciationValue,
      VIRenovation_DepreciationValue:
        PropertyTrackerData?.PropertyDepreciationDepreciationValues.VIRenovation_DepreciationValue,
      WBConstructionYear_DepreciationValue:
        PropertyTrackerData?.PropertyDepreciationDepreciationValues.WBConstructionYear_DepreciationValue,
      WBNearestCapitalCity_DepreciationValue:
        PropertyTrackerData?.PropertyDepreciationDepreciationValues.WBNearestCapitalCity_DepreciationValue,
      WBPropertyType_DepreciationValue:
        PropertyTrackerData?.PropertyDepreciationDepreciationValues.WBPropertyType_DepreciationValue,
      WBQuality_DepreciationValue:
        PropertyTrackerData?.PropertyDepreciationDepreciationValues.WBQuality_DepreciationValue,
      Year1ProRata: 0,
      Year1ProRataDateExpense: null,
      Year1ProRataDateIncome: null,
    };
    setAddPropertyToPortfolioRequest((prevState) => {
      return { ...prevState, ...data };
    });
    PortfolioRequestData.current = data;
  };

  const setValues = (name: string, value: string | number) => {
    setAddPropertyToPortfolioRequest((prevState) => {
      if (!prevState) return undefined;
      const updatedExpense = {
        ...prevState,
        [name]: value !== undefined ? value : 0,
      };
      return updatedExpense;
    });
  };

  const setLeaseData = (name: string, value: string | number) => {
    setLease((prevState) => {
      if (!prevState) return undefined;
      const updatedLease = {
        ...prevState,
        [name]: value !== undefined ? value : 0,
      };
      return updatedLease;
    });
  };

  const updateLoanValue = (loan: ILoan) => {
    if (loanDetails) {
      const loans = loanDetails.map((l) => (l.LoanId === loan.LoanId ? loan : l));
      setLoanDetails(loans);
    }
  };

  return (
    <>
      <REIDialoge
        contentComponent={
          <>
            <h3>The property has been successfully added to your Portfolio Tracker portfolio</h3>
            <div>
              {PropertyTrackerData?.State},{PropertyTrackerData?.Country}
            </div>
            <div className="flex row mt-1">
              <REIButton
                label="OK"
                disabled={false}
                icon=""
                classNames=""
                onClick={() => {
                  navigate("/property-analyser");
                }}
              />
            </div>
          </>
        }
        handleEditClick={() => {
          setIsPorfolioSuccess(false);
        }}
        header="Property added to Portfolio Tracker"
        showHeader={true}
        visible={isPorfolioSuccess}
      ></REIDialoge>
      <div className="gap-3">
        <div className="w-full mb-5">
          You are about to save this property to your Portfolio Tracker. This is also your chance to assign it to a
          particular entity if you have several, and if you don't then simply leave as is and select 'save'.
        </div>
        <div className="w-full">
          <AssignEntity
            memberEntities={memberEntity}
            setIsValid={() => {}}
            updateProperty={handleUpdateProperty}
          ></AssignEntity>
        </div>
        <div>
          {loanDetails &&
            loanDetails.map((loan, index) => {
              return (
                <div className="relative rounded-lg pl-3 pr-1 py-3 border-solid border border-gray-300 bg-white mt-2">
                  <div>
                    <h3>Loan {index + 1}</h3>
                  </div>
                  <div>
                    <LoanDetailsTab
                      key={loan.LoanId}
                      loan={{ ...loan, isFromPortfolio: true }}
                      updateLoanValue={updateLoanValue}
                    ></LoanDetailsTab>
                  </div>
                </div>
              );
            })}
        </div>
        <div className="relative rounded-lg pl-3 pr-1 py-3 border-solid border border-gray-300 bg-white mt-2 flex-row gap-2 w-full">
          <div className="w-full flex">
            <div className="flex w-[50%] content-center p-1">
              <label className="content-center w-[20%]">Tenants</label>
              <REIInputText
                name="tanents"
                type="text"
                onChange={(e) => {
                  setValues("Tenants", e.target.value);
                  setTanents(e.target.value);
                }}
                value={tanents}
              ></REIInputText>
            </div>
            <div className="flex w-[50%] p-1">
              <div className="w-[20%] content-center">Lease start date</div>
              <DatePicker
                dateString={
                  lease?.StartDate
                    ? getDateStringWithGivenFormat(new Date(lease.StartDate))
                    : getDateStringWithGivenFormat(new Date(Date.now()))
                }
                onChange={(date) => setLeaseData("StartDate", getDateStringWithGivenFormat(date.value))}
              ></DatePicker>
            </div>
          </div>
          <div className="w-full flex gap-2">
            <div className="flex w-[50%] content-center p-1">
              <label className="content-center w-[20%]">Lease end</label>
              <DatePicker
                dateString={
                  lease?.EndDate
                    ? getDateStringWithGivenFormat(new Date(lease.EndDate))
                    : getAdjustedFormattedDate(new Date(Date.now()), { years: 1 })
                }
                onChange={(date) => setLeaseData("EndDate", getDateStringWithGivenFormat(date.value))}
              ></DatePicker>
            </div>
            <div className="flex w-[50%] p-1">
              <div className="w-[20%] content-center">Rent review</div>
              <DatePicker
                dateString={
                  lease?.ReviewDate
                    ? getDateStringWithGivenFormat(new Date(lease.ReviewDate))
                    : getAdjustedFormattedDate(new Date(Date.now()), { years: 1 })
                }
                onChange={(date) => setLeaseData("ReviewDate", getDateStringWithGivenFormat(date.value))}
              ></DatePicker>
            </div>
            <div className="flex w-[50%] p-1">
              <div className="w-[20%] content-center">Purchase date</div>
              <DatePicker
                dateString={
                  addPropertyToPortfolioRequest?.PurchaseDate
                    ? getDateStringWithGivenFormat(new Date(addPropertyToPortfolioRequest.PurchaseDate))
                    : getAdjustedFormattedDate(new Date(Date.now()), { years: 1 })
                }
                onChange={(date) => setValues("PurchaseDate", getDateStringWithGivenFormat(date.value))}
              ></DatePicker>
            </div>
          </div>
          <div className="text-end mt-5 mr-3">
            <REIButton
              onClick={() => {
                moveToPortfolioTracker();
              }}
              label="Next"
            ></REIButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default MoveToPortfolioTracker;
