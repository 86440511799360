import { Slider } from "primereact/slider";
import { REIButton, REIDialoge, REIDropDown, REIInputNumber, REIToastContainer } from "../../../components/ui";
import { useEffect, useRef, useState } from "react";
import { ApiEndPoints } from "../../../utils/endpoints";
import { MEMBER_KEY, Messages, USER_DETAIL } from "../../../utils/constants";
import { httpPost, useHttpGets } from "../../../services/httpService";
import {
  ILoan,
  ILoanTypeCost,
  IMemberEntity,
  IPropertyAnnualExpense,
  IPropertyAnnualRentalReturns,
  IPropertyDepreciationEstimation,
  IPropertyDetailFromSearch,
  IPropertyDetails,
  IPropertyMarketFactor,
  IPropertyOtherPurchaseExpense,
  IPropertyPurchaseCost,
  IRentExpense,
  ITaxMarket,
} from "../../../interface/analysed";
import { formatCurrency, getDateStringWithGivenFormat, nth } from "../../../utils/commonUtil";
import { IUserProfileDetails } from "../../../interface/userdetails";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";
import EntityOwnerShip from "../PropertyStepper/Child/AddEntityOwnership";

export interface IPropertyInvestmentCalculatorProps {
  closeDialog?: () => void;
  wathclistItem?: any;
}

const PropertyInvestmentCalculator: React.FC<IPropertyInvestmentCalculatorProps> = ({ closeDialog, wathclistItem }) => {
  const [rentPw, setRentPw] = useState(0);
  const toastRef = useRef<Toast>(null);
  const [purchasePrice, setPurchasePrice] = useState(0);
  const [estimatedMarekPrice, setEstimatedMarekPrice] = useState(0);
  const [estimatedLandValue, setEstimatedLandValue] = useState(0);
  const [selectedEntity, setSelectedEntity] = useState<IMemberEntity | undefined>();
  const [entityOwn, setEntityOwn] = useState(0);
  const [rentPwDescription, setRentPwDescription] = useState("");
  const [priceMessage, setPriceMessage] = useState("");
  const [landValueMessage, setLandValueMessage] = useState("");
  const [memberEntities, setMemberEntities] = useState<IMemberEntity[]>([]);
  const [propertyEntity, setPropertyEntity] = useState("");
  const [propertyDetails, setPropertyDetails] = useState<IPropertyDetails>();
  const [loading, setLoading] = useState<boolean>(false);
  const memberDetail = JSON.parse(localStorage.getItem(USER_DETAIL) || "{}");
  const [showEntityOwnershipDialog, setShowEntityOwnershipDialog] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (wathclistItem) {
      useHttpGets(
        `${ApiEndPoints.analyser}/WatchlistProperty?MemberId=${localStorage.getItem(MEMBER_KEY)}&WatchlistId=${
          wathclistItem?.WatchlistId
        }`,
        undefined
      )
        .then((result) => {
          if (result?.data != null && !result.error) {
            setPropertyDetails(result.data);
          }
        })
        .catch((error) => {});
    }
  }, [wathclistItem]);

  useEffect(() => {
    if (propertyDetails) {
      setRentPwDescription(generateRentDescription(propertyDetails));
      setRentPw(getRentPwValue(propertyDetails));

      setPurchasePrice(propertyDetails.AdvertisedPrice);
      propertyDetails.AdvertisedPrice === 0 && setPriceMessage("This property does not have an advertised price.");
      propertyDetails.AdvertisedPrice !== 0 &&
        setPriceMessage(
          `(This property is advertised at ${formatCurrency(
            propertyDetails.AdvertisedPrice
          )}. The median listing price for a ${propertyDetails.BedroomLabel} bedroom ${
            propertyDetails.PropertyTypeLabel === "other"
              ? 'property of type "other"'
              : propertyDetails.PropertyTypeLabel.toLowerCase()
          } in ${propertyDetails.Suburb} is ${formatCurrency(propertyDetails.MedianSalePrice)}.)`
        );

      setEstimatedMarekPrice(propertyDetails.AdvertisedPrice);

      if (propertyDetails.AdvertisedPrice > 0) {
        setEstimatedMarekPrice(propertyDetails.AdvertisedPrice);
      } else if (propertyDetails.MedianSalePrice > 0) {
        setEstimatedMarekPrice(propertyDetails.MedianSalePrice);
      } else {
        setEstimatedMarekPrice(0);
      }

      if (
        propertyDetails.EstimatedLandValue > 0 &&
        (propertyDetails.PropertyTypeLabel === "House" || propertyDetails.PropertyTypeLabel === "Unit/Apartment")
      ) {
        var o = propertyDetails.PropertyTypeLabel === "House" ? "30%" : "15%";
        setLandValueMessage(
          `(The default value is based on ${o} of purchase price. Actual land value can be obtained from council rating valuations. This value is used for tax and depreciation estimates where relevant.)`
        );
        setEstimatedLandValue(propertyDetails.EstimatedLandValue);
      }
    }
  }, [propertyDetails]);

  useEffect(() => {
    if (memberDetail) {
      useHttpGets(
        `${ApiEndPoints.tracker}/MemberEntities?memberId=${localStorage.getItem(MEMBER_KEY)}&userCountryCode=${
          memberDetail.Country
        }&memberName=${memberDetail.FirstName + " " + memberDetail.LastName}`,
        undefined
      )
        .then((result) => {
          if (result?.data != null && !result.error) {
            setMemberEntities(result.data);
          }
        })
        .catch((error) => {});
    }
  }, []);

  useEffect(() => {
    if (memberEntities && memberEntities.length > 0) {
      setPropertyEntity(memberEntities[0].MemberEntityId.toString());
      setSelectedEntity(memberEntities[0]);
    }
  }, [memberEntities]);

  const generateRentDescription = (details: IPropertyDetails): string => {
    if (!details) return "";

    const {
      PotentialRent,
      Residential_PercentileRent,
      Residential_MedianRent,
      BedroomLabel,
      PropertyTypeLabel,
      Suburb,
      Percentile,
    } = details;
    let description = "";

    const propertyType = PropertyTypeLabel === "other" ? 'property of type "other"' : PropertyTypeLabel.toLowerCase();

    if (PotentialRent && PotentialRent > 0) {
      description = `(Rent of ${formatCurrency(
        PotentialRent
      )} per week has been derived directly from the property listing. `;
      if (Residential_MedianRent && Residential_MedianRent > 0) {
        description += `The median rent for a ${BedroomLabel}-bedroom ${propertyType} in ${Suburb} is ${formatCurrency(
          Residential_MedianRent
        )} per week.)`;
      } else {
        description += ")";
      }
    } else if (Residential_PercentileRent && Residential_PercentileRent > 0) {
      description = `(Rent of ${formatCurrency(
        Residential_PercentileRent
      )} per week has been estimated based on the listing price of the property relative to the median, and advertised rental ranges for similarly priced property in the suburb. `;
      if (Residential_MedianRent && Residential_MedianRent > 0) {
        description += `The median rent for a ${BedroomLabel}-bedroom ${propertyType} in ${Suburb} is ${formatCurrency(
          Residential_MedianRent
        )} per week and this property is listed in the ${Percentile}${nth(Percentile)} percentile of listings.)`;
      } else {
        description += ")";
      }
    } else if (Residential_MedianRent && Residential_MedianRent > 0) {
      description = `The median rent for a ${BedroomLabel}-bedroom ${propertyType} in ${Suburb} is ${formatCurrency(
        Residential_MedianRent
      )} per week.`;
    }

    return description;
  };

  const getRentPwValue = (details: IPropertyDetails) => {
    if (details.PotentialRent && details.PotentialRent > 0) {
      return details.PotentialRent;
    } else if (details.Residential_PercentileRent && details.Residential_PercentileRent > 0) {
      return details.Residential_PercentileRent;
    } else if (details.Residential_MedianRent && details.Residential_MedianRent > 0) {
      return details.Residential_MedianRent;
    }
    return 0;
  };

  const handleOnEntityChange = (e: any) => {
    setPropertyEntity(e.value);
    const selectedEntity = memberEntities.find((entity) => entity.MemberEntityId === e.value);
    setSelectedEntity(selectedEntity);
  };

  const onInvestmentCalculatorNext = async () => {
    setLoading(true);
    await getUserProfile().then(async (data) => {
      if (data && data.data) {
        const usersProfile = data.data as IUserProfileDetails;
        const stampDutyDetails = await calculateStampDuty(usersProfile);
        if (stampDutyDetails && stampDutyDetails.data) {
          addPropertyToAnaylser(stampDutyDetails.data, usersProfile);
        } else {
          console.log("Error calculating stamp duty");
        }
      }
    });
    setLoading(false);
  };

  const getUserProfile = async () => {
    const data = await useHttpGets(
      `${ApiEndPoints.analyser}/UserDefault?ModulesId=${localStorage.getItem(MEMBER_KEY)}&Country=${
        memberDetail.Country
      }&memberName=${memberDetail.FirstName + " " + memberDetail.LastName}`,
      undefined
    );
    return data;
  };

  const calculateStampDuty = async (usersProfile: IUserProfileDetails) => {
    if (!propertyDetails) return;
    var deposite = purchasePrice * (usersProfile.LoanDeposit.CashDepositPercentage / 100);
    const data = await useHttpGets(
      `${ApiEndPoints.analyser}/Stampduty?Price=${purchasePrice}&Deposit=${deposite}&State=${propertyDetails?.StateRegion}`,
      undefined
    );
    return data;
  };

  const getLoanTypeCost = (usersProfile: IUserProfileDetails, stampDuty: number) => {
    var cashDeposit =
      usersProfile.LoanDeposit.EnterPercentage === "1"
        ? purchasePrice * (usersProfile.LoanDeposit.CashDepositPercentage / 100) +
          purchasePrice * (usersProfile.LoanDeposit.EquityDepositPercentage / 100)
        : usersProfile.LoanDeposit.CashDeposit + usersProfile.LoanDeposit.EquityDeposit;

    return {
      Loans: [
        {
          LoanId: 0,
          Name: "1st loan",
          ReferenceCode: "Ref1",
          IsPrincipal: String(usersProfile.MortgageType.PrincipalNInterest),
          StartDate: getDateStringWithGivenFormat(new Date()),
          Amount: purchasePrice - cashDeposit,
          Length_Years: parseInt(usersProfile.MortgageType.LengthOfLoan.toString()) - 1,
          Length_Months: 1,
          InterestRate: parseFloat(usersProfile.MortgageType.InterestRate.toString()),
          EstablishmentFees: parseFloat(usersProfile.LoanCost.EstPrice.toString()),
          ValuationFees: parseFloat(usersProfile.LoanCost.ValuationFee.toString()),
          OtherLoanCosts: parseFloat(usersProfile.LoanCost.OtherLoanCost.toString()),
        } as ILoan,
      ],
      PropertyOtherPurchaseExpense: {
        CapitalInvestment: parseFloat(usersProfile.LoanCost.ImprovementFee.toString()),
        SolicitorsCost: parseFloat(usersProfile.LoanCost.SolicitorFee.toString()),
        StampDuty: parseFloat(stampDuty.toString()),
        OtherCost: 0,
      } as IPropertyOtherPurchaseExpense,
      PropertyPurchaseCost: {
        isEnterValue: String(usersProfile.LoanDeposit.EnterPercentage) === "0" ? "1" : "0",
        isEnterPercentage: String(usersProfile.LoanDeposit.EnterPercentage) === "1" ? "1" : "0",
        CashDeposit: usersProfile.LoanDeposit.CashDepositPercentage,
        EquityDeposit: usersProfile.LoanDeposit.EquityDepositPercentage,
        MarketValueNow: estimatedMarekPrice,
        PurchaseAmount: purchasePrice,
      } as IPropertyPurchaseCost,
    } as ILoanTypeCost;
  };

  const getPropertyDetailFromSearch = () => {
    return {
      PropertyDetailId: 0,
      DateAdded: getDateStringWithGivenFormat(new Date()),
      PropertyName: propertyDetails?.StreetAddress,
      CountryId: propertyDetails?.CountryId ? parseInt(propertyDetails.CountryId.toString()) : 0,
      UserCountryId: memberDetail.Country == "AU" ? 1 : 2,
      MemberEntityId: selectedEntity?.MemberEntityId,
      PortionOwned: entityOwn || 100,
      PropertyTypeId: propertyDetails?.PropertyType ? parseInt(propertyDetails.PropertyType.toString()) : 0,
      BedroomId: propertyDetails?.Bedroom ? parseInt(propertyDetails.Bedroom.toString()) : 0,
      BathroomId: propertyDetails?.Bathroom ? parseInt(propertyDetails.Bathroom.toString()) : 0,
      CarportId: propertyDetails?.Carport ? parseInt(propertyDetails.Carport.toString()) : 0,
      Landsize: propertyDetails?.LandSize_sqm,
      useDefault: "1",
      ModulesId: localStorage.getItem(MEMBER_KEY),
      postcode: propertyDetails?.PostCode,
      locality: propertyDetails?.Suburb,
      state: propertyDetails?.StateRegion,
      District: propertyDetails?.District,
      PropertyLinkId: "",
      WatchlistId: parseInt(wathclistItem?.WatchlistId),
      MemberPropertyId: 0,
      Website: "",
      WebsiteMode: 0,
    } as IPropertyDetailFromSearch;
  };

  const getRentExpense = (usersProfile: IUserProfileDetails) => {
    var rates = 0,
      insurance = 0,
      maintenance = 0,
      bodyCorp = 0;

    if (String(usersProfile.PropertyExpenseStandard.isRatePercentage) === "0") {
      rates = parseFloat(usersProfile.PropertyExpenseStandard.Rates.toString());
      insurance = parseFloat(usersProfile.PropertyExpenseStandard.Insurance.toString());
      maintenance = parseFloat(usersProfile.PropertyExpenseStandard.Repair.toString());
      bodyCorp =
        propertyDetails?.PropertyTypeLabel.toLowerCase() === "unit/apartment" ||
        propertyDetails?.PropertyTypeLabel.toLowerCase() === "townhouse"
          ? parseFloat(usersProfile.PropertyExpenseSpecific.BodyCorporate.toString())
          : 0;
    } else {
      rates = purchasePrice * (parseFloat(usersProfile.PropertyExpenseStandard.RatesPercentage.toString()) / 100);
      insurance =
        purchasePrice * (parseFloat(usersProfile.PropertyExpenseStandard.InsurancePercentage.toString()) / 100);
      maintenance =
        purchasePrice * (parseFloat(usersProfile.PropertyExpenseStandard.RepairPercentage.toString()) / 100);
      bodyCorp =
        propertyDetails?.PropertyTypeLabel.toLowerCase() === "unit/apartment" ||
        propertyDetails?.PropertyTypeLabel.toLowerCase() === "townhouse"
          ? purchasePrice * (parseFloat(usersProfile.PropertyExpenseSpecific.BodyCorpatePercentage.toString()) / 100)
          : 0;
    }

    return {
      PropertyAnnualRentalReturns: {
        isResidential: String(propertyDetails?.Rental_IsResidential) === "1" ? "1" : 0,
        isHoliday: String(propertyDetails?.Rental_IsResidential) === "0" ? "1" : 0,
        RateOfOccupancy: propertyDetails?.Rental_ResidentialNumWeeks
          ? parseFloat(propertyDetails?.Rental_ResidentialNumWeeks.toString())
          : 0,
        WeeklyRent: parseFloat(rentPw.toString()),
        NoOfWeeksPeak: propertyDetails?.Holiday_Peak_NumWeeks
          ? parseInt(propertyDetails?.Holiday_Peak_NumWeeks.toString())
          : 0,
        NoOfWeeksShoulder: propertyDetails?.Holiday_Shoulder_NumWeeks
          ? parseInt(propertyDetails?.Holiday_Shoulder_NumWeeks.toString())
          : 0,
        NoOfWeeksOffPeak: propertyDetails?.Holiday_OffPeak_NumWeeks
          ? parseInt(propertyDetails?.Holiday_OffPeak_NumWeeks.toString())
          : 0,
        OccupancyPeak: propertyDetails?.Holiday_Peak_OccupancyRate
          ? parseFloat(propertyDetails?.Holiday_Peak_OccupancyRate.toString())
          : 0,
        OccupancyShoulder: propertyDetails?.Holiday_Shoulder_OccupancyRate
          ? parseFloat(propertyDetails?.Holiday_Shoulder_OccupancyRate.toString())
          : 0,
        OccupancyOffPeak: propertyDetails?.Holiday_OffPeak_OccupancyRate
          ? parseFloat(propertyDetails?.Holiday_OffPeak_OccupancyRate.toString())
          : 0,
        RatePWPeak: propertyDetails?.Holiday_Peak_MedianRent
          ? parseFloat(propertyDetails?.Holiday_Peak_MedianRent.toString())
          : 0,
        RatePWShoulder: propertyDetails?.Holiday_Shoulder_MedianRent
          ? parseFloat(propertyDetails?.Holiday_Shoulder_MedianRent.toString())
          : 0,
        RatePWOffPeak: propertyDetails?.Holiday_OffPeak_MedianRent
          ? parseFloat(propertyDetails?.Holiday_OffPeak_MedianRent.toString())
          : 0,
      } as IPropertyAnnualRentalReturns,
      PropertyAnnualExpense: {
        Rates: rates,
        LandLease: usersProfile?.PropertyExpenseSpecific?.LandLease
          ? parseFloat(usersProfile.PropertyExpenseSpecific.LandLease.toString())
          : 0,
        ServiceCont: parseFloat(usersProfile.PropertyExpenseStandard.ServiceCont.toString()),
        LettingFee: parseFloat(usersProfile.PropertyExpenseStandard.LettingFee.toString()),
        Cleaning: parseFloat(usersProfile.PropertyExpenseStandard.Cleaning.toString()),
        AgentComm: parseFloat(usersProfile.PropertyExpenseStandard.AgentComm.toString()),
        Insurance: insurance,
        Maintenance: maintenance,
        Gardening: parseFloat(usersProfile.PropertyExpenseSpecific.Gardening.toString()),
        BodyCorp: bodyCorp,
        Other: parseFloat(usersProfile.PropertyExpenseStandard.Others.toString()),
        Yr1_Expense: 0,
      } as IPropertyAnnualExpense,
    } as IRentExpense;
  };

  const getTaxMarket = (usersProfile: IUserProfileDetails) => {
    return {
      PropertyMarketFactor: {
        ConsumerPriceIndex: parseFloat(usersProfile.TaxRateMarketFactors.ConsumerPrice.toString()),
        CapitalGrowth: parseFloat(usersProfile.TaxRateMarketFactors.CapitalGrowth.toString()),
      } as IPropertyMarketFactor,
      PropertyDepreciationEstimation: {
        Quality: propertyDetails?.WBQualityType ? parseInt(propertyDetails?.WBQualityType.toString()) : 0,
        NearestCapitalCity: propertyDetails?.WBNearestCity ? parseInt(propertyDetails?.WBNearestCity.toString()) : 0,
        ConstructionYearComplete: propertyDetails?.ConstructionDate
          ? parseInt(propertyDetails?.ConstructionDate.toString())
          : 0,
        NZRecentRenovation: propertyDetails?.VIRenovationType
          ? parseInt(propertyDetails?.VIRenovationType.toString())
          : 0,
        NZEstimatedLandValue: propertyDetails?.EstimatedLandValue
          ? parseFloat(propertyDetails?.EstimatedLandValue.toString())
          : 0,
        WBPropertyTypeId: propertyDetails?.WBPropertyTypeId,
        CalculateDepreciation: propertyDetails?.CalculateDepreciation,
      } as IPropertyDepreciationEstimation,
    } as ITaxMarket;
  };

  const addPropertyToAnaylser = async (stampDuty: number, usersProfile: IUserProfileDetails) => {
    const propertyDetailFromSearchRequest = {
      LoanTypeCost: getLoanTypeCost(usersProfile, stampDuty),
      RentExpense: getRentExpense(usersProfile),
      TaxMarket: getTaxMarket(usersProfile),
      PropertyDetailFromSearch: getPropertyDetailFromSearch(),
    };

    await httpPost(
      `${ApiEndPoints.analyser}/PropertyAnalyserDetailFromSearchWatchlist/`,
      propertyDetailFromSearchRequest
    )
      .then((result) => {
        if (result.data) {
          toastRef.current?.show({
            severity: "success",
            summary: Messages.SUCCESS,
            detail: "Note Added Successfully",
            life: 2000,
          });

          closeDialog && closeDialog();
          navigate("/property-analyser/analysis-report", {
            state: { propertyAnalyserId: result.data, propertyName: propertyDetails?.StreetAddress },
          });
        }
      })
      .catch(() => {
        toastRef.current?.show({
          severity: "success",
          summary: Messages.ERROR,
          detail: Messages.ERROR,
          life: 2000,
        });
      });
  };

  const renderInputSlider = (label: string, value: number, setValue: any, max: number, step: number = 1000) => (
    <div className="flex w-[60%] p-1 mt-3">
      <span className="w-[31rem] mt-2">{label}</span>
      <Slider
        value={value}
        onChange={(e) => setValue(Array.isArray(e.value) ? e.value[0] : e.value)}
        className="w-full mt-4"
        min={0}
        step={step}
        max={max}
      />
      <REIInputNumber value={value} onValueChange={(e) => setValue(e.target.value)} className="ml-3 w-full h-10" />
    </div>
  );

  const hideEntityOwnershipDialog = () => {
    setShowEntityOwnershipDialog(false);
  };

  return (
    <>
      <REIDialoge
        showHeader={true}
        header={`Add a new entity ownership structure`}
        visible={showEntityOwnershipDialog}
        handleEditClick={hideEntityOwnershipDialog}
        position="top"
        customClass="update-statistics-dialog"
        style={{ width: "55vw" }}
        contentComponent={<EntityOwnerShip closeDialogue={hideEntityOwnershipDialog} />}
      ></REIDialoge>
      <REIToastContainer toastReference={toastRef} />
      <div className="flex flex-wrap gap-2">
        <div className="flex-auto">
          <p>
            Please complete the following details so that Property Analyser can calculate this property's investment
            potential.
          </p>
        </div>
      </div>
      {propertyDetails && (
        <>
          <div className="flex">
            {renderInputSlider("Rent pw ($)", rentPw, setRentPw, 2000, 5)}
            <div className="flex w-[40%]">
              <p className="text-justify">{rentPwDescription}</p>
            </div>
          </div>
          <div className="flex">
            {renderInputSlider(
              "Purchase price ($)",
              purchasePrice,
              setPurchasePrice,
              propertyDetails.AdvertisedPrice * 1.5
            )}
            <div className="flex w-[40%] mt-[-10px]">
              <p className="text-justify">{priceMessage}</p>
            </div>
          </div>
          <div className="flex">
            {renderInputSlider(
              "Est. Market Value ($)",
              estimatedMarekPrice,
              setEstimatedMarekPrice,
              propertyDetails.AdvertisedPrice * 1.5
            )}
            <div className="flex w-[40%]">
              <REIButton label="GET ESTIMATE" classNames="p-button-link p-0 pt-2" onClick={() => {}} />
            </div>
          </div>
          {propertyDetails.CountryId !== 1 && (
            <div className="flex">
              {renderInputSlider(
                "Est. Land Value ($)",
                estimatedLandValue,
                setEstimatedLandValue,
                propertyDetails.AdvertisedPrice * 1.5,
                5
              )}
              <div className="flex w-[40%]">{landValueMessage}</div>
            </div>
          )}
          <div className="flex flex-wrap gap-2">
            <div className="flex-auto">
              <p>
                * Estimated Market Value is not a mandatory field, so you don't need to adjust it. If left unadjusted,
                it will default to the purchase price entered.
              </p>
            </div>
          </div>
          <div className="flex">
            <div className="flex w-[60%] p-1 mt-3">
              <span className="w-[24rem] mt-2">This property belongs to entity:</span>
              <REIDropDown
                value={propertyEntity}
                onSelectionChange={handleOnEntityChange}
                data={memberEntities}
                label="EntityName"
                className="w-full mr-1"
                optionValue="MemberEntityId"
                placeholder="Select an entity"
              />
            </div>
            <div className="flex w-[40%]">
              <REIButton
                label="Create a new entity"
                classNames="p-button-link p-0 pt-2"
                onClick={() => {
                  setShowEntityOwnershipDialog(true);
                }}
              />
            </div>
          </div>
          <div className="flex">
            <div className="flex w-1/4 p-1">
              <span className="w-full mt-2 font-bold">Entity Selected</span>
            </div>
            <div className="flex w-[35%]">
              <span className="w-full mt-2 font-bold">Entity Owns</span>
            </div>
          </div>
          <div className="flex">
            <div className="flex w-1/4 p-1">
              {selectedEntity && (
                <label className="w-[18.5rem] mt-2">
                  <span> {selectedEntity?.EntityName} </span>
                  <REIButton label="(view)" classNames="p-button-link p-0 pt-2" onClick={() => {}} />
                </label>
              )}
            </div>
            <div className="flex w-[35%] p-1">
              <Slider
                value={entityOwn}
                onChange={(e) => setEntityOwn(Array.isArray(e.value) ? e.value[0] : e.value)}
                className="w-[14rem] mt-4"
                min={0}
                step={1}
                max={100}
              />
              <REIInputNumber
                value={entityOwn}
                onValueChange={(e) => setEntityOwn(e.target.value)}
                className="ml-3 w-[14rem] h-10"
              />
            </div>
          </div>
          <div className="flex flex-row justify-end">
            <REIButton label="Next" loading={loading} onClick={onInvestmentCalculatorNext} />
          </div>
        </>
      )}
    </>
  );
};

export default PropertyInvestmentCalculator;
