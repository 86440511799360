import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authReducer from "./slice/authSlice";
import investarSearchReducer from "./slice/investarSearchSlice";
import propertyDetailsSlice from "./slice/propertyDetailsSlice";
import watchListSlice from "./slice/watchlistSlice";
import analysisSummarySlice from "./slice/analyserSlice";
import fileSlice from "./slice/fileUploadSlice"
import myTrackerSlice from "./slice/myTrackerSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  investar: investarSearchReducer,
  propertyDetails: propertyDetailsSlice,
  watchList: watchListSlice,
  files: fileSlice,
  analyser: analysisSummarySlice,
  myTracker: myTrackerSlice
});

export const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch;

export default store;
