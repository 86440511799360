import React, { useEffect, useState } from "react";
import { REIButton, REIDialoge, REIInputNumber } from "../../../../components/ui";
import { IAnalysedProperty, IPropertyOtherPurchaseExpense } from "../../../../interface/addAnalyser";
import { useAnalysedPropertyContext } from "./AnalysedPropertyContext";
import { Slider } from "primereact/slider";
import StampDutyCalculator from "./StampDutyCalculator";

interface OtherPurchaseExpenseProps {
  setIsValid: (isValid: boolean) => void;
  updateProperty: (updated: Partial<IAnalysedProperty>) => void;
}

export const OtherPurchaseExpense: React.FC<OtherPurchaseExpenseProps> = ({ setIsValid, updateProperty }) => {
  const { analysedProperty } = useAnalysedPropertyContext();
  const [propertyPurchaseExpense, setPropertyPurchaseExpense] = useState<IPropertyOtherPurchaseExpense>();
  const [showCalculateStampDuty, setShowCalculateStampDuty] = useState(false);
  const [reset, setReset] = useState<boolean>(false);

  useEffect(() => {
    if (analysedProperty && !reset) {
      setPropertyPurchaseExpense(analysedProperty.LoanTypeCost.PropertyOtherPurchaseExpense);
      setReset(true);
    }
  }, [analysedProperty]);

  const setValues = (name: string, value: string | number) => {
    setPropertyPurchaseExpense((prevState) => {
      if (!prevState) return undefined;

      return {
        ...prevState,
        [name]: value !== undefined ? value : 0,
      };
    });
  };

  useEffect(() => {
    if (propertyPurchaseExpense) {
      setIsValid(propertyPurchaseExpense.CapitalInvestment > 0);
      updateProperty({
        LoanTypeCost: {
          ...analysedProperty?.LoanTypeCost,
          PropertyPurchaseCost: analysedProperty?.LoanTypeCost.PropertyPurchaseCost || {
            CashDeposit: 0,
            MarketValueNow: 0,
            PurchaseAmount: 0,
            EquityDeposit: 0,
            isEnterPercentage: "0",
            isEnterValue: "0",
          },
          Loans: analysedProperty?.LoanTypeCost.Loans || [],
          PropertyOtherPurchaseExpense: propertyPurchaseExpense,
        },
      });
    }
  }, [propertyPurchaseExpense]);

  const hideStampDutyDialog = () => {
    setShowCalculateStampDuty(false);
  };

  return (
    <>
      <REIDialoge
        showHeader={true}
        header={`Stamp duty calculator`}
        visible={showCalculateStampDuty}
        handleEditClick={hideStampDutyDialog}
        position="top"
        customClass="update-statistics-dialog"
        style={{ width: "40vw" }}
        contentComponent={<StampDutyCalculator closeDialogue={hideStampDutyDialog} updateProperty={updateProperty} />}
      ></REIDialoge>

      <div className="w-full pt-2">
        <div className="relative rounded-lg pl-3 pr-1 py-3 border-solid border border-gray-300 bg-white mt-2">
          <div className="default-profile-header-container">
            <h3 className="text-[var(--primary-color)] mt-0 text-lg">Other purchase expenses</h3>
          </div>
          {propertyPurchaseExpense && (
            <div className="mt-4">
              <div className="flex flex-col lg:flex-row lg:w-full gap-2">
                <div className="flex-0 flex-col w-full lg:w-1/2 gap-2">
                  <div className="flex w-full p-1">
                    <div className="w-[20%] mt-2">Capital improvements at purchase ($)</div>
                    <div className="w-[40%] pr-2">
                      <Slider
                        value={propertyPurchaseExpense.CapitalInvestment}
                        onChange={(e) => setValues("CapitalInvestment", Array.isArray(e.value) ? e.value[0] : e.value)}
                        className="w-full mt-4"
                        min={0}
                        max={1000000}
                      />
                    </div>
                    <div className="w-[40%] flex px-2">
                      <REIInputNumber
                        value={propertyPurchaseExpense.CapitalInvestment}
                        onValueChange={(e) => setValues("CapitalInvestment", e.target.value)}
                        className="w-full"
                        useGrouping={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-0 flex-col w-full lg:w-1/2 gap-2">
                  <div className="flex w-full p-1">
                    <div className="w-[22%] mt-2">
                      Stamp duty($) (
                      <REIButton
                        label="Calculate"
                        classNames="p-button-link p-0"
                        onClick={() => {
                          setShowCalculateStampDuty(true);
                        }}
                      />
                      )
                    </div>
                    <div className="w-[38%] pr-2">
                      <Slider
                        value={propertyPurchaseExpense.StampDuty}
                        onChange={(e) => setValues("StampDuty", Array.isArray(e.value) ? e.value[0] : e.value)}
                        className="w-full mt-4"
                        min={0}
                        max={1000000}
                      />
                    </div>
                    <div className="w-[40%] flex px-2">
                      <REIInputNumber
                        value={propertyPurchaseExpense.StampDuty}
                        onValueChange={(e) => setValues("StampDuty", e.target.value)}
                        className="w-full"
                        useGrouping={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col lg:flex-row lg:w-full gap-2">
                <div className="flex-0 flex-col w-full lg:w-1/2 gap-2">
                  <div className="flex w-full p-1">
                    <div className="w-[20%] mt-2">Solicitor’s cost ($)</div>
                    <div className="w-[40%] pr-2">
                      <Slider
                        value={propertyPurchaseExpense.SolicitorsCost}
                        onChange={(e) => setValues("SolicitorsCost", Array.isArray(e.value) ? e.value[0] : e.value)}
                        className="w-full mt-4"
                        min={0}
                        max={1000000}
                      />
                    </div>
                    <div className="w-[40%] flex px-2">
                      <REIInputNumber
                        value={propertyPurchaseExpense?.SolicitorsCost}
                        onValueChange={(e) => setValues("SolicitorsCost", e.target.value)}
                        className="w-full"
                        useGrouping={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-0 flex-col w-full lg:w-1/2 gap-2">
                  <div className="flex w-full p-1">
                    <div className="w-[20%] mt-2">Other costs ($)</div>
                    <div className="w-[40%] pr-2">
                      <Slider
                        value={propertyPurchaseExpense.OtherCost}
                        onChange={(e) => setValues("OtherCost", Array.isArray(e.value) ? e.value[0] : e.value)}
                        className="w-full mt-4"
                        min={0}
                        max={1000000}
                      />
                    </div>
                    <div className="w-[40%] flex px-2">
                      <REIInputNumber
                        value={propertyPurchaseExpense?.OtherCost}
                        onValueChange={(e) => setValues("OtherCost", e.target.value)}
                        className="w-full"
                        useGrouping={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default OtherPurchaseExpense;
