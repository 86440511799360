import React, { useCallback, useEffect, useRef, useState } from "react";
import PropertyDescription from "./PropertyDescription";
import PropertyAnalysis from "./PropertyAnalysis";
import PropertyDisclaimer from "./PropertyDisclaimer";
import ViewPropertyDetails, { ISnapshotTableData, TableData } from "../../../interface/Watchlist/propertyDetails";
import {
  ColumnType,
  COUNTRY,
  PASnapshotDataMap,
  PAUnitStasticMap,
  ShowHide_MSG,
  SnapshotDataMap,
  SuburbStatsAUMap,
  SuburbStatsNZMap,
  USER_DETAIL,
} from "../../../utils/constants";
import DataMapper from "../../../utils/dataMapper";
import { REIButton, REIDataTable } from "../../../components/ui";
import { httpPost } from "../../../services/httpService";
import { ApiEndPoints } from "../../../utils/endpoints";
import REITooltip from "../../../components/ui/tooltip";
import BoxComponent from "./BoxComponent";
import { IREIGraphColor, IREIGraphDataSet } from "../../../interface/graph";
import Graph from "../../../components/ui/graph";
import { formatCurrencyWithDecimal } from "../../../utils/commonUtil";
import { classNames } from "primereact/utils";
import MapWithMarker from "../../../components/shared/mapWithMarker";
import REIFileUpload from "../../../components/shared/fileUpload";

interface viewPropertyInformation {
  viewPropertyData: ViewPropertyDetails;
}

interface IMarker {
  lat: number;
  lng: number;
  title: string;
}

const PropertyInformation: React.FC<viewPropertyInformation> = ({ viewPropertyData }) => {
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [propertyInformation, setPropertyInformation] = useState<ViewPropertyDetails>(viewPropertyData);
  const [suburbStats, setSuburbStats] = useState<TableData[]>();
  const [isImageComponent, setIsImageComponent] = useState<boolean>(true);

  const graphValues =
    propertyInformation?.MedianPriceChangeAU && propertyInformation?.MedianPriceChangeAU?.length > 0
      ? propertyInformation.MedianPriceChangeAU.map((value) => ({
          x: 0,
          y: value.PriceChange.Price,
          growth: value.PriceChange.Growth,
          label: value.PriceChange.Label,
        }))
      : "";
  const GrapahVisible = graphValues.length == 0 ? false : true;
  const [snapshotData, setSnapshotData] = useState<ISnapshotTableData[]>();
  const graphLabelText =
    propertyInformation?.MedianPriceChangeAU && propertyInformation?.MedianPriceChangeAU?.length > 0
      ? propertyInformation.MedianPriceChangeAU.map((data) => data.PriceChange.Label)
      : "";
  const MemebreDetail = JSON.parse(localStorage.getItem(USER_DETAIL));
  let isDataNull = useRef<boolean>(false);
  const graphTitle = useRef<string>("");
  const graphLabelColor = useRef<string>("");
  let growthRate = useRef<string>("");
  let suburb = useRef<string>("");
  let quickAnalysisData;

  const [center, setCenter] = useState({ lat: 0, lng: 0 });
  const [marker, setMarker] = useState<IMarker[]>([]);
  const zoom = 15;

  const getCoordinatesFromAddress = async (address: string): Promise<{ lat: number; lng: number } | null> => {
    const apiKey = process.env.GOOGLE_MAPS_API_KEY;
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      address
    )}&key=${apiKey}`;

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Failed to fetch data from Google Maps API");
      }

      const data = await response.json();

      if (data.status === "OK" && data.results.length > 0) {
        const { lat, lng } = data.results[0].geometry.location;
        return { lat, lng };
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  };

  useEffect(() => {
    async function fetchData() {
      {
        const labelMap = viewPropertyData.isFromPropertyAnalyser
          ? PAUnitStasticMap
          : propertyInformation.PropertyDetails.Country == COUNTRY.AU
          ? SuburbStatsAUMap
          : SuburbStatsNZMap;
        const data = DataMapper({
          tableData: viewPropertyData.isFromPropertyAnalyser
            ? viewPropertyData.PAMarketingStatstics
            : viewPropertyData.PropertyStatistics,
          labelMap,
          propertyDetails: viewPropertyData.PropertyDetails,
        });
        if (data != undefined) {
          setSuburbStats(data);
          setPropertyInformation(viewPropertyData);
          GrapahVisible ? getGraphLabel() : "";
        }
      }
    }

    if (viewPropertyData != undefined) {
      fetchData();
      const googleMapsUrl = viewPropertyData?.GoogleMapsLinks?.GoogleMapsUrl?.split("?m=")[1];
      if (googleMapsUrl) {
        getCoordinatesFromAddress(googleMapsUrl).then((response) => {
          if (response) {
            setCenter(response);
            setMarker([{ lat: response.lat, lng: response.lng, title: googleMapsUrl }]);
          }
        });
      }
    }
  }, [viewPropertyData.PropertyDetails.PropertyId]);

  useEffect(() => {
    async function fetchSnapshotData() {
      if (!viewPropertyData.isFromPropertyAnalyser) {
        const parameter = {
          MemberId: propertyInformation.PropertyDetails.MemberId,
          MemberName: MemebreDetail.FirstName + " " + MemebreDetail.LastName,
          PropertyCountryCode: propertyInformation.PropertyDetails.Country,
          PropertyLinkId: propertyInformation.PropertyDetails.LinkIDHash,
          UserCountryCode: MemebreDetail.Country,
          Website: propertyInformation.PropertyDetails.Website,
          WebsiteMode: propertyInformation.PropertyDetails.WebsiteMode,
        };
        await httpPost(`${ApiEndPoints.analyser}/QuickAnalyseList2/`, parameter).then((response) => {
          if (response.status == 200) {
            quickAnalysisData = response.data[0];
          }
        });
      }

      const storeAbsoluteNetYieldData = (data) => {
        for (let key in data) {
          if (key.startsWith("NetYield")) {
            const absoluteValue = Math.abs(parseFloat(data[key]));
            data[key] = absoluteValue.toFixed(1) + "%";
          }
        }
        return data;
      };

      const responseData = !viewPropertyData.isFromPropertyAnalyser
        ? storeAbsoluteNetYieldData(quickAnalysisData)
        : storeAbsoluteNetYieldData(viewPropertyData.PASummarySnapshot);
      const data = DataMapper({
        tableData: responseData,
        labelMap: viewPropertyData.isFromPropertyAnalyser ? PASnapshotDataMap : SnapshotDataMap,
        snapshotData: true,
      });

      isDataNull.current = viewPropertyData.isFromPropertyAnalyser ? false : responseData.DisplayGraph ? false : true;

      if (data != undefined && !isDataNull.current) {
        const formattedData = FormatSnapshotData(data);
        setSnapshotData(formattedData);
      }

      suburb.current = viewPropertyData.PropertyDetails.Suburb.split(" ")
        .map((word) => {
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        })
        .join(" ");

      growthRate.current = `${Math.abs(Number(responseData.LongTermGrowthSuburbRate)).toLocaleString("en-US", {
        maximumFractionDigits: 2,
        useGrouping: true,
      })}%`;
    }

    if (viewPropertyData.PropertyDetails) {
      fetchSnapshotData();
    }
  }, [viewPropertyData.PropertyDetails.PropertyId]);

  const bodyClassName = (rowData) => {
    if (rowData && (rowData.includes("(") || rowData.includes(")"))) {
      return "text-red-500";
    }
    return "";
  };

  const FormatSnapshotData = (snapshotData: ISnapshotTableData[]) => {
    let formattedData = [];

    snapshotData.map((snapshot) => {
      let formattedobject = {};

      for (const key in snapshot) {
        let formattedValue;
        let value = snapshot[key];

        if (value == "%") {
          formattedValue = "Nan%";
        } else if (Number(value) < 0) {
          formattedValue = `($${Math.abs(Number(value)).toLocaleString("en-US", {
            maximumFractionDigits: 0,
            useGrouping: true,
          })})`;
        } else if (Number(value) > 0) {
          formattedValue = `$${Math.abs(Number(value)).toLocaleString("en-US", {
            maximumFractionDigits: 0,
            useGrouping: true,
          })}`;
        } else if (isNaN(Number(value))) {
          formattedValue = value;
        }

        formattedobject[key] = formattedValue;
      }

      formattedData.push(formattedobject);
    });

    return formattedData;
  };

  const TooltipContent = (
    <div>
      <h2 className="text-sky-500">Median Listing Price Graph</h2>
      <p className="text-xs">
        The Median Listing Price Graph shows the historical median listing prices for the suburb / property type /
        bedrooms combination of the property, over the last 6 years and the previous 3 quarters.
        <br />
        Where there are insufficient listings the graph will rollup to dwelling type / suburb or just suburb.
      </p>
      <p className="bg-lime-300 p-1 text-gray-800">
        This shading is the graph at Suburb, Property Type and Bedrooms level
      </p>
      <p className="bg-yellow-200 p-1 text-gray-800"> This shading is the graph at Suburb and Property Type level</p>
      <p className="bg-orange-300 p-1 text-gray-800"> This shading is the graph at Suburb only level</p>
    </div>
  );

  const snapShotTooltipContent = (
    <div>
      <h2 className="text-cyan-600">Snapshot</h2>
      <p className="text-black text-sm">
        The snapshot is a summary view of how this property might perform over a 1yr and 10yr period. <br />
        It shows possible outcomes for pre tax cashflow, gross yields, and overall returns.
      </p>
      <p className="text-black text-sm">The analysis is calculated on the first available of:</p>
      <div className="bg-gray-50 h-8 content-center border-l-2 border-r-0 border-t-0 border-b-0 border-solid border-gray-400 text-sm">
        <span className="ml-5">
          Listed property price or median property price at suburb, property type and bedrooms.
        </span>
      </div>
      <p className="text-black text-sm">and the first available of:</p>
      <div className="bg-gray-50 h-8 content-center border-l-2 border-r-0 border-t-0 border-b-0 border-solid border-gray-400 text-sm">
        <span className="ml-5">
          Potential rent, percentile rent or median rent at the suburb, property type and bedrooms.
        </span>
      </div>
      <p className="text-black text-sm">
        Overall returns include long term capital growth projections, and are based on
        <br />
        <span>
          the latest analysis for {viewPropertyData.PropertyDetails.Category} in {suburb.current}
        </span>
        <span hidden>your default capital growth rate</span>
        <span> of {growthRate.current}</span>
      </p>
    </div>
  );

  const snapShotTooltip = (
    <div>
      Snapshot
      <span>
        <REITooltip
          targetId={`snapshot-tooltip${viewPropertyData.PropertyDetails.LinkIDHash}`}
          content={snapShotTooltipContent}
          position="left"
        ></REITooltip>
        <i className={`pi pi-question-circle p-1 snapshot-tooltip${viewPropertyData.PropertyDetails.LinkIDHash}`} />
      </span>
    </div>
  );

  const renderGraphTitle = () => {
    return (
      <div className={`text-center text-sm pt-1 pb-1 font-medium ${graphLabelColor.current}`}>
        <span>
          <REITooltip targetId="median-graph-tooltip" content={TooltipContent} position="right"></REITooltip>
          Median Listing Price
          <i className="pi pi-question-circle p-1 median-graph-tooltip" />
          <br />
          {graphTitle.current}
        </span>
      </div>
    );
  };

  const getGraphLabel = () => {
    if (propertyInformation.MedianPriceChangeAU.length != 0) {
      switch (propertyInformation.MedianPriceChangeAU[0].PriceChange.StatLevel) {
        case "4":
          graphTitle.current =
            propertyInformation.PropertyDetails.BedRooms +
            " b/r " +
            propertyInformation.PropertyDetails.Category.toLowerCase();
          graphLabelColor.current = "bg-green-300";
          break;
        case "3":
          graphTitle.current = propertyInformation.PropertyDetails.Category;
          graphLabelColor.current = "bg-yellow-200";
          break;
        case "2":
          graphTitle.current = propertyInformation.PropertyDetails.StatisticsSuburb.toUpperCase();
          graphLabelColor.current = "bg-orange-300";
          break;
        default:
          break;
      }
    }
  };

  const suburbStatsColumns = [
    {
      field: "title",
      position: "left",
    },
    {
      field: "data",
      position: "right",
    },
  ];

  const paSnapshotColumns = [
    {
      field: "title",
      position: "left",
      header: snapShotTooltip,
      width: "w-1/2",
    },
    {
      field: "ValueYR1",
      header: "Yr 1",
      position: "right",
      type: ColumnType.negativeValues,
      width: "w-1/4",
      bodyClassName: bodyClassName,
    },

    {
      field: "ValueYR5",
      position: "right",
      header: "Yr 5",
      type: ColumnType.negativeValues,
      width: "w-1/4",
      bodyClassName: bodyClassName,
    },
    {
      field: "ValueYR3",
      header: "Yr 3",
      position: "right",
      type: ColumnType.negativeValues,
      width: "w-1/4",
      bodyClassName: bodyClassName,
    },

    {
      field: "ValueYR10",
      position: "right",
      header: "Yr 10",
      type: ColumnType.negativeValues,
      width: "w-1/4",
      bodyClassName: bodyClassName,
    },
  ];

  const snapshotColumns = [
    {
      field: "title",
      position: "left",
      header: snapShotTooltip,
      width: "w-1/2",
    },
    {
      field: "ValueYR1",
      header: "Yr 1",
      position: "right",
      type: ColumnType.negativeValues,
      width: "w-1/4",
      bodyClassName: bodyClassName,
    },

    {
      field: "ValueYR10",
      position: "right",
      header: "Yr 10",
      type: ColumnType.negativeValues,
      width: "w-1/4",
      bodyClassName: bodyClassName,
    },
  ];

  const onShowPropertyDetails = () => {
    setShowDetails(!showDetails);
  };

  const customOptions = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            const yValue = tooltipItem.dataset.data[tooltipItem.dataIndex];
            const growth =
              tooltipItem.dataset.extraData[tooltipItem.dataIndex] !== "N/A"
                ? tooltipItem.dataset.extraData[tooltipItem.dataIndex]
                : "";
            return [formatCurrencyWithDecimal(yValue), growth];
          },
        },
        multiLine: true,
      },
    },

    aspectRatio: 1,
    scales: {
      y: {
        grid: {
          display: false,
        },
        beginAtZero: true,
        ticks: {
          font: {
            size: 10,
          },
        },
      },
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 10,
          },
        },
      },
    },
  };

  const renderGraphColors = (data: any[]): IREIGraphColor => {
    const colors: string[] = [];
    const borders: string[] = [];

    for (const point of data) {
      if (point.label && point.label.includes("Mth Ago")) {
        colors.push("rgb(206, 205, 205)");
        borders.push("rgb(207, 206, 206)");
      } else if (parseFloat(point.growth.replace(/%/g, "")) < 0) {
        colors.push("rgba(240,83,67,255)");
        borders.push("rgba(241,84,68,256)");
      } else if (point.y > 0) {
        colors.push("rgba(35,214,83,0.8)");
        borders.push("rgba(35,214,83,0.8)");
      }
    }
    return { backgroundColor: colors, borderColor: borders };
  };

  const renderDataSet = (): IREIGraphDataSet[] => {
    if (graphValues.length != 0) {
      const dataSet = [
        {
          extraData: graphValues.map((point) => point.growth),
          data: graphValues.map((point) => point.y),
          backgroundColor: renderGraphColors(graphValues).backgroundColor,
          borderColor: renderGraphColors(graphValues).borderColor,
        },
      ];
      return dataSet;
    } else {
      return [];
    }
  };

  return (
    <>
      <div className="flex lg:flex-row flex-col bg-gray-100">
        <BoxComponent cssClass="m-2">
          <div className="relative">
            {!viewPropertyData.isFromPropertyAnalyser && (
              <>
                <img
                  src={propertyInformation.PropertyDetails.ThumbnailURL}
                  onClick={() => {
                    window.open(viewPropertyData.PropertyDetails.Link);
                  }}
                  className="w-full h-56 object-fill rounded-md cursor-pointer"
                />
                <div className="absolute bottom-1 w-full rounded-b-md bg-black bg-opacity-50 text-white text-center p-2">
                  <a
                    href={viewPropertyData.PropertyDetails.Link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-white no-underline text-sm"
                  >
                    View Property
                  </a>
                </div>
              </>
            )}

            {isImageComponent && viewPropertyData.isFromPropertyAnalyser && (
              <REIFileUpload propertyData={viewPropertyData} editorMode="Basic"></REIFileUpload>
            )}

            {!isImageComponent && center && (
              <div className="w-full h-56 object-fill">
                <MapWithMarker center={center} markers={marker} zoom={zoom} />
              </div>
            )}

            {viewPropertyData.isFromPropertyAnalyser && (
              <div className="absolute bottom-0 right-0 flex space-x-1">
                <REIButton icon="pi pi-image" label="" onClick={() => setIsImageComponent(true)}></REIButton>
                <REIButton
                  icon="pi pi-map"
                  classNames="ml-1"
                  label=""
                  onClick={() => setIsImageComponent(false)}
                ></REIButton>
              </div>
            )}
          </div>
        </BoxComponent>
        <BoxComponent cssClass="m-2 ml-0">
          <div className="text-sm font-bold">
            {`${viewPropertyData.PropertyDetails.Suburb} ${
              viewPropertyData.PropertyDetails.BedRooms
            } b/r ${viewPropertyData.PropertyDetails.Category.toLowerCase()} ${" "}Statistics`}
            <div className="panel-box">
              <REIDataTable
                data={suburbStats}
                columns={suburbStatsColumns}
                customclass="hide-datatable-header"
              ></REIDataTable>
            </div>
          </div>
        </BoxComponent>
        <BoxComponent cssClass="m-2 ml-0">
          {GrapahVisible && (
            <>
              {renderGraphTitle()}

              <Graph
                graphType="bar"
                dataSet={renderDataSet()}
                labels={graphLabelText}
                customOptions={customOptions}
                customClass="grow-0 justify-center"
                style={{ borderRadius: "6px" }}
              ></Graph>
            </>
          )}
          {!GrapahVisible && (
            <div>
              <div className="text-xs p-2 bg-zinc-300 mb-1" hidden={GrapahVisible}>
                No median price graph. There isn't sufficient data to provide a graph.
              </div>
            </div>
          )}
        </BoxComponent>

        <BoxComponent cssClass="m-2 ml-0 align-baseline">
          {snapshotData && (
            <div className="panel-box w-full" hidden={isDataNull.current}>
              <REIDataTable
                data={snapshotData}
                columns={viewPropertyData.isFromPropertyAnalyser ? paSnapshotColumns : snapshotColumns}
                customclass="leading-3"
              ></REIDataTable>
            </div>
          )}
          {!snapshotData && isDataNull.current && (
            <div className="text-xs p-2 bg-zinc-300 mb-1" hidden={!isDataNull.current}>
              No snapshot table.There isn't sufficient data to provide a snapshot.
            </div>
          )}
        </BoxComponent>
      </div>

      <div>
        <div className="p-2 text-end">
          <span className="cursor-pointer text-blue-700" onClick={onShowPropertyDetails}>
            {showDetails ? ShowHide_MSG.HIDE : ShowHide_MSG.SHOW}
            <i
              className={classNames("pi cursor-pointer align-middle", !showDetails ? "pi-angle-down" : "pi-angle-up")}
              style={{ fontSize: "0.5rem", padding: "2px" }}
            ></i>
          </span>
        </div>
      </div>

      {showDetails && (
        <>
          <PropertyDescription propertyDescription={propertyInformation}></PropertyDescription>
          <PropertyAnalysis propertyAnalysis={propertyInformation}></PropertyAnalysis>
          <PropertyDisclaimer></PropertyDisclaimer>
        </>
      )}
    </>
  );
};

export default React.memo(PropertyInformation);
