import React, { useEffect, useState } from "react";
import { IAnalysisSummaryResponse } from "../../../../interface/analysisSummary";
import { useSelector } from "react-redux";
import { IREIGraphDataSet } from "../../../../interface/graph";
import Graph from "../../../../components/ui/graph";
import {
  formatCurrencyWithDecimal,
  formatNumberWithDecimal,
  sortObjectByNumericKeys,
} from "../../../../utils/commonUtil";
import { YEARS } from "../../../../utils/constants";

const WhoPays: React.FC = () => {
  const analysisData: IAnalysisSummaryResponse = useSelector((state: any) => state.analyser.analysisSummary);
  const [chartData, setChartData] = useState([] as IREIGraphDataSet[]);
  const [chartOptions, setChartOptions] = useState({});

  const extractData = (dataObject: any, ObjectName: any) => {
    const sortedData = sortObjectByNumericKeys(dataObject);

    const data = Object.entries(sortedData)
      .map(([key, value]) => {
        if (ObjectName == "You") {
          let youContribution = Number(formatNumberWithDecimal(value as number, 2, 2).replace(",", "")) * -1;
          youContribution = youContribution < 0 ? 0 : youContribution;
          return youContribution;
        } else {
          return Number(formatNumberWithDecimal(Math.abs(value as number), 2, 2).replace(",", ""));
        }
      })
      .slice(1, 11);

    return data;
  };

  useEffect(() => {
    const taxManData = extractData(analysisData.WhoPays.UpdatedTaxman, "UpdatedTaxman");
    const tenantsData = extractData(analysisData.WhoPays.Tenants, "Tenants");
    const youData = extractData(analysisData.WhoPays.You, "You");

    const TooltipData: number[] = taxManData.map(
      (num, index) => num + (tenantsData[index] || 0) + (youData[index] || 0)
    );

    const data = [
      {
        type: "bar",
        label: "Tenants",
        extraData: TooltipData,
        data: tenantsData,
        backgroundColor: [" rgb(206, 205, 205)"],
      },
      {
        type: "bar",
        label: "Taxman",
        extraData: TooltipData,
        data: taxManData,
        backgroundColor: [" rgb(75, 192, 192)"],
      },
      {
        type: "bar",
        label: "You",
        extraData: TooltipData,
        data: youData,
        backgroundColor: [" rgb(250, 97, 97)"],
      },
    ];

    const options = {
      maintainAspectRatio: true,
      aspectRatio: 3.8,
      plugins: {
        legend: {
          display: true,
          position: "right",
        },
        tooltip: {
          title: "",
          callbacks: {
            label: (tooltipItem: any) => {
              const correspondingValue = YEARS.find(
                (key) => key.label === tooltipItem.label.replace("yr", "Year ")
              )?.label;

              const totalPay = `Total ${correspondingValue}: ${formatCurrencyWithDecimal(
                tooltipItem.dataset.extraData[tooltipItem.dataIndex]
              )}`;

              const variablePay = `${tooltipItem.dataset.label} pays: ${formatCurrencyWithDecimal(
                tooltipItem.dataset.data[tooltipItem.dataIndex]
              )}`;

              return [variablePay, totalPay];
            },
          },
          multiLine: true,
        },
      },
      scales: {
        y: {
          height: 6000,
          stacked: true,
          stackWeight: 1,
          ticks: {
            callback: function (value: any) {
              return "$" + value;
            },
          },
          grid: {
            display: false,
            drawBorder: false,
          },
          beginAtZero: true,
        },
        x: {
          stacked: true,
          grid: {
            display: false,
            drawBorder: false,
          },
        },
      },
    };

    setChartData(data);
    setChartOptions(options);
  }, []);

  return (
    <Graph
      customClass="h-full"
      dataSet={chartData}
      customOptions={chartOptions}
      graphType="bar"
      labels={["yr1", "yr2", "yr3", "yr4", "yr5", "yr6", "yr7", "yr8", "yr9", "yr10"]}
    ></Graph>
  );
};

export default WhoPays;
