import { Card } from "primereact/card";

const PropertyDisclaimer: React.FC = () => {

    return(
        <Card
        title="Disclaimer"
        className="grid grid-cols-1 text-xs text-justify leading-5">
        <p>
          Please note:
          <br /> 
          1. We will display all statistics where data is available for
          suburbs/postcodes. However some data is not visible where a large
          enough sample isn't available hence statistics may read "N/A" and
          some graphs may be missing.
          <br />
          2.There may be additional overlays that affect this property, please
          check with your local council.
        </p>
      </Card>
    )
}

export default PropertyDisclaimer;