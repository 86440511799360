import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import CashFlow from "./CashFlow";
import ErrorMessage from "../../../components/ui/errorMessage";
import PortfolioTrackerHeader from "../header";
import { Filters } from "../Filters";
import { fetchTrackerIndex } from "../../../store/slice/myTrackerSlice";
import { MEMBER_KEY, MyTrackerTabs, USER_DETAIL } from "../../../utils/constants";
import KeyMemberAtGlance from "./KeyMemberAtGlance";
import Equity from "./Equity";
import { ICashflow } from "../../../interface/propertyTracker";
import MyPropertyAtGlance from "./MyPropertyAtGlance";

const MyTrackerIndex = () => {
  const error = useSelector((state: RootState) => state.myTracker.error);
  const [isShowingTotals, setIsShowingTotals] = useState<boolean>(false);
  const [selectedProfileId, setSelectedProfileId] = useState<number>(0);
  const [selectedEntityId, setSelectedEntityId] = useState<number>(0);
  const memberDetail = useMemo(() => JSON.parse(localStorage.getItem(USER_DETAIL) || "{}"), []);
  const memberId = localStorage.getItem(MEMBER_KEY) || "";
  const dispatch: AppDispatch = useDispatch();
  const trackerData = useSelector((state: RootState) => state.myTracker.trackerData);
  const [filteredData, setFilteredData] = useState<{
    Actuals: ICashflow[];
    Forecast: ICashflow[];
  }>();

  useEffect(() => {
    let Entity;
    let ProfileData;
    ProfileData = selectedProfileId != 0 ? [{ ProfileId: selectedProfileId }] : [];
    Entity = selectedEntityId != 0 ? [{ EntityId: selectedEntityId }] : [];

    const parameter = {
      MemberId: memberId,
      UserCountryCode: memberDetail.Country,
      Entity: Entity,
      Profiles: ProfileData,
      MemberName: `${memberDetail.FirstName} ${memberDetail.LastName}`,
      isFromMyTracker: true,
    };
    dispatch(fetchTrackerIndex(parameter));
  }, [dispatch, memberId, selectedEntityId, selectedProfileId, isShowingTotals]);

  useEffect(() => {
    if (trackerData?.length != 0) {
      filterData();
    }
  }, [trackerData]);

  const filterData = () => {
    if (!trackerData) return;
    let totalData;
    const entityIdToCompare = selectedEntityId === 0 ? "" : selectedEntityId.toString();

    if (isShowingTotals) {
      totalData = trackerData.find((total) => total.EntityId === entityIdToCompare)?.CashflowTotals;
    } else {
      totalData = trackerData.find((total) => total.EntityId === entityIdToCompare)?.CashflowPortions;
    }
    if (totalData) {
      setFilteredData({
        Actuals: totalData.Actuals,
        Forecast: totalData.Forecast,
      });
    }
  };

  const onPortionChange = (istotal: boolean) => {
    setIsShowingTotals(istotal);
  };

  const onSelectionChange = (EntityId: number, ProfileId: number) => {
    setSelectedProfileId(ProfileId);
    setSelectedEntityId(EntityId);
  };

  if (error) {
    return <ErrorMessage message={error} />;
  }

  return (
    <>
      <PortfolioTrackerHeader
        tabindex={MyTrackerTabs.MyTracker}
        tabName="My Tracker"
        children={<Filters onPortionChange={onPortionChange} onSelectionChange={onSelectionChange}></Filters>}
      ></PortfolioTrackerHeader>
      <div className="flex flex-row gap-3 content-center">
        <div className="w-1/2">
          <CashFlow
            trackerData={filteredData}
            entityId={selectedEntityId ? selectedEntityId.toString() : ""}
            showingTotalSummary={isShowingTotals}
          ></CashFlow>
        </div>
        <div className="w-1/2">
          <Equity
            entityId={selectedEntityId ? selectedEntityId.toString() : ""}
            showingTotalSummary={isShowingTotals}
          ></Equity>
        </div>
      </div>
      <div className="flex flex-row content-center">
        <div className="w-full mt-2">
          <KeyMemberAtGlance entityId=""></KeyMemberAtGlance>
        </div>
      </div>
      <div className="flex flex-row content-center">
        <div className="w-full mt-2">
          <MyPropertyAtGlance entityId=""></MyPropertyAtGlance>
        </div>
      </div>
    </>
  );
};

export default MyTrackerIndex;
