import React from "react";
import { BathIcon, BedIcon, CarIcon } from "../../public/assets/images";

interface PropertyInfoProps {
  bathrooms: number;
  bedrooms: number;
  carport: number;
}

const PropertyInfo: React.FC<PropertyInfoProps> = ({ bathrooms, bedrooms, carport }) => {
  return (
    <div className="flex flex-row w-full mt-2">
      <div className="flex items-start w-[70px]">
        <img src={BathIcon} alt="Bath" className="h-6 w-auto" />
        <span className="ml-1 self-start">{bathrooms}</span>
      </div>
      <div className="flex items-start w-[70px]">
        <img src={BedIcon} alt="Bed" className="h-6 w-auto" />
        <span className="ml-1 self-start">{bedrooms}</span>
      </div>
      <div className="flex items-start w-[70px]">
        <img src={CarIcon} alt="Car" className="h-6 w-auto" />
        <span className="ml-1 self-start">{carport}</span>
      </div>
    </div>
  );
};

export default PropertyInfo;
