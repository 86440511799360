import { useEffect, useState } from "react";
import { AUS_MAP_DATA } from "../../utils/mapdata";

interface IAustraliaMapProps {
  currentstate: number;
  onStateChange: (state: string) => void;
}

const AustraliaMap: React.FC<IAustraliaMapProps> = ({ currentstate, onStateChange }) => {
  const [selectedState, setSelectedState] = useState<number>(currentstate);

  useEffect(() => {}, [currentstate]);

  const handleStateClick = (state: any) => {
    setSelectedState(AUS_MAP_DATA[state].id);
    onStateChange(AUS_MAP_DATA[state]);
  };

  return (
    <div className="australia-map max-w-[500px]">
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 480 480"
      >
        {Object.keys(AUS_MAP_DATA).map((state) => (
          <g id={AUS_MAP_DATA[state].id}>
            <title>{AUS_MAP_DATA[state].title}</title>
            <path
              key={AUS_MAP_DATA[state].id}
              className={`au-map__state ${AUS_MAP_DATA[state].name} cursor-pointer ${
                selectedState == AUS_MAP_DATA[state].id ? "selected" : ""
              }`}
              d={AUS_MAP_DATA[state].path}
              onClick={() => handleStateClick(state)}
            />
          </g>
        ))}
      </svg>
    </div>
  );
};

export default AustraliaMap;
