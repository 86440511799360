import { useState } from "react";
import { classNames } from "primereact/utils";
import { REIDropDown, REIInputText, REIMultiSelect } from "../../../../components/ui";

interface StrategyComponentProps {
  selectedDropdownValue: any;
  handleDropdownChange: (e: any, dropdownName: string) => void;
  optionsdata: any;
  strategyKeywords: any;
  strategyOnChange: (e: any) => void;
  keywords: string[];
}

const StrategyComponent: React.FC<StrategyComponentProps> = ({
  selectedDropdownValue,
  handleDropdownChange,
  optionsdata,
  strategyKeywords,
  strategyOnChange,
  keywords,
}) => {
  const [showStrategyOptions, setShowStrategyOptions] = useState(false);
  const [keywordPhrases, setKeywordPhrases] = useState("");
  const [excKeywordPhrases, setExcKeywordPhrases] = useState("");

  const toggleStrategyOptions = () => {
    setShowStrategyOptions(!showStrategyOptions);
  };

  const renderDropdown = (label: string, filterName: string, data: any, optionValue: string, classNames?: string) => (
    <REIDropDown
      value={selectedDropdownValue?.[filterName] || data[0]?.[optionValue]}
      onSelectionChange={(e) => handleDropdownChange(e, filterName)}
      data={data}
      label="Label"
      optionValue={optionValue}
      className={classNames}
    />
  );

  return (
    <div className="">
      <div className="flex flex-col xl:flex-row lg:w-full gap-1">
        <div className="flex-0 w-full">
          <div className="flex flex-col lg:flex-row lg:w-full gap-1">
            <div className="flex-0 w-full">
              <label className="block text-sm font-medium">Price Range</label>
              {renderDropdown(
                "Price Range",
                "priceRangeMin",
                optionsdata?.PropertySpecifics?.PriceRange?.PriceRangeMin || [],
                "ListingPrice_From_ID"
              )}
            </div>
            <div className="flex-0 lg:pt-7">
              <span>to</span>
            </div>
            <div className="flex-0 w-full">
              {renderDropdown(
                "Price Range",
                "priceRangeMax",
                optionsdata?.PropertySpecifics?.PriceRange?.PriceRangeMax || [],
                "ListingPrice_To_ID",
                "w-full lg:mt-5"
              )}
            </div>
          </div>
        </div>
        <div className="flex-0 w-full">
          <label className="block text-sm font-medium">Keyword strategies</label>
          {renderDropdown(
            "Keyword strategies",
            "keywordStrategies",
            optionsdata?.StrategiesCollection?.StrategiesAndKeywords || [],
            "FilterStrategyID"
          )}
        </div>
      </div>

      {showStrategyOptions && (
        <>
          <div className="w-full">
            <label className="block text-sm font-medium">Select Keywords</label>
            <REIMultiSelect
              value={strategyKeywords}
              onChange={strategyOnChange}
              options={keywords}
              optionLabel="Label"
              placeholder="Select Keywords"
              maxSelectedLabels={3}
              className="w-full md:w-20rem"
            />
          </div>
          <div className="flex flex-col lg:flex-row lg:w-full gap-1">
            <div className="flex-0 w-full">
              <label className="block text-sm font-medium">Add this keywords (space-separated)</label>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row lg:w-full gap-1">
            <div className="flex-0 lg:w-[80%] w-full">
              <REIInputText
                type="text"
                name="keywords"
                className="w-full"
                onChange={(e) => {
                  setKeywordPhrases(e.target.value);
                  handleDropdownChange({ value: e.target.value }, "keywordPhrases");
                }}
                value={selectedDropdownValue?.keywordPhrases || keywordPhrases}
              />
            </div>
            <div className="flex-0 lg:w-[80%] w-full">
              {renderDropdown(
                "Keyword Phrase",
                "keywordPhrase",
                optionsdata?.Strategy?.KeywordPhrase || [],
                "FilterKeywordSearchTypeID",
                "w-full"
              )}
            </div>
          </div>
          <div className="flex flex-col lg:flex-row lg:w-full gap-1">
            <div className="flex-0 w-full">
              <label className="block text-sm font-medium">Exclude this keywords (space-separated)</label>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row lg:w-full gap-1">
            <div className="flex-0 lg:w-[80%] w-full">
              <REIInputText
                type="text"
                name="keywords"
                className="w-full"
                onChange={(e) => {
                  setExcKeywordPhrases(e.target.value);
                  handleDropdownChange({ value: e.target.value }, "excKeywordPhrases");
                }}
                value={selectedDropdownValue?.excKeywordPhrases || excKeywordPhrases}
              />
            </div>
            <div className="flex-0 lg:w-[80%] w-full">
              {renderDropdown(
                "Exclude Keyword Phrase",
                "excKeywordPhrase",
                optionsdata?.Strategy?.ExcKeywordPhrase || [],
                "FilterExcludeKeywordSearchTypeID",
                "w-full"
              )}
            </div>
          </div>
        </>
      )}

      <div className="col-span-12 text-end mt-2 text-blue-700">
        <span className="mr-1 pt-2 cursor-pointer" onClick={toggleStrategyOptions}>
          {showStrategyOptions ? "Fewer" : "More"} strategy options
        </span>
        <i
          className={classNames("pi cursor-pointer", !showStrategyOptions ? "pi-angle-down" : "pi-angle-up")}
          onClick={toggleStrategyOptions}
        ></i>
      </div>
    </div>
  );
};

export default StrategyComponent;
