import { MouseEvent } from "react";
import { Button } from "primereact/button";

interface IButtonProps {
  label?: string;
  disabled?: boolean;
  loading?: boolean;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  icon?: string;
  classNames?: string;
  tooltip?: string;
  text?: boolean;
  type?: "button" | "submit" | "reset";
  severity?: "secondary" | "success" | "info" | "warning" | "danger" | "help" | undefined;
  iconPos?: "left" | "right" | undefined;
}

const REIButton: React.FC<IButtonProps> = ({
  label,
  disabled,
  loading,
  onClick,
  icon,
  classNames,
  tooltip,
  text,
  type,
  severity,
  iconPos = "left",
}) => {
  return (
    <Button
      disabled={disabled}
      loading={loading}
      label={label}
      onClick={onClick}
      icon={icon}
      text={text}
      type={type}
      tooltip={tooltip}
      severity={severity}
      iconPos={iconPos}
      className={classNames}
    />
  );
};

export default REIButton;
