import React, { useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Messages } from "primereact/messages";
import { REIButton, REIDialoge, REIDivider, REIDropDown, REIInputText } from "../../../../components/ui";
import DatePicker from "../../../../components/ui/calender";
import { ICashFlow } from "../../../../interface/cashFlow";
import { getDateStringWithGivenFormat } from "../../../../utils/commonUtil";
import { MEMBER_KEY, USER_DETAIL } from "../../../../utils/constants";
import { httpPost } from "../../../../services/httpService";
import { ApiEndPoints } from "../../../../utils/endpoints";

interface PaymentRow {
  id: number;
  dateReceived: string;
  dateFrom: string;
  dateTo: string;
  amount: number;
  notes: string;
  invalidFields?: string[];
}

const RentEditForm: React.FC<{ cashFlowData: ICashFlow; onCancel: () => void }> = ({ cashFlowData, onCancel }) => {
  const [selectedProperty, setSelectedProperty] = useState<string>("");
  const [tableData, setTableData] = useState<PaymentRow[]>([
    {
      id: 0,
      dateReceived: "",
      dateFrom: "",
      dateTo: "",
      amount: 0,
      notes: "",
      invalidFields: [],
    },
  ]);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);
  const toastRef = useRef(null);
  const memberId = localStorage.getItem(MEMBER_KEY);
  const memberDetail = JSON.parse(localStorage.getItem(USER_DETAIL) || "{}");

  const hideDefaultProfile = () => {
    setShowConfirmationDialog(false);
  };

  const showToast = (severity: string, summary: string, detail: string) => {
    toastRef.current?.show({
      severity,
      summary,
      detail,
      life: 2000,
      sticky: true,
    });
  };

  const propertyOptions = cashFlowData.PropertyDetails.map((property) => ({
    label: [
      property.PropertyDetail.StreetAddress,
      property.PropertyDetail.Suburb,
      property.PropertyDetail.State,
      property.PropertyDetail.Postcode,
    ]
      .filter(Boolean)
      .join(", "),
    value: property.PropertyDetail.PortfolioTrackerId,
  }));

  const ValidateData = (): boolean => {
    let isValid: boolean = true;
    const startOfFinancialYear = new Date(new Date().getFullYear(), 6, 1); // Assuming financial year starts on July 1st
    const today = new Date();
    let validationFields: string[] = [];

    if (selectedProperty == "") {
      isValid = false;
      if (toastRef.current) {
        showToast("error", "Error", "Please select a property");
      }
    }

    const updatedTableData = tableData.map((row) => {
      let rowInvalidFields: string[] = [];
      if (!row.dateReceived || !row.dateFrom || !row.amount) {
        if (!row.dateReceived) {
          validationFields.push("Date Received");
          rowInvalidFields.push("dateReceived");
        }

        if (!row.amount) {
          validationFields.push("Amount");
          rowInvalidFields.push("amount");
        }

        if (rowInvalidFields && rowInvalidFields.length > 0) ValidationMessege(rowInvalidFields);
        isValid = false;
      }
      if (row.dateFrom && new Date(row.dateFrom) <= startOfFinancialYear && new Date(row.dateReceived) >= today) {
        const containsDateReceived = validationFields.reduce((acc, curr) => {
          return acc || curr === "Date Received";
        }, false);
        !containsDateReceived && rowInvalidFields.push("dateReceived");
        isValid = false;
        showToast(
          "error",
          "Error",
          "Rental income date is in the future. Please enter a date between the start of the current financial year and today."
        );
      }

      return { ...row, invalidFields: rowInvalidFields };
    });

    setTableData(updatedTableData);

    if (isValid) {
      return true;
    } else {
      return false;
    }
  };

  const handleAddRow = (row) => {
    if (ValidateData()) {
      const newRow: PaymentRow = {
        id: tableData.length + 1,
        dateReceived: "",
        dateFrom: "",
        dateTo: "",
        amount: 0,
        notes: "",
      };
      const updatedTableData = [...tableData];
      updatedTableData.splice(row.id, 0, newRow);
      setTableData(updatedTableData);
    }
  };

  const handleDeleteRow = (rowId: number, isRowEditable: boolean) => {
    if (rowId === 0 && !isRowEditable && tableData.length == 0) {
      setTableData([
        {
          id: 0,
          dateReceived: "",
          dateFrom: "",
          dateTo: "",
          amount: 0,
          notes: "",
        },
      ]);
    } else {
      setTableData(tableData.filter((row) => row.id !== rowId));
    }
  };

  const SaveRentData = async () => {
    const payload = {
      MemberId: memberId,
      MemberCountryCode: memberDetail.Country,
      Actuals: tableData.map((row) => ({
        PortfolioTrackerId: selectedProperty,
        LoanId: 0,
        TrackingItemType: "CashflowRent.RentPA",
        Date: getDateStringWithGivenFormat(new Date(row.dateReceived), "dd/mm/yy"),
        DateFrom: getDateStringWithGivenFormat(new Date(row.dateFrom), "dd/mm/yy"),
        DateTo: getDateStringWithGivenFormat(new Date(row.dateTo), "dd/mm/yy"),
        Amount: row.amount.toString(),
        Description: row.notes,
      })),
      PurchaseCosts: [],
    };

    await httpPost(ApiEndPoints.tracker + "PortfolioTracker/ActualsByBatch/", payload).then((response) => {
      if (response?.error) {
        toastRef.current?.show({
          severity: "error",
          summary: "Error Message",
          detail: "Failed to rent details.",
          life: 3000,
        });
      } else {
        toastRef.current.show({
          severity: "success",
          summary: "Success Message",
          detail: "Rent details saved successfully.",
          life: 2000,
        });
        setTableData([
          {
            id: 0,
            dateReceived: "",
            dateFrom: "",
            dateTo: "",
            amount: 0,
            notes: "",
          },
        ]);
      }
      setShowConfirmationDialog(false);
    });
  };

  const handleTableFieldChange = (rowId: number, field: keyof PaymentRow, value: any) => {
    setTableData((prevTableData) =>
      prevTableData.map((row) => (row.id === rowId ? { ...row, [field]: value, invalidFields: [] } : row))
    );
  };

  const dateBodyTemplate = (row: PaymentRow, field: keyof PaymentRow) => (
    <DatePicker
      dateString={row[field] ? row[field].toString() : ""}
      onChange={(e) => handleTableFieldChange(row.id, field, e.value?.toISOString())}
      dateFormat="dd/mm/yy"
      showIcon={true}
      className="w-full"
      invalid={row.invalidFields?.includes(field) ? true : false}
    />
  );

  const ValidationMessege = (fields: string[]) => {
    toastRef.current?.show({
      severity: "error",
      summary: "Error",
      detail: `Please fill the following fields: ${fields.join(", ")}`,
      life: 2000,
      sticky: true,
    });
    return;
  };

  const renderTable = (isRowEditable: boolean) => {
    return (
      <>
        <DataTable value={tableData} className="editable-table" showGridlines={true}>
          <Column field="id" header="" hidden={true} />
          <Column
            field="dateReceived"
            header="Date Received (dd/mm/yy)"
            body={(row) =>
              isRowEditable
                ? dateBodyTemplate(row, "dateReceived")
                : row.dateReceived
                ? getDateStringWithGivenFormat(new Date(row.dateReceived), "dd/mm/yy")
                : ""
            }
          />
          <Column
            field="dateFrom"
            header="Date From (dd/mm/yy)"
            body={(row) =>
              isRowEditable
                ? dateBodyTemplate(row, "dateFrom")
                : row.dateFrom
                ? getDateStringWithGivenFormat(new Date(row.dateFrom), "dd/mm/yy")
                : ""
            }
            bodyClassName={(rowData) => (!rowData.dateFrom ? "p-invalid" : "")}
          />
          <Column
            field="dateTo"
            header="Date To (dd/mm/yy)"
            body={(row) =>
              isRowEditable
                ? dateBodyTemplate(row, "dateTo")
                : row.dateTo
                ? getDateStringWithGivenFormat(new Date(row.dateTo), "dd/mm/yy")
                : ""
            }
          />
          <Column
            field="amount"
            header="Amount"
            body={(row) =>
              isRowEditable ? (
                <REIInputText
                  name="amount"
                  value={row.amount.toString()}
                  onChange={(e) => handleTableFieldChange(row.id, "amount", parseFloat(e.target.value))}
                  type="number"
                  className={`w-full ${row.amount == 0 ? "p-invalid" : ""}`}
                />
              ) : row.amount ? (
                row.amount.toString()
              ) : (
                ""
              )
            }
          />
          <Column
            field="notes"
            header="Notes"
            body={(row) =>
              isRowEditable ? (
                <REIInputText
                  type="text"
                  name="notes"
                  value={row.notes}
                  onChange={(e) =>
                    isRowEditable ? handleTableFieldChange(row.id, "notes", e.target.value) : row.notes
                  }
                  className="w-full"
                />
              ) : (
                row.notes.toString()
              )
            }
          />
          <Column
            header="Action"
            body={(row, options) =>
              options.rowIndex === 0 && isRowEditable ? (
                <REIButton
                  icon="pi pi-plus"
                  classNames="p-button-text"
                  onClick={() => {
                    handleAddRow(row);
                  }}
                />
              ) : (
                <REIButton
                  icon="pi pi-minus"
                  classNames="p-button-text"
                  onClick={() => handleDeleteRow(row.id, isRowEditable)}
                />
              )
            }
            headerStyle={{ width: "3rem" }}
          />
        </DataTable>
        <div className="flex justify-end space-x-2 mt-4">
          <REIButton
            label="Cancel"
            icon="pi pi-times"
            classNames="p-button-secondary"
            onClick={() => {
              onCancel();
              setShowConfirmationDialog(false);
            }}
          />
          {isRowEditable && (
            <REIButton
              label="Save"
              icon="pi pi-check"
              classNames="p-button-success"
              onClick={() => {
                if (ValidateData()) {
                  setShowConfirmationDialog(true);
                }
              }}
            />
          )}
          {!isRowEditable && (
            <REIButton
              key="confirm"
              label="Save"
              icon="pi pi-check"
              classNames="p-button-success"
              onClick={() => {
                SaveRentData();
              }}
            />
          )}
        </div>
      </>
    );
  };

  return (
    <div className="flex flex-col space-y-4">
      <div className="w-full">
        <Messages ref={toastRef}></Messages>
      </div>
      <REIDialoge
        showHeader={true}
        header=""
        visible={showConfirmationDialog}
        handleEditClick={hideDefaultProfile}
        position="top"
        style={{ width: "60vw" }}
        customClass="edit-default-profile"
        contentComponent={<div>{renderTable(false)}</div>}
      ></REIDialoge>
      <div>
        <div className="flex flex-row flex-wrap">
          <label className="self-center mx-3" htmlFor="property">
            Select Property
          </label>
          <REIDropDown
            key={`property-${selectedProperty}`}
            value={selectedProperty}
            data={propertyOptions}
            label="label"
            onSelectionChange={(e) => setSelectedProperty(e.value)}
            placeholder="Select Property"
            className="w-min"
          />
        </div>
      </div>

      <div className="flex-1">{renderTable(true)}</div>
      <REIDivider></REIDivider>
      <div id="footerNote">
        Please note that transactions manually entered will not appear within Xero. When using Xero, always add your
        transactions in Xero, and then Sync to Portfolio Tracker. Sync is one way only, Xero to Portfolio Tracker.
      </div>
    </div>
  );
};

export default RentEditForm;
