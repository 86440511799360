import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { confirmPopup } from "primereact/confirmpopup";
import { Toast } from "primereact/toast";
import BedIcon from "../../../public/assets/images/icon-bed.png";
import BathIcon from "../../../public/assets/images/icon-bath.png";
import CarIcon from "../../../public/assets/images/icon-car.png";
import { REIDataTable, REIDialoge, REIProgressSpinner, REIToastContainer } from "../../../components/ui";
import ErrorMessage from "../../../components/ui/errorMessage";
import { ColumnType, Icons, Messages } from "../../../utils/constants";
import { PropertyDetails } from "../ViewProperty/PropertyDetails";
import { setShowFileUploadNotes } from "../../../store/slice/propertyDetailsSlice";
import { removePropertyFromWatchList, getWatchListProperties } from "../../../store/slice/watchlistSlice";
import { IWatchListData } from "../../../interface/Watchlist/propertyDetails";
import { RootState } from "../../../store";
import { resetSalesListingData } from "../../../store/slice/investarSearchSlice";

const WatchList: React.FC = () => {
  const toastRef = useRef<Toast>(null);
  const [showPropertyDetails, setShowPropertyDetails] = useState<boolean>(false);
  const [selectedPropertyDetails, setSelectedPropertyDetails] = useState<IWatchListData>();
  const [watchListData, setWatchListData] = useState<IWatchListData[]>([]);
  const dispatch = useDispatch();
  let getWatchListResponse = useSelector((state) => state.watchList?.watchListResponse);
  let deleteWatchListResponse = useSelector((state) => state.watchList?.deleteWatchListResponse);
  const salesListingData = useSelector((state: RootState) => state.investar.salesListingData);
  const loading = useSelector((state) => state.watchList?.loading);

  useEffect(() => {
    if (salesListingData) {
      dispatch(resetSalesListingData());
      onClosePropertyDialoge();
    }
  }, [salesListingData]);

  useEffect(() => {
    dispatch(getWatchListProperties());
  }, [deleteWatchListResponse]);

  useEffect(() => {
    if (getWatchListResponse.WatchList.length != 0 && !getWatchListResponse.error) {
      const newData = getWatchListResponse.WatchList.map((item) => {
        return {
          ...item,
          address: `${item.StreetAddress ? `${item.StreetAddress}, ` : `${item.StreetAddress}`.trim()} ${item.Suburb},${
            item.StateRegion
          },${item.Postcode}`,
        };
      });
      setWatchListData(newData);
      dispatch(setShowFileUploadNotes(true));
    } else {
      setWatchListData([]);
    }
  }, [getWatchListResponse]);

  if (getWatchListResponse.error) {
    return <ErrorMessage message="error" />;
  }

  if (loading) {
    return <REIProgressSpinner />;
  }

  const deleteWatchList = async (id: string) => {
    try {
      await dispatch(removePropertyFromWatchList(id));

      if (deleteWatchListResponse?.data == "200" && !loading) {
        await dispatch(getWatchListProperties());
        toastRef.current?.show({
          severity: "success",
          summary: Messages.SUCCESS,
          detail: Messages.DELETE_SUCCESS,
          life: 2000,
        });
      }

      if (deleteWatchListResponse?.error && !loading) {
        toastRef.current?.show({
          severity: "error",
          summary: "Error",
          detail: response.error,
          life: 3000,
        });
      }
    } catch (error) {
      toastRef.current?.show({
        severity: "error",
        summary: "Error",
        detail: error.messege,
        life: 3000,
      });
    }
  };

  const handleDeleteWatchList = (event: React.MouseEvent<HTMLElement>, row: IWatchListData) => {
    confirmPopup({
      target: event.currentTarget,
      message: Messages.CONFIRMATION_MESSAGE,
      icon: Icons.INFO_ICON,
      accept: () => deleteWatchList(row.WatchlistID),
      reject: () => {},
    });
  };

  const handleAddressClick = (event: React.MouseEvent<HTMLElement>, raw: IWatchListData) => {
    setShowPropertyDetails(true);
    dispatch(setShowFileUploadNotes(true));
    setSelectedPropertyDetails(raw);
  };

  const onClosePropertyDialoge = () => {
    setShowPropertyDetails(false);
    dispatch(setShowFileUploadNotes(false));
  };

  const columns = [
    {
      field: "address",
      header: "Address",
      icon: "",
      type: ColumnType.link,
      className: "text-black-300 text-center",
      onClick: handleAddressClick,
    },
    { field: "BedRooms", header: "", image: BedIcon },
    { field: "BathRooms", header: "", image: BathIcon },
    { field: "Carport", header: "", image: CarIcon },
    {
      field: "WatchlistID",
      header: "",
      type: ColumnType.custom,
      onClick: handleDeleteWatchList,
      template: <i className="pi pi-minus-circle text-[#4E9FBA] cursor-pointer" style={{ fontSize: "1rem" }}></i>,
      width: "50px",
    },
  ];

  return (
    <>
      {loading && <REIProgressSpinner />}

      {loading && (
        <div className="flex items-center">
          <REIProgressSpinner></REIProgressSpinner>
        </div>
      )}
      <REIDialoge
        showHeader={true}
        header={selectedPropertyDetails?.Suburb}
        visible={showPropertyDetails}
        style={{ width: "70vw" }}
        position="center"
        handleEditClick={onClosePropertyDialoge}
        contentComponent={
          <>
            <PropertyDetails
              hideCloseBtn={true}
              onClose={onClosePropertyDialoge}
              propertyDetails={selectedPropertyDetails}
            ></PropertyDetails>
          </>
        }
      ></REIDialoge>
      <REIToastContainer toastReference={toastRef} />
      {!loading && watchListData && watchListData.length > 0 && (
        <REIDataTable data={watchListData} columns={columns} actions={undefined} customclass="max-h-40" />
      )}
    </>
  );
};

export default WatchList;
