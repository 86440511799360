import REIToggablePanel from "../../../../components/ui/toggablePanel";
import PreTaxVsPostTax from "./PreTaxVsPostTax";
import MarketVsLoanValue from "./MarketVsLoanValue";
import WhoPays from "./WhoPays";

const AnyalysisGraph: React.FC = () => {
  return (
    <div className="flex flex-col gap-3 p-1">
      <REIToggablePanel
        customClassName="shadow-md h-full "
        header="Who Pays?"
        defaultCollapse={false}
        istoggleable={false}
        contentComponent={<WhoPays></WhoPays>}
      ></REIToggablePanel>
      <REIToggablePanel
        customClassName="shadow-md h-full "
        header="Market value vs loan"
        defaultCollapse={false}
        istoggleable={false}
        contentComponent={<MarketVsLoanValue></MarketVsLoanValue>}
      ></REIToggablePanel>
      <REIToggablePanel
        customClassName="shadow-md h-full "
        header="Cash flows: pre-tax vs after-tax"
        defaultCollapse={false}
        istoggleable={false}
        contentComponent={<PreTaxVsPostTax></PreTaxVsPostTax>}
      ></REIToggablePanel>
    </div>
  );
};
export default AnyalysisGraph;
