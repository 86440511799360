import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import ViewPropertyDetails from "../../interface/Watchlist/propertyDetails";

interface IProprtyDetailsState {
  porpertyDetails: [ViewPropertyDetails];
  showFileUploadNotes : boolean
  error: string | null;
}

const initialState: IProprtyDetailsState = {
  porpertyDetails: [null],
  showFileUploadNotes : false,
  error: null,
};

const propertyDetailsSlice = createSlice({
  name: "propertyDetails",
  initialState,
  reducers: {
    setPropertyDetails(state, action: PayloadAction<IProprtyDetailsState>) {
      state.porpertyDetails.push(action.payload)
    },
    setShowFileUploadNotes(state,action:PayloadAction<boolean>){
      state.showFileUploadNotes = action.payload;
    },
    cleartPropertyDetails(state) {
      state.porpertyDetails = [null];
    },
  },
});

export const { setPropertyDetails, cleartPropertyDetails,setShowFileUploadNotes } = propertyDetailsSlice.actions;

export default propertyDetailsSlice.reducer;
