import { useState, useEffect, useRef } from "react";
import { Editor } from "primereact/editor";
import REIDataTable from "../ui/dataTable";
import { Card } from "primereact/card";
import { httpDelete, httpPost, httpUpdate, useHttpGets } from "../../services/httpService";
import { ApiEndPoints } from "../../utils/endpoints";
import { PropertyDetails } from "../../interface/Watchlist/propertyDetails";
import ErrorMessage from "../../components/ui/errorMessage";
import { REIButton, REIProgressSpinner, REIToastContainer } from "../ui";
import { Toast } from "primereact/toast";
import { Messages } from "../../utils/constants";

interface PropertyData {
  propertyDetails: PropertyDetails;
}

interface Notes {
  CommentDate: string;
  CommentText: string;
  WatchListCommentID: number;
  WatchListNewId: number;
}

interface AddNote {
  Comment: string;
  MemberPropertyId: string;
  WatchlistID: string;
}

const REIEditor: React.FC<PropertyData> = ({ propertyDetails }) => {
  const [notes, setNotes] = useState<Notes[]>([]);
  const [isedittorHidden, setIsedittorHidden] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [editorText, setEditorText] = useState<string>(null);
  const [refreshData, setRefreshData] = useState<boolean>(false);
  const toastRef = useRef<Toast>(null);
  let addnote = useRef<AddNote>(null);
  let selectednote = useRef<Notes>(null);

  useEffect(() => {
    let newData = [];
    setNotes([]);
    const getData = async () => {
      await useHttpGets(`${ApiEndPoints.searchSummary}/Comments/${propertyDetails.WatchlistID}`, undefined)
        .then((result) => {
          if (result.status == 200) {
            result.data.Comments.forEach((data) => {
              newData.push(data);
            });
            setNotes(newData);
            setLoading(false);
          } else {
            setLoading(false);
            return <ErrorMessage message="error" />;
          }
        })
        .catch(() => {
          return <ErrorMessage message="error" />;
        });
    };

    if (propertyDetails.PropertyId) {
      getData();
    } else {
      setLoading(false);
    }
  }, [propertyDetails.PropertyId, refreshData]);

  const handleEdit = (event: React.MouseEvent<HTMLElement>, row: Notes) => {
    setEditorText(row.CommentText);
    selectednote.current = row;
    setIsedittorHidden(false);
  };

  const handleDelete = (event: React.MouseEvent<HTMLElement>, row: Notes) => {
    setLoading(true);
    httpDelete(`${ApiEndPoints.searchSummary}/Comments/${row.WatchListCommentID}`, undefined)
      .then((result) => {
        if (result.status == 200) {
          toastRef.current?.show({
            severity: "success",
            summary: Messages.DELETE_SUCCESS,
            detail: "Note Deleted Successfully",
            life: 2000,
          });
          setRefreshData(!refreshData);
        }
        setLoading(false);
      })
      .catch(() => {
        toastRef.current?.show({
          severity: "success",
          summary: Messages.DELETE_ERROR,
          detail: Messages.ERROR,
          life: 2000,
        });
        setLoading(false);
      });
  };

  const handleSave = async () => {
    if (selectednote.current != null) {
      handleEditNote(editorText);
    } else {
      handleAddNote(editorText);
    }
  };

  const handleAddNote = async (newText: string) => {
    addnote.current = {
      Comment: newText,
      MemberPropertyId: propertyDetails.WatchlistID,
      WatchlistID: propertyDetails.WatchlistID,
    };

    await httpPost(`${ApiEndPoints.searchSummary}Comments/`, addnote.current)
      .then((result) => {
        if (result.status == 200) {
          setIsedittorHidden(true);
          setEditorText(null);
          toastRef.current?.show({
            severity: "success",
            summary: Messages.SUCCESS,
            detail: "Note Added Successfully",
            life: 2000,
          });
          setRefreshData(!refreshData);
        }
      })
      .catch(() => {
        setEditorText(null);
        setIsedittorHidden(true);
        toastRef.current?.show({
          severity: "success",
          summary: Messages.ERROR,
          detail: Messages.ERROR,
          life: 2000,
        });
      });
  };

  const handleEditNote = async (newText: string) => {
    selectednote.current.CommentText = newText;
    await httpUpdate(`${ApiEndPoints.searchSummary}Comments/`, selectednote.current)
      .then((result) => {
        if (result.status == 200) {
          setIsedittorHidden(true);
          setEditorText(null);
          setRefreshData(!refreshData);
        }
      })
      .catch(() => {
        setEditorText(null);
        setIsedittorHidden(true);
        selectednote.current = null;
      });
  };

  const handleEditorToggle = () => {
    setIsedittorHidden(!isedittorHidden);
    selectednote.current = null;
    setEditorText(null);
  };

  const columns = [
    {
      header: "",
      field: "CommentDate",
      position: "center",
      className: "text-black-300 text-center",
    },
    {
      field: "",
      type: "custom",
      onClick: handleEdit,
      template: <i className="pi pi-pencil text-[#4E9FBA] cursor-pointer w-1/5 text-sm text-end"></i>,
    },
    {
      field: "",
      type: "custom",
      onClick: handleDelete,
      template: <i className="pi pi-times text-[#4E9FBA] cursor-pointer w-1/5 text-sm"></i>,
    },
  ];

  const renderHeader = () => {
    return (
      <span className="ql-formats">
        <button className="ql-bold" aria-label="Bold"></button>
        <button className="ql-italic" aria-label="Italic"></button>
        <button className="ql-underline" aria-label="Underline"></button>
      </span>
    );
  };

  return (
    <>
      <REIToastContainer toastReference={toastRef}></REIToastContainer>
      {loading && (
        <div className="flex items-center">
          <REIProgressSpinner></REIProgressSpinner>
        </div>
      )}
      {!loading && (
        <>
          <div>
            <Card title="NOTES" className="card">
              <div className="max-h-36 overflow-auto">
                <REIDataTable data={notes} columns={columns} customclass="panel-box datagrid border rounded-md mt-4" />
              </div>
              <div className="m-3">
                <REIButton
                  classNames="text-xs font-thin border-none p-1 borderRadius"
                  onClick={handleEditorToggle}
                  label="+ Add Note"
                ></REIButton>
              </div>
              <div className="card border border-slate-300" hidden={isedittorHidden}>
                <Editor
                  headerTemplate={renderHeader()}
                  value={editorText}
                  onTextChange={(e) => setEditorText(e.htmlValue)}
                />
                <REIButton classNames="items-end text-xs p-1 m-1 " onClick={handleSave} label="Save"></REIButton>
              </div>
            </Card>
          </div>
        </>
      )}
    </>
  );
};

export default REIEditor;
