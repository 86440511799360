import React, { useState, useMemo, useCallback } from "react";
import SavedInAnalyser from "./Child/SavedInAnalyser";
import RecentlyAnalysed from "./Child/RecentlyAnalysed";
import REITab from "../../../components/ui/tab";
import { useHttpGet } from "../../../services/httpService";
import { ApiEndPoints } from "../../../utils/endpoints";
import { MEMBER_KEY, USER_DETAIL } from "../../../utils/constants";
import { REIProgressSpinner } from "../../../components/ui";
import { ConfirmPopup } from "primereact/confirmpopup";
import { Slider } from "primereact/slider";

const useAnalyserData = (refreshData: boolean) => {
  const memberDetail = JSON.parse(localStorage.getItem(USER_DETAIL) || "{}");

  const {
    data: analyserData,
    error,
    statusCode,
  } = useHttpGet(
    `${ApiEndPoints.analyser}/IndexAnalyser?MemberId=${localStorage.getItem(MEMBER_KEY)}&MemberCountryCode=${
      memberDetail.Country
    }`,
    undefined,
    refreshData
  );

  const loading = !analyserData && !error && statusCode === null;

  return { analyserData, error, loading };
};

interface ISavedAnalyserProps {
  hideExtraColumns?: boolean;
}

const SavedAnalyser: React.FC<ISavedAnalyserProps> = ({ hideExtraColumns }) => {
  const [refreshData, setRefreshData] = useState(false);
  const [forecastForYear, setForecastForYear] = useState<number>(1);
  const { analyserData, loading } = useAnalyserData(refreshData);

  const reloadData = useCallback(() => {
    setRefreshData((prev) => !prev);
  }, []);

  const savedProperties = analyserData?.SavedProperties || [];
  const recentlyAnalysed = analyserData?.RecentlyAnalysed || [];

  const tabs = useMemo(
    () => [
      {
        header: "Saved in analyser",
        content:
          savedProperties.length > 0 ? (
            <SavedInAnalyser
              data={savedProperties}
              forecastForYears={forecastForYear - 1}
              callRefreshData={reloadData}
              hideExtraColumns={hideExtraColumns}
            />
          ) : (
            <div>No saved properties found.</div>
          ),
      },
      {
        header: "Recently analysed (unsaved)",
        content:
          recentlyAnalysed.length > 0 ? (
            <RecentlyAnalysed
              data={recentlyAnalysed}
              forecastForYears={forecastForYear - 1}
              callRefreshData={reloadData}
              hideExtraColumns={hideExtraColumns}
            />
          ) : (
            <div>No recently analysed properties found.</div>
          ),
      },
    ],
    [savedProperties, recentlyAnalysed, forecastForYear, reloadData]
  );

  if (loading) {
    return (
      <div className="text-center">
        <REIProgressSpinner />
      </div>
    );
  }

  if (!analyserData) {
    return <div className="text-center">No data selected for analyser.</div>;
  }

  return (
    <>
      <ConfirmPopup />
      {!hideExtraColumns && (
        <div className="w-[93%] z-10 absolute mt-0">
          <div className="flex justify-end items-center space-x-2">
            <label className=" text-sm font-medium w-[5.5rem]">Forecast for:</label>
            <Slider
              value={forecastForYear}
              onChange={(e) => setForecastForYear(Array.isArray(e.value) ? e.value[0] : e.value)}
              className="w-[150px]"
              max={10}
              min={1}
              step={1}
            />
            <label className="text-sm font-medium w-[2.75rem] ml-3">Yr {forecastForYear}</label>
          </div>
        </div>
      )}
      <div className="w-full bg-white">
        <REITab tabs={tabs} />
      </div>
    </>
  );
};

export default SavedAnalyser;
