import { FC, useRef, useState } from "react";
import { Field, Form } from "react-final-form";
import { Button } from "primereact/button";
import { REIDialoge, REIInputText, REIProgressSpinner, REIToastContainer } from "../../components/ui";
import { ApiEndPoints } from "../../utils/endpoints";
import { EMAIL_REGEX, MEMBER_KEY } from "../../utils/constants";
import { httpPost } from "../../services/httpService";
import { useNavigate } from "react-router-dom";

const ChangeEmail: FC<{ emailId: string }> = ({ emailId }) => {
  const [loading, setLoading] = useState(false);
  const memberId = localStorage.getItem(MEMBER_KEY);
  const toast = useRef(null);
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState(false);

  const onSubmit = async (data, form) => {
    const userdetail = {
      currentEmailAddress: data.currentEmail,
      newEmailAddress: data.newEmail,
      memberId: memberId,
    };

    await httpPost(ApiEndPoints.myaccount + "/ChangeHubspotPrimaryEmail", userdetail).then((response) => {
      setLoading(true);
      if (response?.error) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: response?.error,
          life: 3000,
        });
        form.restart();
        setLoading(false);
      } else {
        UpdateEmailOnExternalSystems(data);
      }
    });
  };

  const renderSuccessMessage = (
    <div className="text-sm">
      Record has been successfuly updated! <br />
      Pls. check your email, if you havent received a confirmation email.
      <br />
      It means there is an error behind the scene. Pls contact Dev team."
    </div>
  );

  const UpdateEmailOnExternalSystems = async (data: any) => {
    setLoading(true);
    const userdetails = {
      CurrentEmailAddress: data.currentEmail,
      NewEmailAddress: data.newEmail,
    };
    await httpPost(ApiEndPoints.changeEmail + "/changeemailaddress", userdetails).then((result) => {
      if (result.status == 200) {
        setShowDialog(true);
      }
    });
  };

  const validateEmailFormat = (value) => {
    if (!value) {
      return "Required";
    }
    const isEmailValid = EMAIL_REGEX.test(value);
    return isEmailValid ? undefined : "Invalid Email";
  };

  return (
    <>
      <REIToastContainer toastReference={toast} />
      {loading && <REIProgressSpinner />}
      <REIDialoge
        header="Success Message"
        visible={showDialog}
        position="center"
        contentComponent={renderSuccessMessage}
        handleEditClick={() => {
          setLoading(false);
          navigate("/login");
        }}
      ></REIDialoge>
      {!loading && (
        <Form
          onSubmit={onSubmit}
          initialValues={{ currentEmail: emailId, newEmail: "" }}
          render={({ handleSubmit, submitting, valid, form, errors }) => (
            <form onSubmit={handleSubmit}>
              <div className="flex flex-col gap-4 gap-x-6">
                <div className="flex flex-grow gap-4">
                  <div className="mt-3 self-center">
                    <label htmlFor="currentEmail">Current Email Address</label>
                  </div>
                  <Field name="currentEmail">
                    {({ input, meta }) => (
                      <div className="mt-3">
                        <REIInputText
                          className="w-full lg:w-72"
                          {...input}
                          type="text"
                          placeholder="New Email"
                          value={emailId}
                          disabled={true}
                        />
                      </div>
                    )}
                  </Field>
                </div>
                <div className="flex gap-4">
                  <div className="mt-3 self-center">
                    <label htmlFor="newEmail">New Email Address</label>
                  </div>
                  <Field name="newEmail" validate={validateEmailFormat}>
                    {({ input, meta }) => (
                      <div className="mt-3 ml-6">
                        <REIInputText
                          {...input}
                          className={`w-full lg:w-72 ${meta.touched && meta.error ? "border-red-500" : ""}`}
                          type="text"
                          placeholder="New Email"
                        />
                        {meta.touched && meta.error && <span style={{ color: "red" }}>{meta.error}</span>}
                      </div>
                    )}
                  </Field>
                </div>
                <div className="flex gap-4">
                  <Button type="submit" disabled={submitting || !valid} label="Update" autoFocus loading={loading} />
                  <Button type="reset" label="Reset" onClick={() => form.reset()} />
                </div>
              </div>
            </form>
          )}
        />
      )}
    </>
  );
};

export default ChangeEmail;
