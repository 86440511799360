import { useState } from "react";
import { classNames } from "primereact/utils";
import { REIDropDown } from "../../../../components/ui";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";

interface DropdownData {
  label: string;
  filterName: string;
  minData: any;
  maxData: any;
  minKey: string;
  maxKey: string;
}

interface SuburbComponentProps {
  selectedDropdownValue: any;
  handleDropdownChange: (e: any, dropdownName: string) => void;
  optionsdata: any;
}

const SuburbComponent: React.FC<SuburbComponentProps> = ({
  selectedDropdownValue,
  handleDropdownChange,
  optionsdata,
}) => {
  const [showSuburbOptions, setShowSuburbOptions] = useState(false);
  const filterData = useSelector((state: RootState) => state.investar.filterValue);

  const renderDropdown = (
    label: string,
    filterName: string,
    minData: any,
    maxData: any,
    minKey: string,
    maxKey: string
  ) => (
    <>
      <div className="flex flex-col lg:flex-row lg:w-full gap-1">
        <div className="flex-0 w-full">
          <label className="block text-sm font-medium">{label}</label>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row lg:w-full gap-1">
        <div className="flex-0 w-full">
          <REIDropDown
            value={selectedDropdownValue?.[`${filterName}Min`] || (minData ? minData[0]?.[minKey] : undefined)}
            onSelectionChange={(e) => handleDropdownChange(e, `${filterName}Min`)}
            data={minData}
            label="Label"
            optionValue={minKey}
            className="w-full"
          />
        </div>
        <div className="flex-0 lg:pt-2">
          <span>to</span>
        </div>
        <div className="flex-0 w-full">
          <REIDropDown
            value={selectedDropdownValue?.[`${filterName}Max`] || (maxData ? maxData[0]?.[maxKey] : undefined)}
            onSelectionChange={(e) => handleDropdownChange(e, `${filterName}Max`)}
            data={maxData}
            label="Label"
            optionValue={maxKey}
            className="w-full"
          />
        </div>
      </div>
    </>
  );

  const toggleOptions = () => setShowSuburbOptions(!showSuburbOptions);
  const maindropdown: DropdownData[] = [
    {
      label: "Suburb rental yeild",
      filterName: "filterRange",
      minData: optionsdata?.SuburbStatistics?.RentalYields?.FilterRangeMin,
      maxData: optionsdata?.SuburbStatistics?.RentalYields?.FilterRangeMax,
      minKey: "From_ID_Int",
      maxKey: "To_ID_Int",
    },
  ];

  const dropdowns: DropdownData[] = [
    {
      label: "Suburb median $",
      filterName: "suburbMedianRange",
      minData: optionsdata?.SuburbStatistics?.SuburbMedian?.FilterRangeMin,
      maxData: optionsdata?.SuburbStatistics?.SuburbMedian?.FilterRangeMax,
      minKey: "From_ID_Int",
      maxKey: "To_ID_Int",
    },
    {
      label: "% of suburb median",
      filterName: "suburbListingRange",
      minData: optionsdata?.SuburbStatistics?.ListingMedian?.FilterRangeMin,
      maxData: optionsdata?.SuburbStatistics?.ListingMedian?.FilterRangeMax,
      minKey: "From_ID_Int",
      maxKey: "To_ID_Int",
    },
  ];

  const additionalDropdowns: DropdownData[] = [
    {
      label: "Suburb 1 year growth",
      filterName: "suburbGrowthRange",
      minData: optionsdata?.SuburbStatistics?.YearGrowth?.FilterRangeMin,
      maxData: optionsdata?.SuburbStatistics?.YearGrowth?.FilterRangeMax,
      minKey: "From_ID_Int",
      maxKey: "To_ID_Int",
    },
    {
      label: "Population",
      filterName: "population",
      minData: optionsdata?.SuburbStatistics?.Population?.FilterRangeMin,
      maxData: optionsdata?.SuburbStatistics?.Population?.FilterRangeMax,
      minKey: "From_ID_Int",
      maxKey: "To_ID_Int",
    },
  ];

  const thirdDropdowns: DropdownData[] = [
    {
      label: "Suburb Vacancy Rate",
      filterName: "vacancyRate",
      minData: optionsdata?.SuburbStatistics?.VacancyRate?.FilterRangeMin,
      maxData: optionsdata?.SuburbStatistics?.VacancyRate?.FilterRangeMax,
      minKey: "From_ID_Int",
      maxKey: "To_ID_Int",
    },
    {
      label: "Suburb Avg days on market",
      filterName: "daysOnMarket",
      minData: optionsdata?.SuburbStatistics?.DaysOnMarket?.FilterRangeMin,
      maxData: optionsdata?.SuburbStatistics?.DaysOnMarket?.FilterRangeMax,
      minKey: "From_ID_Int",
      maxKey: "To_ID_Int",
    },
  ];

  return (
    <>
      <div className={filterData ? "flex flex-col lg:w-full gap-1" : "flex flex-col lg:flex-row lg:w-full gap-1"}>
        {maindropdown.map(({ label, filterName, minData, maxData, minKey, maxKey }, index) => (
          <div className={filterData ? "flex-0 w-full" : "lg:w-1/2"} key={index}>
            {renderDropdown(label, filterName, minData, maxData, minKey, maxKey)}
          </div>
        ))}
      </div>
      <div className={filterData ? "flex flex-col lg:w-full gap-1" : "flex flex-col lg:flex-row lg:w-full gap-1"}>
        {dropdowns.map(({ label, filterName, minData, maxData, minKey, maxKey }, index) => (
          <div className={filterData ? "flex-0 w-full" : "lg:w-1/2"} key={index}>
            {renderDropdown(label, filterName, minData, maxData, minKey, maxKey)}
          </div>
        ))}
      </div>

      <div className={filterData ? "flex flex-col lg:w-full gap-1" : "flex flex-col lg:flex-row lg:w-full gap-1"}>
        {showSuburbOptions &&
          additionalDropdowns.map(({ label, filterName, minData, maxData, minKey, maxKey }, index) => (
            <div className={filterData ? "flex-0 w-full" : "lg:w-1/2"} key={index}>
              {renderDropdown(label, filterName, minData, maxData, minKey, maxKey)}
            </div>
          ))}
      </div>
      <div className={filterData ? "flex flex-col lg:w-full gap-1" : "flex flex-col lg:flex-row lg:w-full gap-1"}>
        {showSuburbOptions &&
          thirdDropdowns.map(({ label, filterName, minData, maxData, minKey, maxKey }, index) => (
            <div className={filterData ? "flex-0 w-full" : "lg:w-1/2"} key={index}>
              {renderDropdown(label, filterName, minData, maxData, minKey, maxKey)}
            </div>
          ))}
      </div>

      <div className="col-span-12 text-end mt-2 text-blue-700">
        <span className="mr-1 pt-2 cursor-pointer" onClick={toggleOptions}>
          {showSuburbOptions ? "Fewer" : "More"} suburb-specific options
        </span>
        <i
          className={classNames("pi cursor-pointer", !showSuburbOptions ? "pi-angle-down" : "pi-angle-up")}
          onClick={toggleOptions}
        ></i>
      </div>
    </>
  );
};

export default SuburbComponent;
