import React from "react";
import { InputText } from "primereact/inputtext";

interface InputFieldProps {
  id: string;
  input: any;
  meta: {
    touched: boolean;
    error?: string;
  };
  placeholder?: string;
  type?: string;
  icon?: string;
}

const InputField: React.FC<InputFieldProps> = ({ id, input, meta, placeholder, type = "text", icon }) => {
  const showError = meta.error && meta.touched;

  return (
    <div className="mt-3">
      <div className="p-inputgroup flex-1">
        <span className="p-inputgroup-addon">{icon && <i className={`pi ${icon}`}></i>}</span>
        <InputText id={id} {...input} placeholder={placeholder} type={type} className={showError ? "p-invalid" : ""} />
      </div>
      {showError && <small className="p-error">{meta.error}</small>}
    </div>
  );
};

export default InputField;
