import { useEffect, useState } from "react";

export const formatCurrency = (amount: number): string => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(amount);
};

export const formatCurrencyWithDecimal = (amount: number): string => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount);
};

export const formatNumber = (amount: number): string => {
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(amount);
};

export const formatNumberWithDecimal = (
  amount: number,
  MinFraction: number | undefined,
  MaxFraction: number | undefined
): string => {
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: MinFraction,
    maximumFractionDigits: MaxFraction,
  }).format(amount);
};

export const formatDayCycle = (dayCycle: number): string => {
  switch (dayCycle) {
    case 1:
      return `${dayCycle}st`;
    case 2:
      return `${dayCycle}nd`;
    case 3:
      return `${dayCycle}rd`;
    default:
      return `${dayCycle}th`;
  }
};

export const formatDate = (date: string): string => {
  return new Date(date).toLocaleDateString("en-GB");
};

export const getDateStringWithGivenFormat = (
  date: Date,
  format: "dd/mm/yy" | "mm/dd/yy" | "yyyy/mm/dd" = "mm/dd/yy"
): string => {
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = String(date.getFullYear());

  switch (format) {
    case "dd/mm/yy":
      return `${day}/${month}/${year}`;
    case "mm/dd/yy":
      return `${month}/${day}/${year}`;
    case "yyyy/mm/dd":
      return `${year}/${month}/${day}`;
    default:
      return `${month}/${day}/${year}`;
  }
};

const adjustDate = (date: Date, adjustment: { days?: number; months?: number; years?: number }) => {
  const newDate = new Date(date);
  if (adjustment.days) {
    newDate.setDate(newDate.getDate() + adjustment.days);
  }
  if (adjustment.months) {
    newDate.setMonth(newDate.getMonth() + adjustment.months);
  }
  if (adjustment.years) {
    newDate.setFullYear(newDate.getFullYear() + adjustment.years);
  }
  return newDate;
};

export const getAdjustedFormattedDate = (
  date: Date,
  adjustment: { days?: number; months?: number; years?: number }
) => {
  const adjustedDate = adjustDate(date, adjustment);
  return getDateStringWithGivenFormat(adjustedDate);
};

export const downloadPdf = (fileName: string, base64Data: string) => {
  const linkSource = `data:application/pdf;base64,${base64Data}`;
  const downloadLink = document.createElement("a");
  const fileNameToDownload = fileName || "invoice.pdf";

  downloadLink.href = linkSource;
  downloadLink.download = fileNameToDownload;
  downloadLink.click();
};

export const nth = (n: number) => {
  if (n > 3 && n < 21) return "th";
  switch (n % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

export const calculatePercentageShare = (data: any[]): number[] => {
  const numericData = data.map((item) => Number(item)).filter((item) => !isNaN(item));

  const total = numericData.reduce((sum, value) => sum + value, 0);

  const percentagesInfo = numericData.map((value) => {
    const exactPercent = (value / total) * 100;
    return {
      rounded: Math.round(exactPercent),
      exact: exactPercent,
    };
  });

  const roundedSum = percentagesInfo.reduce((sum, info) => sum + info.rounded, 0);

  if (roundedSum !== 100) {
    const diff = 100 - roundedSum;

    const sortedIndices = percentagesInfo
      .map((info, index) => ({ index, diff: info.exact - info.rounded }))
      .sort((a, b) => Math.abs(b.diff) - Math.abs(a.diff));

    for (let i = 0; i < Math.abs(diff); i++) {
      percentagesInfo[sortedIndices[i].index].rounded += diff > 0 ? 1 : -1;
    }
  }

  return percentagesInfo.map((info) => info.rounded);
};

export const sortObjectByNumericKeys = (obj) => {
  return Object.fromEntries(
    Object.entries(obj).sort((a, b) => parseInt(a[0].replace(/\D/g, "")) - parseInt(b[0].replace(/\D/g, ""))) // Sort by numeric part
  );
};

export const useGoogleMapsLoader = (): boolean => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const existingScript = document.getElementById("google-maps-script");

    if (existingScript) {
      existingScript.addEventListener("load", () => setIsLoaded(true));
      if ((window as any).google) {
        setIsLoaded(true);
      }
    } else {
      const script = document.createElement("script");
      script.id = "google-maps-script";
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_MAPS_API_KEY}&libraries=places,marker&v=beta&loading=asyn`;
      script.async = true;
      script.defer = true;

      script.onload = () => setIsLoaded(true);
      script.onerror = () => console.error("Failed to load Google Maps API script");

      document.head.appendChild(script);
    }
  }, []);

  return isLoaded;
};
