import { useEffect, useState } from "react";
import { REIProgressSpinner } from "../../../components/ui";
import { useAnalysedPropertyContext } from "./Child/AnalysedPropertyContext";
import { httpUpdate } from "../../../services/httpService";
import { ApiEndPoints } from "../../../utils/endpoints";
import { useNavigate } from "react-router-dom";

const Results: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const { analysedProperty } = useAnalysedPropertyContext();
  const navigation = useNavigate();

  useEffect(() => {
    const updatePropertyDetails = async () => {
      await httpUpdate(`${ApiEndPoints.analyser}/PropertyAnalyserDetail`, analysedProperty)
        .then((result) => {
          setLoading(false);
          navigation("/property-analyser");
        })
        .catch(() => {
          setLoading(false);
          navigation("/property-analyser");
        });
    };

    updatePropertyDetails();
  }, [analysedProperty]);

  return (
    <>
      {loading && (
        <div className="flex items-center">
          <REIProgressSpinner></REIProgressSpinner>
        </div>
      )}
    </>
  );
};

export default Results;
