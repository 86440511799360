import React, { useEffect, useState } from "react";
import { Slider } from "primereact/slider";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { MEMBER_KEY, USER_DETAIL } from "../../../../utils/constants";
import { httpUpdate } from "../../../../services/httpService";
import { ApiEndPoints } from "../../../../utils/endpoints";
import { triggerReload } from "../../../../store/slice/analyserSlice";
import { REIButton, REIInputNumber } from "../../../../components/ui";
import { formatCurrency } from "../../../../utils/commonUtil";
import { IAnalysisSummaryResponse, IForecastOverride, IPropertyExpense } from "../../../../interface/analysisSummary";

interface IUpdateExpenseDialogeProps {
  year?: string;
  value?: string;
  propertyExpense: IPropertyExpense;
  closeDialogue: () => void;
}

interface IExpenseDetails {
  [key: string]: number;
}

const UpdateExpenseDialoge: React.FC<IUpdateExpenseDialogeProps> = ({
  year,
  value,
  propertyExpense,
  closeDialogue,
}) => {
  const dispatch = useDispatch();
  const analysisSummary = useSelector((state: RootState) => state.analyser.analysisSummary as IAnalysisSummaryResponse);
  const memberDetail = JSON.parse(localStorage.getItem(USER_DETAIL) || "{}");
  const selectedYear = year?.replace("Year ", "").trim();
  const [rentalIncome, setRentalIncome] = useState<number>(0);
  const [anyValueChange, setAnyValueChange] = useState(false);
  const [loading, setLoading] = useState(false);

  const expenseFields: IExpenseDetails = {
    rate: propertyExpense.Rates,
    insurance: propertyExpense.Insurance,
    landLease: propertyExpense.LandLease,
    repairMaintenance: propertyExpense.Maintenance,
    serviceContracts: propertyExpense.ServiceCont,
    gardening: propertyExpense.Gardening,
    lettingFees: propertyExpense.LettingFee,
    bodyCorporate: propertyExpense.BodyCorp,
    cleaning: propertyExpense.Cleaning,
    other: propertyExpense.Other,
    managementFees: propertyExpense.AgentComm,
  };

  const [expenseDetails, setExpenseDetails] = useState<IExpenseDetails>(expenseFields);

  const ForecastItemCodeMap: Record<string, string> = {
    rate: "CashflowPropertyExpense.Rates",
    insurance: "CashflowPropertyExpense.Insurance",
    landLease: "CashflowPropertyExpense.LandLease",
    repairMaintenance: "CashflowPropertyExpense.Repairs",
    serviceContracts: "CashflowPropertyExpense.ServiceCont",
    gardening: "CashflowPropertyExpense.GardenFee",
    lettingFees: "CashflowPropertyExpense.LettingFee",
    bodyCorporate: "CashflowPropertyExpense.BodyCorp",
    cleaning: "CashflowPropertyExpense.Cleaning",
    other: "CashflowPropertyExpense.Other",
    managementFees: "CashflowPropertyExpense.RentalCommission",
  };

  const fieldKeys = Object.keys(ForecastItemCodeMap);

  const [isExpenseDetailChange, setIsExpenseDetailChange] = useState<Record<string, boolean>>(
    fieldKeys.reduce((acc, key) => ({ ...acc, [key]: false }), {})
  );

  const [isForeCastApplied, setIsForeCastApplied] = useState<Record<string, boolean>>(
    fieldKeys.reduce((acc, key) => ({ ...acc, [key]: false }), {})
  );

  const updateExpense = (field: string, value: number) => {
    setExpenseDetails((prev) => ({ ...prev, [field]: value }));
    setIsExpenseDetailChange((prev) => ({ ...prev, [field]: true }));
  };

  const applyForecast = (field: string) => {
    setIsForeCastApplied((prev) => ({ ...prev, [field]: true }));
  };

  useEffect(() => {
    const totalExpense = Object.values(expenseDetails).reduce(
      (acc, value, index) => (index === 10 ? acc : acc + value),
      0
    );
    const baseIncome = propertyExpense.RentalIncome * (expenseDetails.managementFees / 100);
    setRentalIncome(totalExpense + baseIncome);
    setAnyValueChange(true);
  }, [expenseDetails]);

  useEffect(() => {
    const forecastOverrides = analysisSummary.ForecastOverrides || [];

    const updatedChangeState = Object.keys(ForecastItemCodeMap).reduce((acc, field) => {
      acc[field] = forecastOverrides.some(
        (x) => x.ForecastItemCode === ForecastItemCodeMap[field] && x.Year === Number(selectedYear)
      );
      return acc;
    }, {} as Record<string, boolean>);

    const updatedForecastState = Object.keys(ForecastItemCodeMap).reduce((acc, field) => {
      acc[field] = forecastOverrides.some(
        (x) =>
          x.ForecastItemCode === ForecastItemCodeMap[field] && x.ThisYearOnly === 0 && x.Year === Number(selectedYear)
      );
      return acc;
    }, {} as Record<string, boolean>);

    setIsExpenseDetailChange(updatedChangeState);
    setIsForeCastApplied(updatedForecastState);
  }, [analysisSummary]);

  const onSave = async () => {
    if (!anyValueChange) return closeDialogue();

    setLoading(true);

    const body = {
      MemberId: localStorage.getItem(MEMBER_KEY),
      MemberCountryCode: memberDetail.Country,
      LVR: analysisSummary?.Levers?.LoanValueRatio,
      LoansPrincipalInterest: analysisSummary?.LoanPrincipalInterest,
      PropertyAnalyserId: analysisSummary?.PropertyAnalyserId,
      ForecastOverrides: Object.keys(expenseDetails).reduce<IForecastOverride[]>((overrides, field) => {
        if (isExpenseDetailChange[field] || isForeCastApplied[field]) {
          overrides.push({
            ForecastItemCode: ForecastItemCodeMap[field],
            Year: selectedYear ? parseInt(selectedYear) : 0,
            ThisYearOnly: isForeCastApplied[field] ? 0 : 1,
            Value: expenseDetails[field],
            Remove: 0,
            LoanId: 0,
          });
        }
        return overrides;
      }, []),
    };

    try {
      const result = await httpUpdate(`${ApiEndPoints.analyser}/AnalysisReport/`, body);
      if (result.status === 200) {
        dispatch(triggerReload());
      }
    } catch (error) {
      console.error("Error updating loan value ratio:", error);
    } finally {
      setLoading(false);
      closeDialogue();
    }

    setLoading(false);
  };

  const renderRow = (fields: { field: string; label: string; max: number; type?: string }[]) => (
    <div className="flex flex-col lg:flex-row lg:w-full gap-2">
      {fields.map(({ field, label, max, type }) => (
        <div key={field} className="flex-0 flex-col w-full lg:w-1/2 gap-2">
          <div className="flex w-full p-1">
            <div className="w-[40%] mt-2">{label}</div>
            <div className="w-[30%] pr-2">
              <Slider
                value={expenseDetails[field]}
                onChange={(e) => updateExpense(field, Array.isArray(e.value) ? e.value[0] : e.value)}
                className="w-full mt-4"
                max={max}
              />
            </div>
            <div className="w-[30%] flex">
              <REIInputNumber
                value={expenseDetails[field]}
                onValueChange={(e) => updateExpense(field, +e.target.value)}
                className="w-full"
                useGrouping={true}
                maxFractionDigits={type === "percentage" ? 2 : 0}
                min={0}
                max={max}
              />
              {isExpenseDetailChange[field] && (
                <REIButton
                  label=""
                  tooltip="Apply changes to future years"
                  icon={isForeCastApplied[field] ? "pi pi-forward" : "pi pi-play"}
                  iconPos="right"
                  classNames="p-button-link"
                  onClick={() => !isForeCastApplied[field] && applyForecast(field)}
                />
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <>
      {/* Rows with two fields per row */}
      {renderRow([
        { field: "rate", label: "Rates ($)", max: 5000 },
        { field: "insurance", label: "Insurance ($)", max: 5000 },
      ])}
      {renderRow([
        { field: "landLease", label: "Land lease ($)", max: 5000 },
        { field: "repairMaintenance", label: "Repairs/maintenance ($)", max: 1000 },
      ])}
      {renderRow([
        { field: "serviceContracts", label: "Service contracts ($)", max: 5000 },
        { field: "gardening", label: "Gardening ($)", max: 1000 },
      ])}
      {renderRow([
        { field: "lettingFees", label: "Letting fees ($)", max: 5000 },
        { field: "bodyCorporate", label: "Body corporate ($)", max: 1000 },
      ])}
      {renderRow([
        { field: "cleaning", label: "Cleaning ($)", max: 5000 },
        { field: "other", label: "Other ($)", max: 1000 },
      ])}
      {renderRow([{ field: "managementFees", label: "Management fees (%)", max: 10, type: "percentage" }])}

      {/* Summary */}
      <div className="flex flex-col lg:flex-row lg:w-full gap-2 mt-4">
        <span className="font-semibold">
          Expenses modified above = {formatCurrency(rentalIncome)} (Prior to editing was{" "}
          {formatCurrency(parseFloat(value || "0"))})
        </span>
      </div>

      {/* Action Buttons */}
      <div className="flex gap-2 mt-3">
        <div className="w-1/2 text-start">
          <REIButton label="Cancel without saving" icon="pi pi-refresh" onClick={closeDialogue} />
        </div>
        <div className="w-1/2 text-end">
          <REIButton label="Save" icon="pi pi-save" loading={loading} classNames="mr-1" onClick={onSave} />
        </div>
      </div>
    </>
  );
};

export default UpdateExpenseDialoge;
