import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Graph from "../../../../components/ui/graph";
import { IAnalysisSummaryResponse } from "../../../../interface/analysisSummary";
import { formatCurrencyWithDecimal, sortObjectByNumericKeys } from "../../../../utils/commonUtil";
import { IREIGraphDataSet } from "../../../../interface/graph";

interface IMarketVsLoanData {
  amountLoan: number[];
  marketValue: number[];
}

const MarketVsLoanValue = () => {
  const analysisData: IAnalysisSummaryResponse = useSelector((state) => state.analyser.analysisSummary);
  const equityInProperty: Number[] = [];
  let graphData: IMarketVsLoanData = { amountLoan: [], marketValue: [] };
  const [chartData, setChartData] = useState([] as IREIGraphDataSet[]);
  const [chartOptions, setChartOptions] = useState({});

  const extractData = () => {
    graphData = Object.entries(sortObjectByNumericKeys(analysisData.AnalysisOverview)).reduce(
      (acc, [key, value]) => {
        if (key.startsWith("AmountLoan") && !key.includes("Today")) {
          acc.amountLoan.push(value);
        } else if (key.startsWith("marketValue") && !key.includes("Today")) {
          acc.marketValue.push(value);
        }
        return acc;
      },
      { amountLoan: [], marketValue: [] }
    );

    graphData.amountLoan.forEach((element, index) => {
      if (graphData.amountLoan[index] !== undefined && graphData.marketValue[index] !== undefined) {
        const difference = graphData.marketValue[index] - graphData.amountLoan[index];
        equityInProperty.push(difference);
      }
    });
  };

  useEffect(() => {
    extractData();

    const data = [
      {
        label: "Market Value",
        extraData: equityInProperty,
        data: graphData.marketValue,
        backgroundColor: " rgb(250, 97, 97)",
      },
      {
        label: "Loan",
        extraData: equityInProperty,
        data: graphData.amountLoan,
        backgroundColor: " rgb(75, 192, 192)",
      },
    ];

    const options = {
      maintainAspectRatio: true,
      aspectRatio: 3.8,
      plugins: {
        legend: {
          display: true,
          position: "right",
        },
        tooltip: {
          title: "",
          callbacks: {
            label: (tooltipItem) => {
              const marketValue = `Equity In Property: ${formatCurrencyWithDecimal(
                tooltipItem.dataset.extraData[tooltipItem.dataIndex]
              )}`;
              const variablePay = `${tooltipItem.dataset.label} : ${formatCurrencyWithDecimal(
                tooltipItem.dataset.extraData[tooltipItem.dataIndex]
              )}`;
              return [variablePay, marketValue];
            },
          },
          multiLine: true,
        },
      },
      scales: {
        y: {
          ticks: {
            callback: function (value) {
              return "$" + value;
            },
          },
          grid: {
            display: false,
          },
        },
        x: {
          grid: {
            display: false,
          },
        },
      },
    };

    setChartData(data);
    setChartOptions(options);
  }, []);

  return (
    <>
      <Graph
        graphType="bar"
        dataSet={chartData}
        labels={["yr1", "yr2", "yr3", "yr4", "yr5", "yr6", "yr7", "yr8", "yr9", "yr10"]}
        customOptions={chartOptions}
      ></Graph>
    </>
  );
};

export default MarketVsLoanValue;
