import { useState } from "react";
import { NZ_MAP_DATA } from "../../utils/mapdata";

interface INewZealandMapProps {
  region: number;
  onStateChange: (state: string) => void;
}

const NewZealandMap: React.FC<INewZealandMapProps> = ({ region, onStateChange }) => {
  const [selectedState, setSelectedState] = useState(region);

  const handleStateClick = (region) => {
    setSelectedState(NZ_MAP_DATA[region].id);
    onStateChange(NZ_MAP_DATA[region]);
  };

  return (
    <div className="newzealand-map max-w-[500px]">
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 330 330"
      >
        {Object.keys(NZ_MAP_DATA).map((region) => (
          <g id={NZ_MAP_DATA[region].id} key={NZ_MAP_DATA[region].id}>
            <title>{NZ_MAP_DATA[region].title}</title>
            <path
              key={NZ_MAP_DATA[region].id}
              className={`nz-map__state ${NZ_MAP_DATA[region].name} cursor-pointer ${
                selectedState == NZ_MAP_DATA[region].id ? "selected" : ""
              }`}
              d={NZ_MAP_DATA[region].path}
              onClick={() => handleStateClick(region)}
            />
          </g>
        ))}
      </svg>
    </div>
  );
};

export default NewZealandMap;
