import { useEffect, useState } from "react";
import { AutoComplete } from "primereact/autocomplete";
import { useHttpGets } from "../../../../services/httpService";
import { ApiEndPoints } from "../../../../utils/endpoints";
import { Tag } from "primereact/tag";
import { REIDropDown, REIButton } from "../../../../components/ui";
import { COUNTRY, OPTION_ALL } from "../../../../utils/constants";

interface ILocalityProps {
  selectedState?: any;
  optionsdata: any;
  country: string;
  selectedDropdownValue: any;
}

const LocalityComponent: React.FC<ILocalityProps> = ({
  selectedState,
  optionsdata,
  country,
  selectedDropdownValue,
}) => {
  const [value, setValue] = useState<string>("");
  const [values, setValues] = useState<string[]>([]);

  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [items, setItems] = useState<string[]>([]);

  const [district, setDistrict] = useState<number>(0);
  const [districts, setDistricts] = useState([]);

  const [region, setRegion] = useState<number>(0);
  const [regions, setRegions] = useState([]);
  const [suburb, setSuburb] = useState<number>(0);
  const [suburbs, setSuburbs] = useState([]);

  useEffect(() => {
    bindRegionDistrictDropdown();
    setRegion(0);
    setDistrict(0);
    setSuburb(0);
    setValues([]);
  }, [selectedState]);

  useEffect(() => {
    bindRegionDistrictDropdown();
  }, [optionsdata]);

  useEffect(() => {
    setValues(selectedDropdownValue?.locationtags);
  }, [selectedDropdownValue?.locationtags]);

  useEffect(() => {
    setRegion(selectedDropdownValue?.selectedRegion);
  }, [selectedDropdownValue?.selectedRegion]);

  useEffect(() => {
    setDistrict(selectedDropdownValue?.selectedDistrict);
  }, [selectedDropdownValue?.selectedDistrict]);

  useEffect(() => {
    if (!selectedState || (!region && !district)) return;
    if (country != COUNTRY.AU && Number.isNaN(district)) return;
    const url =
      country == COUNTRY.AU
        ? `&stateId=${selectedState.id}&regionId=${region}&districtId=${district}`
        : `&stateId=&regionId=${selectedState.id}&districtId=${district}`;
    useHttpGets(`${ApiEndPoints.parameter}/Suburbs?countryCode=${country}${url}`)
      .then((result) => {
        if (result.status === 200) {
          setSuburbs(result.data);
          selectedDropdownValue.suburbs = result.data;
          setItems(result.data.map((suburb: any) => suburb.LocalityLabel));
        }
      })
      .catch((error) => console.log(error));
  }, [selectedState, region, district]);

  useEffect(() => {
    if (!value) return;
    useHttpGets(`${ApiEndPoints.address}/${country}/${value}`)
      .then((result) => {
        if (result.status === 200) {
          setItems(result.data.map((suburb: any) => suburb.LocalityLabel));
        }
      })
      .catch((error) => console.log(error));
  }, [value]);

  useEffect(() => {
    if (!selectedDropdownValue) return;
    selectedDropdownValue.locationBasicsSuburbs = values;
  }, [values]);

  const bindRegionDistrictDropdown = () => {
    if (!selectedState) return;
    if (country == COUNTRY.AU) {
      const data = optionsdata?.LocationBasicAUCollection?.State?.find(
        (state: any) => state.FilterStateID === selectedState.id
      );
      if (data) {
        setRegions(data.Regions);
      }
    } else {
      const data = optionsdata?.LocationBasicNZCollection?.Regions?.find(
        (district: any) => district.FilterRegionID === selectedState.id
      );
      if (data) {
        setDistricts(data.Districts);
      }
    }
  };

  const searchItems = (event: { query: string }) => {
    setTimeout(() => {
      setSuggestions(items);
    }, 250);
  };

  const handleSelect = (selectedItem: string) => {
    setValues([...values, selectedItem]);
    setValue("");
  };

  const handleTagRemove = (tagIndex: number) => {
    const updatedValues = values.filter((_, index) => index !== tagIndex);
    setValues(updatedValues);
  };

  const onRegionChange = (e: { value: number }) => {
    setRegion(e.value);
    setDistrict(0);
    setSuburb(0);
    setValues([]);
    selectedDropdownValue.selectedRegion = e.value;
  };

  const onDistrictChange = (e: { value: number }) => {
    setDistrict(e.value);
    setRegion(0);
    setSuburb(0);
    setValues([]);
    selectedDropdownValue.selectedDistrict = e.value;
  };

  const onAddLocationSelected = () => {
    let selectedValue = "";
    if (region == 0 && suburb == 0 && country == COUNTRY.AU) {
      selectedValue = `${selectedState.title}: all suburbs`;
    } else if (district == 0 && suburb == 0 && country == COUNTRY.NZ) {
      selectedValue = `${selectedState.title}: all suburbs`;
    } else if (suburb === 0 && country == COUNTRY.AU) {
      const selectedRegion = regions.find((x: any) => x.FilterRegionID === region);
      selectedValue = `${selectedRegion?.Label}: all suburbs`;
    } else if (suburb === 0 && country == COUNTRY.NZ) {
      const selectedRegion = districts.find((x: any) => x.FilterDistrictID === district);
      selectedValue = `${selectedRegion?.Label}: all suburbs`;
    } else if (suburb > 0) {
      const selectedSuburb = suburbs.find((x: any) => x.FilterSuburbID === suburb);
      selectedValue = `${selectedSuburb?.Label}`;
    }

    if (
      (suburb > 0 && values.find((value) => value.includes("all suburbs"))?.length > 0) ||
      (region > 0 && suburb === 0)
    ) {
      setValues([selectedValue]);
    } else {
      setValues([...values, selectedValue]);
    }
  };

  return (
    <>
      {values && values.length > 0 && (
        <div className="col-span-12 flex flex-wrap mb-1">
          {values.map((location, index) => (
            <div key={index} className="flex items-center m-1">
              <Tag value={location}>
                <i
                  className="pi pi-times cursor-pointer ml-1 font-bold text-sm"
                  onClick={() => handleTagRemove(index)}
                ></i>
              </Tag>
            </div>
          ))}
        </div>
      )}
      {!selectedState && (
        <div className="col-span-12">
          <AutoComplete
            value={value}
            suggestions={suggestions}
            completeMethod={searchItems}
            placeholder="Please enter a locality"
            className="w-full"
            inputClassName="w-full"
            minLength={2}
            delay={300}
            onChange={(e) => setValue(e.value)}
            onSelect={(e) => handleSelect(e.value)}
          />
        </div>
      )}
      {selectedState && (
        <div className="flex flex-col lg:flex-row flex-wrap gap-1">
          <div className="flex-1 lg:w-1/2 w-full">
            <label className="block text-sm font-medium">
              {country == COUNTRY.AU && "Select region"}
              {country != COUNTRY.AU && "Select district"}
            </label>
            {country == COUNTRY.AU && (
              <REIDropDown
                value={region}
                onSelectionChange={(e) => onRegionChange(e)}
                data={regions}
                label="Label"
                optionValue="FilterRegionID"
                placeholder={OPTION_ALL}
              />
            )}
            {country == COUNTRY.NZ && (
              <REIDropDown
                value={district}
                onSelectionChange={(e) => onDistrictChange(e)}
                data={districts}
                label="Label"
                optionValue="FilterDistrictID"
                placeholder={OPTION_ALL}
              />
            )}
          </div>
          <div className="flex-1 lg:w-1/2 w-full">
            <label className="block text-sm font-medium">Select suburb</label>
            <REIDropDown
              value={suburb}
              onSelectionChange={(e) => setSuburb(e.value)}
              data={suburbs}
              label="Label"
              optionValue="FilterSuburbID"
              placeholder={OPTION_ALL}
            />
          </div>
          <div className="flex-0 lg:w-1/12 w-full">
            <REIButton icon="pi pi-plus" classNames="mt-[16px] w-full" onClick={onAddLocationSelected} />
          </div>
        </div>
      )}
    </>
  );
};

export default LocalityComponent;
