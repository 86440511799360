import React, { useEffect, useState } from "react";
import Graph from "../ui/graph";

interface ScrollableGraphProps {
  data: any[];
  labels: string[];
  graphType: string;
  displayedYears: number;
  customOptions?: any;
  onYearChange: (year: number) => void;
}

const ScrollableGraph: React.FC<ScrollableGraphProps> = ({
  data,
  labels,
  graphType,
  displayedYears,
  customOptions,
  onYearChange,
}) => {
  const [currentYearIndex, setCurrentYearIndex] = useState(0);
  const [slicedLabels, setSlicedLabels] = useState<string[]>([]);
  const [slicedDataSet, setSlicedDataSet] = useState<any[]>([]);
  const stepSize = 1;

  useEffect(() => {
    onYearChange(currentYearIndex);
  }, [currentYearIndex, onYearChange]);

  useEffect(() => {
    if (currentYearIndex < labels.length - displayedYears) {
      setSlicedLabels(labels.slice(currentYearIndex, currentYearIndex + displayedYears));
    }
  }, [labels, currentYearIndex < labels.length - displayedYears, displayedYears]);

  useEffect(() => {
    if (currentYearIndex < labels.length - displayedYears) {
      setSlicedDataSet(
        data.map((dataset) => ({
          ...dataset,
          data: dataset.data.slice(currentYearIndex, currentYearIndex + displayedYears),
          backgroundColor: dataset.backgroundColor.slice(currentYearIndex, currentYearIndex + displayedYears),
        }))
      );
    }
  }, [data, currentYearIndex < labels.length - displayedYears, displayedYears]);

  const nextYears = () => {
    setCurrentYearIndex((prevIndex) => prevIndex + stepSize);
  };

  const prevYears = () => {
    if (currentYearIndex > 0) {
      setCurrentYearIndex((prevIndex) => prevIndex - stepSize);
    }
  };

  return (
    <div>
      <Graph labels={slicedLabels} dataSet={slicedDataSet} graphType={graphType} customOptions={customOptions} />
      <div className="flex justify-around">
        <button onClick={prevYears} disabled={currentYearIndex === 0} aria-label="Previous Year">
          <i className="pi pi-angle-left text-lg align-middle"></i>
        </button>
        <button onClick={nextYears} disabled={currentYearIndex >= labels.length - 1} aria-label="Next Year">
          <i className="pi pi-angle-right text-lg align-middle"></i>
        </button>
      </div>
    </div>
  );
};

export default ScrollableGraph;
