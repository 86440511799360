import { useState, useEffect } from "react";
import { useHttpGets } from "../../services/httpService";
import { MEMBER_KEY } from "../../utils/constants";
import { ApiEndPoints } from "../../utils/endpoints";
import { REIProgressSpinner } from "../../components/ui";
import REIToggablePanel from "../../components/ui/toggablePanel";
import { formatDate } from "../../utils/commonUtil";

const Subscriptions: React.FC = () => {
  const memberId = localStorage.getItem(MEMBER_KEY) ?? "";
  const endpoint = `${ApiEndPoints.myaccount}/subscriptions?memberId=${memberId}`;
  const [subscriptions, setSubscriptions] = useState<IUserSubscription[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!memberId) return;

    useHttpGets(endpoint, undefined)
      .then((result) => {
        if (result.data) {
          setSubscriptions(result.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Failed to fetch subscriptions", error);
        setLoading(false);
      });
  }, [memberId, endpoint]);

  const renderHeader = (sub: IUserSubscription) => (
    <div className="flex flex-row bg-gray-50 border-solid border-gray-200 border-x border-y rounded-t-md p-2 min-h-10">
      <span className="font-semibold">{sub.subscriptionId}:</span>
      <span className="ml-1">
        {formatDate(sub.subscriptionStartDate)} -{" "}
        {sub.subscriptionEndDate ? formatDate(sub.subscriptionEndDate) : "Until Cancelled"}{" "}
      </span>
    </div>
  );

  const renderSubHeader = (plan: ISubscriptionPlan) => (
    <div className="flex flex-row bg-gray-50 border-solid border-gray-200 border-x border-y rounded-md p-2 min-h-10">
      <span className="font-semibold">{plan.productName}:</span>
      <span className="ml-1">{plan.planName}</span>
    </div>
  );

  if (loading) {
    return <REIProgressSpinner />;
  }

  if (!subscriptions.length) {
    return <div className="text-center">No subscriptions found.</div>;
  }

  return (
    <>
      {subscriptions.map((sub: any) => (
        <REIToggablePanel
          key={sub.subscriptionId}
          header=""
          istoggleable={false}
          defaultCollapse={false}
          customHeader={renderHeader(sub)}
          contentComponent={sub.subscriptionPlans.map((plan: any) => (
            <REIToggablePanel
              key={plan.planName}
              header=""
              istoggleable={false}
              defaultCollapse={true}
              customHeader={renderSubHeader(plan)}
              contentComponent={<></>}
            />
          ))}
        />
      ))}
    </>
  );
};

export default Subscriptions;
