import { useRef, useState, useCallback } from "react";
import { useDispatch } from "react-redux";

import { confirmPopup } from "primereact/confirmpopup";
import { Toast } from "primereact/toast";

import {
  REIDataTable,
  REIToastContainer,
  REIProgressSpinner,
  REIDialoge,
  REIErrorMessage,
} from "../../../components/ui";

import EditSearch from "../Edit/EditSearch";

import { MEMBER_KEY } from "../../../utils/constants";
import { ApiEndPoints } from "../../../utils/endpoints";

import { useHttpGet, httpDelete, useHttpGets } from "../../../services/httpService";
import { setFilterValue, setCurrentSavedSearch } from "../../../store/slice/investarSearchSlice";
import { ISaveSearch } from "../../../interface/ISaveSearch";

interface ISearchItem {
  Id: string;
  Title: string;
  WhenAdded: string;
  SavedSearchAlertFK: number;
}

const SavedSearchList: React.FC = () => {
  const toastRef = useRef<Toast>(null);
  const [refreshData, setRefreshData] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [showEditSearch, setShowEditSearch] = useState<boolean>(false);
  const [currentRow, setCurrentRow] = useState<ISearchItem | undefined>();
  const dispatch = useDispatch();

  const memberId = localStorage.getItem(MEMBER_KEY);
  const endpoint = `${ApiEndPoints.search}/${memberId}`;

  const { data: searchlist, error } = useHttpGet(endpoint, undefined, refreshData);

  const toggleRefresh = useCallback(() => {
    setRefreshData((prev) => !prev);
  }, []);

  const deleteSearch = useCallback(
    async (id: string) => {
      const response = await httpDelete(`${ApiEndPoints.search}?MemberId=${memberId}&SavedSearchId=${id}`);
      if (response?.data) {
        toggleRefresh();
        toastRef.current?.show({
          severity: "success",
          summary: "Success Message",
          detail: "Record deleted successfully...",
          life: 2000,
        });
      } else if (response?.error) {
        toastRef.current?.show({
          severity: "error",
          summary: "Error Message",
          detail: response.error,
          life: 3000,
        });
      }
    },
    [memberId, toggleRefresh]
  );

  const handleEdit = useCallback((event, row: any) => {
    setCurrentRow(row);
    setShowEditSearch(true);
  }, []);

  const handleDelete = useCallback(
    (event, row: ISearchItem) => {
      confirmPopup({
        target: event.currentTarget,
        message: "Are you sure you want to delete this saved search?",
        icon: "pi pi-info-circle",
        accept: () => deleteSearch(row.Id),
        reject: () => {},
      });
    },
    [deleteSearch]
  );

  const handleSearchClick = useCallback((event, row: ISearchItem) => {
    setLoading(true);
    useHttpGets(`${ApiEndPoints.search}?SavedSearchId=${row.Id}`, undefined)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(
            setFilterValue({
              filterValue: response?.data as ISaveSearch,
              error: null,
              searchId: parseInt(row.Id),
            })
          );
          dispatch(setCurrentSavedSearch(parseInt(row.Id)));
        }
      })
      .catch((error) => {
        toastRef.current?.show({
          severity: "error",
          summary: "Error while fetching saved search",
          detail: error,
          life: 3000,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const hideEditDialog = useCallback(() => {
    setShowEditSearch(false);
    toggleRefresh();
  }, []);

  if (error) {
    return (
      <div className="flex items-start">
        <REIErrorMessage message={error}></REIErrorMessage>
      </div>
    );
  }

  if (!searchlist) {
    return (
      <div className="flex items-center">
        <REIProgressSpinner></REIProgressSpinner>
      </div>
    );
  }

  const columns = [
    {
      field: "Title",
      header: "Search name",
      type: "link",
      height: "39px",
      onClick: handleSearchClick,
    },
    {
      field: "WhenAdded",
      header: "Created",
      width: "100px",
    },
    {
      field: "Id",
      header: "",
      type: "custom",
      onClick: handleEdit,
      template: <span className="text-blue-700 cursor-pointer">Edit</span>,
      width: "50px",
    },
    {
      field: "SavedSearchAlertFK",
      header: "",
      type: "custom",
      onClick: handleDelete,
      template: <i className="pi pi-minus-circle text-blue-700 cursor-pointer" style={{ fontSize: "1rem" }}></i>,
      width: "50px",
    },
  ];

  return (
    <>
      {loading && (
        <div className="flex items-center">
          <REIProgressSpinner></REIProgressSpinner>
        </div>
      )}
      <REIDialoge
        showHeader={true}
        header={`Edit saved search - ${currentRow?.Title}`}
        visible={showEditSearch}
        handleEditClick={hideEditDialog}
        position="top"
        style={{ width: "68vw" }}
        customClass="edit-saved-search"
        contentComponent={
          <EditSearch
            id={currentRow?.Id}
            whenAdded={currentRow?.WhenAdded}
            alertId={currentRow?.SavedSearchAlertFK}
            title={currentRow?.Title}
            closeDialog={hideEditDialog}
          />
        }
      ></REIDialoge>
      <REIToastContainer toastReference={toastRef} />
      <REIDataTable data={searchlist.SavedSearch} columns={columns} actions={undefined} customclass="max-h-40"/>
    </>
  );
};

export default SavedSearchList;
