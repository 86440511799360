import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { formatCurrency, formatNumberWithDecimal } from "../../../utils/commonUtil";
import { REIButton } from "../../../components/ui";
import { Row } from "primereact/row";
import { ColumnGroup } from "primereact/columngroup";

interface IPropertyCashFlowSummary {
  street: string;
  suburb: string;
  state: string;
  postcode: string;
  country: string;
  type: string;
  preTaxCashflow: number;
  taxableProfitLoss: number;
  yeildPurchasePrice: number;
  yeildCurrentValue: number;
}

interface IPropertySummary {
  type: string;
  preTaxCashflow: number;
  taxableProfitLoss: number;
  yeildPurchasePrice: number;
  yeildCurrentValue: number;
}

const MyCashFlowSummary: React.FC<{ cashFlowData: any; isForecastOnly: boolean; year: string }> = ({
  cashFlowData,
  isForecastOnly,
  year,
}) => {
  const [transformedData, setTransformedData] = useState<IPropertyCashFlowSummary[]>([]);
  const [transformedFooter, setTransformedFooter] = useState<IPropertySummary[]>([]);
  const [collapsedRows, setCollapsedRows] = useState<Record<string, boolean>>({});

  useEffect(() => {
    if (cashFlowData && Array.isArray(cashFlowData.PropertyDetails) && cashFlowData.PropertyDetails.length > 0) {
      const transformed = cashFlowData.PropertyDetails.map((properties: any) => {
        const actual =
          properties.Details.CashflowSummaryPortions?.Actuals?.find((item: any) => item.Year === year) || {};
        const forecast =
          properties.Details.CashflowSummaryPortions?.Forecast?.find((item: any) => item.Year === year) || {};
        const variance =
          properties.Details.CashflowSummaryPortions?.Variance?.find((item: any) => item.Year === year) || {};

        return [
          { type: "Actuals", data: actual },
          { type: "Forecast", data: forecast },
          { type: "Variance", data: variance },
        ].map(({ type, data }) => ({
          street: properties.PropertyDetail?.StreetAddress || "",
          suburb: properties.PropertyDetail?.Suburb || "",
          state: properties.PropertyDetail?.State || "",
          postcode: properties.PropertyDetail?.Postcode || "",
          country: properties.PropertyDetail?.Country || "",
          type,
          preTaxCashflow: Number(data.PreTaxCashflow) || 0,
          taxableProfitLoss: Number(data.NetProfit) || 0,
          yeildPurchasePrice: Number(data.YieldVsPurchase) || 0,
          yeildCurrentValue: Number(data.YieldVsValuation) || 0,
        }));
      }).flat();

      setTransformedData(transformed);

      //const footer = cashFlowData.TotalForDetails[0].map((footerProperties: any) => {

      const footerProperties = cashFlowData.TotalForDetails.find((item: any) => item.EntityId === "") || {};
      const actualFooter =
        footerProperties.CashflowDetailsTotals.Summary?.Actuals?.find((item: any) => item.Year === year) || {};
      const forecastFooter =
        footerProperties.CashflowDetailsTotals.Summary?.Forecast?.find((item: any) => item.Year === year) || {};
      const varianceFooter =
        footerProperties.CashflowDetailsTotals.Summary?.Variance?.find((item: any) => item.Year === year) || {};

      const footer = [
        {
          type: "Actuals",
          preTaxCashflow: Number(actualFooter.PreTaxCashflow) || 0,
          taxableProfitLoss: Number(actualFooter.NetProfit) || 0,
          yeildPurchasePrice: Number(actualFooter.YieldVsPurchase) || 0,
          yeildCurrentValue: Number(actualFooter.YieldVsValuation) || 0,
        },
        {
          type: "Forecast",
          preTaxCashflow: Number(forecastFooter.PreTaxCashflow) || 0,
          taxableProfitLoss: Number(forecastFooter.NetProfit) || 0,
          yeildPurchasePrice: Number(forecastFooter.YieldVsPurchase) || 0,
          yeildCurrentValue: Number(forecastFooter.YieldVsValuation) || 0,
        },
        {
          type: "Variance",
          preTaxCashflow: Number(varianceFooter.PreTaxCashflow) || 0,
          taxableProfitLoss: Number(varianceFooter.NetProfit) || 0,
          yeildPurchasePrice: Number(varianceFooter.YieldVsPurchase) || 0,
          yeildCurrentValue: Number(varianceFooter.YieldVsValuation) || 0,
        },
      ];

      setTransformedFooter(footer);
    }
  }, [cashFlowData, year]);

  const currencyTemplate = (value: number) => (
    <span className={value < 0 ? "text-red-600" : ""}>
      {value < 0 ? `(${formatCurrency(-value)})` : formatCurrency(value)}
    </span>
  );

  const percentageTemplate = (value: number) => (
    <span className={value < 0 ? "text-red-600" : ""}>
      {value < 0 ? `(${formatNumberWithDecimal(-value, 2, 2)}%)` : `${formatNumberWithDecimal(value, 2, 2)}%`}
    </span>
  );

  const renderFooter = (category: any, data: IPropertySummary) =>
    transformedFooter && (
      <Row>
        <Column footer={category == "Forecast" ? "Totals/averages" : ""} />
        <Column field="type" footer={category} />
        <Column footer={currencyTemplate(data?.preTaxCashflow)} footerStyle={{ textAlign: "right" }} />
        <Column footer={currencyTemplate(data?.taxableProfitLoss)} footerStyle={{ textAlign: "right" }} />
        <Column footer={percentageTemplate(data?.yeildPurchasePrice)} footerStyle={{ textAlign: "right" }} />
        <Column footer={percentageTemplate(data?.yeildCurrentValue)} footerStyle={{ textAlign: "right" }} />{" "}
      </Row>
    );

  const footerGroup = transformedFooter && (
    <ColumnGroup>
      {renderFooter("Forecast", transformedFooter.find((x) => x.type == "Forecast")!)}
      {renderFooter("Actuals", transformedFooter.find((x) => x.type == "Actuals")!)}
      {renderFooter("Variance", transformedFooter.find((x) => x.type == "Variance")!)}
    </ColumnGroup>
  );

  const toggleRowCollapse = (street: string) => {
    setCollapsedRows((prev) => ({ ...prev, [street]: !prev[street] }));
  };

  return (
    transformedData &&
    transformedFooter && (
      <DataTable
        value={transformedData}
        rowGroupMode="rowspan"
        groupRowsBy="street"
        sortField="street"
        sortMode="single"
        sortOrder={1}
        size="small"
        footerColumnGroup={footerGroup}
      >
        <Column
          field="street"
          header="Address/property name"
          body={(rowData) => (
            <div className="flex items-center">
              {isForecastOnly && (
                <REIButton
                  icon={collapsedRows[rowData.street] ? "pi pi-angle-down" : "pi pi-angle-right"}
                  text={true}
                  onClick={() => toggleRowCollapse(rowData.street)}
                />
              )}
              <span className="cursor-pointer text-blue-700">{`${rowData.street}, ${rowData.suburb}, ${rowData.state}, ${rowData.postcode} (${rowData.country})`}</span>
            </div>
          )}
        />
        <Column
          field="type"
          header="Type"
          body={(rowData) =>
            (!collapsedRows[rowData.street] || rowData.type === "Forecast") &&
            (isForecastOnly || rowData.type === "Forecast")
              ? rowData.type
              : null
          }
        />
        <Column
          field="preTaxCashflow"
          header="Pre-tax cash flow"
          body={(rowData) =>
            (!collapsedRows[rowData.street] || rowData.type === "Forecast") &&
            (isForecastOnly || rowData.type === "Forecast")
              ? currencyTemplate(rowData.preTaxCashflow)
              : null
          }
          align="right"
        />
        <Column
          field="taxableProfitLoss"
          header="Taxable profit/loss"
          body={(rowData) =>
            (!collapsedRows[rowData.street] || rowData.type === "Forecast") &&
            (isForecastOnly || rowData.type === "Forecast")
              ? currencyTemplate(rowData.taxableProfitLoss)
              : null
          }
          align="right"
        />
        <Column
          field="yeildPurchasePrice"
          header="Yield % of purchase price"
          body={(rowData) =>
            (!collapsedRows[rowData.street] || rowData.type === "Forecast") &&
            (isForecastOnly || rowData.type === "Forecast")
              ? percentageTemplate(rowData.yeildPurchasePrice)
              : null
          }
          align="right"
        />
        <Column
          field="yeildCurrentValue"
          header="Yield % of current value"
          body={(rowData) =>
            (!collapsedRows[rowData.street] || rowData.type === "Forecast") &&
            (isForecastOnly || rowData.type === "Forecast")
              ? percentageTemplate(rowData.yeildCurrentValue)
              : null
          }
          align="right"
        />
      </DataTable>
    )
  );
};

export default MyCashFlowSummary;
