import { useState, useEffect, useRef } from "react";
import { Card } from "primereact/card";
import REIDataTable from "../../../components/ui/dataTable";
import { WatchListService } from "../../../services/watchListService";
import ViewPropertyDetails from "../../../interface/Watchlist/propertyDetails";
import { ColumnType } from "../../../utils/constants";
import { httpPost } from "../../../services/httpService";
import { ApiEndPoints } from "../../../utils/endpoints";
import { setSalesListingData } from "../../../store/slice/investarSearchSlice";
import { useDispatch } from "react-redux";
import Graph from "../../../components/ui/graph";
import { calculatePercentageShare } from "../../../utils/commonUtil";
import { IREIGraphColor, IREIGraphDataSet } from "../../../interface/graph";

interface PropertyDetails {
  propertyAnalysis: ViewPropertyDetails;
}

const PropertyAnalysis: React.FC<PropertyDetails> = ({ propertyAnalysis }) => {
  const [range, setRange] = useState([]);
  const dispatch = useDispatch();
  const includedKeys = propertyAnalysis.isFromPropertyAnalyser
    ? ["Mortgaged", "FullyOwned", "Rented", "Population"]
    : ["FullyOwned", "Purchasing", "Rented", "Population"];
  const labels = Object.keys(propertyAnalysis.PostcodeStatistics).filter((key) => includedKeys.includes(key));
  const graphData = labels.map((key) => propertyAnalysis.PostcodeStatistics[key]);
  const isGraphVisible = graphData.filter((item) => item != 0).length == 0 ? false : true;
  const calculatedGraphData = calculatePercentageShare(graphData);
  const totalUnitSales = useRef<number>(0);
  const totalRentalListings = useRef<number>(0);
  useEffect(() => {
    WatchListService.getProperties().then((data) => setRange(data));
    totalUnitSales.current = propertyAnalysis.SalesPriceRange.reduce((acc, curr) => acc + curr.Count, 0);
    totalRentalListings.current = propertyAnalysis.RentalPriceRange.reduce((acc, curr) => acc + curr.Count, 0);
  }, []);

  const rentalListingColumn = [
    {
      field: "Label",
      position: "left",
    },
    {
      field: "Count",
      position: "right",
    },
  ];

  const getAllSalesListing = async (_event: any, rowData: any) => {
    const isSearchByCategory = !rowData?.DwellingType || rowData.DwellingType === "Other";

    if (rowData?.DwellingType === "Other") return;

    const { Country, StateRegion, Postcode, Suburb, Category } = propertyAnalysis.PropertyDetails;

    const propertyListing: any = {
      CountryCode: Country,
      StateRegion,
      District: "",
      PostCode: Postcode,
      Suburb,
      Category: isSearchByCategory ? Category : rowData?.DwellingType,
      ...(isSearchByCategory && {
        ValueFrom: rowData.ValueFrom == "0" ? "" : rowData.ValueFrom,
        ValueTo: rowData.ValueTo == "0" ? "" : rowData.ValueTo,
      }),
    };

    const apiURL = isSearchByCategory
      ? `${ApiEndPoints.searchSummary}${Country}/Search/CategoryTypeSaleListings/`
      : `${ApiEndPoints.searchSummary}${Country}/Search/AllSales/`;

    try {
      const result = await httpPost(apiURL, propertyListing);
      if (result.status === 200) {
        dispatch(setSalesListingData(result?.data));
      }
    } catch (error) {
      console.error("Error fetching sales listings:", error);
    }
  };

  const allSalesListingColumns = [
    {
      field: "Label",
      position: "left",
    },
    {
      field: "Count",
      position: "right",
      type: ColumnType.link,
      onClick: (_event: any, rowData: any) => {
        getAllSalesListing(_event, rowData);
      },
    },
  ];

  const graphLabel = (
    <div className={`text-center`}>
      <span className="text-base font-semibold">Postcode statistics</span>
      <br />
      <span className="text-sm font-medium">Postcode: {`${propertyAnalysis.PropertyDetails.Postcode}`}</span>
    </div>
  );

  const customOptions = {
    plugins: {
      legend: {
        display: false,
        position: "top",
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => `${tooltipItem.dataset.data[tooltipItem.dataIndex]}%`,
        },
      },
      scales: {
        y: {
          grid: {
            display: false,
          },
          beginAtZero: true,
        },
        x: {
          grid: {
            display: false,
          },
        },
      },
    },
  };

  const rederGraphColors = (): IREIGraphColor => {
    let colors: string[] = [];
    let border: string[] = [];

    colors = [
      "rgba(255, 159, 64, 0.2)",
      "rgba(75, 192, 192, 0.2)",
      "rgba(54, 162, 235, 0.2)",
      "rgba(153, 102, 255, 0.2)",
      "rgba(255, 99, 132, 0.2)",
    ],
    border = ["rgba(255, 255, 255, 1)"];
    return { backgroundColor: colors, borderColor: border };
  };

  const getPieChartStatistics = () => {
    const statistics = graphData.map((item, index) => {
      if (labels[index] === "Population") {
        return (
          <div key={index} className="font-medium italic self-center">
            Population : {item}
          </div>
        );
      } else if (item != 0) {
        return (
          <div key={index} className="self-center">
            <i className="pi pi-stop" style={{ backgroundColor: rederGraphColors().backgroundColor[index] }}></i>
            <span className="text-xs text-left align-middle">
              {` ${calculatedGraphData[index]}%  ${labels[index]} `}
              {<br />}
            </span>
          </div>
        );
      }
    });
    return statistics;
  };

  const renderDataSet = (): IREIGraphDataSet[] => {
    const dataSet = [
      {
        data: graphData,
        backgroundColor: rederGraphColors().backgroundColor,
        borderColor: rederGraphColors().borderColor,
      },
    ];
    return dataSet;
  };

  return (
    <>
      <div className="underline underline-offset-4 font-semibold text-blue-900 p-3 text-lg">{`Suburb sales and rental listings summary for ${propertyAnalysis.PropertyDetails.Suburb} (all bedroom types)`}</div>
      <div className="flex md:flex-row flex-col gap-3">
        <Card title="All Sales Listings" className="text-center w-full md:1/4">
          <div className="panel-box">
            <REIDataTable
              customclass="leading-normal hide-datatable-header"
              data={propertyAnalysis.AllSalesListings}
              columns={allSalesListingColumns}
              actions={undefined}
            />
          </div>
        </Card>
        <Card
          title={`${propertyAnalysis.PropertyDetails.Category} sales listings`}
          className="text-center w-full md:1/4"
        >
          <div className="text-left p-2 text-sm font-medium">{`${totalUnitSales.current} total`}</div>
          <div className="panel-box ">
            <REIDataTable
              customclass="leading-normal hide-datatable-header"
              data={propertyAnalysis.SalesPriceRange}
              columns={allSalesListingColumns}
              actions={undefined}
            />
          </div>
        </Card>
        <Card
          title={`${propertyAnalysis.PropertyDetails.Category} rental listings`}
          className="text-center w-full md:1/4"
        >
          <div className="text-left p-2 text-sm font-medium">{`${totalRentalListings.current} total`}</div>
          <div className="panel-box ">
            <REIDataTable
              customclass="leading-normal hide-datatable-header"
              data={propertyAnalysis.RentalPriceRange}
              columns={rentalListingColumn}
              actions={undefined}
            />
          </div>
        </Card>
        <Card title={graphLabel} className="p-3">
          <div className="flex flex-col gap-2 align-items-center w-full md:1/4">
            {isGraphVisible && (
              <Graph
                key={propertyAnalysis.PropertyDetails.PropertyId}
                graphType="pie"
                labels={labels}
                customClass="grow-0 justify-center h-6 w-full md:1/4"
                style={{ borderRadius: "6px", height: "200px" }}
                customOptions={customOptions}
                dataSet={renderDataSet()}
              ></Graph>
            )}
            {getPieChartStatistics()}
          </div>
        </Card>
      </div>
    </>
  );
};

export default PropertyAnalysis;
