
import { COUNTRY } from "./constants";

function DataMapper(props) {
  const mappedData = [];
  const { tableData, labelMap, propertyDetails, snapshotData } = props;

  Object.keys(tableData).map((key) => {
    let uiKey = labelMap.get(key);
    if (!uiKey) return;

    if (!snapshotData) {
      if (propertyDetails && propertyDetails.Country === COUNTRY.NZ) {
        switch (uiKey) {
          case "1yr sales growth locality %":
          case "Med. rental yield":
            uiKey = `{1yr sales growth locality % ${propertyDetails.BedRooms}}`;
            break;
          case "Med. listing price":
            uiKey = `{Med. listing price % ${propertyDetails.BedRooms}}`;
            break;
          default:
            break;
        }
      }
      const uiValue = tableData[key];
      mappedData.push({ title: uiKey, data: uiValue });
    } else {
      const data = mappedData.find((x) => x.title === uiKey) || {};
      const yearValues = {
        ValueYR1: data.ValueYR1 || "",
        ValueYR3: data.ValueYR3 || "",
        ValueYR5: data.ValueYR5 || "",
        ValueYR10: data.ValueYR10 || "",
      };

      switch (true) {
        case key.includes("Yr10") || key.includes("Y10"):
          yearValues.ValueYR10 = tableData[key];
          break;
        case key.includes("Yr1") || key.includes("Y1"):
          yearValues.ValueYR1 = tableData[key];
          break;
        case key.includes("Yr3") || key.includes("Y3"):
          yearValues.ValueYR3 = tableData[key];
          break;
        case key.includes("Yr5") || key.includes("Y5"):
          yearValues.ValueYR5 = tableData[key];
          break;
        default:
          break;
      }

      if (!data.title) {
        mappedData.push({ title: uiKey, ...yearValues });
      } else {
        Object.assign(data, yearValues);
      }
    }
  });

  return mappedData;
}

export default DataMapper;
