import React, { useEffect, useRef, useState } from "react";
import REIToggablePanel from "../../components/ui/toggablePanel";
import { useHttpGets } from "../../services/httpService";
import { MEMBER_KEY, Messages, PAYMENT_MODES } from "../../utils/constants";
import { ApiEndPoints } from "../../utils/endpoints";
import { REIButton, REIDropDown, REIProgressSpinner, REIToastContainer } from "../../components/ui";
import { formatCurrencyWithDecimal, formatDate, formatDayCycle } from "../../utils/commonUtil";
import ContactSummary from "./ContactSummary";
import Subscriptions from "./Subscription";
import PaymentMethods from "./PaymentMethods";
import Invoice from "./Invoice";
import AddPaymentMethod from "./AddPaymentMethod";
import ChangeEmail from "./ChangeEmail";
import { Toast } from "primereact/toast";

const MyAccount: React.FC = () => {
  const memberId = localStorage.getItem(MEMBER_KEY);
  const endpoint = `${ApiEndPoints.myaccount}/GetAccountInfo?memberId=${memberId}`;
  const [accountInfo, setAccountInfo] = useState<any>(null);
  const [isEditMode, setIsEditMode] = useState<boolean>();
  const [paymentFormVisible, setPaymentFormVisible] = useState<boolean>(false);
  const [refreshPaymentData, setRefreshPaymentData] = useState<boolean>(false);
  const toastRef = useRef<Toast>(null);
  const paymentMode: number = 0;
  const newPmId = useRef<string>();
  const [isUpdateEnabled, setIsUpdateEnabled] = useState<boolean>(true);

  useEffect(() => {
    useHttpGets(endpoint, undefined)
      .then((result) => {
        if (result?.data?.data?.length > 0) {
          setAccountInfo(result?.data?.data[0]);
        }
      })
      .catch((error) => {});
  }, [refreshPaymentData]);

  const renderAccountInfo = () => (
    <>
      <div className="text-center">{!accountInfo && <REIProgressSpinner />}</div>
      {accountInfo && (
        <div className="px-2">
          <div className="flex flex-col md:flex-row p-1">
            <div className="flex-0 md:w-1/6 w-full content-center">
              <span className="font-semibold">Account:</span>
            </div>
            <div className="flex-0 md:w-1/3 w-full content-center break-words">
              <label>{accountInfo.name}</label>
            </div>
            <div className="flex-0 md:w-1/6 w-full content-center">
              <span className="font-semibold">Account Balance:</span>
            </div>
            <div className="flex-0 md:w-1/3 w-full content-center">
              <label>{formatCurrencyWithDecimal(accountInfo.remaining_debit_memo_balance)}</label>
            </div>
          </div>
          <div className="flex flex-col md:flex-row p-1 border-solid border border-x-0 border-b-0 border-gray-300">
            <div className="flex-0 md:w-1/6 w-full content-center">
              <span className="font-semibold">Credit Balance:</span>
            </div>
            <div className="flex-0 md:w-1/3 w-full content-center">
              <label>{formatCurrencyWithDecimal(accountInfo.remaining_credit_memo_balance)}</label>
            </div>
            <div className="flex-0 md:w-1/6 w-full content-center">
              <span className="font-semibold">Account Currency:</span>
            </div>
            <div className="flex-0 md:w-1/3 w-full content-center">
              <label>{accountInfo.currency}</label>
            </div>
          </div>
          <div className="flex flex-col md:flex-row p-1 border-solid border border-x-0 border-b-0 border-gray-300">
            <div className="flex-0 md:w-1/6 w-full content-center">
              <span className="font-semibold">Bill Cycle Day:</span>
            </div>
            <div className="flex-0 md:w-1/3 w-full content-center">
              <label>{formatDayCycle(accountInfo.bill_cycle_day)}</label>
            </div>
            <div className="flex-0 md:w-1/6 w-full content-center">
              <span className="font-semibold">Last Invoiced:</span>
            </div>
            <div className="flex-0 md:w-1/3 w-full content-center">
              <label>{accountInfo.payments?.data?.length > 0 && accountInfo.payments.data[0].created_stime}</label>
            </div>
          </div>
          <div className="flex flex-col md:flex-row p-1 border-solid border border-x-0 border-gray-300">
            <div className="flex-0 md:w-1/6 w-full content-center">
              <span className="font-semibold">Last Paid:</span>
            </div>
            <div className="flex-0 md:w-1/3 w-full content-center">
              <label>
                {accountInfo.payments?.data?.length > 0 &&
                  accountInfo.payments.data[0].payment_date &&
                  formatDate(accountInfo.payments.data[0].payment_date)}
              </label>
            </div>
            <div className="flex-0 md:w-1/6 w-full content-center">
              <span className="font-semibold">Last Payment:</span>
            </div>
            <div className="flex-0 md:w-1/3 w-full content-center">
              <label>
                {formatCurrencyWithDecimal(
                  accountInfo.payments?.data?.length > 0 && accountInfo.payments.data[0].amount
                )}
              </label>
            </div>
          </div>
        </div>
      )}
    </>
  );

  const BillingInfoHeader = (
    <div className="flex flex-row bg-gray-50 border-solid border-gray-200 border-x border-y rounded-t-md">
      <div className="w-1/2 text-start text-gray-700 font-bold text-sm ml-auto pl-3">
        <h3>Billing Info</h3>
      </div>
      <div className="w-1/2 text-end pl-3 content-center">
        <REIButton
          disabled={!isUpdateEnabled}
          icon={`${isEditMode ? "pi pi-save" : "pi pi-pencil"}`}
          label={`${isEditMode ? "Save Changes" : "Update Contact"}`}
          classNames="border-gray-300 text-gray-700 font-bold text-sm mr-1 bg-gray-50"
          onClick={() => {
            setIsEditMode(!isEditMode);
          }}
        ></REIButton>
      </div>
    </div>
  );

  const PaymentInfoHeader = (
    <div className="bg-gray-50 border-solid border-gray-200 border-x border-y font-bold rounded-t-lg p-3 text-lg flex justify-between">
      <span>Payment Methods</span>
      <div className="float-right  self-center">
        <REIDropDown
          placeholder="New Payment Method"
          value={paymentMode}
          className="text-xs h-10"
          label="name"
          data={PAYMENT_MODES}
          onSelectionChange={(e) => {
            setPaymentFormVisible(true);
          }}
          optionValue="code"
        ></REIDropDown>
      </div>
    </div>
  );

  const refreshData = () => {
    window.location.reload();
  };

  const renderPaymentMethod = (isCardAdded: boolean, id?: string) => {
    setPaymentFormVisible(!paymentFormVisible);
    if (isCardAdded) {
      setRefreshPaymentData(!refreshPaymentData);
      toastRef.current?.show({
        severity: "success",
        summary: Messages.SUCCESS,
        detail: "Card added successfully.",
        life: 2000,
      });
    }
    newPmId.current = id;
  };

  const handleIsValid = (isValid: boolean) => {
    setIsUpdateEnabled(isValid);
  };

  return (
    <>
      <REIToastContainer toastReference={toastRef}></REIToastContainer>
      <div className="flex flex-col lg:flex-row flex-wrap gap-2">
        <div className="flex flex-col lg:flex-row lg:w-full gap-2">
          <div className="flex-1 lg:w-1/2 w-full">
            <REIToggablePanel
              header="Account Summary"
              istoggleable={true}
              defaultCollapse={false}
              contentComponent={renderAccountInfo()}
            />
          </div>
          <div className="flex-1 lg:w-1/2 w-full">
            <REIToggablePanel
              customHeader={BillingInfoHeader}
              istoggleable={false}
              defaultCollapse={false}
              header=""
              contentComponent={<ContactSummary isEditMode={isEditMode} isValid={handleIsValid}></ContactSummary>}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-2 mt-2">
        <div className="flex-1 w-full">
          <REIToggablePanel
            header=""
            customHeader={PaymentInfoHeader}
            istoggleable={true}
            defaultCollapse={false}
            contentComponent={
              accountInfo && (
                <PaymentMethods
                  onsuccess={refreshData}
                  refreshData={refreshPaymentData}
                  cardId={newPmId.current}
                  accountId={accountInfo.id}
                ></PaymentMethods>
              )
            }
          />
        </div>
      </div>
      <div className="flex flex-col lg:flex-row flex-wrap lg:w-full gap-2 mt-2">
        <div className="flex flex-col lg:flex-row lg:w-full gap-2">
          <div className="flex-1 w-full">
            <REIToggablePanel
              header="Subscriptions"
              istoggleable={true}
              defaultCollapse={false}
              contentComponent={<Subscriptions />}
            />
          </div>
          <div className="flex-1 lg:w-1/2 w-full">
            <REIToggablePanel
              header="Change Email Address"
              istoggleable={false}
              defaultCollapse={false}
              contentComponent={accountInfo && <ChangeEmail emailId={accountInfo.name} />}
            />
          </div>
        </div>
      </div>

      <div className="flex-1 w-full mt-2 overflow-auto">
        <REIToggablePanel
          header="Invoices"
          istoggleable={false}
          defaultCollapse={true}
          contentComponent={<Invoice />}
        />
      </div>
      {accountInfo && (
        <AddPaymentMethod
          onsuccess={renderPaymentMethod}
          isAddPaymentMethodMode={paymentFormVisible}
          accountId={accountInfo.id}
        ></AddPaymentMethod>
      )}
    </>
  );
};

export default MyAccount;
