import { useEffect, useMemo, useState } from "react";
import REITab from "../../../components/ui/tab";
import REIToggablePanel from "../../../components/ui/toggablePanel";
import InputSlider from "../../../components/ui/slider";
import REIToggleButton from "../../../components/ui/toggleButton";
import { ITab } from "../../../interface/tab";
import { useCashFlowContext } from "./MyCashFlowContext";
import { ICashFlow } from "../../../interface/cashFlow";
import { httpPost } from "../../../services/httpService";
import { ApiEndPoints } from "../../../utils/endpoints";
import { MEMBER_KEY, MyTrackerTabs, USER_DETAIL } from "../../../utils/constants";
import MyCashFlowSummary from "./Summary";
import TrackerPurchaseCost from "./PurchaseCost";
import CashFlowExpense from "./CashFlowExpense";
import Rent from "./Rent/Rent";
import PortfolioTrackerHeader from "../header";
import { Filters } from "../Filters";
import CashFlowSummaryGraph from "./CashFlowSummaryGraph";
import TaxAndDepreciation from "./TaxAndDepreciation";
import Loan from "./Loan/Loan";

const useCashFlow = (entityId = 0, profileId = 0) => {
  const [loading, setLoading] = useState(true);
  const [cashFlowData, setCashFlowData] = useState<ICashFlow | undefined>(undefined);
  const memberDetail = useMemo(() => JSON.parse(localStorage.getItem(USER_DETAIL) || "{}"), []);
  const memberId = localStorage.getItem(MEMBER_KEY) || "";

  useEffect(() => {
    setLoading(true);
    const fetchCashFlowData = async () => {
      try {
        const ProfileData = profileId != 0 ? [{ ProfileId: profileId }] : [];
        const Entity = entityId != 0 ? [{ EntityId: entityId }] : [];

        const parameter = {
          MemberId: memberId,
          UserCountryCode: memberDetail.Country,
          Entity: Entity,
          Profiles: ProfileData,
          MemberName: `${memberDetail.FirstName} ${memberDetail.LastName}`,
          isFromMyTracker: true,
        };
        const result = await httpPost(`${ApiEndPoints.tracker}/PortfolioTracker/Cashflow/`, parameter);
        if (result.status === 200 && result.data) {
          const data = result.data as ICashFlow;
          setCashFlowData(data);
        }
      } catch {
        console.error("Error fetching cash flow data.");
      } finally {
        setLoading(false);
      }
    };

    fetchCashFlowData();
  }, [entityId, profileId]);

  return { cashFlowData, loading };
};

const MyCashFlowIndex: React.FC = () => {
  const [selectedYear, setSelectedYear] = useState<number>(Number(new Date().getFullYear().toString().slice(-2)));
  const [isForecastOnly, setIsForecastOnly] = useState<boolean>(false);
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const { setCashFlow } = useCashFlowContext();
  const { cashFlowData, loading } = useCashFlow(0, 0);
  const [isShowingTotals, setIsShowingTotals] = useState<boolean>(false);
  const [selectedProfileId, setSelectedProfileId] = useState<number>(0);
  const [selectedEntityId, setSelectedEntityId] = useState<number>(0);
  const currentYear = Number(new Date().getFullYear().toString().slice(-2));

  useEffect(() => {
    if (cashFlowData) {
      setCashFlow(cashFlowData);
    }
  }, [cashFlowData]);

  const handleCashFlowUpdate = (updatedProperty: Partial<ICashFlow>) => {
    setCashFlow((prevState) => {
      if (!prevState) return undefined;

      return {
        ...prevState,
        ...updatedProperty,
      };
    });
  };

  const tabs: ITab[] = [
    {
      header: "Summary",
      content: (
        <MyCashFlowSummary
          cashFlowData={cashFlowData}
          isForecastOnly={isForecastOnly}
          year={"20" + selectedYear.toString()}
        />
      ),
      closable: false,
    },
    {
      header: "Rent",
      content: (
        <Rent
          cashFlowData={cashFlowData}
          isForecastOnly={isForecastOnly}
          year={"20" + selectedYear.toString()}
          isEditable={isEditable}
        />
      ),
      closable: false,
    },
    {
      header: "Purchase costs",
      content: (
        <TrackerPurchaseCost
          cashFlowData={cashFlowData}
          isForecastOnly={isForecastOnly}
          isEditable={isEditable}
          setIsEditable={setIsEditable}
          year={"20" + selectedYear.toString()}
        />
      ),
      closable: false,
    },
    {
      header: "Loan",
      content: (
        <Loan
          cashFlowData={cashFlowData}
          isForecastOnly={isForecastOnly}
          year={"20" + selectedYear.toString()}
          isEditable={isEditable}
        />
      ),
      closable: false,
    },
    {
      header: "Expenses",
      content: (
        <CashFlowExpense
          cashFlowData={cashFlowData}
          isForecastOnly={isForecastOnly}
          isEditable={isEditable}
          setIsEditable={setIsEditable}
          year={"20" + selectedYear.toString()}
        />
      ),
      closable: false,
    },
    {
      header: "Tax & Depreciation",
      content: (
        <TaxAndDepreciation
          cashFlowData={cashFlowData}
          isForecastOnly={isForecastOnly}
          isEditable={isEditable}
          setIsEditable={setIsEditable}
          year={"20" + selectedYear.toString()}
        />
      ),
      closable: false,
    },
    {
      header: "",
      content: <div></div>,
      closable: false,
      headerTemplate: (
        <REIToggleButton
          customclass="flex-end "
          index=""
          isSelected={isEditable}
          offlabel="Use wizards to update actuals"
          onToggleChange={() => setIsEditable(!isEditable)}
          onlabel="Currently using wizard to edit actuals"
        />
      ),
    },
  ];

  const HeaderTemplate = (
    <div className="rounded-t-lg p-2 px-4 bg-gray-100 border-solid border-gray-100 border-x border-y flex gap-3 justify-around">
      <div className="flex w-1/3 content-start">
        <h3>Cash flow in detail</h3>
      </div>
      <div className="flex flex-row gap-3 align-middle w-1/3 content-center">
        <h3>Showing:</h3>
        <InputSlider
          value={selectedYear}
          onChange={(e) => setSelectedYear(e.value)}
          min={currentYear}
          max={currentYear + 9}
          step={1}
          classNames="self-center w-1/2"
        />
        <h3>FY-{selectedYear}</h3>
      </div>
      <div className="w-1/3 content-center text-end ">
        <REIToggleButton
          customclass="flex-end "
          index=""
          isSelected={isForecastOnly}
          offlabel="Show actuals & forecasts"
          onToggleChange={() => setIsForecastOnly(!isForecastOnly)}
          onlabel="Show only forecasts"
        />
      </div>
    </div>
  );

  const onPortionChange = (istotal: boolean) => {
    setIsShowingTotals(istotal);
  };

  const onSelectionChange = (EntityId: number, ProfileId: number) => {
    setSelectedProfileId(ProfileId);
    setSelectedEntityId(EntityId);
  };

  return (
    <div className="flex flex-col lg:flex-row flex-wrap gap-2">
      <PortfolioTrackerHeader
        tabindex={MyTrackerTabs.MyCashFlow}
        tabName="My Cash Flow"
        children={<Filters onPortionChange={onPortionChange} onSelectionChange={onSelectionChange}></Filters>}
      ></PortfolioTrackerHeader>
      <div className="w-full">
        <CashFlowSummaryGraph
          key={selectedProfileId}
          isShowingTotals={isShowingTotals}
          selectedProfileId={selectedProfileId}
          selectedEntityId={selectedEntityId}
          isDataLoading={loading}
        ></CashFlowSummaryGraph>
      </div>
      <div className="w-full">
        <REIToggablePanel
          contentComponent={
            <div className="mx-auto p-4 content-center justified-between-tabview">
              <REITab tabs={tabs} classNames="text-center"></REITab>
            </div>
          }
          defaultCollapse={false}
          header=""
          istoggleable={false}
          customHeader={HeaderTemplate}
        ></REIToggablePanel>
      </div>
    </div>
  );
};

export default MyCashFlowIndex;
