import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { IREIGraphDataSet } from "../../../interface/graph";
import { IEntity, IEquity } from "../../../interface/propertyTracker";
import { REILinkButton, REIProgressSpinner } from "../../../components/ui";
import REIToggablePanel from "../../../components/ui/toggablePanel";
import { formatNumber } from "../../../utils/commonUtil";
import ScrollableGraph from "../../../components/shared/ScrollableGraph";

const Equity: React.FC<{ entityId: string; showingTotalSummary: boolean }> = ({ entityId, showingTotalSummary }) => {
  const trackerData = useSelector((state: RootState) => state.myTracker.trackerData);
  const isLoading = useSelector((state: RootState) => state.myTracker.loading);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentYearIndex, setCurrentYearIndex] = useState(0);
  const [chartData, setChartData] = useState<IREIGraphDataSet[]>([
    { label: "MarketValue", data: [], backgroundColor: ["rgba(45, 212, 191, 1)"] },
    { label: "LoanBalance", data: [], backgroundColor: ["rgba(156, 163, 175, 1)"] },
  ]);
  const [trakerIndexData, setTrakerIndexData] = useState<IEntity[]>();
  const [selectedTrakerIndexData, setSelectedTrakerIndexData] = useState<IEntity>();
  const [years, setYears] = useState<string[]>([]);
  const [selectedYear, setSelectedYear] = useState<number>(0);
  const selectedYearForecast = useRef<IEquity>();
  const displayedYears = 5;
  const colorMap = {
    MarketValue: {
      0: "rgba(240, 171, 252, 1)",
      1: "rgba(240, 171, 252, 1)",
      default: "rgba(45, 212, 191, 1)",
    },
    LoanBalance: {
      0: "rgba(56, 189, 248, 1)",
      1: "rgba(56, 189, 248, 1)",

      default: "rgba(156, 163, 175, 1)",
    },
  };

  useEffect(() => {
    if (trackerData) {
      setTrakerIndexData(trackerData as IEntity[]);
      setChartData((prev) => prev.map((data) => ({ ...data, data: [] })));
    }
  }, [trackerData]);

  useEffect(() => {
    setLoading(true);
    getChartData();
    setSelectedYear(Number(years[currentYearIndex]));

    selectedYearForecast.current = selectedTrakerIndexData?.[
      showingTotalSummary ? "EquityTotals" : "EquityPortions"
    ]?.Forecast.find((data) => data.Year === years[currentYearIndex]?.toString());

    setLoading(false);
  }, [trakerIndexData, currentYearIndex, displayedYears]);

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  const getChartData = () => {
    const data = trakerIndexData?.find((x) => x.EntityId === entityId);
    if (data) {
      const forecastData = showingTotalSummary ? data.EquityTotals.Forecast : data.EquityPortions.Forecast;
      const actualData = showingTotalSummary ? data.EquityTotals.Actuals : data.EquityPortions.Actuals;

      const newChartData = forecastData.map((item, index) => ({
        marketValue: Number(
          index < 2 ? actualData.length > 0 && actualData[index] && actualData[index].MarketValue : item.MarketValue
        ),
        loanBalance: Number(
          index < 2 ? actualData.length > 0 && actualData[index] && actualData[index].LoanValue : item.LoanValue
        ),
      }));

      setChartData((prev) =>
        prev.map((dataset, idx) => {
          const backgroundColors: string[] = newChartData.map((_, index) => {
            return colorMap[dataset.label][index] || colorMap[dataset.label].default;
          });

          return {
            ...dataset,
            data: newChartData.map((item) => item[dataset.label === "MarketValue" ? "marketValue" : "loanBalance"]),
            backgroundColor: backgroundColors,
          };
        })
      );

      setYears(forecastData.map((item) => item.Year));
      setSelectedTrakerIndexData(data);
    }
  };

  const customOptions = {
    plugins: {
      legend: {
        display: false,
        position: "top",
      },
      scales: {
        y: {
          grid: {
            display: false,
          },
          beginAtZero: true,
        },
        x: {
          grid: {
            display: false,
          },
        },
      },
    },
  };

  const CashFlowHeader = (
    <div className="flex flex-row bg-gray-200 border-solid border-gray-200 border-x border-y rounded-t-md">
      <div className="w-1/2 text-start text-gray-700 font-bold text-sm ml-auto pl-3">
        <h3>Equity</h3>
      </div>
      <div className="w-1/2 text-end pl-3 content-center px-4">
        <REILinkButton
          href=""
          target="_blank"
          rel="noopener noreferrer"
          className="no-underline cursor-pointer text-gray-700"
        >
          {"View by property >>"}
        </REILinkButton>
      </div>
    </div>
  );

  const getClassForValue = (value: string | undefined) => {
    return Number(value) < 0 ? "text-red-500" : "";
  };

  const renderGraphHeader = (
    <div className="flex flex-row justify-around">
      <div className="align-top w-1/4">
        <b>FY-{selectedYear?.toString().slice(2, 4)} Actuals</b>
      </div>
      <div className="flex flex-col text-sm content-center bg-gray-100 border-solid border-gray-200 border-x border-y rounded-md w-3/4">
        <table className="w-full">
          <thead>
            <tr className="flex gap-5 align-bottom justify-between text-center w-full">
              <th className="w-1/4 content-end">Market Value</th>
              <th className="w-1/4 content-end">Loan Balance</th>
              <th className="w-1/4 content-end">Equity</th>
            </tr>
          </thead>
          <tbody>
            <tr className="flex gap-5 justify-around text-center w-full">
              <td className={`w-1/4 ${getClassForValue(selectedYearForecast.current?.MarketValue)}`}>
                <b>$ {formatNumber(Number(selectedYearForecast.current?.MarketValue))}</b>
              </td>
              <td className={`w-1/4 ${getClassForValue(selectedYearForecast.current?.LoanValue)}`}>
                <b>$ {formatNumber(Number(selectedYearForecast.current?.LoanValue))}</b>
              </td>
              <td className={`w-1/4 ${getClassForValue(selectedYearForecast.current?.Equity)}`}>
                {selectedYearForecast.current?.Equity != null && (
                  <b>
                    <span className={getClassForValue(selectedYearForecast.current.Equity)}>
                      ($ {formatNumber(Number(selectedYearForecast.current.Equity))})
                    </span>
                  </b>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );

  const renderGraphLegend = (
    <div className="bg-gray-100 border-solid border-gray-200 border-x border-y rounded-b-md p-3 m-3">
      <div className="flex justify-around">
        <div className="flex gap-2 items-center">
          <span className="w-4 h-4 bg-fuchsia-300"></span>
          <span className="text-center">Actual market value</span>
        </div>
        <div className="flex gap-2 items-center">
          <div className="w-4 h-4 bg-sky-400"></div>
          <span className="text-center">Actual loan balance</span>
        </div>
        <div className="flex gap-2 items-center">
          <div className="w-4 h-4 bg-teal-400"></div>
          <span className="text-center">Forecast market value</span>
        </div>
        <div className="flex gap-2 items-center">
          <div className="w-4 h-4 bg-gray-400"></div>
          <span className="text-center">Forecast loan balance</span>
        </div>
      </div>
    </div>
  );

  const onYearChange = (selectedYearIndex: number) => {
    setCurrentYearIndex(selectedYearIndex);
  };

  return (
    <>
      {loading && (
        <div className="flex items-center">
          <REIProgressSpinner />
        </div>
      )}

      <REIToggablePanel
        contentComponent={
          <div className="flex flex-col">
            {renderGraphHeader}
            {!loading && chartData[0].data.length != 0 && (
              <div className="mb-4">
                <ScrollableGraph
                  onYearChange={onYearChange}
                  data={chartData}
                  displayedYears={displayedYears}
                  graphType="line"
                  labels={years}
                  customOptions={customOptions}
                />
                {renderGraphLegend}
              </div>
            )}
          </div>
        }
        header=""
        customHeader={CashFlowHeader}
        istoggleable={false}
        defaultCollapse={false}
      />
    </>
  );
};

export default Equity;
