import ViewPropertyDetails, { MedianPriceChangeAU, PropertyLink } from "../../../interface/Watchlist/propertyDetails";
import {
  IMedianpricechangeau as NewMedianPriceChangeAU,
  IPropertylink as NewPropertyLink,
} from "../../../interface/propertySummary";
import { IPropertySummary } from "../../../interface/propertySummary";
import { MEMBER_KEY } from "../../../utils/constants";

const convertMedianPriceChangeAU = (original: NewMedianPriceChangeAU[]): MedianPriceChangeAU[] => {
  return original.map((item) => ({
    PriceChange: {
      StatLevel: "",
      Label: item.Label,
      Price: item.Price,
      Priceformatted: `$${item.Price.toLocaleString()}`,
      Growth: item.Growth,
      Shading: false,
    },
  }));
};

const convertPropertyLinks = (original: NewPropertyLink[]): PropertyLink[] => {
  return original.map((item) => ({
    Links: {
      UrlDescription: item.UrlDescription,
      Url: item.Url,
    },
  }));
};

const convertSalesPriceListing = (propertyType: string, data: IPropertySummary) => {
  let SalesPriceRange;
  let RentalPriceRange;

  switch (propertyType.toLocaleLowerCase()) {
    case "townhouse":
      SalesPriceRange = data.InvestarSearch.TownhouseSalesListings.TownhouseSales;
      RentalPriceRange = data.InvestarSearch.TownhouseRentalListing.TownhouseRental;
      break;
    case "unit/apartment":
      SalesPriceRange = data.InvestarSearch.UnitSalesListing.Unitsales;
      RentalPriceRange = data.InvestarSearch.UnitRentalListing.Unitrental;
      break;
    default:
    case "house":
      SalesPriceRange = data.InvestarSearch.HouseSalesListing.HouseSales;
      RentalPriceRange = data.InvestarSearch.HouseRentalListing.HouseRental;
  }

  return {
    SalesPriceRange: SalesPriceRange.map((item) => ({
      Count: item.Value,
      FilterPriceRangeID: 0,
      Label: item.Label,
      ValueFrom: item.ValueFrom,
      ValueTo: item.ValueTo,
    })),
    RentalPriceRange: RentalPriceRange.map((item) => ({
      Count: item.Value,
      FilterRentRangeID: 0,
      Label: item.Label,
      ValueFrom: item.ValueFrom,
      ValueTo: item.ValueTo,
    })),
  };
};

export const Mapper = (data: IPropertySummary): ViewPropertyDetails => {
  const memberId = localStorage.getItem(MEMBER_KEY);

  const mappedData: ViewPropertyDetails = {
    GoogleMapsLinks: data.GoogleMapsLinks,
    AllSalesListings: data.InvestarSearch.AllSalesListingUpdated,
    CountryCode: data.PropertyDetailSummary.Country,
    MedianPriceChangeAU: convertMedianPriceChangeAU(data.MedianPriceChangeAU),
    PostcodeStatistics: {
      Rented: data.InvestarSearch.PostCodeStatistics.Renting,
      Purchasing: 0,
      FullyOwned: data.InvestarSearch.PostCodeStatistics.Owned,
      Postcode: Number(data.InvestarSearch.PostCodeStatistics.Postcode),
      Population: data.InvestarSearch.PostCodeStatistics.Population,
      Trust: data.InvestarSearch.PostCodeStatistics.Trust,
      Other: data.InvestarSearch.PostCodeStatistics.Other,
    },
    PropertyDetails: {
      Rows: 0,
      PropertyId: data.PropertyDetailSummary.PropertyDetailId,
      Title: data.InvestarSearch.ISPropertyDetails.PropertyTitle,
      Description: data.InvestarSearch.ISPropertyDetails.PropertyDescription,
      DwellingType: "",
      Category: data.InvestarSearch.ISPropertyDetails.PropertyType,
      specCategory: null,
      Price: 0,
      PriceFormatted: "",
      StreetAddress: "",
      Suburb: data.InvestarSearch.ISPropertyDetails.PropertySuburb,
      State: data.PropertyDetailSummary.state,
      StateRegion: "",
      District: null,
      Postcode: data.PropertyDetailSummary.postcode,
      Country: data.PropertyDetailSummary.Country,
      Agent: "",
      LandArea: 0,
      FloorArea: 0,
      BedRooms: data.PropertyDetailSummary.Bedroom,
      BathRooms: data.PropertyDetailSummary.Bathroom,
      Carport: data.PropertyDetailSummary.Carport,
      DateDownloaded: data.PropertyDetailSummary.DateAdded,
      DataAdded: data.PropertyDetailSummary.DateAdded,
      LocalityMedian: "",
      DateDownloadedFirst: "",
      Link: "",
      LinkIDHash: "",
      ThumbnailURL: "",
      AuctionDetails: "",
      InspectionDetails: "",
      Version: "",
      Sold: 0,
      Duplicate: "",
      Shortdesc: "",
      Council: "",
      SuburbMatch: "",
      CouncilTelephoneNumber: "",
      CouncilAddress: "",
      Population: 0,
      ID: 0,
      HouseAvailability: 0,
      TownHouseAvailability: 0,
      UnitAvailability: 0,
      Website: "",
      WebAddress: "",
      WebsiteMode: 0,
      ListingPriority: 0,
      wikilink: null,
      StatisticsSuburb: "",
      FinanceLink: "",
      CountryID: null,
      WatchlistID: data.PropertyDetailSummary.MemberPropertyId,
      PropertyID: data.PropertyDetailSummary.PropertyDetailId,
      MemberId: memberId,
      Locality: data.PropertyDetailSummary.locality,
    },
    PropertyLinks: convertPropertyLinks(data.InvestarSearch.PropertyLinks),
    PropertyStatistics: {
      MedianSalePrice: "",
      MedianRent: "",
      MedianRentalYield: "",
      PotentialRent: "",
      PotentialYield: "",
      RentGrowth_1yr: "",
      YearGrowthPercent: "",
      PropertyStatisticsLabel: "",
    },
    PAMarketingStatstics: data.InvestarSearch.MarketStatistics,
    RentalPriceHighLow: {
      PriceHigh: 0,
      PriceLow: 0,
      LabelLow: "",
      LabelHigh: "",
      RentalPriceRangeCategory: "",
    },
    RentalPriceRange: convertSalesPriceListing(data.PropertyDetailSummary.PropertyType, data).RentalPriceRange,
    SalesPriceHighLow: {
      PriceHigh: 0,
      PriceLow: 0,
      LabelLow: "",
      LabelHigh: "",
      SalesPriceRangeCategory: "",
    },
    SalesPriceRange: convertSalesPriceListing(data.PropertyDetailSummary.PropertyType, data).SalesPriceRange,
    SuburbStatistics: {
      VacancyPercent: "",
      VacancyChange_1yr: "",
      AvgDaysOnMarket: "",
      NumberOfSalesInLastMonth: "",
      ListingsClearedLast30days: "",
      NumberOfSales: "",
      ClearanceRate: "",
    },
    PASummarySnapshot: data.SummarySnapshot,
    MedianPriceChangeNZ: null,
    isFromPropertyAnalyser: true,
  };
  return mappedData;
};
