import { FC } from "react";

interface IErrorMsgProps {
  message: string;
}

const REIErrorMessage: FC<IErrorMsgProps> = ({ message }) => (
  <p className="text-red-500 mb-4">{message}</p>
);

export default REIErrorMessage;
