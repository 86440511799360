import React, { useEffect, useState } from "react";
import { Calendar } from "primereact/calendar";

interface IDatePickerProps {
  dateString: string;
  dateFormat?: string;
  onChange?: (e: any) => void;
  showIcon?: boolean;
  className?: string;
  invalid?: boolean;
}

const DatePicker: React.FC<IDatePickerProps> = ({
  dateString,
  dateFormat = "mm/dd/yy",
  onChange,
  showIcon,
  className,
  invalid,
}) => {
  const [selectedDate, setDate] = useState(new Date(dateString));

  useEffect(() => {
    setDate(new Date(dateString));
  }, [dateString]);

  return (
    <div className="justify-content-center">
      <Calendar
        value={new Date(selectedDate)}
        onChange={onChange}
        dateFormat={dateFormat}
        showIcon={showIcon}
        className={className}
        invalid={invalid}
      />
    </div>
  );
};

export default DatePicker;
