import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Card } from "primereact/card";
import REIDataTable from "../../../components/ui/dataTable";
import REIFileUpload from "../../../components/shared/fileUpload";
import REIEditor from "../../../components/shared/editor";
import { REILinkButton, REIProgressSpinner } from "../../../components/ui";
import ViewPropertyDetails, { Links, TableData } from "../../../interface/Watchlist/propertyDetails";
import { ArchistrarDataMap, ColumnType, PAUnitStasticMap, UnitStasticMap } from "../../../utils/constants";
import SuburbStatastics from "../../../utils/dataMapper";
import * as styles from "./PropertyInformation.module.scss";
import { useHttpGets } from "../../../services/httpService";
import { ApiEndPoints } from "../../../utils/endpoints";
import DataMapper from "../../../utils/dataMapper";
import { RootState } from "../../../store";

interface PropertyDescription {
  propertyDescription: ViewPropertyDetails;
}

interface ArchistrarData {
  Archistar_Activated: boolean;
  LandUses: string[];
  MaxDensity: string;
  MaxHeight: string;
  MaxStories: string;
  Overlays: string[];
  Zoning: string;
}

interface ArchistrarUIColumns {
  LandUses: string;
  Overlays: string;
  Zoning: string;
}

const PropertyDescription: React.FC<PropertyDescription> = ({ propertyDescription }) => {
  const [unitSales, setUnitSales] = useState<TableData[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const archistrarMapperData = useRef<TableData[]>(null);
  const archistarTableData = useRef<ArchistrarUIColumns>(null);
  const archistarData = useRef<ArchistrarData>(null);
  const resources = useRef<Links[]>(propertyDescription.PropertyLinks.map((data) => data.Links).map((link) => link));
  const showNotesAndEditor = useSelector((state: RootState) => state.propertyDetails.showFileUploadNotes);
  let descriptionText = {};

  useEffect(() => {
    setLoading(true);
    async function fetchData() {
      {
        const data = SuburbStatastics({
          tableData: propertyDescription.isFromPropertyAnalyser
            ? propertyDescription.PAMarketingStatstics
            : propertyDescription.SuburbStatistics,
          labelMap: propertyDescription.isFromPropertyAnalyser ? PAUnitStasticMap : UnitStasticMap,
          propertyDetails: propertyDescription.PropertyDetails,
        });
        if (data != undefined) {
          setLoading(false);
          setUnitSales(data);
          if (!propertyDescription.isFromPropertyAnalyser) {
            await fetchArchistrarData();
          }
        }
      }
    }

    async function fetchArchistrarData() {
      {
        setLoading(true);
        await useHttpGets(
          `${ApiEndPoints.searchSummary}AU/search/ArchistarStats?MemberID=${propertyDescription.PropertyDetails.MemberId}&PropertyCountryCode=${propertyDescription.PropertyDetails.CountryID}&StreetAddress=${propertyDescription.PropertyDetails.StreetAddress}&Suburb=${propertyDescription.PropertyDetails.Suburb}&State=${propertyDescription.PropertyDetails.State}&Postcode=${propertyDescription.PropertyDetails.Postcode}&_=1719314481954`,
          undefined
        )
          .then((result) => {
            if (result.status == 200) {
              archistarData.current = result.data;
              archistarTableData.current = {
                LandUses: archistarData.current.LandUses.at(0),
                Overlays: archistarData.current.Overlays.at(0),
                Zoning: archistarData.current.Zoning,
              };
              archistrarMapperData.current = DataMapper({
                tableData: archistarTableData.current,
                labelMap: ArchistrarDataMap,
              });
            } else {
              return <REIProgressSpinner></REIProgressSpinner>;
            }
          })
          .catch(() => {});
        setLoading(false);
      }
    }

    if (propertyDescription != undefined) {
      fetchData();
    }
  }, [propertyDescription.PropertyDetails.PropertyId]);

  const resourcesColumns = [
    {
      field: "UrlDescription",
      header: "resorceLinks",
      icon: "",
      type: ColumnType.link,
    },
  ];

  const unitSalesColumns = [
    {
      field: "title",
      header: "Berrimah suburb stats",
      position: "left",
    },
    {
      field: "data",
      header: "",
      position: "right",
    },
  ];

  const archistrarColumns = [
    {
      field: "title",
      header: "",
      position: "left",
    },
    {
      field: "data",
      header: "",
      position: "right",
    },
  ];

  descriptionText = {
    __html: propertyDescription.PropertyDetails.Description,
  };

  return (
    <>
      {loading && (
        <div className="flex items-center">
          <REIProgressSpinner></REIProgressSpinner>
        </div>
      )}
      {!loading && (
        <div className="flex md:flex-row flex-col">
          <div className="flex-none w-full md:w-2/3">
            {propertyDescription.PropertyDetails.Description && (
              <>
                <Card title={`${propertyDescription.PropertyDetails.Title.replace(/^,|,$/g, "")}`}>
                  <p
                    className="justify-start text-gray-800 text-justify text-xs p-1 leading-6"
                    dangerouslySetInnerHTML={descriptionText}
                  ></p>
                </Card>
              </>
            )}
            {showNotesAndEditor && (
              <>
                <REIFileUpload propertyData={propertyDescription} editorMode="Advanced"></REIFileUpload>
                <REIEditor propertyDetails={propertyDescription.PropertyDetails}></REIEditor>
              </>
            )}
          </div>
          <div className="flex-initial w-full md:w-1/3">
            <Card className="mb-4 md:ml-4" title="Resources">
              <div className="panel-box ">
                <REILinkButton
                  children="Get finance for this property"
                  className={`${styles.linkbutton} ${styles.aChip} m-1 mb-1`}
                  href="https://www.refinancing.com.au/quote-rei/"
                  target="_blank"
                  rel="noopener noreferrer"
                ></REILinkButton>
                <REIDataTable data={resources.current} columns={resourcesColumns} customclass="hide-datatable-header" />
              </div>
            </Card>
            <Card
              title={`${propertyDescription.PropertyDetails.Suburb} suburb stats`}
              header=""
              className="mb-4 md:ml-4"
            >
              <div className="panel-box">
                <REIDataTable data={unitSales} columns={unitSalesColumns} customclass="hide-datatable-header" />
              </div>
            </Card>
            {!propertyDescription.isFromPropertyAnalyser && (
              <Card title="Planning Essentials - Powered by Archistar©" header="" className="mb-4 md:ml-4">
                <div className="panel-box">
                  <REIDataTable
                    data={archistrarMapperData.current}
                    columns={archistrarColumns}
                    customclass="hide-datatable-header"
                  ></REIDataTable>
                </div>
                <div className="text-xs pl-3 pt-1 pb-1"> See Disclaimer 2 </div>
                <div className="border-solid border-y-2 border-x-0 border-gray-300 pl-1">
                  <p className="text-sm pl-2">To access more information</p>
                  <p className="text-sm pl-2">
                    {!propertyDescription.isFromPropertyAnalyser && archistarData?.current?.Archistar_Activated ? (
                      <a href="https://www.realestateinvestar.com.au/archistar-signin" target="_blank">
                        Log into your ArchiStar account
                      </a>
                    ) : (
                      <a
                        href="https://info.realestateinvestar.com.au/archistar-upgrade?hs_preview=sXSVPRLP-11913338868"
                        target="_blank"
                      >
                        Sign up to ArchiStar
                      </a>
                    )}
                  </p>
                </div>
              </Card>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default PropertyDescription;
