import axios, { AxiosInstance } from "axios";
import { API_URL, httpCode, removeTokensFromLocalStorage } from "../utils/constants";

// Create axios instance
export const axiosInstance: AxiosInstance = axios.create({
  baseURL: API_URL,
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    if (response?.status === httpCode.UNAUTHORIZED) {
      handleUnauthorizedRepsonse();
    } else {
      return response;
    }
  },
  (error) => {
    if (
      error?.response?.status === httpCode.UNAUTHORIZED ||
      error?.response?.status === httpCode.ERR_NETWORK ||
      error?.response?.status === httpCode.BAD_REQUEST
    ) {
      handleUnauthorizedRepsonse();
    }
    return Promise.reject(error);
  }
);

const handleUnauthorizedRepsonse = () => {
  removeTokensFromLocalStorage();
  window.location.href = window?.location?.origin;
};
