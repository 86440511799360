import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { REIButton, REIProgressSpinner, REIToastContainer } from "../../components/ui";
import REIToggablePanel from "../../components/ui/toggablePanel";
import ErrorMessage from "../../components/ui/errorMessage";
import { MEMBER_KEY, Messages } from "../../utils/constants";
import { httpDelete, httpPost, useHttpGets } from "../../services/httpService";
import { ApiEndPoints } from "../../utils/endpoints";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";

interface IPaymentMethod {
  id: string;
  type: string;
  creditCardHolderName: string;
  creditCardMaskNumber: string;
  creditCardExpirationYear: number;
  creditCardExpirationMonth: number;
  daysToExpiration: number;
  creditCardType: string;
  isDefault: boolean;
  expiresSoon: boolean;
  achAccountName: string;
  achAccountNumberMask: string;
  achAccountType: string;
  achBankName: string;
}

interface IPaymentSummaryData {
  success: boolean;
  creditPaymentMethods: any[];
  achPaymentMethods: any[];
  hasPaymentMethods: boolean;
}

const PaymentMethods: React.FC<{
  onsuccess: () => void;
  refreshData: boolean;
  accountId: string;
  cardId: string;
}> = ({ refreshData, accountId, cardId, onsuccess }) => {
  const memberId = localStorage.getItem(MEMBER_KEY);
  const endpoint = `${ApiEndPoints.myaccount}/GetPaymentMethods/?memberId=${memberId}`;
  const [paymentMethodSummary, setPaymentMethodSummary] = useState<IPaymentSummaryData>();
  const [loading, setLoading] = useState<boolean>(false);
  const toastRef = useRef<Toast>(null);
  const [isRefreshData, setIsRefreshData] = useState<boolean>(false);
  const defaultData = useRef<any>();

  useEffect(() => {
    if (!memberId) return;
    setLoading(true);
    useHttpGets(endpoint, undefined)
      .then((result) => {
        if (result.data && result.status == 200) {
          setPaymentMethodSummary(result.data);
          defaultData.current = result.data.creditPaymentMethods.find((pmData) => pmData.isDefault);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        return <ErrorMessage message={error} />;
      });
  }, [memberId, isRefreshData]);

  useEffect(() => {
    if (refreshData && cardId != null) {
      handleDefaultCardUpdate(cardId);
    }
  }, [cardId && refreshData]);

  const renderHeader = (expiresSoon: boolean) => {
    if (expiresSoon) {
      return (
        <div className="bg-red-300 font-bold rounded-t-lg p-3 text-lg flex flex-auto gap-3 text-gray-800">
          <span className="pi pi-exclamation-circle text-lg font-bold text-gray-800"></span>
          Card Expiring Soon!
        </div>
      );
    } else {
      return (
        <div className="bg-zinc-200 font-bold rounded-t-lg p-3 text-lg flex flex-auto gap-3">
          <span className="pi pi-check text-lg font-bold"></span>
          Card Authorized
        </div>
      );
    }
  };

  const renderFooter = (isDefault: boolean, id: string) => {
    return (
      <div className="flex flex-col h-full content-center">
        {isDefault ? (
          <div className="border-solid border-gray-200 border-x border-b border-t-0 rounded-b-lg pt-2 pb-2 flex flex-auto gap-3 content-center text-black bg-amber-100">
            <span className="pi pi-star-fill ml-4 content-center text-lg text-amber-400"></span>
            <span className="content-center text-lg"> Default Payment Method</span>
          </div>
        ) : (
          <div className="border-solid border-gray-200 border-x border-b border-t-0 rounded-b-lg pt-2 pb-2 flex flex-auto gap-3 p-3">
            <REIButton
              key={`defaultbtn${id}`}
              classNames="text-sm bg-transparent text-black"
              label="Make Default"
              onClick={() => {
                updateDefaultPM(id, false);
              }}
            ></REIButton>
            <REIButton
              key={`removebtn${id}`}
              icon="pi pi-check"
              classNames="text-sm"
              label="Remove"
              onClick={() => {
                handleDeletePaymentMethod(id);
              }}
            ></REIButton>
          </div>
        )}
      </div>
    );
  };

  const noPaymentMethodContent = (
    <div className="bg-red-300 rounded-lg" role="alert">
      <div className="p-4 text-red-800">
        <i className="pi pi-exclamation-circle font-bold text-lg"></i>
        <span className="sr-only">Error:</span>
        <p>
          Welcome to the new Real Estate Investar billing platform built on Zuora! As we don't have access to you Credit
          Card number we need you to add your payment method again.{" "}
        </p>
        <p>
          Please do the following as soon as you can;
          <ol>
            <li>Click new payment method</li>
            <li>Enter a valid card number will billing details into the secure form (SSL)</li>
            <li>Once saved we will activate your new billing account.</li>
          </ol>
          You will not be billed for anything immediately but upon your next renewal. If you have any questions, please
          contact our support.
        </p>
      </div>
    </div>
  );

  const removePaymentMethod = async (id: string) => {
    setLoading(true);
    await httpDelete(`${ApiEndPoints.myaccount}/DeletePaymentMethod?paymentId=${id}`)
      .then((data) => {
        if (data.status == 200) {
          toastRef.current?.show({
            severity: "success",
            summary: Messages.DELETE_SUCCESS,
            detail: "Payment Method Deleted Successfully",
            life: 2000,
          });
          setIsRefreshData(!isRefreshData);
        } else {
          toastRef.current?.show({
            severity: "error",
            summary: Messages.DELETE_ERROR,
            detail: data.error,
            life: 2000,
          });
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        toastRef.current?.show({
          severity: "error",
          summary: Messages.DELETE_ERROR,
          detail: error,
          life: 2000,
        });
      });
  };

  const updateDefaultPM = (id: string, isCradAdded: boolean) => {
    setLoading(true);
    httpPost(`${ApiEndPoints.myaccount}/UpdateDefaultPaymentMethod`, null, {
      pmId: `${id}`,
      accountId: `${accountId}`,
    })
      .then((data) => {
        if (data.status == 200) {
          toastRef.current?.show({
            severity: "success",
            summary: Messages.SUCCESS,
            detail: "Default Payment Method Updated Successfully",
            life: 2000,
          });
          setIsRefreshData(!isRefreshData);
        } else {
          toastRef.current?.show({
            severity: "error",
            summary: Messages.ERROR,
            detail: data.error,
            life: 2000,
          });
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        toastRef.current?.show({
          severity: "error",
          summary: Messages.ERROR,
          detail: error,
          life: 2000,
        });
      })
      .finally(() => {
        isCradAdded && onsuccess();
      });
  };

  const handleDeletePaymentMethod = (id: string) => {
    confirmDialog({
      message: <div className="text-lg">Are you sure you want to delete the card?</div>,
      header: "Delete Confirmation",
      icon: "pi pi-exclamation-triangle",
      defaultFocus: "reject",
      acceptClassName: "p-button-danger",
      accept: () => {
        removePaymentMethod(id);
      },
      reject: () => {},
    });
  };

  const handleDefaultCardUpdate = (id: string) => {
    confirmDialog({
      message: <div className="text-lg">Do you want to set this card as default payment method?</div>,
      header: "Default Payment Method",
      icon: "pi pi-check-circle",
      defaultFocus: "accept",
      accept: () => {
        updateDefaultPM(id, true);
      },
      reject: () => {
        setIsRefreshData(!isRefreshData);
        onsuccess();
      },
      closable: false,
    });
  };

  return (
    <>
      <REIToastContainer toastReference={toastRef}></REIToastContainer>
      <ConfirmDialog />
      {loading && <REIProgressSpinner />}
      {!loading && paymentMethodSummary && !paymentMethodSummary?.hasPaymentMethods && noPaymentMethodContent}
      {!loading && paymentMethodSummary && paymentMethodSummary?.hasPaymentMethods && (
        <>
          <div className="flex flex-wrap gap-3">
            {defaultData.current && (
              <div className="md:w-2/4 lg:w-1/4 w-full" key={defaultData.current.id}>
                <REIToggablePanel
                  customClassName="w-full p-0 payment-panel h-full flex flex-col"
                  istoggleable={false}
                  customHeader={renderHeader(defaultData.current.expiresSoon)}
                  header=""
                  defaultCollapse={false}
                  footerTemplate={renderFooter(defaultData.current.isDefault, defaultData.current.id)}
                  contentComponent={
                    <>
                      <div className="pl-3">
                        <span className="font-bold align-middle h-5 block">
                          <i className="pi pi-credit-card text-lg"></i> {defaultData.current.creditCardType}
                        </span>
                        <br />
                        <span className="h-5 block">{defaultData.current.creditCardMaskNumber}</span> <br />
                        <span className="h-5 block">
                          Exp : {defaultData.current.creditCardExpirationMonth}/
                          {defaultData.current.creditCardExpirationYear}
                        </span>
                        <br />
                        <span className="h-5 block">{defaultData.current.creditCardHolderName}</span>
                      </div>
                    </>
                  }
                ></REIToggablePanel>
              </div>
            )}

            {paymentMethodSummary?.creditPaymentMethods.map((creditCardData: IPaymentMethod) => {
              return (
                <>
                  {!creditCardData.isDefault && (
                    <div className="md:w-2/4 lg:w-1/4 w-full" key={creditCardData.id}>
                      <REIToggablePanel
                        customClassName="w-full p-0 payment-panel h-full flex flex-col"
                        istoggleable={false}
                        customHeader={renderHeader(creditCardData.expiresSoon)}
                        header=""
                        defaultCollapse={false}
                        footerTemplate={renderFooter(creditCardData.isDefault, creditCardData.id)}
                        contentComponent={
                          <>
                            <div className="pl-3">
                              <span className="font-bold align-middle h-5 block">
                                <i className="pi pi-credit-card text-lg"></i> {creditCardData.creditCardType}
                              </span>
                              <br />
                              <span className="h-5 block">{creditCardData.creditCardMaskNumber}</span> <br />
                              <span className="h-5 block">
                                Exp : {creditCardData.creditCardExpirationMonth}/
                                {creditCardData.creditCardExpirationYear}
                              </span>
                              <br />
                              <span className="h-5 block">{creditCardData.creditCardHolderName}</span>
                            </div>
                          </>
                        }
                      ></REIToggablePanel>
                    </div>
                  )}
                </>
              );
            })}
          </div>
          {paymentMethodSummary &&
            paymentMethodSummary?.achPaymentMethods.map((achData: IPaymentMethod) => {
              return (
                <div className="flex md:flex-row flex-col gap-3 w-1/4" key={achData.id}>
                  <REIToggablePanel
                    customClassName="w-full p-0 payment-panel"
                    istoggleable={false}
                    customHeader={renderHeader(achData.expiresSoon)}
                    header=""
                    defaultCollapse={false}
                    footerTemplate={renderFooter(achData.isDefault, achData.id)}
                    contentComponent={
                      <>
                        <div className="pl-3">
                          <span className="font-bold align-middle h-5 block">
                            <i className="pi pi-credit-card text-lg"></i> {achData.creditCardType}
                          </span>
                          <br />
                          <span className="h-5 block">{achData.creditCardMaskNumber}</span> <br />
                          <span className="h-5 block">
                            Exp : {achData.creditCardExpirationYear}/{achData.creditCardExpirationMonth}
                          </span>
                          <br />
                          <span className="h-5 block">{achData.creditCardHolderName}</span>
                        </div>
                      </>
                    }
                  ></REIToggablePanel>
                </div>
              );
            })}
        </>
      )}
    </>
  );
};

export default PaymentMethods;
