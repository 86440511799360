import React, { useState } from "react";
import { RadioButton } from "primereact/radiobutton";
import REIToggablePanel from "../../../components/ui/toggablePanel";
import { REIButton } from "../../../components/ui";
import { IMyProperties } from "../../../interface/myProperty";
import { formatCurrency, formatNumber, formatNumberWithDecimal } from "../../../utils/commonUtil";
import HouseIcon from "../../../public/assets/images/icon-house.png";
import BedIcon from "../../../public/assets/images/icon-bed.png";
import BathIcon from "../../../public/assets/images/icon-bath.png";
import CarIcon from "../../../public/assets/images/icon-car.png";

enum PortionType {
  Total = "total",
  Portion = "portion",
}

enum CurrencyType {
  AUD = "AUD",
  NZD = "NZD",
}

const PropertyCard: React.FC<{
  propertyData: IMyProperties;
  setPropertyData: (propertyData: IMyProperties) => void;
}> = ({ propertyData, setPropertyData }) => {
  const [selectedCurrencyFilter, setSelectedCurrencyFilter] = useState<string>(propertyData.PortfolioCurrencyCode);
  const [selectedPortion, setSelectedPortion] = useState<string>(PortionType.Total);

  const currencyTemplate = (value: string) => {
    return (
      <span className={Number(value) < 0 ? "text-red-600" : ""}>
        {Number(value) < 0 ? `(${formatCurrency(-Number(value))})` : formatCurrency(Number(value))}
      </span>
    );
  };

  const renderHeader = () => (
    <div className="flex flex-col text-white bg-slate-600 justify-between gap-2 rounded-t-md p-2 ">
      <div className="flex justify-between text-lg">
        {[propertyData.StreetAddress, propertyData.Suburb, propertyData.State, propertyData.Postcode]
          .filter(Boolean)
          .join(", ")}
        <div className="flex justify-end gap-2">
          <REIButton
            label="Manage property"
            classNames="text-white text-sm bg-slate-500 rounded-lg p-1 border-none"
            onClick={(event) => {
              handleManagePropertyClick(event, propertyData);
            }}
          ></REIButton>
        </div>
      </div>

      {propertyData.PropertyKeyValuesUserCurrencyTotal == null &&
        formatNumber(propertyData.PositionOwned) !== "100" && (
          <div className="flex flex-row gap-4">
            <div className="flex gap-2 ">
              <RadioButton
                inputId="total"
                name="portionTotal"
                value={PortionType.Total}
                checked={selectedPortion == PortionType.Total ? true : false}
                onChange={(e) => {
                  setSelectedPortion(e.value);
                }}
              />
              <label className="text-sm self-center">Total</label>
              <RadioButton
                inputId="portion"
                name="portionEntity"
                value={PortionType.Portion}
                checked={selectedPortion == PortionType.Portion ? true : false}
                onChange={(e) => {
                  setSelectedPortion(e.value);
                }}
              />
              <label className="text-sm self-center">
                Entity's portion {formatNumber(propertyData.PositionOwned)}%
              </label>
            </div>
          </div>
        )}
    </div>
  );

  const returnCurrencyKeyValues = () => {
    if (propertyData.CurrencyCode != propertyData.PortfolioCurrencyCode) {
      return selectedCurrencyFilter === propertyData.PortfolioCurrencyCode
        ? propertyData.PropertyKeyValuesUserCurrencyTotal
        : propertyData.PropertyKeyValuesTotal;
    } else {
      return selectedPortion === PortionType.Total
        ? propertyData.PropertyKeyValuesTotal
        : propertyData.PropertyKeyValuesPortion;
    }
  };

  const renderContentComponent = () => (
    <>
      <div className="flex flex-row gap-2 p-1">
        <div className="w-1/6">
          <div className="w-full flex flex-col gap-2">
            <div className="text-start content-center">
              <img src={HouseIcon} alt="Image 1" className="h-5 w-5" />
              <span className="text-center text-md m-1 align-top">Category</span>
            </div>
            <div className="text-start content-center">
              {`Land size: ${propertyData.Landsize == "0" ? "N/A" : propertyData.Landsize}`}
            </div>
            <div className="text-start mr-2 content-center">
              <img src={BathIcon} alt="Image 1" className="h-5 w-5" />
              <span className="text-center text-md align-top m-1">2</span>
              <img src={BedIcon} alt="Image 2" className="h-5 w-5" />
              <span className=" text-md text-md align-top m-1">2</span>
              <img src={CarIcon} alt="Image 3" className="h-5 w-5" />
              <span className=" text-md text-md align-top m-1">2</span>
            </div>
          </div>
        </div>
        <div className=" flex flex-col gap-3 w-5/6 ">
          <div className="flex justify-around">
            <div>Entity:{propertyData.Entity}</div>
            {propertyData.PropertyKeyValuesUserCurrencyTotal != null && (
              <div className="flex flex-row content-center items-center gap-2">
                <label>Showing as: </label>
                <RadioButton
                  inputId="entity"
                  name="currencyFilterAUD"
                  value={CurrencyType.AUD}
                  checked={selectedCurrencyFilter == CurrencyType.AUD ? true : false}
                  onChange={(e) => {
                    setSelectedCurrencyFilter(e.value);
                  }}
                />
                <label htmlFor="currencyFilterAUD" className="ml-2 ">
                  $AUD
                </label>
                <RadioButton
                  inputId="entity"
                  name="currencyFilterANZ"
                  value={CurrencyType.NZD}
                  checked={selectedCurrencyFilter == CurrencyType.NZD ? true : false}
                  onChange={(e) => {
                    setSelectedCurrencyFilter(e.value);
                  }}
                />
                <label htmlFor="currencyFilterANZ" className="ml-2 ">
                  $ANZ
                </label>
              </div>
            )}
            <div>Added:{propertyData.DateAdded}</div>
          </div>
          <div className=" p-2 bg-white" style={{ borderRadius: "2px 2px 2px 2px", border: "1px solid #e5e7eb" }}>
            <div className="flex flex-wrap content-center text-lg font-semibold">
              <div className="flex-1 text-center">
                {currencyTemplate(returnCurrencyKeyValues()?.CurrentMarketValue)}
              </div>
              <div className="flex-1 text-center">{currencyTemplate(returnCurrencyKeyValues()?.NetEquity)}</div>
              <div className="flex-1 text-center">{currencyTemplate(returnCurrencyKeyValues()?.PreTaxCashflow)}</div>
              <div className="flex-1 text-center">{currencyTemplate(returnCurrencyKeyValues()?.CapitalGrowth)}</div>
              <div className="flex-1 text-center">
                {formatNumberWithDecimal(Number(returnCurrencyKeyValues()?.CapitalGrowthPercentage), 2, 2)} %
              </div>
              <div className="flex-1 text-center">
                {formatNumberWithDecimal(Number(returnCurrencyKeyValues()?.LVR), 2, 2)} %
              </div>
              <div className="flex-1 text-center">{returnCurrencyKeyValues()?.DSR}</div>
            </div>
            <div className="flex flex-wrap content-center">
              {[
                "Current market estimate",
                "Net equity",
                "Pre-tax cash flow",
                "Capital growth",
                "Capital growth",
                "LVR",
                "DSR",
              ].map((subLabel, index) => (
                <div key={index} className="flex-1 text-center text-sm">
                  {subLabel}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );

  const handleManagePropertyClick = (event: React.MouseEvent<HTMLElement>, rowData: IMyProperties) => {
    setPropertyData(rowData);
  };

  return (
    <>
      <REIToggablePanel
        contentComponent={renderContentComponent()}
        customHeader={renderHeader()}
        istoggleable={false}
        header={""}
        defaultCollapse={false}
      ></REIToggablePanel>
    </>
  );
};

export default PropertyCard;
