import React, { useEffect, useState } from "react";
import { REIButton, REIDropDown, REIInputNumber } from "../../../../components/ui";
import { useAnalysedPropertyContext } from "./AnalysedPropertyContext";
import { Slider } from "primereact/slider";
import { useHttpGets } from "../../../../services/httpService";
import { ApiEndPoints } from "../../../../utils/endpoints";
import { AU_STATES } from "../../../../utils/constants";
import { RadioButton } from "primereact/radiobutton";
import { formatCurrency } from "../../../../utils/commonUtil";
import { IAnalysedProperty } from "../../../../interface/addAnalyser";

interface StampDutyCalculatorProps {
  closeDialogue: () => void;
  updateProperty: (updated: Partial<IAnalysedProperty>) => void;
}

const RadioButtonGroup = ({
  label,
  options,
  selectedValue,
  onChange,
}: {
  label: string;
  options: Array<{ name: string; key: boolean | number; value: number }>;
  selectedValue: any;
  onChange: (value: any) => void;
}) => (
  <div className="flex flex-row w-full p-1">
    <div className="flex-0 w-1/3">
      <label>{label}</label>
    </div>
    <div className="flex-0 w-2/3">
      {options.map((item) => (
        <div key={item.value} className="flex align-items-center p-1">
          <RadioButton
            inputId={item.value.toString()}
            name={label.toLowerCase()}
            value={item}
            onChange={() => onChange(item)}
            checked={selectedValue.key === item.key}
          />
          <label htmlFor={item.value.toString()} className="ml-2">
            {item.name}
          </label>
        </div>
      ))}
    </div>
  </div>
);

export const StampDutyCalculator: React.FC<StampDutyCalculatorProps> = ({ closeDialogue }) => {
  const { analysedProperty, setAnalysedProperty } = useAnalysedPropertyContext();

  const [state, setState] = useState<string>("");
  const [purchasePrice, setPurchasePrice] = useState<number>(0);
  const [stampDuty, setStampDuty] = useState<number>(0);

  const propertyInUse = [
    { name: "To Live in", key: true, value: 0 },
    { name: "An investment", key: false, value: 1 },
  ];
  const propertyDescriptions = [
    { name: "An established home", key: 0, value: 0 },
    { name: "A newly-constructed home", key: 1, value: 1 },
    { name: "Vacant land to build a home", key: 2, value: 2 },
  ];
  const homeBuyers = [
    { name: "Yes", key: true, value: 0 },
    { name: "No", key: false, value: 1 },
  ];

  const [selectPropertyInUse, setSelectPropertyInUse] = useState(propertyInUse[1]);
  const [propertyDescription, setPropertyDescription] = useState(propertyDescriptions[2]);
  const [firstHomeBuyer, setFirstHomeBuyer] = useState(homeBuyers[1]);

  useEffect(() => {
    if (analysedProperty) {
      setState(analysedProperty.PropertyDetail.state);
      setPurchasePrice(analysedProperty.LoanTypeCost.PropertyPurchaseCost.PurchaseAmount);
    }
  }, [analysedProperty]);

  const fetchStampDuty = async (price: number) => {
    if (state && price) {
      try {
        const response = await useHttpGets(
          `${ApiEndPoints.analyser}/Stampduty?State=${state}&Price=${price}&homeConcession=${
            selectPropertyInUse.key
          }&firstHomeConcession=${firstHomeBuyer.key}&vacantLand=${propertyDescription.key === 2}`
        );
        if (response.data !== null) {
          setStampDuty(response.data);
        }
      } catch (error) {
        console.error("Failed to fetch stamp duty:", error);
      }
    }
  };

  const handlePropertyChange = (updateFunction: React.Dispatch<any>, value: any) => {
    updateFunction(value);
  };

  useEffect(() => {
    fetchStampDuty(purchasePrice || 0);
  }, [state, selectPropertyInUse, propertyDescription, firstHomeBuyer, purchasePrice]);

  const closeStampDutyDialogue = () => {
    setAnalysedProperty((prev) => {
      if (!prev) return prev;
      return {
        ...prev,
        LoanTypeCost: {
          ...prev.LoanTypeCost,
          PropertyPurchaseCost: {
            ...prev.LoanTypeCost.PropertyPurchaseCost,
            PurchaseAmount: purchasePrice,
          },
          PropertyOtherPurchaseExpense: {
            ...prev.LoanTypeCost.PropertyOtherPurchaseExpense,
            StampDuty: stampDuty,
          },
        },
      };
    });
    closeDialogue();
  };

  return (
    <div className="w-full pt-2">
      <div className="relative rounded-lg border-solid border border-gray-300 bg-white p-1">
        <div className="">
          {/* State Selection */}
          <div className="flex flex-row w-full items-center p-1">
            <div className="flex-0 w-1/3">
              <label>State</label>
            </div>
            <div className="flex-0 w-2/3">
              <REIDropDown
                value={state}
                onSelectionChange={(e) => setState(e.value)}
                data={AU_STATES}
                label="name"
                className="w-full mr-1"
                optionValue="value"
                placeholder="Please select a state"
              />
            </div>
          </div>

          {/* Purchase Price */}
          <div className="flex flex-row w-full items-center p-1">
            <div className="flex-0 w-1/3">
              <label>Purchase price</label>
            </div>
            <div className="flex-0 w-2/3">
              <div className="flex w-full">
                <Slider
                  value={purchasePrice}
                  onChange={(e) => {
                    const price = Array.isArray(e.value) ? e.value[0] : e.value;
                    setPurchasePrice(price);
                    fetchStampDuty(price);
                  }}
                  className="w-[50%] mt-4"
                  min={0}
                  max={10_000_000}
                />
                <REIInputNumber
                  value={purchasePrice}
                  onValueChange={(e) => {
                    setPurchasePrice(e.value);
                    fetchStampDuty(e.value);
                  }}
                  className="w-[50%] ml-2"
                  useGrouping
                />
              </div>
            </div>
          </div>

          {/* Radio Button Groups */}
          <RadioButtonGroup
            label="Property use"
            options={propertyInUse}
            selectedValue={selectPropertyInUse}
            onChange={(value) => handlePropertyChange(setSelectPropertyInUse, value)}
          />
          <RadioButtonGroup
            label="Property description"
            options={propertyDescriptions}
            selectedValue={propertyDescription}
            onChange={(value) => handlePropertyChange(setPropertyDescription, value)}
          />
          <RadioButtonGroup
            label="First home buyer"
            options={homeBuyers}
            selectedValue={firstHomeBuyer}
            onChange={(value) => handlePropertyChange(setFirstHomeBuyer, value)}
          />
        </div>
      </div>

      {/* Footer */}
      <div className="flex flex-row w-full items-center p-1">
        <div className="flex-0 w-1/3">
          <label className="p-dialog-title">Total stamp duty: {formatCurrency(stampDuty ?? 0)}</label>
        </div>
        <div className="flex-0 w-2/3 text-right">
          <REIButton label="Add to analysis" onClick={closeStampDutyDialogue} />
        </div>
      </div>
    </div>
  );
};

export default StampDutyCalculator;
