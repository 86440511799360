import { Panel } from "primereact/panel";
import { Ripple } from "primereact/ripple";

const REIToggablePanel = ({
  footerTemplate,
  customClassName,
  header,
  istoggleable,
  contentComponent,
  defaultCollapse = true,
  customHeader = null,
}: {
  footerTemplate?: JSX.Element;
  customClassName?: string;
  header: string;
  istoggleable: boolean;
  contentComponent: JSX.Element;
  defaultCollapse: boolean;
  customHeader?: any | null;
}) => {
  const template = (options: any) => {
    const toggleIcon = options.collapsed ? "pi pi-chevron-right" : "pi pi-chevron-down";
    const className = `${options.className} justify-content-start panel-header ${!istoggleable ? "min-h-[48px]" : ""}`;
    const buttonClassName = `${options.togglerClassName} mr-2`;
    return (
      <div className={className}>
        <span className="font-bold">{header}</span>
        {istoggleable && (
          <button className={buttonClassName} onClick={options.onTogglerClick}>
            <span className={toggleIcon}></span>
            <Ripple />
          </button>
        )}
      </div>
    );
  };

  return (
    <Panel
      className={customClassName}
      footerTemplate={footerTemplate}
      headerTemplate={customHeader ? customHeader : template}
      toggleable={istoggleable}
      collapsed={defaultCollapse}
    >
      {contentComponent}
    </Panel>
  );
};

export default REIToggablePanel;
