import { Checkbox } from "primereact/checkbox";

interface REICheckBoxProps {
  checked: boolean;
  onChange: (e: any) => void;
  className?: string;
  label?: string;
  disabled?: boolean;
}

const REICheckBox: React.FC<REICheckBoxProps> = ({ checked, onChange, className = "mr-1", label, disabled }) => {
  return (
    <>
      <Checkbox disabled={disabled} onChange={onChange} checked={checked} className={className}></Checkbox>
      {label && <span>{label}</span>}
    </>
  );
};

export default REICheckBox;
