import React, { createContext, useContext, useState, ReactNode } from "react";
import { ICashFlow } from "../../../interface/cashFlow";

interface ICashFlowContextType {
  cashflow: ICashFlow | undefined;
  setCashFlow: React.Dispatch<React.SetStateAction<ICashFlow | undefined>>;
}

const CashFlowContextType = createContext<ICashFlowContextType | undefined>(undefined);

export const CashFlowProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [cashflow, setCashFlow] = useState<ICashFlow | undefined>(undefined);

  return <CashFlowContextType.Provider value={{ cashflow, setCashFlow }}>{children}</CashFlowContextType.Provider>;
};

export const useCashFlowContext = () => {
  const context = useContext(CashFlowContextType);
  if (!context) {
    throw new Error("cashFlowContext must be used within a CashFlowProvider");
  }
  return context;
};
