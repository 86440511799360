import React, { useEffect, useState } from "react";
import { IAnalysedProperty } from "../../../interface/addAnalyser";
import OtherPurchaseExpense from "./Child/OtherPurchaseExpenses";
import PurchaseDetails from "./Child/PurchaseDetails";
import LoanDetails from "./Child/LoanDetails";
import { useAnalysedPropertyContext } from "./Child/AnalysedPropertyContext";
import { formatCurrency } from "../../../utils/commonUtil";

interface LoanPurchaseCostProps {
  setIsValid: (isValid: boolean) => void;
  updateProperty: (updated: Partial<IAnalysedProperty>) => void;
}

export const LoanPurchaseCost: React.FC<LoanPurchaseCostProps> = ({ setIsValid, updateProperty }) => {
  const { analysedProperty } = useAnalysedPropertyContext();
  const [loanPurchaseCost, setLoanPurchaseCost] = useState<number>(0);

  useEffect(() => {
    if (analysedProperty) {
      var totalPurchaseCost = 0;
      totalPurchaseCost =
        analysedProperty.LoanTypeCost.PropertyPurchaseCost.PurchaseAmount +
        analysedProperty.LoanTypeCost.PropertyOtherPurchaseExpense.CapitalInvestment +
        analysedProperty.LoanTypeCost.PropertyOtherPurchaseExpense.OtherCost +
        analysedProperty.LoanTypeCost.PropertyOtherPurchaseExpense.SolicitorsCost +
        analysedProperty.LoanTypeCost.PropertyOtherPurchaseExpense.StampDuty;

      analysedProperty.LoanTypeCost.Loans.forEach((loan) => {
        totalPurchaseCost += loan.EstablishmentFees + loan.OtherLoanCosts + loan.ValuationFees;
      });

      setLoanPurchaseCost(totalPurchaseCost);
    }
  }, [analysedProperty]);

  return (
    <>
      <PurchaseDetails setIsValid={setIsValid} updateProperty={updateProperty} />
      <OtherPurchaseExpense setIsValid={setIsValid} updateProperty={updateProperty} />
      <LoanDetails setIsValid={setIsValid} updateProperty={updateProperty} />
      <div className="w-full">
        <div className="relative rounded-lg pl-3 pr-1 py-3 border-solid border border-gray-300 bg-white mt-2">
          <div className="">
            <div className="flex flex-col lg:flex-row lg:w-full gap-2">
              <div className="flex-0 flex-col w-full lg:w-1/3 gap-2">
                <span className="font-semibold">Total purchase costs:</span>
              </div>
              <div className="flex-0 flex-col w-full lg:w-1/3 gap-2">
                <span>{formatCurrency(loanPurchaseCost)}</span>
              </div>
              <div className="flex-0 flex-col w-full lg:w-1/3 gap-2">
                <span>Deposit and loans equal purchase costs</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoanPurchaseCost;
