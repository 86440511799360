import React, { useEffect, useState, useCallback } from "react";
import { IAnalysisSummaryResponse, IPropertyRentalIncome } from "../../../../interface/analysisSummary";
import { REIDialoge, REIProgressSpinner } from "../../../../components/ui";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import EditableDataTable from "../../../../components/shared/editableDataTable";
import { triggerReload } from "../../../../store/slice/analyserSlice";
import { ForecastItemCode, MEMBER_KEY, USER_DETAIL, YEARS } from "../../../../utils/constants";
import { httpUpdate } from "../../../../services/httpService";
import { ApiEndPoints } from "../../../../utils/endpoints";
import UpdateRentDialoge from "./UpdateRentDialoge";

const Statistics: React.FC<{
  editFlag: boolean;
  visibleYears: { label: string; defaultShow: boolean }[];
  resetEditFlag: () => void;
}> = ({ editFlag, visibleYears, resetEditFlag }) => {
  const analysisSummary = useSelector((state: RootState) => state.analyser.analysisSummary as IAnalysisSummaryResponse);
  const memberDetail = JSON.parse(localStorage.getItem(USER_DETAIL) || "{}");
  const [overviewData, setOverviewData] = useState<{ [key: string]: any }>({});
  const [loading, setLoading] = useState(false);
  const [showEditStatistics, setShowEditStatistics] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<{ key?: string; year?: string; value?: string }>({});
  const [propertyRentalIncome, setPropertyRentalIncome] = useState<IPropertyRentalIncome>();

  const dispatch = useDispatch();

  const didValueChange = useCallback(
    (itemCode: string, year: number) =>
      analysisSummary?.ForecastOverrides?.some(
        (forecast) => forecast.ForecastItemCode === itemCode && forecast.Year === year
      ),
    [analysisSummary]
  );

  const didAnyValueChange = useCallback(
    (itemCode: string) =>
      analysisSummary?.ForecastOverrides?.some((forecast) => forecast.ForecastItemCode === itemCode),
    [analysisSummary]
  );

  const forectedForFutureYear = useCallback(
    (itemCode: string, year: number) =>
      analysisSummary?.ForecastOverrides?.some(
        (forecast) => forecast.ForecastItemCode === itemCode && forecast.Year === year && forecast.ThisYearOnly === 0
      ),
    [analysisSummary]
  );

  const mapValuesToYears = (
    overview: { [key: string]: any },
    forecastCode: string,
    prefix: string,
    editableYears: number[]
  ) => {
    const yearsMap: {
      [key: string]: { value: string; editable: boolean; didValueChange: boolean; forcastedForFutureYears: boolean };
    } = {};
    YEARS.forEach((year, index) => {
      const yearLabel = year.label.replace("Year ", "Yr");
      yearsMap[year.label] = {
        value: overview[`${prefix}${yearLabel}`]?.toString() || "",
        editable: editableYears?.includes(index),
        didValueChange: didValueChange(forecastCode, index),
        forcastedForFutureYears: forectedForFutureYear(forecastCode, index),
      };
    });
    return yearsMap;
  };

  const initializeOverviewData = (analysisSummary: IAnalysisSummaryResponse) => ({
    "Equity in property $": {
      type: "currency",
      values: mapValuesToYears(analysisSummary.AnalysisStatistics, "", "EquityInProperty", []),
    },
    "Loan to value ratio %": {
      type: "%",
      values: mapValuesToYears(analysisSummary.AnalysisStatistics, "", "LoanValueRatio", []),
    },
    "Surplus equity to reinvest $": {
      type: "currency",
      values: mapValuesToYears(analysisSummary.AnalysisStatistics, "", "SurplusEquitytoReinvest", []),
    },
    "Buying power $": {
      type: "currency",
      values: mapValuesToYears(analysisSummary.AnalysisStatistics, "", "BuyingPower", []),
    },
    "Rental income $": {
      type: "currency",
      forecastOverrideKey: ForecastItemCode.CapitalGrowth,
      anyCellValueChange: didAnyValueChange(ForecastItemCode.CashFlowRent),
      values: mapValuesToYears(
        analysisSummary.AnalysisStatistics,
        ForecastItemCode.CashFlowRent,
        "AverageWeeklyRent",
        [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
      ),
    },
  });

  const footer = (analysisSummary: IAnalysisSummaryResponse) =>
    analysisSummary && {
      "Gross yield %": {
        type: "%",
        values: mapValuesToYears(analysisSummary.AnalysisStatistics, "", "GrossYield", []),
      },
      "Net yield %": {
        type: "%",
        values: mapValuesToYears(analysisSummary.AnalysisStatistics, "", "NetYield", []),
      },
    };

  useEffect(() => {
    if (analysisSummary) {
      setOverviewData(initializeOverviewData(analysisSummary));
    }
  }, [analysisSummary]);

  const onEdit = (rowKey: string, year: string, value: string) => {};

  const onInputClick = (rowKey: string, year: string, value: string) => {
    setSelectedRow({ key: rowKey, year: year, value: value });
    const propertyRentalIncome = analysisSummary.PropertyRentalIncome.find(
      (item) => item.Year == parseInt(year.replace("Year ", ""))
    );
    setPropertyRentalIncome(propertyRentalIncome);
    setShowEditStatistics(true);
  };

  const onForecastEdit = async (rowKey: string, year: string, value: string, forecastItemCode: string) => {
    setLoading(true);
    var variableBody = {};
    variableBody = {
      MemberId: localStorage.getItem(MEMBER_KEY),
      MemberCountryCode: memberDetail.Country,
      LVR: analysisSummary?.Levers?.LoanValueRatio,
      LoansPrincipalInterest: analysisSummary?.LoanPrincipalInterest,
      ForecastOverrides:
        year === "Year 10"
          ? analysisSummary.ForecastOverrides.map((forecast) => {
              return {
                ...forecast,
                Remove: 1,
                ThisYearOnly: 0,
              };
            })
          : analysisSummary.ForecastOverrides.map((forecast) => {
              return {
                ...forecast,
                ThisYearOnly: 0,
              };
            }),
      PropertyAnalyserId: analysisSummary?.PropertyAnalyserId,
    };

    updateForecastValue(variableBody, false);
  };

  const updateForecastValue = async (variableBody: object, updateSingleValue: boolean) => {
    try {
      const result = await httpUpdate(`${ApiEndPoints.analyser}/AnalysisReport/`, variableBody);

      if (result.status === 200 && result.data) {
        dispatch(triggerReload());

        !updateSingleValue && resetEditFlag();
      }
    } catch (error) {
      console.error("Error updating loan value ratio:", error);
    } finally {
      setLoading(false);
      resetEditFlag();
    }
  };

  const hideEditDialog = useCallback(() => {
    setShowEditStatistics(false);
    resetEditFlag();
  }, []);

  if (!analysisSummary || loading) {
    return (
      <div className="w-full text-center">
        <REIProgressSpinner />
      </div>
    );
  }

  return (
    <>
      <REIDialoge
        showHeader={true}
        header={`Update Rent - ${selectedRow?.year}`}
        visible={showEditStatistics}
        handleEditClick={hideEditDialog}
        position="top"
        customClass="update-statistics-dialog"
        style={{ width: "60vw" }}
        contentComponent={
          <UpdateRentDialoge
            value={selectedRow.value}
            year={selectedRow.year}
            propertyRentalIncome={propertyRentalIncome || ({} as IPropertyRentalIncome)}
            closeDialogue={hideEditDialog}
          />
        }
      ></REIDialoge>
      <EditableDataTable
        title="Statistics"
        data={overviewData}
        years={visibleYears.map((year) => year.label)}
        isEditable={editFlag}
        onForecastValueChange={onForecastEdit}
        onEdit={onEdit}
        onClick={onInputClick}
        footer={footer(analysisSummary)}
      />
    </>
  );
};

export default Statistics;
