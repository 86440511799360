import React, { useEffect, useRef, useState } from "react";
import REIStepper from "../../../components/ui/stepper";
import { useLocation, useNavigate } from "react-router-dom";
import Analysis from "./Analysis";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import { fetchAnalysisSummary, fetchMemeberDetail } from "../../../store/slice/analyserSlice";
import AnyalysisGraph from "./Graph";
import { REIButton, REIDialoge } from "../../../components/ui";
import SavedAnalyser from "../SaveAnalyser";
import { httpUpdate } from "../../../services/httpService";
import { ApiEndPoints } from "../../../utils/endpoints";
import { MEMBER_KEY, USER_DETAIL } from "../../../utils/constants";
import { Toast } from "primereact/toast";
import PropertySummary from "../Property Summary/PropertySummary";
import MoveToPortfolioTracker from "./Child/MoveToPortfolioTracker";
import { AnalysedPropertyProvider } from "../PropertyStepper/Child/AnalysedPropertyContext";
import { IMemberEntity } from "../../../interface/analysed";

const AnalysisReportIndex: React.FC = () => {
  const location = useLocation();
  const toastRef = useRef<Toast>(null);
  const memberDetail = JSON.parse(localStorage.getItem(USER_DETAIL) || "{}");
  const { propertyAnalyserId, propertyName, MemberCountryCode } = location.state || {};
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const [showMoveToPortfolioDialog, setShowMoveToPortfolioDialog] = useState<boolean>(false);
  const memeberEntity = useSelector((state: RootState) => state.analyser.memeberEntity as IMemberEntity[]);
  const reload = useSelector((state: RootState) => state.analyser.reload);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (propertyAnalyserId) {
      dispatch(fetchAnalysisSummary(propertyAnalyserId));
    } else {
      navigate("property-analyser");
    }
  }, [propertyAnalyserId, reload]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await dispatch(fetchMemeberDetail());
      setLoading(false);
    };

    fetchData();
  }, [dispatch]);

  const steps = [
    {
      header: "Analysis report",
      content: <Analysis />,
      validate: () => true,
    },
    {
      header: "Graphs",
      content: (
        <span>
          <AnyalysisGraph />
        </span>
      ),
      validate: () => true,
    },
    {
      header: "Property summary",
      content: (
        <span>
          <PropertySummary></PropertySummary>
        </span>
      ),
      validate: () => true,
    },
  ];

  const AddToSave = async () => {
    const result = await httpUpdate(`${ApiEndPoints.analyser}/PropertyAnalyserStatus/`, {
      PropertyAnalyserId: propertyAnalyserId,
      MemberId: localStorage.getItem(MEMBER_KEY),
    });
    if (result) {
      toastRef.current?.show({
        severity: "success",
        summary: "Success Message",
        detail: "Property Analyser saved successfully.",
        life: 2000,
      });
    } else {
      toastRef.current?.show({
        severity: "error",
        summary: "Error Message",
        detail: "Failed to save Property Analyser.",
        life: 3000,
      });
    }
  };

  const onEdit = () => {
    navigate("/analyse-property", {
      state: { propertyAnalyserId: propertyAnalyserId, medianRequestBody: undefined },
    });
  };

  return (
    <>
      <Toast ref={toastRef} />
      <div className="flex flex-row w-full items-start justify-between mb-1">
        <div className="flex flex-col w-1/2 gap-2 pr-2">
          <div className="flex w-full border border-x-0 border-solid border-gray-300 py-2">
            <label className="text-lg font-semibold text-blue-600">Results for {propertyName}</label>
          </div>
          <div className="flex gap-1">
            <REIButton label="Add to Save" disabled={false} loading={false} icon="" onClick={AddToSave} />
            <REIButton
              label="Move to Portfolio Tracker"
              disabled={false}
              loading={false}
              icon=""
              onClick={() => {
                setShowMoveToPortfolioDialog(true);
              }}
            />
            <REIButton label="Edit" disabled={false} loading={false} icon="" onClick={onEdit} />
          </div>
        </div>

        <div className="flex w-1/2 justify-end border border-solid border-gray-300 rounded-md p-1 pa-stepper-header min-h-[100px]">
          <SavedAnalyser hideExtraColumns={true} />
        </div>
      </div>
      {showMoveToPortfolioDialog && !loading && (
        <REIDialoge
          header="Add a property to my portfolio"
          contentComponent={
            <AnalysedPropertyProvider>
              <MoveToPortfolioTracker
                memberEntity={memeberEntity}
                propertyAnalyserId={propertyAnalyserId}
                memberCountryCode={memberDetail.Country}
              ></MoveToPortfolioTracker>
            </AnalysedPropertyProvider>
          }
          handleEditClick={() => {
            setShowMoveToPortfolioDialog(false);
          }}
          showHeader={true}
          visible={showMoveToPortfolioDialog}
        ></REIDialoge>
      )}
      <div className="flex flex-row bg-white pa-stepper-header">
        <div className="flex-1 w:full border border-solid border-gray-300 rounded-md">
          <REIStepper steps={steps} linear={false} />
        </div>
      </div>
    </>
  );
};

export default AnalysisReportIndex;
